import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation AcknowledgePolicyMutation($input: AcknowledgePolicyMutationInput!) {
    acknowledgePolicy(input: $input) {
      ok
      reasons
    }
  }
`;

export const acknowledgePolicy = (environment, pk, callback) => {
  const variables = {
    input: {
      documentPk: pk
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      if (err) {
        throw new Error(err);
      }
    }
  });
};
