import { Box, Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { setHomeSite } from "pstat-anywhere/components/bloc/ChooseSiteModalMutation";
import { StyledForm } from "pstat-anywhere/components/document_control/approve_policy/ApprovalModal/components";
import { SingleSelectDropdownFormElement } from "pstat-design-system/inputs/FormElements";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";

const ChooseSiteModal = ({ environment, accessibleTenants }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validadeOption = event => {
    if (event.target.value >= 0) {
      setBtnDisabled(false);
      setSelectedTenant(event.target.value);
    } else {
      setBtnDisabled(true);
    }
  };

  const resetSelect = event => {
    setBtnDisabled(true);
    setSelectedTenant(null);
  };

  const setHomeSiteMutation = event => {
    if (selectedTenant !== null) {
      setIsLoading(true);
      setHomeSite(
        environment,
        selectedTenant,
        onMutationSuccess,
        onMutationFail
      );
    }
  };

  const onMutationSuccess = () => {
    setIsOpen(false);
    createSuccessMessage(t("bloc.sites.chooseHomeSite.successMessage"));
    window.location.replace(urls[selectedTenant]);
  };

  const onMutationFail = () => {
    setIsOpen(false);
    createErrorMessage(t("bloc.sites.chooseHomeSite.errorMessage"));
  };

  const allowedTenants = accessibleTenants.filter(tenant => !tenant.isAllBlocs);

  let urls = {};
  allowedTenants.map(tenant => (urls[tenant.pk] = tenant.url));

  const tenantOptions = [
    ...allowedTenants.map(({ pk, name }) => {
      return { pk: pk, name: name };
    })
  ];

  return (
    <Modal
      id="ChooseSiteModal"
      contentLabel={t("bloc.sites.chooseHomeSite")}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      renderFooter={() => (
        <ModalFooter
          display="flex"
          flexDirection="row-reverse"
          justifyContent="space-between"
          position="relative"
          px={5}
          py={3}
        >
          <Button
            id="submit-homeSite"
            type="submit"
            buttonStyle="secondary"
            data-testid="homeSite-submit-button"
            onClick={setHomeSiteMutation}
            disabled={btnDisabled}
            onKeyDown={setHomeSiteMutation}
          >
            {t("buttons.setHomeSite")}
          </Button>
        </ModalFooter>
      )}
    >
      {isLoading ? (
        <Flex justify="center" align="center" mt={10}>
          <Box>
            <LoadingSpinner size={100} />
          </Box>
        </Flex>
      ) : (
        <StyledForm>
          <Text display={"inline-block"} fontSize={2} mb={6}>
            {t("bloc.sites.chooseDescription")}
          </Text>
          <SingleSelectDropdownFormElement
            id="homeSite_modal_select"
            label={t("bloc.sites.homeSite")}
            containerProps={{ width: "100%", mb: "105px" }}
            options={tenantOptions}
            onChange={validadeOption}
            onClear={resetSelect}
            value={selectedTenant}
            required={true}
            placeholder={t("bloc.sites.chooseHomeSite.placeholder")}
          />
        </StyledForm>
      )}
    </Modal>
  );
};

export default ChooseSiteModal;
