import { Box, Flex } from "grid-styled";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import {
  DateInput,
  UserAutocompleteFormElement
} from "pstat-design-system/inputs/FormElements";
import Panel from "pstat-design-system/Panel";
import { Text } from "pstat-design-system/text";

const ApprovalSimulation = ({
  policyForm,
  onApproverChange,
  onApproverClear,
  onApprovedDateChange,
  onApprovedDateClear,
  errors
}) => {
  const { t } = useTranslation();
  return (
    <Panel
      name={t("documentControl.override.approvalSimulationSection")}
      hideHeaderBorder={true}
      id="approval_simulation"
    >
      <Flex>
        <Box>
          <StyledFontAwesomeIcon
            icon={["fas", "info-circle"]}
            color="secondary.0"
          />
        </Box>
        <Box flex={1}>
          <Text fontSize={1} display="inline-block" mb={1}>
            {t("documentControl.override.approvalSimulation.warning")}
          </Text>
          <Text fontSize={1} display="block" mb={7}>
            {t("documentControl.override.approvalSimulation.advanceWorkflow")}
          </Text>
        </Box>
      </Flex>
      <UserAutocompleteFormElement
        id="approver"
        onClear={onApproverClear}
        required={false}
        value={policyForm.approver}
        label={t("documentControl.override.approvalSimulation.approverLabel")}
        onChange={useCallback(event => onApproverChange(event.target.value), [
          onApproverChange
        ])}
        name="approver"
        errors={errors.approver}
        selectMultiple={false}
        mb={30}
      />
      <DateInput
        id="approved_date"
        onClear={onApprovedDateClear}
        required={false}
        value={policyForm.approvedDate}
        label={t(
          "documentControl.override.approvalSimulation.approvedDateLabel"
        )}
        onChange={useCallback(
          event => onApprovedDateChange(event.target.value),
          [onApprovedDateChange]
        )}
        name="approved_date"
        errors={errors.approved_date}
      />
    </Panel>
  );
};

export default ApprovalSimulation;
