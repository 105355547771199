import { Box, Flex } from "grid-styled";
import PropTypes from "prop-types";
import { Children, cloneElement } from "react";
import { useTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";

import theme from "pstat-anywhere/themes/policystat/theme";
import { Badge } from "pstat-design-system/Badge";
import { Text } from "pstat-design-system/text";
import MobileHorizontalScrollControl from "pstat-design-system/utils/AddHorizontalScrollControls";

const StyledTabContainer = system({
  width: "100%",
  bg: ["nav.95", "nav.95", "nav.100"],
  borderBottom: 1,
  borderRadius: 1,
  borderColor: "nav.80",
  boxShadow: "card",
  height: [52, 52, "inherit"]
}).extend`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const RelativePositioner = system({
  position: "relative",
  height: "100%",
  px: [3, 3, 6]
});

const TabListContainer = system({
  display: "flex",
  role: "tablist",
  height: "100%"
});

const EmptyTabListContainer = system({
  display: "flex",
  height: "55px"
});

export const TabContainer = props => {
  const childrenWithExtraProps = Children.map(props.children, child =>
    cloneElement(child, {
      selected: props.selectedTabId === child.props.id,
      onSelect: props.onSelectedTabChange
    })
  );

  return (
    <StyledTabContainer>
      <RelativePositioner>
        <MobileHorizontalScrollControl
          render={theRef => (
            <Flex flex="1" justifyContent="space-between" alignItems="center">
              <TabListContainer innerRef={theRef}>
                {childrenWithExtraProps}
              </TabListContainer>
              {props.totalCount !== null &&
                props.totalCount !== undefined &&
                props.currentPageResultsCount !== null &&
                props.currentPageResultsCount !== undefined && (
                  <ResultsCount
                    totalCount={props.totalCount}
                    currentPageResultsCount={props.currentPageResultsCount}
                  />
                )}
            </Flex>
          )}
        />
      </RelativePositioner>
    </StyledTabContainer>
  );
};
TabContainer.propTypes = {
  selectedTabId: PropTypes.string.isRequired,
  onSelectedTabChange: PropTypes.func.isRequired
};

export const EmptyTabContainer = props => {
  return (
    <StyledTabContainer>
      <RelativePositioner>
        <MobileHorizontalScrollControl
          render={theRef => (
            <Flex flex="1" justifyContent="space-between" alignItems="center">
              <EmptyTabListContainer innerRef={theRef} />
              <ResultsCount
                totalCount={props.totalCount}
                currentPageResultsCount={props.currentPageResultsCount}
              />
            </Flex>
          )}
        />
      </RelativePositioner>
    </StyledTabContainer>
  );
};

const StyledTab = system({
  role: "tab",
  display: "flex",
  alignItems: "center",
  tabIndex: 0,
  height: "100%"
}).extend`
  border-bottom: ${props =>
    props.selected
      ? `3px solid ${theme.colors.secondary[0]}`
      : `3px solid ${theme.colors.nav[95]}`};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    border-bottom: ${props =>
      props.selected
        ? `3px solid ${theme.colors.secondary[0]}`
        : `3px solid ${theme.colors.nav[100]}`};
  }
  &:focus {
    outline: none;
  }
  &:hover { 
    cursor: pointer;
  }
`;

const TabContent = system({
  py: [0, 0, 4],
  px: 4,
  tabIndex: "-1"
}).extend`
  &:focus {
    outline: none;
  }
  ${StyledTab}:focus > & {
    outline: auto 5px -webkit-focus-ring-color;
  }
  white-space: nowrap;
`;

export const Tab = props => {
  const handleClick = event => {
    event.target.blur();
    if (!props.selected) {
      props.onSelect(props.id);
    }
  };
  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      props.onSelect(props.id);
    }
  };
  return (
    <StyledTab
      aria-selected={props.selected}
      aria-controls={`${props.id}-panel`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      id={props.id}
      selected={props.selected}
    >
      <TabContent>{props.children}</TabContent>
    </StyledTab>
  );
};
Tab.propTypes = {
  id: PropTypes.string.isRequired
};

export const TabPanelContainer = props => {
  const childrenWithExtraProps = Children.map(props.children, child =>
    cloneElement(child, {
      isShown: props.selectedTabId === child.props.tabId
    })
  );

  return <div>{childrenWithExtraProps}</div>;
};
TabPanelContainer.propTypes = {
  selectedTabId: PropTypes.string.isRequired
};

const StyledTabPanel = system({
  role: "tabpanel"
});

export const TabPanel = props =>
  props.isShown && (
    <StyledTabPanel aria-labelledby={props.tabId} id={`${props.tabId}-panel`}>
      {props.children}
    </StyledTabPanel>
  );
TabPanel.propTypes = {
  tabId: PropTypes.string.isRequired
};

export const StyledTabBadge = system({
  is: Badge,
  borderColor: "primary.0",
  color: "secondary.0"
});

const ResultsCount = ({ totalCount, currentPageResultsCount }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Text color="nav.0">{t("tabResults.showing")} </Text>
      <Text color="nav.0" fontWeight="medium">
        {currentPageResultsCount}{" "}
      </Text>
      <Text color="nav.0">{t("tabResults.of")} </Text>
      <Text color="nav.0" fontWeight="medium">
        {totalCount}{" "}
      </Text>
      <Text color="nav.0">{t("tabResults.results")}</Text>
    </Box>
  );
};
