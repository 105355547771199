import queryString from "query-string";

import { LoginFormCustomerLogo } from "./Common";
import LoginForm from "./LoginForm";

const LoginPage = props => {
  const parsed = queryString.parse(window.location.search);

  const isLogout = parsed["lo"];
  const isErrorSsoUri = parsed["sso"];
  const isErrorSsoUsername = parsed["sso_username"];
  const isErrorSsoUserAuthFailed = parsed["sso_user_auth_failed"];
  const isErrorSsoUsernameInvalid = parsed["sso_username_invalid"];
  const isErrorSsoSamlResponseStatusError =
    parsed["saml_response_status_error"];

  const notHardRedirect =
    isLogout ||
    isErrorSsoUri ||
    isErrorSsoUsername ||
    isErrorSsoUserAuthFailed ||
    isErrorSsoUsernameInvalid ||
    isErrorSsoSamlResponseStatusError;

  return (
    <LoginFormCustomerLogo customerLogo={props.currentTenant?.customerLogo}>
      <LoginForm hardRedirect={!notHardRedirect} />
    </LoginFormCustomerLogo>
  );
};

export default LoginPage;
