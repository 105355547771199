import { Fragment } from "react";
import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import { Text } from "pstat-design-system/text/paragraph";

const BreadcrumbLink = ({ id, text, to }) => (
  <RouterFocusLink id={id} to={to} mt={4} mb={1}>
    <BreadcrumbText text={text} />
  </RouterFocusLink>
);

const Button = system({
  is: "button",
  bg: "transparent",
  border: "none",
  p: 0,
  pb: 1
}).extend`
  cursor: pointer;
`;

export const BreadcrumbButton = ({ id, text, onClick, ...otherProps }) => {
  return (
    <Button id={id} onClick={onClick} {...otherProps}>
      <BreadcrumbText text={text} />
    </Button>
  );
};

const BreadcrumbText = ({ text }) => (
  <Fragment>
    <StyledFontAwesomeIcon icon={["far", "chevron-left"]} ml={0} />
    <Text fontWeight="medium" fontSize={1} color="nav.25">
      {text}
    </Text>
  </Fragment>
);

export default BreadcrumbLink;
