import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { getDocumentViewRecord, getStoredTokens } from "./utils";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation CreateTokenMutation($input: CreateDocumentAccessTokenInput!) {
    createDocumentAccessToken(input: $input) {
      ok
      token {
        tokenId
        description
        dateLastAccessed
        url
      }
    }
  }
`;

export function createNewToken(documentPk, description, location, callback) {
  const variables = {
    input: {
      documentPk,
      description
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    },
    updater: store => {
      const payload = store.getRootField("createDocumentAccessToken");
      const newToken = payload.getLinkedRecord("token");

      const documentViewRecord = getDocumentViewRecord(
        store,
        documentPk,
        location
      );
      if (!documentViewRecord) {
        return;
      }
      const storedTokens = getStoredTokens(documentViewRecord);

      const tokens = [...storedTokens, newToken];
      documentViewRecord.setLinkedRecords(tokens, "documentAccessTokens");
    }
  });
}
