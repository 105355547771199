import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation PublishTemplatesMutation(
    $input: PublishSystemDocumentTemplatesInput!
  ) {
    publishSystemDocumentTemplates(input: $input) {
      ok
      result
      error
    }
  }
`;

function publishTemplates(templatePks, blocPks, callback) {
  const variables = {
    input: {
      systemDocumentTemplates: templatePks,
      blocs: blocPks
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    }
  });
}

export default publishTemplates;
