import { Route } from "found";

import { CustomerLogoQuery } from "./CustomerLogoQuery";
import { ForgotPasswordPage } from "./ForgotPassword";
import { ForgotPasswordConfirmPage } from "./ForgotPasswordConfirm";

const routes = (
  <Route path="authorization">
    <Route
      path="login_help"
      query={CustomerLogoQuery}
      Component={ForgotPasswordPage}
      data={{
        routeName: "authorization_login_help"
      }}
    />
    <Route
      path="password_reset/:uidb64/:token"
      Component={ForgotPasswordConfirmPage}
      data={{
        routeName: "authorization_password_reset_confirm"
      }}
    />
  </Route>
);

export default routes;
