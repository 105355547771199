import { Box } from "grid-styled";
import { useEffect, useRef } from "react";
import stickybits from "stickybits";
import styled from "styled-components";
import { height, zIndex } from "styled-system";

// the Box component doesn't accept zIndex prop for some reason,
// even if adding the styled-system position function.
const ZIndexBox = styled(Box)`
  ${height}
  ${zIndex}
  pointer-events:auto;
`;

const Sticky = ({ id, offset, loading = false, children, ...otherProps }) => {
  const stickyRef = useRef(null);
  useEffect(() => {
    const options = {};
    if (offset) {
      options.stickyBitStickyOffset = offset;
    }
    stickyRef.current = stickybits(`#${id}.sticky`, options);
    const resizeListener = window.addEventListener("resize", () => {
      stickyRef.current.update();
    });
    return () => {
      window.removeEventListener("resize", resizeListener);
      stickyRef.current.cleanup();
    };
  }, [id, offset]);

  // if loading changes from true to false, recalculate
  useEffect(() => {
    if (!loading) {
      stickyRef.current.update();
    }
  }, [loading]);

  // need a unique id for each sticky component on the page
  if (!id) {
    return null;
  }

  return (
    <ZIndexBox id={id} className="sticky" {...otherProps}>
      {children}
    </ZIndexBox>
  );
};

export default Sticky;
