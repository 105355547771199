/**
 * @flow
 * @relayHash d56f7378b7167c5e02963e6a013c9935
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useTaskResultQueryVariables = {|
  taskId: any
|};
export type useTaskResultQueryResponse = {|
  +getTaskStatus: ?{|
    +status: ?string,
    +result: ?any,
  |}
|};
export type useTaskResultQuery = {|
  variables: useTaskResultQueryVariables,
  response: useTaskResultQueryResponse,
|};
*/


/*
query useTaskResultQuery(
  $taskId: UUID!
) {
  getTaskStatus(taskId: $taskId) {
    status
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "getTaskStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskId",
        "variableName": "taskId",
        "type": "UUID!"
      }
    ],
    "concreteType": "TaskStatusType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "useTaskResultQuery",
  "id": "99bd6d372c3d42b6bfd3893cb3ae9e00",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "useTaskResultQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "useTaskResultQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query useTaskResultQuery(\n  $taskId: UUID!\n) {\n  getTaskStatus(taskId: $taskId) {\n    status\n    result\n  }\n}\n";
(node/*: any*/).hash = '5f9f0982a02107213eead01331c8f122';
module.exports = node;
