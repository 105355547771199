import { style } from "styled-system";

const fontStyle = style({
  // React prop name
  prop: "fontStyle",
  // The corresponding CSS property
  cssProperty: "fontStyle"
});

export default fontStyle;
