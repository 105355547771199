import { Route } from "found";

import { SearchResultsPage } from "./SearchResults/SearchResults";

import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";


// TODO read about query in routes and farce router

const routes = (
  <Route
    path="search"
    Component={props => (
      <PageTitleWrapper titleKey="documentSearch.documentTitle">
        <SearchResultsPage {...props} />
      </PageTitleWrapper>
    )}
    data={{
      routeName: "search"
    }}
    prepareVariables={(params, { location }) => ({
      ...params,
      queryName: location.query.name
    })}
  />
);

export default routes;
