import { Flex } from "grid-styled";
import { NavLink } from "rebass";
import styled from "styled-components";
import { space, themeGet, width } from "styled-system";
import system from "system-components";

import { StyledFontAwesomeIcon } from "./icons";

import { P } from "pstat-design-system/text";
import RouterLink from "pstat-design-system/RouterLink";
import checkForClientUpdate from "pstat-anywhere/utils/autoUpdate";

const HoverNavLink = NavLink.extend`
  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
  height: inherit;
`;

const ActiveNavLink = HoverNavLink.extend`
  @media (min-width: ${themeGet("breakpoints.1")}) {
    border-top: 2px solid;
  }
  color: ${themeGet("colors.nav.100")};
`;

const ActivatableNavLink = ({ active, ...props }) =>
  active ? (
    <ActiveNavLink href="#" {...props} pt={1} bg="nav.10" />
  ) : (
    <HoverNavLink href="#" {...props} />
  );

const StyledActivatableNavLink = styled(ActivatableNavLink)``;

const StyledRouterLink = styled(RouterLink)``;

const BaseMenuText = system(
  {
    is: P,
    border: 1,
    borderColor: "transparent",
    borderRadius: 1
  },
  space,
  width
).extend`
  white-space: nowrap;
`;

export const CenteringFlex = styled(Flex)`
  align-items: center;
  width: 100%;
  height: 100%;
  &:focus {
    outline: 0;
  }
`;

const MainMenuText = styled(BaseMenuText)`
  ${StyledActivatableNavLink}:focus &,
  ${StyledRouterLink}:focus & {
    border: dashed 1px ${themeGet("colors.nav.100")};
  }
`;

const SubMenuText = styled(BaseMenuText)`
  @media (max-width: ${themeGet("breakpoints.1")}) {
    ${StyledActivatableNavLink}:focus &, ${StyledRouterLink}:focus & {
      border: dashed 1px ${themeGet("colors.nav.100")};
    }
  }
  @media (min-width: ${themeGet("breakpoints.1")}) {
    ${StyledActivatableNavLink}:focus &, ${StyledRouterLink}:focus & {
      border: dashed 1px ${themeGet("colors.nav.0")};
    }
  }
`;

const MenuLink = ({ mainMenu, children, to, menuItemContainer, ...props }) => {
  const MenuItemContainer = menuItemContainer || CenteringFlex;
  const handleClick = event => {
    if (props.onClick) {
      props.onClick(event);
    }
    if (to) {
      checkForClientUpdate();
    }
  };
  const handleKeyDown = event => {
    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
    if (event.key === "Enter" && to) {
      checkForClientUpdate();
    }
  };

  let LinkComponent = StyledActivatableNavLink;
  const additionalProps = {};

  if (to) {
    LinkComponent = StyledRouterLink;
    additionalProps.to = to;
    additionalProps.Component = ActivatableNavLink;
    additionalProps.activePropName = "active";
  }

  let MenuText = SubMenuText;
  if (mainMenu) {
    MenuText = MainMenuText;
  }
  return (
    <LinkComponent
      width="100%"
      px={0}
      py={0}
      {...additionalProps}
      {...props}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <MenuItemContainer px={[8, 8, 2]} tabIndex="-1">
        <MenuText p={2} size="normal" color="inherit">
          {children}
        </MenuText>
      </MenuItemContainer>
    </LinkComponent>
  );
};

MenuLink.displayName = "MenuLink";

export default MenuLink;

export const MenuLabel = props => (
  <CenteringFlex px={[8, 8, 2]}>
    <SubMenuText
      p={2}
      size="normal"
      color={props.color || "inherit"}
      fontWeight={props.fontWeight}
    >
      {props.children}
    </SubMenuText>
  </CenteringFlex>
);

export const SelectedMenuLabel = ({ menuItemContainer, children }) => {
  const MenuItemContainer = menuItemContainer || CenteringFlex;
  return (
    <MenuItemContainer px={[2, 2, 2]}>
      <StyledFontAwesomeIcon icon={["far", "check"]} color="primary.0" />
      <SubMenuText p={2} size="normal" color="inherit" fontStyle="italic">
        {children}
      </SubMenuText>
    </MenuItemContainer>
  );
};
