import { useState } from "react";

export const useEpViewMenu = () => {
  const [showEpAssociationViewMenu, setShowEpAssociationViewMenu] = useState(
    false
  );
  const [epContent, setEpContent] = useState(null);

  const closeEpAssociationMenu = () => {
    setShowEpAssociationViewMenu(false);
  };

  const openEpAssociationMenu = content => {
    setShowEpAssociationViewMenu(true);
    setEpContent(content);
  };
  return [
    showEpAssociationViewMenu,
    epContent,
    openEpAssociationMenu,
    closeEpAssociationMenu
  ];
};
