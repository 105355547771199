import { createContext, useContext } from "react";

export const EnvironmentContext = createContext({});

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error(
      "useEnvironment must be used within a EnvironmentContext.Provider"
    );
  }
  return context;
};
EnvironmentContext.displayName = "EnvironmentContext";
