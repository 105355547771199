/**
 * @flow
 * @relayHash ab1701d51dfff3fa7d81d2db1e6dd2a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type DraftsPageQueryVariables = {|
  showAll?: ?boolean,
  first?: ?number,
  after?: ?string,
  sort?: ?string,
  searchQuery?: ?string,
|};
export type DraftsPageQueryResponse = {|
  +drafts: ?{|
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +drafts: ?{|
      +totalCount: ?number,
      +myCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +savedCopy: ?{|
            +pk: ?number,
            +name: string,
            +restricted: boolean,
            +category: {|
              +name: string
            |},
            +bloc: {|
              +name: string
            |},
            +applicabilityType: ?ApplicabilityType,
          |},
          +document: ?{|
            +pk: ?number
          |},
          +isEditDraft: ?boolean,
          +saverDisplay: ?string,
          +createdDate: ?string,
          +modifiedDate: ?string,
        |}
      |}>,
    |},
  |}
|};
export type DraftsPageQuery = {|
  variables: DraftsPageQueryVariables,
  response: DraftsPageQueryResponse,
|};
*/


/*
query DraftsPageQuery(
  $showAll: Boolean
  $first: Int
  $after: String
  $sort: String
  $searchQuery: String
) {
  drafts(showAll: $showAll, sort: $sort, searchQuery: $searchQuery) {
    error {
      statusCode
      message
    }
    drafts(first: $first, after: $after) {
      totalCount
      myCount
      edges {
        node {
          pk
          savedCopy {
            pk
            name
            restricted
            category {
              name
              id
            }
            bloc {
              name
              id
            }
            applicabilityType
            id
          }
          document {
            pk
            id
          }
          isEditDraft
          saverDisplay
          createdDate
          modifiedDate
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "showAll",
    "variableName": "showAll",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "myCount",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v9 = [
  v7
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isEditDraft",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saverDisplay",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "modifiedDate",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v16 = [
  v7,
  v15
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DraftsPageQuery",
  "id": "9120185337ea02ac8b79135720e64a4b",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DraftsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "drafts",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentDraftsPageType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "drafts",
            "storageKey": null,
            "args": v3,
            "concreteType": "DraftCountableConnection",
            "plural": false,
            "selections": [
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DraftCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DraftDocumentSaveType",
                    "plural": false,
                    "selections": [
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "savedCopy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "RLDatixDocumentType",
                        "plural": false,
                        "selections": [
                          v6,
                          v7,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryType",
                            "plural": false,
                            "selections": v9
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": v9
                          },
                          v10
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "RLDatixDocumentType",
                        "plural": false,
                        "selections": [
                          v6
                        ]
                      },
                      v11,
                      v12,
                      v13,
                      v14
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DraftsPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "drafts",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentDraftsPageType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "drafts",
            "storageKey": null,
            "args": v3,
            "concreteType": "DraftCountableConnection",
            "plural": false,
            "selections": [
              v4,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DraftCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DraftDocumentSaveType",
                    "plural": false,
                    "selections": [
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "savedCopy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "RLDatixDocumentType",
                        "plural": false,
                        "selections": [
                          v6,
                          v7,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryType",
                            "plural": false,
                            "selections": v16
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": v16
                          },
                          v10,
                          v15
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "RLDatixDocumentType",
                        "plural": false,
                        "selections": [
                          v6,
                          v15
                        ]
                      },
                      v11,
                      v12,
                      v13,
                      v14,
                      v15
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query DraftsPageQuery(\n  $showAll: Boolean\n  $first: Int\n  $after: String\n  $sort: String\n  $searchQuery: String\n) {\n  drafts(showAll: $showAll, sort: $sort, searchQuery: $searchQuery) {\n    error {\n      statusCode\n      message\n    }\n    drafts(first: $first, after: $after) {\n      totalCount\n      myCount\n      edges {\n        node {\n          pk\n          savedCopy {\n            pk\n            name\n            restricted\n            category {\n              name\n              id\n            }\n            bloc {\n              name\n              id\n            }\n            applicabilityType\n            id\n          }\n          document {\n            pk\n            id\n          }\n          isEditDraft\n          saverDisplay\n          createdDate\n          modifiedDate\n          id\n        }\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = 'aafb7de6abf5dfd3d55fb9fbaeb27ab9';
module.exports = node;
