import graphql from "babel-plugin-relay/macro";

export const EditPolicyQuery = graphql`
  query EditPolicyQuery(
    $documentPk: Int!
    $continueDraft: Boolean
    $isEditPage: Boolean
  ) {
    documentEdit(pk: $documentPk, continueDraft: $continueDraft) {
      document {
        pk
        name
        ...PolicyEditor_document @arguments(isEditPage: $isEditPage)
      }
      documentSave {
        ...PolicyEditor_documentSave
      }
      editForm {
        name
        author {
          pk
          firstName
          lastName
          title
        }
        category {
          pk
          name
        }
        categoryChoices {
          pk
          name
        }
        flowTemplate {
          pk
          name
        }
        flowTemplateChoices {
          pk
          name
        }
        bloc {
          pk
          name
        }
        blocChoices {
          pk
          name
        }
        html
        activeLifespan
        expirationDate
        isAForm
        restricted
        regulations
        onesourceDocuments {
          oneSourceDocumentId
          catalogNumber
          manufacturerName
          revisionInformation
          viewUrl
        }
      }
      tagForm {
        tags {
          pk
          name
        }
      }
      saveForm {
        hasSavedCopy
        continueDraft
        timestamp
      }
      scheduledEffectiveDateForm {
        effectiveDate
        effectiveDateTimedelta
        effectiveDateAlgorithm
      }
      systemDocumentTemplateForm {
        blocs {
          pk
          name
        }
      }
      alreadyChanged
      error {
        statusCode
        message
      }
      tenantsToRedirect {
        name
        url
      }
      ...PolicyEditor_editorProperties
      redirectUrl
      policyActions
      canLocalize
    }
  }
`;

export default EditPolicyQuery;
