import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import system from "system-components";

import RouterLink from "pstat-design-system/RouterLink";
import { H1 } from "pstat-design-system/text/headings";
import { Text } from "pstat-design-system/text/paragraph";

const PageContainer = system({
  pl: 70,
  pr: 30,
  py: [20, 20, 45]
});

const MessageContainer = system({
  mt: [0, 0, 45],
  width: ["100%", 340]
});

const MessageSpan = system({
  is: Text,
  color: "nav.25",
  display: "block",
  fontSize: 3,
  lineHeight: "25px"
});

export const UnsubscribedFromCommentsPage = props => {
  const { t } = useTranslation();
  const document = props.unsubscribedDocument.document;
  const documentName = document.name;

  return (
    <PageContainer>
      <Flex justifyContent="space-evenly" wrap="wrap">
        <Box flex={1}>
          <Flex justifyContent="center">
            <MessageContainer>
              <H1 color="nav.0" mb={6}>
                {t("documentControl.unsubscribedFromComments.header")}
              </H1>
              <MessageSpan color="nav.25" fontSize={1} mb={6}>
                {t("documentControl.unsubscribedFromComments.confirmation", {
                  documentName
                })}
              </MessageSpan>
              <MessageSpan color="nav.25" fontSize={1} mb={6}>
                {t("documentControl.unsubscribedFromComments.resubscribe")}{" "}
                <RouterLink to={document.link} fontSize={1}>
                  {t(
                    "documentControl.unsubscribedFromComments.resubscribe.url"
                  )}
                </RouterLink>
              </MessageSpan>
            </MessageContainer>
          </Flex>
        </Box>
      </Flex>
    </PageContainer>
  );
};

export default UnsubscribedFromCommentsPage;
