const tocStyles = `
& .autonumber.autonumber-0 {
  counter-increment: toc-autonumber-0;
  counter-set: toc-autonumber-1;
}
& .autonumber.autonumber-1 {
  counter-increment: toc-autonumber-1;
  counter-set: toc-autonumber-2;
}
& .autonumber.autonumber-2 {
  counter-increment: toc-autonumber-2;
  counter-set: toc-autonumber-3;
}
& .autonumber.autonumber-3 {
  counter-increment: toc-autonumber-3;
  counter-set: toc-autonumber-4;
}
& .autonumber.autonumber-4 {
  counter-increment: toc-autonumber-4;
  counter-set: toc-autonumber-5;
}
& .autonumber.autonumber-5 {
  counter-increment: toc-autonumber-5;
}
@supports not (counter-set: toc-autonumber-0) {
  & .autonumber.autonumber-0 {
    counter-reset: toc-autonumber-1;
  }
  & .autonumber.autonumber-1 {
    counter-reset: toc-autonumber-2;
  }
  & .autonumber.autonumber-2 {
    counter-reset: toc-autonumber-3;
  }
  & .autonumber.autonumber-3 {
    counter-reset: toc-autonumber-4;
  }
  & .autonumber.autonumber-4 {
    counter-reset: toc-autonumber-5;
  }
}

& .autonumber.autonumber-0.autonumber-restart {
  counter-set: toc-autonumber-0 1;
}
& .autonumber.autonumber-1.autonumber-restart {
  counter-set: toc-autonumber-1 1;
}
& .autonumber.autonumber-2.autonumber-restart {
  counter-set: toc-autonumber-2 1;
}
& .autonumber.autonumber-3.autonumber-restart {
  counter-set: toc-autonumber-3 1;
}
& .autonumber.autonumber-4.autonumber-restart {
  counter-set: toc-autonumber-4 1;
}
& .autonumber.autonumber-5.autonumber-restart {
  counter-set: toc-autonumber-5 1;
}
@supports not (counter-set: toc-autonumber-0) {
  & .autonumber.autonumber-0.autonumber-restart {
    counter-reset: toc-autonumber-0;
  }
  & .autonumber.autonumber-1.autonumber-restart {
    counter-reset: toc-autonumber-1;
  }
  & .autonumber.autonumber-2.autonumber-restart {
    counter-reset: toc-autonumber-2;
  }
  & .autonumber.autonumber-3.autonumber-restart {
    counter-reset: toc-autonumber-3;
  }
  & .autonumber.autonumber-4.autonumber-restart {
    counter-reset: toc-autonumber-4;
  }
  & .autonumber.autonumber-5.autonumber-restart {
    counter-reset: toc-autonumber-5;
  }
}

/* Counter Content
* N = number
* A = upper-alpha
* a = lower-alpha
* R = upper-roman
* r = lower-roman
*/

& .autonumber:before {
  white-space: pre-wrap;
}

/* Default */
& .autonumber.autonumber-0:before {
  content: counter(toc-autonumber-0) ". ";
}
& .autonumber.autonumber-1:before {
  content: counter(toc-autonumber-0) "." counter(toc-autonumber-1) ". ";
}
& .autonumber.autonumber-2:before {
  content: counter(toc-autonumber-0) "." counter(toc-autonumber-1) "."
    counter(toc-autonumber-2) ". ";
}
& .autonumber.autonumber-3:before {
  content: counter(toc-autonumber-0) "." counter(toc-autonumber-1) "."
    counter(toc-autonumber-2) "." counter(toc-autonumber-3) ". ";
}
& .autonumber.autonumber-4:before {
  content: counter(toc-autonumber-0) "." counter(toc-autonumber-1) "."
    counter(toc-autonumber-2) "." counter(toc-autonumber-3) "."
    counter(toc-autonumber-4) ". ";
}
& .autonumber.autonumber-5:before {
  content: counter(toc-autonumber-0) "." counter(toc-autonumber-1) "."
    counter(toc-autonumber-2) "." counter(toc-autonumber-3) "."
    counter(toc-autonumber-4) "." counter(toc-autonumber-5) ". ";
}

/* N */

& .autonumber.autonumber-0.autonumber-N:before {
  content: counter(toc-autonumber-0) ". ";
}
& .autonumber.autonumber-1.autonumber-N:before {
  content: counter(toc-autonumber-1) ". ";
}
& .autonumber.autonumber-2.autonumber-N:before {
  content: counter(toc-autonumber-2) ". ";
}
& .autonumber.autonumber-3.autonumber-N:before {
  content: counter(toc-autonumber-3) ". ";
}
& .autonumber.autonumber-4.autonumber-N:before {
  content: counter(toc-autonumber-4) ". ";
}
& .autonumber.autonumber-5.autonumber-N:before {
  content: counter(toc-autonumber-5) ". ";
}

/* A */

& .autonumber.autonumber-0.autonumber-A:before {
  content: counter(toc-autonumber-0, upper-alpha) ". ";
}
& .autonumber.autonumber-1.autonumber-A:before {
  content: counter(toc-autonumber-1, upper-alpha) ". ";
}
& .autonumber.autonumber-2.autonumber-A:before {
  content: counter(toc-autonumber-2, upper-alpha) ". ";
}
& .autonumber.autonumber-3.autonumber-A:before {
  content: counter(toc-autonumber-3, upper-alpha) ". ";
}
& .autonumber.autonumber-4.autonumber-A:before {
  content: counter(toc-autonumber-4, upper-alpha) ". ";
}
& .autonumber.autonumber-5.autonumber-A:before {
  content: counter(toc-autonumber-5, upper-alpha) ". ";
}

/* a */

& .autonumber.autonumber-0.autonumber-a:before {
  content: counter(toc-autonumber-0, lower-alpha) ". ";
}
& .autonumber.autonumber-1.autonumber-a:before {
  content: counter(toc-autonumber-1, lower-alpha) ". ";
}
& .autonumber.autonumber-2.autonumber-a:before {
  content: counter(toc-autonumber-2, lower-alpha) ". ";
}
& .autonumber.autonumber-3.autonumber-a:before {
  content: counter(toc-autonumber-3, lower-alpha) ". ";
}
& .autonumber.autonumber-4.autonumber-a:before {
  content: counter(toc-autonumber-4, lower-alpha) ". ";
}
& .autonumber.autonumber-5.autonumber-a:before {
  content: counter(toc-autonumber-5, lower-alpha) ". ";
}

/* R */

& .autonumber.autonumber-0.autonumber-R:before {
  content: counter(toc-autonumber-0, upper-roman) ". ";
}
& .autonumber.autonumber-1.autonumber-R:before {
  content: counter(toc-autonumber-1, upper-roman) ". ";
}
& .autonumber.autonumber-2.autonumber-R:before {
  content: counter(toc-autonumber-2, upper-roman) ". ";
}
& .autonumber.autonumber-3.autonumber-R:before {
  content: counter(toc-autonumber-3, upper-roman) ". ";
}
& .autonumber.autonumber-4.autonumber-R:before {
  content: counter(toc-autonumber-4, upper-roman) ". ";
}
& .autonumber.autonumber-5.autonumber-R:before {
  content: counter(toc-autonumber-5, upper-roman) ". ";
}

/* r */

& .autonumber.autonumber-0.autonumber-r:before {
  content: counter(toc-autonumber-0, lower-roman) ". ";
}
& .autonumber.autonumber-1.autonumber-r:before {
  content: counter(toc-autonumber-1, lower-roman) ". ";
}
& .autonumber.autonumber-2.autonumber-r:before {
  content: counter(toc-autonumber-2, lower-roman) ". ";
}
& .autonumber.autonumber-3.autonumber-r:before {
  content: counter(toc-autonumber-3, lower-roman) ". ";
}
& .autonumber.autonumber-4.autonumber-r:before {
  content: counter(toc-autonumber-4, lower-roman) ". ";
}
& .autonumber.autonumber-5.autonumber-r:before {
  content: counter(toc-autonumber-5, lower-roman) ". ";
}`;

export default tocStyles;
