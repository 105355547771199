import graphql from "babel-plugin-relay/macro";
import { Box, Flex } from "grid-styled";
import queryString from "query-string";
import { Fragment, memo, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer, fetchQuery } from "react-relay";

import AuthenticateWithOnesource, {
  ONESOURCE_EXPANDED
} from "./AuthenticateWithOnesource";
import CatalogSearchInput from "./CatalogSearchInput";
import DocumentsList from "./DocumentsList";
import ManufacturerInput, { ManufacturersQuery } from "./ManufacturerInput";
import { useOnesourceDocuments } from "./OneSourceContext";
import SearchResults from "./SearchResults";

import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";
import { ExpandablePanel } from "pstat-design-system/Panel";
import ExternalLink from "pstat-design-system/ExternalLink";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import { EnvironmentContext } from "pstat-anywhere/context_providers/EnvironmentContext";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const query = graphql`
  query onesourceQuery(
    $manufacturer: String
    $catalogNumber: String
    $documentId: String
  ) {
    onesourceDocumentsSearch(
      manufacturer: $manufacturer
      catalogNumber: $catalogNumber
      documentId: $documentId
    ) {
      data {
        documentId
        documentName
        manufacturerId
        manufacturerName
        instrumentCategoryNumber
        revisionInformation
        viewUrl
      }
      resultsLink
      error {
        statusCode
        message
      }
      totalCount
    }
  }
`;

const POLICYSTAT_PREFIX = "policystat:";

const OneSourceSection = withViewer(
  withTenantContext(({ onChangesMade, tenant, viewer }) => {
    const { t } = useTranslation();
    const environment = useContext(EnvironmentContext);
    const parsed = queryString.parse(window.location.search);
    const expanded = !!parsed[ONESOURCE_EXPANDED];
    const [searchExpanded, setSearchExpanded] = useState(expanded);
    const integrationEnabled = tenant?.onesourceIntegrationEnabled;
    const {
      onesourceDocuments,
      setOnesourceDocuments
    } = useOnesourceDocuments();

    // the handleAddDocument and handleRemoveDocument callbacks only get set on the table
    // 'Add' and 'Remove' buttons when the table is first rendered. This results in the
    // onesourceDocuments state becoming stale after changes are made to it. These functions
    // still reference the old state that was set when they were created instead of the
    // new value. We can get the latest state into these functions by having them reference
    // the onesourceDocumentsRef, which will mirror the state changes. The difference is
    // the ref is a reference to the same object across re-renders and state changes so
    // these functions can reference the updated state even though they were created before
    // the state changed.
    const onesourceDocumentsRef = useRef(onesourceDocuments);
    useEffect(() => {
      onesourceDocumentsRef.current = onesourceDocuments;
    }, [onesourceDocuments]);

    const handleAddDocument = document => {
      const onesourceDocuments = onesourceDocumentsRef.current;
      const documentIdToAdd = document.oneSourceDocumentId;
      for (let i = 0; i < onesourceDocuments.length; i++) {
        if (onesourceDocuments[i].oneSourceDocumentId === documentIdToAdd) {
          // TODO: add some sort of message?
          console.log("bail out, we see this one already included");
          return;
        }
      }
      setOnesourceDocuments([...onesourceDocuments, document]);
      onChangesMade();
    };
    const handleRemoveDocument = documentId => {
      const onesourceDocuments = onesourceDocumentsRef.current;
      const updatedOnesourceDocuments = onesourceDocuments.filter(
        document => document.oneSourceDocumentId !== documentId
      );
      setOnesourceDocuments(updatedOnesourceDocuments);
      onChangesMade();
    };
    return (
      <Fragment>
        {integrationEnabled && (
          <Fragment>
            <QueryRenderer
              query={ManufacturersQuery}
              environment={environment}
              render={({ props, error }) => {
                return (
                  <ExpandablePanel
                    id="onesource"
                    expandedExternal={searchExpanded}
                    onExpandedChanged={setSearchExpanded}
                    headerText={t("onesource.edit.sectionHeading")}
                    hideHeaderBorder={true}
                    wrapperProps={{ mb: 0, noBottomRadius: true }}
                    headerChildren={() => (
                      <Flex justifyContent="end" flex={1}>
                        <Box>
                          <StyledFontAwesomeIcon
                            icon={["far", "question-circle"]}
                            color="secondary.0"
                            tabIndex="0"
                            data-tip
                            data-for="onesource-search-tooltip"
                            data-event={HOVER_EVENTS}
                            data-event-off={HOVER_EVENTS_OFF}
                            mr={0}
                          />
                          <ExternalLink
                            target="_blank"
                            href="https://search.onesourcedocs.com/"
                          >
                            {t("onesource.edit.header.linkText")}
                          </ExternalLink>
                          <Tooltip name="onesource-search-tooltip">
                            {t("onesource.edit.header.tooltip")}
                          </Tooltip>
                        </Box>
                      </Flex>
                    )}
                  >
                    {viewer.hasOnesourceToken ? (
                      <OneSourceSearch
                        environment={environment}
                        onAddDocument={handleAddDocument}
                        manufacturersData={props?.manufacturersData}
                        manufacturersError={error}
                      />
                    ) : (
                      <AuthenticateWithOnesource environment={environment} />
                    )}
                  </ExpandablePanel>
                );
              }}
            />
            <DocumentsList
              onesourceDocuments={onesourceDocuments}
              onRemoveDocument={handleRemoveDocument}
            />
          </Fragment>
        )}
      </Fragment>
    );
  })
);

export const OneSourceSearch = ({
  environment,
  onAddDocument,
  manufacturersData,
  manufacturersError
}) => {
  const [manufacturer, setManufacturer] = useState(null);
  const [catalogSearch, setCatalogSearch] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [resultsLink, setResultsLink] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const handleCatalogSearchChange = event => {
    setCatalogSearch(event.target.value);
  };
  const handleSearch = () => {
    const pasteFromOnesource = catalogSearch.startsWith(POLICYSTAT_PREFIX);
    const variables = {};
    if (pasteFromOnesource) {
      variables["documentId"] = catalogSearch.substring(
        POLICYSTAT_PREFIX.length
      );
    } else {
      variables["catalogNumber"] = catalogSearch;
      if (manufacturer) {
        variables["manufacturer"] = manufacturer;
      }
    }

    setSearchLoading(true);
    fetchQuery(environment, query, variables).then(data => {
      setSearchLoading(false);
      const results = data?.onesourceDocumentsSearch?.data;
      const error = data?.onesourceDocumentsSearch?.error;

      if (error?.statusCode) {
        setSearchError(true);
        setSearchResults(null);
        return;
      }
      if (results) {
        setSearchResults(cleanOnesourceSearchResults(results));
        setResultsLink(data?.onesourceDocumentsSearch?.resultsLink);
        setTotalCount(data?.onesourceDocumentsSearch?.totalCount);
        setSearchError(false);
      }
    });
  };
  return (
    <Fragment>
      <ManufacturerInput
        onManufacturerChange={manufacturer => setManufacturer(manufacturer)}
        manufacturersData={manufacturersData}
        error={manufacturersError}
      />
      <CatalogSearchInput
        catalogSearch={catalogSearch}
        onChange={handleCatalogSearchChange}
        onSearch={handleSearch}
        loading={searchLoading}
      />
      {(searchResults || searchError) && (
        <SearchResults
          results={searchResults}
          error={searchError}
          onAddDocument={onAddDocument}
          resultsLink={resultsLink}
          totalCount={totalCount}
        />
      )}
    </Fragment>
  );
};

export default memo(OneSourceSection);

const cleanOnesourceSearchResults = documents => {
  return documents.map(document => {
    return {
      oneSourceDocumentId: document.documentId,
      name: document.documentName,
      catalogNumber: document.instrumentCategoryNumber,
      manufacturerName: document.manufacturerName,
      manufacturerId: document.manufacturerId,
      viewUrl: document.viewUrl,
      revisionInformation: document.revisionInformation
    };
  });
};

export const getOnesourceDocumentInputData = onesourceDocuments => {
  // this removes unnecessary fields from the mutation input variables
  return onesourceDocuments.map(document => {
    const {
      oneSourceDocumentId,
      catalogNumber,
      manufacturerId,
      manufacturerName,
      revisionInformation,
      name
    } = document;
    const data = {
      oneSourceDocumentId,
      catalogNumber,
      manufacturerName,
      manufacturerId,
      name
    };
    return {
      ...data,
      ...(revisionInformation && { revisionInformation })
    };
  });
};
