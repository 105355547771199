/**
 * @flow
 * @relayHash 9759298303ce986cc66983c4bcf753e7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type MyPoliciesToAcknowledgeReportQueryVariables = {|
  first?: ?number,
  sort?: ?string,
  after?: ?string,
|};
export type MyPoliciesToAcknowledgeReportQueryResponse = {|
  +myDocumentsToAcknowledge: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +totalAcknowledged: ?number,
        +totalAssigned: ?number,
        +waitTime: ?string,
        +line: ?{|
          +pk: ?number,
          +document: ?{|
            +pk: ?number,
            +name: string,
            +hasAttachments: ?boolean,
            +effectiveDate: ?string,
            +legacyEffectiveDate: ?string,
            +restricted: boolean,
            +bloc: {|
              +name: string
            |},
            +applicabilityType: ?ApplicabilityType,
          |},
        |},
      |}
    |}>,
  |}
|};
export type MyPoliciesToAcknowledgeReportQuery = {|
  variables: MyPoliciesToAcknowledgeReportQueryVariables,
  response: MyPoliciesToAcknowledgeReportQueryResponse,
|};
*/


/*
query MyPoliciesToAcknowledgeReportQuery(
  $first: Int
  $sort: String
  $after: String
) {
  myDocumentsToAcknowledge(first: $first, sort: $sort, after: $after) {
    totalCount
    edges {
      node {
        totalAcknowledged
        totalAssigned
        waitTime
        line {
          pk
          document {
            pk
            name
            hasAttachments
            effectiveDate
            legacyEffectiveDate
            restricted
            bloc {
              name
              id
            }
            applicabilityType
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAcknowledged",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAssigned",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "waitTime",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MyPoliciesToAcknowledgeReportQuery",
  "id": "dbc5d3dd2813649854bbb1cad64bc8d1",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MyPoliciesToAcknowledgeReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myDocumentsToAcknowledge",
        "storageKey": null,
        "args": v1,
        "concreteType": "CountableConnectionAcknowledgmentConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentDocumentLineType",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentLineType",
                    "plural": false,
                    "selections": [
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v6,
                          v7,
                          v8,
                          v9,
                          v10,
                          v11,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": [
                              v7
                            ]
                          },
                          v12
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyPoliciesToAcknowledgeReportQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myDocumentsToAcknowledge",
        "storageKey": null,
        "args": v1,
        "concreteType": "CountableConnectionAcknowledgmentConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentDocumentLineType",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentLineType",
                    "plural": false,
                    "selections": [
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v6,
                          v7,
                          v8,
                          v9,
                          v10,
                          v11,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": [
                              v7,
                              v13
                            ]
                          },
                          v12,
                          v13
                        ]
                      },
                      v13
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query MyPoliciesToAcknowledgeReportQuery(\n  $first: Int\n  $sort: String\n  $after: String\n) {\n  myDocumentsToAcknowledge(first: $first, sort: $sort, after: $after) {\n    totalCount\n    edges {\n      node {\n        totalAcknowledged\n        totalAssigned\n        waitTime\n        line {\n          pk\n          document {\n            pk\n            name\n            hasAttachments\n            effectiveDate\n            legacyEffectiveDate\n            restricted\n            bloc {\n              name\n              id\n            }\n            applicabilityType\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = '2c95035236ee4c88187413e40502200c';
module.exports = node;
