/**
 * @flow
 * @relayHash 9ec2fac897c1745024630986b585e9ed
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OneSourceDocumentInputType = {
  oneSourceDocumentId?: ?string,
  catalogNumber?: ?string,
  manufacturerName?: ?string,
  manufacturerId?: ?string,
  oneSourceUpdated?: ?string,
  revisionInformation?: ?string,
  name?: ?string,
};
export type OverrideMutationVariables = {|
  documentPk: number,
  tagInput?: ?$ReadOnlyArray<?string>,
  effectiveDate?: ?any,
  effectiveDateTimedelta?: ?number,
  effectiveDateAlgorithm?: ?string,
  originationDate?: ?any,
  approvalDate?: ?any,
  legacyEffectiveDate?: ?any,
  name?: ?string,
  html?: ?string,
  author?: ?number,
  category?: ?number,
  bloc?: ?number,
  flowTemplate?: ?number,
  activeLifespan?: ?number,
  expirationDate?: ?any,
  restricted?: ?boolean,
  isAForm?: ?boolean,
  actionComment?: ?string,
  regulations?: ?$ReadOnlyArray<?number>,
  approver?: ?number,
  approvedDate?: ?any,
  revisionDates?: ?$ReadOnlyArray<?any>,
  onesourceDocuments?: ?$ReadOnlyArray<?OneSourceDocumentInputType>,
  locations?: ?$ReadOnlyArray<?number>,
|};
export type OverrideMutationResponse = {|
  +override: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
  |}
|};
export type OverrideMutation = {|
  variables: OverrideMutationVariables,
  response: OverrideMutationResponse,
|};
*/


/*
mutation OverrideMutation(
  $documentPk: Int!
  $tagInput: [String]
  $effectiveDate: PStatDate
  $effectiveDateTimedelta: Int
  $effectiveDateAlgorithm: String
  $originationDate: PStatDate
  $approvalDate: PStatDate
  $legacyEffectiveDate: PStatDate
  $name: String
  $html: String
  $author: Int
  $category: Int
  $bloc: Int
  $flowTemplate: Int
  $activeLifespan: Int
  $expirationDate: PStatDate
  $restricted: Boolean
  $isAForm: Boolean
  $actionComment: String
  $regulations: [Int]
  $approver: Int
  $approvedDate: PStatDate
  $revisionDates: [Date]
  $onesourceDocuments: [OneSourceDocumentInputType]
  $locations: [Int]
) {
  override(documentPk: $documentPk, tagInput: $tagInput, effectiveDate: $effectiveDate, effectiveDateTimedelta: $effectiveDateTimedelta, effectiveDateAlgorithm: $effectiveDateAlgorithm, originationDate: $originationDate, approvalDate: $approvalDate, legacyEffectiveDate: $legacyEffectiveDate, approver: $approver, approvedDate: $approvedDate, name: $name, html: $html, author: $author, category: $category, bloc: $bloc, flowTemplate: $flowTemplate, activeLifespan: $activeLifespan, expirationDate: $expirationDate, restricted: $restricted, isAForm: $isAForm, actionComment: $actionComment, regulations: $regulations, revisionDates: $revisionDates, onesourceDocuments: $onesourceDocuments, locations: $locations) {
    ok
    reasons
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tagInput",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDateTimedelta",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDateAlgorithm",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "originationDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approvalDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "legacyEffectiveDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "html",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "author",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bloc",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flowTemplate",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "activeLifespan",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expirationDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "restricted",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isAForm",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "actionComment",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "regulations",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approver",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approvedDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "revisionDates",
    "type": "[Date]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onesourceDocuments",
    "type": "[OneSourceDocumentInputType]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "locations",
    "type": "[Int]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "override",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "actionComment",
        "variableName": "actionComment",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "activeLifespan",
        "variableName": "activeLifespan",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "approvalDate",
        "variableName": "approvalDate",
        "type": "PStatDate"
      },
      {
        "kind": "Variable",
        "name": "approvedDate",
        "variableName": "approvedDate",
        "type": "PStatDate"
      },
      {
        "kind": "Variable",
        "name": "approver",
        "variableName": "approver",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "author",
        "variableName": "author",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "bloc",
        "variableName": "bloc",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "category",
        "variableName": "category",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "documentPk",
        "variableName": "documentPk",
        "type": "Int!"
      },
      {
        "kind": "Variable",
        "name": "effectiveDate",
        "variableName": "effectiveDate",
        "type": "PStatDate"
      },
      {
        "kind": "Variable",
        "name": "effectiveDateAlgorithm",
        "variableName": "effectiveDateAlgorithm",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "effectiveDateTimedelta",
        "variableName": "effectiveDateTimedelta",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "expirationDate",
        "variableName": "expirationDate",
        "type": "PStatDate"
      },
      {
        "kind": "Variable",
        "name": "flowTemplate",
        "variableName": "flowTemplate",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "html",
        "variableName": "html",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "isAForm",
        "variableName": "isAForm",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "legacyEffectiveDate",
        "variableName": "legacyEffectiveDate",
        "type": "PStatDate"
      },
      {
        "kind": "Variable",
        "name": "locations",
        "variableName": "locations",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "onesourceDocuments",
        "variableName": "onesourceDocuments",
        "type": "[OneSourceDocumentInputType]"
      },
      {
        "kind": "Variable",
        "name": "originationDate",
        "variableName": "originationDate",
        "type": "PStatDate"
      },
      {
        "kind": "Variable",
        "name": "regulations",
        "variableName": "regulations",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "restricted",
        "variableName": "restricted",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "revisionDates",
        "variableName": "revisionDates",
        "type": "[Date]"
      },
      {
        "kind": "Variable",
        "name": "tagInput",
        "variableName": "tagInput",
        "type": "[String]"
      }
    ],
    "concreteType": "OverrideDocument",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "OverrideMutation",
  "id": "dc3e51d83c6f3609677ea17b12056be9",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "OverrideMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "OverrideMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation OverrideMutation(\n  $documentPk: Int!\n  $tagInput: [String]\n  $effectiveDate: PStatDate\n  $effectiveDateTimedelta: Int\n  $effectiveDateAlgorithm: String\n  $originationDate: PStatDate\n  $approvalDate: PStatDate\n  $legacyEffectiveDate: PStatDate\n  $name: String\n  $html: String\n  $author: Int\n  $category: Int\n  $bloc: Int\n  $flowTemplate: Int\n  $activeLifespan: Int\n  $expirationDate: PStatDate\n  $restricted: Boolean\n  $isAForm: Boolean\n  $actionComment: String\n  $regulations: [Int]\n  $approver: Int\n  $approvedDate: PStatDate\n  $revisionDates: [Date]\n  $onesourceDocuments: [OneSourceDocumentInputType]\n  $locations: [Int]\n) {\n  override(documentPk: $documentPk, tagInput: $tagInput, effectiveDate: $effectiveDate, effectiveDateTimedelta: $effectiveDateTimedelta, effectiveDateAlgorithm: $effectiveDateAlgorithm, originationDate: $originationDate, approvalDate: $approvalDate, legacyEffectiveDate: $legacyEffectiveDate, approver: $approver, approvedDate: $approvedDate, name: $name, html: $html, author: $author, category: $category, bloc: $bloc, flowTemplate: $flowTemplate, activeLifespan: $activeLifespan, expirationDate: $expirationDate, restricted: $restricted, isAForm: $isAForm, actionComment: $actionComment, regulations: $regulations, revisionDates: $revisionDates, onesourceDocuments: $onesourceDocuments, locations: $locations) {\n    ok\n    reasons\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = '706ed42559f10f242104cae7e3cc8d06';
module.exports = node;
