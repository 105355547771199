/**
 * @flow
 * @relayHash b0072a96660a67f9648e01c5ae43313c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDocumentInput = {
  documentPk: number,
  actionComment: string,
  clientMutationId?: ?string,
};
export type DeletePolicyMutationVariables = {|
  input: DeleteDocumentInput
|};
export type DeletePolicyMutationResponse = {|
  +deleteDocument: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type DeletePolicyMutation = {|
  variables: DeletePolicyMutationVariables,
  response: DeletePolicyMutationResponse,
|};
*/


/*
mutation DeletePolicyMutation(
  $input: DeleteDocumentInput!
) {
  deleteDocument(input: $input) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteDocumentInput!"
      }
    ],
    "concreteType": "DeleteDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeletePolicyMutation",
  "id": "dcf34ae17d48e4b744c7600c8215b50e",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeletePolicyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeletePolicyMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation DeletePolicyMutation(\n  $input: DeleteDocumentInput!\n) {\n  deleteDocument(input: $input) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = 'c4096c921951be8a4b82542ccc25ff11';
module.exports = node;
