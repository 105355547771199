import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import { ActionPanel } from "./BlocManagementPage";
import BlocTenantsPageQuery from "./BlocTenantsPageQuery";
import EditBlocModal from "./EditBlocModal";

import { Button } from "pstat-design-system/shared/Button";
import Breadcrumb from "pstat-design-system/Breadcrumb";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { environment } from "pstat-anywhere/relay";
import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";

const BlocManageBreadcrumb = props => {
  const { t } = useTranslation();
  return (
    <Breadcrumb
      id="back-to-managment-page-link"
      text={t("bloc.manage.heading")}
      to={`${getRootUrl()}/bloc`}
    />
  );
};

const EditBlocButton = ({
  isEditable,
  editModalIsOpen,
  onModalOpen,
  onEditModalClose,
  currentBlocName,
  currentDocumentSettingsPk,
  documentSettings,
  onViewOrEditSitesClicked,
  selectedTenantPks,
  blocPk,
  selectedTenantsChanged,
  router
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Button
        id="edit-bloc-button"
        buttonStyle="secondary"
        disabled={!isEditable}
        onClick={onModalOpen}
      >
        {t("bloc.details.editButton")}
      </Button>
      {isEditable && (
        <EditBlocModal
          isOpen={editModalIsOpen}
          onClose={onEditModalClose}
          currentBlocName={currentBlocName}
          currentDocumentSettingsPk={currentDocumentSettingsPk}
          documentSettings={documentSettings}
          isNewBloc={false}
          onViewOrEditSitesClicked={onViewOrEditSitesClicked}
          selectedTenantPks={selectedTenantPks}
          blocPk={blocPk}
          selectedTenantsChanged={selectedTenantsChanged}
          router={router}
        />
      )}
    </Fragment>
  );
};

const EditSites = ({
  route,
  router,
  location,
  initiallySelectedPks,
  selectedTenantPks,
  blocName,
  onSelectTenants,
  selectedTenantsChanged
}) => {
  const { t } = useTranslation();
  const existingBlocsCache = useRef({});
  const tabs = [
    {
      id: "bloc_details",
      textKey: "bloc.details.tab",
      path: null
    }
  ];
  return (
    <QueryRenderer
      environment={environment}
      query={BlocTenantsPageQuery}
      variables={{ sort: location?.query?.sort }}
      render={({ props }) => {
        return (
          <AdvancedReportPage
            variables={{}}
            data={props?.canAdminTenants?.tenants}
            route={route}
            router={router}
            location={location}
            tabs={tabs}
            headerText={t("bloc.details.heading", {
              blocName
            })}
            noContentMessage={t("bloc.manage.emptyMessage")}
            showTabsPanel={true}
            tableType={"bloc_tenants"}
            showPagination={false}
            customOnRow={() => {}}
            frozenColumnTable={true}
            showActionButtonPanel={true}
            renderActionPanel={selectedTenantPks => (
              <ActionPanel
                initiallySelectedPks={initiallySelectedPks}
                selectedTenantPks={selectedTenantPks}
                existingBlocsCache={existingBlocsCache.current}
                onSelectTenants={onSelectTenants}
                blocName={blocName}
                selectedTenantsChanged={selectedTenantsChanged}
              />
            )}
            singularSelectedActionsLabel={t("bloc.sites.singularSitesLabel")}
            pluralSelectedActionsLabel={t("bloc.sites.pluralSitesLabel")}
            initiallySelectedPks={selectedTenantPks}
          />
        );
      }}
    />
  );
};

const BlocDetailPage = ({
  location,
  route,
  router,
  blocDetails,
  canAdminTenants,
  params
}) => {
  const { t } = useTranslation();
  const [editModalIsOpen, setEditModalIsOpen] = useState(params?.edit);
  const [editSitesOpen, setEditSitesOpen] = useState(false);
  const [selectedTenantPks, setSelectedTenantPks] = useState([]);
  const selectedTenantsChanged = (selectedTenantPks, initiallySelectedPks) => {
    if (selectedTenantPks.length !== initiallySelectedPks.length) {
      return true;
    }
    const selectedTenantPksCopy = [...selectedTenantPks].sort();
    const initiallySelectedPksCopy = [...initiallySelectedPks].sort();
    for (let i = 0; i < selectedTenantPksCopy.length; i++) {
      if (selectedTenantPksCopy[i] !== initiallySelectedPksCopy[i]) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    if (params?.edit) {
      setEditModalIsOpen(params.edit);
    }
  }, [params?.edit]);
  useEffect(() => {
    const tenants = blocDetails?.bloc?.tenants;
    if (tenants) {
      setSelectedTenantPks(tenants.map(tenant => tenant.pk));
    }
  }, [blocDetails?.bloc?.tenants]);
  const tabs = [
    {
      id: "bloc_details",
      textKey: "bloc.details.tab",
      path: null
    }
  ];
  const bloc = blocDetails?.bloc;
  const isEditable = bloc?.isEditable;
  const initiallySelectedPks = bloc?.tenants?.map(tenant => tenant.pk);
  if (editSitesOpen) {
    return (
      <EditSites
        route={route}
        router={router}
        location={location}
        initiallySelectedPks={initiallySelectedPks}
        selectedTenantPks={selectedTenantPks}
        blocName={bloc.name}
        onSelectTenants={selectedTenantPks => {
          setEditSitesOpen(false);
          setSelectedTenantPks(selectedTenantPks);
        }}
        selectedTenantsChanged={selectedTenantPks =>
          selectedTenantsChanged(selectedTenantPks, initiallySelectedPks)
        }
      />
    );
  }

  return (
    <AdvancedReportPage
      data={bloc?.tenants}
      tabs={tabs}
      route={route}
      router={router}
      location={location}
      headerText={t("bloc.details.heading", {
        blocName: bloc?.name
      })}
      renderHeader={() => (
        <EditBlocButton
          isEditable={isEditable}
          editModalIsOpen={editModalIsOpen}
          onModalOpen={() => setEditModalIsOpen(true)}
          onEditModalClose={() => setEditModalIsOpen(false)}
          currentBlocName={bloc?.name}
          documentSettings={canAdminTenants?.documentSettings}
          currentDocumentSettingsPk={bloc?.documentSettings?.pk || null}
          onViewOrEditSitesClicked={() => setEditSitesOpen(true)}
          selectedTenantPks={selectedTenantPks}
          blocPk={bloc?.pk}
          selectedTenantsChanged={selectedTenantPks =>
            selectedTenantsChanged(selectedTenantPks, initiallySelectedPks)
          }
          router={router}
        />
      )}
      noContentMessage={t("bloc.manage.emptyMessage")}
      showTabsPanel={true}
      tableType="bloc_details"
      showPagination={false}
      customOnRow={() => {}}
      frozenColumnTable={true}
      BreadcrumbComponent={BlocManageBreadcrumb}
      otherTableProps={{ blocIsEditable: isEditable }}
    />
  );
};

export default BlocDetailPage;
