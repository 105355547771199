import graphql from "babel-plugin-relay/macro";

export const NotificationSettingsQuery = graphql`
  query NoticeSettingsQuery($userPk: Int) {
    noticeSettings(userPk: $userPk) {
      settings {
        noticeType {
          pk
          whenSent
          display
          description
        }
        noticeMedium
        send
      }
      error {
        statusCode
        message
      }
    }
  }
`;
