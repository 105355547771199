/**
 * @flow
 * @relayHash 2eb0463e90ad04ad6aadd5c569658083
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type documentTimelineQueryVariables = {|
  documentPk: number
|};
export type documentTimelineQueryResponse = {|
  +documentView: ?{|
    +timeline: ?$ReadOnlyArray<?any>
  |}
|};
export type documentTimelineQuery = {|
  variables: documentTimelineQueryVariables,
  response: documentTimelineQueryResponse,
|};
*/


/*
query documentTimelineQuery(
  $documentPk: Int!
) {
  documentView(pk: $documentPk, showChanges: true, latest: false, ignoreLatestRedirect: true) {
    timeline
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentView",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "ignoreLatestRedirect",
        "value": true,
        "type": "Boolean"
      },
      {
        "kind": "Literal",
        "name": "latest",
        "value": false,
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "pk",
        "variableName": "documentPk",
        "type": "Int!"
      },
      {
        "kind": "Literal",
        "name": "showChanges",
        "value": true,
        "type": "Boolean"
      }
    ],
    "concreteType": "DocumentViewType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "timeline",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "documentTimelineQuery",
  "id": "4978a81120fe70987ab67262ccbbbd49",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "documentTimelineQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "documentTimelineQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query documentTimelineQuery(\n  $documentPk: Int!\n) {\n  documentView(pk: $documentPk, showChanges: true, latest: false, ignoreLatestRedirect: true) {\n    timeline\n  }\n}\n";
(node/*: any*/).hash = '81f6d4bbfdca50f094648510bc4e409c';
module.exports = node;
