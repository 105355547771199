import { Route } from "found";

import ImportFromWordPage from "./ImportFromWord";
import ImportFromWordQuery from "./ImportFromWordQuery";

import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";
import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";
import { checkForRedirect } from "pstat-anywhere/utils/urls";


const routes = (
  <Route path="build">
    <Route
      path=""
      query={ImportFromWordQuery}
      Component={props => (
        <PageTitleWrapper titleKey="documentBuild.documentTitle">
          <ImportFromWordPage {...props} />
        </PageTitleWrapper>
      )}
      data={{
        routeName: "document_build"
      }}
      render={({ Component, props }) => {
        checkForRedirect(props, "documentBuild");
        return (
          <ErrorCheckWrapper queryName="documentBuild" {...props}>
            <Component {...props} />
          </ErrorCheckWrapper>
        );
      }}
    />
  </Route>
);

export default routes;
