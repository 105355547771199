import system from "system-components";

export const TH = system(
  {
    is: "th",
    py: 2,
    pl: 1,
    textAlign: "left",
    borderBottom: 1,
    borderColor: "nav.80"
  },
  "space",
  "borderWidth"
);

// the th for the column that is frozen
export const FrozenTH = system({
  is: TH,
  position: "absolute",
  minWidth: 150,
  left: 0,
  top: "auto",
  borderBottom: 0
});

// the th for all other columns in a frozen column table
export const FrozenTableTH = system({
  is: TH,
  borderBottom: 0
}).extend`
  vertical-align: top; /* needed for IE11 to align the frozen column vertically with the rest of the header row */
`;

export default TH;
