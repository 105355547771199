import PolicyActionCommentSection from "../policy_action_comment_section/PolicyActionCommentSection";
import ViewPolicy from "../view_policy/ViewPolicy";

import { deletePolicy } from "./DeletePolicyMutation";

function DeletePolicySection({ documentPk, location, router }) {
  return (
    <PolicyActionCommentSection
      documentPk={documentPk}
      policyActionMutation={deletePolicy}
      translationsKey="delete"
      location={location}
      router={router}
    />
  );
}

export default function DeletePolicyPage({ ...props }) {
  return (
    <ViewPolicy
      {...props}
      hasPolicyActionComment={true}
      PolicyActionCommentSection={DeletePolicySection}
      displayConfig={{
        showHistoryPanel: false,
        showCommentsPanel: false,
        showCloneAction: false
      }}
    />
  );
}
