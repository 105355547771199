import mixpanel from "mixpanel-browser";
import { useState } from "react";

export const useMixpanelEvents = () => {
  const [isIdentified, setIsIdentified] = useState(false);
  const [distinctId, setDistinctId] = useState(null);
  const [apiToken, setApiToken] = useState(null);

  const init = (apiToken, username) => {
    setDistinctId(username);
    setApiToken(apiToken);
  };

  const connect = () => {
    const conf = {
      upgrade: true, // Upgrade from mixpanel v1 to v2
      cross_subdomain_cookie: true // Allow cookies on *.policystat.com
    };
    mixpanel.init(apiToken, conf);
    mixpanel.identify(distinctId);
    setIsIdentified(true);
  };

  const sendEvent = (event, data) => {
    if (!isIdentified) {
      connect();
    }
    mixpanel.track(event, data);
  };

  return {
    init,
    sendEvent
  };
};

export function withMixpanelEvents(Component) {
  return function WrappedComponent(props) {
    return <Component {...props} mixpanel={useMixpanelEvents()} />;
  };
}
