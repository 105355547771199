import { useEffect, useState } from "react";

import doBulkReview from "../graphql/BulkReviewMutation";

import useQueryVariables from "pstat-anywhere/components/pages/reports/hooks/useQueryVariables";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";


export default function useDocumentsToReveiwReport({
  router,
  route,
  location,
  labels,
  t
}) {
  const [taskId, setTaskId] = useState(null);
  const { error, loading, result } = useTaskResult(taskId);

  useEffect(() => {
    if (result) {
      createSuccessMessage(
        t("documentReports.expiring.successfulBulkReview", {
          documentLabelPlural: labels.documentLabelPlural.toLowerCase()
        })
      );
      router.push(getRootUrl());
    }
  }, [result, labels.documentLabelPlural, router, t]);
  useEffect(() => {
    if (error) {
      createErrorMessage(
        t("documentReports.expiring.errorBulkReview", {
          documentLabelPlural: labels.documentLabelPlural.toLowerCase()
        })
      );
    }
  }, [error, labels.documentLabelPlural, t]);

  const justMine =
    route?.data?.routeName !== "expiring_all" &&
    route?.data?.routeName !== "expiring_templates_all";
  const isTemplateView = [
    "expiring_templates_all",
    "expiring_templates_mine"
  ].includes(route?.data?.routeName);

  const { variables } = useQueryVariables({ location });
  const {
    categories,
    days_until_expiration,
    days_waiting_since,
    include_pending,
    search_query
  } = location.query;
  variables.categories = categories;
  variables.daysUntilExpiration = days_until_expiration;
  variables.includePending = include_pending;
  variables.searchQuery = search_query;
  variables.daysWaitingSince = days_waiting_since;

  function handleBulkReview(selectedItemPks, comment) {
    doBulkReview({
      documentPks: selectedItemPks,
      comment,
      callback: successBulkReviewCallback
    });
  }

  function successBulkReviewCallback(response, errors) {
    setTaskId(response.bulkReview.taskId);
  }

  return {
    variables: {
      ...variables,
      justMine,
      reviewType: isTemplateView ? "TEMPLATE" : "TIME"
    },
    handleBulkReview,
    loading,
    isTemplateView
  };
}
