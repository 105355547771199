import graphql from "babel-plugin-relay/macro";

const VergeAssociatedDocumentsQuery = graphql`
  query VergeAssociatedDocumentsQuery(
    $justMine: Boolean
    $after: String
    $first: Int
    $sort: String
  ) {
    documentsWithVergeStandards(justMine: $justMine, sort: $sort) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            approvalDate
            epAssociationDocuments {
              pk
              id
              epId
              associationDatetime
            }
            bloc {
              name
            }
          }
        }
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default VergeAssociatedDocumentsQuery;
