// @flow

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  Divider,
  RootContainer,
  StepContainer,
  StepCurrentMarker,
  StepSpan,
  StepTitle,
  StepUsersContainer
} from "./components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { H3 } from "pstat-design-system/text";


export const APPROVAL_WORKFLOW_CONTENT_VARIATIONS = {
  VIEW_ONLY: "VIEW_ONLY",
  PENDING_NO_PRIVILEGES: "PENDING_NO_PRIVILEGES",
  PENDING_WITH_PRIVILEGES: "PENDING_WITH_PRIVILEGES"
};

type Props = {
  title: string,
  onUserRowClick?: Function, // Callback for emailing functionality
  steps: {
    title: string,
    users: {
      isDocumentOwner: boolean,
      firstName: string,
      lastName: string,
      hasApproved: boolean
    }[]
  }[],
  labels: {
    documentLabel: string,
    authorLabel: string
  },
  variation?: $Values<APPROVAL_WORKFLOW_CONTENT_VARIATIONS>
};

function ApprovalWorkflowContent({
  steps,
  title,
  labels: { documentLabel, authorLabel },
  variation = APPROVAL_WORKFLOW_CONTENT_VARIATIONS.VIEW_ONLY,
  onUserRowClick,
  inModal,
  currentStepId
}: Props) {
  const { t } = useTranslation();
  // Sort step.users, so Document Owner user is first in step
  const sortedSteps = steps.map(step => {
    // can't directly sort the approvers array because it is read-only
    const editableApprovers = [...step.approvers];
    return {
      ...step,
      approvers: editableApprovers.sort((a, b) => {
        return a.isDocumentOwner === b.isDocumentOwner
          ? 0
          : a.isDocumentOwner
          ? -1
          : 1;
      })
    };
  });

  return (
    <RootContainer textAlign={inModal ? "center" : 0}>
      <H3
        id="approval_workflow_title"
        width="100%"
        ml={inModal ? 0 : 1}
        mt={1}
        mb={5}
        color="nav.0"
        fontWeight="400"
      >
        {title}
      </H3>

      {sortedSteps.map(
        ({ name, approvers, id, includeDocumentOwner }, index) => (
          <StepContainer key={id}>
            <StepTitle className="step_name">
              {name ? (
                <Fragment>
                  {index + 1}. {name}
                </Fragment>
              ) : (
                <Fragment>
                  {t("documentControl.approvalWorkflow.modal.stepDefaultName", {
                    stepNumber: index + 1
                  })}
                </Fragment>
              )}

              {id === currentStepId && id != null && (
                <StepCurrentMarker>
                  {t("documentControl.approvalWorkflow.modal.currentStep")}{" "}
                </StepCurrentMarker>
              )}
            </StepTitle>
            <StepUsersContainer>
              {includeDocumentOwner && (
                <StepSpan
                  className="is_document_owner"
                  fontStyle="italic"
                  color="nav.25"
                >
                  {documentLabel} {authorLabel}
                  <Divider />
                </StepSpan>
              )}
              {approvers.map(user => {
                const showCheckMark =
                  (variation ===
                    APPROVAL_WORKFLOW_CONTENT_VARIATIONS.PENDING_NO_PRIVILEGES ||
                    variation ===
                      APPROVAL_WORKFLOW_CONTENT_VARIATIONS.PENDING_WITH_PRIVILEGES) &&
                  user.hasApproved;
                const canEmail =
                  variation ===
                    APPROVAL_WORKFLOW_CONTENT_VARIATIONS.PENDING_WITH_PRIVILEGES &&
                  !user.hasApproved;
                const viewOnly =
                  variation === APPROVAL_WORKFLOW_CONTENT_VARIATIONS.VIEW_ONLY;
                // for now, if the step is marked as including the document owner, don't show
                // which user is the owner in the flow, as it can change depending on which
                // user is selected to be the author on the edit page
                const showUserAsDocumentOwner =
                  !includeDocumentOwner && user.isDocumentOwner;
                return (
                  <section
                    onClick={() => {
                      if (
                        variation ===
                        APPROVAL_WORKFLOW_CONTENT_VARIATIONS.PENDING_WITH_PRIVILEGES
                      ) {
                        onUserRowClick(user);
                      }
                    }}
                    className="approval-workflow-step-user-row"
                  >
                    <StepSpan
                      className="user_name"
                      color={
                        viewOnly || user.hasApproved ? "nav.25" : "secondary.0"
                      }
                    >
                      {canEmail && (
                        <StyledFontAwesomeIcon
                          className="approval-email-icon"
                          icon={["fal", "envelope"]}
                          color="secondary.0"
                        />
                      )}
                      {showCheckMark && (
                        <StyledFontAwesomeIcon
                          className="approval-done-check-mark"
                          icon={["far", "check"]}
                          color="nav.25"
                        />
                      )}{" "}
                      {user.lastName && `${user.lastName},`} {user.firstName}
                    </StepSpan>
                    {showUserAsDocumentOwner && (
                      <StepSpan
                        className="is_document_owner"
                        fontStyle="italic"
                        color={
                          viewOnly || user.hasApproved
                            ? "nav.25"
                            : "secondary.0"
                        }
                      >
                        {documentLabel} {authorLabel}
                        <Divider />
                      </StepSpan>
                    )}
                  </section>
                );
              })}
            </StepUsersContainer>
          </StepContainer>
        )
      )}
    </RootContainer>
  );
}

export default withLabelContext(ApprovalWorkflowContent);
