import graphql from "babel-plugin-relay/macro";

export const documentTimelineQuery = graphql`
  query documentTimelineQuery($documentPk: Int!) {
    documentView(
      pk: $documentPk
      showChanges: true
      latest: false
      ignoreLatestRedirect: true
    ) {
      timeline
    }
  }
`;

export default documentTimelineQuery;
