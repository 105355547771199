/**
 * @flow
 * @relayHash 313ce20a530c481e171a70cd62f6b42f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type HomeContentQueriesDocumentsToReviewQueryVariables = {||};
export type HomeContentQueriesDocumentsToReviewQueryResponse = {|
  +documentsToReviewHome: ?{|
    +documents: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
      +bloc: {|
        +name: string
      |},
      +applicabilityType: ?ApplicabilityType,
      +category: {|
        +name: string
      |},
      +expirationDate: ?string,
      +restricted: boolean,
    |}>,
    +totalCount: ?number,
    +buckets: ?any,
    +counts: ?{|
      +templateCount: ?number
    |},
  |}
|};
export type HomeContentQueriesDocumentsToReviewQuery = {|
  variables: HomeContentQueriesDocumentsToReviewQueryVariables,
  response: HomeContentQueriesDocumentsToReviewQueryResponse,
|};
*/


/*
query HomeContentQueriesDocumentsToReviewQuery {
  documentsToReviewHome {
    documents {
      pk
      name
      bloc {
        name
        id
      }
      applicabilityType
      category {
        name
        id
      }
      expirationDate
      restricted
      id
    }
    totalCount
    buckets
    counts {
      templateCount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  v1
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buckets",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "counts",
  "storageKey": null,
  "args": null,
  "concreteType": "DueForReviewCounts",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "templateCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v10 = [
  v1,
  v9
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HomeContentQueriesDocumentsToReviewQuery",
  "id": "9e502d33ea1c34b9864ad49fe190e0bd",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HomeContentQueriesDocumentsToReviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsToReviewHome",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentsToReviewHomePageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": true,
            "selections": [
              v0,
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bloc",
                "storageKey": null,
                "args": null,
                "concreteType": "BlocType",
                "plural": false,
                "selections": v2
              },
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v2
              },
              v4,
              v5
            ]
          },
          v6,
          v7,
          v8
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomeContentQueriesDocumentsToReviewQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsToReviewHome",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentsToReviewHomePageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": true,
            "selections": [
              v0,
              v1,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bloc",
                "storageKey": null,
                "args": null,
                "concreteType": "BlocType",
                "plural": false,
                "selections": v10
              },
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v10
              },
              v4,
              v5,
              v9
            ]
          },
          v6,
          v7,
          v8
        ]
      }
    ]
  }
};
})();
node.text = "query HomeContentQueriesDocumentsToReviewQuery {\n  documentsToReviewHome {\n    documents {\n      pk\n      name\n      bloc {\n        name\n        id\n      }\n      applicabilityType\n      category {\n        name\n        id\n      }\n      expirationDate\n      restricted\n      id\n    }\n    totalCount\n    buckets\n    counts {\n      templateCount\n    }\n  }\n}\n";
(node/*: any*/).hash = 'f84bac803e42b90efbe4d029df4c6e97';
module.exports = node;
