/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Info_references$ref = any;
type Properties_author$ref = any;
type Properties_category$ref = any;
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Info_template$ref: FragmentReference;
export type Info_template = {|
  +pk: ?number,
  +status: ?DocumentStatus,
  +approvalDate: ?string,
  +revisionDate: ?string,
  +author: ?{|
    +$fragmentRefs: Properties_author$ref
  |},
  +category: ?{|
    +$fragmentRefs: Properties_category$ref
  |},
  +tags: ?$ReadOnlyArray<?{|
    +$fragmentRefs: Info_references$ref
  |}>,
  +$refType: Info_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "formatted",
    "value": true,
    "type": "Boolean"
  }
];
return {
  "kind": "Fragment",
  "name": "Info_template",
  "type": "SystemDocumentTemplateType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pk",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "approvalDate",
      "args": v0,
      "storageKey": "approvalDate(formatted:true)"
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "revisionDate",
      "args": v0,
      "storageKey": "revisionDate(formatted:true)"
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "author",
      "storageKey": null,
      "args": null,
      "concreteType": "UserType",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Properties_author",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryType",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Properties_category",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tags",
      "storageKey": null,
      "args": null,
      "concreteType": "TagType",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Info_references",
          "args": null
        }
      ]
    }
  ]
};
})();

(node/*: any*/).hash = '18f76bb64acf831acc6031539677e124';
module.exports = node;
