import { Box, Flex } from "grid-styled";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import system from "system-components";

import Dropdown, {
  DropdownFooterContainer
} from "pstat-anywhere/components/partials/Dropdown";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import InlineInputWrapper from "pstat-design-system/InlineInputWrapper";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import { TextInputWithIcon } from "pstat-design-system/inputs/TextInput";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text/paragraph";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

export const PageContainer = system({
  px: [0, 0, 4]
});

export const PaginationContainer = system({
  mt: 4
});

export const PointerBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

export const ActionButtonContainer = system({
  width: "100%",
  bg: "secondary.0",
  borderBottom: 1,
  borderRadius: 1,
  borderColor: "nav.80",
  boxShadow: "card",
  height: 60,
  display: "flex",
  pl: [2, 2, 8],
  pr: [2, 2, 8]
}).extend`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const SearchInlineInputWrapper = system({
  is: InlineInputWrapper,
  width: 1,
  pb: 2
});

export const SearchBar = ({
  initialValue = "",
  onChange,
  placeholder,
  children,
  changesTimeoutTime
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);
  const timeout = useRef(null);
  const internalOnChange = event => {
    const value = event.target.value;
    setValue(value);
  };
  let timeoutTime = 2000;
  if (changesTimeoutTime) {
    timeoutTime = changesTimeoutTime;
  }
  useEffect(() => {
    if (timeoutTime === 0) {
      onChange(value);
      return;
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      onChange(value);
    }, timeoutTime);
    return () => clearTimeout(timeout.current);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <SearchInlineInputWrapper>
      <Flex
        width="100%"
        alignItems={["stretch", "center", "center"]}
        flexDirection={["column", "row", "row"]}
      >
        <TextInputWithIcon
          icon={["far", "search"]}
          side="left"
          placeholder={placeholder}
          value={value}
          onChange={internalOnChange}
          containerProps={{ flex: 1 }}
          aria-label={t("documentReports.advancedReportPage.filters.keyword")}
        />
        {children}
      </Flex>
    </SearchInlineInputWrapper>
  );
};

export const FilterDropdown = props => {
  const { t } = useTranslation();
  const {
    filterName,
    expanded,
    onToggle,
    onClose,
    children,
    expandedFilterName
  } = props;
  return (
    <Dropdown
      expanded={expanded}
      renderToggleComponent={() => (
        <Button
          buttonStyle="tertiary"
          onClick={() => onToggle()}
          width={1}
          focusBorderProps={{ width: 1 }}
        >
          {filterName}
        </Button>
      )}
      headerName={expandedFilterName || filterName}
      renderFooter={() => (
        <DropdownFooterContainer>
          <Button buttonStyle="primary" onClick={onClose} px={6}>
            {t("documentReports.advancedReportPage.filters.done")}
          </Button>
        </DropdownFooterContainer>
      )}
      onClose={onClose}
    >
      {children}
    </Dropdown>
  );
};

const ActiveFilterBoxContainer = system({
  display: "inline-block",
  bg: "nav.90",
  p: 1,
  borderRadius: 1,
  mr: 1,
  mb: 1
});

const CloseIcon = system({
  is: StyledFontAwesomeIcon,
  mr: 0
}).extend`
  cursor: pointer;
`;

export const ActiveFilterBox = ({ children, onClick }) => {
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      onClick();
    }
  };
  return (
    <ActiveFilterBoxContainer>
      <Text fontSize={1} fontWeight="medium">
        {children}
        <CloseIcon
          icon={["fas", "times-circle"]}
          role="button"
          onClick={onClick}
          onKeyDown={handleKeyDown}
          tabIndex="0"
        />
      </Text>
    </ActiveFilterBoxContainer>
  );
};

export const CheckboxFilterBox = ({ name, onChange, checked, tooltipText }) => {
  return (
    <Fragment>
      <CheckboxGroup
        name={name}
        onChange={onChange}
        selectedValues={checked ? [checked] : null}
      >
        <Checkbox value={checked} label={name} />
      </CheckboxGroup>
      {tooltipText && (
        <Fragment>
          <StyledFontAwesomeIcon
            icon={["far", "question-circle"]}
            color="secondary.0"
            size="lg"
            mx={2}
            data-tip
            data-event={HOVER_EVENTS}
            data-event-off={HOVER_EVENTS_OFF}
            data-for="checkbox-tooltip-filter"
          />
          <Tooltip name="checkbox-tooltip-filter">{tooltipText}</Tooltip>
        </Fragment>
      )}
    </Fragment>
  );
};
