import graphql from "babel-plugin-relay/macro";

export const AuthorableTenantsQuery = graphql`
  query AuthorableTenantsQuery {
    authorableTenants {
      pk
      name
    }
  }
`;

export default AuthorableTenantsQuery;
