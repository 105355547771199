import { Box, Flex } from "grid-styled";
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";
import { QueryRenderer } from "react-relay";
import styled from "styled-components";

import MyPoliciesToAcknowledgeReportQuery from "./MyPoliciesToAcknowledgeReportQuery";

import {
  ReportHeader,
  ReportPage
} from "pstat-anywhere/components/pages/reports";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import Card from "pstat-design-system/Card";
import { SmileIcon } from "pstat-design-system/Icons";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import RouterFocusLink from "pstat-design-system/RouterLink";
import ShareButton from "pstat-design-system/ShareButton";
import { SortablePolicyTable } from "pstat-design-system/tables/PolicyTable";
import { Text } from "pstat-design-system/text/paragraph";


// @flow

const HITS_PER_PAGE = 20;

const AcksHistoryLinkContainer = styled.div`
  margin-top: 5px;
`;

const ActionItem = styled.div`
  float: left;
`;

export const buildPolicyTableStructure = myDocumentsToAcknowledge => {
  return myDocumentsToAcknowledge?.edges.map(edge => {
    const { totalAcknowledged, totalAssigned, waitTime, line } = edge.node;
    return {
      ...line.document,
      line,
      totalAcknowledged,
      totalAssigned,
      waitTime
    };
  });
};

class _MyPoliciesToAcknowledgeReport extends Component {
  // Be default we set totalCount to zero and don't show count badge
  state = {
    totalCount: 0,
    showCountBadge: false
  };

  handleSortChange = sort => {
    const queryParams = { ...this.props.location.query };
    queryParams.sort = sort;
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/mine/`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handlePageChange = afterCursor => {
    const queryParams = { ...this.props.location.query };
    delete queryParams.after;
    if (afterCursor) {
      queryParams.after = afterCursor;
    }
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/mine/`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleTotalCountUpdate = queryProps => {
    // Verify queryProps is dfined and totalCount is there
    if (!queryProps || !("totalCount" in queryProps.myDocumentsToAcknowledge)) {
      return;
    }
    if (!this.state.showCountBadge) {
      this.setState({ showCountBadge: true });
    }
    const totalCount = queryProps.myDocumentsToAcknowledge.totalCount;
    if (this.state.totalCount !== totalCount) {
      this.setState({ totalCount });
    }
  };

  buildPoliciesForRendering = queryProps => {
    return queryProps
      ? buildPolicyTableStructure(queryProps.myDocumentsToAcknowledge)
      : [];
  };

  render() {
    const { t } = this.props;
    const { documentLabelPlural } = this.props.labels;
    const queryVariables = {
      first: HITS_PER_PAGE,
      sort: this.props.location.query.sort || "",
      after: this.props.location.query.after || ""
    };
    return (
      <ReportPage
        totalNumberOfResults={this.state.totalCount}
        hitsPerPage={HITS_PER_PAGE}
      >
        <ReportHeader
          title={t("pages.acknowledgment.mine.header", { documentLabelPlural })}
          resultCount={this.state.totalCount}
          showCountBadge={this.state.showCountBadge}
        >
          <Media query={{ minWidth: theme.breakpoints[1] }}>
            {isDesktop => (
              <Fragment>
                {isDesktop && (
                  <ActionItem>
                    <AcksHistoryLinkContainer>
                      <RouterFocusLink
                        to={`${getRootUrl()}/acknowledgment/mine/all/`}
                      >
                        <StyledFontAwesomeIcon
                          icon={["far", "history"]}
                          size="lg"
                          color="secondary.0"
                        />
                        {t("acknowledgments.mine.history")}
                      </RouterFocusLink>
                    </AcksHistoryLinkContainer>
                  </ActionItem>
                )}
              </Fragment>
            )}
          </Media>
          <ActionItem>
            <ShareButton copyUrl={window.location.href} />
          </ActionItem>
        </ReportHeader>
        <QueryRenderer
          environment={this.props.environment}
          query={MyPoliciesToAcknowledgeReportQuery}
          variables={queryVariables}
          render={data => {
            const queryProps = data.props;
            if (queryProps !== undefined) {
              const policies = this.buildPoliciesForRendering(queryProps);
              this.handleTotalCountUpdate(queryProps);
              return (
                <Media query={{ minWidth: theme.breakpoints[1] }}>
                  {isDesktop => (
                    <Card
                      display="flex"
                      flexDirection="column"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="390px"
                    >
                      {policies.length > 0 ? (
                        <SortablePolicyTable
                          id="results_table"
                          onSortChange={this.handleSortChange}
                          policies={policies}
                          tableType={
                            isDesktop
                              ? "my_policies_to_acknowledge"
                              : "my_policies_to_acknowledge_mobile"
                          }
                          numberToDisplay={HITS_PER_PAGE}
                          sort={this.props.location.query.sort}
                          noSort={!isDesktop}
                        />
                      ) : (
                        <Fragment>
                          <SmileIcon
                            width={"100%"}
                            marginBottom={theme.space[6]}
                          />
                          <Text color="nav.25" fontSize={2}>
                            {t("acknowledgments.mine.emptyResults.large", {
                              documentLabelPlural: documentLabelPlural.toLowerCase()
                            })}
                          </Text>
                        </Fragment>
                      )}
                    </Card>
                  )}
                </Media>
              );
            } else {
              return (
                <Flex justify="center" align="center" mt={10}>
                  <Box>
                    <LoadingSpinner size={100} />
                  </Box>
                </Flex>
              );
            }
          }}
        />
      </ReportPage>
    );
  }
}

export default withLabelContext(
  withTranslation()(_MyPoliciesToAcknowledgeReport)
);
