import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { FocusLink } from "pstat-design-system/Link";
import RouterLink from "pstat-design-system/RouterFocusLink";

const LinkContainer = system({
  is: "span",
  display: "inline-block"
});

const ExternalLink = ({ children, to, ...otherProps }) => {
  let LinkComponent = FocusLink;
  if (to) {
    LinkComponent = RouterLink;
  }
  return (
    <LinkComponent to={to} {...otherProps}>
      <LinkContainer>
        {children}
        <StyledFontAwesomeIcon icon={["far", "external-link"]} size="xs" />
      </LinkContainer>
    </LinkComponent>
  );
};

export default ExternalLink;
