import graphql from "babel-plugin-relay/macro";

const UnpublishedDocumentsForTemplateCsvQuery = graphql`
  query UnpublishedDocumentsForTemplateCsvQuery($pk: Int!) {
    hiddenSystemDocumentTemplatesDocumentsCsv(pk: $pk) {
      taskId
    }
  }
`;

export default UnpublishedDocumentsForTemplateCsvQuery;
