import styled from "styled-components";
import { themeGet } from "styled-system";

import { FocusLink } from "pstat-design-system/Link";

export const LabelFieldContainer = styled.label`
  opacity: 0.9;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  text-align: left;
  color: ${themeGet("colors.nav.0")};
  margin-bottom: 7px;
  display: block;
`;

export const RequiredLabelContainer = styled(LabelFieldContainer)`
  &:after {
    content: "*";
    color: ${themeGet("colors.messages.error")};
  }
`;

export const LabelField = ({ name, required, htmlFor, url }) => {
  let content = name;
  if (url) {
    content = (
      <FocusLink href={url} target="_blank">
        {name}
      </FocusLink>
    );
  }
  return (
    <div>
      {required ? (
        <RequiredLabelContainer htmlFor={htmlFor}>
          {content}
        </RequiredLabelContainer>
      ) : (
        <LabelFieldContainer htmlFor={htmlFor}>{content}</LabelFieldContainer>
      )}
    </div>
  );
};
