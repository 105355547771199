/**
 * @flow
 * @relayHash 87e1308985b8a5f18788b37633fec4b5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditPolicyStartApprovalsInput = {
  documentPk: number,
  actionComment?: ?string,
  documentSaveId?: ?number,
  hasSavedCopy?: ?boolean,
  continueDraft?: ?boolean,
  tagInput?: ?$ReadOnlyArray<?string>,
  effectiveDate?: ?any,
  effectiveDateTimedelta?: ?number,
  effectiveDateAlgorithm?: ?string,
  name?: ?string,
  html?: ?string,
  author?: ?number,
  category?: ?number,
  bloc?: ?number,
  flowTemplate?: ?number,
  activeLifespan?: ?number,
  expirationDate?: ?any,
  restricted?: ?boolean,
  isAForm?: ?boolean,
  regulations?: ?$ReadOnlyArray<?number>,
  onesourceDocuments?: ?$ReadOnlyArray<?OneSourceDocumentInputType>,
  locations?: ?$ReadOnlyArray<?number>,
  addApproval?: ?boolean,
  clientMutationId?: ?string,
};
export type OneSourceDocumentInputType = {
  oneSourceDocumentId?: ?string,
  catalogNumber?: ?string,
  manufacturerName?: ?string,
  manufacturerId?: ?string,
  oneSourceUpdated?: ?string,
  revisionInformation?: ?string,
  name?: ?string,
};
export type EditPolicyStartApprovalsMutationVariables = {|
  input: EditPolicyStartApprovalsInput
|};
export type EditPolicyStartApprovalsMutationResponse = {|
  +editPolicyStartApprovals: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
  |}
|};
export type EditPolicyStartApprovalsMutation = {|
  variables: EditPolicyStartApprovalsMutationVariables,
  response: EditPolicyStartApprovalsMutationResponse,
|};
*/


/*
mutation EditPolicyStartApprovalsMutation(
  $input: EditPolicyStartApprovalsInput!
) {
  editPolicyStartApprovals(input: $input) {
    ok
    reasons
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EditPolicyStartApprovalsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "editPolicyStartApprovals",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EditPolicyStartApprovalsInput!"
      }
    ],
    "concreteType": "EditPolicyStartApprovalsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "EditPolicyStartApprovalsMutation",
  "id": "c9f001e4435d1573324d70fd967ea770",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditPolicyStartApprovalsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EditPolicyStartApprovalsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation EditPolicyStartApprovalsMutation(\n  $input: EditPolicyStartApprovalsInput!\n) {\n  editPolicyStartApprovals(input: $input) {\n    ok\n    reasons\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = '7bf3dce6a820ea74e6d18eef49c7c29b';
module.exports = node;
