import graphql from "babel-plugin-relay/macro";

const MyAcknowledgmentHistoryQuery = graphql`
  query MyAcknowledgmentsForLineQuery(
    $linePk: Int!
    $after: String
    $first: Int
    $sort: String
  ) {
    acknowledgmentsByLine(
      linePk: $linePk
      after: $after
      first: $first
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          dateActive
          dateAcknowledged
          dateAssigned
          revised
          waitTime
          document {
            pk
            name
            status
            category {
              name
            }
          }
        }
      }
    }
  }
`;

export default MyAcknowledgmentHistoryQuery;
