// @flow
import { Component } from "react";
import styled from "styled-components";

import TextInput from "../TextInput";

export const HiddenDiv = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  visibility: hidden;
  overflow: scroll;
  white-space: nowrap;
`;

class AutosizeInput extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      inputWidth: 350,
      value: this.props.value
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateInputWidth = this.updateInputWidth.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateInputWidth();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  placeHolderSizerRef = el => {
    this.placeHolderSizer = el;
  };

  sizerRef = el => {
    this.sizer = el;
  };

  getSizerWidth = () => {
    return this.sizer ? this.sizer.scrollWidth : 0;
  };

  updateInputWidth() {
    if (!this.mounted || !this.sizer || !this.getSizerWidth() === "undefined") {
      return;
    }

    let newInputWidth;
    if (this.props.placeholder) {
      newInputWidth =
        Math.max(this.getSizerWidth(), this.placeHolderSizer.scrollWidth) +
        this.props.margin;
    } else {
      newInputWidth = this.getSizerWidth() + this.props.margin;
    }

    if (newInputWidth < this.props.minimumWidth) {
      newInputWidth = this.props.minimumWidth;
    }

    if (newInputWidth !== this.state.inputWidth) {
      this.setState({ inputWidth: newInputWidth });
    }
    return newInputWidth;
  }

  handleChange(event: Event) {
    this.updateInputWidth();
    this.setState({
      value: event.target.value
    });
    this.props.onChange(event);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value
      };
    }
  }
  render() {
    const { label, type, error, name } = this.props;
    let width = this.state.inputWidth + "px";
    return (
      <div>
        <TextInput
          {...this.props}
          style={{
            width
          }}
          autosize
          ref={this.inputRef}
          error={error}
          type={type}
          name={name}
          value={this.state.value}
          onChange={this.handleChange}
          aria-label={label}
        />
        <HiddenDiv innerRef={this.sizerRef}>{this.state.value}</HiddenDiv>
        {this.props.placeholder && (
          <HiddenDiv innerRef={this.placeHolderSizerRef}>
            {this.props.placeholder}
          </HiddenDiv>
        )}
      </div>
    );
  }
}

AutosizeInput.defaultProps = {
  margin: 40,
  minimumWidth: 350
};

export default AutosizeInput;
