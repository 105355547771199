/**
 * @flow
 * @relayHash 5df5e0f3e3baef6edd21c55eed095ab9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type useCheckPasswordResetTokenQueryVariables = {|
  uidb64: string,
  token: string,
|};
export type useCheckPasswordResetTokenQueryResponse = {|
  +passwordResetTokenCheck: ?{|
    +result: ?boolean
  |}
|};
export type useCheckPasswordResetTokenQuery = {|
  variables: useCheckPasswordResetTokenQueryVariables,
  response: useCheckPasswordResetTokenQueryResponse,
|};
*/


/*
query useCheckPasswordResetTokenQuery(
  $uidb64: String!
  $token: String!
) {
  passwordResetTokenCheck(uidb64: $uidb64, token: $token) {
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uidb64",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "passwordResetTokenCheck",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "uidb64",
        "variableName": "uidb64",
        "type": "String!"
      }
    ],
    "concreteType": "PasswordResetCheckTokenType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "useCheckPasswordResetTokenQuery",
  "id": "d472bab1d8aa331d663dc11698d72104",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "useCheckPasswordResetTokenQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "useCheckPasswordResetTokenQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query useCheckPasswordResetTokenQuery(\n  $uidb64: String!\n  $token: String!\n) {\n  passwordResetTokenCheck(uidb64: $uidb64, token: $token) {\n    result\n  }\n}\n";
(node/*: any*/).hash = 'a122fcefe1d1bd17e097fd2d706b9d68';
module.exports = node;
