import graphql from "babel-plugin-relay/macro";
import { withRouter } from "found";
import { Box, Flex } from "grid-styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { createFragmentContainer } from "react-relay";
import system from "system-components";

import theme from "../../themes/policystat/theme";
import { getRootUrl } from "../../utils/urls";
import { QuickSearch } from "../document_search/QuickSearch/QuickSearch";
import SimpleSearch from "../document_search/SimpleSearch";
import { ContentContainerFlex } from "../pages";

import { layer } from "pstat-design-system/utils";
import SiteSelector from "pstat-design-system/SiteSelector";

export const SecondaryNavFlex = system(
  {
    is: Flex,
    position: "relative",
    bg: "nav.10",
    py: [3, 3, 0]
  },
  "height",
  layer
);
SecondaryNavFlex.displayName = "SecondaryNavFlex";

const SecondaryNav = props => {
  const pathname = props.match.location.pathname;
  const isSearchPage = pathname.startsWith(`${getRootUrl()}/search`);
  const isAdminPage = pathname.startsWith(`${getRootUrl()}/admin`);

  const dropdownAccessibleTenantItems = props.accessibleTenants.sort((a, b) => {
    const aNameLowerCase = a.name.toLowerCase();
    const bNameLowerCase = b.name.toLowerCase();
    if (aNameLowerCase < bNameLowerCase) {
      return -1;
    } else if (aNameLowerCase > bNameLowerCase) {
      return 1;
    } else {
      return 0;
    }
  });
  const location = props.match.location;
  const { t } = useTranslation();

  return (
    <nav aria-label={t("nav.navBar.secondary.ariaLabel")}>
      <SecondaryNavFlex id="secondary-nav" layer="secondaryNav">
        <ContentContainerFlex
          data-testid="secondary-nav-container"
          pl={3}
          pr={4}
        >
          <Flex
            width={[992 / 2, 992 / 2, 992 / 2, 1200 / 2]}
            pt={[0, 0, 2]}
            align="center"
          >
            <SiteSelector
              tenant={props.tenant}
              accessibleTenants={dropdownAccessibleTenantItems}
              isAdminPage={isAdminPage}
            />
          </Flex>
          <Box flex={1} py={[0, 0, 3]}>
            <Media query={{ minWidth: theme.breakpoints[1] }}>
              {matches =>
                // disable quick search on the search pages
                // and don't have any search bar in the nav on mobile
                // Also reset the search bar on page changes (by changing key)
                matches ? (
                  isSearchPage ? (
                    <SimpleSearch />
                  ) : (
                    <QuickSearch key={location.pathname} />
                  )
                ) : null
              }
            </Media>
          </Box>
        </ContentContainerFlex>
      </SecondaryNavFlex>
    </nav>
  );
};

SecondaryNav.propTypes = {
  tenant: PropTypes.object,
  accessibleTenants: PropTypes.arrayOf(PropTypes.object)
};

const SecondaryNavWithRouter = withRouter(SecondaryNav);

export default SecondaryNavWithRouter;

export const SecondaryNavFragmentContainer = createFragmentContainer(
  SecondaryNavWithRouter,
  graphql`
    fragment SecondaryNav_tenant on TenantType {
      id
      subdomain
      name
    }

    fragment SecondaryNav_accessibleTenants on TenantType @relay(plural: true) {
      id
      url
      name
    }
  `
);
