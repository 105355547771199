/**
 * @flow
 * @relayHash b56783727454ea729ac80960df4311ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LogoutMutationVariables = {|
  lo: string
|};
export type LogoutMutationResponse = {|
  +logout: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type LogoutMutation = {|
  variables: LogoutMutationVariables,
  response: LogoutMutationResponse,
|};
*/


/*
mutation LogoutMutation(
  $lo: String!
) {
  logout(lo: $lo) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lo",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "logout",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lo",
        "variableName": "lo",
        "type": "String!"
      }
    ],
    "concreteType": "Logout",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "LogoutMutation",
  "id": "21c8b2517f5c2ee2b23d4e1512f8a296",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LogoutMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LogoutMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation LogoutMutation(\n  $lo: String!\n) {\n  logout(lo: $lo) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = 'aec63d80e27a86a0376d426daf142220';
module.exports = node;
