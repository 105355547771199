import graphql from "babel-plugin-relay/macro";

export const DueForReviewQuery = graphql`
  query HomeContentQueriesDocumentsToReviewQuery {
    documentsToReviewHome {
      documents {
        pk
        name
        bloc {
          name
        }
        applicabilityType
        category {
          name
        }
        expirationDate
        restricted
      }
      totalCount
      buckets
      counts {
        templateCount
      }
    }
  }
`;

export const RecentlyRevisedDocumentsQuery = graphql`
  query HomeContentQueriesNewAndRecentlyRevisedQuery {
    recentlyRevisedDocuments {
      documents(first: 5) {
        totalCount
        edges {
          node {
            pk
            name
            bloc {
              name
            }
            category {
              name
            }
            applicabilityType
            effectiveDate
            legacyEffectiveDate
            restricted
            revisedFromParent
            revisedFromParentStatus
          }
        }
      }
      buckets
    }
  }
`;

export const PendingDocumentsQuery = graphql`
  query HomeContentQueriesPendingDocumentsQuery {
    pendingDocuments(showAll: false) {
      documents(first: 5) {
        totalCount
        edges {
          node {
            id
            pk
            name
            bloc {
              name
            }
            applicabilityType
            category {
              name
            }
            effectiveDate
            restricted
            legacyEffectiveDate
          }
        }
      }
      viewOnlyPks
    }
  }
`;

export const MyDocumentsToAcknowledgeQuery = graphql`
  query HomeContentQueriesMyDocumentsToAcknowledgeQuery {
    myDocumentsToAcknowledge(first: 5) {
      totalCount
      edges {
        node {
          totalAcknowledged
          totalAssigned
          line {
            pk
            document {
              pk
              name
              hasAttachments
              effectiveDate
              legacyEffectiveDate
              restricted
              category {
                name
              }
              bloc {
                name
              }
              applicabilityType
            }
          }
        }
      }
    }
  }
`;
