import styled from "styled-components";

import { TERTIARY_NAVBAR_HEIGHT } from "pstat-anywhere/components/partials/tertiary_nav";
import theme from "pstat-anywhere/themes/policystat/theme";

export const FloatContainer = styled.div`
  position: sticky;
  top: ${TERTIARY_NAVBAR_HEIGHT}px;
  bottom: 0;
  left: 0;
  z-index: ${theme.layers.secondaryNav - 1};
  pointer-events: none;
`;

export default FloatContainer;
