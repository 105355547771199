import { Box, Flex } from "grid-styled";
import { Component, Fragment } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { QueryRenderer } from "react-relay";
import styled from "styled-components";
import { themeGet } from "styled-system";

import MyAcknowledgmentHistoryQuery from "./MyAcknowledgmentHistoryQuery";

import {
  ReportHeader,
  ReportPage
} from "pstat-anywhere/components/pages/reports";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import Breadcrumb from "pstat-design-system/Breadcrumb";
import Card from "pstat-design-system/Card";
import TableSearchBar from "pstat-design-system/inputs/TableSearchBar";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { HorizontalScrollTable } from "pstat-design-system/tables";
import {
  StyledTabBadge,
  Tab,
  TabContainer,
  TabPanel,
  TabPanelContainer
} from "pstat-design-system/Tabs";
import { Text } from "pstat-design-system/text/paragraph";


const EmptyResultContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 370;
  padding-top: 75px;
`;

const EmptyResultsMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyResultsMessage = styled(Text)`
  color: ${themeGet("colors.nav.25")};
  display: block;
  padding-top: ${themeGet("space.7")}px;
`;

const EmptyResults = props => {
  const { t } = useTranslation();
  const searchResult =
    typeof props.searchQuery === "string" && props.searchQuery !== "";
  const allAcknowledged =
    props.acknowledgedCount !== 0 && props.incompleteCount === 0;
  const noneAcknowledged =
    props.acknowledgedCount === 0 && props.incompleteCount !== 0;

  let messageKey = "acknowledgments.mine.emptyResults.noAcksYet";
  if (searchResult) {
    messageKey = "acknowledgments.mine.emptyResults.noAcksFound";
  } else if (allAcknowledged && props.tab === "unacknowledged") {
    messageKey = "acknowledgments.mine.emptyResults.noOutstandingAcks";
  } else if (noneAcknowledged && props.tab === "acknowledged") {
    messageKey = "acknowledgments.mine.emptyResults.allAcksStillOutstanding";
  }

  return (
    <EmptyResultContainer>
      <EmptyResultsMessageContainer>
        <StyledFontAwesomeIcon
          icon={["fal", "folder-open"]}
          size="4x"
          color="nav.55"
        />
        <EmptyResultsMessage>{t(messageKey)}</EmptyResultsMessage>
      </EmptyResultsMessageContainer>
    </EmptyResultContainer>
  );
};

const YourAcksBreadcrumb = props => {
  const { t } = useTranslation();
  return (
    <Breadcrumb
      text={t("acknowledgments.mine.yourAcksLink")}
      to={`${getRootUrl()}/acknowledgment/mine`}
    />
  );
};

const NoTopBorderRadiusCard = styled(Card)`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;

const PageContent = props => (
  <QueryRenderer
    environment={props.environment}
    query={MyAcknowledgmentHistoryQuery}
    variables={props.variables}
    render={renderProps => {
      const queryProps = renderProps.props;
      if (queryProps && queryProps.myDocumentsToAcknowledge) {
        const result = queryProps.myDocumentsToAcknowledge;
        props.onDataUpdate(result);
        if (result.totalCount === 0) {
          return (
            <EmptyResults
              acknowledgedCount={result.acknowledgedCount}
              incompleteCount={result.incompleteCount}
              searchQuery={props.variables.q}
              tab={props.tab}
            />
          );
        }
        const lines = result.edges.map(edge => {
          const { line, ...otherFields } = edge.node;
          return {
            applicabilityType: line.document.applicabilityType,
            ...line,
            ...otherFields
          };
        });
        return (
          <HorizontalScrollTable
            tableType="my_acknowledgment_history"
            policies={lines}
            sort={props.variables.sort}
            onSortChange={props.onSortChange}
            noSort={false}
            hasFrozenColumn={true}
          />
        );
      } else {
        return (
          <Flex justify="center" align="center" mt={10}>
            <Box>
              <LoadingSpinner size={100} />
            </Box>
          </Flex>
        );
      }
    }}
  />
);

const Tabs = props => {
  const { t } = useTranslation();
  return (
    <TabContainer
      selectedTabId={props.selected}
      onSelectedTabChange={props.onTabChange}
    >
      <Tab id="all_acknowledgments">
        <Text>
          {t("acknowledgments.mine.tabs.all")}
          <StyledTabBadge>
            {props.acknowledgedCount + props.incompleteCount}
          </StyledTabBadge>
        </Text>
      </Tab>
      <Tab id="acknowledged">
        <Text>
          {t("acknowledgments.mine.tabs.acknowledged")}
          <StyledTabBadge>{props.acknowledgedCount}</StyledTabBadge>
        </Text>
      </Tab>
      <Tab id="unacknowledged">
        <Text>
          {t("acknowledgments.mine.tabs.outstanding")}
          <StyledTabBadge>{props.incompleteCount}</StyledTabBadge>
        </Text>
      </Tab>
    </TabContainer>
  );
};

class MyAcknowledgmentHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalNumberOfResults: 0,
      hitsPerPage: 50,
      acknowledgedCount: 0,
      incompleteCount: 0,
      searchQuery: props.location.query.q
    };
    this.searchQueryDelay = null;
  }

  handleDataUpdate = data => {
    const updatedData = {};
    let updated = false;
    if (this.state.totalNumberOfResults !== data.totalCount) {
      updatedData.totalNumberOfResults = data.totalCount;
      updated = true;
    }
    if (this.state.acknowledgedCount !== data.acknowledgedCount) {
      updatedData.acknowledgedCount = data.acknowledgedCount;
      updated = true;
    }
    if (this.state.incompleteCount !== data.incompleteCount) {
      updatedData.incompleteCount = data.incompleteCount;
      updated = true;
    }

    if (updated) {
      this.setState(updatedData);
    }
  };

  handlePageChange = afterCursor => {
    const queryParams = { ...this.props.location.query };
    delete queryParams.after;
    if (afterCursor) {
      queryParams.after = afterCursor;
    }
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/mine/all`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleSortChange = sort => {
    const queryParams = { ...this.props.location.query };
    queryParams.sort = sort;
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/mine/all`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleFilterChange = filter => {
    const queryParams = { ...this.props.location.query };
    delete queryParams.filter;
    if (filter) {
      queryParams.filter = filter;
    }
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/mine/all`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleHitsPerPageChange = hitsPerPage => {
    this.setState({ hitsPerPage: hitsPerPage }, this.handlePageChange(null));
  };

  handleTabChange = filter => {
    if (filter === "all_acknowledgments") {
      filter = undefined;
    }
    this.handleFilterChange(filter);
  };

  handleSearchQueryChange = query => {
    if (this.searchQueryDelay) {
      clearTimeout(this.searchQueryDelay);
    }
    this.searchQueryDelay = setTimeout(() => {
      const queryParams = { ...this.props.location.query };
      queryParams.q = query;
      const to = {
        pathname: `${getRootUrl()}/acknowledgment/mine/all`,
        query: queryParams
      };
      this.props.router.push(to);
    }, 500);
    this.setState({ searchQuery: query });
  };

  render() {
    const { t, location } = this.props;
    const { after, sort, filter, q } = location.query;
    const variables = {
      first: this.state.hitsPerPage,
      after: after,
      sort: sort,
      filter: filter,
      q: q
    };

    let selectedTabId = filter;
    if (filter === undefined) {
      selectedTabId = "all_acknowledgments";
    }

    const pageContentProps = {
      environment: this.props.environment,
      variables: variables,
      onSortChange: this.handleSortChange,
      onDataUpdate: this.handleDataUpdate
    };

    return (
      <ReportPage
        totalNumberOfResults={this.state.totalNumberOfResults}
        hitsPerPage={this.state.hitsPerPage}
        showItemCount={true}
        onHitsPerPageChange={this.handleHitsPerPageChange}
        showHitsPerPage={true}
      >
        <Media query={{ minWidth: theme.breakpoints[1] }}>
          {isDesktop => (
            <Fragment>
              {isDesktop && (
                <ReportHeader
                  BreadcrumbComponent={YourAcksBreadcrumb}
                  title={t("acknowledgments.mine.history.header")}
                />
              )}
            </Fragment>
          )}
        </Media>
        <Tabs
          selected={selectedTabId}
          onTabChange={this.handleTabChange}
          acknowledgedCount={this.state.acknowledgedCount}
          incompleteCount={this.state.incompleteCount}
        />
        {(this.state.searchQuery || this.state.totalNumberOfResults > 0) && (
          <TableSearchBar
            value={this.state.searchQuery}
            placeholder={t("acknowledgments.mine.searchInput.placeholder")}
            onChange={event => this.handleSearchQueryChange(event.target.value)}
          />
        )}
        <NoTopBorderRadiusCard>
          <TabPanelContainer selectedTabId={selectedTabId}>
            <TabPanel tabId="all_acknowledgments">
              <PageContent {...pageContentProps} />
            </TabPanel>
            <TabPanel tabId="acknowledged">
              <PageContent {...pageContentProps} tab="acknowledged" />
            </TabPanel>
            <TabPanel tabId="unacknowledged">
              <PageContent {...pageContentProps} tab="unacknowledged" />
            </TabPanel>
          </TabPanelContainer>
        </NoTopBorderRadiusCard>
      </ReportPage>
    );
  }
}

export default withTranslation()(MyAcknowledgmentHistory);
