/**
 * @flow
 * @relayHash 60c0aa1b9d80eb54f4eed15c318e856f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PendingDocumentsCSVQueryVariables = {|
  showAll?: ?boolean,
  search_query?: ?string,
  pending_approver?: ?number,
  bloc?: ?number,
|};
export type PendingDocumentsCSVQueryResponse = {|
  +pendingDocumentsCsv: ?{|
    +taskId: ?string
  |}
|};
export type PendingDocumentsCSVQuery = {|
  variables: PendingDocumentsCSVQueryVariables,
  response: PendingDocumentsCSVQueryResponse,
|};
*/


/*
query PendingDocumentsCSVQuery(
  $showAll: Boolean
  $search_query: String
  $pending_approver: Int
  $bloc: Int
) {
  pendingDocumentsCsv(showAll: $showAll, q: $search_query, pendingApproverId: $pending_approver, blocId: $bloc) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search_query",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pending_approver",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bloc",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pendingDocumentsCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "blocId",
        "variableName": "bloc",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "pendingApproverId",
        "variableName": "pending_approver",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "search_query",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "showAll",
        "variableName": "showAll",
        "type": "Boolean"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PendingDocumentsCSVQuery",
  "id": "b7e27dd814fb24ebc3072e70148c6461",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PendingDocumentsCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "PendingDocumentsCSVQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query PendingDocumentsCSVQuery(\n  $showAll: Boolean\n  $search_query: String\n  $pending_approver: Int\n  $bloc: Int\n) {\n  pendingDocumentsCsv(showAll: $showAll, q: $search_query, pendingApproverId: $pending_approver, blocId: $bloc) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '6388872bd0677c933560375244f761c7';
module.exports = node;
