import graphql from "babel-plugin-relay/macro";

export const BlocTenantsPageQuery = graphql`
  query BlocTenantsPageQuery($sort: String) {
    canAdminTenants(sort: $sort) {
      error {
        statusCode
        message
      }
      tenants {
        pk
        name
        subdomain
        created
      }
      documentSettings {
        pk
        display
      }
    }
  }
`;

export default BlocTenantsPageQuery;
