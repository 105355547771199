import { Route } from "found";

import TokenViewPolicy from "./TokenViewPolicy";

import { environment } from "pstat-anywhere/relay";


const routes = (
  <Route path="policy/token_access">
    <Route
      path=":tokenId(\w{8}-\w{4}-4\w{3}-[89ab]\w{3}-\w{12})"
      Component={TokenViewPolicy}
      data={{
        routeName: "document_token_view"
      }}
      render={({ Component, props }) => (
        <Component {...props} environment={environment}>
          {props.children}
        </Component>
      )}
    />
  </Route>
);

export default routes;
