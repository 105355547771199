import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";

const SelectIcon = system({
  is: StyledFontAwesomeIcon,
  icon: ["far", "chevron-down"],
  color: "nav.55",
  position: "absolute"
}).extend`
  pointer-events: none;
  top: 14px;
  right: ${theme.space[2]}px;
`;

const SelectContainer = system(
  {
    display: "inline-block",
    position: "relative"
  },
  "width"
);

const selectProps = {
  is: "select",
  borderRadius: 1,
  pl: 3,
  pr: 6,
  py: 3,
  fontSize: 2,
  fontWeight: "normal",
  bg: "nav.100",
  boxShadow: `inset 1px 1px 0 0 ${theme.colors.nav["90"]}`,
  border: theme.borders[1],
  borderColor: "nav.80",
  color: "nav.0",
  focus: {
    border: theme.borders[1],
    borderColor: "secondary.0"
  },
  disabledStyle: {
    "background-color": theme.colors.nav["95"],
    cursor: "not-allowed"
  }
};
// something about setting the font in this select causes some of the image snapshot tests
// for the notification table to always fail after updating puppeteer to v5.2.1.
// So don't set the font when running in puppeteer
if (!process.env.STORYBOOK) {
  selectProps.fontFamily = theme.fontFamily;
}

const Select = system(selectProps).extend`
  -moz-appearance: none;
  -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }
  outline: none;
  width: ${({ width }) => width}
`;

const SelectInput = props => (
  <SelectContainer width={props.width ? props.width : null}>
    <Select
      width={props.width ? props.width : null}
      value={props.value}
      onChange={props.onChange}
      data-testid={props.dataTestid}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </Select>
    <SelectIcon size="sm" />
  </SelectContainer>
);

export default SelectInput;
