import system from "system-components";

const NotificationTR = system({
  is: "tr",
  display: "block",
  borderBottom: "1",
  borderColor: "nav.80",
  bg: "primary.80"
});

export default NotificationTR;
