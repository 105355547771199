import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation EditScheduledEffectiveDateMutation(
    $input: EditScheduledEffectiveDateInput!
  ) {
    editScheduledEffectiveDate(input: $input) {
      ok
      reasons
      document {
        id
        pk
        status
        effectiveDate(formatted: true)
        actions(isEditPage: false) {
          id
          pk
          actionType
          actionDate
          comment
          canEdit
          isExternal
          user {
            id
            userDisplay(userDisplayType: FORWARD)
          }
        }
      }
    }
  }
`;

const editEffectiveDate = (environment, variables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default editEffectiveDate;
