import graphql from "babel-plugin-relay/macro";

const DocumentsForReviewCSVQuery = graphql`
  query DocumentsForReviewCSVQuery(
    $justMine: Boolean
    $includePending: Boolean
    $categories: [Int]
    $daysUntilExpiration: Int
    $reviewType: ReviewType
  ) {
    documentsToReviewCsv(
      justMine: $justMine
      includePending: $includePending
      categories: $categories
      daysUntilExpiration: $daysUntilExpiration
      reviewType: $reviewType
    ) {
      taskId
    }
  }
`;

export default DocumentsForReviewCSVQuery;
