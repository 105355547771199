import PolicyActionCommentSection from "../policy_action_comment_section/PolicyActionCommentSection";
import ViewPolicy from "../view_policy/ViewPolicy";

import { retirePolicy } from "./RetirePolicyMutation";

function RetirePolicySection({ documentPk, location, router }) {
  return (
    <PolicyActionCommentSection
      documentPk={documentPk}
      policyActionMutation={retirePolicy}
      translationsKey="retire"
      location={location}
      router={router}
    />
  );
}

const RetirePolicyPage = props => {
  // the ViewPolicy component is expecting the data from the query to be in 'documentView'
  const documentView = props.documentRetire;
  return (
    <ViewPolicy
      {...props}
      documentView={documentView}
      hasPolicyActionComment={true}
      PolicyActionCommentSection={RetirePolicySection}
      displayConfig={{
        showHistoryPanel: false,
        showCommentsPanel: false,
        showCloneAction: false
      }}
    />
  );
};

export default RetirePolicyPage;
