import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { withLabelContext } from "../../context_providers/LabelContext";
import { withTenantSamlContext } from "../../context_providers/TenantSamlContext";

import { LoginWrapper } from "./Common";
import SimpleLogin from "./SimpleLogin";
import SSOLogin from "./SSOLogin";

import { Text } from "pstat-design-system/text";
import { Link } from "pstat-design-system/Link";
import { ErrorBanner, SuccessBanner } from "pstat-design-system/banner";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import sanitize from "pstat-anywhere/utils/sanitize";

const LoginForm = props => {
  const { samlSettings, cleanBorders, onLoginHelpClicked } = props;
  const { t } = useTranslation();

  const parsed = queryString.parse(window.location.search);

  let ssoUsernameInvalid;
  let proxyUsername;
  const isLogout = parsed["lo"];
  const isErrorSsoUri = parsed["sso"];
  const isErrorSsoUsername = parsed["sso_username"];
  const isErrorSsoUserAuthFailed = parsed["sso_user_auth_failed"];
  const isErrorSsoUsernameInvalid = sanitize(parsed["sso_username_invalid"]);
  const isUserHasProxy = parsed["user_has_proxy"];
  if (isErrorSsoUsernameInvalid) {
    ssoUsernameInvalid = atob(isErrorSsoUsernameInvalid);
  }
  if (isUserHasProxy) {
    proxyUsername = atob(isUserHasProxy);
  }
  const isErrorSsoSamlResponseStatusError = sanitize(
    parsed["saml_response_status_error"]
  );

  return (
    <LoginWrapper my={props.my | 0} width={props.width}>
      {isLogout && (
        <SuccessBanner
          width={"100%"}
          message={t("authentication.loginForm.logoutMessage")}
        />
      )}
      {isErrorSsoUri && (
        <ErrorBanner
          width={"100%"}
          message={t("authentication.loginForm.errorSsoUri")}
        />
      )}
      {isErrorSsoUsername && (
        <ErrorBanner
          width={"100%"}
          message={t("authentication.loginForm.errorSsoUsername")}
        />
      )}
      {isErrorSsoUserAuthFailed && (
        <ErrorBanner
          width={"100%"}
          message={t("authentication.loginForm.errorSsoUserAuthFailed")}
        />
      )}
      {isErrorSsoUsernameInvalid && (
        <ErrorBanner
          width={"100%"}
          message={t("authentication.loginForm.errorSsoUsernameInvalid", {
            username: ssoUsernameInvalid
          })}
        />
      )}
      {isErrorSsoSamlResponseStatusError && (
        <ErrorBanner
          width={"100%"}
          message={t(
            "authentication.loginForm.errorSsoSamlResponseStatusError"
          )}
        />
      )}
      {isUserHasProxy && (
        <ErrorBanner width={"100%"}>
          <Text color="nav.0">
            {t("authentication.loginForm.errorSsoUserHasProxy", {
              proxyUsername: proxyUsername,
              documentLabelPlural: props.labels.documentLabelPlural.toLowerCase()
            })}
          </Text>
          <Link href={`${getRootUrl()}/?force_guest_login=1`}>
            {t("authentication.loginForm.errorSsoUserHasProxyContinueLink", {
              guestLabel: props.labels.guestLabel.toLowerCase()
            })}
          </Link>
        </ErrorBanner>
      )}
      {samlSettings && samlSettings.isActive ? (
        <SSOLogin
          samlSettings={samlSettings}
          hardRedirect={props.hardRedirect}
          isUserHasProxy={isUserHasProxy}
        />
      ) : (
        <SimpleLogin
          cleanBorders={cleanBorders}
          onLoginHelpClicked={onLoginHelpClicked}
        />
      )}
    </LoginWrapper>
  );
};

export default withLabelContext(withTenantSamlContext(LoginForm));
