/**
 * @flow
 * @relayHash 30a3396750ac3022f0e1817a31a4945c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BulkApproveMutationVariables = {|
  documentPks?: ?$ReadOnlyArray<?number>,
  comment: string,
|};
export type BulkApproveMutationResponse = {|
  +bulkApprove: ?{|
    +taskId: ?string
  |}
|};
export type BulkApproveMutation = {|
  variables: BulkApproveMutationVariables,
  response: BulkApproveMutationResponse,
|};
*/


/*
mutation BulkApproveMutation(
  $documentPks: [Int]
  $comment: String!
) {
  bulkApprove(documentPks: $documentPks, comment: $comment) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPks",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "bulkApprove",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "documentPks",
        "variableName": "documentPks",
        "type": "[Int]"
      }
    ],
    "concreteType": "BulkApproveMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "BulkApproveMutation",
  "id": "d0930e6c5ae44e95d4eb914f008fa3e5",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BulkApproveMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "BulkApproveMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation BulkApproveMutation(\n  $documentPks: [Int]\n  $comment: String!\n) {\n  bulkApprove(documentPks: $documentPks, comment: $comment) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '8f0d2e4ec73bbe832395d6a5505fdc9d';
module.exports = node;
