import { Flex } from "grid-styled";
import { withTranslation } from "react-i18next";

import doDeleteGroupRecurringAcknowledgment from "pstat-anywhere/components/acknowledgments/delete/DeleteGroupAcknowledgmentMutation";
import doDeleteUserRecurringAcknowledgment from "pstat-anywhere/components/acknowledgments/delete/DeleteUserAcknowledgmentMutation";
import { DeleteIconBadge } from "pstat-design-system/Badge";
import { Text } from "pstat-design-system/text";

const ListBadges = ({ title, objectList, onDelete }) => (
  <Flex flexDirection="column">
    <Text py={1}> {title} </Text>
    <Flex wrap>
      {objectList.map(({ display, recurringAckPk }) => (
        <DeleteIconBadge
          key={recurringAckPk}
          borderColor="primary.0"
          value={display}
          onClick={() => onDelete(recurringAckPk)}
        />
      ))}
    </Flex>
  </Flex>
);

const AcknowledgementsList = ({
  t,
  users,
  groups,
  deleteUserCallback,
  deleteGroupCallback
}) => {
  const userDelete = recurringAckPk => {
    doDeleteUserRecurringAcknowledgment(recurringAckPk, deleteUserCallback);
  };

  const groupDelete = recurringAckPk => {
    doDeleteGroupRecurringAcknowledgment(recurringAckPk, deleteGroupCallback);
  };

  return (
    <Flex flexDirection="column" pb={5}>
      {users.length > 0 && (
        <ListBadges
          title={t("acknowledgments.list.users")}
          objectList={users}
          onDelete={userDelete}
        />
      )}
      {groups.length > 0 && (
        <ListBadges
          title={t("acknowledgments.list.groups")}
          objectList={groups}
          onDelete={groupDelete}
        />
      )}
    </Flex>
  );
};

export default withTranslation()(AcknowledgementsList);
