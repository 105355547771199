import { Route } from "found";

import DocumentAcceptImportPage from "./accept/DocumentAcceptImportPage";
import DocumentAcceptImportPageQuery from "./accept/DocumentAcceptImportPageQuery";

import { environment } from "pstat-anywhere/relay";
import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";

/*
import DocumentsRequiringAcceptancePage, {
  RESULTS_PER_PAGE
} from "./accept_list/DocumentsRequiringAcceptancePage";
import DocumentsRequiringAcceptancePageQuery from "./accept_list/DocumentsRequiringAcceptancePageQuery";
*/

const routes = (
  <Route path="implementation">
    {/* <Route
      path="accept"
      query={DocumentsRequiringAcceptancePageQuery}
      data={{
        routeName: "implementation_accept_report"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        sort: location.query.sort,
        first: RESULTS_PER_PAGE,
        after: location.query.after,
        categories: location.query.categories
      })}
      render={({ props, retry }) => (
        <ErrorCheckWrapper queryName="documentsToAccept" {...props}>
          <DocumentsRequiringAcceptancePage {...props} retry={retry} />
        </ErrorCheckWrapper>
      )}
    /> */}
    <Route
      path="accept/:documentImportPk(\d+)"
      data={{
        routeName: "implementation_accept_import"
      }}
      query={DocumentAcceptImportPageQuery}
      render={({ props }) => (
        <ErrorCheckWrapper queryName="documentToAccept" {...props}>
          <DocumentAcceptImportPage environment={environment} {...props} />
        </ErrorCheckWrapper>
      )}
    />
  </Route>
);

export default routes;
