import {
  differenceInDays,
  formatDistanceToNow,
  isValid,
  parseISO
} from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

export const FORMAT_STRINGS = {
  "month-day-year": "MM/dd/yyyy", // "09/12/2022"
  "monthName-day-year": "MMMM d',' yyyy", // "September 12, 2022"
  "month-year": "MM/yyyy", // "09/2022"
  "shortMonthName-shortDay-year": "MMM d',' yyyy", // "Sep 12, 2022"
  "shortMonth-day-year": "M/d/yyyy", // "9/12/2022"
  "year-month-day": "yyyy-MM-dd", // "2022-09-12"
  "monthName-day-year-time-tz": "MMMM dd yyyy' at 'hh':'mmaaa zzz" // "September 12 2022 at 12:00am EDT"
};

export const FORMAT_STRINGS_TIMEZONE = {};

export const dateParser = date => {
  /* 
    The idea is receive date in any format and return a Date object
  */

  if (!date) {
    /* 
      new Date(null) returns dec 31, 1969
      So this check avoid passing through false positive date validations, returning
      a real invalid javascript date object 
    */
    return new Date("");
  }

  if (date instanceof Date) {
    return date;
  }

  return parseISO(date);
};

export const validateAndFormatDate = ({
  date,
  formatString,
  fallbackValue = "",
  nullableValue = ""
}) => {
  if (!date) {
    return nullableValue;
  }

  const dateObjectBrowserTz = dateParser(date);

  if (!isValid(dateObjectBrowserTz)) {
    return fallbackValue;
  }

  if (!(formatString in FORMAT_STRINGS)) {
    throw new Error("Invalid date format string");
  }

  const dateTzParsed = zonedTimeToUtc(
    dateObjectBrowserTz,
    "America/Indiana/Indianapolis"
  );

  return formatInTimeZone(
    dateTzParsed,
    "America/Indiana/Indianapolis",
    FORMAT_STRINGS[formatString]
  );
};

export const validateAndFormatDistanceToNow = (date, fallbackValue = "") => {
  const dateObject = dateParser(date);

  return isValid(dateObject) ? formatDistanceToNow(dateObject) : fallbackValue;
};

export const getDifferenceInDays = (firstDate, secondDate) => {
  const parsedFirstDate = dateParser(firstDate);
  const parsedSecondDate = dateParser(secondDate);

  if (isValid(parsedFirstDate) && isValid(parsedSecondDate)) {
    return differenceInDays(parsedFirstDate, parsedSecondDate);
  }

  return "";
};
