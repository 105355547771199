import { Component, Fragment, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useTranslation, withTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";
import system from "system-components";

import documentChangesQuery from "./documentChangesQuery";
import documentTimelineQuery from "./documentTimelineQuery";
import DraggablePickerButton from "./DraggablePickerButton";
import DroppableVersionItem, {
  DroppableVersionItemSpan,
  VersionPickerItemContainer
} from "./DroppableVersionItem";

import { getActionString } from "pstat-anywhere/components/document_control/view_policy/history/Headline";
import { TERTIARY_NAVBAR_HEIGHT } from "pstat-anywhere/components/partials/tertiary_nav";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import theme from "pstat-anywhere/themes/policystat/theme";
import { validateAndFormatDate } from "pstat-anywhere/utils/dateHelpers";
import isTouchDevice from "pstat-anywhere/utils/isTouchDevice";
import { FocusLink } from "pstat-design-system/Link";
import { TOAST_CONTAINER_ID } from "pstat-design-system/message/MessagesContainer";
import { FloatingButton } from "pstat-design-system/shared/Button";
import Sticky from "pstat-design-system/Sticky";
import { Text } from "pstat-design-system/text";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";
import { layer } from "pstat-design-system/utils";


export type DiffVersionPickerProps = {
  versions: {
    pk: number,
    author: string,
    date: string,
    actionType: string
  }[],
  onVersionsRangePick: Function,
  onClose: Function,
  defaultStartItemIndex: ?number,
  defaultEndItemIndex: ?number,
  t: Function
};

type State = {
  startItemIndex: number,
  endItemIndex: number
};

export const DraggableItemTypes = {
  ARROW: "scrollArrow"
};

const AbsoluteContainer = system(
  {
    position: ["fixed", "fixed", "absolute"],
    top: [null, null, 0],
    bottom: [TERTIARY_NAVBAR_HEIGHT, TERTIARY_NAVBAR_HEIGHT, 0],
    right: 0,
    width: ["100%", "100%", 360],
    height: ["50%", "50%", "auto"],
    layer: "activeFloatingUi"
  },
  layer
);

const TimelineContainer = system({
  bg: "nav.100",
  boxShadow: "0 15px 30px 0 rgba(24, 45, 74, 0.45)",
  height: "100%"
});

const ToggleButtonContainer = props => {
  const toastContainer = document.querySelector(
    `#${TOAST_CONTAINER_ID} .Toastify__toast-container`
  );
  let containerProps = {};
  if (toastContainer) {
    containerProps.top = toastContainer.getBoundingClientRect().top - 60;
    containerProps.bottom = null;
  } else {
    containerProps.top = null;
    containerProps.bottom = 0;
  }
  return (
    <StyledToggleButtonContainer {...containerProps}>
      {props.children}
    </StyledToggleButtonContainer>
  );
};

const StyledToggleButtonContainer = system({
  position: "fixed",
  top: 0,
  bottom: 0,
  right: 0,
  // move the button above the bottom nav bar on mobile screen sizes
  // theme.space[2] === 8
  mb: [
    TERTIARY_NAVBAR_HEIGHT + theme.space[2],
    TERTIARY_NAVBAR_HEIGHT + theme.space[2],
    6
  ]
});

const TimelineItemsContainer = system({
  height: "100%"
}).extend`
  overflow-y: auto;
`;

class _DiffVersionPicker extends Component<DiffVersionPickerProps, State> {
  handleSelectionChange = (index, { indexType }) => {
    if (indexType === "startItemIndex") {
      this.props.onStartIndexChange(index);
    }
    if (indexType === "endItemIndex") {
      this.props.onEndIndexChange(index);
    }
  };
  render() {
    const {
      loading,
      versions,
      startItemIndex,
      endItemIndex,
      onClose,
      t,
      labels
    } = this.props;
    const versionsCopy = [...versions];
    const versionsInDescendingOrder = versionsCopy.reverse();
    let title = t("documentControl.acknowledge.bar.changesTitle.custom");
    const newestActionIndex = versions.length - 1;
    if (endItemIndex === newestActionIndex) {
      title = t("documentTimeline.diffVersionPicker.changesTitle.newest");
    }

    return (
      <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
        <AbsoluteContainer className="diffVersionPicker__container">
          <Sticky
            id="timeline"
            offset={TERTIARY_NAVBAR_HEIGHT}
            height={["100%", "100%", "auto"]}
          >
            <TimelineContainer>
              <VersionPickerItemContainer pr={3} justifyContent="space-between">
                <Text size="large">{title}</Text>
                <FocusLink
                  role="button"
                  onActivation={onClose}
                  fontSize={1}
                  fontWeight="medium"
                  textDecoration="underline"
                >
                  {t("documentTimeline.diffVersionPicker.collapse")}
                </FocusLink>
              </VersionPickerItemContainer>
              {loading ? (
                <Text>Loading...</Text>
              ) : (
                <TimelineItemsContainer>
                  {versionsInDescendingOrder.map((version, index) => {
                    // the actions array is indexed with 0 being the oldest action but this map
                    // has the newest action first because we want to display the newest action at the top of the timeline.
                    // So we need to convert the index to go in reverse order to match the order of the actions array
                    const actionsIndex = versions.length - 1 - index;
                    let bg = "nav.100";
                    if (
                      actionsIndex >= startItemIndex &&
                      actionsIndex <= endItemIndex
                    ) {
                      bg = "primary.80";
                    }
                    let shouldShowStartPicker = false;
                    let shouldShowEndPicker = false;
                    if (actionsIndex === startItemIndex) {
                      shouldShowStartPicker = true;
                    }
                    if (actionsIndex === endItemIndex) {
                      shouldShowEndPicker = true;
                    }
                    const translatedActionTypeString = getActionString(
                      version.type,
                      t,
                      labels
                    );
                    const lastVersion = index === versions.length - 1;
                    return (
                      <DroppableVersionItem
                        className="timeline_action"
                        key={version.pk}
                        bg={bg}
                        onDrop={arrow =>
                          this.handleSelectionChange(actionsIndex, arrow)
                        }
                        mb={
                          lastVersion && [
                            TERTIARY_NAVBAR_HEIGHT,
                            TERTIARY_NAVBAR_HEIGHT,
                            0
                          ]
                        }
                      >
                        {shouldShowEndPicker && (
                          <DraggablePickerButton
                            data-testid="timelineEndButton"
                            top="-25%"
                            indexType="endItemIndex"
                          />
                        )}
                        <DroppableVersionItemSpan color="nav.0">
                          <span
                            data-tip
                            data-for={`action-date-tooltip-${index}`}
                            data-event={HOVER_EVENTS}
                            data-event-off={HOVER_EVENTS_OFF}
                          >
                            {t(
                              "documentTimeline.diffVersionPicker.actionTypeAndDate",
                              {
                                date: validateAndFormatDate({
                                  date: version.isoDate,
                                  formatString: "shortMonthName-shortDay-year"
                                }),
                                actionType: translatedActionTypeString
                              }
                            )}
                          </span>
                        </DroppableVersionItemSpan>
                        <Tooltip name={`action-date-tooltip-${index}`}>
                          {version.date} {version.time}
                        </Tooltip>
                        <DroppableVersionItemSpan
                          color="nav.25"
                          fontSize={2}
                          fontWeight="normal"
                        >
                          {version.user}
                        </DroppableVersionItemSpan>
                        {shouldShowStartPicker && (
                          <DraggablePickerButton
                            data-testid="timelineStartButton"
                            bottom="-20%"
                            indexType="startItemIndex"
                          />
                        )}
                      </DroppableVersionItem>
                    );
                  })}
                </TimelineItemsContainer>
              )}
            </TimelineContainer>
          </Sticky>
        </AbsoluteContainer>
      </DndProvider>
    );
  }
}

const DiffVersionPicker = withLabelContext(
  withTranslation()(_DiffVersionPicker)
);
export default DiffVersionPicker;

export const DocumentTimeline = ({
  documentPk,
  onTimelineChangesTaskChange,
  onTimelineSelectionChange,
  onCheckingDiff
}) => {
  const [timeline, setTimeline] = useState(null);
  const [fromActionIndex, setFromActionIndex] = useState(null);
  const [toActionIndex, setToActionIndex] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const actions = useRef([]);
  const documentChangesCache = useRef({});
  const getCurrentDocumentIndex = documents => {
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].id === documentPk) {
        return i;
      }
    }
    return null;
  };
  const getPreviousDocumentIndex = currentDocumentIndex => {
    const previousDocumentIndex = currentDocumentIndex - 1;
    if (previousDocumentIndex >= 0) {
      return previousDocumentIndex;
    }
    return null;
  };
  const getFromActionIndex = (
    previousDocumentIndex,
    currentDocumentIndex,
    documents
  ) => {
    // if there is a previous document version, get its first comparable action
    // if no previous version, get the current version's first comparable action
    if (
      previousDocumentIndex !== null &&
      documents[previousDocumentIndex].actions.length > 0
    ) {
      const previousDocumentActions = documents[previousDocumentIndex].actions;
      for (let i = previousDocumentActions.length - 1; i >= 0; i--) {
        if (previousDocumentActions[i].canBeCompared) {
          return i;
        }
      }
    } else {
      const currentDocument = currentDocumentIndex
        ? documents[currentDocumentIndex]
        : documents[0];
      for (let i = 0; i < currentDocument.actions.length; i++) {
        if (currentDocument.actions[i].canBeCompared) {
          return i;
        }
      }
    }
  };
  const getToActionIndex = (currentDocumentIndex, documents) => {
    // get the last comparable action from the current document
    // if there are no actions return null
    const currentDocumentActions =
      documents[currentDocumentIndex]?.actions || [];
    for (let i = currentDocumentActions.length - 1; i >= 0; i--) {
      if (currentDocumentActions[i].canBeCompared) {
        return i;
      }
    }
    return null;
  };

  // take the array of document versions and their actions and create an array with just those
  // actions as well as the indices for the ones being compared
  const getFlatActionsArrayWithStartAndEndIndices = (
    previousDocumentIndex,
    currentDocumentIndex,
    fromActionIndex,
    toActionIndex
  ) => {
    let actions = [];
    let fromActionGlobalIndex = fromActionIndex;
    let toActionGlobalIndex = toActionIndex;
    for (let i = 0; i < timeline.length; i++) {
      if (previousDocumentIndex === i) {
        fromActionGlobalIndex = actions.length + fromActionIndex;
      } else if (currentDocumentIndex === i) {
        if (toActionIndex !== null) {
          toActionGlobalIndex = actions.length + toActionIndex;
        } else {
          toActionGlobalIndex = actions.length - 1;
        }
      }
      const documentActions = timeline[i].actions;
      actions = [...actions, ...documentActions];
    }
    return [actions, fromActionGlobalIndex, toActionGlobalIndex];
  };
  const updateSelectedActions = (newFromActionIndex, newToActionIndex) => {
    if (newFromActionIndex > newToActionIndex) {
      return;
    }
    if (!actions.current[newFromActionIndex].canBeCompared) {
      return;
    }
    if (!actions.current[newToActionIndex].canBeCompared) {
      return;
    }

    setFromActionIndex(newFromActionIndex);
    setToActionIndex(newToActionIndex);
  };
  useEffect(() => {
    fetchQuery(environment, documentTimelineQuery, {
      documentPk: documentPk
    }).then(data => {
      if (!data) {
        return;
      }
      const timeline = data.documentView.timeline;
      setTimeline(timeline);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (timeline === null) {
      return;
    }
    const currentDocumentIndex = getCurrentDocumentIndex(timeline, documentPk);
    const previousDocumentIndex = getPreviousDocumentIndex(
      currentDocumentIndex
    );
    const fromActionIndex = getFromActionIndex(
      previousDocumentIndex,
      currentDocumentIndex,
      timeline
    );
    const toActionIndex = getToActionIndex(currentDocumentIndex, timeline);
    const [
      actionsList,
      fromActionGlobalIndex,
      toActionGlobalIndex
    ] = getFlatActionsArrayWithStartAndEndIndices(
      previousDocumentIndex,
      currentDocumentIndex,
      fromActionIndex,
      toActionIndex
    );
    setFromActionIndex(fromActionGlobalIndex);
    setToActionIndex(toActionGlobalIndex);
    actions.current = actionsList;
  }, [timeline]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      timeline === null ||
      fromActionIndex === null ||
      toActionIndex === null
    ) {
      return;
    }
    const fromActionPk = actions.current[fromActionIndex].id;
    const toActionPk = actions.current[toActionIndex].id;
    onTimelineSelectionChange(fromActionPk, toActionPk);
    const cacheKey = `${fromActionPk}-${toActionPk}`;
    const cachedChanges = documentChangesCache.current[cacheKey];
    if (cachedChanges) {
      const initialDocument = cachedChanges.initialDocument;
      const taskId = cachedChanges.taskId;
      onTimelineChangesTaskChange(initialDocument, taskId);
      return;
    }
    onCheckingDiff(true);
    fetchQuery(environment, documentChangesQuery, {
      fromPk: fromActionPk,
      toPk: toActionPk
    }).then(data => {
      const initialHtml = data.documentChanges.initialHtml;
      const initialDocument = data.documentChanges.initialDocument;
      const taskId = data.documentChanges.taskId;
      // need to make a copy in order to assign the html field because the object is read-only by default
      const initialDocumentCopy = { ...initialDocument };
      initialDocumentCopy.html = initialHtml;
      documentChangesCache.current[cacheKey] = {
        initialDocument: initialDocumentCopy,
        taskId
      };
      onCheckingDiff(false);
      onTimelineChangesTaskChange(initialDocumentCopy, taskId);
    });
  }, [fromActionIndex, toActionIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  const { t } = useTranslation();
  return (
    <Fragment>
      {expanded && (
        <DiffVersionPicker
          loading={timeline === null}
          versions={actions.current}
          startItemIndex={fromActionIndex}
          endItemIndex={toActionIndex}
          onStartIndexChange={newStartIndex =>
            updateSelectedActions(newStartIndex, toActionIndex)
          }
          onEndIndexChange={newEndIndex =>
            updateSelectedActions(fromActionIndex, newEndIndex)
          }
          onClose={() => setExpanded(false)}
        />
      )}
      {!expanded && (
        <ToggleButtonContainer>
          <FloatingButton
            id="timeline_button"
            onClick={() => setExpanded(!expanded)}
            icon={["fas", "clock"]}
            iconProps={{ size: "2x" }}
            aria-label={t("documentTimeline.diffVersionPicker.ariaLabel")}
            px={0}
          />
        </ToggleButtonContainer>
      )}
    </Fragment>
  );
};
