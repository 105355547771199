import graphql from "babel-plugin-relay/macro";
import { Box, Flex } from "grid-styled";
import { memo, useEffect, useState } from "react";
import { createFragmentContainer } from "react-relay";

import { subscribeToComments } from "../SubscribeToCommentsMutation";
import { unsubscribeFromComments } from "../UnsubscribeFromCommentsMutation";

import CommentInput from "./CommentInput";
import useCommentForm from "./hooks";

import ConfirmationModal from "pstat-anywhere/components/document_control/policy_action_comment_section/ConfirmationModal";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import Panel from "pstat-design-system/Panel";
import { Button } from "pstat-design-system/shared/Button";
import { H2, Text } from "pstat-design-system/text";



function _CommentPanel({
  document,
  documentSave,
  isEditPage,
  documentName,
  labels,
  type
}) {
  const {
    t,
    commentProcessing,
    value,
    handleTextChange,
    handleMentionAdd,
    handleSubmit,
    getData,
    isMentionSelected,
    usersToNotifyText,
    usersOptedOutText
  } = useCommentForm({ document, documentSave, isEditPage, type });

  const [subscribedToComments, setSubscribedToComments] = useState(
    document.subscribedToComments
  );
  const [
    showCommentNotificationUsers,
    setShowCommentNotificationUsers
  ] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  useEffect(() => {
    if (document?.subscribedToComments) {
      setSubscribedToComments(true);
    }
  }, [document?.subscribedToComments]);

  if (!document || !document.canComment) {
    return null;
  }
  const documentLabel = labels.documentLabel.toLowerCase();

  function unsubscribeFromCommentsCallback() {
    unsubscribeFromComments(environment, document, (response, errors) => {
      if (response.unsubscribeFromComments.ok) {
        createSuccessMessage(
          t(
            "documentControl.view.commentForm.unsubscribedfromcomments.success",
            { documentName }
          )
        );
        setSubscribedToComments(!subscribedToComments);
      } else {
        createErrorMessage(
          t("documentControl.view.commentForm.unsubscribedfromcomments.error", {
            documentName
          })
        );
      }
    });
    setConfirmationModalIsOpen(false);
  }

  function onCommentSubscriptionChange() {
    if (subscribedToComments) {
      setConfirmationModalIsOpen(true);
    } else {
      subscribeToComments(environment, document, (response, errors) => {
        if (response.subscribeToComments.ok) {
          createSuccessMessage(
            t("documentControl.view.commentForm.subscribedtocomments.success", {
              documentName
            })
          );
          setSubscribedToComments(!subscribedToComments);
        } else {
          createErrorMessage(
            t("documentControl.view.commentForm.subscribedtocomments.error", {
              documentName
            })
          );
        }
      });
    }
  }

  return (
    <Panel
      id="comments"
      renderHeader={() => (
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <H2 color="nav.0" fontWeight="normal">
              {t("documentControl.view.filters.comments")}
            </H2>
          </Box>
          <CheckboxGroup
            selectedValues={
              subscribedToComments ? [subscribedToComments] : null
            }
            onChange={onCommentSubscriptionChange}
          >
            <Checkbox
              value={subscribedToComments}
              label={t("documentControl.view.commentForm.subscribedtocomments")}
            />
          </CheckboxGroup>
        </Flex>
      )}
    >
      <CommentInput
        handleTextChange={handleTextChange}
        handleMentionAdd={handleMentionAdd}
        getData={getData}
        isMentionSelected={isMentionSelected}
        value={value}
        onFocus={() => setShowCommentNotificationUsers(true)}
      />
      {showCommentNotificationUsers && usersToNotifyText && (
        <Flex>
          <Text fontWeight="medium" mr={1}>
            {t("documentControl.view.commentForm.commentPanel.note")}
          </Text>
          <Box>
            <Text>
              {t("documentControl.view.commentForm.commentPanel.usersToNotify")}
            </Text>
            <Text fontWeight="medium" ml={1}>
              {usersToNotifyText}
            </Text>
          </Box>
        </Flex>
      )}
      {showCommentNotificationUsers && usersOptedOutText && (
        <Flex>
          <Box>
            <Text>
              {t(
                "documentControl.view.commentForm.commentPanel.usersToNotNotify"
              )}
            </Text>
            <Text fontWeight="medium" ml={1}>
              {usersOptedOutText}
            </Text>
          </Box>
        </Flex>
      )}
      <Flex p={1}>
        <Box>
          <Button
            buttonStyle="secondary"
            onClick={handleSubmit}
            disabled={value === ""}
            processing={commentProcessing}
          >
            {t("buttons.comment")}
          </Button>
        </Box>
      </Flex>
      <ConfirmationModal
        isOpen={confirmationModalIsOpen}
        onSubmit={unsubscribeFromCommentsCallback}
        onClose={() => setConfirmationModalIsOpen(false)}
        title={t(
          "documentControl.view.commentForm.unsubscribeFromComments.confirmationModal.title"
        )}
        text={t(
          "documentControl.view.commentForm.unsubscribeFromComments.confirmationModal.text",
          { documentLabel }
        )}
        submitButtonText={t(
          "documentControl.view.commentForm.unsubscribeFromComments.confirmationModal.submitButton"
        )}
        processing={false}
      />
    </Panel>
  );
}

export const CommentPanel = memo(withLabelContext(_CommentPanel));
export default createFragmentContainer(
  CommentPanel,
  graphql`
    fragment commentForm_document on DocumentType {
      id
      pk
      canComment
      subscribedToComments
      usersNotificationComment {
        usersNotificationCommentOn {
          pk
          firstName
          lastName
        }
        usersNotificationCommentOff {
          pk
          firstName
          lastName
        }
      }
    }
  `
);
