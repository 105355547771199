import { withTranslation } from "react-i18next";

import Panel from "pstat-design-system/Panel";
import {Text} from "pstat-design-system/text";

export const RegulationItem = ({ key, name, isLast, newLine }) => (
  <Text p={1} key={key}>
    {`${name}${ isLast || newLine ? '' : ', '}`}
  </Text>
);

const StandardsAndRegulationsPanel = ({ t, regulations }) => (
  <Panel
    id="standards-and-regulations-panel"
    name={t("documentControl.view.standardsAndRegulation.panel.title")}
  >
    {regulations.map((regulation, index) => {
      return <RegulationItem
        {...regulation}
        isLast={index === (regulations.length - 1)}
      />
    })}
  </Panel>
);

export default withTranslation()(StandardsAndRegulationsPanel);
