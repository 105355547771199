import graphql from "babel-plugin-relay/macro";

const blocsQuery = graphql`
  query BlocChoicesQuery {
    blocChoicesPendingReport {
      blocs {
        pk
        name
      }
    }
  }
`;
export default blocsQuery;
