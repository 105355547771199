import graphql from "babel-plugin-relay/macro";

export const BlocManagementPageQuery = graphql`
  query BlocManagementPageQuery($sort: String) {
    blocs(sort: $sort) {
      error {
        statusCode
        message
      }
      blocs {
        pk
        name
        tenants(sort: "name") {
          subdomain
        }
        isEditable
      }
    }
  }
`;

export default BlocManagementPageQuery;
