import { Flex } from "grid-styled";
import PropTypes from "prop-types";
import { themeGet } from "styled-system";
import system from "system-components";

export const Link = system(
  {
    is: "a",
    color: "secondary.0",
    tabIndex: "0",
    fontFamily: "font",
    fontSize: 2
  },
  "space",
  "display",
  "alignItems",
  "width",
  "height",
  "fontWeight"
).extend`
  color: ${props =>
    props.color && themeGet(props.color)
      ? themeGet(props.color) // Direct passing of color is more important, then passing `shade`
      : props.light
      ? themeGet("colors.primary.0")
      : themeGet("colors.secondary.0")};
  text-decoration: ${props =>
    props.textDecoration ? props.textDecoration : "none"};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { /*IE HACK */
    &, & * {
      max-width: inherit;
      min-width: inherit;
      width: inherit;
    }
  }
`;
//IE 11 fails to limit the width of this element in a table by default

Link.displayName = "Link";

const FocusHolder = system({
  is: Flex,
  display: "inline-flex",
  alignItems: "center",
  tabIndex: "-1",
  height: "100%"
}).extend`
  &:focus {
    outline: 0;
  }
`;
FocusHolder.displayName = "FocusHolder";

const FocusBorder = system({
  is: Flex,
  alignItems: "center",
  border: "dashed 1px",
  borderColor: "transparent",
  borderRadius: 1,
  p: 1
}).extend`
  &:focus {
    outline: 0;
  }
  ${Link}:focus & {
    border: dashed 1px;
    border-color: ${props =>
      props.light ? themeGet("colors.nav.100") : themeGet("colors.nav.25")};
  }
`;
FocusBorder.displayName = "FocusBorder";

export const NewWindowLink = props => (
  <FocusLink
    target="_blank"
    onActivation={() => window.open(props.to, "_blank")}
    href={props.to}
    notOverrideOnClick={true}
    {...props}
  />
);

export const FocusLink = props => {
  const keyboardHandler = event => {
    if (event.key === "Enter" || event.key === " ") {
      if (props.onActivation) {
        event.preventDefault();
        props.onActivation();
      }
    }
  };
  const {
    light,
    dataTestid,
    external,
    notOverrideOnClick,
    ...otherProps
  } = props;
  let extra = {};
  if (props.onActivation && !notOverrideOnClick) {
    // If the component has an `onClick` prop
    // Found's Link  will call that function and
    // even deffer to the default browser behaviour.
    // It will end up refreshing the page.
    extra.onClick = props.onActivation;
  }
  return (
    <Link
      {...otherProps}
      {...extra}
      light={light}
      display="inline-block"
      onKeyDown={keyboardHandler}
      data-testid={dataTestid}
    >
      <FocusHolder>
        <FocusBorder light={light}>{props.children}</FocusBorder>
      </FocusHolder>
    </Link>
  );
};

FocusLink.propTypes = {
  onActivation: PropTypes.func
};
