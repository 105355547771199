import { css } from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import colors from "../../../themes/policystat/colors";
import theme from "../../../themes/policystat/theme";

export const baseTextareaStyles = css`
  border-radius: ${theme.radii[1]}px;
  outline: none;
  padding: ${theme.space[3]}px;
  font-size: ${themeGet("fontSizes.2")};
  font-weight: normal;
  box-shadow: inset 1px 1px 0 0 ${theme.colors.nav[90]};
  border: ${theme.borders[1]};
  border-color: ${theme.colors.nav[80]};
  color: ${theme.colors.nav[0]};

  &::disabled {
    background-color: ${theme.colors.nav[95]};
    cursor: not-allowed;
  }

  &::focus {
    border: ${theme.borders[1]};
    border-color: ${theme.colors.secondary[0]};
  }
  &::placeholder {
    color: ${theme.colors.nav["25"]};
  }

  &:invalid:not(:focus) {
    border: solid 2px ${theme.colors.messages["error"]};
  }
`;

export const Textarea = system(
  {
    is: "textarea",
    fontFamily: theme.fontFamily,
    bg: colors.nav[100],
    border: theme.borders[1],
    borderColor: colors.nav[80],
    color: colors.nav[0]
  },
  "space",
  "width",
  "fontSize",
  "fontWeight",
  "height",
  "borderRadius"
).extend`
  box-sizing: border-box;
  ${baseTextareaStyles}
`;

export default Textarea;
