import { Flex } from "grid-styled";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { TextInputWithIcon } from "pstat-design-system/inputs/TextInput";
import MenuLink, {
  CenteringFlex,
  SelectedMenuLabel
} from "pstat-anywhere/components/partials/MenuLink";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import DropdownMenu from "pstat-anywhere/components/partials/DropdownMenu";

const DropdownBlocItem = styled.li`
  display: block;
  float: none;
`;

const MenuItemContainer = styled(CenteringFlex).attrs({
  className: "accessible-blocs-items"
})`
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  padding: ${themeGet("space.1")}px;
`;
MenuItemContainer.displayName = "MenuItemContainer";

const AccessibleBlocDropdownItems = ({
  selectedBlocPk,
  items,
  systemDocumentTemplate
}) => {
  const { t } = useTranslation();
  const [filterQuery, setFilterQuery] = useState("");
  const handleSearchValue = event => setFilterQuery(event.target.value);
  const { bloc: templateBloc, document } = systemDocumentTemplate || {};
  const templateLink = `/policy/${document?.pk}/`;

  const filterBlocsByName = items => {
    const query = filterQuery.toLowerCase();
    return items.filter(
      ({ bloc: { name: blocName } }) =>
        blocName.toLowerCase().indexOf(query) !== -1
    );
  };

  const filteredTenants = filterBlocsByName(items);
  const blocItems = filteredTenants.map(
    ({ bloc: { pk, name }, pk: documentPk }) => {
      return (
        <DropdownBlocItem key={pk}>
          {selectedBlocPk === pk ? (
            <SelectedMenuLabel menuItemContainer={MenuItemContainer}>
              {name}
            </SelectedMenuLabel>
          ) : (
            <MenuLink
              href={`/policy/${documentPk}/`}
              menuItemContainer={MenuItemContainer}
            >
              {name}
            </MenuLink>
          )}
        </DropdownBlocItem>
      );
    }
  );
  return (
    <Fragment>
      <Flex px={4} py={2}>
        <TextInputWithIcon
          icon={["far", "search"]}
          side="left"
          value={filterQuery}
          onChange={handleSearchValue}
          placeholder={t("siteSelector.filterLocation")}
        />
      </Flex>
      {systemDocumentTemplate && (
        <DropdownBlocItem>
          <MenuLink menuItemContainer={MenuItemContainer} href={templateLink}>
            {templateBloc.name}
          </MenuLink>
        </DropdownBlocItem>
      )}
      {blocItems}
    </Fragment>
  );
};

const ButtonBadge = system({
  is: "button",
  textAlign: "left",
  bg: "transparent",
  border: 1,
  borderColor: "primary.0",
  borderRadius: 3,
  px: 4,
  py: 1,
  my: 1,
  fontSize: [1],
  fontWeight: "medium",
  minWidth: "28px",
  color: "secondary.0"
}).extend`
  cursor: pointer;
  text-decoration: underline;
`;

const BlocSelector = props => {
  const { onClick, title } = props;
  return (
    <ButtonBadge
      onClick={onClick}
      data-testid="bloc-selector-button"
      data-tip={true}
      data-for="applicability-tenants-tooltip"
    >
      {title} <StyledFontAwesomeIcon icon={["far", "chevron-down"]} />
    </ButtonBadge>
  );
};

export const AccessibleBlocsForAdoptedDocuments = ({
  title,
  items,
  blocPk,
  systemDocumentTemplate
}) => {
  const [expanded, setExpanded] = useState(false);
  const closeDropdown = () => setExpanded(false);
  const toggle = () => setExpanded(!expanded);

  return (
    <DropdownMenu
      dropdownSelector={() => <BlocSelector onClick={toggle} title={title} />}
      expanded={expanded}
      rtl={true}
      toggle={toggle}
      closeMenu={closeDropdown}
      width={"300px"}
    >
      <AccessibleBlocDropdownItems
        items={items}
        selectedBlocPk={blocPk}
        systemDocumentTemplate={systemDocumentTemplate}
      />
    </DropdownMenu>
  );
};
