/* eslint-disable sort-imports */
import "./public_path";
import "./i18n";

import * as Sentry from "@sentry/browser";
import { render } from "react-dom";

import App from "./App";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://0df11783f8f748a78110c56cfd20d21d@sentry.io/1810316",
    // attempt to ignore errors related to a pwd manager extension
    // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
    ignoreErrors: [
      "Non-Error promise rejection captured with value: Object Not Found Matching Id"
    ],
    environment: window.ENVIRONMENT
  });
}

render(<App />, document.getElementById("root"));
