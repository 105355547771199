import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation NoticeClearMutation {
    noticeClearAll {
      ok
    }
  }
`;

export function clearAllNotifications({ callback }) {
  commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    }
  });
}
