import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { RadioGroupFormElement } from "pstat-design-system/inputs/FormElements";
import Radio from "pstat-design-system/inputs/Radio";
import { ExpandablePanel } from "pstat-design-system/Panel";

const RESTRICTED = "yes";
const NOT_RESTRICTED = "no";

const HIDE_PDF_WATERMARK = "yes";
const SHOW_PDF_WATERMARK = "no";

const convertBooleanToRestrictedValue = value =>
  value ? RESTRICTED : NOT_RESTRICTED;
const convertRestrictedValueToBoolean = value => value === RESTRICTED;
const converBooleanToHidePDFWatermarkValue = value =>
  value ? HIDE_PDF_WATERMARK : SHOW_PDF_WATERMARK;
const convertHidePDFWatermarkToBoolean = value => value === HIDE_PDF_WATERMARK;

const Advanced = props => {
  const { t } = useTranslation();
  const { policyForm, onHidePDFWatermarkChange, onRestrictedChange } = props;
  return (
    <ExpandablePanel
      id="advanced"
      headerText={t("documentControl.edit.advancedSection")}
      hideHeaderBorder={true}
    >
      <AdvancedOptions
        policyForm={policyForm}
        onHidePDFWatermarkChange={onHidePDFWatermarkChange}
        onRestrictedChange={onRestrictedChange}
      />
    </ExpandablePanel>
  );
};

Advanced.propTypes = {
  policyForm: PropTypes.object.isRequired,
  onHidePDFWatermarkChange: PropTypes.func.isRequired,
  onRestrictedChange: PropTypes.func.isRequired
};

const AdvancedOptions = ({
  policyForm,
  onHidePDFWatermarkChange,
  onRestrictedChange
}) => (
  <AdvancedOptionsContainer>
    <HidePDFWatermark
      value={policyForm.hidePDFWatermark}
      handleChange={onHidePDFWatermarkChange}
    />
    <VisibilityRestrictions
      value={policyForm.restricted}
      handleChange={onRestrictedChange}
    />
  </AdvancedOptionsContainer>
);

AdvancedOptions.propTypes = {
  policyForm: PropTypes.object.isRequired,
  onHidePDFWatermarkChange: PropTypes.func.isRequired,
  onRestrictedChange: PropTypes.func.isRequired
};

const HidePDFWatermark = ({ value, handleChange }) => {
  const { t } = useTranslation();
  return (
    <RadioGroupFormElement
      header={t("documentControl.create.advanced.hidePDF")}
      name="hidePDFWatermark"
      selectedValue={converBooleanToHidePDFWatermarkValue(value)}
      onChange={value => handleChange(convertHidePDFWatermarkToBoolean(value))}
      inline={true}
    >
      <Radio value={HIDE_PDF_WATERMARK} label={t("yes")} inline={true} />
      <Radio value={SHOW_PDF_WATERMARK} label={t("no")} inline={true} />
    </RadioGroupFormElement>
  );
};

HidePDFWatermark.propTypes = {
  value: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

const VisibilityRestrictions = ({ value, handleChange }) => {
  const { t } = useTranslation();
  return (
    <RadioGroupFormElement
      header={t("documentControl.create.advanced.visibilityRestrictions")}
      name="restricted"
      selectedValue={convertBooleanToRestrictedValue(value)}
      onChange={value => handleChange(convertRestrictedValueToBoolean(value))}
      inline={true}
    >
      <Radio
        value={NOT_RESTRICTED}
        label={t("documentControl.create.advanced.viewableForAllUsers")}
        inline={true}
      />
      <Radio
        value={RESTRICTED}
        label={t("documentControl.create.advanced.onlyUsersWithPermissions")}
        inline={true}
      />
    </RadioGroupFormElement>
  );
};

VisibilityRestrictions.propTypes = {
  value: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

const AdvancedOptionsContainer = styled.div`
  margin-top: 20px;
`;

export default Advanced;
