import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation undoMSWordUploadMutation(
    $input: UndoMSDocumentUploadMutationInput!
  ) {
    undoMswordUpload(input: $input) {
      ok
      reasons
    }
  }
`;

const undoMSWordDocumentUpload = (environment, variables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default undoMSWordDocumentUpload;
