import { Box, Flex } from "grid-styled";
import { Component, createRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withTranslation } from "react-i18next";
import system from "system-components";

import colors from "../../../themes/policystat/colors";
import InlineInputWrapper from "../InlineInputWrapper";
import { Button } from "../shared/Button";

import { ForwardingTextInput as TextInput } from "./TextInput";

const CopyInput = system({
  is: TextInput,
  disabledStyle: {
    "background-color": colors.nav["100"],
    cursor: "text"
  },
  width: "100%"
}).extend`
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

class CopyLink extends Component {
  constructor(props) {
    super(props);
    this.copyLinkRef = createRef();
  }

  handleClick = () => {
    const input = this.copyLinkRef.current;
    const inputLength = input.value.length;
    input.focus();
    input.setSelectionRange(0, inputLength);
  };

  render() {
    const { url, fieldsetWidth, t } = this.props;
    return (
      <InlineInputWrapper width={fieldsetWidth}>
        <Flex width="100%" alignItems="center">
          <Box flex={1}>
            <CopyInput
              className="copy-link-input"
              innerRef={this.copyLinkRef}
              onClick={this.handleClick}
              value={url}
              data-testid="copy-link-input"
              readOnly={true}
            />
          </Box>
          <CopyToClipboard text={url}>
            <Button
              data-tip
              data-for="copy-link-tooltip"
              data-event-off="mouseup keyup"
              data-event="mousedown"
              data-delay-hide="2000"
              buttonStyle="tertiary"
            >
              {t("copyLink")}
            </Button>
          </CopyToClipboard>
        </Flex>
      </InlineInputWrapper>
    );
  }
}

export default withTranslation()(CopyLink);
