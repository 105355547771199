/**
 * @flow
 * @relayHash 2093c8e0f6e3d41f8d45dd954a452451
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type deleteDocumentAttachmentMutationVariables = {|
  attachmentPk: number
|};
export type deleteDocumentAttachmentMutationResponse = {|
  +deleteDocumentAttachment: ?{|
    +ok: ?boolean
  |}
|};
export type deleteDocumentAttachmentMutation = {|
  variables: deleteDocumentAttachmentMutationVariables,
  response: deleteDocumentAttachmentMutationResponse,
|};
*/


/*
mutation deleteDocumentAttachmentMutation(
  $attachmentPk: Int!
) {
  deleteDocumentAttachment(attachmentPk: $attachmentPk) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "attachmentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteDocumentAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attachmentPk",
        "variableName": "attachmentPk",
        "type": "Int!"
      }
    ],
    "concreteType": "DeleteDocumentAttachmentMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "deleteDocumentAttachmentMutation",
  "id": "197d62c2bae00a6bb9eb59f8eb105e91",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "deleteDocumentAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "deleteDocumentAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation deleteDocumentAttachmentMutation(\n  $attachmentPk: Int!\n) {\n  deleteDocumentAttachment(attachmentPk: $attachmentPk) {\n    ok\n  }\n}\n";
(node/*: any*/).hash = '1efa6fa95940e989c1dfe15e73c1b867';
module.exports = node;
