import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import EditBlocModal from "./EditBlocModal";

import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { InfoBanner } from "pstat-design-system/banner";
import { InfoExclamationIcon } from "pstat-design-system/banner/BannerIcons";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


const InfoText = props => (
  <Fragment>
    <InfoExclamationIcon />
    <Text id={props.id} color="nav.100" fontSize={props.fontSize}>
      {props.children}
    </Text>
  </Fragment>
);

export const ActionPanel = ({
  selectedTenantPks,
  initiallySelectedPks,
  onSelectTenants,
  blocName,
  documentSettings,
  router
}) => {
  const { t } = useTranslation();
  const atLeastTwoSitesSelected = selectedTenantPks.length >= 2;

  return (
    <Fragment>
      {atLeastTwoSitesSelected ? (
        <Fragment>
          {initiallySelectedPks.length > 0 ? (
            <Button
              id="update-selected-tenants-button"
              buttonStyle="primary"
              onClick={() => onSelectTenants(selectedTenantPks)}
            >
              {t("bloc.details.updateSites", { blocName })}
            </Button>
          ) : (
            <CreateBlocButton
              selectedTenantPks={selectedTenantPks}
              documentSettings={documentSettings}
              router={router}
            />
          )}
        </Fragment>
      ) : (
        <InfoText>{t("bloc.sites.atLeastTwoSitesMessage")}</InfoText>
      )}
    </Fragment>
  );
};

const CreateBlocButton = ({ selectedTenantPks, documentSettings, router }) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <Fragment>
      <Button
        id="create-new-bloc-button"
        buttonStyle="primary"
        onClick={() => setModalIsOpen(true)}
      >
        {t("bloc.sites.createBlocButton")}
      </Button>
      <EditBlocModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        currentBlocName={""}
        documentSettings={documentSettings}
        currentDocumentSettingsPk={null}
        selectedTenantPks={selectedTenantPks}
        onViewOrEditSitesClicked={() => setModalIsOpen(false)}
        isNewBloc={true}
        selectedTenantsChanged={selectedTenantPks => true}
        router={router}
      />
    </Fragment>
  );
};

const BlocManagement = ({
  location,
  variables,
  route,
  router,
  data,
  tableType,
  documentSettings
}) => {
  const { t } = useTranslation();
  const tabs = [
    {
      id: "blocs_manage",
      textKey: "bloc.manage.blocsTab",
      path: "bloc"
    },
    {
      id: "bloc_tenants",
      textKey: "bloc.manage.sitesTab",
      path: "bloc/sites"
    }
  ];
  return (
    <AdvancedReportPage
      variables={variables}
      data={data}
      route={route}
      router={router}
      location={location}
      tabs={tabs}
      headerText={t("bloc.manage.heading")}
      renderHeader={() => (
        <Fragment>
          {route && route.data.routeName !== "bloc_tenants" && (
            <Button
              id="create-new-bloc-button"
              buttonStyle="secondary"
              onClick={() => router.push(`${getRootUrl()}/bloc/sites`)}
            >
              {t("bloc.sites.createBlocButton")}
            </Button>
          )}
        </Fragment>
      )}
      noContentMessage={t("bloc.manage.emptyMessage")}
      showTabsPanel={true}
      tableType={tableType}
      showPagination={false}
      customOnRow={() => {}}
      frozenColumnTable={true}
      showActionButtonPanel={true}
      renderActionPanel={selectedSitePks => (
        <ActionPanel
          selectedTenantPks={selectedSitePks}
          initiallySelectedPks={[]}
          documentSettings={documentSettings}
          router={router}
        />
      )}
      singularSelectedActionsLabel={t("bloc.sites.singularSitesLabel")}
      pluralSelectedActionsLabel={t("bloc.sites.pluralSitesLabel")}
    />
  );
};

export const BlocManagementPage = ({ blocs, ...otherProps }) => {
  return (
    <BlocManagement
      data={blocs?.blocs}
      tableType="blocs_management"
      {...otherProps}
    />
  );
};

export const BlocTenantsPage = ({ canAdminTenants, ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <InfoBanner message={t("bloc.sites.bannerMessage")} width="100%" />
      <BlocManagement
        data={canAdminTenants?.tenants}
        tableType="bloc_tenants"
        documentSettings={canAdminTenants?.documentSettings}
        {...otherProps}
      />
    </Fragment>
  );
};
