import graphql from "babel-plugin-relay/macro";

export const AcknowledgementListQuery = graphql`
  query AcknowledgementListQuery(
    $linePk: Int!
  ) {
    userAndUserGroupsAcknowlegmentByLine(linePk: $linePk) {
      users {
        display
        recurringAckPk
      }
      userGroups {
        display
        recurringAckPk
      }
    }
  }
`;

export default AcknowledgementListQuery;
