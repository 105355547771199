import { Route } from "found";


import BlocDetailPage from "./BlocDetailPage";
import BlocDetailPageQuery from "./BlocDetailPageQuery";
import { BlocManagementPage, BlocTenantsPage } from "./BlocManagementPage";
import BlocManagementPageQuery from "./BlocManagementPageQuery";
import BlocTenantsPageQuery from "./BlocTenantsPageQuery";

import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";
import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";
import { ContentContainer } from "pstat-anywhere/components/pages";

const routes = (
  <Route path="bloc">
    <Route
      path=""
      query={BlocManagementPageQuery}
      data={{
        routeName: "blocs_manage"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        sort: location.query.sort
      })}
      render={({ props }) => (
        <ErrorCheckWrapper queryName="blocs" {...props}>
          <ContentContainer>
            <PageTitleWrapper titleKey="bloc.manage.documentTitle">
              <BlocManagementPage {...props} />
            </PageTitleWrapper>
          </ContentContainer>
        </ErrorCheckWrapper>
      )}
    />
    <Route
      path="sites"
      query={BlocTenantsPageQuery}
      data={{
        routeName: "bloc_tenants"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        sort: location.query.sort
      })}
      render={({ props }) => (
        <ErrorCheckWrapper queryName="canAdminTenants" {...props}>
          <ContentContainer>
            <BlocTenantsPage {...props} />
          </ContentContainer>
        </ErrorCheckWrapper>
      )}
    />
    <Route
      path=":blocPk(\d+)/:edit?"
      query={BlocDetailPageQuery}
      getData={({ params }) => ({
        ...params,
        routeName: "bloc_details"
      })}
      prepareVariables={(params, { location }) => ({
        ...params,
        sort: location.query.sort
      })}
      render={({ props }) => (
        <ErrorCheckWrapper queryName="blocDetails" {...props}>
          <ContentContainer>
            <BlocDetailPage {...props} />
          </ContentContainer>
        </ErrorCheckWrapper>
      )}
    />
  </Route>
);

export default routes;
