// @flow
import "react-toastify/dist/ReactToastify.min.css";

import { Box, Flex } from "grid-styled";
import React, { Component, Suspense } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

import { EnvironmentContext } from "./context_providers/EnvironmentContext";
import SessionProvider from "./context_providers/SessionProvider";
import { environment } from "./relay";
import { Router } from "./router";
import theme from "./themes/policystat/theme";
import doInjectHotjar from "./utils/hotjar/doInject";

import doInjectGlobal from "pstat-design-system/utils/doInjectGlobal";
import MessagesContainer from "pstat-design-system/message/MessagesContainer";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import ErrorBoundary from "pstat-anywhere/components/pages/ErrorBoundary";

// Initialize aXe
if (process.env.NODE_ENV !== "production") {
  const axe = require("react-axe");
  axe(React, ReactDOM, 1000);
}

doInjectGlobal();

class App extends Component {
  constructor(props) {
    super(props);

    // Use optional chaining cause Opera 72 does not support location.hostname
    // And that correlates with eslint compat/compat
    // eslint-disable-next-line compat/compat
    doInjectHotjar(window?.location?.hostname);
  }
  render() {
    return (
      <Suspense
        fallback={
          <Flex justify="center" align="center" mt={10}>
            <Box>
              <LoadingSpinner size={theme.space[7]} />
            </Box>
          </Flex>
        }
      >
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <SessionProvider environment={environment}>
              <MessagesContainer />
              <EnvironmentContext.Provider value={environment}>
                <Router environment={environment} />
              </EnvironmentContext.Provider>
            </SessionProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Suspense>
    );
  }
}

export default App;
