import { Box, Flex } from "grid-styled";
import { rgba } from "polished";
import ReactModal from "react-modal";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import theme from "../../../themes/policystat/theme";

import ModalHeader from "./ModalHeader";

if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

const ModalFlex = system({
  is: Flex,
  flexDirection: "column",
  height: "100%"
}).extend`
  overflow: auto;
`;

const ModalHeight = system({
  is: Flex,
  flexDirection: "column",
  flex: 1,
  minHeight: 250
});

const ContentFlex = system({
  is: Flex,
  flexDirection: "column",
  flex: 1,
  minWidth: [320, 320, 500]
}).extend`
  overflow: auto;
`;

const LoadingContent = system({
  is: Flex,
  flexDirection: "column",
  alignItems: "center",
  p: 7
});

// see:  https://github.com/styled-components/styled-components/issues/1494
const ReactModalAdapter = ({
  id,
  className,
  overlayClassName,
  modalClassName,
  isLoadingModal,
  minWidth,
  renderFooter,
  ...props
}) => (
  <ReactModal
    className={modalClassName}
    overlayClassName={overlayClassName}
    portalClassName={className}
    {...props}
  >
    <ModalFlex id={id}>
      {isLoadingModal ? (
        <LoadingContent>{props.children}</LoadingContent>
      ) : (
        <ModalHeight>
          <Box>
            <ModalHeader {...props} />
          </Box>
          <Flex flexDirection="column" justifyContent="space-between" flex={1}>
            <ContentFlex minWidth={minWidth}>{props.children}</ContentFlex>
            {renderFooter && renderFooter()}
          </Flex>
        </ModalHeight>
      )}
    </ModalFlex>
  </ReactModal>
);

// http://lynn.io/2014/02/22/modalin/
const BaseStyledReactModal = styled(ReactModalAdapter).attrs({
  overlayClassName: "reactModalOverlay",
  modalClassName: "reactModal"
})`
  & .reactModalOverlay {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: ${props => (props.parentSelector ? "absolute" : "fixed")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${rgba(
      theme.colors.nav[55], // Need to use theme directly, cause usage of function as argument for rgba causing error
      0.5
    )};
    z-index: ${themeGet("layers.modalBg", 9000)};
  }
  & .reactModal {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: 100vw;
    position: fixed;
    background-color: ${themeGet("colors.nav.100")};
    z-index: ${themeGet("layers.modal", 9000)};
    box-shadow: 0 0 30px 0 ${rgba(theme.colors.nav[0], 0.45)}; // Same here
  }
  & .reactModal:focus {
    outline: none;
  }
  & .reactModal:fullscreen footer {
    bottom: 0;
  }
`;

export const FullPageModal = styled(BaseStyledReactModal)`
  & .reactModal {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: ${themeGet("colors.nav.95")};
    z-index: ${themeGet("layers.modal", 9000)};
    box-shadow: 0 0 30px 0 ${rgba(theme.colors.nav[0], 0.45)};
  }
`;

const StyledLoadingModal = styled(BaseStyledReactModal)`
  & .reactModal {
    width: inherit;
    height: inherit;
    max-height: calc(100% - 100px);
    max-width: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: ${themeGet("radii.1")}px;
  }
`;

export const LoadingModal = ({ children, ...otherProps }) => (
  <StyledLoadingModal {...otherProps} isLoadingModal={true}>
    {children}
  </StyledLoadingModal>
);

const StyledReactModal = styled(BaseStyledReactModal)`
  @media (min-width: ${themeGet("breakpoints.1")}) {
    & .reactModal {
      width: inherit;
      height: inherit;
      max-height: calc(100% - 100px);
      max-width: calc(100% - 100px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: ${themeGet("radii.1")}px;
    }
  }
`;

StyledReactModal.displayName = "StyledReactModal";

export default StyledReactModal;
