import { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from "react-accessible-accordion";
import { withTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";
import { Flex } from "grid-styled";

import { withLabelContext } from "../../../../context_providers/LabelContext";
import { StyledFontAwesomeIcon } from "../../../partials/icons";
import {
  AUTHORS,
  CATEGORIES,
  SELECT_ALL_VALUE,
  TAGS,
  TITLE_ONLY_VALUE
} from "../constants";

import { FocusBorder } from "./";

import { fontFamily } from "pstat-design-system/utils";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";

const StyledBody = system({
  is: Flex,
  bg: "nav.95",
  color: "nav.0",
  height: "100%",
  justifyContent: "space-between",
  flexDirection: "column",
  flex: "1"
}).extend`
  ${fontFamily};
  overflow: auto;
  .accordion {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }
  .accordion__body {
    display: block;
    background-color: ${themeGet("colors.nav.100")};
    box-shadow: 0px 2px 0px 0px ${themeGet("colors.nav.90")}
  }

  .accordion__body--hidden {
      display: none;
  }

  [aria-selected='true']{
    background-color: ${themeGet("colors.nav.100")};
    box-shadow: 0px 0px 0px 2px ${themeGet("colors.nav.90")}
  }

  .accordion__title:focus {
    outline:none;
  }
`;

const StyledAccordionItemTitle = system({
  is: AccordionItemTitle
}).extend`
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const AccordionTitleFocusBorder = system({
  is: FocusBorder,
  display: "inline-block",
  color: "secondary.0"
}).extend`
  ${StyledAccordionItemTitle}:focus & {
    border: dashed 1px;
  }
`;

const StyledAccordionItem = system({
  is: AccordionItem
}).extend`
  flex-shrink: 0;
  ${props => (props.expanded ? `overflow-y: auto; flex-shrink: 1;` : ``)}
`;

const AccordionTitleFocusBlocker = system({
  px: 2,
  py: 1
}).extend`
  &:focus {
    outline: 0;
  }
`;

const StyledAccordionTitle = props => (
  <AccordionTitleFocusBlocker tabIndex="-1">
    <AccordionTitleFocusBorder>{props.children}</AccordionTitleFocusBorder>
  </AccordionTitleFocusBlocker>
);

const Divider = system({
  borderTop: 1,
  borderColor: "nav.80"
});

class _FiltersAccordion extends Component {
  getSelectedCountText = selectedValues => {
    const { t } = this.props;
    if (
      selectedValues.length === 0 ||
      selectedValues.indexOf(SELECT_ALL_VALUE) > -1
    ) {
      return `(${t("acknowledgments.mine.tabs.all")})`;
    } else {
      return t(
        "documentSearch.searchFilters.filtersAccordion.getSelectedCountText.numberSelected",
        { selectedValuesLength: selectedValues.length }
      );
    }
  };

  handleAuthorsChange = value => {
    if (value === SELECT_ALL_VALUE) {
      this.props.onSelectAllAuthors();
    } else {
      this.props.onAuthorsChange(value);
    }
  };

  handleCategoriesChange = value => {
    if (value === SELECT_ALL_VALUE) {
      this.props.onSelectAllCategories();
    } else {
      this.props.onCategoriesChange(value);
    }
  };

  handleTagsChange = value => {
    if (value === SELECT_ALL_VALUE) {
      this.props.onSelectAllTags();
    } else {
      this.props.onTagsChange(value);
    }
  };

  render() {
    const {
      t,
      labels: { categoryLabelPlural, authorLabelPlural, referencesLabelPlural },
      updateFilterData,
      titleOnly,
      onTitleOnlyChange,
      onExpandedSectionChange,
      expanded,
      selectedCategories,
      selectedTags,
      selectedAuthors,
      filterOptions,
      fetchFilterOptions
    } = this.props;
    if (!filterOptions) {
      fetchFilterOptions();
      return (
        <StyledBody alignItems="center">
          <LoadingSpinner size={100} />
        </StyledBody>
      );
    }
    updateFilterData(filterOptions);
    const { authors, categories, tags } = filterOptions;

    // Because of array.sort edits existing array - we need create copy
    // Otherwise - we will get an error
    const renderAuthorsCheckboxes = [...authors]
      .sort((a, b) => {
        if (a.userDisplay < b.userDisplay) {
          return -1;
        }
        if (a.userDisplay > b.userDisplay) {
          return 1;
        }
        return 0;
      })
      .map(item => {
        return (
          <Checkbox
            key={item.pk}
            value={item.pk}
            label={item.userDisplay}
          />
        );
      });

    return (
      <StyledBody>
        <CheckboxGroup
          py={3}
          name={TITLE_ONLY_VALUE}
          selectedValues={titleOnly}
          onChange={onTitleOnlyChange}
        >
          <Checkbox
            value={TITLE_ONLY_VALUE}
            label={t(
              "documentSearch.searchFilters.filtersAccordion.onlySearchTitles"
            )}
          />
        </CheckboxGroup>
        <Divider />
        <Accordion key={expanded} onChange={onExpandedSectionChange}>
          <StyledAccordionItem
            expanded={expanded === CATEGORIES}
            uuid={CATEGORIES}
          >
            <StyledAccordionItemTitle>
              <StyledAccordionTitle>
                {expanded === CATEGORIES ? (
                  <StyledFontAwesomeIcon
                    icon={["fal", "minus-square"]}
                    size="lg"
                  />
                ) : (
                  <StyledFontAwesomeIcon
                    icon={["fal", "plus-square"]}
                    size="lg"
                  />
                )}
                {categoryLabelPlural}{" "}
                {this.getSelectedCountText(selectedCategories)}
              </StyledAccordionTitle>
            </StyledAccordionItemTitle>
            <AccordionItemBody>
              <CheckboxGroup
                pb={2}
                name={CATEGORIES}
                selectedValues={selectedCategories}
                onChange={this.handleCategoriesChange}
              >
                <Checkbox
                  value={SELECT_ALL_VALUE}
                  label={t(
                    "documentSearch.searchFilters.filtersAccordion.selectAll"
                  )}
                />
                {categories.map(item => {
                  return (
                    <Checkbox
                      key={item.pk}
                      value={item.pk}
                      label={item.name}
                    />
                  );
                })}
              </CheckboxGroup>
            </AccordionItemBody>
          </StyledAccordionItem>

          <StyledAccordionItem
            uuid={AUTHORS}
            expanded={expanded === AUTHORS}
          >
            <StyledAccordionItemTitle>
              <StyledAccordionTitle>
                {expanded === AUTHORS ? (
                  <StyledFontAwesomeIcon
                    icon={["fal", "minus-square"]}
                    size="lg"
                  />
                ) : (
                  <StyledFontAwesomeIcon
                    icon={["fal", "plus-square"]}
                    size="lg"
                  />
                )}
                {authorLabelPlural}{" "}
                {this.getSelectedCountText(this.props.selectedAuthors)}
              </StyledAccordionTitle>
            </StyledAccordionItemTitle>
            <AccordionItemBody>
              <CheckboxGroup
                pb={2}
                name={AUTHORS}
                selectedValues={selectedAuthors}
                onChange={this.handleAuthorsChange}
              >
                <Checkbox
                  value={SELECT_ALL_VALUE}
                  label={t(
                    "documentSearch.searchFilters.filtersAccordion.selectAll"
                  )}
                />
                {renderAuthorsCheckboxes}
              </CheckboxGroup>
            </AccordionItemBody>
          </StyledAccordionItem>

          <StyledAccordionItem uuid={TAGS} expanded={expanded === TAGS}>
            <StyledAccordionItemTitle>
              <StyledAccordionTitle>
                {expanded === TAGS ? (
                  <StyledFontAwesomeIcon
                    icon={["fal", "minus-square"]}
                    size="lg"
                  />
                ) : (
                  <StyledFontAwesomeIcon
                    icon={["fal", "plus-square"]}
                    size="lg"
                  />
                )}
                {referencesLabelPlural}{" "}
                {this.getSelectedCountText(selectedTags)}
              </StyledAccordionTitle>
            </StyledAccordionItemTitle>
            <AccordionItemBody>
              <CheckboxGroup
                pb={2}
                name={TAGS}
                selectedValues={selectedTags}
                onChange={this.handleTagsChange}
              >
                <Checkbox
                  value={SELECT_ALL_VALUE}
                  label={t(
                    "documentSearch.searchFilters.filtersAccordion.selectAll"
                  )}
                />
                {tags.map(item => {
                  return (
                    <Checkbox
                      key={item.pk}
                      value={item.pk}
                      label={item.name}
                    />
                  );
                })}
              </CheckboxGroup>
            </AccordionItemBody>
          </StyledAccordionItem>
        </Accordion>
      </StyledBody>
    );
  }
}
export const FiltersAccordion = withTranslation()(
  withLabelContext(_FiltersAccordion)
);
