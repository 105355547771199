import { createContext, forwardRef } from "react";

export const DataStoreContext = createContext({});

export const withDataStore = Component =>
  forwardRef((props, ref) => (
    <DataStoreContext.Consumer>
      {dataStore => <Component {...props} dataStore={dataStore} ref={ref} />}
    </DataStoreContext.Consumer>
  ));
DataStoreContext.displayName = "DataStoreContext";
