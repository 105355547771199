/**
 * @flow
 * @relayHash 9da34ccfae07cf7fc95a64e8abfbb56d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LocalDocumentsForTemplateCsvQueryVariables = {|
  pk: number
|};
export type LocalDocumentsForTemplateCsvQueryResponse = {|
  +blocSystemDocumentTemplatesDocumentsCsv: ?{|
    +taskId: ?string
  |}
|};
export type LocalDocumentsForTemplateCsvQuery = {|
  variables: LocalDocumentsForTemplateCsvQueryVariables,
  response: LocalDocumentsForTemplateCsvQueryResponse,
|};
*/


/*
query LocalDocumentsForTemplateCsvQuery(
  $pk: Int!
) {
  blocSystemDocumentTemplatesDocumentsCsv(pk: $pk) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "blocSystemDocumentTemplatesDocumentsCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pk",
        "variableName": "pk",
        "type": "Int!"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LocalDocumentsForTemplateCsvQuery",
  "id": "1116bd2b8349ff7e5be4c93e34ef38c2",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LocalDocumentsForTemplateCsvQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LocalDocumentsForTemplateCsvQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query LocalDocumentsForTemplateCsvQuery(\n  $pk: Int!\n) {\n  blocSystemDocumentTemplatesDocumentsCsv(pk: $pk) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '0a2f5e654c0dd41ce551dcdc6d89329c';
module.exports = node;
