/**
 * @flow
 * @relayHash 25ff6d28e47d9e9cb27f94063ab0915d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateDocumentsCSVQueryVariables = {||};
export type DuplicateDocumentsCSVQueryResponse = {|
  +duplicateDocumentsCsv: ?{|
    +taskId: ?string
  |}
|};
export type DuplicateDocumentsCSVQuery = {|
  variables: DuplicateDocumentsCSVQueryVariables,
  response: DuplicateDocumentsCSVQueryResponse,
|};
*/


/*
query DuplicateDocumentsCSVQuery {
  duplicateDocumentsCsv {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "duplicateDocumentsCsv",
    "storageKey": null,
    "args": null,
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DuplicateDocumentsCSVQuery",
  "id": "15985183134da55a074f71555b1338ce",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DuplicateDocumentsCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "DuplicateDocumentsCSVQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query DuplicateDocumentsCSVQuery {\n  duplicateDocumentsCsv {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '62d098ed3c6308bd335f757af5fb1e2b';
module.exports = node;
