import { Box } from "grid-styled";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { themeGet } from "styled-system";
import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import parseContent from "pstat-anywhere/components/view/content/ContentParser";
import ContentRenderer from "pstat-anywhere/components/view/content/ContentRenderer";
import { withDocumentSettingsContext } from "pstat-anywhere/context_providers/DocumentSettingsContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { getAllHeadingsWithIds } from "pstat-anywhere/utils/domHelpers";
import Card from "pstat-design-system/Card";
import { H1 } from "pstat-design-system/text/headings";

const Content = ({
  name,
  nameHtml,
  html,
  isRestricted,
  tableOfContentsCallback,
  documentSettings
}) => (
  <Box width={1} pt={0} pb={[0, 0, 6]} px={[0, 0, 3]}>
    <Card id="document_content">
      <TitleContainer>
        {nameHtml ? (
          <Title dangerouslySetInnerHTML={{ __html: nameHtml }} />
        ) : (
          <Title>{name}</Title>
        )}
        {isRestricted && (
          <StyledFontAwesomeIcon
            icon={["fas", "lock"]}
            color="colors.nav.80"
            style={{ marginLeft: 5 }}
            data-testid="lock-icon-policy-title"
          />
        )}
      </TitleContainer>
      <PolicyContent
        html={html}
        documentSettings={documentSettings}
        tableOfContentsCallback={tableOfContentsCallback}
      />
    </Card>
    <div id="end-of-content" />
  </Box>
);

export default withDocumentSettingsContext(Content);

const PolicyContent = ({ html, documentSettings, tableOfContentsCallback }) => {
  let documentContent = null;
  // let documentHeaders = null;
  let sectionHeaders = null;
  let activeElement = null;

  const updateTocHighlight = event => {
    if (documentContent) {
      const documentHeaders = cleanHeaders(
        getAllHeadingsWithIds(documentContent)
      );
      if (documentHeaders) {
        activeElement = getActiveElementIdAfterScroll([
          ...Array.from(documentHeaders),
          ...sectionHeaders
        ]);
        tableOfContentsCallback(activeElement);
      }
    }
  };

  const getAllHeadingsFromDocumentBody = () => {
    documentContent = document.querySelectorAll(
      '#document_content [role="main"]'
    )[0];

    sectionHeaders = Array.from(
      document.querySelectorAll(
        "#attachments, #onesource-documents-panel, #standards"
      )
    );
    const documentHeaders = cleanHeaders(
      getAllHeadingsWithIds(documentContent)
    );
    if (documentHeaders) {
      return [...Array.from(documentHeaders), ...sectionHeaders];
    }
    return null;
  };

  useEffect(() => {
    let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    //This is a workaround for the IE11
    //i don't think they'll change the IE11's API anymore, but here's the link for the "solution"
    //https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    if (isIE11) {
      return;
    }
    if (!tableOfContentsCallback) {
      return;
    }

    const headers = getAllHeadingsFromDocumentBody();
    if (!headers) {
      return;
    }

    tableOfContentsCallback(
      getActiveElementIdAfterScroll(getAllHeadingsFromDocumentBody())
    );
    window.addEventListener("scroll", updateTocHighlight);
    return () => window.removeEventListener("scroll", updateTocHighlight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { headerHtml, footerHtml, viewStyles } = documentSettings;
  return (
    <ContentContainer>
      <Helmet>
        <style>{viewStyles}</style>
      </Helmet>
      <Fragment>
        {headerHtml && (
          <ContentRenderer>
            <div className="document">
              <div className="document_header">{parseContent(headerHtml)}</div>
            </div>
          </ContentRenderer>
        )}
        <ContentRenderer role="main">
          <div className="document">
            <div className="document_body">{parseContent(html)}</div>
          </div>
        </ContentRenderer>
        {footerHtml && (
          <ContentRenderer>
            <div className="document">
              <div className="document_footer">{parseContent(footerHtml)}</div>
            </div>
          </ContentRenderer>
        )}
      </Fragment>
    </ContentContainer>
  );
};

const ContentContainer = system({
  position: "relative"
}).extend`
  counter-reset: toc-autonumber-0 toc-autonumber-1 toc-autonumber-2 toc-autonumber-3 toc-autonumber-4 toc-autonumber-5;
`;

const Title = H1.extend`
  text-align: center;
  margin: 0;
  word-break: break-word;
  word-wrap: break-word;
  font-weight: ${themeGet("fontWeights.bold")};
`;

const TitleContainer = system({
  style: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    marginBottom: `${theme.space[5]}px`,
    paddingBottom: `${theme.space[5]}px`,
    borderBottom: `1px solid ${theme.colors.nav[80]}`
  }
});

export const cleanHeaders = headers => {
  const cleanedHeaders = [];
  if (headers) {
    for (let i = 0; i < headers.length; i++) {
      if (headers[i].childNodes[0].textContent) {
        let headerText = headers[i].childNodes[0].textContent.trim();
        if (headerText !== "") {
          cleanedHeaders.push(headers[i]);
        }
      }
    }
    if (cleanedHeaders.length > 0) {
      return cleanedHeaders;
    }
  }
  return null;
};

export const getActiveElementIdAfterScroll = allHeaders => {
  let highestVisibleElementIndex = 0;
  for (let i = 0; i < allHeaders.length; i++) {
    const { top } = allHeaders[i].getBoundingClientRect();
    if (top <= 100) {
      highestVisibleElementIndex = i;
    }
  }
  const highestVisibleElement = allHeaders[highestVisibleElementIndex];
  return highestVisibleElement.id;
};
