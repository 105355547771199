import { Box, Flex } from "grid-styled";
import { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import system from "system-components";

import theme from "../../../themes/policystat/theme";
import titleCase from "../../../utils/titleCase";
import { getRootUrl } from "../../../utils/urls";
import { StyledFontAwesomeIcon } from "../../partials/icons";
import SearchFilters from "../SearchFilters/SearchFilters";
import {
  CSVIconContainer,
  SearchResults,
  SearchResultsBadge
} from "../SearchResults/SearchResults";
import SimpleSearch from "../SimpleSearch";

import { H1 } from "pstat-design-system/text/headings";
import { Text } from "pstat-design-system/text";
import { SearchShareButton } from "pstat-design-system/ShareButton";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Export from "pstat-anywhere/components/partials/export/Export";

const AlignRight = system({
  ml: "auto"
}).extend`
  &:focus {
    outline: 0;
  }
`;

const AlignRightFlex = system({
  is: Flex,
  ml: "auto"
});

class MobileSearchIcon extends Component {
  render() {
    const { t } = this.props;
    return (
      <Media query={{ minWidth: theme.breakpoints[1] }}>
        {isDesktop =>
          !isDesktop && (
            <AlignRightFlex alignItems="center">
              <AlignRight>
                <RouterFocusLink
                  light="1"
                  to={{
                    pathname: `${getRootUrl()}/search/`
                  }}
                  tabIndex="0"
                  color="nav.100"
                >
                  <StyledFontAwesomeIcon
                    icon={["far", "search"]}
                    color="primary.0"
                    size="lg"
                  />
                  <Text size="normal" color="nav.100">
                    {t("buttons.mobileSearch")}
                  </Text>
                </RouterFocusLink>
              </AlignRight>
            </AlignRightFlex>
          )
        }
      </Media>
    );
  }
}

const Container = system({
  bg: "nav.95",
  color: "nav.0"
});

const SearchContainer = system({
  p: 4
});

const SearchFiltersContainer = system({
  mt: 3
});

const Divider = system({
  borderTop: 1,
  borderColor: "nav.80",
  mt: 3,
  mb: 4
});

const SearchInfoContainer = system({
  is: Flex,
  alignItems: "center",
  justifyContent: "space-between"
});

export const SearchContentMobile = withLabelContext(
  ({
    totalNumberOfResults,
    titleOnly,
    authors,
    categories,
    references,
    expanded,
    q,
    after,
    onExpandedFilterChange,
    onFiltersChange,
    onTotalCountUpdate,
    onPageChange,
    csvQuery,
    environment,
    labels,
    filterOptions,
    fetchFilterOptions,
    handleGuestShareTokenUpdate,
    guestAccessToken
  }) => {
    const { t } = useTranslation();
    const { documentLabelPlural } = labels;
    return (
      <Container>
        <SearchContainer>
          <H1>
            {titleCase(
              t("inputs.searchInput.placeholder", { documentLabelPlural })
            )}
          </H1>
          <SimpleSearch />
          <SearchFiltersContainer>
            <SearchFilters
              expanded={expanded}
              filters={{ titleOnly, authors, categories, references }}
              onExpandedFilterChange={onExpandedFilterChange}
              onFiltersChange={onFiltersChange}
              environment={environment}
              filterOptions={filterOptions}
              fetchFilterOptions={fetchFilterOptions}
            />
          </SearchFiltersContainer>
          <Divider />
          <SearchInfoContainer>
            <Box>
              <SearchResultsBadge totalNumberOfResults={totalNumberOfResults} />
            </Box>
            <Box>
              <CSVIconContainer>
                <Export
                  queryName="searchCsv"
                  csvQuery={csvQuery}
                  csvQueryArgs={{
                    titleOnly,
                    authors,
                    categories,
                    references,
                    q
                  }}
                />
              </CSVIconContainer>
              {guestAccessToken && (
                <SearchShareButton token={guestAccessToken} withText={false} />
              )}
            </Box>
          </SearchInfoContainer>
        </SearchContainer>
        <SearchResults
          query={q}
          afterCursor={after}
          titleOnly={titleOnly}
          authors={authors}
          categories={categories}
          references={references}
          totalNumberOfResults={totalNumberOfResults}
          onTotalCountUpdate={onTotalCountUpdate}
          handleGuestShareTokenUpdate={handleGuestShareTokenUpdate}
          onPageChange={onPageChange}
        />
      </Container>
    );
  }
);

export default withTranslation()(MobileSearchIcon);
