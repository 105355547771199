import { em } from "polished";

import colors from "./colors";

const breakpointPxs = [450, 768, 992, 1200];

const theme = {
  colors: colors,
  breakpoints: breakpointPxs.map(breakpointPx => em(`${breakpointPx}px`)),
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40],
  radii: [0, 6, 12, 27],
  // font stuff
  fontFamily: "Roboto, Arial, sans-serif",
  fonts: {
    font: "Roboto, Arial, sans-serif"
  },
  lineHeight: 1.3,
  fontSizes: [
    "12px", // 0
    "14px",
    "15px",
    "18px",
    "20px",
    "22px", // 5
    "24px",
    "26px",
    "28px",
    "32px",
    "36px"
  ],
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700
  },
  icons: {
    size: 20
  },
  shadows: {
    card: `2px 2px 0 0 ${colors["nav"][90]}`,
    dropdown: "0 0 15px 0 rgba(24, 45, 74, 0.25)"
  },
  borders: [0, "1px solid", "2px solid"],
  layers: {
    textOverlay: 1,
    tertiaryNav: 25,
    secondaryNav: 50,
    nav: 100,
    activeFloatingUi: 150,
    modal: 201,
    modalBg: 200
  },
  buttons: {
    primary: {
      color: colors["nav"][0],
      borderColor: colors["primary"][0],
      baseColor: colors["primary"][0],
      accentColor: colors["primary"][40],
      disabledColor: colors["nav"][55],
      disabledBackgroundColor: colors["primary"][80]
    },
    secondary: {
      color: colors["nav"][100],
      borderColor: colors["secondary"][0],
      baseColor: colors["secondary"][0],
      accentColor: colors["secondary"][15],
      disabledColor: colors["nav"][55],
      disabledBackgroundColor: colors["secondary"][80]
    },
    tertiary: {
      color: colors["nav"][0],
      borderColor: colors["secondary"][80],
      baseColor: colors["nav"][95],
      accentColor: colors["nav"][100],
      disabledColor: colors["nav"][55],
      disabledBackgroundColor: colors["nav"][90]
    },
    danger: {
      color: colors["nav"][100],
      borderColor: colors["error"][0],
      baseColor: colors["error"][0],
      accentColor: colors["error"][15],
      disabledColor: colors["nav"][55],
      disabledBackgroundColor: colors["error"][80]
    }
  },
  tertiaryNavHeight: 52,
  toggleShowChangesColoredCircleSize: 13
};

export default theme;
