/**
 * @flow
 * @relayHash 459fd0d1ae709419e2f8b5cc6f544539
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeBlocInput = {
  blocPk?: ?number,
  name: string,
  documentSettings?: ?number,
  tenants: $ReadOnlyArray<?number>,
  clientMutationId?: ?string,
};
export type EditBlocMutationVariables = {|
  input: ChangeBlocInput
|};
export type EditBlocMutationResponse = {|
  +changeBloc: ?{|
    +ok: ?boolean,
    +error: ?any,
    +bloc: ?{|
      +pk: ?number,
      +name: string,
    |},
  |}
|};
export type EditBlocMutation = {|
  variables: EditBlocMutationVariables,
  response: EditBlocMutationResponse,
|};
*/


/*
mutation EditBlocMutation(
  $input: ChangeBlocInput!
) {
  changeBloc(input: $input) {
    ok
    error
    bloc {
      pk
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ChangeBlocInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "ChangeBlocInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "error",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "EditBlocMutation",
  "id": "a45c6fd6e6197d784dcfd89adecdedbe",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditBlocMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "changeBloc",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeBlocPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditBlocMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "changeBloc",
        "storageKey": null,
        "args": v1,
        "concreteType": "ChangeBlocPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v4,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation EditBlocMutation(\n  $input: ChangeBlocInput!\n) {\n  changeBloc(input: $input) {\n    ok\n    error\n    bloc {\n      pk\n      name\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '0e6fbd92e98efef62617c8278ae91473';
module.exports = node;
