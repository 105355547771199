export const getDocumentViewRecord = (store, documentPk, location) => {
  const showChanges = !!location.query?.showchanges;
  const latest = location.pathname.indexOf("latest") > -1;
  const ignoreLatestRedirect =
    location.pathname.indexOf("delete") > -1 ||
    location.pathname.indexOf("approve") > -1;

  // the args need to exactly match what was passed into the query to find it in the store
  const variables = {
    pk: `${documentPk}`,
    showChanges,
    latest
  };
  if (ignoreLatestRedirect) {
    variables.ignoreLatestRedirect = true;
  }
  return store.getRoot().getLinkedRecord("documentView", variables);
};

export const getStoredTokens = documentViewRecord => {
  return documentViewRecord.getLinkedRecords("documentAccessTokens") || [];
};
