import { Fragment, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { ErrorCheckWrapper } from "./routes";

import LoadingModal from "pstat-design-system/modal/LoadingModal";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";

export const PageTitleWrapper = withLabelContext(
  ({ children, labels, documentName = "", forcedTitle, titleKey }) => {
    const { t } = useTranslation();
    const parsedLabels = useMemo(() => ({ ...labels, documentName }), [
      documentName,
      labels
    ]);

    return (
      <Fragment>
        <Helmet>
          <title>{forcedTitle || t(titleKey, parsedLabels)}</title>
        </Helmet>
        {children}
      </Fragment>
    );
  }
);

export const LoadingPage = ({ id }) => {
  const { t } = useTranslation();
  return <LoadingModal id={id} isOpen={true} text={t("loading")} />;
};

export const PageWrapper = ({
  component: Component,
  documentName = "",
  queryName,
  showLoading,
  titleKey,
  forcedTitle,
  ...props
}) => {
  if (showLoading) {
    return <LoadingPage />;
  }

  return (
    <PageTitleWrapper
      titleKey={titleKey}
      documentName={documentName}
      forcedTitle={forcedTitle}
    >
      <ErrorCheckWrapper queryName={queryName} {...props}>
        <Component {...props} />
      </ErrorCheckWrapper>
    </PageTitleWrapper>
  );
};
