import { Environment, Network, RecordSource, Store } from "relay-runtime";

import queryMappings from "./queryMap.json";
import { clientIsOutdated, scheduleClientForUpdate } from "./utils/autoUpdate";
import customFetch from "./utils/customFetch";
import {
  setMaintenanceInProgress,
  setUpcomingMaintenance
} from "./utils/maintenance";
import { getRootUrl, isDevMode } from "./utils/urls";

const source = new RecordSource();
const store = new Store(source);

const LATEST_CLIENT_VERSION_HEADER = "X-Latest-Client-Version";
const MAINTENANCE_IN_PROGRESS_HEADER = "X-Maintenance-In-Progress";
const UPCOMING_MAINTENANCE_HEADER = "X-Upcoming-Maintenance";

const addFilesToRequestBody = (files, operations, isRelayMutation) => {
  const formData = new FormData();
  const fileKeys = Object.keys(files);
  const fileMap = {};
  for (let i = 0; i < fileKeys.length; i++) {
    const fileKey = fileKeys[i];
    if (isRelayMutation) {
      operations.variables.input[fileKey] = null;
      fileMap[fileKey] = [`variables.input.${fileKey}`];
    } else {
      operations.variables[fileKeys[i]] = null;
      fileMap[fileKey] = [`variables.${fileKey}`];
    }
    formData.append(fileKey, files[fileKey]);
  }
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", JSON.stringify(fileMap));
  return formData;
};

const setQuery = (body, queryMappings, operation) => {
  if (isDevMode()) {
    body.query = queryMappings[operation.id];
  } else {
    body.query_hash = operation.id;
  }
};

function relayCustomFetch(operation, variables, cacheConfig, uploadables) {
  let body, uri, headers;
  if (isDevMode()) {
    uri = `${getRootUrl()}/graphiql`;
  } else {
    uri = `${getRootUrl()}/graphql/`;
  }

  const isRelayMutation =
    variables.input && typeof variables.input === "object";
  if (uploadables) {
    headers = {};
    const operations = {};
    setQuery(operations, queryMappings, operation);
    operations.variables = { ...variables };
    body = addFilesToRequestBody(uploadables, operations, isRelayMutation);
  } else {
    headers = {
      "content-type": "application/json"
    };
    body = { variables };
    setQuery(body, queryMappings, operation);
    body = JSON.stringify(body);
  }

  const fetchOptions = {
    method: "POST",
    body: body,
    headers: headers
  };

  return customFetch(uri, fetchOptions).then(processResponse);
}

const processResponse = response => {
  const headers = response.headers;

  if (headers.has(LATEST_CLIENT_VERSION_HEADER)) {
    const latestClientVersion = headers.get(LATEST_CLIENT_VERSION_HEADER);
    if (clientIsOutdated(latestClientVersion)) {
      scheduleClientForUpdate();
    }
  }
  setMaintenanceInProgress(headers.has(MAINTENANCE_IN_PROGRESS_HEADER));
  setUpcomingMaintenance(headers.get(UPCOMING_MAINTENANCE_HEADER));
  return response.json();
};

const network = Network.create(relayCustomFetch);

export const environment = new Environment({
  network,
  store
});

export default environment;
