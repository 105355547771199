/* eslint-disable import/order */

import styled from "styled-components";

const AlwaysShowBlocks = styled.div`
  & address,
  & blockquote,
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p,
  & pre,
  & ul,
  & ol {
    background-position: top left;
    padding-left: 8px;
    background-size: auto 15px;
    background-repeat: no-repeat;
    border: 1px dotted gray;
    padding-top: 8px;
  }

  & address {
    background-image: url(${require("./images/block_address.png")});
  }

  & blockquote {
    background-image: url(${require("./images/block_blockquote.png")});
  }

  & h1 {
    background-image: url(${require("./images/block_h1.png")});
  }

  & h2 {
    background-image: url(${require("./images/block_h2.png")});
  }

  & h3 {
    background-image: url(${require("./images/block_h3.png")});
  }

  & h4 {
    background-image: url(${require("./images/block_h4.png")});
  }

  & h5 {
    background-image: url(${require("./images/block_h5.png")});
  }

  & h6 {
    background-image: url(${require("./images/block_h6.png")});
  }

  & p {
    background-image: url(${require("./images/block_p.png")});
  }

  & pre {
    background-image: url(${require("./images/block_pre.png")});
  }

  & ol,
  & ul {
    border-left: 20px solid #b9c4d0;
    padding: 0px 5px;
  }

  & ol ol,
  & ol ul,
  & ul ol,
  & ul ul {
    border-right: none;
    border-top: none;
    border-bottom: none;
  }
`;

export default AlwaysShowBlocks;
