import { withRouter } from "found";
import { Component } from "react";
import system from "system-components";

import colors from "../../../themes/policystat/colors";

export const TR = system(
  {
    is: "tr",
    tabIndex: "0"
  },
  "space",
  "color"
).extend`
  border-bottom: 1px solid ${colors.nav[80]};
  &:last-of-type {
    border-bottom: none;
  }
  &:focus {
    outline: 0;
  }
`;

class RouterTR extends Component {
  handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.goToPolicy();
    }
  };

  goToPolicy = () => {
    if (this.props.to) {
      this.props.router.push(this.props.to);
    }
  };

  render() {
    const { to, TRComp = TR, ...otherProps } = this.props;
    return (
      <TRComp onKeyDown={this.handleKeyDown} {...otherProps}>
        {this.props.children}
      </TRComp>
    );
  }
}

export const LinkedTR = withRouter(RouterTR);

export const TRHiddenSmall = system(
  {
    is: "tr",
    display: ["none", "none", "table-row"]
  },
  "space"
);
