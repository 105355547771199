import graphql from "babel-plugin-relay/macro";

const LocalDocumentsForTemplateCsvQuery = graphql`
  query LocalDocumentsForTemplateCsvQuery($pk: Int!) {
    blocSystemDocumentTemplatesDocumentsCsv(pk: $pk) {
      taskId
    }
  }
`;

export default LocalDocumentsForTemplateCsvQuery;
