/**
 * @flow
 * @relayHash 2ec75066624ab8bf0c989559cf64f224
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RenameDocumentAccessTokenInput = {
  tokenId: any,
  documentPk: number,
  description: string,
  clientMutationId?: ?string,
};
export type RenameTokenMutationVariables = {|
  input: RenameDocumentAccessTokenInput
|};
export type RenameTokenMutationResponse = {|
  +renameDocumentAccessToken: ?{|
    +ok: ?boolean,
    +token: ?{|
      +tokenId: ?any,
      +description: string,
      +dateLastAccessed: ?string,
      +url: ?string,
    |},
  |}
|};
export type RenameTokenMutation = {|
  variables: RenameTokenMutationVariables,
  response: RenameTokenMutationResponse,
|};
*/


/*
mutation RenameTokenMutation(
  $input: RenameDocumentAccessTokenInput!
) {
  renameDocumentAccessToken(input: $input) {
    ok
    token {
      tokenId
      description
      dateLastAccessed
      url
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RenameDocumentAccessTokenInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "RenameDocumentAccessTokenInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tokenId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateLastAccessed",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RenameTokenMutation",
  "id": "51da2c461efeeb6c24b3be44965d7653",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RenameTokenMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "renameDocumentAccessToken",
        "storageKey": null,
        "args": v1,
        "concreteType": "RenameDocumentAccessTokenPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAccessTokenType",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RenameTokenMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "renameDocumentAccessToken",
        "storageKey": null,
        "args": v1,
        "concreteType": "RenameDocumentAccessTokenPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAccessTokenType",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation RenameTokenMutation(\n  $input: RenameDocumentAccessTokenInput!\n) {\n  renameDocumentAccessToken(input: $input) {\n    ok\n    token {\n      tokenId\n      description\n      dateLastAccessed\n      url\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '8aacd9d2b8c34d6220b5eecac6528ddb';
module.exports = node;
