import system from "system-components";

import { Text } from "pstat-design-system/text/paragraph";

export const BucketContainer = system({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  pt: 3,
  width: 1
});

const StyledBucket = system({
  bg: "nav.100",
  borderRadius: 1,
  boxShadow: "card",
  flex: 1,
  p: 4,
  mb: 4,
  mr: 3
}).extend`
  &:last-child {
    margin-right: 0px;
  }
`;

export const Bucket = ({ label, count, color }) => (
  <StyledBucket>
    <Text color="nav.25" display="block" mb={1}>
      {label}
    </Text>
    <Text color={color} fontSize={5} fontWeight="medium">
      {count}
    </Text>
  </StyledBucket>
);
