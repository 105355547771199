import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";

import blocChoicesQuery from "./BlocChoicesQuery";

import {
  ActiveFilterBox,
  FilterDropdown,
  SearchBar
} from "pstat-anywhere/components/pages/reports/components";
import pendingApproversQuery from "pstat-anywhere/non_relay_queries/pendingApproversNonRelay";
import { environment } from "pstat-anywhere/relay";
import { SingleSelectDropdownFormElement } from "pstat-design-system/inputs/FormElements";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";


const SearchBarAndFilters = ({ location, router }) => {
  const { t } = useTranslation();
  const [approverExpanded, setApproverExpanded] = useState(false);
  const [approvers, setApprovers] = useState(null);
  const [blocExpanded, setBlocExpanded] = useState(false);
  const [blocs, setBlocs] = useState(null);
  const [searchQuery, setSearchQuery] = useState(
    location.query.search_query || ""
  );
  const [approver, setApprover] = useState(location.query.pending_approver);
  const [bloc, setBloc] = useState(location.query.bloc);

  const getSelectedFilter = (pk, options) => {
    if (pk && options) {
      const selectedOptionsList = options.filter(option => option.pk === pk);
      if (selectedOptionsList.length > 0) {
        return selectedOptionsList[0];
      }
    }
    return null;
  };

  useEffect(() => {
    const queryParams = { ...location.query };
    if (searchQuery) {
      queryParams.search_query = searchQuery;
    } else {
      delete queryParams.search_query;
    }
    if (!approverExpanded) {
      if (approver) {
        queryParams.pending_approver = approver;
      } else {
        delete queryParams.pending_approver;
      }
    }
    if (!blocExpanded) {
      if (bloc) {
        queryParams.bloc = bloc;
      } else {
        delete queryParams.bloc;
      }
    }

    const searchChanged =
      queryParams.search_query !== location.query.search_query;
    const approverChanged =
      queryParams.pending_approver !== location.query.pending_approver;
    const blocChanged = queryParams.bloc !== location.query.bloc;
    if (searchChanged || approverChanged || blocChanged) {
      const to = {
        pathname: location.pathname,
        query: queryParams
      };
      router.push(to);
    }
    // the linter wants location and router added to the dependencies array but that breaks
    // the whole page because it gets into an infinite loop reloading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, approver, approverExpanded, bloc, blocExpanded]);
  useEffect(() => {
    if (!approvers && (approverExpanded || approver)) {
      fetchQuery(environment, pendingApproversQuery).then(data => {
        if (!data) {
          return;
        }
        const approvers = data.pendingApprovers;
        setApprovers(
          approvers.map(approver => {
            return { pk: approver.id, name: approver.userDisplay };
          })
        );
      });
    }
  }, [approverExpanded, approvers]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!blocs && (blocExpanded || bloc)) {
      fetchQuery(environment, blocChoicesQuery).then(data => {
        if (!data) {
          return;
        }
        const blocs = data.blocChoicesPendingReport.blocs;
        setBlocs(blocs.map(bloc => ({ ...bloc, pk: `${bloc.pk}` })));
      });
    }
  }, [blocExpanded, blocs]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedApprover = getSelectedFilter(approver, approvers);
  const selectedBloc = getSelectedFilter(bloc, blocs);
  return (
    <Fragment>
      <SearchBar
        placeholder={t(
          "documentReports.awaitingActivation.searchFilterPlaceholder"
        )}
        initialValue={searchQuery}
        onChange={setSearchQuery}
      >
        <FilterDropdown
          expanded={approverExpanded}
          onClose={() => setApproverExpanded(false)}
          onToggle={() => {
            setApproverExpanded(!approverExpanded);
            setBlocExpanded(false);
          }}
          filterName={t("documentReports.awaitingActivation.approverFilter")}
        >
          <ApproversUserInput
            approvers={approvers}
            selectedApprover={approver}
            onSelectedApproverChanged={setApprover}
            t={t}
          />
        </FilterDropdown>
        <FilterDropdown
          expanded={blocExpanded}
          onClose={() => setBlocExpanded(false)}
          onToggle={() => {
            setBlocExpanded(!blocExpanded);
            setApproverExpanded(false);
          }}
          filterName={t("documentReports.awaitingActivation.blocFilter")}
        >
          <BlocInput
            blocs={blocs}
            selectedBloc={bloc}
            onSelectedBlocChanged={setBloc}
            t={t}
          />
        </FilterDropdown>
      </SearchBar>
      {selectedApprover && (
        <ActiveFilterBox onClick={() => setApprover(null)}>
          {selectedApprover.name}
        </ActiveFilterBox>
      )}
      {selectedBloc && (
        <ActiveFilterBox onClick={() => setBloc(null)}>
          {selectedBloc.name}
        </ActiveFilterBox>
      )}
    </Fragment>
  );
};
export default SearchBarAndFilters;

const ApproversUserInput = ({
  approvers,
  selectedApprover,
  onSelectedApproverChanged,
  t
}) => (
  <Fragment>
    {approvers ? (
      <SingleSelectDropdownFormElement
        id="approver_filter_input"
        options={approvers}
        label={t("documentReports.awaitingActivation.approverFilter")}
        onClear={() => onSelectedApproverChanged(null)}
        value={selectedApprover}
        onChange={event => onSelectedApproverChanged(event.target.value)}
        name="approver"
        errors={[]}
        placeholder={t(
          "documentReports.awaitingActivation.approverFilterPlaceholder"
        )}
      />
    ) : (
      <LoadingSpinner />
    )}
  </Fragment>
);

const BlocInput = ({ blocs, selectedBloc, onSelectedBlocChanged, t }) => (
  <Fragment>
    {blocs ? (
      <SingleSelectDropdownFormElement
        id="bloc_filter_input"
        options={blocs}
        label={t("documentReports.awaitingActivation.blocFilter")}
        onClear={() => onSelectedBlocChanged(null)}
        value={selectedBloc}
        onChange={event => onSelectedBlocChanged(event.target.value)}
        name="bloc"
        errors={[]}
        placeholder={t(
          "documentReports.awaitingActivation.blocFilterPlaceholder"
        )}
      />
    ) : (
      <LoadingSpinner />
    )}
  </Fragment>
);
