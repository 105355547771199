import querystring from "querystring";
import { useEffect } from "react";

const EVENT = "Event";
const LABEL_SEPARATOR = "\u2022";

const POLICY_CATEGORY = "Policy";
const SEARCH_CATEGORY = "Searches";
export const POLICY_ACTIONS = {
  DOWNLOAD_TO_WORD: "Download To Word",
  EDIT: "Edit",
  EMAIL: "Email",
  PRINT: "Print",
  VIEW: "View"
};
const SEARCH_ACTION = "PSA Search";
const QUICK_SEARCH_ACTION = "Quick Search";
const SEARCH_SELECTION_ACTION = "Search Selection";

export const triggerPolicyEvent = (
  action,
  policy_id,
  policy_title,
  policy_category_id,
  policy_category
) => {
  if (window.googleTagData) {
    const label = getPolicyEventLabel(policy_title, policy_category, policy_id);
    window.googleTagData.push({
      event: EVENT,
      eventProps: {
        category: POLICY_CATEGORY,
        action: action,
        label: label,
        policy_id: policy_id,
        policy_title: policy_title,
        policy_category_id: policy_category_id,
        policy_category: policy_category,
        search_query: null,
        search_query_filter_used: null,
        search_total_results: null,
        search_selected_result_num: null
      }
    });
  }
};

const getQueryString = search_query => {
  let queryObject = querystring.parse(
    search_query,
    "&",
    "=",
    decodeURIComponent
  );
  const searchQuery = queryObject["q"] ? queryObject["q"].toLowerCase() : null;
  let searchFilterUsed = false;
  const filterParams = ["author", "category", "reference", "titleOnly"];
  for (let param of filterParams) {
    if (param in queryObject) {
      searchFilterUsed = true;
      break;
    }
  }
  return { searchQuery, searchFilterUsed };
};

export const triggerSearchEvent = (
  action,
  label,
  search_query = null,
  search_total_results = null,
  policy_id = null,
  policy_title = null,
  policy_category = null,
  search_selected_result_num = null
) => {
  if (window.googleTagData) {
    const { searchQuery, searchFilterUsed } = getQueryString(search_query);
    window.googleTagData.push({
      event: EVENT,
      eventProps: {
        category: SEARCH_CATEGORY,
        action: action,
        label: label,
        search_query: searchQuery,
        search_query_filter_used: searchFilterUsed,
        search_total_results: search_total_results,
        policy_id: policy_id,
        policy_title: policy_title,
        policy_category: policy_category,
        search_selected_result_num: search_selected_result_num
      }
    });
  }
};

const getPolicyEventLabel = (title, category, pk) =>
  `${title}${LABEL_SEPARATOR}${category}${LABEL_SEPARATOR}${pk}`;

export const triggerQuickSearchEvent = (
  suggestedSearchQuery,
  suggestedSearchDocumentPk,
  suggestedSearchTitleClicked,
  suggestedSearchCategoryClicked,
  suggestedSearchResultNumber
) => {
  suggestedSearchQuery = "q=" + suggestedSearchQuery;
  const label = `${LABEL_SEPARATOR}${suggestedSearchQuery}${LABEL_SEPARATOR}${suggestedSearchDocumentPk}${LABEL_SEPARATOR}${suggestedSearchTitleClicked}${LABEL_SEPARATOR}${suggestedSearchCategoryClicked}${LABEL_SEPARATOR}${suggestedSearchResultNumber}`;
  triggerSearchEvent(
    QUICK_SEARCH_ACTION,
    label,
    suggestedSearchQuery,
    null,
    suggestedSearchDocumentPk,
    suggestedSearchTitleClicked,
    suggestedSearchCategoryClicked,
    suggestedSearchResultNumber
  );
};

export const triggerBasicSearchEvent = (totalCount, queryString) => {
  const label = `${LABEL_SEPARATOR}${totalCount}${LABEL_SEPARATOR}${queryString}`;
  triggerSearchEvent(SEARCH_ACTION, label, queryString, totalCount);
};

export const triggerSearchSelectionEvent = (
  searchQuery,
  queryString,
  documentUrl,
  documentPk,
  documentName,
  resultNumber,
  categoryName,
  numberOfResults
) => {
  const label = `${LABEL_SEPARATOR}${searchQuery}${LABEL_SEPARATOR}${documentUrl}${LABEL_SEPARATOR}${documentName}${LABEL_SEPARATOR}${resultNumber}${LABEL_SEPARATOR}${categoryName}${LABEL_SEPARATOR}${numberOfResults}`;
  triggerSearchEvent(
    SEARCH_SELECTION_ACTION,
    label,
    queryString,
    numberOfResults,
    documentPk,
    documentName,
    categoryName,
    resultNumber
  );
};

export const TriggerEventOnMount = ({ triggerEvent, children }) => {
  // only call triggerEvent on component mount so we don't get duplicate events on re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => triggerEvent(), []);
  return children;
};
