import { Children, cloneElement } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

const ToolbarDiv = styled.div`
  display: block;

  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: flex;
    align-items: center;
  }
`;

ToolbarDiv.defaultProps = {
  width: "100%"
};

ToolbarDiv.displayName = "Toolbar";

export const Toolbar = ({ children, closeMenu }) => (
  <ToolbarDiv>
    {Children.map(children, child => cloneElement(child, { closeMenu }))}
  </ToolbarDiv>
);

export default Toolbar;
