import { HITS_PER_PAGE } from "../index";

export default function useQueryVariables({ location }) {
  const variables = {};
  let hitsPerPage = HITS_PER_PAGE;
  if (location) {
    const { after, sort, rowsPerPage } = location.query;
    if (rowsPerPage) {
      hitsPerPage = rowsPerPage;
    }
    variables.first = hitsPerPage;
    variables.after = after;
    variables.sort = sort;
  }

  return {
    variables,
    hitsPerPage: hitsPerPage
  };
}
