/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Attachments_attachments$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Attachments_template$ref: FragmentReference;
export type Attachments_template = {|
  +attachments: ?$ReadOnlyArray<?{|
    +$fragmentRefs: Attachments_attachments$ref
  |}>,
  +$refType: Attachments_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Attachments_template",
  "type": "SystemDocumentTemplateType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "attachments",
      "storageKey": "attachments(excludeEmbedded:true)",
      "args": [
        {
          "kind": "Literal",
          "name": "excludeEmbedded",
          "value": true,
          "type": "Boolean"
        }
      ],
      "concreteType": "DocumentAttachmentType",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Attachments_attachments",
          "args": null
        }
      ]
    }
  ]
};

(node/*: any*/).hash = 'f872e26f6a17645531b181a33182f18a';
module.exports = node;
