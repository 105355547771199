import graphql from "babel-plugin-relay/macro";
import { Fragment } from "react";
import { createFragmentContainer } from "react-relay";

import Properties, {
  ApprovedDate,
  AuthorContainer,
  AuthorSearchLinkFragmentContainer,
  CategoryContainer,
  CategorySearchLinkFragmentContainer,
  ReferencesContainer,
  RevisionDate,
  TagSearchLink,
  TagsFragmentContainer
} from "pstat-anywhere/components/view/Properties";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import { useTenant } from "pstat-anywhere/context_providers/TenantContext";

const TemplateProperties = ({ template }) => {
  const labels = useLabels();
  const tenant = useTenant();
  return (
    <Properties
      headerImageUrl={template.headerImageUrl}
      renderDates={() => (
        <Dates
          labels={labels}
          documentSettings={tenant.documentSettings}
          approvalDate={template.approvalDate}
          revisionDate={template.revisionDate}
        />
      )}
      renderProperties={() => (
        <Ownership
          labels={labels}
          documentSettings={tenant.documentSettings}
          author={template.author}
          category={template.category}
          tags={template.tags}
        />
      )}
    />
  );
};

export default createFragmentContainer(
  TemplateProperties,
  graphql`
    fragment TemplateProperties_template on SystemDocumentTemplateType {
      headerImageUrl
      approvalDate(formatted: true)
      revisionDate(formatted: true)
      author {
        ...Properties_author
      }
      category {
        ...Properties_category
      }
      tags {
        ...Properties_tags
      }
    }
  `
);

const Dates = ({ documentSettings, labels, approvalDate, revisionDate }) => (
  <Fragment>
    <ApprovedDate
      documentSettings={documentSettings}
      labels={labels}
      date={approvalDate}
    />
    <RevisionDate
      documentSettings={documentSettings}
      labels={labels}
      date={revisionDate}
    />
  </Fragment>
);

const Ownership = ({ documentSettings, labels, author, category, tags }) => (
  <Fragment>
    <AuthorContainer documentSettings={documentSettings} labels={labels}>
      <AuthorSearchLinkFragmentContainer author={author} />
    </AuthorContainer>
    <CategoryContainer documentSettings={documentSettings} labels={labels}>
      <CategorySearchLinkFragmentContainer category={category} />
    </CategoryContainer>
    <ReferencesContainer
      tags={tags}
      documentSettings={documentSettings}
      labels={labels}
    >
      <TagsFragmentContainer
        tags={tags}
        renderTag={(pk, text) => <TagSearchLink pk={pk} text={text} />}
      />
    </ReferencesContainer>
  </Fragment>
);
