/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PolicyEditor_documentSave$ref: FragmentReference;
export type PolicyEditor_documentSave = {|
  +pk: ?number,
  +savedCopy: ?{|
    +pk: ?number,
    +author: {|
      +pk: ?number,
      +firstName: string,
      +lastName: string,
      +email: string,
    |},
    +isTemplate: ?boolean,
  |},
  +$refType: PolicyEditor_documentSave$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PolicyEditor_documentSave",
  "type": "DocumentSaveType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "savedCopy",
      "storageKey": null,
      "args": null,
      "concreteType": "RLDatixDocumentType",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "author",
          "storageKey": null,
          "args": null,
          "concreteType": "UserType",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "email",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isTemplate",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();

(node/*: any*/).hash = '8674de87d64c1c6a6ff6d05dbc7a4225';
module.exports = node;
