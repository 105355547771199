import queryString from "query-string";
import { QueryRenderer } from "react-relay";

import { environment as relayEnvironment } from "../relay";

import { DataStoreContext } from "./DataStoreContext";
import { DocumentSettingsContext } from "./DocumentSettingsContext";
import { LabelContext } from "./LabelContext";
import SessionQuery from "./SessionQuery";
import { SettingsContext } from "./SettingsContext";
import { TenantContext } from "./TenantContext";
import { TenantSamlContext } from "./TenantSamlContext";
import { ViewerContext } from "./ViewerContext";

// provider that provides all the global/site-specific contexts at once

const SessionProvider = ({ environment = relayEnvironment, children }) => {
  let path = null;
  const parsed = queryString.parse(window.location.search);
  const nextUrl = parsed["next"];
  if (nextUrl) {
    path = nextUrl;
  }

  return (
    <QueryRenderer
      environment={environment}
      query={SessionQuery}
      variables={{ path: path }}
      render={obj => {
        const props = obj.props;
        if (props) {
          const {
            pk,
            name,
            subdomain,
            documentSettings,
            hasScheduledEffectiveDateEnabled,
            showApplicability,
            settings,
            isIsland,
            customerSettings,
            loadHomeQueriesIndependently
          } = props.currentTenant;
          const {
            vergeIntegrationEnabled,
            onesourceIntegrationEnabled,
            enableAllBlocs,
            standardsAndRegulationsEnabled,
            enableEditWithWord,
            enableEnterprise,
            documentsWithStandardsReportEnabled // feature flag gh7450_standards_report
          } = settings;
          return (
            <SettingsContext.Provider value={props.settings}>
              <LabelContext.Provider value={settings.labels}>
                <ViewerContext.Provider value={props.viewer}>
                  <TenantContext.Provider
                    value={{
                      pk,
                      name,
                      subdomain,
                      documentSettings,
                      hasScheduledEffectiveDateEnabled,
                      showApplicability,
                      enableEditWithWord,
                      standardsAndRegulationsEnabled,
                      enableAllBlocs,
                      onesourceIntegrationEnabled,
                      vergeIntegrationEnabled,
                      singleTenantCustomer: isIsland,
                      customerSettings,
                      enableEnterprise,
                      loadHomeQueriesIndependently,
                      documentsWithStandardsReportEnabled
                    }}
                  >
                    <TenantSamlContext.Provider
                      value={props.currentTenant.samlSettings}
                    >
                      <DocumentSettingsContext.Provider
                        value={props.currentTenant.documentSettings}
                      >
                        <DataStoreContext.Provider value={{}}>
                          {children}
                        </DataStoreContext.Provider>
                      </DocumentSettingsContext.Provider>
                    </TenantSamlContext.Provider>
                  </TenantContext.Provider>
                </ViewerContext.Provider>
              </LabelContext.Provider>
            </SettingsContext.Provider>
          );
        }
        return <div>Loading</div>;
      }}
    />
  );
};

export default SessionProvider;
