import { Box } from "grid-styled";
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import Media from "react-media";

import { withLabelContext } from "../../../context_providers/LabelContext";

import {
  FiltersFooter,
  FiltersPositioner,
  FiltersTitle,
  FullHeightFlex,
  MobileFiltersModal,
  OverflowFlex
} from "./components";
import { FiltersAccordion } from "./components/FiltersAccordion";
import {
  AUTHORS,
  CATEGORIES,
  SELECT_ALL_VALUE,
  TAGS,
  TITLE_ONLY_VALUE
} from "./constants";

import { LinkedBadge } from "pstat-design-system/Badge";
import theme from "pstat-anywhere/themes/policystat/theme";

class SearchFilters extends Component {
  constructor(props) {
    super(props);

    const { titleOnly, authors, categories, references } = props.filters;
    const authorsBadgeText = this.getFilterBadgeText(
      false,
      authors.length,
      props.labels.authorLabel,
      props.labels.authorLabelPlural
    );
    const categoriesBadgeText = this.getFilterBadgeText(
      false,
      categories.length,
      props.labels.categoryLabel,
      props.labels.categoryLabelPlural
    );
    const tagsBadgeText = this.getFilterBadgeText(
      false,
      references.length,
      props.labels.referencesLabel,
      props.labels.referencesLabelPlural
    );

    const expanded = this.getExpandedValue(props.expanded);

    this.state = {
      categoriesBadgeText: categoriesBadgeText,
      authorsBadgeText: authorsBadgeText,
      tagsBadgeText: tagsBadgeText,

      allCategoriesSelected: false,
      allAuthorsSelected: false,
      allTagsSelected: false,

      titleOnly: titleOnly,
      selectedCategories: categories,
      selectedAuthors: authors,
      selectedTags: references,

      isShown: expanded !== null
    };

    // not making this state on purpose because it comes from query render props,
    // don't need to re-render anything again if this changes
    // because it just got re-rendered in the query renderer
    this.allCategories = [];
    this.allAuthors = [];
    this.allTags = [];
    this.isUpdated = false;
  }

  // add the pk to the list if it is not already in the list,
  // or remove it from the list if it is already there
  updatePkList = (list, pk) => {
    const pkIndex = list.indexOf(pk);
    if (pkIndex > -1) {
      list.splice(pkIndex, 1);
    } else {
      list.push(pk);
    }
  };

  showAuthors = () => {
    this.props.onExpandedFilterChange(AUTHORS);
    this.showFilters();
  };

  showCategories = () => {
    this.props.onExpandedFilterChange(CATEGORIES);
    this.showFilters();
  };

  showTags = () => {
    this.props.onExpandedFilterChange(TAGS);
    this.showFilters();
  };

  handleTitleOnlyChange = () => {
    this.setState({ titleOnly: !this.state.titleOnly });
  };

  handleAuthorsChange = authorPk => {
    const selectedAuthorsCopy = [...this.state.selectedAuthors];
    this.updatePkList(selectedAuthorsCopy, authorPk);
    const selectAll = selectedAuthorsCopy.length === this.allAuthors.length;
    this.setState({
      selectedAuthors: selectedAuthorsCopy,
      allAuthorsSelected: selectAll
    });
  };

  handleCategoriesChange = categoryPk => {
    const selectedCategoriesCopy = [...this.state.selectedCategories];
    this.updatePkList(selectedCategoriesCopy, categoryPk);
    const selectAll =
      selectedCategoriesCopy.length === this.allCategories.length;
    this.setState({
      selectedCategories: selectedCategoriesCopy,
      allCategoriesSelected: selectAll
    });
  };

  handleTagsChange = tagPk => {
    const selectedTagsCopy = [...this.state.selectedTags];
    this.updatePkList(selectedTagsCopy, tagPk);
    const selectAll = selectedTagsCopy.length === this.allTags.length;
    this.setState({
      selectedTags: selectedTagsCopy,
      allTagsSelected: selectAll
    });
  };

  handleSelectAllCategories = () => {
    if (this.state.allCategoriesSelected) {
      this.setState({
        allCategoriesSelected: false,
        selectedCategories: []
      });
    } else {
      this.setState({
        allCategoriesSelected: true,
        selectedCategories: this.allCategories
      });
    }
  };

  handleSelectAllAuthors = () => {
    if (this.state.allAuthorsSelected) {
      this.setState({
        allAuthorsSelected: false,
        selectedAuthors: []
      });
    } else {
      this.setState({
        allAuthorsSelected: true,
        selectedAuthors: this.allAuthors
      });
    }
  };

  handleSelectAllTags = () => {
    if (this.state.allTagsSelected) {
      this.setState({
        allTagsSelected: false,
        selectedTags: []
      });
    } else {
      this.setState({
        allTagsSelected: true,
        selectedTags: this.allTags
      });
    }
  };

  hideFilters = () => {
    this.props.onExpandedFilterChange(null);
    this.setState({ isShown: false });
  };

  showFilters = () => {
    this.setState({ isShown: true });
  };

  getFilterBadgeText = (allSelected, numberSelected, label, labelPlural) => {
    const { t } = this.props;
    if (allSelected || numberSelected === 0) {
      return `${t("acknowledgments.mine.tabs.all")} ${labelPlural}`;
    } else if (numberSelected === 1) {
      return `${numberSelected} ${label}`;
    } else {
      return `${numberSelected} ${labelPlural}`;
    }
  };

  updateFilterBadges = () => {
    let authorsBadgeText = this.getFilterBadgeText(
      this.state.allAuthorsSelected,
      this.state.selectedAuthors.length,
      this.props.labels.authorLabel,
      this.props.labels.authorLabelPlural
    );
    let categoriesBadgeText = this.getFilterBadgeText(
      this.state.allCategoriesSelected,
      this.state.selectedCategories.length,
      this.props.labels.categoryLabel,
      this.props.labels.categoryLabelPlural
    );
    let tagsBadgeText = this.getFilterBadgeText(
      this.state.allTagsSelected,
      this.state.selectedTags.length,
      this.props.labels.referencesLabel,
      this.props.labels.referencesLabelPlural
    );
    this.setState({
      authorsBadgeText: authorsBadgeText,
      categoriesBadgeText: categoriesBadgeText,
      tagsBadgeText: tagsBadgeText
    });
  };

  handleSearch = () => {
    this.updateFilterBadges();
    this.setState({ isShown: false });

    let authors = this.state.selectedAuthors;
    let categories = this.state.selectedCategories;
    let references = this.state.selectedTags;
    if (this.state.selectedAuthors.length === this.allAuthors.length) {
      authors = [];
    }
    if (this.state.selectedCategories.length === this.allCategories.length) {
      categories = [];
    }

    this.props.onFiltersChange({
      expanded: null,
      titleOnly: this.state.titleOnly,
      authors: authors,
      categories: categories,
      references: references
    });
  };

  handleClear = () => {
    this.setState({
      titleOnly: false,
      selectedCategories: [],
      allCategoriesSelected: false,
      selectedAuthors: [],
      allAuthorsSelected: false,
      selectedTags: [],
      allTagsSelected: false
    });
  };

  updateFilterData = filterQueryProps => {
    // only need to update the first time filters are loaded
    if (this.isUpdated) {
      return;
    }
    const { categories, authors, tags } = filterQueryProps;
    this.allCategories = categories.map(category => category.pk);
    this.allAuthors = authors.map(author => author.pk);
    this.allTags = tags.map(tag => tag.pk);
    this.isUpdated = true;

    // need to check that the initial filters are
    // actually things this user can see
    const validAuthors = this.state.selectedAuthors.filter(
      pk => this.allAuthors.indexOf(pk) > -1
    );
    const validCategories = this.state.selectedCategories.filter(
      pk => this.allCategories.indexOf(pk) > -1
    );
    const validTags = this.state.selectedTags.filter(
      pk => this.allTags.indexOf(pk) > -1
    );

    const selectedAuthors = validAuthors.length !== 0 ? validAuthors : [];
    const selectedCategories =
      validCategories.length !== 0 ? validCategories : [];
    const selectedTags = validTags.length !== 0 ? validTags : [];

    this.setState({
      selectedAuthors,
      selectedCategories,
      selectedTags,
      allAuthorsSelected: this.allAuthors.length === selectedAuthors.length,
      allCategoriesSelected:
        this.allCategories.length === selectedCategories.length,
      allTagsSelected: this.allTags.length === selectedTags.length
    });
  };

  getSelectedFilters = (allSelected, selected) => {
    if (allSelected) {
      return [SELECT_ALL_VALUE, ...selected];
    }
    return selected;
  };

  handleExpandedSectionChange = key => {
    if (key === this.props.expanded) {
      this.props.onExpandedFilterChange(null);
    } else {
      this.props.onExpandedFilterChange(key);
    }
  };

  handleKeyPress = (event, showFilterFunc) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      showFilterFunc();
    }
  };

  handleModalOpen = () => {
    // Sometimes full screen mode is not available, so we should check before trying activate it
    // There is no need to exit full screen mode after modal cancel, since dom node would be destroyed.
    // And full screen mode will be exited automatically without failure.
    if (document.fullscreenEnabled) {
      if (this.modalContentRef.requestFullscreen) {
        if (document.fullScreenElement) {
          // If some other element in full screen mode - close it
          document.cancelFullScreen();
        }
        // Request full screen can fails, so to not show broken app - just log error
        // Keep calm and log errors
        this.modalContentRef
          .requestFullscreen()
          .catch(error => console.error(error));
      } else if (this.modalContentRef.msRequestFullscreen) {
        if (document.msFullscreenElement) {
          document.msExitFullscreen();
        }
        this.modalContentRef
          .msRequestFullscreen()
          .catch(error => console.error(error));
      } else if (this.modalContentRef.mozRequestFullScreen) {
        if (document.mozFullScreenElement) {
          document.mozCancelFullScreen();
        }
        this.modalContentRef
          .mozRequestFullScreen()
          .catch(error => console.error(error));
      } else if (this.modalContentRef.webkitRequestFullscreen) {
        if (document.webkitFullscreenElement) {
          document.webkitCancelFullScreen();
        }
        this.modalContentRef
          .webkitRequestFullscreen()
          .catch(error => console.error(error));
      }
    }
  };

  componentDidUpdate(prevProps) {
    const filtersExpandedFromRouteChange =
      this.props.expanded && prevProps.expanded !== this.props.expanded;

    let hasNewState = false;
    const newState = {};
    if (filtersExpandedFromRouteChange && !this.state.isShown) {
      hasNewState = true;
      newState.isShown = true;
    }
    if (prevProps.filters.titleOnly !== this.props.filters.titleOnly) {
      hasNewState = true;
      newState.titleOnly = this.props.filters.titleOnly;
    }
    if (hasNewState) {
      this.setState(newState);
    }
  }

  getExpandedValue(expanded) {
    if (expanded === AUTHORS || expanded === CATEGORIES || expanded === TAGS) {
      return expanded;
    }
    return null;
  }

  render() {
    const expanded = this.getExpandedValue(this.props.expanded);
    return (
      <Fragment>
        {this.props.filters.titleOnly && (
          <LinkedBadge
            display="inline-block"
            onClick={this.showFilters}
            onKeyDown={event => this.handleKeyPress(event, this.showFilters)}
          >
            Titles Only
          </LinkedBadge>
        )}
        <LinkedBadge
          display="inline-block"
          onClick={this.showCategories}
          onKeyDown={event => this.handleKeyPress(event, this.showCategories)}
        >
          {this.state.categoriesBadgeText}
        </LinkedBadge>
        <LinkedBadge
          display="inline-block"
          onClick={this.showAuthors}
          onKeyDown={event => this.handleKeyPress(event, this.showAuthors)}
        >
          {this.state.authorsBadgeText}
        </LinkedBadge>
        <LinkedBadge
          display="inline-block"
          onClick={this.showTags}
          onKeyDown={event => this.handleKeyPress(event, this.showTags)}
        >
          {this.state.tagsBadgeText}
        </LinkedBadge>
        {this.state.isShown && (
          <Media query={{ minWidth: theme.breakpoints[1] }}>
            {matches =>
              matches ? (
                <FiltersPositioner>
                  <OverflowFlex
                    flexDirection="column"
                    justifyContent="space-between"
                    flex={1}
                  >
                    <FiltersTitle hideFilters={this.hideFilters} />
                    <FiltersAccordion
                      filterOptions={this.props.filterOptions}
                      fetchFilterOptions={this.props.fetchFilterOptions}
                      hideFilters={this.hideFilters}
                      expanded={expanded}
                      onExpandedSectionChange={this.handleExpandedSectionChange}
                      titleOnly={this.state.titleOnly ? [TITLE_ONLY_VALUE] : []}
                      onTitleOnlyChange={this.handleTitleOnlyChange}
                      selectedAuthors={this.getSelectedFilters(
                        this.state.allAuthorsSelected,
                        this.state.selectedAuthors
                      )}
                      onAuthorsChange={this.handleAuthorsChange}
                      onSelectAllAuthors={this.handleSelectAllAuthors}
                      selectedCategories={this.getSelectedFilters(
                        this.state.allCategoriesSelected,
                        this.state.selectedCategories
                      )}
                      onCategoriesChange={this.handleCategoriesChange}
                      onSelectAllCategories={this.handleSelectAllCategories}
                      selectedTags={this.getSelectedFilters(
                        this.state.allTagsSelected,
                        this.state.selectedTags
                      )}
                      onTagsChange={this.handleTagsChange}
                      onSelectAllTags={this.handleSelectAllTags}
                      updateFilterData={this.updateFilterData}
                      environment={this.props.environment}
                    />
                  </OverflowFlex>
                  <Box>
                    <FiltersFooter
                      onSearch={this.handleSearch}
                      onClear={this.handleClear}
                    />
                  </Box>
                </FiltersPositioner>
              ) : (
                <MobileFiltersModal
                  isShown={this.state.isShown}
                  onClose={this.hideFilters}
                  onAfterOpen={this.handleModalOpen}
                  contentRef={node => (this.modalContentRef = node)}
                >
                  <FullHeightFlex
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <OverflowFlex
                      flexDirection="column"
                      justifyContent="space-between"
                      flex={1}
                      bg="nav.95"
                    >
                      <FiltersAccordion
                        filterOptions={this.props.filterOptions}
                        fetchFilterOptions={this.props.fetchFilterOptions}
                        hideFilters={this.hideFilters}
                        expanded={expanded}
                        onExpandedSectionChange={
                          this.handleExpandedSectionChange
                        }
                        titleOnly={
                          this.state.titleOnly ? [TITLE_ONLY_VALUE] : []
                        }
                        onTitleOnlyChange={this.handleTitleOnlyChange}
                        selectedAuthors={this.getSelectedFilters(
                          this.state.allAuthorsSelected,
                          this.state.selectedAuthors
                        )}
                        onAuthorsChange={this.handleAuthorsChange}
                        onSelectAllAuthors={this.handleSelectAllAuthors}
                        selectedCategories={this.getSelectedFilters(
                          this.state.allCategoriesSelected,
                          this.state.selectedCategories
                        )}
                        onCategoriesChange={this.handleCategoriesChange}
                        onSelectAllCategories={this.handleSelectAllCategories}
                        selectedTags={this.getSelectedFilters(
                          this.state.allTagsSelected,
                          this.state.selectedTags
                        )}
                        onTagsChange={this.handleTagsChange}
                        onSelectAllTags={this.handleSelectAllTags}
                        updateFilterData={this.updateFilterData}
                        environment={this.props.environment}
                      />
                    </OverflowFlex>
                    <FiltersFooter
                      onSearch={this.handleSearch}
                      onClear={this.handleClear}
                    />
                  </FullHeightFlex>
                </MobileFiltersModal>
              )
            }
          </Media>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(withLabelContext(SearchFilters));
