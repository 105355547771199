//@flow

import { withUniqueId } from "@team-griffin/react-unique-id";
import { Radio } from "react-radio-group";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { fontFamily } from "pstat-design-system/utils";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

const Input = styled(Radio)`
  opacity: 0;
  width: 0;
  padding: 0;
  margin: 0;
`;

// original CSS from https://medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3

const Label = styled.label`
  ${fontFamily};
  font-size: ${themeGet("fontSizes.2")};
  color: ${themeGet("colors.nav.0")};
  position: relative;
  display: inline-block;
  cursor: pointer;

  /*24px width of fake checkbox + 8px distance between fake checkbox and text*/
  padding-left: ${themeGet("space.8")}px;

  &::before,
  &::after {
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
    cursor: pointer;
  }

  /*Outer box of the fake checkbox*/
  &::before {
    box-sizing: border-box;
    height: 24px;
    width: 24px;

    left: 0px;
    top: 50%;

    border-radius: 100%;

    border: 1px solid ${themeGet("colors.nav.80")};
    background-color: ${themeGet("colors.nav.100")};

    box-shadow: inset 2px 2px ${themeGet("colors.nav.90")};
    transform: translateY(-50%);
  }

  &::after {
    box-sizing: border-box;
    height: 8px;
    width: 8px;

    left: 8px;
    top: 50%;

    border-radius: 100%;
    background-color: ${themeGet("colors.nav.100")};
    transform: translateY(-50%);
  }
`;

const Container = system(
  {
    lineHeight: "24px",
    p: 1
  },
  "display"
).extend`

/*Hide the activated radio by default*/
${Input} + ${Label}::after {
  content: none;
}

/* make outer dot blue on check */
${Input}:checked + ${Label}::before {
  background-color: ${themeGet("colors.secondary.0")};
  box-shadow: none;
}

/*Unhide inner dot on the checked state*/
${Input}:checked + ${Label}::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake radio button*/
${Input}:focus + ${Label}::before {
  border: 1px solid ${themeGet("colors.secondary.0")};
  box-shadow: none;
}

${Input}:disabled + ${Label}::before {
  background-color: ${themeGet("colors.secondary.80")};
  box-shadow: none;
}
`;

const PstatRadio = ({
  renderLabel,
  label,
  inline,
  id,
  value,
  disabled = false,
  disabledTooltipText
}) => {
  let disabledTooltipProps = {};
  if (disabled) {
    disabledTooltipProps = {
      "data-tip": true,
      "data-for": id + "tooltip",
      "data-event": HOVER_EVENTS,
      "data-event-off": HOVER_EVENTS_OFF
    };
  }
  return (
    <Container display={inline ? "inline-block" : "block"} mr={inline ? 5 : 0}>
      <Input id={id} value={value} disabled={disabled} />
      <Label htmlFor={id} {...disabledTooltipProps}>
        {renderLabel ? renderLabel() : label}
      </Label>
      {disabled && (
        <Tooltip name={id + "tooltip"}>{disabledTooltipText}</Tooltip>
      )}
    </Container>
  );
};
const RadioWithId = withUniqueId({})(PstatRadio);

export default RadioWithId;
