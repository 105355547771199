/**
 * @flow
 * @relayHash f02bf00ed938b9cb214e4a91dfa06925
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ForgotPasswordMutationVariables = {|
  email: string,
  captcha: string,
|};
export type ForgotPasswordMutationResponse = {|
  +forgotPassword: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type ForgotPasswordMutation = {|
  variables: ForgotPasswordMutationVariables,
  response: ForgotPasswordMutationResponse,
|};
*/


/*
mutation ForgotPasswordMutation(
  $email: String!
  $captcha: String!
) {
  forgotPassword(email: $email, captcha: $captcha) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "captcha",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "forgotPassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "captcha",
        "variableName": "captcha",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email",
        "type": "String!"
      }
    ],
    "concreteType": "ForgotPassword",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ForgotPasswordMutation",
  "id": "5c39a2b6ae0f77aec0ad65df3b517594",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ForgotPasswordMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ForgotPasswordMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation ForgotPasswordMutation(\n  $email: String!\n  $captcha: String!\n) {\n  forgotPassword(email: $email, captcha: $captcha) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = '063acdcc09e885c02b9335a0bb7873aa';
module.exports = node;
