import graphql from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import { AttachmentsFragmentContainer } from "pstat-anywhere/components/view/Attachments";

const TemplateAttachments = ({ template }) => (
  <AttachmentsFragmentContainer attachments={template.attachments} />
);

export default createFragmentContainer(
  TemplateAttachments,
  graphql`
    fragment Attachments_template on SystemDocumentTemplateType {
      attachments(excludeEmbedded: true) {
        ...Attachments_attachments
      }
    }
  `
);
