/**
 * @flow
 * @relayHash 6efd887073b521ebd01a0dbf9a3c3ec7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlocCopiesQueryVariables = {|
  tenantPks: $ReadOnlyArray<?number>,
  excludeBlocPk: number,
|};
export type BlocCopiesQueryResponse = {|
  +blocCopies: ?{|
    +taskId: ?string
  |}
|};
export type BlocCopiesQuery = {|
  variables: BlocCopiesQueryVariables,
  response: BlocCopiesQueryResponse,
|};
*/


/*
query BlocCopiesQuery(
  $tenantPks: [Int]!
  $excludeBlocPk: Int!
) {
  blocCopies(tenantPks: $tenantPks, excludeBlocPk: $excludeBlocPk) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantPks",
    "type": "[Int]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "excludeBlocPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "blocCopies",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "excludeBlocPk",
        "variableName": "excludeBlocPk",
        "type": "Int!"
      },
      {
        "kind": "Variable",
        "name": "tenantPks",
        "variableName": "tenantPks",
        "type": "[Int]!"
      }
    ],
    "concreteType": "BlocCopiesType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BlocCopiesQuery",
  "id": "d5bce0c62400e02640fc5aead3e83666",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BlocCopiesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "BlocCopiesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query BlocCopiesQuery(\n  $tenantPks: [Int]!\n  $excludeBlocPk: Int!\n) {\n  blocCopies(tenantPks: $tenantPks, excludeBlocPk: $excludeBlocPk) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = 'a9b00c8c1067b8fd1541f54cdceb9143';
module.exports = node;
