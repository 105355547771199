import graphql from "babel-plugin-relay/macro";

const LocalDocumentsForTemplatePageQuery = graphql`
  query LocalDocumentsForTemplateQuery(
    $pk: Int!
    $after: String
    $first: Int
    $sort: String
  ) {
    blocSystemDocumentTemplatesDocuments(pk: $pk, sort: $sort) {
      blocSystemDocumentTemplates(after: $after, first: $first) {
        totalCount
        edges {
          node {
            systemDocumentTemplate {
              pk
              name
            }
            bloc {
              tenants {
                name
              }
            }
            publishDate
            document {
              pk
              name
              status
              category {
                name
              }
              author {
                pk
                firstName
                lastName
                title
                email
              }
              effectiveDate
              link(fullUrl: true)
            }
          }
        }
      }
      systemDocumentTemplateName
      error {
        statusCode
        message
      }
      unpublishedDocumentsCount
    }
  }
`;

export default LocalDocumentsForTemplatePageQuery;
