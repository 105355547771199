import system from "system-components";

import { TextInputWithIcon } from "./TextInput";

const TextInputContainer = system({
  bg: ["nav.95", "nav.95", "nav.100"],
  pt: 4,
  pb: [8, 8, 0],
  px: 4,
  boxShadow: "card"
});

const TableSearchBar = props => (
  <TextInputContainer boxShadow={props.boxShadow} pt={props.pt}>
    <TextInputWithIcon
      icon={["far", "search"]}
      side="left"
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  </TextInputContainer>
);

export default TableSearchBar;
