import { Route } from "found";


import AdminMenuPage from "./AdminMenuPage";
import AdminMenuQuery from "./AdminMenuQuery";

import { PageWrapper } from "pstat-anywhere/utils/pageWrappers";

const routes = (
  <Route
    path="admin"
    query={AdminMenuQuery}
    data={{
      routeName: "admin_menu"
    }}
    render={({ props }) => {
      return (
        <PageWrapper
          component={AdminMenuPage}
          queryName="adminMenu"
          titleKey="administration.title"
          {...props}
        />
      );
    }}
  />
);

export default routes;
