import { withTranslation } from "react-i18next";
import Media from "react-media";
import system from "system-components";

import theme from "../../themes/policystat/theme";

import { DuoToneFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { addRedirect, getRootUrl } from "pstat-anywhere/utils/urls";
import { Text } from "pstat-design-system/text/paragraph";
import Tooltip from "pstat-design-system/Tooltip";


const VersionSwitcher = props => {
  const currentPath = window.location.pathname;
  let params = window.location.search;
  let legacyPath = currentPath.replace(RegExp("^/v2/"), "/");
  if (legacyPath === "") {
    legacyPath = "/";
  } else {
    const rootUrl = getRootUrl();
    const startsWithRootPath = legacyPath.startsWith(rootUrl);
    if (startsWithRootPath) {
      legacyPath = legacyPath.substring(rootUrl.length);
    }
    if (legacyPath.startsWith(`/search`)) {
      legacyPath = "/policy_search/";
    }
    if (startsWithRootPath) {
      legacyPath = `${rootUrl}${legacyPath}`;
    }
  }
  if (!legacyPath.endsWith("/")) {
    legacyPath += "/";
  }
  // path to legacy return view should be hard-coded "/v2/"
  // it is added to urls separate from PSA
  const switcherView = `${getRootUrl()}/v2/to_legacy/`;
  legacyPath = switcherView + addRedirect(legacyPath, params);
  return (
    <Media query={{ minWidth: theme.breakpoints[1] }}>
      {isDesktop => (
        <SwitcherContainer px={isDesktop ? 3 : 8}>
          <SwitcherBorderContainer>
            <ToggleSwitchLink
              href={legacyPath}
              className="psa_to_legacy_switch"
              aria-describedby="version-switcher-text"
            >
              <ToggleSwitchText>
                {props.t("partials.versionSwitcher.switchText")}
              </ToggleSwitchText>
              <ToggleSwitchCircle />
            </ToggleSwitchLink>
            <SwitcherText id="version-switcher-text">
              {props.t("partials.versionSwitcher.label")}
            </SwitcherText>
            <DuoToneFontAwesomeIcon
              aria-describedby="version-switcher-tooltip"
              aria-hidden={false}
              icon={["fad", "question-circle"]}
              size="sm"
              primaryColor={theme.colors.nav["100"]}
              secondaryColor={theme.colors.switch.help}
              data-tip
              data-for="version-switcher-tooltip"
              data-event="mouseover focus"
              data-event-off="mouseout blur"
            />
            <Tooltip name="version-switcher-tooltip">
              {props.t("partials.versionSwitcher.tooltip")}
            </Tooltip>
          </SwitcherBorderContainer>
        </SwitcherContainer>
      )}
    </Media>
  );
};

export default withTranslation()(VersionSwitcher);

const SwitcherContainer = system({
  height: "inherit",
  display: "flex",
  alignItems: "center",
  px: 3
});
SwitcherContainer.displayName = "SwitcherContainer";

const SwitcherBorderContainer = system({
  is: "span",
  display: "inline-flex",
  alignItems: "center",
  bg: "nav.0",
  border: 1,
  borderColor: "nav.10",
  borderRadius: "4px",
  color: "nav.100",
  px: 2,
  py: 1
});
SwitcherBorderContainer.displayName = "SwitcherBorderContainer";

const ToggleSwitchCircle = system({
  display: "inline-block",
  position: "absolute",
  top: "1px",
  right: 0,
  bg: "nav.100",
  width: "18px",
  height: "18px",
  borderRadius: "15px",
  boxShadow:
    "0 0 0 1px rgba(0, 0, 0, 0.9), 0 2px 0 -1px rgba(152, 162, 179, 0.3)"
});
ToggleSwitchCircle.displayName = "ToggleSwitchCircle";

const SwitcherText = system({
  is: Text,
  display: "inline-block",
  px: 2,
  fontSize: 1,
  color: "nav.100"
});
SwitcherText.displayName = "SwitcherText";

const ToggleSwitchLink = system({
  is: "a",
  display: "inline-flex",
  position: "relative",
  alignItems: "center",
  bg: "switch.background",
  borderRadius: "15px",
  height: "20px",
  width: "46px"
}).extend`
  text-decoration: none;
`;
ToggleSwitchLink.displayName = "ToggleSwitchLink";

const ToggleSwitchText = system({
  is: SwitcherText,
  fontSize: 0,
  pl: 2
});
ToggleSwitchText.displayName = "ToggleSwitchText";
