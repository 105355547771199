/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PrimaryNav_documentTemplates$ref: FragmentReference;
export type PrimaryNav_documentTemplates = $ReadOnlyArray<{|
  +pk: ?number,
  +name: string,
  +$refType: PrimaryNav_documentTemplates$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PrimaryNav_documentTemplates",
  "type": "DocumentTemplateType",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pk",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '66cac0e11c1c22dba665eef8b37d8b58';
module.exports = node;
