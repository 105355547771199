import graphql from "babel-plugin-relay/macro";

export const BlocDetailPageQuery = graphql`
  query BlocDetailPageQuery($blocPk: Int!, $sort: String) {
    blocDetails(pk: $blocPk) {
      error {
        statusCode
        message
      }
      bloc {
        pk
        name
        tenants(sort: $sort) {
          pk
          name
          subdomain
          created
        }
        documentSettings {
          pk
        }
        isEditable
      }
    }
    canAdminTenants {
      documentSettings {
        pk
        display
      }
    }
  }
`;

export default BlocDetailPageQuery;
