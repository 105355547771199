import graphql from "babel-plugin-relay/macro";

export const UnsubscribedFromCommentsQuery = graphql`
  query UnsubscribedFromCommentsQuery($documentPk: Int!) {
    unsubscribedDocument(pk: $documentPk) {
      document {
        name
        link
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default UnsubscribedFromCommentsQuery;
