//Policy statuses
export const SAVED = "SAVED";
export const PENDING = "PENDING";
export const OLD_SAVE = "OLD_SAVE";
export const ACTIVE = "ACTIVE";
export const OLD = "OLD";
export const RETIRED = "RETIRED";
export const DELETED = "DELETED";
export const SCHEDULED = "SCHEDULED";

//Policy review statuses
export const PRE_IMPORT = "PREIMPORT";
export const UNCHANGED = "UNCHANGED";
export const REVISED = "REVISED";
export const NEW = "NEW";

//Template status
export const DRAFT_NOT_STARTED = "DRAFT_NOT_STARTED";

//Other statuses
const PREVIEW = "Preview"; //this status is not returned from backend

export const isDraft = status => status === SAVED;
export const isPending = status => status === PENDING;
export const isOldDraft = status => status === OLD_SAVE;
export const isActive = status => status === ACTIVE;
export const isOld = status => status === OLD;
export const isRetired = status => status === RETIRED;
export const isDeleted = status => status === DELETED;
export const isScheduled = status => status === SCHEDULED;

// A function to take a status and return the correct text ie: SAVED -> Draft
// can also add translations
export const parseDocumentStatusToTranslationKey = status => {
  const dict = {
    //Policy status
    [SAVED]: "documentControl.documentStatus.draftStatus",
    [PENDING]: "documentControl.documentStatus.pendingStatus",
    [OLD_SAVE]: "documentControl.documentStatus.oldSaveStatus",
    [ACTIVE]: "documentControl.documentStatus.activeStatus",
    [OLD]: "documentControl.documentStatus.oldStatus",
    [RETIRED]: "documentControl.documentStatus.retiredStatus",
    [DELETED]: "documentControl.documentStatus.deletedStatus",
    [SCHEDULED]: "documentControl.documentStatus.scheduledStatus",

    //Policy review status
    [PRE_IMPORT]: "tables.formatters.prePolicyStat",
    [UNCHANGED]: "tables.formatters.unchanged",
    [REVISED]: "tables.formatters.revised",
    [NEW]: "tables.formatters.new",

    //Template status
    [DRAFT_NOT_STARTED]: "systemDocumentTemplates.status.draftNotStarted",

    //Other status
    [PREVIEW]: "documentControl.documentStatus.previewStatus"
  };

  return dict[status];
};

export const DOCUMENT_CONTENT_TYPE = "DOCUMENT";
export const DOCUMENT_SAVE_CONTENT_TYPE = "DOCUMENT_SAVE";

export const shouldShowOriginationDate = tenant => {
  // Directly compare with false instead of !tenant.hasScheduledEffectiveDateEnabled, cause it might be undefined
  // And, in such case, !tenant.hasScheduledEffectiveDateEnabled would be evaluated as true
  // This if else might be simplified to 1 row. But it would be not readable :(
  return (
    tenant.hasScheduledEffectiveDateEnabled === true ||
    typeof tenant.hasScheduledEffectiveDateEnabled === "undefined"
  );
};
