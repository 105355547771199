import { isDevMode } from "./urls";

const isTestMode = () => process.env.NODE_ENV === "test";

export const canViewInDevelopmentWork = viewer => {
  if (isDevMode() || isTestMode()) {
    return true;
  }
  if (!viewer) {
    return false;
  }
  return viewer.isStaff;
};
