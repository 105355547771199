import graphql from "babel-plugin-relay/macro";

export default graphql`
  query pendingApproversNonRelayQuery {
    pendingApprovers {
      userDisplay
      id
    }
  }
`;
