/**
 * @flow
 * @relayHash d0217bae80c270555f61ac290f84e804
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NoticeSettingsQueryVariables = {|
  userPk?: ?number
|};
export type NoticeSettingsQueryResponse = {|
  +noticeSettings: ?{|
    +settings: ?$ReadOnlyArray<?{|
      +noticeType: {|
        +pk: ?number,
        +whenSent: ?string,
        +display: string,
        +description: string,
      |},
      +noticeMedium: ?string,
      +send: boolean,
    |}>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type NoticeSettingsQuery = {|
  variables: NoticeSettingsQueryVariables,
  response: NoticeSettingsQueryResponse,
|};
*/


/*
query NoticeSettingsQuery(
  $userPk: Int
) {
  noticeSettings(userPk: $userPk) {
    settings {
      noticeType {
        pk
        whenSent
        display
        description
        id
      }
      noticeMedium
      send
      id
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userPk",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userPk",
    "variableName": "userPk",
    "type": "Int"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "whenSent",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "display",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "noticeMedium",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "send",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NoticeSettingsQuery",
  "id": "8155a0de7fed23945f1a21c6e4cd76ec",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NoticeSettingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "noticeSettings",
        "storageKey": null,
        "args": v1,
        "concreteType": "NoticeSettingsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "NoticeSettingType",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "noticeType",
                "storageKey": null,
                "args": null,
                "concreteType": "NoticeTypeType",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5
                ]
              },
              v6,
              v7
            ]
          },
          v8
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NoticeSettingsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "noticeSettings",
        "storageKey": null,
        "args": v1,
        "concreteType": "NoticeSettingsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "NoticeSettingType",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "noticeType",
                "storageKey": null,
                "args": null,
                "concreteType": "NoticeTypeType",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v9
                ]
              },
              v6,
              v7,
              v9
            ]
          },
          v8
        ]
      }
    ]
  }
};
})();
node.text = "query NoticeSettingsQuery(\n  $userPk: Int\n) {\n  noticeSettings(userPk: $userPk) {\n    settings {\n      noticeType {\n        pk\n        whenSent\n        display\n        description\n        id\n      }\n      noticeMedium\n      send\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = '804b96ab417d984cd8cb2015f45ff880';
module.exports = node;
