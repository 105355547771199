// @flow
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import { getRootUrl } from "../../utils/urls";

import { LoginFormContainer } from "./Common";
import { PasswordResetTokenInvalid } from "./ForgotPasswordConfirm";
import { doResetPassword } from "./ForgotPasswordResetMutation";

import { Button } from "pstat-design-system/shared/Button";
import { createSuccessMessage } from "pstat-design-system/message/manageMessages";
import { InputFormElement } from "pstat-design-system/inputs/FormElements";

class _PasswordResetConfirmForm extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      noChangesMade: true,
      errorFound: false,
      saving: false,
      formError: {
        newPassword1: "",
        newPassword2: "",
        token: null
      },
      form: {
        newPassword1: "",
        newPassword2: ""
      },
      reasons: []
    };
  }

  handleChange = (event: Event) => {
    let form = Object.assign({}, this.state.form);
    const { name, value } = event.target;
    form[name] = value;
    this.setState({
      form,
      noChangesMade: false
    });
  };

  validateFields = () => {
    this.resetErrors();
    let isValid = true;
    const required = ["newPassword1", "newPassword2"];

    const { t } = this.props;
    const form = this.state.form;
    const error = Object.assign({}, this.state.formError);
    Object.keys(form).forEach(field => {
      if (required.includes(field)) {
        // Checking for blank fields that are required
        if (!form[field]) {
          error[field] = true;
          isValid = false;
        }
      }
      // Make sure passwords are the same
      if (form["newPassword1"] !== form["newPassword2"]) {
        error["newPassword2"] = [
          t("authentication.passwordResetForm.errors.passwordsDoNotMatch")
        ];
        isValid = false;
      }
    });
    this.setState({
      formError: error,
      errorFound: !isValid
    });
    return isValid;
  };

  resetErrors = () => {
    const error = Object.assign({}, this.state.formError);
    Object.keys(error).forEach(field => {
      error[field] = false;
    });
    this.setState({
      formError: error,
      errorFound: false
    });
  };

  formSubmit = e => {
    e.preventDefault();
    const { t } = this.props;
    const { uidb64, token } = this.props.params;
    this.setState({ saving: true, reasons: [] }, () => {
      if (this.validateFields()) {
        const form = this.state.form;
        doResetPassword(
          uidb64,
          token,
          form.newPassword1,
          form.newPassword2,
          response => {
            const { ok, reasons } = response.resetPassword;
            if (ok) {
              this.setState({
                saving: false,
                noChangesMade: true
              });
              createSuccessMessage(
                t("authentication.passwordResetForm.onCompleted.successMessage")
              );
              this.props.router.push(getRootUrl() || "/");
            } else if (reasons) {
              this.setState({
                formError: reasons,
                errorFound: true
              });
            }
          }
        );
      } else {
        this.setState({
          saving: false,
          noChangesMade: false // when there are changes not saved
        });
      }
    });
    return false;
  };

  render() {
    // we can not use useTranslation, as it conflicts with hooks
    const { t } = this.props;
    const { form, formError } = this.state;
    return (
      <Fragment>
        {formError.token ? (
          <PasswordResetTokenInvalid />
        ) : (
          <Fragment>
            <LoginFormContainer onSubmit={this.formSubmit} id="passwordReset">
              <InputFormElement
                required={true}
                autosize
                value={form.newPassword1}
                name="newPassword1"
                label={t("authentication.passwordResetForm.newPassword1")}
                onChange={this.handleChange}
                type="password"
                errors={formError.newPassword1}
                id="pwd-reset-new-password1"
              />

              <InputFormElement
                required={true}
                autosize
                value={form.newPassword2}
                name="newPassword2"
                label={t("authentication.passwordResetForm.newPassword2")}
                onChange={this.handleChange}
                type="password"
                errors={formError.newPassword2}
                id="pwd-reset-new-password2"
              />

              <Button
                focusBorderProps={{ width: "100%" }}
                width="100%"
                buttonStyle="secondary"
                form="passwordReset"
              >
                {t("buttons.passwordReset")}
              </Button>
            </LoginFormContainer>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

// we can not use useTranslation, as it conflicts with hooks
export const PasswordResetConfirmForm = withTranslation()(
  _PasswordResetConfirmForm
);
