import graphql from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { fetchQuery } from "react-relay";

import AutocompleteTextInput from "./AutocompleteTextInput";

import { environment } from "pstat-anywhere/relay";

const query = graphql`
  query StandardsAndRegulationsInputQuery {
    agenciesForTenant {
      name
      isCap
      programs {
        name
        category {
          pk
          name
        }
        regulations {
          pk
          name
        }
      }
    }
  }
`;

const buildSuggestion = (name, level) => {
  return {
    name,
    level,
    expanded: false,
    isVisible: false
  };
};

const buildAgencies = agencies => {
  const baseIndentionLevel = 0;
  let agencySuggestions = [];
  for (let i = 0; i < agencies.length; i++) {
    const agency = agencies[i];
    const isCap = agency.isCap;
    const agencySuggestion = buildSuggestion(agency.name, baseIndentionLevel);
    agencySuggestion.isVisible = true;
    agencySuggestions.push(agencySuggestion);
    agencySuggestions = agencySuggestions.concat(
      buildPrograms(agency.programs, baseIndentionLevel + 1, isCap)
    );
  }
  return agencySuggestions;
};

const getProgramsByCategory = programs => {
  const programsByCategory = {};
  const categoriesByPk = {};
  for (let i = 0; i < programs.length; i++) {
    const program = programs[i];
    const category = program.category;
    categoriesByPk[category.pk] = category;
    let programsForCategory = programsByCategory[category.pk];
    if (!programsForCategory) {
      programsForCategory = [program];
    } else {
      programsForCategory.push(program);
    }
    programsByCategory[category.pk] = programsForCategory;
  }
  return [categoriesByPk, programsByCategory];
};

const buildPrograms = (programs, level, isCap) => {
  let programSuggestions = [];
  if (isCap) {
    const [categories, programsByCategory] = getProgramsByCategory(programs);
    const categoryPks = Object.keys(categories);
    for (let i = 0; i < categoryPks.length; i++) {
      const categoryPk = categoryPks[i];
      const category = categories[categoryPk];
      programSuggestions.push(buildSuggestion(category.name, level));
      const categoryPrograms = programsByCategory[categoryPk];
      for (let j = 0; j < categoryPrograms.length; j++) {
        const program = categoryPrograms[j];
        const regulations = program.regulations;
        programSuggestions.push(buildSuggestion(program.name, level + 1));
        programSuggestions = programSuggestions.concat(
          buildRegulations(regulations, level + 2)
        );
      }
    }
  } else {
    for (let i = 0; i < programs.length; i++) {
      const program = programs[i];
      const regulations = program.regulations;
      programSuggestions.push(buildSuggestion(program.name, level));
      programSuggestions = programSuggestions.concat(
        buildRegulations(regulations, level + 1)
      );
    }
  }
  return programSuggestions;
};

const buildRegulations = (regulations, level) => {
  const regulationSuggestions = [];
  for (let i = 0; i < regulations.length; i++) {
    const regulation = regulations[i];
    const regulationSuggestion = buildSuggestion(regulation.name, level);
    regulationSuggestion.isRegulation = true;
    regulationSuggestion.pk = regulation.pk;
    regulationSuggestions.push(regulationSuggestion);
  }
  return regulationSuggestions;
};

const buildInitialSuggestions = suggestions => {
  return buildAgencies(suggestions);
};

const expandOrCollapseSuggestions = (
  suggestion,
  suggestionIndex,
  suggestionsCopy
) => {
  const expanded = !suggestion.expanded;
  suggestionsCopy[suggestionIndex].expanded = expanded;
  if (expanded) {
    expandNextLevel(suggestion, suggestionIndex, suggestionsCopy);
  } else {
    collapseNextLevel(suggestion, suggestionIndex, suggestionsCopy);
  }
};

const expandNextLevel = (suggestion, suggestionIndex, suggestions) => {
  updateNextLevelVisibility(suggestion, suggestionIndex, suggestions, true);
};

const collapseNextLevel = (suggestion, suggestionIndex, suggestions) => {
  updateNextLevelVisibility(suggestion, suggestionIndex, suggestions, false);
};

const updateNextLevelVisibility = (
  suggestion,
  suggestionIndex,
  suggestions,
  isVisible
) => {
  const suggestionLevel = suggestion.level;
  const nextLevel = suggestionLevel + 1;
  let nextSuggestionIndex = suggestionIndex + 1;
  for (let i = nextSuggestionIndex; i < suggestions.length; i++) {
    const nextSuggestion = suggestions[i];
    if (nextSuggestion.level === suggestionLevel) {
      break;
    }
    // if collapsing, collapse all levels below
    if (nextSuggestion.level >= nextLevel && !isVisible) {
      nextSuggestion.isVisible = isVisible;
      if (!nextSuggestion.isRegulation) {
        nextSuggestion.expanded = false;
      }
    }
    // if expanding, only show next level below
    if (nextSuggestion.level === nextLevel) {
      nextSuggestion.isVisible = isVisible;
    }
  }
};

const getSuggestionIndex = (visibleSuggestionsIndex, suggestions) => {
  let visibleSuggestionsSeen = 0;
  for (let i = 0; i < suggestions.length; i++) {
    const suggestion = suggestions[i];
    if (suggestion.isVisible) {
      visibleSuggestionsSeen++;
      if (visibleSuggestionsSeen === visibleSuggestionsIndex + 1) {
        return i;
      }
    }
  }
};

const getVisibleSuggestions = suggestions =>
  suggestions.filter(suggestion => suggestion.isVisible);

const StandardsAndRegulationsInput = ({
  onChange,
  onClear,
  name,
  initialSelectedRegPks,
  ...otherProps
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const getSuggestionValue = suggestion => suggestion.name;

  const addSuggestionToSelectedSuggestions = suggestion => {
    const currentlySelectedSuggestionPks = selectedSuggestions.map(
      suggestion => suggestion.pk
    );
    if (currentlySelectedSuggestionPks.indexOf(suggestion.pk) === -1) {
      const updatedSelectedSuggestions = [...selectedSuggestions, suggestion];
      updateSelectedSuggestions(updatedSelectedSuggestions);
    }
  };

  const updateSelectedSuggestions = selectedSuggestions => {
    if (selectedSuggestions.length === 0) {
      onClear();
    } else {
      const event = {
        target: {
          value: selectedSuggestions.map(suggestion => suggestion.pk),
          name: name
        }
      };
      onChange(event);
    }
    setSelectedSuggestions(selectedSuggestions);
  };

  const handleSelectedSuggestion = (suggestion, visibleSuggestionIndex) => {
    const suggestionsCopy = [...suggestions];
    if (suggestion.isRegulation) {
      addSuggestionToSelectedSuggestions(suggestion);
    } else {
      const suggestionIndex = getSuggestionIndex(
        visibleSuggestionIndex,
        suggestions
      );
      expandOrCollapseSuggestions(suggestion, suggestionIndex, suggestionsCopy);
      setSuggestions(suggestionsCopy);
    }
  };

  const shouldKeepSuggestionsOnSelect = suggestion => !suggestion.isRegulation;

  useEffect(() => {
    const isInitialSuggestion = suggestion => {
      return (
        suggestion.isRegulation &&
        initialSelectedRegPks.indexOf(suggestion.pk) > -1
      );
    };
    fetchQuery(environment, query).then(data => {
      if (!data) {
        return;
      }
      const agencies = data.agenciesForTenant;
      const suggestions = buildInitialSuggestions(agencies);
      const initialSuggestions = suggestions.filter(isInitialSuggestion);
      setSuggestions(suggestions);
      setSelectedSuggestions(initialSuggestions);
    });
    // only want fetchQuery to be called once per page load
    // since `initialSelectedRegPks` is an array, the dependency comparison will
    // think the pks changed when they didn't, just the array reference did
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutocompleteTextInput
      selectedSuggestions={selectedSuggestions}
      onSuggestionSelected={handleSelectedSuggestion}
      onSuggestionDeselected={updateSelectedSuggestions}
      suggestions={getVisibleSuggestions(suggestions)}
      onSuggestionsFetchRequested={() => {}}
      getSuggestionValue={getSuggestionValue}
      selectMultiple={true}
      standardsAndRegs={true}
      shouldKeepSuggestionsOnSelect={shouldKeepSuggestionsOnSelect}
      name={name}
      {...otherProps}
    />
  );
};

export default StandardsAndRegulationsInput;
