import { useState } from "react";
import { useTranslation } from "react-i18next";

export function useFocusBorderButton({
  onMouseDown,
  onFocus,
  onBlur,
  showFocus
}) {
  const [hasFocus, setHasFocus] = useState(showFocus);
  const { t } = useTranslation();

  const handleOnMouseDown = event => {
    // prevent the button from getting focus
    event.preventDefault();
    // if there is a mousedown handler passed in, call it
    onMouseDown && onMouseDown();
  };

  const handleOnFocus = event => {
    // if the button is in focus, we want the focus border to show
    setHasFocus(true);
    // if there is an onFocus handler passed in, call it
    onFocus && onFocus();
  };

  const handleOnBlur = event => {
    setHasFocus(false);

    onBlur && onBlur();
  };
  return {
    handleOnMouseDown,
    handleOnFocus,
    handleOnBlur,
    hasFocus,
    t
  };
}
