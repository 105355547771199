/**
 * @flow
 * @relayHash b034d10747a05117ef86b8c8c8b25165
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OneSourceDocumentInputType = {
  oneSourceDocumentId?: ?string,
  catalogNumber?: ?string,
  manufacturerName?: ?string,
  manufacturerId?: ?string,
  oneSourceUpdated?: ?string,
  revisionInformation?: ?string,
  name?: ?string,
};
export type SaveDocumentMutationVariables = {|
  documentSaveId?: ?number,
  tagInput?: ?$ReadOnlyArray<?string>,
  effectiveDate?: ?any,
  effectiveDateTimedelta?: ?number,
  effectiveDateAlgorithm?: ?string,
  name?: ?string,
  html?: ?string,
  author?: ?number,
  category?: ?number,
  bloc?: ?number,
  flowTemplate?: ?number,
  activeLifespan?: ?number,
  expirationDate?: ?any,
  restricted?: ?boolean,
  isAForm?: ?boolean,
  regulations?: ?$ReadOnlyArray<?number>,
  onesourceDocuments?: ?$ReadOnlyArray<?OneSourceDocumentInputType>,
  locations?: ?$ReadOnlyArray<?number>,
|};
export type SaveDocumentMutationResponse = {|
  +saveDraft: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +documentSaveId: ?number,
    +saveCommentUrl: ?string,
    +draftViewUrl: ?string,
    +documentSave: ?{|
      +pk: ?number,
      +savedCopy: ?{|
        +pk: ?number
      |},
    |},
  |}
|};
export type SaveDocumentMutation = {|
  variables: SaveDocumentMutationVariables,
  response: SaveDocumentMutationResponse,
|};
*/


/*
mutation SaveDocumentMutation(
  $documentSaveId: Int
  $tagInput: [String]
  $effectiveDate: PStatDate
  $effectiveDateTimedelta: Int
  $effectiveDateAlgorithm: String
  $name: String
  $html: String
  $author: Int
  $category: Int
  $bloc: Int
  $flowTemplate: Int
  $activeLifespan: Int
  $expirationDate: PStatDate
  $restricted: Boolean
  $isAForm: Boolean
  $regulations: [Int]
  $onesourceDocuments: [OneSourceDocumentInputType]
  $locations: [Int]
) {
  saveDraft(documentSaveId: $documentSaveId, tagInput: $tagInput, effectiveDate: $effectiveDate, effectiveDateTimedelta: $effectiveDateTimedelta, effectiveDateAlgorithm: $effectiveDateAlgorithm, name: $name, html: $html, author: $author, category: $category, bloc: $bloc, flowTemplate: $flowTemplate, activeLifespan: $activeLifespan, expirationDate: $expirationDate, restricted: $restricted, isAForm: $isAForm, regulations: $regulations, onesourceDocuments: $onesourceDocuments, locations: $locations) {
    ok
    reasons
    documentSaveId
    saveCommentUrl
    draftViewUrl
    documentSave {
      pk
      savedCopy {
        pk
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentSaveId",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tagInput",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDateTimedelta",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDateAlgorithm",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "html",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "author",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bloc",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flowTemplate",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "activeLifespan",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expirationDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "restricted",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isAForm",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "regulations",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "onesourceDocuments",
    "type": "[OneSourceDocumentInputType]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "locations",
    "type": "[Int]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "activeLifespan",
    "variableName": "activeLifespan",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "author",
    "variableName": "author",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "bloc",
    "variableName": "bloc",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "documentSaveId",
    "variableName": "documentSaveId",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "effectiveDate",
    "variableName": "effectiveDate",
    "type": "PStatDate"
  },
  {
    "kind": "Variable",
    "name": "effectiveDateAlgorithm",
    "variableName": "effectiveDateAlgorithm",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "effectiveDateTimedelta",
    "variableName": "effectiveDateTimedelta",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "expirationDate",
    "variableName": "expirationDate",
    "type": "PStatDate"
  },
  {
    "kind": "Variable",
    "name": "flowTemplate",
    "variableName": "flowTemplate",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "html",
    "variableName": "html",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "isAForm",
    "variableName": "isAForm",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "locations",
    "variableName": "locations",
    "type": "[Int]"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "onesourceDocuments",
    "variableName": "onesourceDocuments",
    "type": "[OneSourceDocumentInputType]"
  },
  {
    "kind": "Variable",
    "name": "regulations",
    "variableName": "regulations",
    "type": "[Int]"
  },
  {
    "kind": "Variable",
    "name": "restricted",
    "variableName": "restricted",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "tagInput",
    "variableName": "tagInput",
    "type": "[String]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reasons",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "documentSaveId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saveCommentUrl",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "draftViewUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SaveDocumentMutation",
  "id": "aca3d7f64fbe170ca67c8c9524ba9c7e",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SaveDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveDraft",
        "storageKey": null,
        "args": v1,
        "concreteType": "SaveDraft",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSave",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSaveType",
            "plural": false,
            "selections": [
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "savedCopy",
                "storageKey": null,
                "args": null,
                "concreteType": "RLDatixDocumentType",
                "plural": false,
                "selections": [
                  v7
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SaveDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "saveDraft",
        "storageKey": null,
        "args": v1,
        "concreteType": "SaveDraft",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSave",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSaveType",
            "plural": false,
            "selections": [
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "savedCopy",
                "storageKey": null,
                "args": null,
                "concreteType": "RLDatixDocumentType",
                "plural": false,
                "selections": [
                  v7,
                  v8
                ]
              },
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation SaveDocumentMutation(\n  $documentSaveId: Int\n  $tagInput: [String]\n  $effectiveDate: PStatDate\n  $effectiveDateTimedelta: Int\n  $effectiveDateAlgorithm: String\n  $name: String\n  $html: String\n  $author: Int\n  $category: Int\n  $bloc: Int\n  $flowTemplate: Int\n  $activeLifespan: Int\n  $expirationDate: PStatDate\n  $restricted: Boolean\n  $isAForm: Boolean\n  $regulations: [Int]\n  $onesourceDocuments: [OneSourceDocumentInputType]\n  $locations: [Int]\n) {\n  saveDraft(documentSaveId: $documentSaveId, tagInput: $tagInput, effectiveDate: $effectiveDate, effectiveDateTimedelta: $effectiveDateTimedelta, effectiveDateAlgorithm: $effectiveDateAlgorithm, name: $name, html: $html, author: $author, category: $category, bloc: $bloc, flowTemplate: $flowTemplate, activeLifespan: $activeLifespan, expirationDate: $expirationDate, restricted: $restricted, isAForm: $isAForm, regulations: $regulations, onesourceDocuments: $onesourceDocuments, locations: $locations) {\n    ok\n    reasons\n    documentSaveId\n    saveCommentUrl\n    draftViewUrl\n    documentSave {\n      pk\n      savedCopy {\n        pk\n        id\n      }\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '97c2a230c01536b75970e9522e9386b8';
module.exports = node;
