/**
 * @flow
 * @relayHash 0109ca1f1195827dfafacdd0c51e9005
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadMSDocumentMutationInput = {
  objectId: number,
  documentFile: any,
  html: string,
  isDocument?: ?boolean,
  isImport?: ?boolean,
  clientMutationId?: ?string,
};
export type uploadMSWordMutationVariables = {|
  input: UploadMSDocumentMutationInput
|};
export type uploadMSWordMutationResponse = {|
  +uploadMsword: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +taskId: ?string,
  |}
|};
export type uploadMSWordMutation = {|
  variables: uploadMSWordMutationVariables,
  response: uploadMSWordMutationResponse,
|};
*/


/*
mutation uploadMSWordMutation(
  $input: UploadMSDocumentMutationInput!
) {
  uploadMsword(input: $input) {
    ok
    reasons
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UploadMSDocumentMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "uploadMsword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UploadMSDocumentMutationInput!"
      }
    ],
    "concreteType": "UploadMSDocumentMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "uploadMSWordMutation",
  "id": "77f0d5996cf845ddf6c8a1cf9f8380a8",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "uploadMSWordMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "uploadMSWordMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation uploadMSWordMutation(\n  $input: UploadMSDocumentMutationInput!\n) {\n  uploadMsword(input: $input) {\n    ok\n    reasons\n    taskId\n  }\n}\n";
(node/*: any*/).hash = 'a1f1d63876ddf562f9f3d08f5da2faf4';
module.exports = node;
