import { Box, Flex } from "grid-styled";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { QueryRenderer } from "react-relay";
import system from "system-components";

import PolicyProperties, {
  NUM_ITEMS_TO_DISPLAY_IN_HEADER
} from "../../view_policy/policy_properties/PolicyProperties";
import StatusBar from "../../view_policy/StatusBar";
import { isOverridePage } from "../utils";

import PreviewQuery from "./PreviewQuery";

import ReferencesPanel from "pstat-anywhere/components/document_control/view_policy/ReferencesPanel";
import ShowChanges from "pstat-anywhere/components/document_control/view_policy/share/ShowChanges";
import { ContentContainer } from "pstat-anywhere/components/pages";
import { TopNav } from "pstat-anywhere/components/partials/tertiary_nav";
import AttachmentsPanel from "pstat-anywhere/components/view/Attachments";
import PolicyContent from "pstat-anywhere/components/view/content/Content";
import { EnvironmentContext } from "pstat-anywhere/context_providers/EnvironmentContext";
import { useTenant } from "pstat-anywhere/context_providers/TenantContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import { FullPageModal } from "pstat-design-system/modal/StyledReactModal";
import { Text } from "pstat-design-system/text";



const FullHeightFlex = system({
  is: Flex,
  height: "100%"
});

export const PreviewContent = ({
  document,
  documentToPrintAndShare,
  showChangesTaskId,
  nameChanges
}) => {
  const [showChanges, setShowChanges] = useState(false);
  const showChangesTaskResult = useTaskResult(showChangesTaskId);
  const { loading, error, result } = showChangesTaskResult;
  const { t } = useTranslation();
  const tenant = useTenant();
  return (
    <Fragment>
      {showChangesTaskId && (
        <TopNav>
          <Box flex={1} height="100%">
            <FullHeightFlex alignItems="center" justifyContent="flex-end">
              <ShowChanges
                showChanges={showChanges}
                title={t("documentControl.preview.showChanges")}
                toggleShowChanges={() => setShowChanges(!showChanges)}
                showChangesTaskResult={showChangesTaskResult}
              />
            </FullHeightFlex>
          </Box>
        </TopNav>
      )}
      <ContentContainer>
        <StatusBar
          document={document}
          isPreview={true}
          showPrintAndShare={!!documentToPrintAndShare}
          documentToPrintAndShare={documentToPrintAndShare}
          displayChangesDiff={showChanges}
        />
        <Media query={{ minWidth: theme.breakpoints[1] }}>
          {matches =>
            matches && (
              <PolicyProperties
                approvalDate={document.approvalDate}
                effectiveDate={document.effectiveDate}
                revisionDate={document.revisionDate}
                expirationDate={document.expirationDate}
                originationDate={document.originationDate}
                author={document.author}
                category={document.category}
                bloc={document.bloc}
                tags={document.tags}
                headerImageUrl={document.headerImageUrl}
                isPreview={true}
              />
            )
          }
        </Media>
        {showChanges && !loading && !error && result ? (
          <PolicyContent nameHtml={nameChanges} html={result.diff} />
        ) : (
          <PolicyContent name={document.name} html={document.html} />
        )}
        {document.attachments.length > 0 && (
          <AttachmentsPanel attachments={document.attachments} />
        )}
        {tenant.documentSettings?.referencesShow &&
          document.tags &&
          document.tags.length > NUM_ITEMS_TO_DISPLAY_IN_HEADER && (
            <Box width={1} px={[0, 0, 3]}>
              <ReferencesPanel tags={document.tags} />
            </Box>
          )}
      </ContentContainer>
    </Fragment>
  );
};

const Preview = ({
  isOpen,
  type,
  document,
  documentSave,
  policyForm,
  effectiveDate,
  effectiveDateTimedelta,
  effectiveDateAlgorithm,
  onClose,
  documentSettings
}) => {
  const {
    name,
    html,
    author,
    category,
    bloc,
    flowTemplate,
    activeLifespan,
    expirationDate,
    restricted
  } = policyForm;
  const variables = {
    name,
    html,
    author,
    category,
    bloc,
    flowTemplate,
    activeLifespan,
    expirationDate,
    restricted,
    effectiveDate,
    effectiveDateTimedelta,
    effectiveDateAlgorithm,
    documentSettings
  };
  if (document) {
    variables["hasParent"] = true;
  }
  if (!isOverridePage(type)) {
    variables["documentSavePk"] = documentSave.pk;
  } else {
    variables["documentPk"] = document.pk;
  }
  const environment = useContext(EnvironmentContext);
  const { t } = useTranslation();
  return (
    <FullPageModal
      id="preview_modal"
      contentLabel={t("documentControl.preview.heading")}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <QueryRenderer
        environment={environment}
        query={PreviewQuery}
        variables={variables}
        render={({ props, error }) => {
          if (props && props.documentPreview) {
            const {
              documentEditing,
              showChangesTaskId,
              nameChanges,
              ...otherProps
            } = props.documentPreview;
            const documentProps = {
              ...otherProps,
              tags: [],
              pk: t("documentControl.documentStatus.previewStatus"),
              status: t("documentControl.documentStatus.previewStatus")
            };
            return (
              <PreviewContent
                document={documentProps}
                documentToPrintAndShare={documentEditing}
                showChangesTaskId={showChangesTaskId}
                nameChanges={nameChanges}
              />
            );
          } else {
            return <Text>{t("loading")}</Text>;
          }
        }}
      />
    </FullPageModal>
  );
};

export default Preview;
