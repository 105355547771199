import graphql from "babel-plugin-relay/macro";

const ApprovalWorkflowByActionQuery = graphql`
  query ApprovalWorkflowByActionQuery($actionId: Int!) {
    approvalFlowByAction(actionId: $actionId) {
      approvalFlow {
        pk
        name
        currentStep {
          id
        }
        steps {
          id
          name
          approvers {
            firstName
            lastName
            isDocumentOwner
            hasApproved
          }
        }
      }
    }
  }
`;

export default ApprovalWorkflowByActionQuery;
