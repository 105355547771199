/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Approvable = "NO" | "OPTIONAL" | "REQUIRED" | "%future added value";
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SecondaryNav_document$ref: FragmentReference;
export type SecondaryNav_document = {|
  +id: string,
  +pk: ?number,
  +name: string,
  +status: ?DocumentStatus,
  +approvable: Approvable,
  +rejectable: ?boolean,
  +shouldBeAcknowledged: ?boolean,
  +$refType: SecondaryNav_document$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SecondaryNav_document",
  "type": "DocumentType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pk",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "approvable",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rejectable",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shouldBeAcknowledged",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '6ca3b7510449fc52e267db29cc53ffa0';
module.exports = node;
