import { Route } from "found";
import React from "react";

import LippincottSearchPage from "./lippincott_search/LippincottSearchPage";
import { LippincottSearchQuery } from "./lippincott_search/LippincottSearchQuery";


const routes = (
  <Route path="integrations">
    <Route
      path="lippincott/search"
      query={LippincottSearchQuery}
      data={{
        routeName: "lippincott_search"
      }}
      Component={LippincottSearchPage}
      prepareVariables={(params, { location }) => ({
        ...params,
        showAll: location.query.showAll,
        q: location.query.q
      })}
    />
  </Route>
);

export default routes;
