import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation LogoutMutation($lo: String!) {
    logout(lo: $lo) {
      ok
      reasons
    }
  }
`;

export const doLogout = callback => {
  const variables = {
    lo: "lo"
  };

  // TODO decide how to handle errors
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (response.logout.ok) {
        callback();
      }
    },
    onError: err => console.error(err)
  });
};
