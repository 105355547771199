import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import { ExpirationFilterDropdown } from "pstat-anywhere/components/document_reports/expiring/components/ExpirationFilterDropdown";
import { WaitingSinceFilterDropdown } from "pstat-anywhere/components/document_reports/expiring/components/WaitingSinceFilterDropdown";
import {
  CheckboxFilterBox,
  FilterDropdown,
  SearchBar
} from "pstat-anywhere/components/pages/reports/components";
import Checkbox, {
  ALL_SELECTED,
  CheckboxGroup,
  NONE_SELECTED,
  SELECT_ALL_CHECKBOX_VALUE,
  SOME_SELECTED,
  SelectAllCheckbox
} from "pstat-design-system/inputs/Checkbox";
import { Button } from "pstat-design-system/shared/Button";

const CategoriesContainer = system({
  maxHeight: 200,
  width: "100%"
}).extend`
  overflow: auto;
`;

export const SearchBarAndFilters = ({
  location,
  router,
  categories,
  waitingSinceFilter
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(
    location.query.search_query || ""
  );
  const [categoriesExpanded, setCategoriesExpanded] = useState(false);
  const [filterDaysExpanded, setFilterDaysExpanded] = useState(false);
  const [filterDays, setFilterDays] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [
    categoriesSelectAllCheckboxState,
    setCategoriesSelectAllCheckboxState
  ] = useState(NONE_SELECTED);
  const [includePending, setIncludePending] = useState(false);

  useEffect(() => {
    const queryParams = { ...location.query };
    if (searchQuery) {
      queryParams.search_query = searchQuery;
    } else {
      delete queryParams.search_query;
    }

    if (!categoriesExpanded) {
      if (selectedCategories) {
        queryParams.categories = selectedCategories;
      } else {
        delete queryParams.categories;
      }
    }

    if (!filterDaysExpanded) {
      if (filterDays) {
        if (waitingSinceFilter) {
          queryParams.days_waiting_since = filterDays;
        } else {
          queryParams.days_until_expiration = filterDays;
        }
      } else {
        delete queryParams.days_until_expiration;
        delete queryParams.days_waiting_since;
      }
    }

    if (includePending) {
      queryParams.include_pending = includePending;
    } else {
      delete queryParams.include_pending;
    }

    const to = {
      pathname: location.pathname,
      query: queryParams
    };
    router.push(to);
  }, [categoriesExpanded, filterDaysExpanded, includePending, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectCategory = value => {
    const updatedSelectedCategories = getSelectedCategories(value);
    updateSelectAllCheckboxState(updatedSelectedCategories);
    setSelectedCategories(updatedSelectedCategories);
  };

  const getSelectedCategories = value => {
    if (value === SELECT_ALL_CHECKBOX_VALUE) {
      if (selectedCategories.length === categories.length) {
        return [];
      }
      return categories.map(category => category.pk);
    }
    if (selectedCategories.includes(value)) {
      return selectedCategories.filter(item => item !== value);
    }
    return [...selectedCategories, value];
  };

  const updateSelectAllCheckboxState = selectedCategories => {
    if (selectedCategories.length === categories.length) {
      setCategoriesSelectAllCheckboxState(ALL_SELECTED);
    } else if (selectedCategories.length === 0) {
      setCategoriesSelectAllCheckboxState(NONE_SELECTED);
    } else {
      setCategoriesSelectAllCheckboxState(SOME_SELECTED);
    }
  };

  const handleClearSelectedCategories = () => {
    setSelectedCategories([]);
    setCategoriesSelectAllCheckboxState(NONE_SELECTED);
  };
  const handleClearFilterDays = () => setFilterDays(0);
  const handleincludePending = () => setIncludePending(!includePending);

  const categoriesCheckboxes = useMemo(
    () =>
      (categories || []).map(({ id, pk, name }) => (
        <Checkbox key={id} value={pk} label={name} />
      )),
    [categories, selectedCategories] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFilterDays = event => setFilterDays(event.target.value);

  const onToggleDaysFilter = () => {
    setFilterDaysExpanded(!filterDaysExpanded);
    setCategoriesExpanded(false);
  };

  const onCloseDaysFilter = () => setFilterDaysExpanded(false);
  const FilterDaysComponent = waitingSinceFilter
    ? WaitingSinceFilterDropdown
    : ExpirationFilterDropdown;

  return (
    <Fragment>
      <SearchBar
        placeholder={t("documentReports.expiring.searchFilterPlaceholder")}
        initialValue={searchQuery}
        onChange={setSearchQuery}
      >
        <CheckboxFilterBox
          name={t("documentReports.expiring.inApprovalProcessTitle")}
          onChange={handleincludePending}
          checked={includePending}
          tooltipText={t("documentReports.expiring.inApprovalProcessTooltip")}
        />
        <FilterDropdown
          expanded={categoriesExpanded}
          onClose={() => setCategoriesExpanded(false)}
          onToggle={() => {
            setCategoriesExpanded(!categoriesExpanded);
            setFilterDaysExpanded(false);
          }}
          filterName={t("documentReports.expiring.areaFilterDropdown.title")}
          expandedFilterName={t(
            "documentReports.expiring.areaFilterDropdown.expandedTitle"
          )}
        >
          <CategoriesContainer>
            <CheckboxGroup
              onChange={handleSelectCategory}
              selectedValues={selectedCategories}
              selectAllCheckboxState={categoriesSelectAllCheckboxState}
            >
              <SelectAllCheckbox
                id="categories-select-all-checkbox"
                ariaLabel={t(
                  "documentSearch.searchFilters.filtersAccordion.selectAll"
                )}
                label={t(
                  "documentSearch.searchFilters.filtersAccordion.selectAll"
                )}
              />
              {categoriesCheckboxes}
            </CheckboxGroup>
          </CategoriesContainer>
          <Button
            width="100%"
            style={{ marginTop: "10px" }}
            buttonStyle="tertiary"
            onClick={handleClearSelectedCategories}
          >
            {t("documentReports.expiring.clearButton.title")}
          </Button>
        </FilterDropdown>

        <FilterDaysComponent
          onChange={handleFilterDays}
          onClear={handleClearFilterDays}
          value={filterDays}
          onToggle={onToggleDaysFilter}
          onClose={onCloseDaysFilter}
          expanded={filterDaysExpanded}
        />
      </SearchBar>
    </Fragment>
  );
};
