/**
 * @flow
 * @relayHash 05d66bf355b6a444dcafef65909f53ea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LippincottSearchQueryVariables = {|
  q?: ?string,
  showAll?: ?boolean,
|};
export type LippincottSearchQueryResponse = {|
  +lippincottSearch: ?{|
    +taskId: ?string
  |}
|};
export type LippincottSearchQuery = {|
  variables: LippincottSearchQueryVariables,
  response: LippincottSearchQueryResponse,
|};
*/


/*
query LippincottSearchQuery(
  $q: String
  $showAll: Boolean
) {
  lippincottSearch(q: $q, showAll: $showAll) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "q",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "lippincottSearch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "q",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "showAll",
        "variableName": "showAll",
        "type": "Boolean"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LippincottSearchQuery",
  "id": "be0d87c9a84f5c6afe4355fbf105c768",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LippincottSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "LippincottSearchQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query LippincottSearchQuery(\n  $q: String\n  $showAll: Boolean\n) {\n  lippincottSearch(q: $q, showAll: $showAll) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = 'c89d25475a242cc1298bec7f44aa3b4c';
module.exports = node;
