import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation deleteDocumentAttachmentMutation($attachmentPk: Int!) {
    deleteDocumentAttachment(attachmentPk: $attachmentPk) {
      ok
    }
  }
`;

const deleteDocumentAttachment = (environment, variables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default deleteDocumentAttachment;
