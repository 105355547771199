/**
 * @flow
 * @relayHash 0457310b4e33160f376fcae2903f9ed7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovalWorkflowByActionQueryVariables = {|
  actionId: number
|};
export type ApprovalWorkflowByActionQueryResponse = {|
  +approvalFlowByAction: ?{|
    +approvalFlow: ?{|
      +pk: ?number,
      +name: ?string,
      +currentStep: ?{|
        +id: string
      |},
      +steps: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +approvers: ?$ReadOnlyArray<?{|
          +firstName: string,
          +lastName: string,
          +isDocumentOwner: ?boolean,
          +hasApproved: ?boolean,
        |}>,
      |}>,
    |}
  |}
|};
export type ApprovalWorkflowByActionQuery = {|
  variables: ApprovalWorkflowByActionQueryVariables,
  response: ApprovalWorkflowByActionQueryResponse,
|};
*/


/*
query ApprovalWorkflowByActionQuery(
  $actionId: Int!
) {
  approvalFlowByAction(actionId: $actionId) {
    approvalFlow {
      pk
      name
      currentStep {
        id
      }
      steps {
        id
        name
        approvers {
          firstName
          lastName
          isDocumentOwner
          hasApproved
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "actionId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "actionId",
    "variableName": "actionId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "currentStep",
  "storageKey": null,
  "args": null,
  "concreteType": "ApprovalStepType",
  "plural": false,
  "selections": [
    v4
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDocumentOwner",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasApproved",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ApprovalWorkflowByActionQuery",
  "id": "7bef1d6bf473870c75e54bf60b4e5c9c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ApprovalWorkflowByActionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvalFlowByAction",
        "storageKey": null,
        "args": v1,
        "concreteType": "ApprovalWorkflowViewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvalFlow",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalWorkflowType",
            "plural": false,
            "selections": [
              v2,
              v3,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "steps",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalStepType",
                "plural": true,
                "selections": [
                  v4,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvers",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalStepUserType",
                    "plural": true,
                    "selections": [
                      v6,
                      v7,
                      v8,
                      v9
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApprovalWorkflowByActionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvalFlowByAction",
        "storageKey": null,
        "args": v1,
        "concreteType": "ApprovalWorkflowViewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvalFlow",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalWorkflowType",
            "plural": false,
            "selections": [
              v2,
              v3,
              v5,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "steps",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalStepType",
                "plural": true,
                "selections": [
                  v4,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvers",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalStepUserType",
                    "plural": true,
                    "selections": [
                      v6,
                      v7,
                      v8,
                      v9,
                      v4
                    ]
                  }
                ]
              },
              v4
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query ApprovalWorkflowByActionQuery(\n  $actionId: Int!\n) {\n  approvalFlowByAction(actionId: $actionId) {\n    approvalFlow {\n      pk\n      name\n      currentStep {\n        id\n      }\n      steps {\n        id\n        name\n        approvers {\n          firstName\n          lastName\n          isDocumentOwner\n          hasApproved\n          id\n        }\n      }\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '5d072226e69f1fa260081b89844d84e6';
module.exports = node;
