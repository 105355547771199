/**
 * @flow
 * @relayHash 3700e8009b1a6565e9c38456ff9e2a93
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RejectPendingDocumentMutationVariables = {|
  documentPk: number,
  comment: string,
|};
export type RejectPendingDocumentMutationResponse = {|
  +rejectPendingDocument: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +document: ?{|
      +pk: ?number
    |},
  |}
|};
export type RejectPendingDocumentMutation = {|
  variables: RejectPendingDocumentMutationVariables,
  response: RejectPendingDocumentMutationResponse,
|};
*/


/*
mutation RejectPendingDocumentMutation(
  $documentPk: Int!
  $comment: String!
) {
  rejectPendingDocument(documentPk: $documentPk, comment: $comment) {
    ok
    reasons
    document {
      pk
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "comment",
    "variableName": "comment",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "documentPk",
    "variableName": "documentPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reasons",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RejectPendingDocumentMutation",
  "id": "a3190f8f1ee23fde699f68fa57f98575",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RejectPendingDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "rejectPendingDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "RejectPendingDocumentMutation",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RejectPendingDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "rejectPendingDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "RejectPendingDocumentMutation",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation RejectPendingDocumentMutation(\n  $documentPk: Int!\n  $comment: String!\n) {\n  rejectPendingDocument(documentPk: $documentPk, comment: $comment) {\n    ok\n    reasons\n    document {\n      pk\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '636d5fa028c50eae12c3e67e3c895835';
module.exports = node;
