/**
 * @flow
 * @relayHash d62a88a21fa4f020760a515b518b3bf4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type LocalDocumentsForTemplateQueryVariables = {|
  pk: number,
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type LocalDocumentsForTemplateQueryResponse = {|
  +blocSystemDocumentTemplatesDocuments: ?{|
    +blocSystemDocumentTemplates: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +systemDocumentTemplate: {|
            +pk: ?number,
            +name: ?string,
          |},
          +bloc: {|
            +tenants: ?$ReadOnlyArray<?{|
              +name: string
            |}>
          |},
          +publishDate: ?string,
          +document: ?{|
            +pk: ?number,
            +name: string,
            +status: ?DocumentStatus,
            +category: {|
              +name: string
            |},
            +author: {|
              +pk: ?number,
              +firstName: string,
              +lastName: string,
              +title: ?string,
              +email: string,
            |},
            +effectiveDate: ?string,
            +link: ?string,
          |},
        |}
      |}>,
    |},
    +systemDocumentTemplateName: ?string,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +unpublishedDocumentsCount: ?number,
  |}
|};
export type LocalDocumentsForTemplateQuery = {|
  variables: LocalDocumentsForTemplateQueryVariables,
  response: LocalDocumentsForTemplateQueryResponse,
|};
*/


/*
query LocalDocumentsForTemplateQuery(
  $pk: Int!
  $after: String
  $first: Int
  $sort: String
) {
  blocSystemDocumentTemplatesDocuments(pk: $pk, sort: $sort) {
    blocSystemDocumentTemplates(after: $after, first: $first) {
      totalCount
      edges {
        node {
          systemDocumentTemplate {
            pk
            name
            id
          }
          bloc {
            tenants {
              name
              id
            }
            id
          }
          publishDate
          document {
            pk
            name
            status
            category {
              name
              id
            }
            author {
              pk
              firstName
              lastName
              title
              email
              id
            }
            effectiveDate
            link(fullUrl: true)
            id
          }
          id
        }
      }
    }
    systemDocumentTemplateName
    error {
      statusCode
      message
    }
    unpublishedDocumentsCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk",
    "type": "Int!"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  v5
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": [
    {
      "kind": "Literal",
      "name": "fullUrl",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "link(fullUrl:true)"
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "systemDocumentTemplateName",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unpublishedDocumentsCount",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v19 = [
  v5,
  v18
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "LocalDocumentsForTemplateQuery",
  "id": "1c6f2fcffa3de1c1ad78eb614d569cad",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "LocalDocumentsForTemplateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocSystemDocumentTemplatesDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocSystemDocumentTemplateListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocSystemDocumentTemplates",
            "storageKey": null,
            "args": v2,
            "concreteType": "BlocSystemDocumentTemplateCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BlocSystemDocumentTemplateCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BlocSystemDocumentTemplateType",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "systemDocumentTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SystemDocumentTemplateType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "tenants",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantType",
                            "plural": true,
                            "selections": v6
                          }
                        ]
                      },
                      v7,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryType",
                            "plural": false,
                            "selections": v6
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "author",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserType",
                            "plural": false,
                            "selections": [
                              v4,
                              v9,
                              v10,
                              v11,
                              v12
                            ]
                          },
                          v13,
                          v14
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v15,
          v16,
          v17
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "LocalDocumentsForTemplateQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocSystemDocumentTemplatesDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocSystemDocumentTemplateListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocSystemDocumentTemplates",
            "storageKey": null,
            "args": v2,
            "concreteType": "BlocSystemDocumentTemplateCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BlocSystemDocumentTemplateCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BlocSystemDocumentTemplateType",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "systemDocumentTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SystemDocumentTemplateType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v18
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "tenants",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantType",
                            "plural": true,
                            "selections": v19
                          },
                          v18
                        ]
                      },
                      v7,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryType",
                            "plural": false,
                            "selections": v19
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "author",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserType",
                            "plural": false,
                            "selections": [
                              v4,
                              v9,
                              v10,
                              v11,
                              v12,
                              v18
                            ]
                          },
                          v13,
                          v14,
                          v18
                        ]
                      },
                      v18
                    ]
                  }
                ]
              }
            ]
          },
          v15,
          v16,
          v17
        ]
      }
    ]
  }
};
})();
node.text = "query LocalDocumentsForTemplateQuery(\n  $pk: Int!\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  blocSystemDocumentTemplatesDocuments(pk: $pk, sort: $sort) {\n    blocSystemDocumentTemplates(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          systemDocumentTemplate {\n            pk\n            name\n            id\n          }\n          bloc {\n            tenants {\n              name\n              id\n            }\n            id\n          }\n          publishDate\n          document {\n            pk\n            name\n            status\n            category {\n              name\n              id\n            }\n            author {\n              pk\n              firstName\n              lastName\n              title\n              email\n              id\n            }\n            effectiveDate\n            link(fullUrl: true)\n            id\n          }\n          id\n        }\n      }\n    }\n    systemDocumentTemplateName\n    error {\n      statusCode\n      message\n    }\n    unpublishedDocumentsCount\n  }\n}\n";
(node/*: any*/).hash = '4a7025de57db728f876b9add84dd9980';
module.exports = node;
