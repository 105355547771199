import { Box, Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "./ConfirmationModal";
import usePolicyAction from "./hooks/usePolicyAction";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { LabelField } from "pstat-design-system/inputs/Labels";
import Textarea from "pstat-design-system/inputs/Textarea";
import {
  createErrorMessage,
  createRejectSuccessMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import Panel from "pstat-design-system/Panel";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


function _PolicyCommentSection({
  documentPk,
  documentName,
  policyActionMutation,
  translationsKey,
  labels,
  location,
  router,
  extraInformation,
  isConfirmationRequired = true,
  isCommentsRequired = true,
  eventBuilder,
  extraButton,
  isReject = false
}) {
  function policyActionCallback(response, errors) {
    if (errors) {
      throw new Error();
    }
    const { reasons, ok, redirectPath } = response[
      `${translationsKey}Document`
    ];
    if (!ok) {
      Object.keys(reasons).forEach(reasonKey =>
        createErrorMessage(reasons[reasonKey])
      );
    } else {
      const createMessage =  isReject ? createRejectSuccessMessage : createSuccessMessage;
      createMessage(
        t(`documentControl.${translationsKey}.successMessage`, {
          documentLabel: titleCase(labels.documentLabel)
        })
      );
      if (redirectPath) {
        location.pathname = redirectPath;
      } else {
        location.pathname = getRootUrl() || "/";
      }
      router.replace(location);
    }
  }
  const { t } = useTranslation();
  const {
    actionProcessing,
    comment,
    handleSubmit,
    handleCommentChange
  } = usePolicyAction({
    documentPk,
    policyActionMutation,
    policyActionCallback,
    eventBuilder,
    isCommentsRequired
  });
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const dangerStyles = ["delete", "rejectImport"];

  return (
    <Panel
      id={translationsKey}
      name={t(`documentControl.${translationsKey}.panel.name`, {
        documentName: documentName,
        documentLabel: titleCase(labels.documentLabel)
      })}
      wrapperProps={{ mb: 0, pb: 1 }}
    >
      {extraInformation && (
        <Flex p={1} pb={5} flexWrap="wrap">
          {extraInformation}
        </Flex>
      )}
      {isCommentsRequired && (
        <Flex p={1} flexWrap="wrap">
          <LabelField
            name={t(`documentControl.${translationsKey}.commentForm.label`)}
            required={true}
            htmlFor="action_comment_textarea"
          />
          <Textarea
            id="action_comment_textarea"
            rows={4}
            onChange={handleCommentChange}
            value={comment}
            width="100%"
            borderRadius={theme.radii[1]}
            mb={1}
          />
        </Flex>
      )}
      <Flex
        p={1}
        justifyContent="space-between"
        alignItems="baseline"
        flexWrap="wrap"
      >
        <Box>
          <Button
            id="submit-action-button"
            buttonStyle={
              dangerStyles.includes(translationsKey) ? "danger" : "secondary"
            }
            onClick={() =>
              isConfirmationRequired
                ? setConfirmationModalIsOpen(true)
                : handleSubmit()
            }
            disabled={comment === "" && isCommentsRequired}
            px={75}
          >
            {t(`documentControl.${translationsKey}.commentForm.submitButton`, {
              documentLabel: titleCase(labels.documentLabel)
            })}
          </Button>
          {extraButton}
        </Box>
        {translationsKey === "delete" && (
          <Box>
            <StyledFontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              color="nav.0"
            />
            <Text color="nav.0">
              {t(
                `documentControl.${translationsKey}.commentForm.actionNotReversible`
              )}
            </Text>
          </Box>
        )}
      </Flex>
      <ConfirmationModal
        isOpen={confirmationModalIsOpen}
        onSubmit={handleSubmit}
        onClose={() => setConfirmationModalIsOpen(false)}
        title={t(`documentControl.${translationsKey}.confirmationModal.title`, {
          documentLabel: titleCase(labels.documentLabel)
        })}
        text={t(`documentControl.${translationsKey}.confirmationModal.text`, {
          documentLabel: labels.documentLabel.toLowerCase(),
          documentLabelPlural: labels.documentLabelPlural.toLowerCase()
        })}
        submitButtonText={t(
          `documentControl.${translationsKey}.confirmationModal.submitButton`
        )}
        processing={actionProcessing}
      />
    </Panel>
  );
}

const PolicyCommentSection = withLabelContext(_PolicyCommentSection);

export default PolicyCommentSection;
