import graphql from "babel-plugin-relay/macro";

const PreviewQuery = graphql`
  query PreviewQuery(
    $documentPk: Int
    $documentSavePk: Int
    $name: String
    $html: String
    $author: Int
    $category: Int
    $bloc: Int
    $flowTemplate: Int
    $activeLifespan: Int
    $expirationDate: PStatDate
    $legacyEffectiveDate: PStatDate
    $effectiveDate: PStatDate
    $effectiveDateTimedelta: Int
    $effectiveDateAlgorithm: String
    $approvalDate: PStatDate
    $restricted: Boolean
    $hasParent: Boolean
  ) {
    documentPreview(
      documentPk: $documentPk
      documentSavePk: $documentSavePk
      name: $name
      html: $html
      author: $author
      category: $category
      bloc: $bloc
      flowTemplate: $flowTemplate
      activeLifespan: $activeLifespan
      expirationDate: $expirationDate
      legacyEffectiveDate: $legacyEffectiveDate
      effectiveDate: $effectiveDate
      effectiveDateTimedelta: $effectiveDateTimedelta
      effectiveDateAlgorithm: $effectiveDateAlgorithm
      approvalDate: $approvalDate
      restricted: $restricted
      hasParent: $hasParent
    ) {
      name
      html
      author {
        pk
        userDisplay
        firstName
        lastName
        profile {
          title
        }
      }
      category {
        pk
        name
      }
      bloc {
        id
        name
        tenants {
          pk
          name
        }
      }
      restricted
      approvalDate
      expirationDate
      effectiveDate
      originationDate
      documentEditing {
        pk
      }
      attachments {
        title
        fileUrl
      }
      showChangesTaskId
      nameChanges
      headerImageUrl
    }
  }
`;

export default PreviewQuery;
