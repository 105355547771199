import { Box, Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import AuthorableTenantsQuery from "pstat-anywhere/components/document_control/view_policy/AuthorableTenantsQuery";
import { environment } from "pstat-anywhere/relay";
import { RadioGroupFormElement } from "pstat-design-system/inputs/FormElements";
import Radio from "pstat-design-system/inputs/Radio";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";

export const CloneModalContent = ({
  tenants,
  isOpen,
  onClose,
  onClone,
  isProcessing
}) => {
  const { t } = useTranslation();
  const [dstTenantPk, setDstTenantPk] = useState(null);
  return (
    <Modal
      contentLabel={t(
        "documentControl.view.secondaryNav.cloneDocument.modal.contentLabel"
      )}
      isOpen={isOpen}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                id="delete_draft_button"
                buttonStyle="tertiary"
                onClick={onClose}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
            <Box>
              <Button
                processing={isProcessing}
                disabled={!dstTenantPk}
                buttonStyle="secondary"
                onClick={() => onClone(dstTenantPk)}
                data-testid="clone-submit-button"
              >
                {t(
                  "documentControl.view.secondaryNav.cloneDocument.modal.button"
                )}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" flexWrap="wrap" pb={60} mx={1}>
        {tenants ? (
          <RadioGroupFormElement
            name="authorableTenants"
            onChange={selectedPk => setDstTenantPk(selectedPk)}
            selectedValue={dstTenantPk}
          >
            {tenants.map(tenant => (
              <Radio
                key={tenant.pk}
                label={tenant.name}
                value={tenant.pk}
                disabled={isProcessing}
              />
            ))}
          </RadioGroupFormElement>
        ) : (
          <Flex justify="center" align="center" my={10}>
            <Box>
              <LoadingSpinner size={100} />
            </Box>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

const ClonePolicyModal = props => {
  return (
    <QueryRenderer
      environment={environment}
      query={AuthorableTenantsQuery}
      render={data => {
        return (
          <CloneModalContent
            tenants={data?.props?.authorableTenants}
            {...props}
          />
        );
      }}
    />
  );
};

export default ClonePolicyModal;
