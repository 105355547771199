/**
 * @flow
 * @relayHash 481c49457004f013e70d8baa313d226c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type editAttachmentMutationVariables = {|
  attachmentPk: number,
  title: string,
  file?: ?any,
|};
export type editAttachmentMutationResponse = {|
  +editAttachment: ?{|
    +ok: ?boolean
  |}
|};
export type editAttachmentMutation = {|
  variables: editAttachmentMutationVariables,
  response: editAttachmentMutationResponse,
|};
*/


/*
mutation editAttachmentMutation(
  $attachmentPk: Int!
  $title: String!
  $file: Upload
) {
  editAttachment(attachmentPk: $attachmentPk, title: $title, attachmentFile: $file) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "attachmentPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "file",
    "type": "Upload",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "editAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attachmentFile",
        "variableName": "file",
        "type": "Upload"
      },
      {
        "kind": "Variable",
        "name": "attachmentPk",
        "variableName": "attachmentPk",
        "type": "Int!"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title",
        "type": "String!"
      }
    ],
    "concreteType": "EditAttachmentMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "editAttachmentMutation",
  "id": "8898fdc3fbbcf51c1f0a1ba4100d7355",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "editAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "editAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation editAttachmentMutation(\n  $attachmentPk: Int!\n  $title: String!\n  $file: Upload\n) {\n  editAttachment(attachmentPk: $attachmentPk, title: $title, attachmentFile: $file) {\n    ok\n  }\n}\n";
(node/*: any*/).hash = '45a727b3df58682087e6b8d3a413e2d1';
module.exports = node;
