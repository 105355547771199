import { useTranslation } from "react-i18next";

import ErrorBoundary from "./ErrorBoundary";

import { Content, PageContainer, SkipLink } from "./index";

import Footer from "pstat-design-system/Footer";

const PageWithoutNav = props => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <header>
        <nav aria-label={t("pages.pageWithNav.skipToContent")}>
          <SkipLink>{t("pages.pageWithNav.skipToContent")}</SkipLink>
        </nav>
      </header>
      <main>
        <Content id="content">
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Content>
      </main>
      <Footer />
    </PageContainer>
  );
};

export default PageWithoutNav;
