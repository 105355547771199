import system from "system-components";

import {
  activeButtonStyle,
  buttonStyle,
  hoverButtonStyle
} from "pstat-design-system/shared/Button";

// a link that looks like a button
const ButtonLink = system(
  {
    is: "a",
    py: 3,
    px: 4,
    m: 0,
    border: 1,
    borderRadius: 1,
    fontFamily: "font",
    fontSize: 2,
    fontWeight: "normal",
    height: "44px"
  },
  "buttonStyle",
  "display"
).extend`
  white-space: nowrap;
  text-decoration: none;

  ${buttonStyle}
  &:hover {
    ${hoverButtonStyle}
  }
  &:active {
    ${activeButtonStyle}
  }
`;

export default ButtonLink;
