/**
 * @flow
 * @relayHash 062212a5211534c909125ebcf09a0be8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EpStandardContentQueryVariables = {|
  associationPk: number
|};
export type EpStandardContentQueryResponse = {|
  +epContent: ?{|
    +data: ?{|
      +name: ?string,
      +description: ?string,
      +chapter: ?string,
      +body: ?string,
      +url: ?string,
      +printUrl: ?string,
      +standard: ?string,
      +validFromVersion: ?string,
      +associatedVersion: ?{|
        +name: ?string,
        +description: ?string,
        +chapter: ?string,
        +standard: ?string,
        +body: ?string,
        +url: ?string,
        +printUrl: ?string,
        +validFromVersion: ?string,
      |},
    |},
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type EpStandardContentQuery = {|
  variables: EpStandardContentQueryVariables,
  response: EpStandardContentQueryResponse,
|};
*/


/*
query EpStandardContentQuery(
  $associationPk: Int!
) {
  epContent(associationPk: $associationPk) {
    data {
      name
      description
      chapter
      body
      url
      printUrl
      standard
      validFromVersion
      associatedVersion {
        name
        description
        chapter
        standard
        body
        url
        printUrl
        validFromVersion
      }
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "associationPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "chapter",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "printUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "standard",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "validFromVersion",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "epContent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "associationPk",
        "variableName": "associationPk",
        "type": "Int!"
      }
    ],
    "concreteType": "SingleDocLineEpStandard",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "data",
        "storageKey": null,
        "args": null,
        "concreteType": "SingleDocLineEpStandardData",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "associatedVersion",
            "storageKey": null,
            "args": null,
            "concreteType": "SingleDocLineEpStandardData",
            "plural": false,
            "selections": [
              v1,
              v2,
              v3,
              v7,
              v4,
              v5,
              v6,
              v8
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EpStandardContentQuery",
  "id": "eb02f1a66ce6743b90b34178f6867444",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EpStandardContentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v9
  },
  "operation": {
    "kind": "Operation",
    "name": "EpStandardContentQuery",
    "argumentDefinitions": v0,
    "selections": v9
  }
};
})();
node.text = "query EpStandardContentQuery(\n  $associationPk: Int!\n) {\n  epContent(associationPk: $associationPk) {\n    data {\n      name\n      description\n      chapter\n      body\n      url\n      printUrl\n      standard\n      validFromVersion\n      associatedVersion {\n        name\n        description\n        chapter\n        standard\n        body\n        url\n        printUrl\n        validFromVersion\n      }\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = '49c5e03e39bbcfe8e2404e09a1b0d20b';
module.exports = node;
