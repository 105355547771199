import { Box, Flex } from "grid-styled";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";

import ApprovalWorkflowContent, {
  APPROVAL_WORKFLOW_CONTENT_VARIATIONS
} from "pstat-anywhere/components/document_control/approve_policy/ApprovalWorkflowContent";
import ApprovalWorkflowByActionQuery from "pstat-anywhere/components/document_control/view_policy/history/ApprovalWorkflowByActionQuery";
import { environment } from "pstat-anywhere/relay";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Modal from "pstat-design-system/modal";

const ViewActionApprovalWorkflow = ({ t, actionId, isOpen, onCloseModal }) => {
  const [flowData, setFlowData] = useState();
  // TODO: Custom hook for fetching data (remove fetching from a component)
  useEffect(() => {
    if (isOpen && !flowData) {
      fetchQuery(environment, ApprovalWorkflowByActionQuery, {
        actionId: actionId
      }).then(data => setFlowData(data.approvalFlowByAction.approvalFlow));
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const { steps, name, currentStep } = flowData || {};

  return (
    <Modal
      id="approval_workflow_modal"
      isOpen={isOpen}
      contentLabel={t("documentControl.approvalWorkflow.modal.contentLabel")}
      onRequestClose={onCloseModal}
    >
      {flowData ? (
        <ApprovalWorkflowContent
          title={name}
          steps={steps}
          variation={APPROVAL_WORKFLOW_CONTENT_VARIATIONS}
          inModal={true}
          currentStepId={currentStep.id}
        />
      ) : (
        <Flex
          justify="center"
          align="center"
          mt={10}
          data-testid="approvalWorkflowLoadingModal"
        >
          <Box>
            <LoadingSpinner size={100} />
          </Box>
        </Flex>
      )}
    </Modal>
  );
};

export default withTranslation()(ViewActionApprovalWorkflow);
