/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PrimaryNav_tenant$ref: FragmentReference;
export type PrimaryNav_tenant = {|
  +id: string,
  +subdomain: ?string,
  +name: string,
  +settings: ?{|
    +enableEditWithWord: boolean,
    +standardsAndRegulationsEnabled: ?boolean,
    +enableAllBlocs: boolean,
    +autoLoginGuest: boolean,
    +vergeIntegrationEnabled: ?boolean,
    +documentsWithStandardsReportEnabled: ?boolean,
  |},
  +customerSettings: ?{|
    +enableEnterprisePolicySharing: boolean
  |},
  +acknowledgmentsEnabled: ?boolean,
  +hasScheduledEffectiveDateEnabled: ?boolean,
  +$refType: PrimaryNav_tenant$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PrimaryNav_tenant",
  "type": "TenantType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subdomain",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "settings",
      "storageKey": null,
      "args": null,
      "concreteType": "TenantSettingsType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "enableEditWithWord",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "standardsAndRegulationsEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "enableAllBlocs",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "autoLoginGuest",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vergeIntegrationEnabled",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "documentsWithStandardsReportEnabled",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "customerSettings",
      "storageKey": null,
      "args": null,
      "concreteType": "CustomerSettingsType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "enableEnterprisePolicySharing",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "acknowledgmentsEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasScheduledEffectiveDateEnabled",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '19cadbe1d9d8a3d786f7fc6ff87b6b4e';
module.exports = node;
