import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";

import { H2 } from "pstat-design-system/text";
import theme from "pstat-anywhere/themes/policystat/theme";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const FilterDropdownRelativeContainer = system({
  position: "relative"
});

const FilterDropdownContainer = system({
  position: "absolute",
  right: 0,
  zIndex: theme.layers.tertiaryNav
}).extend`
  display: ${props => (props.expanded ? "block" : "none")};
  bottom: ${props => (props.above ? "100%" : null)};

  &:before,
  &:after {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    border: 8px solid transparent; /* arrow size */
  }

  &:before {
    border-${props => (props.above ? "top" : "bottom")}-color: ${themeGet(
  "colors.nav.100"
)}; /* arrow color */

    /* positioning */
    position: absolute;
    ${props => (props.above ? "bottom" : "top")}: -6px;

    right: 16px;
    z-index: 2;
  }

  &:after {
    border-${props => (props.above ? "top" : "bottom")}-color: ${themeGet(
  "colors.nav.90"
)}; /* arrow color */

    /* positioning */
    position: absolute;
    ${props => (props.above ? "bottom" : "top")}: -8px;
    right: 16px;
    z-index: 1;
  }
`;

const FilterDropdownContent = system({
  bg: "nav.100",
  border: 1,
  borderColor: "nav.90",
  borderRadius: 1,
  boxShadow: "dropdown",
  minWidth: [0, 300, 300]
}).extend`
  display: ${props => (props.expanded ? "block" : "none")};
  ${props =>
    props.above
      ? `margin-bottom: ${theme.space[2]}px`
      : `margin-top: ${theme.space[2]}px`};
`;

const FilterDropdownHeaderContainer = system({
  is: Flex,
  justifyContent: "space-between",
  alignItems: "center",
  width: 1,
  pl: 5,
  pr: 4,
  py: 4,
  borderBottom: 2,
  borderColor: "nav.80"
});

const CloseButtonContainer = system({}).extend`
  cursor: pointer;
`;

const CloseButton = props => (
  <CloseButtonContainer
    tabIndex="0"
    role="button"
    aria-label={props.t("documentReports.advancedReportPage.filters.close")}
    onClick={props.onClick}
    onKeyDown={props.onKeyDown}
  >
    <StyledFontAwesomeIcon
      icon={["fal", "times"]}
      size="lg"
      color="nav.55"
      aria-label="close"
      ml={0}
      mr={0}
    />
  </CloseButtonContainer>
);

export const DropdownFooterContainer = system({
  is: Flex,
  width: 1,
  px: 5,
  py: 3,
  justifyContent: "flex-end",
  bg: "nav.95",
  borderRadius: 1
});

const Dropdown = props => {
  const { t } = useTranslation();
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      props.onClose();
    }
  };
  return (
    <FilterDropdownRelativeContainer className="fieldset-button-container">
      {props.renderToggleComponent()}
      <FilterDropdownContainer expanded={props.expanded} above={props.above}>
        <FilterDropdownContent expanded={props.expanded} above={props.above}>
          {props.expanded && (
            <Fragment>
              {props.headerName && (
                <FilterDropdownHeaderContainer>
                  <H2
                    color="nav.0"
                    fontWeight={theme.fontWeights.normal}
                    pr={5}
                  >
                    {props.headerName}
                  </H2>
                  <CloseButton
                    onClick={props.onClose}
                    onKeyDown={handleKeyDown}
                    t={t}
                  />
                </FilterDropdownHeaderContainer>
              )}
              <Flex flexDirection="column" px={5} py={4}>
                {props.children}
              </Flex>
              {props.renderFooter && props.renderFooter()}
            </Fragment>
          )}
        </FilterDropdownContent>
      </FilterDropdownContainer>
    </FilterDropdownRelativeContainer>
  );
};

export default Dropdown;
