import algoliasearch from "algoliasearch/lite";
import { Fragment } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import { withViewer } from "../../../context_providers/ViewerContext";

import { AutoCompleteDesktop } from "./QuickSearchDesktop";

// decided to put the check for viewer here
// as secondary nav doesnt have the viewer,
// and this one already did
export const QuickSearchBase = props => {
  const searchClient = algoliasearch(
    props.viewer.algoliaApplicationId,
    props.viewer.algoliaAccessKey
  );
  return (
    <Fragment>
      {props.viewer && (
        <InstantSearch
          searchClient={searchClient}
          indexName={props.viewer.algoliaDocumentsIndexName}
        >
          <AutoCompleteDesktop {...props} />
          <Configure
            hitsPerPage={5}
            restrictSearchableAttributes="id,name,text"
            attributesToHighlight="name"
            attributesToSnippet="text"
            responseFields={["hits", "nbHits", "query"]}
          />
        </InstantSearch>
      )}
    </Fragment>
  );
};

export const QuickSearch = withViewer(QuickSearchBase);
