/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type editor_editorProperties$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PolicyEditor_editorProperties$ref: FragmentReference;
export type PolicyEditor_editorProperties = {|
  +ckeditor5: ?boolean,
  +$fragmentRefs: editor_editorProperties$ref,
  +$refType: PolicyEditor_editorProperties$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PolicyEditor_editorProperties",
  "type": "DocumentEditType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "editor_editorProperties",
      "args": null
    }
  ]
};

(node/*: any*/).hash = '84b71f7166334073f5f8fcb619109628';
module.exports = node;
