import * as Sentry from "@sentry/browser";
import { Component } from "react";

import { ContentContainerFlex } from "./";

import {
  PageNotFound404Error,
  PermissionDenied403Error
} from "pstat-anywhere/errors";
import {
  AccessDeniedErrorPage,
  PageNotFoundErrorPage,
  ServerErrorPage
} from "pstat-design-system/ErrorPages";


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  shouldLogError = error => {
    if (process.env.NODE_ENV !== "production") {
      return false;
    }
    if (error instanceof PageNotFound404Error) {
      return false;
    }
    if (error instanceof PermissionDenied403Error) {
      return false;
    }
    return true;
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.children !== this.props.children)
      this.setState({ hasError: false });
  }

  componentDidCatch(error, errorInfo) {
    // NOTE: this isn't called when an error causes props.children to be undefined
    if (this.shouldLogError(error)) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError || this.props.children === undefined) {
      if (this.state.error instanceof PageNotFound404Error) {
        return (
          <ContentContainerFlex>
            <PageNotFoundErrorPage />
          </ContentContainerFlex>
        );
      } else if (this.state.error instanceof PermissionDenied403Error) {
        return (
          <ContentContainerFlex>
            <AccessDeniedErrorPage
              message={this.state.error.message}
              tenantsToRedirect={this.state.error.tenantsToRedirect}
              documentPk={this.state.error.documentPk}
              routeName={this.state.error.routeName}
            />
          </ContentContainerFlex>
        );
      }
      return (
        <ContentContainerFlex>
          <ServerErrorPage />
        </ContentContainerFlex>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
