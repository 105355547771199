import VisuallyHidden from "@reach/visually-hidden";
import graphql from "babel-plugin-relay/macro";
import { Box, Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { borderColor, borders } from "styled-system";

import theme from "pstat-anywhere/themes/policystat/theme";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { userDisplay } from "pstat-anywhere/utils/userDisplay";
import Card from "pstat-design-system/Card";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import SiteLogo from "pstat-design-system/SiteLogo";
import { Text } from "pstat-design-system/text/paragraph";

export const AuthorSearchLink = ({ author }) => {
  const pathname = `${getRootUrl()}/search`;
  const query = {
    author: author.pk
  };
  return (
    <RouterFocusLink
      to={{
        pathname: pathname,
        query: query
      }}
    >
      {userDisplay(author)}
    </RouterFocusLink>
  );
};

export const AuthorSearchLinkFragmentContainer = createFragmentContainer(
  AuthorSearchLink,
  graphql`
    fragment Properties_author on UserType {
      pk
      userDisplay
      firstName
      lastName
      profile {
        title
      }
    }
  `
);

export const CategorySearchLink = ({ category }) => {
  const pathname = `${getRootUrl()}/search`;
  const query = {
    category: category.pk
  };
  return (
    <RouterFocusLink
      to={{
        pathname: pathname,
        query: query
      }}
    >
      {category.name}
    </RouterFocusLink>
  );
};

export const CategorySearchLinkFragmentContainer = createFragmentContainer(
  CategorySearchLink,
  graphql`
    fragment Properties_category on CategoryType {
      pk
      name
    }
  `
);

export const Tags = ({ tags, renderTag }) => {
  return tags.map((tag, index) => {
    let tagText = tag.name;
    if (index !== tags.length - 1) {
      tagText += ",";
    }
    return renderTag(tag.pk, tagText);
  });
};

export const TagsFragmentContainer = createFragmentContainer(
  Tags,
  graphql`
    fragment Properties_tags on TagType @relay(plural: true) {
      pk
      name
    }
  `
);

export const TagSearchLink = ({ pk, text }) => {
  const pathname = `${getRootUrl()}/search`;
  const query = {
    reference: pk
  };
  return (
    <RouterFocusLink
      key={pk}
      to={{
        pathname: pathname,
        query: query
      }}
    >
      {text}
    </RouterFocusLink>
  );
};

const Properties = ({ headerImageUrl, renderDates, renderProperties }) => (
  <Media query={{ minWidth: theme.breakpoints[1] }}>
    {isDesktop =>
      isDesktop && (
        <Box width={1} pt={0} pb={3} px={3}>
          <Card id="document_header">
            <Flex>
              <Flex
                alignItems="center"
                justify="center"
                width={1 / 3}
                height="0%"
              >
                <SiteLogo src={headerImageUrl} />
              </Flex>
              <BoxWithBorder
                width={1 / 3}
                borderRight="1px solid"
                borderColor="nav.80"
              >
                {renderDates()}
              </BoxWithBorder>
              <OwnershipBox width={1 / 3}>{renderProperties()}</OwnershipBox>
            </Flex>
          </Card>
        </Box>
      )
    }
  </Media>
);

export default Properties;

const BoxWithBorder = styled(Box)`
  ${borders};
  ${borderColor};
`;

const OwnershipBox = styled(Box).attrs({
  "data-testid": "policy-properties-ownership"
})`
  & a div {
    max-width: 120px;
    word-break: break-word;
  }
  & div.accessible-blocs-items {
    max-width: 100%;
  }
`;

export const KeyValueRow = props => {
  return (
    <Flex id={props.id} flexDirection="row" justify="center">
      <LeftBox width={1 / 2} py={1} px={2}>
        <Text color="nav.25">{props.label}</Text>
      </LeftBox>
      <Box width={1 / 2} px={2}>
        {props.children}
      </Box>
    </Flex>
  );
};

const LeftBox = styled(Box)`
  text-align: right;
`;

export const DateKeyValueRow = ({ id, label, value }) => {
  const { t } = useTranslation();
  let displayValue = value;
  let screenReaderFullDate = value;
  if (value === null) {
    displayValue = "-";
    screenReaderFullDate = t(
      "documentControl.view.infoModal.dateDisplay.notApplicable"
    );
  }
  return (
    <Flex id={id} flexDirection="row" justify="center">
      <VisuallyHidden>{`${label}, ${screenReaderFullDate}`}</VisuallyHidden>
      <LeftBox width={1 / 2} py={1} px={2}>
        <Text color="nav.25" aria-hidden="true">
          {label}
        </Text>
      </LeftBox>
      <Box width={1 / 2} py={1} px={2}>
        <Text aria-hidden="true">{displayValue}</Text>
      </Box>
    </Flex>
  );
};

export const AuthorContainer = ({ documentSettings, labels, children }) => (
  <Fragment>
    {documentSettings.authorShow && (
      <KeyValueRow id="author" label={labels.authorLabel}>
        {children}
      </KeyValueRow>
    )}
  </Fragment>
);

export const CategoryContainer = ({ documentSettings, labels, children }) => (
  <Fragment>
    {documentSettings.departmentShow && (
      <KeyValueRow id="category" label={labels.categoryLabel}>
        {children}
      </KeyValueRow>
    )}
  </Fragment>
);

export const ReferencesContainer = ({
  tags,
  documentSettings,
  labels,
  children
}) => (
  <Fragment>
    {documentSettings.referencesShow && (
      <Fragment>
        {tags?.length > 0 && (
          <KeyValueRow id="tags" label={labels.referencesLabel}>
            {children}
          </KeyValueRow>
        )}
      </Fragment>
    )}
  </Fragment>
);

export const ApprovedDate = ({ documentSettings, labels, date }) => (
  <Fragment>
    {documentSettings.approvedDateShow && (
      <DateKeyValueRow
        id="approved_date"
        label={labels.approvedDateLabel}
        value={date}
      />
    )}
  </Fragment>
);

export const RevisionDate = ({ documentSettings, labels, date }) => (
  <Fragment>
    {documentSettings.revisionDateShow && (
      <DateKeyValueRow
        id="revision_date"
        label={labels.revisionDateLabel}
        value={date}
      />
    )}
  </Fragment>
);
