import { withRouter } from "found";
import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import ButtonLink from "pstat-design-system/ButtonLink";
import { Link } from "pstat-design-system/Link";
import { RouterLink } from "pstat-design-system/RouterLink";
import { H1 } from "pstat-design-system/text/headings";
import { Text } from "pstat-design-system/text/paragraph";

const ErrorPageContainer = system({
  pl: 70,
  pr: 30,
  py: [20, 20, 45]
});

const ErrorMessageContainer = system({
  mt: [0, 0, 45],
  width: ["100%", 340]
});

const ErrorMessageSpan = system({
  is: Text,
  color: "nav.25",
  display: "block",
  fontSize: 2,
  lineHeight: "20px"
});

const ErrorLink = system({
  is: RouterLink,
  fontSize: 2,
  fontFamily: theme.fontFamily
}).extend`
  text-decoration: none;
  & hover {
    text-decoration: underline;
  }
`;

const PreviousLink = system({
  is: Link,
  fontSize: 2,
  fontFamily: theme.fontFamily
}).extend`
  text-decoration: none;
  & hover {
    text-decoration: underline;
  }
`;

const ErrorList = system({
  is: "ul",
  color: "nav.25",
  fontSize: 2
}).extend`
  list-style:disc;
`;

const ErrorOptions = system({
  is: "li",
  ml: 24,
  mt: 2,
  fontSize: 2,
  fontFamily: theme.fontFamily
}).extend`
  display: list-item;
`;

const SadKate = system({
  is: "img",
  maxWidth: "100%",
  minWidth: 400,
  maxHeight: 425
});

const ErrorMessage = ({ title, children }) => (
  <ErrorMessageContainer>
    <H1 color="nav.0" mb={4}>
      {title}
    </H1>
    {children}
  </ErrorMessageContainer>
);

const ErrorPage = ({ children }) => (
  <ErrorPageContainer>
    <Flex justifyContent="space-evenly" wrap="wrap">
      <Box flex={1}>
        <Flex justifyContent="center">{children}</Flex>
      </Box>
      <Box flex={2}>
        <Flex justifyContent="center">
          <SadKate
            src={`${window.S3_IMG_URL}sad-kate.png`}
            alt="sad kate"
            id="sad-kate"
          />
        </Flex>
      </Box>
    </Flex>
  </ErrorPageContainer>
);

const _AccessDeniedErrorPage = props => {
  const { t } = useTranslation();
  const {
    message,
    tenantsToRedirect,
    tenant: { name: actualTenantName },
    documentPk,
    routeName
  } = props;

  const parsedSimpleMessage =
    message && message !== "null" ? message : t("errorPages.403.message");

  const Container = styled.div`
    margin-right: ${themeGet("space.5")}px;
  `;

  const Line = system({
    borderTop: 2,
    borderColor: "nav.80",
    mb: 4
  });

  const SimpleAccessDeniedMessage = () => (
    <ErrorMessage title={t("errorPages.403.title")}>
      <ErrorMessageSpan mb={5}>{parsedSimpleMessage}</ErrorMessageSpan>
    </ErrorMessage>
  );

  const routeNameToActionMap = {
    document_approve: "approve",
    document_delete: "delete",
    document_edit: "edit",
    document_override: "override",
    document_print: "print",
    document_retire: "retire",
    document_view: "view"
  };
  const action = routeNameToActionMap[routeName];

  const AccessDeniedWithTenantsToRedirect = () => (
    <Container>
      <ErrorMessage title={t("errorPages.403.withTenants.title")}>
        <ErrorMessageSpan mb={0}>
          {t("errorPages.403.withTenants.message.action")}
        </ErrorMessageSpan>
        <ErrorMessageSpan mb={5}>
          {t("errorPages.403.withTenants.message.actualTenant", {
            actualTenantName
          })}
        </ErrorMessageSpan>
        <ErrorMessageSpan mb={5}>
          {t("errorPages.403.withTenants.message.otherTenants")}
        </ErrorMessageSpan>
        <Line />
        {tenantsToRedirect.map(({ name, url }) => (
          <div key={url}>
            <ButtonLink
              buttonStyle="primary"
              href={`${url}policy/${documentPk}`}
              mb={4}
              display="inline-block"
            >
              {action
                ? t(`errorPages.403.withTenants.${action}`, {
                    tenantName: name
                  })
                : t(`errorPages.403.withTenants.default`, { tenantName: name })}
            </ButtonLink>
          </div>
        ))}
      </ErrorMessage>
    </Container>
  );

  return (
    <ErrorPage>
      {tenantsToRedirect && tenantsToRedirect.length > 0 ? (
        <AccessDeniedWithTenantsToRedirect />
      ) : (
        <SimpleAccessDeniedMessage />
      )}
    </ErrorPage>
  );
};

export const AccessDeniedErrorPage = withTenantContext(_AccessDeniedErrorPage);

export const _PageNotFoundErrorPage = ({ router }) => {
  const { t } = useTranslation();
  return (
    <ErrorPage>
      <ErrorMessage title={t("errorPages.404.title")}>
        <ErrorMessageSpan mb={5}>
          {t("errorPages.404.message")}
        </ErrorMessageSpan>
        <ErrorList>
          <ErrorOptions>
            {t("errorPages.404.suggestions.previousPage")}
            <PreviousPageLink router={router}>
              {t("errorPages.404.suggestions.previousPage.link")}
            </PreviousPageLink>
          </ErrorOptions>
          <ErrorOptions>
            {t("errorPages.404.suggestions.returnHome")}
            <ErrorLink to={{ pathname: `${getRootUrl()}/` }}>
              {t("errorPages.404.suggestions.returnHome.link")}
            </ErrorLink>
          </ErrorOptions>
        </ErrorList>
      </ErrorMessage>
    </ErrorPage>
  );
};

export const PageNotFoundErrorPage = withRouter(_PageNotFoundErrorPage);

export const ServerErrorPage = props => {
  const { t } = useTranslation();
  return (
    <ErrorPage>
      <ErrorMessage title={t("errorPages.500.title")}>
        <ErrorMessageSpan mb={5}>
          {t("errorPages.500.message")}
        </ErrorMessageSpan>
        <ErrorMessageSpan mb={5}>
          {t("errorPages.500.suggestions")}
        </ErrorMessageSpan>
      </ErrorMessage>
    </ErrorPage>
  );
};

const PreviousPageLink = ({ children, router }) => {
  return <PreviousLink onClick={() => router.go(-1)}>{children}</PreviousLink>;
};
