import { Box, Flex } from "grid-styled";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";
import system from "system-components";

import blocCopiesQuery from "./BlocCopiesQuery";
import editBloc from "./EditBlocMutation";

import useGettingBlocCopies from "pstat-anywhere/components/bloc/useGettingBlocCopies";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { WarningBanner } from "pstat-design-system/banner";
import {
  InputFormElement,
  SingleSelectDropdownFormElement
} from "pstat-design-system/inputs/FormElements";
import { FocusLink, Link } from "pstat-design-system/Link";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


const ExistedTenantItem = system({
  is: Link
}).extend`
  display: list-item;
`;

const _EditBlocModal = ({
  isOpen,
  onClose,
  currentBlocName,
  currentDocumentSettingsPk,
  documentSettings,
  isNewBloc,
  labels,
  onViewOrEditSitesClicked,
  selectedTenantPks,
  blocPk,
  selectedTenantsChanged,
  router
}) => {
  const [blocName, setBlocName] = useState(currentBlocName);
  const [selectedDocumentSetting, setSelectedDocumentSetting] = useState(
    currentDocumentSettingsPk
  );
  const [saving, setSaving] = useState(false);
  const [blocCopiesTaskId, setBlocCopiesTaskId] = useState(null);
  const { blocCopies } = useGettingBlocCopies(blocCopiesTaskId);
  const { t } = useTranslation();

  useEffect(() => {
    if (blocPk && isOpen && selectedTenantPks && selectedTenantPks.length > 0) {
      const variables = {
        excludeBlocPk: blocPk,
        tenantPks: selectedTenantPks
      };
      fetchQuery(environment, blocCopiesQuery, variables).then(response => {
        const { blocCopies } = response || {};
        const { error, taskId } = blocCopies || {};
        if (!error) {
          setBlocCopiesTaskId(taskId);
        }
      });
    }
  }, [selectedTenantPks, isOpen, blocPk, t]);

  const documentSettingsOptions = [
    { pk: null, name: "-----------------" },
    ...documentSettings.map(settings => {
      return { pk: settings.pk, name: settings.display };
    })
  ];
  const saveChanges = () => {
    setSaving(true);
    editBloc(
      environment,
      blocPk,
      blocName,
      selectedDocumentSetting,
      selectedTenantPks,
      (response, errors) => {
        setSaving(false);
        if (errors) {
          createErrorMessage(t("bloc.details.editModal.errorMessage"));
          return;
        }
        const error = response.changeBloc.error;
        if (error) {
          const nameErrors = error.name || [];
          if (nameErrors.length > 0) {
            createErrorMessage(nameErrors[0]);
            return;
          }

          createErrorMessage(t("bloc.details.editModal.errorMessage"));
          return;
        }
        const changeBloc = response.changeBloc;
        if (changeBloc.ok) {
          onClose();
          const bloc = changeBloc.bloc;
          createSuccessMessage(
            t("bloc.details.editModal.successMessage", { blocName: bloc.name })
          );
          router.push(`${getRootUrl()}/bloc/${bloc.pk}`);
        } else {
          createErrorMessage(t("bloc.details.editModal.errorMessage"));
        }
      }
    );
  };
  const changesMade =
    blocName !== currentBlocName ||
    selectedDocumentSetting !== currentDocumentSettingsPk ||
    selectedTenantsChanged(selectedTenantPks);
  return (
    <Modal
      id="edit_bloc_modal"
      contentLabel={
        isNewBloc
          ? t("bloc.sites.createModal.heading")
          : t("bloc.details.editModal.heading")
      }
      isOpen={isOpen}
      onRequestClose={onClose}
      minWidth={[320, 320, 390]}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button buttonStyle="tertiary" onClick={onClose}>
                {t("buttons.cancel")}
              </Button>
            </Box>
            <Box>
              <Button
                id="save-changes-button"
                buttonStyle="primary"
                width="100%"
                onClick={saveChanges}
                disabled={!changesMade || blocName.length === 0}
                processing={saving}
              >
                {isNewBloc ? (
                  <Fragment>{t("bloc.sites.createModal.heading")}</Fragment>
                ) : (
                  <Fragment>{t("bloc.details.editModal.saveChanges")}</Fragment>
                )}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={4} width={["100%", "100%", 390]}>
        {blocCopies && blocCopies.length > 0 && (
          <WarningBanner width={null}>
            <Flex flexDirection="column" pb={5}>
              <Text color="nav.0" pb={2} fontWeight="medium">
                {t("bloc.details.editModal.existedWarning.title")}
                <Text color="nav.0">
                  {t("bloc.details.editModal.existedWarning.body")}
                </Text>
              </Text>
              {blocCopies.map(({ name, url, blocPk }) => (
                <ExistedTenantItem key={blocPk} href={url}>
                  {name}
                </ExistedTenantItem>
              ))}
            </Flex>
          </WarningBanner>
        )}
        <InputFormElement
          id="bloc_name_input"
          label={t("bloc.details.editModal.nameLabel")}
          value={blocName}
          onChange={event => setBlocName(event.target.value)}
          required={true}
          maxLength={100}
        />
        <SingleSelectDropdownFormElement
          id="bloc_document_settings_input"
          label={t("bloc.details.editModal.documentSettings.label", {
            documentLabel: titleCase(labels.documentLabel)
          })}
          options={documentSettingsOptions}
          onClear={() => setSelectedDocumentSetting(null)}
          value={selectedDocumentSetting}
          onChange={event => setSelectedDocumentSetting(event.target.value)}
          name="document_settings"
          errors={[]}
          placeholder={t("bloc.details.editModal.documentSettings.placeholder")}
          helpText={t("bloc.details.editModal.documentSettings.helpText", {
            documentLabel: labels.documentLabel.toLowerCase()
          })}
        />
        <Text fontWeight="medium" fontSize={1} mb={7}>
          {t("bloc.details.editModal.sitesInBloc", {
            sitesInBloc: selectedTenantPks.length
          })}
          <FocusLink
            id="edit-tenants-link"
            role="button"
            onActivation={onViewOrEditSitesClicked}
            fontSize={1}
            fontWeight="normal"
          >
            {t("bloc.details.editModal.siteSelectionLinkText")}
          </FocusLink>
        </Text>
        {isNewBloc && (
          <WarningBanner width={1} message={t("bloc.sites.createModal.note")} />
        )}
      </Flex>
    </Modal>
  );
};
export default withLabelContext(_EditBlocModal);
