/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PrimaryNav_accessibleTenants$ref: FragmentReference;
export type PrimaryNav_accessibleTenants = $ReadOnlyArray<{|
  +id: string,
  +url: ?string,
  +name: string,
  +$refType: PrimaryNav_accessibleTenants$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PrimaryNav_accessibleTenants",
  "type": "TenantType",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = 'bc421ac002e140496b992f86a6a07891';
module.exports = node;
