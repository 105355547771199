import { Box } from "grid-styled";
import { Fragment } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { ContentContainerFlex } from "pstat-anywhere/components/pages";

export const Divider = system({
  borderTop: 1,
  borderColor: "nav.80",
  mt: 3,
  mb: 4
});

export const LoginFormContainer = system({
  is: "form",
  bg: "nav.100",
  p: 4,
  textAlign: "center",
  borderRadius: 1
}).extend`
  box-shadow: ${props =>
    props.hideShadow ? "none" : themeGet("shadows.card")};
  }
`;

export const LoginWrapper = system(
  {
    is: "article"
  },
  "space",
  "width"
);

export const CustomerLogoWrapper = system({
  is: "div",
  m: "0 auto",
  width: "350px"
});

export const CustomerLogo = styled.div`
  margin: 0 auto;
  height: 350px;
  width: 350px;
  background-image: url(${props => props.imgUrl});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const LoginFormCustomerLogo = ({ children, customerLogo }) => (
  <Fragment>
    <CustomerLogoWrapper>
      <CustomerLogo imgUrl={customerLogo} />
    </CustomerLogoWrapper>
    <ContentContainerFlex wrap px={[3, 3, 0]}>
      <Box width={[1, 1, 1 / 2.5]} p={[0, 0, 3]} m="auto">
        {children}
      </Box>
    </ContentContainerFlex>
  </Fragment>
);
