import { Component } from "react";
// @flow
import { withTranslation } from "react-i18next";

import { LoginFormContainer } from "./Common";

import { Button } from "pstat-design-system/shared/Button";
import { InputFormElement } from "pstat-design-system/inputs/FormElements";

class SSOLogin extends Component {
  static defaultProps = {
    delay: 1000,
    hardRedirect: true,
    isUserHasProxy: false
  };

  componentDidMount() {
    if (this.props.hardRedirect) {
      this.timeoutSubmit = setTimeout(
        () => this.autoSubmitForm(),
        this.props.delay
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutSubmit);
  }

  autoSubmitForm() {
    // Auto-login only for non-proxied users
    if (!this.props.isUserHasProxy) {
      document.getElementById("ssoLoginForm").submit();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <LoginFormContainer
        id="ssoLoginForm"
        action={this.props.samlSettings.location}
        data-testid="ssoForm"
        method="POST"
      >
        <InputFormElement
          required={false}
          label={""}
          autosize
          value={this.props.samlSettings.samlRequest}
          type="hidden"
          id={"id_SAMLRequest"}
          name={"SAMLRequest"}
          dataTestid="samlRequest"
        />
        <Button
          focusBorderProps={{ width: "100%" }}
          width="100%"
          buttonStyle="secondary"
        >
          {t("buttons.ssoLogin")}
        </Button>
      </LoginFormContainer>
    );
  }
}

export default withTranslation()(SSOLogin);
