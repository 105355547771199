/**
 * @flow
 * @relayHash a43b2805cac5a1661577782b23be8c48
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssignAcknowledgmentMutationVariables = {|
  docIds?: ?$ReadOnlyArray<number>,
  userIds?: ?$ReadOnlyArray<number>,
  groupIds?: ?$ReadOnlyArray<number>,
  includeRecurring?: ?boolean,
  selectAll?: ?boolean,
|};
export type AssignAcknowledgmentMutationResponse = {|
  +createCertification: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type AssignAcknowledgmentMutation = {|
  variables: AssignAcknowledgmentMutationVariables,
  response: AssignAcknowledgmentMutationResponse,
|};
*/


/*
mutation AssignAcknowledgmentMutation(
  $docIds: [Int!]
  $userIds: [Int!]
  $groupIds: [Int!]
  $includeRecurring: Boolean
  $selectAll: Boolean
) {
  createCertification(docIds: $docIds, userIds: $userIds, groupIds: $groupIds, includeRecurring: $includeRecurring, selectAll: $selectAll) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "docIds",
    "type": "[Int!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userIds",
    "type": "[Int!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "groupIds",
    "type": "[Int!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includeRecurring",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "selectAll",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCertification",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "docIds",
        "variableName": "docIds",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "groupIds",
        "variableName": "groupIds",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "includeRecurring",
        "variableName": "includeRecurring",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "selectAll",
        "variableName": "selectAll",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "userIds",
        "variableName": "userIds",
        "type": "[Int]"
      }
    ],
    "concreteType": "CreateCertification",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "AssignAcknowledgmentMutation",
  "id": "c055661d69c85442a0bab07895f60cd5",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AssignAcknowledgmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "AssignAcknowledgmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation AssignAcknowledgmentMutation(\n  $docIds: [Int!]\n  $userIds: [Int!]\n  $groupIds: [Int!]\n  $includeRecurring: Boolean\n  $selectAll: Boolean\n) {\n  createCertification(docIds: $docIds, userIds: $userIds, groupIds: $groupIds, includeRecurring: $includeRecurring, selectAll: $selectAll) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = 'a5bca72fa38144d2afee61dd837a643c';
module.exports = node;
