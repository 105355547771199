import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import { Mention, MentionsInput } from "react-mentions";
import styled from "styled-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";
import { baseTextareaStyles } from "pstat-design-system/inputs/Textarea";
import { Label, Text } from "pstat-design-system/text";
import Tooltip from "pstat-design-system/Tooltip";

const StyledMentionsInput = styled(MentionsInput)`
  margin-top: ${theme.space[1]}px;
  margin-bottom: ${theme.space[1]}px;
  height: unset; // outer element must not define a height for resize to work
  width: 100%;
  font-family: ${theme.fontFamily};
  textarea {
    ${baseTextareaStyles};
  }
  .mentions-input__suggestions {
    max-height: 7.5rem;
    max-width: 50%;
    width: fit-content;
    padding-top: ${theme.space[2]}px;
    padding-bottom: ${theme.space[2]}px;
    box-shadow: inset 2px 2px 0 0 #e8eaed;
    border: ${theme.borders[1]};
    overflow: auto;
    border-radius: ${theme.radii[1]}px;
  }
  .mentions-input__highlighter {
    visibility: hidden;
    height: 0;
  }
`;

// Some default options must be overwritten
// https://github.com/signavio/react-mentions/blob/master/src/MentionsInput.js#L1055
const textareaInlineStyle = {
  position: "relative",
  overflowY: "visible",

  input: {
    display: "block",
    width: "100%",
    position: "relative", // changed from 'absolute'
    margin: 0,
    "min-height": "7.5rem",
    top: 0,
    left: 0,
    boxSizing: "border-box",
    backgroundColor: "transparent",
    fontFamily: "inherit",
    fontSize: "inherit",
    letterSpacing: "inherit",
    resize: "vertical",
    "overflow-x": "hidden",
    "overflow-y": "auto"
  },
  suggestions: {
    position: "static"
  }
};

export default function CommentInput({
  isMentionSelected,
  handleTextChange,
  value,
  getData,
  handleMentionAdd,
  onFocus
}) {
  const { t } = useTranslation();
  function renderSuggestion(entry) {
    if (entry.type === "user") {
      const mentionSelected = isMentionSelected(entry.id, entry.display_name);
      const splittedDisplayName = entry.display_name.split(":");
      let title;
      if (splittedDisplayName.length > 1) {
        title = splittedDisplayName[1].trim();
      }

      return (
        <Box mb={3} fontSize={3} pr={8} pl={mentionSelected ? 6 : 8}>
          {mentionSelected && (
            <StyledFontAwesomeIcon
              icon={["far", "check"]}
              color="secondary.0"
              mr={2}
            />
          )}
          <Text
            data-tip
            data-for={`user-${entry.id}-title-tooltip`}
            fontSize={3}
            textTransform={mentionSelected ? "underline" : "none"}
          >
            {entry.first_name} {entry.last_name}
          </Text>
          {title && (
            <Tooltip name={`user-${entry.id}-title-tooltip`}>{title}</Tooltip>
          )}
        </Box>
      );
    } else if (entry.type === "group") {
      const mentionSelected = isMentionSelected(entry.id, entry.name);
      return (
        <Box mb={3} fontSize={3} pr={8} pl={mentionSelected ? 6 : 8}>
          <Text>{entry.name}</Text>
        </Box>
      );
    }
  }
  return (
    <Flex p={1} flexWrap="wrap">
      <Label>{t("documentControl.view.commentForm.commentPanel.header")}</Label>
      <StyledMentionsInput
        className="mentions-input"
        onChange={handleTextChange}
        value={value}
        data-testid="comment-textarea"
        allowSpaceInQuery={true}
        placeholder={t(
          "documentControl.view.commentForm.commentPanel.inputPlaceholder"
        )}
        onFocus={onFocus}
        style={textareaInlineStyle}
      >
        <Mention
          data={getData}
          renderSuggestion={renderSuggestion}
          onAdd={handleMentionAdd}
        />
      </StyledMentionsInput>
    </Flex>
  );
}
