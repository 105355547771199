/**
 * @flow
 * @relayHash 1fbe0b71dc376a35f3f69b8bedc36707
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type newDocumentAttachmentMutationVariables = {|
  documentPk: number,
  title: string,
  file: any,
|};
export type newDocumentAttachmentMutationResponse = {|
  +newDocumentAttachment: ?{|
    +ok: ?boolean
  |}
|};
export type newDocumentAttachmentMutation = {|
  variables: newDocumentAttachmentMutationVariables,
  response: newDocumentAttachmentMutationResponse,
|};
*/


/*
mutation newDocumentAttachmentMutation(
  $documentPk: Int!
  $title: String!
  $file: Upload!
) {
  newDocumentAttachment(documentPk: $documentPk, title: $title, attachmentFile: $file) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "file",
    "type": "Upload!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "newDocumentAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attachmentFile",
        "variableName": "file",
        "type": "Upload!"
      },
      {
        "kind": "Variable",
        "name": "documentPk",
        "variableName": "documentPk",
        "type": "Int!"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title",
        "type": "String"
      }
    ],
    "concreteType": "NewDocumentAttachmentMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "newDocumentAttachmentMutation",
  "id": "5462fb87e5b554d1c54d5da995457ae0",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "newDocumentAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "newDocumentAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation newDocumentAttachmentMutation(\n  $documentPk: Int!\n  $title: String!\n  $file: Upload!\n) {\n  newDocumentAttachment(documentPk: $documentPk, title: $title, attachmentFile: $file) {\n    ok\n  }\n}\n";
(node/*: any*/).hash = '88f677bf8d24b87e8f4261fa1d0b3427';
module.exports = node;
