/**
 * @flow
 * @relayHash 3a9700dec088bd5f74e4eb3eb83ef2af
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDocumentAccessTokenInput = {
  tokenId: any,
  documentPk: number,
  clientMutationId?: ?string,
};
export type DeleteTokenMutationVariables = {|
  input: DeleteDocumentAccessTokenInput
|};
export type DeleteTokenMutationResponse = {|
  +deleteDocumentAccessToken: ?{|
    +ok: ?boolean,
    +token: ?{|
      +tokenId: ?any
    |},
  |}
|};
export type DeleteTokenMutation = {|
  variables: DeleteTokenMutationVariables,
  response: DeleteTokenMutationResponse,
|};
*/


/*
mutation DeleteTokenMutation(
  $input: DeleteDocumentAccessTokenInput!
) {
  deleteDocumentAccessToken(input: $input) {
    ok
    token {
      tokenId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteDocumentAccessTokenInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "DeleteDocumentAccessTokenInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tokenId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeleteTokenMutation",
  "id": "9112292a4a265d7f4101f7eeebe74d9a",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteTokenMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteDocumentAccessToken",
        "storageKey": null,
        "args": v1,
        "concreteType": "DeleteDocumentAccessTokenPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAccessTokenType",
            "plural": false,
            "selections": [
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteTokenMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deleteDocumentAccessToken",
        "storageKey": null,
        "args": v1,
        "concreteType": "DeleteDocumentAccessTokenPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAccessTokenType",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation DeleteTokenMutation(\n  $input: DeleteDocumentAccessTokenInput!\n) {\n  deleteDocumentAccessToken(input: $input) {\n    ok\n    token {\n      tokenId\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '2021f59f236e5f561f537da4af2b13c7';
module.exports = node;
