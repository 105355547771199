import { useEffect, useState } from "react";

import { getRootUrl } from "pstat-anywhere/utils/urls";
import {
  ALL_SELECTED,
  NONE_SELECTED,
  SOME_SELECTED
} from "pstat-design-system/inputs/Checkbox";

export default function useAdvancedReportPage({
  router,
  route,
  location,
  tabs,
  items,
  initiallySelectedPks,
  isCheckboxDisabled = () => false,
  onRowSelection
}) {
  const [selectedItemPks, setSelectedItemPks] = useState(initiallySelectedPks);
  let initialSelectAllState = NONE_SELECTED;
  if (initiallySelectedPks.length > 0) {
    initialSelectAllState = SOME_SELECTED;
  }
  const [selectAllCheckboxState, setSelectAllCheckbox] = useState(
    initialSelectAllState
  );
  useEffect(() => {
    onRowSelection && onRowSelection(selectedItemPks);
  }, [selectedItemPks, onRowSelection]);
  const selectableItems =
    items && items.filter(item => !isCheckboxDisabled(item));

  function handleTabChange(selectedTab) {
    const tabPath = tabs.filter(tab => tab.id === selectedTab)[0].path;
    if (tabPath) {
      router.push(`${getRootUrl()}/${tabPath}`);
    }
  }

  function handleSortChange(sort) {
    const queryParams = { ...location.query };
    queryParams.sort = sort;
    const to = {
      pathname: location.pathname,
      query: queryParams
    };
    router.push(to);
  }

  function handleCheckboxClick(newPk) {
    //  Don't know what's exactly wrong there, but somehow selectedItemPks
    // becomes stale state. So it will work only this way
    setSelectedItemPks(selectedItemPks => {
      const [
        visibleSelectedItemPks,
        hiddenSelectedItemPks
      ] = getVisibleAndHiddenItemPks(selectedItemPks);
      if (selectedItemPks.includes(newPk)) {
        const newVisibleSelectedItemPks = visibleSelectedItemPks.filter(
          pk => pk !== newPk
        );
        if (newVisibleSelectedItemPks.length === 0) {
          setSelectAllCheckbox(NONE_SELECTED);
        } else {
          setSelectAllCheckbox(SOME_SELECTED);
        }
        return [...newVisibleSelectedItemPks, ...hiddenSelectedItemPks];
      } else {
        const newVisibleSelectedItemPks = [...visibleSelectedItemPks, newPk];
        if (newVisibleSelectedItemPks.length === selectableItems.length) {
          setSelectAllCheckbox(ALL_SELECTED);
        } else {
          setSelectAllCheckbox(SOME_SELECTED);
        }
        return [...newVisibleSelectedItemPks, ...hiddenSelectedItemPks];
      }
    });
  }

  function handleClearSelection() {
    setSelectedItemPks([]);
  }

  function getVisibleAndHiddenItemPks(selectedItemPks) {
    const visibleSelectedItemPks = [];
    const hiddenSelectedItemPks = [];
    for (const selectedItemPk of selectedItemPks) {
      const itemPks = items.map(item => item.pk);
      if (itemPks.indexOf(selectedItemPk) > -1) {
        visibleSelectedItemPks.push(selectedItemPk);
      } else {
        hiddenSelectedItemPks.push(selectedItemPk);
      }
    }
    return [visibleSelectedItemPks, hiddenSelectedItemPks];
  }

  function handleSelectAllCheckboxClick() {
    // `selectedItemPks` can include items that are not shown in the table due to filters or search filtering them out.
    // The select all checkbox should only change selection for items that are visible in the table
    const [
      visibleSelectedItemPks,
      hiddenSelectedItemPks
    ] = getVisibleAndHiddenItemPks(selectedItemPks);
    if (visibleSelectedItemPks.length === selectableItems.length) {
      // only keep the hidden items selected
      setSelectedItemPks(items => hiddenSelectedItemPks);
      setSelectAllCheckbox(NONE_SELECTED);
    } else {
      setSelectedItemPks([
        ...hiddenSelectedItemPks,
        ...selectableItems.map(item => item.pk)
      ]);
      setSelectAllCheckbox(ALL_SELECTED);
    }
  }

  const updatedItems =
    items &&
    items.map(item => {
      const itemCopy = { ...item };
      itemCopy.selected = selectedItemPks.indexOf(item.pk) > -1;
      return itemCopy;
    });

  return {
    currentRouteName: route?.data?.routeName,
    selectedItemPks,
    selectAllCheckboxState,
    handleSortChange,
    handleTabChange,
    handleCheckboxClick,
    handleClearSelection,
    handleSelectAllCheckboxClick,
    updatedItems
  };
}
