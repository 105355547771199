import system from "system-components";

const Card = system(
  {
    borderRadius: 1,
    bg: "nav.100",
    boxShadow: "card",
    px: [3, 3, 6],
    py: [3, 3, 6]
  },
  "left",
  "position",
  "width",
  "minHeight",
  "height",
  "display",
  "justifyContent",
  "alignContent",
  "flexWrap",
  "flexDirection",
  "alignItems"
);

Card.displayName = "Card";

export default Card;
