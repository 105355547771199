import { borderRadius } from "polished";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components";
import media from "styled-media-query";
import { themeGet } from "styled-system";
import system from "system-components";

import { StyledFontAwesomeIcon } from "../../partials/icons";
import { MenuIcon, SearchIcon } from "../Icons";

import { useFocusBorderButton } from "./hooks/Button";

import { P } from "pstat-design-system/text";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import InlineInputWrapper from "pstat-design-system/InlineInputWrapper";
import theme from "pstat-anywhere/themes/policystat/theme";

export const BaseButton = system(
  {
    is: "button",
    py: 3, // 3x spacing scale - 12px
    px: 4, // 16px
    m: 0,
    border: 1,
    borderRadius: 1,
    fontFamily: "font",
    fontSize: 2,
    fontWeight: "normal",
    height: "44px",
    focus: {
      outline: 0
    }
  },
  "buttonStyle",
  "width",
  "display",
  "justifyContent"
).extend`
  &:disabled {
    cursor: not-allowed;
  }
  white-space: nowrap;
`;

export const buttonStyle = css`
  background-image: linear-gradient(
    to bottom,
    ${props => themeGet(`buttons.${props.buttonStyle}.accentColor`)},
    ${props => themeGet(`buttons.${props.buttonStyle}.accentColor`)} 50%,
    ${props => themeGet(`buttons.${props.buttonStyle}.baseColor`)} 50%,
    ${props => themeGet(`buttons.${props.buttonStyle}.baseColor`)}
  );
`;

export const hoverButtonStyle = css`
  background-image: linear-gradient(
    to bottom,
    ${props => themeGet(`buttons.${props.buttonStyle}.baseColor`)},
    ${props => themeGet(`buttons.${props.buttonStyle}.baseColor`)} 50%,
    ${props => themeGet(`buttons.${props.buttonStyle}.accentColor`)} 50%,
    ${props => themeGet(`buttons.${props.buttonStyle}.accentColor`)}
  );
`;

export const activeButtonStyle = css`
  background-color: ${props =>
    themeGet(`buttons.${props.buttonStyle}.baseColor`)};
  background-image: none;
`;

const StyledButton = system(
  {
    is: BaseButton
  },
  "buttonStyle",
  "display",
  "justifyContent"
).extend`
  ${InlineInputWrapper} & {
    font-size: ${themeGet("fontSizes.1")};
  }

  ${buttonStyle}
  &:hover:enabled {
    ${hoverButtonStyle}
  }
  &:active:enabled {
    ${activeButtonStyle}
  }
  &:disabled {
    border-color: transparent;
    background-image: none;
    background-color: ${props =>
      themeGet(`buttons.${props.buttonStyle}.disabledBackgroundColor`)};
    color: ${props => themeGet(`buttons.${props.buttonStyle}.disabledColor`)};
  }
`;

const FocusBorder = system(
  {
    display: "inline-block",
    border: 1,
    borderColor: "transparent",
    borderRadius: 1,
    tabIndex: "-1"
  },
  "width"
).extend`
  box-shadow: ${props =>
    props.hasFocus ? `0 0 0 1px ${props.focusBorderColor}` : `none`};

  ${InlineInputWrapper} &:not(:first-child):not(:last-child) {
    border-left: none;
    border-right: none;
  }
  ${InlineInputWrapper} &:not(:first-child):not(:last-child) ${StyledButton} {
    ${borderRadius("left", "0px")}
    ${borderRadius("right", "0px")};
  }
  ${InlineInputWrapper} &:first-child:not(:last-child) {
    border-right: none;
  }
  ${InlineInputWrapper} &:first-child:not(:last-child) ${StyledButton} {
    ${borderRadius("right", "0px")};
  }
  ${InlineInputWrapper} &:last-child:not(:first-child) {
    border-left: none;
  }
  ${InlineInputWrapper} &:last-child:not(:first-child) ${StyledButton} {
    ${borderRadius("left", "0px")};
  }

  /*
    Workaround for when the Button component is not direct siblings
    with the other Buttons in the fieldset.
    Need to add the 'fieldset-button-container' class to the top-most container around each button
    that are siblings

    Example:
    <InlineInputWrapper>
      <div className="fieldset-button-container">
        <Button>Button A</Button>
      </div>
      <div className="fieldset-button-container">
        <Button>Button B</Button>
      </div>
      <div className="fieldset-button-container">
        <Button>Button C</Button>
      </div>
    </InlineInputWrapper>
  */
  ${InlineInputWrapper} .fieldset-button-container:not(:first-child):not(:last-child) & {
    border-left: none;
    border-right: none;
  }
  ${InlineInputWrapper} .fieldset-button-container:not(:first-child):not(:last-child) & ${StyledButton} {
    ${borderRadius("left", "0px")}
    ${borderRadius("right", "0px")};
  }
  ${InlineInputWrapper} .fieldset-button-container:first-child:not(:last-child) & {
    border-right: none;
  }
  ${InlineInputWrapper} .fieldset-button-container:first-child:not(:last-child) & ${StyledButton} {
    ${borderRadius("left", "6px")};
    ${borderRadius("right", "0px")};
  }
  ${InlineInputWrapper} .fieldset-button-container:last-child:not(:first-child) & {
    border-left: none;
  }
  ${InlineInputWrapper} .fieldset-button-container:last-child:not(:first-child) & ${StyledButton} {
    ${borderRadius("left", "0px")};
    ${borderRadius("right", "6px")};
  }
`;

const RelativePositioned = system({
  position: "relative",
  minWidth: "100px"
});

const AbsolutePositioned = system({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  ml: "auto",
  mr: "auto",
  minWidth: "100px",
  display: "flex",
  justifyContent: "center"
});

const HideContent = system({
  display: "inline-block"
}).extend`
  visibility: hidden;
`;

function FocusBorderButton({
  focusBorderProps,
  ButtonComponent,
  showFocus,
  processing,
  children,
  loadingSpinnerProps,
  onFocus,
  onBlur,
  onMouseDown,
  ...otherProps
}) {
  const {
    t,
    handleOnMouseDown,
    handleOnFocus,
    handleOnBlur,
    hasFocus
  } = useFocusBorderButton({ onFocus, onBlur, onMouseDown, showFocus });
  // make a copy to possibly update
  let updatableOtherProps = { ...otherProps };
  if (processing) {
    updatableOtherProps = { ...updatableOtherProps, disabled: true };
  }
  return (
    <FocusBorder hasFocus={hasFocus} {...focusBorderProps}>
      <ButtonComponent
        onMouseDown={handleOnMouseDown}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        {...updatableOtherProps}
      >
        {processing ? (
          <RelativePositioned>
            <HideContent>{children}</HideContent>
            <AbsolutePositioned display="flex" justifyContent="center">
              {t("loading")}
              <LoadingSpinner size={theme.space[5]} {...loadingSpinnerProps} />
            </AbsolutePositioned>
          </RelativePositioned>
        ) : (
          <Fragment>{children}</Fragment>
        )}
      </ButtonComponent>
    </FocusBorder>
  );
}

const StyledFocusBorderButton = props => {
  const { buttonStyle } = props;
  const focusBorderColor = theme.buttons[buttonStyle].borderColor;
  return (
    <FocusBorderButton
      focusBorderProps={{
        focusBorderColor: focusBorderColor,
        ...props.focusBorderProps
      }}
      ButtonComponent={StyledButton}
      {...props}
    >
      {props.children}
    </FocusBorderButton>
  );
};

export { StyledFocusBorderButton as Button };

const StyledReadMoreButton = system({
  is: BaseButton,
  width: "100%",
  height: "54px",
  textAlign: "center",
  border: 0,
  borderTop: "1px solid",
  borderColor: "nav.80",
  bg: "nav.100",
  py: 4
}).extend`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  cursor: pointer;
`;

export const ReadMoreButton = props => {
  const focusBorderColor = theme.buttons["tertiary"].borderColor;
  const { t } = useTranslation();
  const buttonText = props.isExpanded
    ? t("buttons.readLess")
    : t("buttons.readAll");
  return (
    <FocusBorderButton
      focusBorderProps={{ focusBorderColor: focusBorderColor, width: "100%" }}
      ButtonComponent={StyledReadMoreButton}
      {...props}
    >
      <P color="secondary.0">
        {buttonText}
        &nbsp;
        <StyledFontAwesomeIcon
          icon={["far", props.isExpanded ? "chevron-up" : "chevron-down"]}
        />
        {props.children}
      </P>
    </FocusBorderButton>
  );
};

const StyledDownloadButton = system({
  is: BaseButton,
  height: "100%",
  textAlign: "center",
  borderColor: "nav.90",
  bg: "nav.100",
  px: 10,
  py: 6
});

export const DownloadButton = props => {
  const focusBorderColor = theme.buttons["tertiary"].borderColor;
  return (
    <FocusBorderButton
      focusBorderProps={{ focusBorderColor: focusBorderColor, width: "100%" }}
      ButtonComponent={StyledDownloadButton}
      {...props}
    >
      {props.children}
    </FocusBorderButton>
  );
};

export const MenuButtonContainer = system({
  is: BaseButton,
  mr: "auto",
  bg: "nav.0"
}).extend`
  ${media.greaterThan("medium")`
        display: none;
    `};
  cursor: pointer;
`;

export const MenuButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <MenuButtonContainer onClick={onClick}>
      <MenuIcon />
      {t("buttons.menu")}
    </MenuButtonContainer>
  );
};

export const StyledMobileSearchButton = system({
  is: BaseButton,
  ml: "auto",
  bg: "nav.0"
}).extend`
  ${media.greaterThan("medium")`
        display: none;
    `};
`;

export const MobileSearchButton = () => {
  const { t } = useTranslation();
  return (
    <StyledMobileSearchButton>
      <SearchIcon />
      {t("buttons.mobileSearch")}
    </StyledMobileSearchButton>
  );
};

const StyledSearchButton = system({
  is: StyledFocusBorderButton,
  width: "44px",
  px: 0
});

export const SearchButton = ({ loading, ...otherProps }) => (
  <StyledSearchButton
    {...otherProps}
    buttonStyle="primary"
    aria-label="Search button"
  >
    {loading ? (
      <LoadingSpinner type="circle" />
    ) : (
      <StyledFontAwesomeIcon icon={["far", "search"]} size="lg" />
    )}
  </StyledSearchButton>
);

export const StyledClearButton = system({
  is: BaseButton,
  border: 1,
  borderColor: "transparent",
  bg: "transparent",
  display: "inline-block"
}).extend`
	vertical-align: middle;
	outline: 0;

  &:after {
    content: "X";
    display: block;
    width: ${themeGet("space.4")}px;
    height: ${themeGet("space.4")}px;
    z-index: ${themeGet("layers.textOverlay", 1)};
    right: ${themeGet("space.7")}px;
    margin: auto;
    text-align: center;
    color: ${themeGet("colors.nav.25")};
    font-weight: ${themeGet("fontWeights.light")};
    font-size: ${themeGet("fontSizes.3")}px;
    cursor: pointer;
  }
`;

export const STYLED_FLOATING_BUTTON_HEIGHT = 60;

const StyledFloatingButton = system({
  is: BaseButton,
  color: "nav.100",
  bg: "nav.10",
  height: STYLED_FLOATING_BUTTON_HEIGHT,
  width: 60,
  boxShadow: `0px 2px 32px 0 rgba(0, 0, 0, 0.16), 0px 1px 6px 0 rgba(0, 0, 0, 0.06)`,
  borderRadius: "50%",
  border: 2,
  borderColor: "nav.100"
}).extend`
  &:hover, &:active {
    cursor: pointer;
  }
`;

export function FloatingButton({ onClick, icon, iconProps, ...props }) {
  const focusBorderColor = theme.buttons["tertiary"].borderColor;
  return (
    <StyledFocusBorderButton
      buttonStyle="secondary"
      focusBorderProps={{ focusBorderColor }}
      ButtonComponent={StyledFloatingButton}
      onClick={onClick}
      {...props}
    >
      <StyledFontAwesomeIcon
        icon={icon}
        size="lg"
        ml={0}
        mr={0}
        color="nav.100"
        {...iconProps}
      />
    </StyledFocusBorderButton>
  );
}
