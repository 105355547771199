import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import PendingDocumentsCSVQuery from "./graphql/PendingDocumentsCSVQuery";
import PendingDocumentsQuery from "./graphql/PendingDocumentsQuery";
import usePendingDocumentsReport from "./hooks";
import SearchBarAndFilters from "./PendingPoliciesFilters";

import { parseVariablesWithDefaultSort } from "pstat-design-system/tables/utils/helpers";
import { environment } from "pstat-anywhere/relay";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import {
  AdvancedReportPage,
  DefaultActionPanel
} from "pstat-anywhere/components/pages/reports";

function PendingDocumentsReport({ route, router, location, labels }) {
  const { t } = useTranslation();
  const { variables, handleBulkApprove, loading } = usePendingDocumentsReport({
    location,
    router,
    route,
    t,
    labels
  });

  const { showAll, pending_approver, bloc, search_query } = variables;
  const tabs = [
    {
      id: "awaiting_activation_mine",
      textKey: "documentReports.awaitingActivation.tabs.mine",
      path: "policy_reports/awaiting_activation/pending"
    },
    {
      id: "awaiting_activation_all",
      textKey: "documentReports.awaitingActivation.tabs.everyone",
      path: "policy_reports/awaiting_activation/pending/all"
    }
  ];

  const tableType = useMemo(
    () => (showAll ? "awaiting_activation_all" : "awaiting_activation_mine"),
    [showAll]
  );
  const parsed_variables = useMemo(() => {
    return parseVariablesWithDefaultSort({
      variables,
      tableType,
      t,
      noDefaultSort: variables?.search_query
    });
  }, [variables, tableType, t]);

  const noContentMessage = showAll
    ? t(
        "documentReports.awaitingActivation.reportContent.everyone.noContentMessage",
        {
          documentLabelPlural: labels.documentLabelPlural.toLowerCase()
        }
      )
    : t(
        "documentReports.awaitingActivation.reportContent.mine.noContentMessage",
        {
          documentLabelPlural: labels.documentLabelPlural.toLowerCase()
        }
      );

  return (
    <QueryRenderer
      environment={environment}
      variables={parsed_variables}
      query={PendingDocumentsQuery}
      render={({ props }) => {
        const viewOnlyPks = props?.pendingDocuments?.viewOnlyPks;
        const isCheckboxDisabled = rowData => {
          if (rowData.revisedFromParent) {
            return "documentReports.awaitingActivation.cannotBulkApproveRevisedDocument";
          } else if (viewOnlyPks && viewOnlyPks.indexOf(rowData.pk) >= 0) {
            return "documentReports.awaitingActivation.cannotBulkApproveCollaborator";
          }
          return null;
        };
        return (
          <AdvancedReportPage
            csvQueryName="pendingDocumentsCsv"
            csvQueryArgs={{ showAll, pending_approver, bloc, search_query }}
            CSVQuery={PendingDocumentsCSVQuery}
            variables={parsed_variables}
            data={props?.pendingDocuments?.documents}
            otherTableProps={{
              viewOnlyPks: props?.pendingDocuments?.viewOnlyPks,
              isCheckboxDisabled
            }}
            noSort={parsed_variables?.search_query && !parsed_variables.sort}
            route={route}
            router={router}
            location={location}
            tabs={tabs}
            headerText={t("documentReports.awaitingActivation.header", {
              documentLabelPlural: labels.documentLabelPlural
            })}
            noContentMessage={noContentMessage}
            showActionButtonPanel={true}
            showTabsPanel={true}
            tableType={tableType}
            renderActionPanel={selectedPolicies => (
              <DefaultActionPanel
                selectedItemPks={selectedPolicies}
                actionTextKey={
                  "documentReports.awaitingActivation.approveButton"
                }
                onActionButtonClick={handleBulkApprove}
                loading={loading}
              />
            )}
            isCheckboxDisabled={isCheckboxDisabled}
            renderFilterPanel={() => (
              <SearchBarAndFilters location={location} router={router} />
            )}
          />
        );
      }}
    />
  );
}

export default withViewer(withLabelContext(PendingDocumentsReport));
