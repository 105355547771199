import { Box, Flex } from "grid-styled";
import { Component, Fragment } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import { themeGet } from "styled-system";

import theme from "../../themes/policystat/theme";

import { Text } from "pstat-design-system/text/paragraph";
import Select from "pstat-design-system/inputs/SelectInput";
import {
  calculateOffset,
  calculatePageCount,
  getInitialPage,
  offsetToCursor
} from "pstat-anywhere/utils/paginationUtils";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

// the nbsp are in here to give some text content so that it aligns properly with display: flex
const PreviousPaginationIcon = () => (
  <Fragment>
    &nbsp;
    <StyledFontAwesomeIcon icon={["far", "chevron-left"]} mx={0} />
    &nbsp;
  </Fragment>
);

const NextPaginationIcon = () => (
  <Fragment>
    &nbsp;
    <StyledFontAwesomeIcon icon={["far", "chevron-right"]} mx={0} />
    &nbsp;
  </Fragment>
);

const ItemCounter = ({ pageNumber, hitsPerPage, totalNumber }) => {
  const { t } = useTranslation();
  const pageStart = pageNumber * hitsPerPage + 1;
  let pageEnd = pageStart + hitsPerPage - 1;
  if (totalNumber < pageEnd) {
    pageEnd = totalNumber;
  }
  let text = t("pagination.itemsCounter.default", {
    pageStart,
    pageEnd,
    totalNumber: totalNumber
  });
  if (totalNumber === 0) {
    text = t("pagination.itemsCounter.empty");
  }
  return <Text>{text}</Text>;
};

const MobilePageSelectorContainer = styled(Flex)`
  border-bottom: ${themeGet("borders.1")};
  border-color: ${themeGet("colors.nav.80")};
  padding-bottom: ${themeGet("space.4")}px;
  width: 100%;
`;

const HitsPerPageSelector = props => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Select value={props.value} onChange={props.onChange}>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Select>
      <Media query={{ minWidth: theme.breakpoints[0] }}>
        {notSmallMobile => (
          <Fragment>
            {notSmallMobile && (
              <Text pl={2}>{t("pagination.hitsPerPage.rowsPerPage")}</Text>
            )}
          </Fragment>
        )}
      </Media>
    </Fragment>
  );
};

class _Pagination extends Component {
  handlePageChange = page => {
    const pageNumber = page.selected;

    // don't need the cursor if we are on the first page
    if (pageNumber === 0) {
      this.props.onPageChange(null);
    } else {
      const offset = calculateOffset(pageNumber, this.props.hitsPerPage);
      const cursor = offsetToCursor(offset);
      this.props.onPageChange(cursor);
    }
  };

  handleHitsPerPageChange = event => {
    const hitsPerPage = parseInt(event.target.value, 10);
    this.props.onHitsPerPageChange(hitsPerPage);
  };

  render() {
    let showPagination = true;
    if (
      this.props.totalNumberOfResults === 0 ||
      this.props.totalNumberOfResults <= this.props.hitsPerPage
    ) {
      showPagination = false;
    }
    const pageNumber = getInitialPage(
      this.props.afterCursor,
      this.props.hitsPerPage
    );
    const onlyShowingPagination =
      showPagination &&
      !this.props.showItemCount &&
      !this.props.onHitsPerPageChange;
    return (
      <Media query={{ minWidth: theme.breakpoints[1] }}>
        {isDesktop =>
          isDesktop ? (
            <Flex
              id="pagination"
              alignItems="center"
              justifyContent={
                onlyShowingPagination ? "center" : "space-between"
              }
            >
              {this.props.showItemCount && (
                <Box>
                  <ItemCounter
                    totalNumber={this.props.totalNumberOfResults}
                    hitsPerPage={this.props.hitsPerPage}
                    pageNumber={pageNumber}
                  />
                </Box>
              )}
              {showPagination && (
                <Box>
                  <ReactPaginate
                    previousLabel={<PreviousPaginationIcon />}
                    nextLabel={<NextPaginationIcon />}
                    pageCount={calculatePageCount(
                      this.props.totalNumberOfResults,
                      this.props.hitsPerPage
                    )}
                    forcePage={pageNumber}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={isDesktop ? 5 : 1}
                    onPageChange={this.handlePageChange}
                    containerClassName={this.props.className}
                    pageClassName={"react-paginate__page"}
                    pageLinkClassName={"react-paginate__page-link"}
                    previousClassName="react-paginate__previous"
                    previousLinkClassName="react-paginate__previous-link"
                    nextClassName="react-paginate__next"
                    nextLinkClassName="react-paginate__next-link"
                    breakClassName="react-paginate__break"
                    nextAriaLabel={this.props.t("nav.paginate.nextPage")}
                    previousAriaLabel={this.props.t(
                      "nav.paginate.previousPage"
                    )}
                  />
                </Box>
              )}
              {this.props.onHitsPerPageChange && (
                <Box>
                  <HitsPerPageSelector
                    value={this.props.hitsPerPage}
                    onChange={this.handleHitsPerPageChange}
                    isDesktop={isDesktop}
                  />
                </Box>
              )}
            </Flex>
          ) : (
            <Flex
              id="pagination"
              alignItems="center"
              flexDirection="column"
              px={4}
            >
              {showPagination && (
                <MobilePageSelectorContainer>
                  <Box m="auto">
                    <ReactPaginate
                      previousLabel={<PreviousPaginationIcon />}
                      nextLabel={<NextPaginationIcon />}
                      pageCount={calculatePageCount(
                        this.props.totalNumberOfResults,
                        this.props.hitsPerPage
                      )}
                      forcePage={pageNumber}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={isDesktop ? 5 : 1}
                      onPageChange={this.handlePageChange}
                      containerClassName={this.props.className}
                      pageClassName={"react-paginate__page"}
                      pageLinkClassName={"react-paginate__page-link"}
                      previousClassName="react-paginate__previous"
                      previousLinkClassName="react-paginate__previous-link"
                      nextClassName="react-paginate__next"
                      nextLinkClassName="react-paginate__next-link"
                      breakClassName="react-paginate__break"
                      nextAriaLabel={this.props.t("nav.paginate.nextPage")}
                      previousAriaLabel={this.props.t(
                        "nav.paginate.previousPage"
                      )}
                    />
                  </Box>
                </MobilePageSelectorContainer>
              )}
              <Flex
                alignItems="center"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                pt={2}
              >
                {this.props.showItemCount && (
                  <Box>
                    <ItemCounter
                      totalNumber={this.props.totalNumberOfResults}
                      hitsPerPage={this.props.hitsPerPage}
                      pageNumber={pageNumber}
                    />
                  </Box>
                )}
                {this.props.onHitsPerPageChange && (
                  <Box>
                    <HitsPerPageSelector
                      value={this.props.hitsPerPage}
                      onChange={this.handleHitsPerPageChange}
                    />
                  </Box>
                )}
              </Flex>
            </Flex>
          )
        }
      </Media>
    );
  }
}

const Pagination = withTranslation()(_Pagination);

const StyledPagination = styled(Pagination)`
  & li {
    display: inline-block;
    margin: 0px 2px 0px 2px;
    color: ${themeGet("colors.nav.0")};

    font-family: ${themeGet("fonts.font")};
    font-size: ${themeGet("fontSizes.2")};
    font-weight: normal;
  }

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: ${themeGet("space.3")}px ${themeGet("space.4")}px
      ${themeGet("space.3")}px ${themeGet("space.4")}px;
    border-radius: ${themeGet("radii.1")}px;
    outline: none;
  }

  & a:focus:not(:active) {
    box-shadow: 0 0 0 1px ${themeGet("colors.nav.100")},
      0 0 0 2px ${themeGet("colors.nav.80")};
  }

  /* currently selected page button */

  & li.react-paginate__page.selected a.react-paginate__page-link {
    border-color: transparent;
    background-image: none;
    background-color: ${themeGet("colors.nav.90")};
    color: ${themeGet("colors.misc.selected-pagination-text")};

    /* hide the focus state for clicks */
    box-shadow: none;
  }

  /* previous, next, and page buttons */

  & a.react-paginate__previous-link,
  & a.react-paginate__next-link,
  & a.react-paginate__page-link {
    border: ${themeGet("borders.1")} ${themeGet("colors.secondary.80")};

    background-image: linear-gradient(
      to bottom,
      ${themeGet("buttons.tertiary.accentColor")},
      ${themeGet("buttons.teriary.accentColor")} 50%,
      ${themeGet("buttons.tertiary.baseColor")} 50%,
      ${themeGet("buttons.tertiary.baseColor")}
    );
  }

  & a.react-paginate__previous-link:hover,
  & a.react-paginate__next-link:hover,
  & a.react-paginate__page-link:hover {
    background-image: linear-gradient(
      to bottom,
      ${themeGet("buttons.tertiary.baseColor")},
      ${themeGet("buttons.tertiary.baseColor")} 50%,
      ${themeGet("buttons.tertiary.accentColor")} 50%,
      ${themeGet("buttons.tertiary.accentColor")}
    );
  }

  & a.react-paginate__previous-link:active,
  & a.react-paginate__next-link:active,
  & a.react-paginate__page-link:active {
    background-color: ${themeGet("buttons.tertiary.baseColor")};
    background-image: none;
  }

  /* previous and next buttons */

  & a.react-paginate__previous-link,
  & a.react-paginate__next-link {
    width: 44px;
  }

  /* ellipsis buttons */

  & li.react-paginate__break a {
    padding-left: ${themeGet("space.2")}px;
    padding-right: ${themeGet("space.2")}px;
  }

  /* Disabled previous/next buttons */

  & .react-paginate__previous.disabled .react-paginate__previous-link,
  & .react-paginate__next.disabled .react-paginate__next-link {
    border-color: transparent;
    background-image: none;
    background-color: ${themeGet("colors.nav.95")};
    color: ${themeGet("colors.nav.80")};
  }
`;

export default StyledPagination;
