/**
 * @flow
 * @relayHash 252d57a0cd91f76f29d0bdda6b19ae61
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VergeAssociatedDocumentsCSVQueryVariables = {||};
export type VergeAssociatedDocumentsCSVQueryResponse = {|
  +documentsWithVergeStandardsCsv: ?{|
    +taskId: ?string
  |}
|};
export type VergeAssociatedDocumentsCSVQuery = {|
  variables: VergeAssociatedDocumentsCSVQueryVariables,
  response: VergeAssociatedDocumentsCSVQueryResponse,
|};
*/


/*
query VergeAssociatedDocumentsCSVQuery {
  documentsWithVergeStandardsCsv {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentsWithVergeStandardsCsv",
    "storageKey": null,
    "args": null,
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VergeAssociatedDocumentsCSVQuery",
  "id": "222d3b900a9d2c28dcbc549d3463e155",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VergeAssociatedDocumentsCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "VergeAssociatedDocumentsCSVQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query VergeAssociatedDocumentsCSVQuery {\n  documentsWithVergeStandardsCsv {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = 'a99055dd6c497c949e079e24bc2c0c1c';
module.exports = node;
