import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation editAttachmentMutation(
    $attachmentPk: Int!
    $title: String!
    $file: Upload
  ) {
    editAttachment(
      attachmentPk: $attachmentPk
      title: $title
      attachmentFile: $file
    ) {
      ok
    }
  }
`;

const editAttachment = (environment, variables, uploadables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    uploadables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default editAttachment;
