// @flow
import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";
import { Badge } from "pstat-design-system/Badge";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { Text } from "pstat-design-system/text";

const INSERT_TAG = "<ins>";
const DELETE_TAG = "<del>";

const ColoredCircle = system({
  is: Flex,
  borderRadius: "50%",
  borderColor: "transparent",
  height: theme.toggleShowChangesColoredCircleSize,
  width: theme.toggleShowChangesColoredCircleSize,
  position: "absolute",
  bottom: theme.space[1]
}).extend`
  right: ${props => props.right};
  left: ${props => props.left};
  transition: all 0.2s ease-out;
`;

type Props = {
  onClick: Function,
  onKeyDown: Function,
  showChanges: boolean
};
function ToggleChangesBadge({ onClick, onKeyDown, showChanges }: Props) {
  const coloredCircleProps = {
    bg: "nav.100",
    ml: showChanges ? 4 : 0
  };
  return (
    <Badge
      id="toggle_show_changes"
      tabIndex="0"
      role="button"
      display="flex"
      pl={1}
      pr={0}
      py={1}
      alignItems="center"
      onClick={onClick}
      onKeyDown={onKeyDown}
      position="relative"
      bg="secondary.0"
      data-testid="toggle-show-changes-badge"
      minWidth={null}
    >
      <StyledFontAwesomeIcon
        icon={["far", "check"]}
        size="sm"
        color="nav.100"
        ml={0}
      />
      <ColoredCircle {...coloredCircleProps} />
      <StyledFontAwesomeIcon
        icon={["far", "times"]}
        size="sm"
        color="nav.100"
      />
    </Badge>
  );
}

type ShowChangesProps = {
  showChanges: boolean,
  title: string,
  toggleShowChanges: Function,
  handleSetShowChangesDiff: Function,
  checkingChangesDiff: boolean,
  showChangesTaskResult: Function
};
const ShowChanges = ({
  showChanges,
  title,
  toggleShowChanges,
  showChangesTaskResult,
  checkingChangesDiff
}: ShowChangesProps) => {
  const { t } = useTranslation();
  const handleToggleShowChangesButtonKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleShowChanges();
    }
  };
  const diffContainsChanges = diff => {
    if (diff.indexOf(INSERT_TAG) > -1 || diff.indexOf(DELETE_TAG) > -1) {
      return true;
    }
    return false;
  };
  const { loading, error, result } = showChangesTaskResult;
  if (loading || checkingChangesDiff) {
    return (
      <Fragment>
        <LoadingSpinner size={theme.space[5]} />
        <Text color="nav.55">
          <Media query={{ minWidth: theme.breakpoints[1] }}>
            {isDesktop =>
              isDesktop ? (
                <Fragment>
                  {t("documentControl.acknowledge.bar.showChanges.loading")}
                </Fragment>
              ) : (
                <Fragment>
                  {t(
                    "documentControl.acknowledge.bar.showChanges.loading.short"
                  )}
                </Fragment>
              )
            }
          </Media>
        </Text>
      </Fragment>
    );
  }
  if (error) {
    return (
      <Text color="misc.show-changes-text">
        <Media query={{ minWidth: theme.breakpoints[1] }}>
          {isDesktop =>
            isDesktop ? (
              <Fragment>
                {t("documentControl.acknowledge.bar.showChanges.error")}
              </Fragment>
            ) : (
              <Fragment>
                {t("documentControl.acknowledge.bar.showChanges.error.short")}
              </Fragment>
            )
          }
        </Media>
      </Text>
    );
  }
  if (!result || !diffContainsChanges(result.diff)) {
    return (
      <Text color="misc.show-changes-text">
        {t("documentControl.acknowledge.bar.showChanges.noChanges")}
      </Text>
    );
  }
  return (
    <Fragment>
      <ToggleChangesBadge
        onClick={toggleShowChanges}
        showChanges={showChanges}
        onKeyDown={handleToggleShowChangesButtonKeyDown}
      />
      <Text color="secondary.0">{title}</Text>
    </Fragment>
  );
};

export default ShowChanges;
