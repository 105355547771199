import parse from "html-react-parser";
import attributesToProps from "html-react-parser/lib/attributes-to-props";
import domToReact from "html-react-parser/lib/dom-to-react";
import moment from "moment";

import { Text } from "../../text";

import { triggerSearchSelectionEvent } from "pstat-anywhere/utils/googleTagManager";
import { getInitialPage } from "pstat-anywhere/utils/paginationUtils";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { NewWindowLink } from "pstat-design-system/Link";
import Link from "pstat-design-system/RouterFocusLink";
import {
  AddAndViewOnesourceButtons,
  Checkbox,
  Edit,
  RemoveAndViewOnesourceButtons,
  SelectAllCheckbox
} from "pstat-design-system/tables/Actions";


export const validateDueDate = date => {
  const now = new Date();
  return {
    valid: moment(date).isSameOrAfter(now)
  };
};

export const wrapToActionCheckbox = (
  onActionClick,
  selectedCheckboxPks,
  getDisabledTooltipTextKey
) => (cellData, { rowData, ...props }) => {
  return {
    ...props,
    pk: rowData.pk,
    ContentComponent: Checkbox,
    onActionClick,
    selectedCheckboxPks,
    style: {
      "min-width": "30px"
    },
    disabledTooltipTextKey:
      getDisabledTooltipTextKey && getDisabledTooltipTextKey(rowData)
  };
};

export const addSelectAllCheckbox = (
  selectAllCheckboxState,
  onSelectAllCheckboxClick
) => (cellData, { rowData, ...props }) => {
  return {
    ...props,
    ContentComponent: SelectAllCheckbox,
    style: {
      "padding-top": 0,
      "padding-bottom": 0,
      "min-width": "30px"
    },
    onSelectAllCheckboxClick,
    selectAllCheckboxState
  };
};

export const wrapAcceptPolicyCheckbox = onActionClick => (
  cellData,
  { rowData, ...props }
) => {
  return {
    ...props,
    pk: rowData.documentImport.pk,
    ContentComponent: Checkbox,
    onActionClick
  };
};

export const wrapToAcceptImportLink = (cellData, { rowData, ...props }) => {
  const { pk } = rowData.documentImport;
  if (pk) {
    return {
      ...props,
      to: `${getRootUrl()}/implementation/accept/${pk}`
    };
  }
  return props;
};

export const wrapToLippincottSearchResult = (
  cellData,
  { rowData, ...props }
) => {
  const options = {
    replace: domNode => {
      if (
        domNode.attribs &&
        domNode.name === "span" &&
        domNode.attribs?.class === "y"
      ) {
        const props = attributesToProps(domNode.attribs);
        return (
          <span style={{ fontWeight: "bold", fontStyle: "italic" }} {...props}>
            {domToReact(domNode.children)}
          </span>
        );
      }
      return domToReact(domNode);
    }
  };

  const data = parse(rowData[props.property], options);
  rowData.synopsis = data;
  return props;
};

export const wrapToEditLinkRevision = (cellData, { rowData, ...props }) => {
  const pk = rowData.pk;
  if (pk && rowData.isEditable) {
    return {
      ...props,
      ContentComponent: Edit,
      to: `${getRootUrl()}/policy/${pk}/edit`
    };
  }
  return { ...props, CellSpan: Text };
};

export const wrapToEditLink = (cellData, { rowData, ...props }) => {
  return {
    ...props,
    ContentComponent: Edit,
    to: `${getRootUrl()}/policy/${rowData.pk}/edit`
  };
};

export const wrapToDocumentLink = (cellData, { rowData, ...props }) => {
  return {
    ...props,
    CellSpan: Link,
    to: `${getRootUrl()}/policy/${rowData.pk}`
  };
};

export const wrapToLink = (cellData, { rowData, ...props }) => {
  return {
    ...props,
    CellSpan: Link,
    to: rowData.link
  };
};

// returns transform function using the getHasDocument and getDocumentPk functions
export const wrapToDocumentLinkIfDocument = (getHasDocument, getDocument) => (
  cellData,
  { rowData, ...props }
) => {
  const hasDocument = getHasDocument(rowData);
  const document = getDocument(rowData);
  let documentLink = document?.link;
  if (!documentLink) {
    documentLink = `${getRootUrl()}/policy/${document?.pk}`;
  }
  return {
    ...props,
    CellSpan: hasDocument ? Link : Text,
    color: hasDocument ? undefined : "nav.25",
    pl: hasDocument ? undefined : 2,
    to: hasDocument ? documentLink : undefined
  };
};

export const wrapToSystemTemplateLink = (cellData, { rowData, ...props }) => {
  const { document } = rowData;
  return {
    ...props,
    CellSpan: Link,
    to: `${getRootUrl()}/policy/${document.pk}`
  };
};

export const wrapToSystemTemplateReviewLink = (
  cellData,
  { rowData, ...props }
) => {
  return {
    ...props,
    CellSpan: Link,
    to: `${getRootUrl()}/template/${rowData.pk}/review`
  };
};

export const wrapToLippincottDocumentLink = (
  cellData,
  { rowData, ...props }
) => {
  return {
    ...props,
    CellSpan: NewWindowLink,
    to: rowData.url
  };
};

export const wrapToDocumentSaveLink = (cellData, { rowData, ...props }) => ({
  ...props,
  to: `${getRootUrl()}/policy/${rowData.savedCopy.pk}`
});

export const wrapToDocumentShowChangesLink = (
  cellData,
  { rowData, ...props }
) => {
  if (rowData.pk) {
    return {
      ...props,
      CellSpan: Link,
      to: `${getRootUrl()}/policy/${rowData.pk}?showchanges=true`
    };
  }
  return { ...props, color: "nav.25", pl: 2 };
};

export const wrapToDocumentApproveLink = (cellData, { rowData, ...props }) => ({
  ...props,
  to: `${getRootUrl()}/policy/${rowData.pk}/approve`
});

export const wrapToApproveOrViewDocumentLink = viewOnlyPks => (
  cellData,
  { rowData, ...props }
) => {
  return viewOnlyPks?.includes(rowData.pk)
    ? {
        ...props,
        to: `${getRootUrl()}/policy/${rowData.pk}?showchanges=true`
      }
    : {
        ...props,
        to: `${getRootUrl()}/policy/${rowData.pk}/approve`
      };
};

export const wrapToLatestDocumentLinkFromSearchPage = ({
  searchQuery,
  totalNumberOfResults,
  afterCursor,
  hitsPerPage
}) => (cellData, { rowData, ...props }) => {
  const documentUrl = `${getRootUrl()}/policy/${rowData.pk}/latest`;
  const pageNumber = getInitialPage(afterCursor, hitsPerPage);
  const resultNumber = hitsPerPage * pageNumber + props.rowIndex;
  const queryString = window.location.search.slice(1);
  const eventArgs = [
    searchQuery,
    queryString,
    documentUrl,
    rowData.pk,
    rowData.name,
    resultNumber,
    rowData.category.name,
    totalNumberOfResults
  ];
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      triggerSearchSelectionEvent(...eventArgs);
    }
  };
  return {
    ...props,
    to: documentUrl,
    onClick: () => triggerSearchSelectionEvent(...eventArgs),
    onKeyDown: handleKeyDown
  };
};

export const wrapToLatestDocumentLink = (cellData, { rowData, ...props }) => {
  return {
    ...props,
    to: `${getRootUrl()}/policy/${rowData.pk}/latest`
  };
};

export const wrapToDocumentLineLink = (cellData, { rowData, ...props }) => ({
  ...props,
  to: `${getRootUrl()}/acknowledgment/line/${rowData.pk}`
});

export const wrapToDocumentOrLineLink = (cellData, { rowData, ...props }) => {
  let url = `${getRootUrl()}/acknowledgment/line/${rowData.line.pk}`;
  const remainingDocsToAcknowledge =
    rowData.totalAssigned - rowData.totalAcknowledged;
  if (remainingDocsToAcknowledge === 1) {
    url = `${getRootUrl()}/policy/${rowData.pk}`;
  }
  return {
    ...props,
    to: url
  };
};

export const wrapToBlocLink = (cellData, { rowData, ...props }) => {
  return {
    ...props,
    CellSpan: Link,
    to: `${getRootUrl()}/bloc/${rowData.pk}`
  };
};

export const wrapToViewDuplicatesLink = (cellData, { rowData, ...props }) => {
  return {
    ...props,
    CellSpan: Link,
    to: rowData.url
  };
};

export const wrapToCategorySearchResultsLink = (
  cellData,
  { rowData, ...props }
) => {
  const { pk } = rowData;
  if (pk) {
    return {
      ...props,
      to: `${getRootUrl()}/search/?category=${pk}`
    };
  }
  return props;
};

export const frozen = (headerLabel, obj) => {
  return { frozen: true, ...obj };
};

export const onesourceResultsActions = onClickAdd => (
  cellData,
  { rowData, ...props }
) => {
  return {
    ContentComponent: props => (
      <AddAndViewOnesourceButtons
        onClickAdd={() => onClickAdd(rowData)}
        viewUrl={rowData.viewUrl}
        {...props}
      />
    ),
    ...props
  };
};

export const onesourceDocumentsActions = onClickRemove => (
  cellData,
  { rowData, ...props }
) => {
  const documentId = rowData.oneSourceDocumentId;
  return {
    ContentComponent: props => (
      <RemoveAndViewOnesourceButtons
        onClickRemove={() => onClickRemove(documentId)}
        viewUrl={rowData.viewUrl}
        {...props}
      />
    ),
    ...props
  };
};
