import graphql from "babel-plugin-relay/macro";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";

import { ContentContainer } from "pstat-anywhere/components/pages";
import Attachments from "pstat-anywhere/components/system_document_templates/view_template/Attachments";
import Content from "pstat-anywhere/components/system_document_templates/view_template/Content";
import StatusBar from "pstat-anywhere/components/system_document_templates/view_template/StatusBar";
import TemplateProperties from "pstat-anywhere/components/system_document_templates/view_template/TemplateProperties";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { PageWrapper } from "pstat-anywhere/utils/pageWrappers";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { SuccessBanner } from "pstat-design-system/banner";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import { Text } from "pstat-design-system/text";

const ViewTemplatePage = props => {
  const template = props?.systemDocumentTemplateView?.systemDocumentTemplate;
  return <ViewTemplatePageWrapper template={template} {...props} />;
};

export default ViewTemplatePage;

const _ViewTemplatePageWrapper = ({ template, ...otherProps }) => (
  <PageWrapper
    component={ViewTemplate}
    documentName={template ? template.name : ""}
    queryName="systemDocumentTemplateView"
    titleKey="systemDocumentTemplates.view.title"
    showLoading={!template}
    template={template}
    {...otherProps}
  />
);

const ViewTemplatePageWrapper = createFragmentContainer(
  _ViewTemplatePageWrapper,
  graphql`
    fragment ViewTemplatePage_template on SystemDocumentTemplateType {
      name
      ...StatusBar_template
      ...TemplateProperties_template
      ...Content_template
      ...Attachments_template
    }
  `
);

const ViewTemplate = ({ template, location }) => {
  const showConvertSuccess = location.query?.converted;
  return (
    <ViewTemplateContent
      template={template}
      showConvertSuccess={showConvertSuccess}
    />
  );
};

export const ViewTemplateContent = ({ template, showConvertSuccess }) => (
  <ContentContainer id="view-content" maxWidth={theme.breakpoints[2]}>
    {showConvertSuccess && (
      <ConvertToTemplateSuccessBanner template={template} />
    )}
    <StatusBar template={template} />
    <TemplateProperties template={template} />
    <Content template={template} />
    <Attachments template={template} />
  </ContentContainer>
);

const ConvertToTemplateSuccessBanner = ({ template }) => {
  const { t } = useTranslation();
  const labels = useLabels();
  const documentLabel = labels.documentLabel.toLowerCase();
  const templateName = template.name;
  return (
    <SuccessBanner width={null} mt={5} mb={1} mx={3}>
      <Text>
        {t("systemDocumentTemplates.view.convertSuccess.message", {
          templateName,
          documentLabel
        })}
        <RouterFocusLink
          to={`${getRootUrl()}/template/manage`}
          textDecoration="underline"
        >
          {t("systemDocumentTemplates.view.convertSuccess.manageLinkText")}
        </RouterFocusLink>
      </Text>
    </SuccessBanner>
  );
};
