import { Box, Flex } from "grid-styled";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import { AddAttachment, fetchAttachments } from "./Attachments";
import undoMSWordUploadMutation from "./undoMSWordUploadMutation";
import uploadMSWordDocument from "./uploadMSWordMutation";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import {
  DOCUMENT_CONTENT_TYPE,
  DOCUMENT_SAVE_CONTENT_TYPE
} from "pstat-anywhere/utils/document";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import { Link } from "pstat-design-system/Link";
import { createSuccessMessage } from "pstat-design-system/message/manageMessages";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


const UnderlinedText = system({
  is: Text
}).extend`
  text-decoration: underline;
`;

const ImportSuccessMessage = props => {
  const { t } = useTranslation();
  return (
    <Text color="nav.100">
      {t("documentControl.create.importFromWord.successToast")}{" "}
      <Link onClick={props.onClick}>
        <UnderlinedText color="nav.100">
          ({t("documentControl.create.importFromWord.undo")})
        </UnderlinedText>
      </Link>
    </Text>
  );
};

const ImportFromWordModal = ({
  isOpen,
  html,
  onClose,
  onHtmlChange,
  documentSave,
  setAttachments,
  document,
  isOverridePage
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [uploadHtml, setUploadHtml] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const originalHtml = useRef(null);
  const deletedAttachments = useRef(null);
  const newAttachmentIds = useRef(null);

  const isEnterKey = event => event.key === "Enter";
  const task = useTaskResult(taskId);

  const handleClose = useCallback(() => {
    setTaskId(null);
    setLoading(false);
    setFile(null);
    setUploadHtml(null);
    onClose();
    setErrorMessage(null);
    task.reset();
  }, [onClose, task]);

  const handleSubmitUpload = () => {
    const variables = {
      input: {
        html: html,
        documentFile: file
      }
    };
    if (isOverridePage) {
      variables.input.objectId = document.pk;
      variables.input.isDocument = true;
    } else {
      variables.input.objectId = documentSave.pk;
    }
    const uploadables = {
      documentFile: file
    };
    setLoading(true);
    setErrorMessage(null);
    uploadMSWordDocument(
      environment,
      variables,
      uploadables,
      (data, errors) => {
        if (errors) {
          throw new Error();
        }
        if (data.uploadMsword.ok) {
          const taskId = data.uploadMsword.taskId;
          setTaskId(taskId);
        } else {
          setLoading(false);
          setErrorMessage(data.uploadMsword.reasons.message);
        }
      }
    );
  };
  const handleSubmitUploadKeyDown = event => {
    if (isEnterKey(event)) {
      handleSubmitUpload(event);
    }
  };
  const undoImport = () => {
    const variables = {
      input: {
        deletedAttachments: JSON.stringify(deletedAttachments.current),
        newAttachmentIds: newAttachmentIds.current
      }
    };
    if (isOverridePage) {
      variables.input.objectId = document.pk;
      variables.input.isDocument = true;
    } else {
      variables.input.objectId = documentSave.pk;
    }
    undoMSWordUploadMutation(environment, variables, (data, errors) => {
      if (errors) {
        throw new Error();
      }
      if (data.undoMswordUpload.ok) {
        onHtmlChange(originalHtml.current);
        if (isOverridePage) {
          fetchAttachments(document.pk, DOCUMENT_CONTENT_TYPE, setAttachments);
        } else {
          fetchAttachments(
            documentSave.pk,
            DOCUMENT_SAVE_CONTENT_TYPE,
            setAttachments
          );
        }
      }
    });
  };
  const handleUpdateContent = () => {
    onHtmlChange(task.result.html);
    const SuccessMessage = props => {
      return <ImportSuccessMessage onClick={undoImport} />;
    };
    createSuccessMessage(SuccessMessage);
    if (isOverridePage) {
      fetchAttachments(document.pk, DOCUMENT_CONTENT_TYPE, setAttachments);
    } else {
      fetchAttachments(
        documentSave.pk,
        DOCUMENT_SAVE_CONTENT_TYPE,
        setAttachments
      );
    }
    handleClose();
  };
  const handleUpdateContentKeyDown = event => {
    if (isEnterKey(event)) {
      handleUpdateContent(event);
    }
  };
  const handleCancelKeyDown = event => {
    if (isEnterKey(event)) {
      handleClose();
    }
  };
  const handleFileChange = file => {
    setFile(file);
  };

  useEffect(() => {
    if (task.result && task.result.success) {
      originalHtml.current = html;
      deletedAttachments.current = task.result.deleted_attachments;
      newAttachmentIds.current = task.result.new_attachment_ids;
      setUploadHtml(task.result.html);
    }
  }, [task.result, handleClose, onHtmlChange, html]);

  const filename = file ? file.name : null;
  const formErrors = errorMessage ? [errorMessage] : null;
  return (
    <Modal
      contentLabel={t("documentControl.create.importFromWord.header")}
      isOpen={isOpen}
      onRequestClose={handleClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              {uploadHtml ? (
                <Button
                  buttonStyle="secondary"
                  onClick={handleUpdateContent}
                  onKeyDown={handleUpdateContentKeyDown}
                >
                  {t("documentControl.create.importFromWord.updateContent")}
                </Button>
              ) : (
                <Button
                  buttonStyle="secondary"
                  onClick={handleSubmitUpload}
                  onKeyDown={handleSubmitUploadKeyDown}
                  disabled={!file}
                  processing={loading || task.loading}
                >
                  {t("documentControl.create.importFromWord.upload")}
                </Button>
              )}
            </Box>
            <Box>
              <Button
                buttonStyle="tertiary"
                onClick={handleClose}
                onKeyDown={handleCancelKeyDown}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} py={7}>
        {uploadHtml ? (
          <Text>
            {t("documentControl.create.importFromWord.successMessage")}
          </Text>
        ) : (
          <AddAttachment
            fileID="word_import"
            onFileChange={handleFileChange}
            filename={filename}
            disabled={loading || task.loading}
            errors={formErrors}
          />
        )}
      </Flex>
    </Modal>
  );
};

export default withLabelContext(ImportFromWordModal);
