// Dynamically set the public path used for the bundle files
// This allows us to set the path in the django template where we know the static files URL
// see https://v4.webpack.js.org/configuration/output/#outputpublicpath for info on this variable
// Note: the webpack config publicPath at the time the bundle is built is still going to use the package.json homepage value
// so you can't leave it out of the config
// https://github.com/facebook/create-react-app/issues/6024#issuecomment-446830606

// This needs to be in its own file so that it can be the first code run in index.js
// The imports always run before other code, so it needs to be imported
if (process.env.NODE_ENV === "production") {
  // eslint-disable-next-line
  __webpack_public_path__ = window.WEBPACK_PUBLIC_PATH;
}
