import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Header from "./Header";
import SignaturesTable from "./SignaturesTable";

import { H2 } from "pstat-design-system/text/headings";
import Panel from "pstat-design-system/Panel";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const StyledFlex = styled(Flex)`
  cursor: pointer;
`;

export default function ApprovalSignatureSection({
  document: { approvalSignatures, pastApprovalSignatures },
  isOpenPastApproval,
  togglePastApproval,
  showStepDescription = true
}) {
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      togglePastApproval();
    }
  }
  const { t } = useTranslation();
  return (
    <Panel
      renderHeader={() => <Header showStepDescription={showStepDescription} />}
      id="approval-signatures-panel"
    >
      <SignaturesTable
        signatures={approvalSignatures}
        showStepDescription={showStepDescription}
      />
      {pastApprovalSignatures && pastApprovalSignatures.length > 0 && (
        <Fragment>
          <StyledFlex
            alignItems="center"
            flexWrap="wrap"
            tabIndex="0"
            onClick={togglePastApproval}
            onKeyDown={handleKeyDown}
            mt={10}
            mb={6}
          >
            <StyledFontAwesomeIcon
              icon={
                isOpenPastApproval
                  ? ["far", "minus-square"]
                  : ["far", "plus-square"]
              }
              color="secondary.0"
              size="lg"
            />
            <H2 color="secondary.0" display="inline" fontWeight="normal">
              {t("documentControl.view.approvalSignatures.olderHeader")}
            </H2>
          </StyledFlex>
          {isOpenPastApproval && (
            <SignaturesTable
              signatures={pastApprovalSignatures}
              showStepDescription={showStepDescription}
            />
          )}
        </Fragment>
      )}
    </Panel>
  );
}
