/**
 * @flow
 * @relayHash d3139b4d17f45e152502e9c9c2e5fab4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SessionQueryVariables = {|
  path?: ?string
|};
export type SessionQueryResponse = {|
  +currentTenant: ?{|
    +pk: ?number,
    +name: string,
    +subdomain: ?string,
    +showApplicability: ?boolean,
    +isIsland: ?boolean,
    +settings: ?{|
      +labels: ?{|
        +approvedDateLabel: ?string,
        +authorLabel: ?string,
        +authorLabelPlural: ?string,
        +categoryLabel: ?string,
        +categoryLabelPlural: ?string,
        +documentLabel: ?string,
        +documentLabelPlural: ?string,
        +effectiveDateLabel: ?string,
        +expirationDateLabel: ?string,
        +guestLabel: ?string,
        +originationDateLabel: ?string,
        +referencesLabel: ?string,
        +referencesLabelPlural: ?string,
        +revisionDateLabel: ?string,
      |},
      +documentsWithStandardsReportEnabled: ?boolean,
      +enableAllBlocs: boolean,
      +enableEditWithWord: boolean,
      +standardsAndRegulationsEnabled: ?boolean,
      +onesourceIntegrationEnabled: ?boolean,
      +vergeIntegrationEnabled: ?boolean,
      +enableEnterprise: boolean,
    |},
    +samlSettings: ?{|
      +isActive: ?boolean,
      +location: ?string,
      +samlRequest: ?string,
    |},
    +documentSettings: ?{|
      +showCommittee: ?boolean,
      +approvedDateShow: boolean,
      +authorShow: boolean,
      +departmentShow: boolean,
      +effectiveDateShow: boolean,
      +expirationDateShow: boolean,
      +originationDateShow: boolean,
      +referencesShow: ?boolean,
      +revisionDateShow: boolean,
      +footerHtml: string,
      +headerHtml: string,
      +editorStyles: string,
      +viewStyles: string,
    |},
    +hasScheduledEffectiveDateEnabled: ?boolean,
    +acknowledgmentsEnabled: ?boolean,
    +customerSettings: ?{|
      +enableEnterprisePolicySharing: boolean
    |},
    +loadHomeQueriesIndependently: ?boolean,
  |},
  +viewer: ?{|
    +pk: ?number,
    +algoliaApplicationId: ?string,
    +algoliaDocumentsIndexName: ?string,
    +algoliaAccessKey: ?string,
    +isGuest: ?boolean,
    +isStaff: boolean,
    +canReverseProxyLogin: ?boolean,
    +userAuth: ?{|
      +isSiteAdmin: ?boolean,
      +canCreateDocuments: ?boolean,
      +canManageCertifications: ?boolean,
      +canViewReviewSetList: ?boolean,
      +canAcceptTemplates: ?boolean,
    |},
    +forceChooseHomeTenant: ?boolean,
    +userDisplay: ?string,
    +hasOnesourceToken: ?boolean,
    +username: string,
  |},
  +settings: ?{|
    +recaptchaPublicKey: ?string,
    +showChangesWarningBanner: ?string,
    +mixpanelApiToken: ?string,
  |},
|};
export type SessionQuery = {|
  variables: SessionQueryVariables,
  response: SessionQueryResponse,
|};
*/


/*
query SessionQuery(
  $path: String
) {
  currentTenant {
    pk
    name
    subdomain
    showApplicability
    isIsland
    settings {
      labels {
        approvedDateLabel
        authorLabel
        authorLabelPlural
        categoryLabel
        categoryLabelPlural
        documentLabel
        documentLabelPlural
        effectiveDateLabel
        expirationDateLabel
        guestLabel
        originationDateLabel
        referencesLabel
        referencesLabelPlural
        revisionDateLabel
      }
      documentsWithStandardsReportEnabled
      enableAllBlocs
      enableEditWithWord
      standardsAndRegulationsEnabled
      onesourceIntegrationEnabled
      vergeIntegrationEnabled
      enableEnterprise
      id
    }
    samlSettings {
      isActive
      location
      samlRequest(path: $path)
      id
    }
    documentSettings {
      showCommittee
      approvedDateShow
      authorShow
      departmentShow
      effectiveDateShow
      expirationDateShow
      originationDateShow
      referencesShow
      revisionDateShow
      footerHtml
      headerHtml
      editorStyles
      viewStyles
      id
    }
    hasScheduledEffectiveDateEnabled
    acknowledgmentsEnabled
    customerSettings {
      enableEnterprisePolicySharing
      id
    }
    loadHomeQueriesIndependently
    id
  }
  viewer {
    pk
    algoliaApplicationId
    algoliaDocumentsIndexName
    algoliaAccessKey
    isGuest
    isStaff
    canReverseProxyLogin
    userAuth {
      isSiteAdmin
      canCreateDocuments
      canManageCertifications
      canViewReviewSetList
      canAcceptTemplates
    }
    forceChooseHomeTenant
    userDisplay(userDisplayType: SHORT_FORWARD, showStaff: true)
    hasOnesourceToken
    username
    id
  }
  settings {
    recaptchaPublicKey
    showChangesWarningBanner
    mixpanelApiToken
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "path",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subdomain",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showApplicability",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isIsland",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "labels",
  "storageKey": null,
  "args": null,
  "concreteType": "LabelsType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "approvedDateLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "authorLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "authorLabelPlural",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "categoryLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "categoryLabelPlural",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "documentLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "documentLabelPlural",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "effectiveDateLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "expirationDateLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "guestLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "originationDateLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "referencesLabel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "referencesLabelPlural",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "revisionDateLabel",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "documentsWithStandardsReportEnabled",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enableAllBlocs",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enableEditWithWord",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "standardsAndRegulationsEnabled",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "onesourceIntegrationEnabled",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "vergeIntegrationEnabled",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enableEnterprise",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isActive",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "location",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "samlRequest",
  "args": [
    {
      "kind": "Variable",
      "name": "path",
      "variableName": "path",
      "type": "String"
    }
  ],
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showCommittee",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvedDateShow",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "authorShow",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "departmentShow",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDateShow",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDateShow",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "originationDateShow",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "referencesShow",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDateShow",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "footerHtml",
  "args": null,
  "storageKey": null
},
v27 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "headerHtml",
  "args": null,
  "storageKey": null
},
v28 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "editorStyles",
  "args": null,
  "storageKey": null
},
v29 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewStyles",
  "args": null,
  "storageKey": null
},
v30 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasScheduledEffectiveDateEnabled",
  "args": null,
  "storageKey": null
},
v31 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledgmentsEnabled",
  "args": null,
  "storageKey": null
},
v32 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enableEnterprisePolicySharing",
  "args": null,
  "storageKey": null
},
v33 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "loadHomeQueriesIndependently",
  "args": null,
  "storageKey": null
},
v34 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "algoliaApplicationId",
  "args": null,
  "storageKey": null
},
v35 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "algoliaDocumentsIndexName",
  "args": null,
  "storageKey": null
},
v36 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "algoliaAccessKey",
  "args": null,
  "storageKey": null
},
v37 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isGuest",
  "args": null,
  "storageKey": null
},
v38 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isStaff",
  "args": null,
  "storageKey": null
},
v39 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "canReverseProxyLogin",
  "args": null,
  "storageKey": null
},
v40 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "userAuth",
  "storageKey": null,
  "args": null,
  "concreteType": "UserAuth",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isSiteAdmin",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "canCreateDocuments",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "canManageCertifications",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "canViewReviewSetList",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "canAcceptTemplates",
      "args": null,
      "storageKey": null
    }
  ]
},
v41 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "forceChooseHomeTenant",
  "args": null,
  "storageKey": null
},
v42 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userDisplay",
  "args": [
    {
      "kind": "Literal",
      "name": "showStaff",
      "value": true,
      "type": "Boolean"
    },
    {
      "kind": "Literal",
      "name": "userDisplayType",
      "value": "SHORT_FORWARD",
      "type": "UserDisplayType"
    }
  ],
  "storageKey": "userDisplay(showStaff:true,userDisplayType:\"SHORT_FORWARD\")"
},
v43 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasOnesourceToken",
  "args": null,
  "storageKey": null
},
v44 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v45 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "settings",
  "storageKey": null,
  "args": null,
  "concreteType": "SettingsType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "recaptchaPublicKey",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "showChangesWarningBanner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mixpanelApiToken",
      "args": null,
      "storageKey": null
    }
  ]
},
v46 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SessionQuery",
  "id": "b63b7872967e818ef365a3fc9e2ce4ee",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SessionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantSettingsType",
            "plural": false,
            "selections": [
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "samlSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "SAMLSettingsType",
            "plural": false,
            "selections": [
              v14,
              v15,
              v16
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": false,
            "selections": [
              v17,
              v18,
              v19,
              v20,
              v21,
              v22,
              v23,
              v24,
              v25,
              v26,
              v27,
              v28,
              v29
            ]
          },
          v30,
          v31,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customerSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerSettingsType",
            "plural": false,
            "selections": [
              v32
            ]
          },
          v33
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": false,
        "selections": [
          v1,
          v34,
          v35,
          v36,
          v37,
          v38,
          v39,
          v40,
          v41,
          v42,
          v43,
          v44
        ]
      },
      v45
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SessionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          v1,
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantSettingsType",
            "plural": false,
            "selections": [
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v46
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "samlSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "SAMLSettingsType",
            "plural": false,
            "selections": [
              v14,
              v15,
              v16,
              v46
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": false,
            "selections": [
              v17,
              v18,
              v19,
              v20,
              v21,
              v22,
              v23,
              v24,
              v25,
              v26,
              v27,
              v28,
              v29,
              v46
            ]
          },
          v30,
          v31,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "customerSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "CustomerSettingsType",
            "plural": false,
            "selections": [
              v32,
              v46
            ]
          },
          v33,
          v46
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": false,
        "selections": [
          v1,
          v34,
          v35,
          v36,
          v37,
          v38,
          v39,
          v40,
          v41,
          v42,
          v43,
          v44,
          v46
        ]
      },
      v45
    ]
  }
};
})();
node.text = "query SessionQuery(\n  $path: String\n) {\n  currentTenant {\n    pk\n    name\n    subdomain\n    showApplicability\n    isIsland\n    settings {\n      labels {\n        approvedDateLabel\n        authorLabel\n        authorLabelPlural\n        categoryLabel\n        categoryLabelPlural\n        documentLabel\n        documentLabelPlural\n        effectiveDateLabel\n        expirationDateLabel\n        guestLabel\n        originationDateLabel\n        referencesLabel\n        referencesLabelPlural\n        revisionDateLabel\n      }\n      documentsWithStandardsReportEnabled\n      enableAllBlocs\n      enableEditWithWord\n      standardsAndRegulationsEnabled\n      onesourceIntegrationEnabled\n      vergeIntegrationEnabled\n      enableEnterprise\n      id\n    }\n    samlSettings {\n      isActive\n      location\n      samlRequest(path: $path)\n      id\n    }\n    documentSettings {\n      showCommittee\n      approvedDateShow\n      authorShow\n      departmentShow\n      effectiveDateShow\n      expirationDateShow\n      originationDateShow\n      referencesShow\n      revisionDateShow\n      footerHtml\n      headerHtml\n      editorStyles\n      viewStyles\n      id\n    }\n    hasScheduledEffectiveDateEnabled\n    acknowledgmentsEnabled\n    customerSettings {\n      enableEnterprisePolicySharing\n      id\n    }\n    loadHomeQueriesIndependently\n    id\n  }\n  viewer {\n    pk\n    algoliaApplicationId\n    algoliaDocumentsIndexName\n    algoliaAccessKey\n    isGuest\n    isStaff\n    canReverseProxyLogin\n    userAuth {\n      isSiteAdmin\n      canCreateDocuments\n      canManageCertifications\n      canViewReviewSetList\n      canAcceptTemplates\n    }\n    forceChooseHomeTenant\n    userDisplay(userDisplayType: SHORT_FORWARD, showStaff: true)\n    hasOnesourceToken\n    username\n    id\n  }\n  settings {\n    recaptchaPublicKey\n    showChangesWarningBanner\n    mixpanelApiToken\n  }\n}\n";
(node/*: any*/).hash = '8994d137ec246a3cd6ab5603ac972c93';
module.exports = node;
