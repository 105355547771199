import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import Media from "react-media";

import theme from "../../../../themes/policystat/theme";

import FilterSet from "./Filters";

import { H2 } from "pstat-design-system/text";

const HistoryCardHeader = props => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <H2 color="nav.0" fontWeight="normal">
          {t("documentControl.view.historyCardHeader")}
        </H2>
      </Box>
      <Media query={{ minWidth: theme.breakpoints[1] }}>
        <Flex>
          <FilterSet {...props} />
        </Flex>
      </Media>
    </Flex>
  );
};

export default HistoryCardHeader;
