import { easing, tween } from "popmotion";
import { Component, Fragment, createRef } from "react";
import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const ButtonContainer = system(
  {
    position: "absolute",
    top: 0,
    height: "100%"
  },
  "display",
  "right",
  "left"
).extend`
  cursor: pointer;
`;

const DefaultButtonWrapper = system({
  bg: "nav.90",
  width: 36,
  height: "100%"
});

const IconButton = system({
  is: StyledFontAwesomeIcon,
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  color: "secondary.0",
  m: "auto"
});

const OverflowWrapper = system({
  display: "flex",
  width: "100%",
  height: "100%"
}).extend`
  overflow-y: ${props => (props.overflow ? "hidden" : "visible")};
`;

export default class HorizontalScrollControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayLeftButton: false,
      displayRightButton: true,
      maxScroll: 0
    };
    this.buttonHandler = this.buttonHandler.bind(this);
    this.updateMaxScroll = this.updateMaxScroll.bind(this);
    this.displayHandler = this.displayHandler.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.overflowRef = createRef();
    this.navOptionsRef = createRef();

    this.scrollTimeout = null;
  }

  componentDidMount() {
    this.updateMaxScroll(3.75);
  }

  // For some reason the DOM Elements difference has this wierd 3.75 offset on
  // mount but not any other time.
  updateMaxScroll(offset) {
    let { displayLeftButton, displayRightButton, maxScroll } = this.state;
    let navOptionsWidth = 0,
      overflowWrapperWidth = 0;
    // This optional chaining check needed only for tests purposes
    navOptionsWidth = this.navOptionsRef?.current?.getBoundingClientRect()
      ?.width;
    overflowWrapperWidth = this.overflowRef?.current?.getBoundingClientRect()
      ?.width;

    // The difference between the OverflowWrapper and NavOption elements widths
    // is the maximum amount you can scroll within the OverflowWrapper
    let diff = navOptionsWidth - overflowWrapperWidth - offset;
    // 2. missing 5 pixels on either side is not worthy of having buttons
    if (diff <= 20) {
      displayLeftButton = false;
      displayRightButton = false;
    }
    if (maxScroll !== diff) {
      this.setState({
        maxScroll: diff,
        displayLeftButton,
        displayRightButton
      });
    }
  }

  // OnClick determine how far scroll you are and continue scrolling based on
  // the direction given.
  buttonHandler(direction) {
    // make sure the sizes have not changed
    this.updateMaxScroll(0);
    let scrollArea = this.overflowRef.current;
    let current = scrollArea.scrollLeft;
    // Step allows for you to change the distance scrolled each click
    let step = this.state.maxScroll;
    let next = current - step;
    // If we allow for 0 > next > MaxScroll then tween will continue past
    // the values while the scrollArea stops.  This causes The animation appear
    // finished sooner than anticipated.  ( Causes a laggy scrollbar )
    if (next < 0) next = 0;

    if (direction === "scrollLeft") {
      next = current + step;
      if (next > this.state.maxScroll) next = this.state.maxScroll;
    }
    // This handles animation allowing for a smoother scroll feel
    tween({
      from: current,
      to: next,
      duration: 200,
      delay: 0,
      ease: easing.easeOut
    }).start({
      update: v => (scrollArea.scrollLeft = v),
      complete: () => {
        this.displayHandler();
      }
    });
  }
  // Depending on the distance we have scrolled the left and right button will
  // either be displayed or hidden.
  displayHandler() {
    let scrollArea = this.overflowRef.current;
    let displayLeftButton = true;
    let displayRightButton = true;

    if (this.state.maxScroll <= 20) {
      displayLeftButton = false;
      displayRightButton = false;
    } else if (scrollArea.scrollLeft <= 20) {
      displayLeftButton = false;
      displayRightButton = true;
    } else if (scrollArea.scrollLeft >= this.state.maxScroll - 20) {
      displayLeftButton = true;
      displayRightButton = false;
    }
    this.setState({
      displayLeftButton,
      displayRightButton
    });
  }

  handleScroll() {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.displayHandler();
    }, 200);
  }

  render() {
    const { LeftButton, RightButton } = this.props;
    return (
      <Fragment>
        <ButtonContainer
          display={this.state.displayLeftButton ? "block" : "none"}
          left={this.props.leftOffset || 0}
          onClick={() => this.buttonHandler("scrollRight")}
        >
          {LeftButton ? (
            <LeftButton />
          ) : (
            <DefaultButtonWrapper>
              <IconButton icon={["far", "chevron-left"]} size="sm" />
            </DefaultButtonWrapper>
          )}
        </ButtonContainer>
        <OverflowWrapper
          className="horizontal-scroll-overflow-wrapper" // needed got mocking ref in snapshot tests
          innerRef={this.overflowRef}
          onTouchEnd={this.displayHandler}
          onScroll={this.handleScroll}
          overflow={this.state.maxScroll > 20}
        >
          {this.props.render(this.navOptionsRef)}
        </OverflowWrapper>
        <ButtonContainer
          display={this.state.displayRightButton ? "block" : "none"}
          right={0}
          onClick={() => this.buttonHandler("scrollLeft")}
        >
          {RightButton ? (
            <RightButton />
          ) : (
            <DefaultButtonWrapper>
              <IconButton icon={["far", "chevron-right"]} size="sm" />
            </DefaultButtonWrapper>
          )}
        </ButtonContainer>
      </Fragment>
    );
  }
}
