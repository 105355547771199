import { useTranslation } from "react-i18next";

import { DaysFilterDropdown } from "pstat-anywhere/components/document_reports/expiring/components/DaysFilterDropdown";

export const WaitingSinceFilterDropdown = props => {
  const { t } = useTranslation();
  const filterName = t(
    "documentReports.expiring.waitingSinceFilterDropdown.title"
  );
  const expandedFilterName = t(
    "documentReports.expiring.waitingSinceFilterDropdown.expandedTitle"
  );
  const inputLabel = t(
    "documentReports.expiring.waitingSinceFilterDropdown.inputLabel"
  );
  const buttonTitle = t("documentReports.expiring.clearButton.title");
  const inputName = "daysWaitingSince";
  const inputId = "days_waiting_since";
  const daysFilterProps = {
    ...props,
    filterName,
    expandedFilterName,
    inputLabel,
    buttonTitle,
    inputName,
    inputId
  };
  return <DaysFilterDropdown {...daysFilterProps} />;
};
