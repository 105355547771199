import graphql from "babel-plugin-relay/macro";

const VergeAssociatedDocumentsCSVQuery = graphql`
  query VergeAssociatedDocumentsCSVQuery {
    documentsWithVergeStandardsCsv {
      taskId
    }
  }
`;

export default VergeAssociatedDocumentsCSVQuery;
