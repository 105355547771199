import { Box } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import acceptImport from "../accept/acceptImportMutation";
import rejectImport from "../reject/rejectImportMutation";

import PolicyActionCommentSection from "pstat-anywhere/components/document_control/policy_action_comment_section/PolicyActionCommentSection";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { LabelField } from "pstat-design-system/inputs/Labels";
import { Link } from "pstat-design-system/Link";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


const _PolicyAcceptRejectSection = ({
  labels,
  documentPk,
  documentName,
  location,
  router
}) => {
  const [acceptView, setAcceptView] = useState(true);
  const { t } = useTranslation();
  const { documentLabel, documentLabelPlural } = labels;

  const inputBuilder = (documentPk, comment) => ({
    importPk: documentPk,
    comment
  });

  const RejectButton = () => (
    <Button
      id="reject-action-button"
      buttonStyle="danger"
      onClick={() => setAcceptView(false)}
      px={75}
      ml={2}
    >
      {t("documentControl.rejectImport.button.name")}
    </Button>
  );

  const AcceptMessagePanel = () => (
    <Box pr={5}>
      <LabelField
        name={t("documentControl.acceptImport.message", {
          documentLabelPlural: documentLabelPlural.toLowerCase()
        })}
      />
    </Box>
  );

  const AcceptDlg = () => (
    <PolicyActionCommentSection
      documentPk={documentPk}
      documentName={documentName}
      policyActionMutation={acceptImport}
      location={location}
      translationsKey="acceptImport"
      router={router}
      extraButton={<RejectButton />}
      extraInformation={<AcceptMessagePanel />}
      isConfirmationRequired={false}
      isCommentsRequired={false}
      eventBuilder={inputBuilder}
    />
  );

  const CancelButton = () => (
    <Button
      buttonStyle="tertiary"
      onClick={() => setAcceptView(true)}
      px={75}
      ml={2}
    >
      {t("buttons.cancel")}
    </Button>
  );

  const RejectDlg = () => (
    <PolicyActionCommentSection
      documentPk={documentPk}
      documentName={documentName}
      policyActionMutation={rejectImport}
      location={location}
      translationsKey="rejectImport"
      router={router}
      extraButton={<CancelButton />}
      extraInformation={<RejectWarningMessage />}
      isConfirmationRequired={false}
      eventBuilder={inputBuilder}
      isReject={true}
    />
  );

  const RejectWarningMessage = () => (
    <Box pr={5}>
      <LabelField
        name={t("documentControl.rejectImport.extraInfo.label", {
          documentLabel
        })}
      />
      <Text fontWeight="normal" fontSize={1}>
        {t("documentControl.rejectImport.extraInfo.body", {
          documentLabel: titleCase(documentLabel)
        })}
        <Link
          target="_blank"
          href="https://rldatix-public.zendesk.com/hc/en-us/articles/10394838990108"
        >
          {t("documentControl.rejectImport.extraInfo.body.link.acceptReject", {
            documentLabel
          })}
        </Link>
      </Text>
    </Box>
  );

  return <Fragment>{acceptView ? <AcceptDlg /> : <RejectDlg />}</Fragment>;
};

const PolicyAcceptRejectSection = withLabelContext(_PolicyAcceptRejectSection);

export default PolicyAcceptRejectSection;
