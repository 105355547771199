/**
 * @flow
 * @relayHash bbdf17fabeb5c8cc7269677f575b2c59
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddCommentToDocumentInput = {
  comment: string,
  documentPk: number,
  userPks?: ?$ReadOnlyArray<?number>,
  groupPks?: ?$ReadOnlyArray<?number>,
  clientMutationId?: ?string,
};
export type AddCommentMutationVariables = {|
  input: AddCommentToDocumentInput
|};
export type AddCommentMutationResponse = {|
  +addComment: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +documentAction: ?{|
      +id: string,
      +pk: ?number,
      +canEdit: ?boolean,
      +actionType: string,
      +actionDate: any,
      +comment: string,
      +isExternal: boolean,
      +user: ?{|
        +id: string,
        +userDisplay: ?string,
      |},
    |},
  |}
|};
export type AddCommentMutation = {|
  variables: AddCommentMutationVariables,
  response: AddCommentMutationResponse,
|};
*/


/*
mutation AddCommentMutation(
  $input: AddCommentToDocumentInput!
) {
  addComment(input: $input) {
    ok
    reasons
    documentAction {
      id
      pk
      canEdit
      actionType
      actionDate
      comment
      isExternal
      user {
        id
        userDisplay
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddCommentToDocumentInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addComment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AddCommentToDocumentInput!"
      }
    ],
    "concreteType": "AddCommentToDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentAction",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentActionType",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pk",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "canEdit",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "actionType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "actionDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "comment",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isExternal",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "UserType",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "userDisplay",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "AddCommentMutation",
  "id": "d9a734f40db53fa275cd7987012ec3be",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AddCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "AddCommentMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
node.text = "mutation AddCommentMutation(\n  $input: AddCommentToDocumentInput!\n) {\n  addComment(input: $input) {\n    ok\n    reasons\n    documentAction {\n      id\n      pk\n      canEdit\n      actionType\n      actionDate\n      comment\n      isExternal\n      user {\n        id\n        userDisplay\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = '480dded4370e098b4b4557588ffd5219';
module.exports = node;
