/**
 * @flow
 * @relayHash 0acae8fe1d061ec696e4beecba48bab8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HomeContent_currentTenant$ref = any;
export type HomeContentBaseQueryVariables = {||};
export type HomeContentBaseQueryResponse = {|
  +currentTenant: ?{|
    +$fragmentRefs: HomeContent_currentTenant$ref
  |}
|};
export type HomeContentBaseQuery = {|
  variables: HomeContentBaseQueryVariables,
  response: HomeContentBaseQueryResponse,
|};
*/


/*
query HomeContentBaseQuery {
  currentTenant {
    ...HomeContent_currentTenant
    id
  }
}

fragment HomeContent_currentTenant on TenantType {
  settings {
    customWelcomeContentShow
    customWelcomeContentPsa
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HomeContentBaseQuery",
  "id": "4e802410bb1b256a160adb32c0d8e3f3",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HomeContentBaseQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "HomeContent_currentTenant",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomeContentBaseQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "settings",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantSettingsType",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "customWelcomeContentShow",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "customWelcomeContentPsa",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          v0
        ]
      }
    ]
  }
};
})();
node.text = "query HomeContentBaseQuery {\n  currentTenant {\n    ...HomeContent_currentTenant\n    id\n  }\n}\n\nfragment HomeContent_currentTenant on TenantType {\n  settings {\n    customWelcomeContentShow\n    customWelcomeContentPsa\n    id\n  }\n}\n";
(node/*: any*/).hash = '840f1acf5625456ef962601b56d8a853';
module.exports = node;
