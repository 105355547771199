import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation documentBuildMutation($input: DocumentBuildMutationInput!) {
    documentBuild(input: $input) {
      ok
      reasons
      taskId
      name
    }
  }
`;

const documentBuildMutation = (
  environment,
  variables,
  uploadables,
  callback
) => {
  commitMutation(environment, {
    mutation,
    variables,
    uploadables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default documentBuildMutation;
