import { withRouter } from "found";
import { Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentContainer } from "../pages";

import DiscardDraftModal from "./DiscardDraftModal";
import DraftsPageCSVQuery from "./DraftsPageCSVQuery";
import DraftsSearch from "./DraftsSearch";

import {
  ReportHeader,
  ReportPage
} from "pstat-anywhere/components/pages/reports";
import Export from "pstat-anywhere/components/partials/export/Export";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import Card from "pstat-design-system/Card";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { HorizontalScrollTable } from "pstat-design-system/tables/PolicyTable";
import { Tab, TabContainer } from "pstat-design-system/Tabs";
import { Text } from "pstat-design-system/text/paragraph";



export const RESULTS_PER_PAGE = 20;
const SHOW_ALL_TAB_ID = "all";
const MINE_TAB_ID = "mine";
const SHOW_ALL_PATHNAME = "/draft/all";
const MINE_PATHNAME = "/draft/mine";

const _DraftsPage = ({ drafts, showAll, retry, location, router }) => {
  const initSearchQuery = location?.query?.searchQuery;
  const { t } = useTranslation();
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [discardDocumentSavePK, setDiscardDocumentSavePk] = useState(null);
  const [searchQuery, setSearchQuery] = useState(initSearchQuery || "");

  let documentSaves,
    totalCount,
    myCount = null;
  if (drafts) {
    documentSaves = drafts.drafts.edges.map(edge => edge.node);
    totalCount = drafts.drafts.totalCount;
    myCount = drafts.drafts.myCount;
  }
  if (documentSaves) {
    documentSaves = documentSaves.map(documentSave => {
      const documentSaveCopy = { ...documentSave };
      documentSaveCopy.openDiscardModal = documentSavePk => {
        setDiscardModalIsOpen(true);
        setDiscardDocumentSavePk(documentSavePk);
      };
      documentSaveCopy.applicabilityType =
        documentSaveCopy.savedCopy.applicabilityType;
      return documentSaveCopy;
    });
  }
  const handleSortChange = sort => {
    const queryParams = { ...location.query };
    queryParams.sort = sort;
    const to = {
      pathname: location.pathname,
      query: queryParams
    };
    router.push(to);
  };

  const handleSearch = value => {
    const queryParams = { ...location.query };
    queryParams.searchQuery = value;
    setSearchQuery(value);
    const to = {
      pathname: location.pathname,
      query: queryParams
    };
    router.push(to);
  };

  const handleTabChange = () => {
    if (!location) {
      return;
    }
    let to = {
      query: location.query
    };
    if (showAll) {
      to["pathname"] = `${getRootUrl()}${MINE_PATHNAME}`;
    } else {
      to["pathname"] = `${getRootUrl()}${SHOW_ALL_PATHNAME}`;
    }
    router.push(to);
  };
  return (
    <ContentContainer>
      <ReportPage
        totalNumberOfResults={showAll ? totalCount : myCount}
        hitsPerPage={RESULTS_PER_PAGE}
      >
        <ReportHeader title={t("documentDraft.header")}>
          <Export
            csvQuery={DraftsPageCSVQuery}
            queryName="draftsCsv"
            csvQueryArgs={{ showAll, searchQuery }}
          />
        </ReportHeader>
        <TabContainer
          selectedTabId={showAll ? SHOW_ALL_TAB_ID : MINE_TAB_ID}
          onSelectedTabChange={handleTabChange}
          totalCount={showAll ? totalCount : myCount}
          currentPageResultsCount={
            documentSaves
              ? Math.min(documentSaves.length, RESULTS_PER_PAGE)
              : null
          }
        >
          <Tab id={MINE_TAB_ID}>
            <Text>{t("documentDraft.tabs.justMine")}</Text>
          </Tab>
          <Tab id={SHOW_ALL_TAB_ID}>
            <Text>{t("documentDraft.tabs.all")}</Text>
          </Tab>
        </TabContainer>
        <Card>
          {!documentSaves ? (
            <Flex justifyContent="center">
              <LoadingSpinner size={100} />
            </Flex>
          ) : (
            <Fragment>
              <DraftsSearch
                handleSearch={handleSearch}
                initValue={searchQuery}
              />
              {documentSaves.length > 0 ? (
                <HorizontalScrollTable
                  id="results_table"
                  onSortChange={handleSortChange}
                  policies={documentSaves}
                  tableType="drafts"
                  numberToDisplay={RESULTS_PER_PAGE}
                  sort={location.query.sort}
                  hasFrozenColumn={true}
                />
              ) : (
                <Fragment>
                  <Text color="nav.25" fontSize={2}>
                    {t("documentDraft.noResults")}
                  </Text>
                </Fragment>
              )}
            </Fragment>
          )}
        </Card>
        <DiscardDraftModal
          isOpen={discardModalIsOpen}
          onClose={() => setDiscardModalIsOpen(false)}
          documentSavePk={discardDocumentSavePK}
          successCallback={retry}
        />
      </ReportPage>
    </ContentContainer>
  );
};

export default withRouter(_DraftsPage);
