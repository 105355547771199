import { Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withTranslation } from "react-i18next";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import MenuButton from "pstat-design-system/shared/MenuButton";
import { Text } from "pstat-design-system/text";
import Tooltip from "pstat-design-system/Tooltip";

const _ShareButton = ({ copyUrl, t, withText = true }) => (
  <Fragment>
    <CopyToClipboard text={copyUrl}>
      <MenuButton
        id="share_button"
        data-tip
        data-for="copy-link-tooltip"
        data-event="mousedown keydown"
        data-event-off="mouseup keyup"
        data-delay-hide="2000"
      >
        <StyledFontAwesomeIcon
          icon={["fal", "share-alt"]}
          size="lg"
          color="secondary.0"
        />
        {withText && <Text color="secondary.0">{t("buttons.share.text")}</Text>}
      </MenuButton>
    </CopyToClipboard>
    <Tooltip name="copy-link-tooltip">{t("buttons.share.tooltip")}</Tooltip>
  </Fragment>
);

const ShareButton = withTranslation()(_ShareButton);
export default ShareButton;

export const SearchShareButton = ({ token, withText }) => {
  const link = new URL(window.location.href);
  const next = `${link.pathname}${link.search}`;
  link.searchParams.delete("q");
  link.searchParams.append("lt", token);
  link.searchParams.append("next", next);
  return (
    <ShareButton copyUrl={`${link.origin}${link.search}`} withText={withText} />
  );
};
