import { useTranslation } from "react-i18next";

import { FilterDropdown } from "pstat-anywhere/components/pages/reports/components";
import { InputFormElement } from "pstat-design-system/inputs/FormElements";
import { Button } from "pstat-design-system/shared/Button";

export const DaysFilterDropdown = props => {
  const {
    expanded,
    onToggle,
    value,
    onChange,
    onClear,
    onClose,
    filterName,
    expandedFilterName,
    inputLabel,
    inputName,
    inputId
  } = props;
  const { t } = useTranslation();
  return (
    <FilterDropdown
      expanded={expanded}
      onClose={onClose}
      onToggle={onToggle}
      filterName={filterName}
      expandedFilterName={expandedFilterName}
    >
      <InputFormElement
        value={value}
        label={inputLabel}
        onChange={onChange}
        type="number"
        step="1"
        min="0"
        name={inputName}
        id={inputId}
      />
      <Button
        width="100%"
        style={{ marginTop: "10px" }}
        buttonStyle="tertiary"
        onClick={onClear}
      >
        {t("documentReports.expiring.clearButton.title")}
      </Button>
    </FilterDropdown>
  );
};
