import { Flex } from "grid-styled";
import PropTypes from "prop-types";
import { themeGet } from "styled-system";
import system from "system-components";

const MenuButton = system(
  {
    is: "button",
    bg: "transparent",
    border: "0",
    color: "secondary.0",
    fontFamily: "font",
    fontSize: 2,
    tabIndex: "0",
    textAlign: undefined
  },
  "space",
  "display",
  "alignItems",
  "width",
  "height"
).extend`
  white-space: ${({ whiteSpace }) => whiteSpace || "nowrap"};

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

MenuButton.displayName = "MenuButton";

const ActiveMenuButton = system({
  is: MenuButton,
  bg: "nav.10",
  borderTop: [0, 2, 2]
});

ActiveMenuButton.displayName = "ActiveMenuButton";

const FocusHolder = system({
  is: Flex,
  display: "inline-flex",
  alignItems: "center",
  tabIndex: "-1",
  height: "100%"
}).extend`
  &:focus {
    outline: 0;
  }
`;

FocusHolder.displayName = "FocusHolder";

const FocusBorder = system({
  is: Flex,
  alignItems: "center",
  border: "dashed 1px",
  borderColor: "transparent",
  borderRadius: 1,
  p: 1
}).extend`
  &:focus {
    outline: 0;
  }
  ${MenuButton}:hover & {
    text-decoration: underline;
  }
  ${MenuButton}:active & {
    text-decoration: underline;
  }
  ${MenuButton}:focus & {
    border: dashed 1px;
    border-color: ${props => themeGet(`colors.${props.focusBorderColor}`)};
  }
`;

FocusBorder.displayName = "FocusBorder";

const FocusMenuButton = ({ active, children, color, px, ...otherProps }) => {
  let MenuButtonComponent = MenuButton;
  if (active) {
    MenuButtonComponent = ActiveMenuButton;
  }
  if (otherProps.onClick) {
    otherProps.onKeyDown = event => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        otherProps.onClick();
      }
    };
  }
  return (
    <MenuButtonComponent {...otherProps} px={px} height="100%" color={color}>
      <FocusHolder>
        <FocusBorder focusBorderColor={color} px={px}>
          {children}
        </FocusBorder>
      </FocusHolder>
    </MenuButtonComponent>
  );
};

FocusMenuButton.propTypes = {
  onActivation: PropTypes.func
};

export default FocusMenuButton;
