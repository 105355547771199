import graphql from "babel-plugin-relay/macro";

export const documentAttachmentsQuery = graphql`
  query documentAttachmentsQuery(
    $objectPk: Int!
    $objectType: AttachmentContentType!
  ) {
    documentAttachments(objectPk: $objectPk, objectType: $objectType) {
      pk
      title
      fileUrl
      isImage
    }
  }
`;

export default documentAttachmentsQuery;
