import { Flex } from "grid-styled";
import { Fragment } from "react";
import Media from "react-media";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";
import { Badge } from "pstat-design-system/Badge";
import Caption from "pstat-design-system/tables/Caption";
import { H1 } from "pstat-design-system/text/headings";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

const ReportHeading = props => (
  <Media query={{ minWidth: theme.breakpoints[1] }}>
    {isDesktop => (
      <Fragment>
        <H1
          color="nav.0"
          display="inline-block"
          fontSize={isDesktop ? 8 : 3}
          fontWeight={isDesktop ? "light" : "normal"}
        >
          {props.title}
        </H1>
        {props.tooltip && (
          <Fragment>
            <StyledFontAwesomeIcon
              icon={["fal", "question-circle"]}
              color="secondary.0"
              size="lg"
              mx={2}
              data-tip
              data-event={HOVER_EVENTS}
              data-event-off={HOVER_EVENTS_OFF}
              data-for="report-header-tooltip"
            />
            <Tooltip name={`report-header-tooltip`}> {props.tooltip} </Tooltip>
          </Fragment>
        )}
        {isDesktop && (
          <Fragment>
            {typeof props.resultCount === "number" && props.showCountBadge && (
              <Badge id="results_count">{props.resultCount}</Badge>
            )}
          </Fragment>
        )}
      </Fragment>
    )}
  </Media>
);

const ReportHeader = ({
  BreadcrumbComponent,
  children,
  title,
  resultCount,
  showCountBadge = true,
  tooltip,
  PrefixHeaderComponent
}) => {
  return (
    <Fragment>
      {BreadcrumbComponent && (
        <Fragment>
          <BreadcrumbComponent />
        </Fragment>
      )}
      <Caption mt={BreadcrumbComponent ? 0 : 5}>
        <Flex alignItems="center">
          {PrefixHeaderComponent && <PrefixHeaderComponent />}
          <ReportHeading
            title={title}
            resultCount={resultCount}
            showCountBadge={showCountBadge}
            tooltip={tooltip}
          />
        </Flex>
        <Flex>{children}</Flex>
      </Caption>
    </Fragment>
  );
};

export default ReportHeader;
