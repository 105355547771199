// @flow
import system from "system-components";

import theme from "../../../themes/policystat/theme";
import { StyledFontAwesomeIcon } from "../../partials/icons";

import { MESSAGE_TYPES } from "./";

const StyledMessageIcon = system({
  is: StyledFontAwesomeIcon,
  width: "20px",
  height: "20px",
  color: theme.colors.nav[100],
  display: "flex",
  alignItems: "flex-start"
});

const MessageIconContainer = system({
  is: "section",
  height: "100%",
  mr: 2
});

export default function MessageIcons({ type }: $Values<typeof MESSAGE_TYPES>) {
  switch (type) {
    case MESSAGE_TYPES.SUCCESS:
      return (
        <MessageIconContainer>
          <StyledMessageIcon icon={["fal", "check-circle"]} />
        </MessageIconContainer>
      );
    case MESSAGE_TYPES.WARNING:
    case MESSAGE_TYPES.ERROR:
      return (
        <MessageIconContainer>
          <StyledMessageIcon icon={["fal", "exclamation-triangle"]} />
        </MessageIconContainer>
      );
    case MESSAGE_TYPES.INFO:
    default:
      return (
        <MessageIconContainer>
          <StyledMessageIcon icon={["fal", "info-circle"]} />
        </MessageIconContainer>
      );
  }
}
