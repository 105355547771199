/**
 * @flow
 * @relayHash 95b53a95cee8a453df80a482f911bc2b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getCategoryLifespanQueryVariables = {|
  categoryPk?: ?number
|};
export type getCategoryLifespanQueryResponse = {|
  +getCategoryLifespan: ?number
|};
export type getCategoryLifespanQuery = {|
  variables: getCategoryLifespanQueryVariables,
  response: getCategoryLifespanQueryResponse,
|};
*/


/*
query getCategoryLifespanQuery(
  $categoryPk: Int
) {
  getCategoryLifespan(categoryPk: $categoryPk)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "categoryPk",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "getCategoryLifespan",
    "args": [
      {
        "kind": "Variable",
        "name": "categoryPk",
        "variableName": "categoryPk",
        "type": "Int"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "getCategoryLifespanQuery",
  "id": "db636529e5d1a72b8d606cacee0363cc",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "getCategoryLifespanQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "getCategoryLifespanQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query getCategoryLifespanQuery(\n  $categoryPk: Int\n) {\n  getCategoryLifespan(categoryPk: $categoryPk)\n}\n";
(node/*: any*/).hash = '2cb82a6fb9cc35b322d8eddf1668dd70';
module.exports = node;
