import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation discardDraftMutation($documentSaveId: Int!) {
    discardDraft(documentSaveId: $documentSaveId) {
      ok
      reasons
      redirectUrl
    }
  }
`;

const discardDraft = (environment, variables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default discardDraft;
