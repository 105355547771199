import "typeface-roboto";

import { injectGlobal } from "styled-components";
import reset from "styled-reset";

let called = false;

export default function doInjectGlobal() {
  // we are only supposed to call initGlobal once (ideally)
  // this handles certain global css, in this case the font-face declaration that
  // ensures the Roboto font is recognizable

  // the output of https://fonts.googleapis.com/css?family=Roboto:300,400,500

  if (!called) {
    called = true;

    injectGlobal`
    ${reset};
    * { box-sizing: border-box; }
    body {
      overflow-x: hidden;
    }
    body.ReactModal__Body--open {
      overflow: hidden;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    `;
  }
}
