import { Box, Flex } from "grid-styled";
import { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import PolicyAcceptRejectSection from "./PolicyAcceptRejectSection";

import ViewPolicy from "pstat-anywhere/components/document_control/view_policy/ViewPolicy";
import ViewPolicyQuery from "pstat-anywhere/components/document_control/view_policy/ViewPolicyQuery";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";


const DocumentAcceptImportPage = props => {
  const { documentToAcceptView, environment } = props;
  const documentToAccept = documentToAcceptView?.documentToAccept;
  return (
    <Fragment>
      {!documentToAccept?.document ? (
        <LoadingSpinner size={100} />
      ) : (
        <QueryRenderer
          environment={environment}
          query={ViewPolicyQuery}
          variables={{
            documentPk: documentToAccept.document.pk,
            latest: false,
            ignoreLatestRedirect: true
          }}
          render={renderProps => {
            const queryProps = renderProps.props;
            if (queryProps && queryProps.documentView) {
              return (
                <ViewPolicy
                  {...props}
                  documentView={queryProps.documentView}
                  hasPolicyActionComment={true}
                  PolicyActionCommentSection={PolicyAcceptRejectSection}
                  actionDocumentPk={documentToAccept.documentImport.pk}
                  policyActions={documentToAccept.policyActions}
                  displayConfig={{
                    showPrintAndShare: false,
                    showLippincottPanel: false,
                    showApprovalSignaturePanel: false,
                    showApplicabilityPanel: false,
                    showAcknowledgementsPanel: false,
                    showCommentsPanel: false,
                    showApprovalFlow: true,
                    showCloneAction: false
                  }}
                />
              );
            } else {
              return (
                <Flex justify="center" align="center" mt={10}>
                  <Box>
                    <LoadingSpinner size={100} />
                  </Box>
                </Flex>
              );
            }
          }}
        ></QueryRenderer>
      )}
    </Fragment>
  );
};

export default withTranslation()(
  withViewer(withTenantContext(DocumentAcceptImportPage))
);
