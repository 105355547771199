import graphql from "babel-plugin-relay/macro";
import { Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import { commitMutation } from "react-relay";
import system from "system-components";

import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import ExternalLink from "pstat-design-system/ExternalLink";

export const ONESOURCE_EXPANDED = "onesource-expanded";

const Container = system({
  is: Flex,
  flexDirection: "column",
  alignItems: "center",
  bg: "nav.95",
  border: 1,
  borderColor: "nav.80",
  borderRadius: 1,
  px: 6,
  py: 8
});

const OnesourceImage = system({
  is: "img"
}).extend`
  max-width: 100%;
`;

const AuthenticateWithOnesource = ({ environment }) => {
  const redirectToOnesource = () => {
    const url = new URL(window.location.href);
    url.searchParams.append(ONESOURCE_EXPANDED, "1");
    getOnesourceAuthUrl(
      environment,
      url.toString(),
      (response, responseError) => {
        const { redirectPath, error } = response.onesourceAuthUrl;
        const noError = !responseError && (!error || error?.statusCode === 200);
        if (noError && redirectPath) {
          window.location.replace(redirectPath);
        } else {
          createErrorMessage(t("onesource.edit.authenticate.errorMessage"));
        }
      }
    );
  };
  const { t } = useTranslation();
  return (
    <Container>
      <OnesourceImage
        src={`${window.S3_IMG_URL}onesource-color.png`}
        alt="onesource logo"
      />
      <ExternalLink onClick={redirectToOnesource} pt={7}>
        {t("onesource.edit.authenticate.link")}
      </ExternalLink>
    </Container>
  );
};

export default AuthenticateWithOnesource;

const mutation = graphql`
  mutation AuthenticateWithOnesourceMutation($redirectUrl: String!) {
    onesourceAuthUrl(redirectUrl: $redirectUrl) {
      redirectPath
      error {
        statusCode
      }
    }
  }
`;

export const getOnesourceAuthUrl = (environment, redirectUrl, callback) => {
  const variables = { redirectUrl };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
