/**
 * @flow
 * @relayHash d7416ef1d75bd4c2ac82a45cae12d02d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlocManagementPageQueryVariables = {|
  sort?: ?string
|};
export type BlocManagementPageQueryResponse = {|
  +blocs: ?{|
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +blocs: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
      +tenants: ?$ReadOnlyArray<?{|
        +subdomain: ?string
      |}>,
      +isEditable: ?boolean,
    |}>,
  |}
|};
export type BlocManagementPageQuery = {|
  variables: BlocManagementPageQueryVariables,
  response: BlocManagementPageQueryResponse,
|};
*/


/*
query BlocManagementPageQuery(
  $sort: String
) {
  blocs(sort: $sort) {
    error {
      statusCode
      message
    }
    blocs {
      pk
      name
      tenants(sort: "name") {
        subdomain
        id
      }
      isEditable
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "sort",
    "value": "name",
    "type": "String"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subdomain",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isEditable",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BlocManagementPageQuery",
  "id": "8bea8822f48d101344f36854b068ecbf",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BlocManagementPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocs",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": "tenants(sort:\"name\")",
                "args": v5,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v6
                ]
              },
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BlocManagementPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocs",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": "tenants(sort:\"name\")",
                "args": v5,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v6,
                  v8
                ]
              },
              v7,
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query BlocManagementPageQuery(\n  $sort: String\n) {\n  blocs(sort: $sort) {\n    error {\n      statusCode\n      message\n    }\n    blocs {\n      pk\n      name\n      tenants(sort: \"name\") {\n        subdomain\n        id\n      }\n      isEditable\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = 'a750a48e79abf02a054aa7ba2a751dcd';
module.exports = node;
