/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type commentForm_document$ref = any;
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PolicyEditor_document$ref: FragmentReference;
export type PolicyEditor_document = {|
  +id: string,
  +pk: ?number,
  +name: string,
  +status: ?DocumentStatus,
  +actions: ?$ReadOnlyArray<?{|
    +id: string,
    +pk: ?number,
    +actionType: string,
    +actionDate: any,
    +comment: string,
    +canEdit: ?boolean,
    +isExternal: boolean,
    +user: ?{|
      +id: string,
      +userDisplay: ?string,
    |},
  |}>,
  +epAssociationDocuments: ?$ReadOnlyArray<?{|
    +pk: ?number
  |}>,
  +systemDocumentTemplate: ?{|
    +pk: ?number
  |},
  +isTemplate: ?boolean,
  +$fragmentRefs: commentForm_document$ref,
  +$refType: PolicyEditor_document$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v2 = [
  v1
];
return {
  "kind": "Fragment",
  "name": "PolicyEditor_document",
  "type": "DocumentType",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "isEditPage",
      "type": "Boolean",
      "defaultValue": false
    }
  ],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "actions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isEditPage",
          "variableName": "isEditPage",
          "type": "Boolean"
        }
      ],
      "concreteType": "DocumentActionType",
      "plural": true,
      "selections": [
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "actionType",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "actionDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "comment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "canEdit",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isExternal",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "UserType",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "userDisplay",
              "args": [
                {
                  "kind": "Literal",
                  "name": "userDisplayType",
                  "value": "FORWARD",
                  "type": "UserDisplayType"
                }
              ],
              "storageKey": "userDisplay(userDisplayType:\"FORWARD\")"
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "epAssociationDocuments",
      "storageKey": null,
      "args": null,
      "concreteType": "EpAssociationDocumentType",
      "plural": true,
      "selections": v2
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "systemDocumentTemplate",
      "storageKey": null,
      "args": null,
      "concreteType": "SystemDocumentTemplateType",
      "plural": false,
      "selections": v2
    },
    {
      "kind": "FragmentSpread",
      "name": "commentForm_document",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isTemplate",
      "args": null,
      "storageKey": null
    }
  ]
};
})();

(node/*: any*/).hash = '820c82fbfcfad15f755f8018ec5f8823';
module.exports = node;
