import styled from "styled-components";

const CardTable = styled("table")`
  border-collapse: separate;
  width: 100%;
`;

export const FixedLayoutCardTable = styled(CardTable)`
  border-collapse: collapse;
  table-layout: fixed;
`;

export default CardTable;
