// @flow
import type { ReactNode } from "react";
import { themeGet } from "styled-system";

import ContentRenderer from "../view/content/ContentRenderer";

import ExpandableContainer from "pstat-design-system/utils/ExpandableContainer";
import Card from "pstat-design-system/Card";

type Props = {
  isExpanded: boolean,
  shouldShowResizer: Function,
  content: string | ReactNode
};

export default function Welcome({ content }: Props) {
  return (
    <Card position="relative">
      <ExpandableContainer
        drillExpandableContainerProps={true}
        buttonPositionToContent="parentSibling"
      >
        <WelcomeRenderer
          dangerouslySetInnerHTML={{ __html: `<div>${content}</div>` }}
        />
      </ExpandableContainer>
    </Card>
  );
}

// max-height: 220px comes from that, how react-sizeme render content which is out of the container
// it is not use overflow:hidden, but just removes content which goes out.
// So cause of we have shouldShowResizer check with returning true if height > 215, we should ensure, that if content not fit
// We will show resizer. For that - we must have max-height available bigger, then in check
export const WelcomeRenderer = ContentRenderer.extend`
  ${props => (props.isExpanded ? "" : "max-height: 220px; overflow: hidden;")};
  padding-bottom: ${props =>
    props.shouldShowResizer() ? themeGet("space.8") : "0"}px;

  /* The following CSS is based on the default welcome content from legacy */
  &.container {
    text-align: center;
    margin-top: 3em;
    /* Prevent the welcome content from pushing the login/logout box off screen */
    width: auto;
  }
  & .section {
    text-align: center;
    width: 220px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 3em;
  }
  & .section img {
    margin-bottom: 2em;
    object-fit: contain;
  }
  & .welcome-search {
    width: 60px !important;
    height: 60px;
  }
  & .welcome-manage {
    width: 60px;
    height: 60px !important;
  }
  & .welcome-location {
    width: 60px;
    height: 60px !important;
  }
  & .welcome-help {
    width: 60px !important;
    height: 60px;
  }
  & .section .header {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 1em;
  }
  & .section .description {
    font-size: 15px;
    width: 210px;
    margin: auto;
  }
`;
