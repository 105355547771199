import graphql from "babel-plugin-relay/macro";
import { useEffect, useState } from "react";
import { fetchQuery } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const query = graphql`
  query useCheckPasswordResetTokenQuery($uidb64: String!, $token: String!) {
    passwordResetTokenCheck(uidb64: $uidb64, token: $token) {
      result
    }
  }
`;
const useCheckPasswordResetToken = (uidb64, token) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);

  useEffect(() => {
    const checkTokenValid = (uidb64, token) => {
      const variables = { uidb64: uidb64, token: token };
      fetchQuery(environment, query, variables).then(data => checkStatus(data));
    };

    const checkStatus = data => {
      if (!data) {
        setResult(null);
        setLoading(false);
        return;
      }
      const { result } = data.passwordResetTokenCheck;

      setResult(result);
      setLoading(false);
    };

    checkTokenValid(uidb64, token);

    return;
  }, [uidb64, token]);

  return { loading, result };
};

export default useCheckPasswordResetToken;
