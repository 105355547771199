import { useEffect, useState } from "react";

const useBeforeUnload = onBeforeUnload => {
  const [disabedBeforeUnload, setDisableBeforeUnload] = useState(false);
  let beforeUnloadFunction = onBeforeUnload;
  if (disabedBeforeUnload) {
    beforeUnloadFunction = event => {};
  }
  const temporailyDisableBeforeUnload = () => {
    setDisableBeforeUnload(true);
    setTimeout(() => setDisableBeforeUnload(false), 1000);
  };
  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadFunction);
    return () =>
      window.removeEventListener("beforeunload", beforeUnloadFunction);
  }, [beforeUnloadFunction]);

  return temporailyDisableBeforeUnload;
};

export default useBeforeUnload;
