import graphql from "babel-plugin-relay/macro";

export const TemplateReviewPageQuery = graphql`
  query TemplateReviewPageQuery($documentPk: Int!) {
    documentReview(pk: $documentPk) {
      document {
        pk
        name
        html
        author {
          pk
          userDisplay
          firstName
          lastName
          profile {
            title
          }
        }
        category {
          pk
          name
        }
        bloc {
          id
          pk
          name
          tenants(sort: "name") {
            pk
            name
          }
        }
        isTemplate
        status
        restricted
        effectiveDate(formatted: true)
        approvalDate(formatted: true)
        expirationDate(formatted: true)
        revisionDate(formatted: true)
        originationDate(formatted: true)
        legacyEffectiveDate(formatted: true)
        headerImageUrl
        attachments {
          title
          fileUrl
        }
        tags {
          pk
          name
        }
      }
      showChangesTaskId
      error {
        statusCode
        message
      }
      redirectUrl
    }
  }
`;

export default TemplateReviewPageQuery;
