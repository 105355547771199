import graphql from "babel-plugin-relay/macro";

const ScheduledDocumentsQuery = graphql`
  query ScheduledDocumentsQuery(
    $after: String
    $first: Int
    $sort: String
    $showAll: Boolean
    $search_query: String
  ) {
    scheduledDocuments(sort: $sort, showAll: $showAll, q: $search_query) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            effectiveDate
            hasAttachments
            restricted
            bloc {
              name
            }
            applicabilityType
            category {
              name
            }
          }
        }
      }
      error {
        statusCode
      }
    }
  }
`;

export default ScheduledDocumentsQuery;
