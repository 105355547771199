import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation RetirePolicyMutation($input: RetireDocumentInput!) {
    retireDocument(input: $input) {
      ok
      reasons
      redirectPath
    }
  }
`;

export const retirePolicy = (environment, input, callback) => {
  const variables = {
    input
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
