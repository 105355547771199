// @flow

import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation PrintMutation($input: PrintDocumentInput!) {
    printDocument(input: $input) {
      ok
      reasons
      url
      error {
        statusCode
      }
      tenantsToRedirect {
        name
        url
      }
    }
  }
`;

export const printDocument = (
  environment,
  document,
  watermark = false,
  showHistory = false,
  showApplicability = false,
  isDraft = false,
  callback,
  displayChangesDiff,
  includePastApprovals = false,
  beforeActionPk = null,
  afterActionPk = null
) => {
  const beforeActionId = displayChangesDiff ? beforeActionPk : undefined;
  const afterActionId = displayChangesDiff ? afterActionPk : undefined;
  const variables = {
    input: {
      documentPk: document.pk,
      watermark: watermark,
      showHistory: showHistory,
      showApplicability: showApplicability,
      draft: isDraft,
      showChanges: displayChangesDiff,
      includePastApprovals,
      beforeActionPk: beforeActionId,
      afterActionPk: afterActionId
    }
  };

  // we probably don't need a config here - we won't actually use the returned object in a relay container.

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};
