export default function sanitize(string) {
  // inspired from https://docs.djangoproject.com/en/dev/ref/templates/language/#automatic-html-escaping
  if (typeof string !== "string" || string.length === 0) return "";
  const map = {
    "<": "&lt;",
    ">": "&gt;",
    "'": "&#x27;",
    '"': "&quot;",
    "&": "&amp;"
  };
  const reg = /[&<>"']/gi;
  return string.replace(reg, match => map[match]);
}
