import { Flex } from "grid-styled";
import styled from "styled-components";

import { StyledFontAwesomeIcon } from "../partials/icons";

import { FocusLink } from "pstat-design-system/Link";
import { Text } from "pstat-design-system/text";


const Item = styled(Flex)`
  height: 100%;
  align-items: center;
`;

export const HeaderLink = ({ linkIcon, linkLabel, ...otherProps }) => {
  return (
    <Item>
      <FocusLink {...otherProps}>
        <StyledFontAwesomeIcon icon={linkIcon} />
        <Text color="secondary.0">{linkLabel}</Text>
      </FocusLink>
    </Item>
  );
};
