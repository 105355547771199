import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { getDocumentViewRecord, getStoredTokens } from "./utils";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation DeleteTokenMutation($input: DeleteDocumentAccessTokenInput!) {
    deleteDocumentAccessToken(input: $input) {
      ok
      token {
        tokenId
      }
    }
  }
`;

export function deleteToken(tokenId, documentPk, location, callback) {
  const variables = {
    input: {
      tokenId,
      documentPk
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    },
    updater: store => {
      const payload = store.getRootField("deleteDocumentAccessToken");
      const deletedToken = payload.getLinkedRecord("token");

      const documentViewRecord = getDocumentViewRecord(
        store,
        documentPk,
        location
      );
      if (!documentViewRecord) {
        return;
      }
      const storedTokens = getStoredTokens(documentViewRecord);

      const tokens = storedTokens.filter(
        token => token.getValue("tokenId") !== deletedToken.getValue("tokenId")
      );
      documentViewRecord.setLinkedRecords(tokens, "documentAccessTokens");
    }
  });
}
