import { Flex } from "grid-styled";
import { useState } from "react";

import FloatContainer from "./FloatContainer";
import FloatingButton from "./FloatingButton";
import TableOfContents from "./TableOfContents";

import { TERTIARY_NAVBAR_HEIGHT } from "pstat-anywhere/components/partials/tertiary_nav";
import theme from "pstat-anywhere/themes/policystat/theme";
import { htmlStringToHeadingTree } from "pstat-anywhere/utils/domHelpers";
import Sticky from "pstat-design-system/Sticky";


export default function TogglableTableOfContents({
  countAttachments,
  countStandards,
  countOneSource,
  html,
  expanded,
  onToggle,
  onClose,
  activeItemId
}) {
  const rootNode = htmlStringToHeadingTree(html);

  if (rootNode.children.length === 0) {
    onClose();
    return null;
  }
  return (
    <FloatContainer data-testid="toc-float">
      <Sticky
        id="table_of_contents"
        offset={TERTIARY_NAVBAR_HEIGHT + theme.space["5"]}
      >
        {expanded && (
          <TableOfContents
            countAttachments={countAttachments}
            countStandards={countStandards}
            countOneSource={countOneSource}
            rootNode={rootNode}
            activeItemId={activeItemId}
          />
        )}
        <FloatingButton onClick={onToggle} tocExpanded={expanded} />
      </Sticky>
    </FloatContainer>
  );
}

export const useTableOfContents = isDesktop => {
  const [tocExpandedDesktop, settocExpandedDesktop] = useState(isDesktop);
  const [tocExpandedMobile, settocExpandedMobile] = useState(false);
  const toggleTableOfContents = () => {
    if (isDesktop) {
      settocExpandedDesktop(!tocExpandedDesktop);
    } else {
      settocExpandedMobile(!tocExpandedMobile);
    }
  };
  const closeTableOfContents = () => {
    if (isDesktop) {
      settocExpandedDesktop(false);
    } else {
      settocExpandedMobile(false);
    }
  };
  return {
    tocExpandedDesktop,
    tocExpandedMobile,
    toggleTableOfContents,
    closeTableOfContents
  };
};

export const AfterTOCContainer = ({ tocExpanded, children }) => (
  <Flex flexDirection={tocExpanded ? "row" : "column"}>{children}</Flex>
);
