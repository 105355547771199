import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";

export const Filter = ({ name, handler, checked }) => (
  <CheckboxGroup
    name={name}
    onChange={handler}
    selectedValues={checked ? [checked] : null}
  >
    <Checkbox value={checked} label={name} />
  </CheckboxGroup>
);

const FilterSet = props => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Filter
        name={t("documentControl.view.filters.edits")}
        handler={props.handlers.edits}
        checked={props.filterEdits}
      />
      <Filter
        name={t("documentControl.view.filters.approvals")}
        handler={props.handlers.approvals}
        checked={props.filterApprovals}
      />
      <Filter
        name={t("documentControl.view.filters.comments")}
        handler={props.handlers.comments}
        checked={props.filterComments}
      />
    </Fragment>
  );
};

export default FilterSet;
