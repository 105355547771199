import VisuallyHidden from "@reach/visually-hidden";
import { Flex } from "grid-styled";
import { Component, Fragment, memo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { APPROVAL_WORKFLOW_CONTENT_VARIATIONS } from "pstat-anywhere/components/document_control/approve_policy/ApprovalWorkflowContent";
import ApprovalWorkflowModal from "pstat-anywhere/components/document_control/approve_policy/ApprovalWorkflowModal";
import EditEffectiveDateModal from "pstat-anywhere/components/document_control/view_policy/EditEffectiveDateModal";
import Info from "pstat-anywhere/components/document_control/view_policy/Info";
import Print from "pstat-anywhere/components/document_control/view_policy/print/Print";
import ShareModal from "pstat-anywhere/components/document_control/view_policy/share/ShareModal";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import {
  PkDisplay,
  StatusBarContainer,
  StatusBarFlex,
  StatusBarItem,
  StatusDisplay
} from "pstat-anywhere/components/view/StatusBar.jsx";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { isPending } from "pstat-anywhere/utils/document";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { Badge, LinkedBadge } from "pstat-design-system/Badge";
import { FocusLink } from "pstat-design-system/Link";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import { Text } from "pstat-design-system/text";
import Tooltip from "pstat-design-system/Tooltip";

const ApprovalWorkflowName = ({ workflowName }) => {
  const { t } = useTranslation();
  return (
    <StatusBarItem mr={[0, 0, 1, 3]}>
      <VisuallyHidden>
        {t("documentControl.view.infoModal.approvalWorkflow.visuallyHidden", {
          workflowName
        })}
      </VisuallyHidden>
      <Text aria-hidden="true">
        {t("documentControl.view.infoModal.approvalWorkflow.title")}
      </Text>
      <Badge aria-hidden="true">{workflowName}</Badge>
    </StatusBarItem>
  );
};

export const ShareLink = ({ toggle, ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <StatusBarItem>
      <FocusLink id="share-modal-link" onActivation={toggle} {...otherProps}>
        <StyledFontAwesomeIcon icon={["fal", "share-alt"]} size="lg" />
        <Text color="secondary.0">{t("buttons.share.text")}</Text>
      </FocusLink>
    </StatusBarItem>
  );
};

export const AllVersionsLink = ({ document }) => {
  const { t } = useTranslation();
  return (
    <RouterFocusLink
      to={`${getRootUrl()}/policy/${document.pk}/revisions`}
      ml={4}
    >
      <StyledFontAwesomeIcon icon={["fal", "layer-group"]} size="lg" />
      <Text color="secondary.0">
        {t("documentControl.acknowledge.desktopBar.allVersionsLink")}
      </Text>
    </RouterFocusLink>
  );
};

class _Share extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  close = () => {
    this.setState({
      isOpen: false
    });
  };

  updateTooltips = () => {
    // This is needed for the copy link tooltip to show up inside the modal
    // https://github.com/wwayne/react-tooltip#1-using-tooltip-within-the-modal-eg-react-modal
    ReactTooltip.rebuild();
  };

  render() {
    const { t, location } = this.props;
    return (
      <Fragment>
        <ShareLink toggle={this.toggle} />
        <ShareModal
          isOpen={this.state.isOpen}
          onAfterOpen={this.updateTooltips}
          document={this.props.document}
          documentAccessTokens={this.props.documentAccessTokens}
          guestAccessLink={this.props.guestAccessLink}
          onClose={this.close}
          viewer={this.props.viewer}
          includeRss={this.props.includeRss}
          isPreview={this.props.isPreview}
          displayChangesDiff={this.props.displayChangesDiff}
          location={location}
          canEditDocument={this.props.canEditDocument}
        />
        <Tooltip name="copy-link-tooltip">{t("buttons.share.tooltip")}</Tooltip>
      </Fragment>
    );
  }
}

export const Share = withTranslation()(_Share);

const Progress = styled("progress")`
  width: 5em;
`;

const ApprovalStatus = ({ approvalSteps, workflowTitle, currentStepId }) => {
  const [isOpen, setIsOpen] = useState(false);
  function handleModalToggle() {
    setIsOpen(!isOpen);
  }
  return (
    <StatusBarItem mr={[0, 0, 1, 3]} onClick={handleModalToggle}>
      <ApprovalStatusLink
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        workflowTitle={workflowTitle}
        approvalSteps={approvalSteps}
        currentStepId={currentStepId}
      />
    </StatusBarItem>
  );
};

export const ApprovalStatusLink = ({
  isOpen,
  setIsOpen,
  approvalSteps,
  workflowTitle,
  currentStepId
}) => {
  const { t } = useTranslation();
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      setIsOpen(!isOpen);
    }
  }
  return (
    <Fragment>
      <Text display={["block", "block", undefined]}>
        {t("documentControl.view.infoModal.approvalStatus.title")}
      </Text>
      <LinkedBadge
        id="approval_progress_badge"
        display={["inline-block", "inline-block", undefined]}
        height={["inherit", "inherit", undefined]}
        mx={[0, 0, undefined]}
        onKeyDown={handleKeyDown}
      >
        <Progress value="10" max="100" />
      </LinkedBadge>
      <ApprovalWorkflowModal
        isOpen={isOpen}
        onClose={setIsOpen}
        title={workflowTitle}
        steps={approvalSteps}
        variation={APPROVAL_WORKFLOW_CONTENT_VARIATIONS.PENDING_NO_PRIVILEGES}
        currentStepId={currentStepId}
      />
    </Fragment>
  );
};

export const showEffectiveBadge = (tenant, document) =>
  tenant.hasScheduledEffectiveDateEnabled &&
  (document.status === "PENDING" || document.status === "SCHEDULED");

export const EffectiveDate = ({ document, canEdit }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      openModal();
    }
  };
  return (
    <StatusBarItem id="edit_scheduled_effective_date">
      <Text>{t("tables.labels.effective")}</Text>
      {canEdit ? (
        <Fragment>
          <LinkedBadge onClick={openModal} onKeyDown={handleKeyDown}>
            {document.effectiveDate}
          </LinkedBadge>
          <EditEffectiveDateModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            document={document}
          />
        </Fragment>
      ) : (
        <Badge>{document.effectiveDate}</Badge>
      )}
    </StatusBarItem>
  );
};

export const StatusBar = ({
  document,
  timelineDocument,
  documentAccessTokens,
  guestAccessLink,
  tenant,
  viewer,
  isPreview = false,
  showPrintAndShare = true,
  documentToPrintAndShare,
  applicabilityExpanded = true,
  isApprovalPage,
  policyActions,
  canEditDocument,
  showWorkflowName = false,
  displayChangesDiff = false,
  includePastApprovals = false,
  location,
  beforeActionPk,
  afterActionPk
}) => {
  let theDocumentToPrintAndShare = documentToPrintAndShare;
  const currentStepId = document?.approvalWorkflow?.currentStep?.id;

  if (!theDocumentToPrintAndShare && showPrintAndShare) {
    theDocumentToPrintAndShare = document;
  }
  const documentToShow = timelineDocument || document;

  return (
    <Media query={{ minWidth: theme.breakpoints[1] }}>
      {isDesktop => (
        <StatusBarContainer>
          <StatusBarFlex>
            <StatusDisplay status={documentToShow.status} />
            {isDesktop ? (
              <Fragment>
                <PkDisplay pk={documentToShow.pk} />
                {isPending(document.status) && (
                  <ApprovalStatus
                    workflowTitle={
                      document.approvalWorkflow &&
                      document.approvalWorkflow.name
                    }
                    approvalSteps={
                      document.approvalWorkflow &&
                      document.approvalWorkflow.steps
                    }
                    currentStepId={currentStepId}
                  />
                )}
                {showEffectiveBadge(tenant, document) && (
                  <EffectiveDate
                    document={document}
                    canEdit={canEditDocument}
                  />
                )}
                {showWorkflowName && (
                  <ApprovalWorkflowName
                    workflowName={
                      document.approvalWorkflow?.name ||
                      document.workflowTemplate?.name
                    }
                  />
                )}
              </Fragment>
            ) : null}
          </StatusBarFlex>
          <StatusBarFlex>
            {!isDesktop && (
              <Info
                document={documentToShow}
                isPreview={isPreview}
                isViewDocument={true}
              />
            )}
            {showPrintAndShare && (
              <Fragment>
                {isDesktop && policyActions?.links?.revisions && (
                  <AllVersionsLink viewer={viewer} document={document} />
                )}
                <Print
                  document={theDocumentToPrintAndShare}
                  viewer={viewer}
                  applicabilityExpanded={applicabilityExpanded}
                  isPreview={isPreview}
                  isDraft={isPreview}
                  displayChangesDiff={displayChangesDiff}
                  includePastApprovals={includePastApprovals}
                  beforeActionPk={beforeActionPk}
                  afterActionPk={afterActionPk}
                />
                <Share
                  document={theDocumentToPrintAndShare}
                  documentAccessTokens={documentAccessTokens}
                  guestAccessLink={guestAccessLink}
                  viewer={viewer}
                  includeRss={!isApprovalPage}
                  isPreview={isPreview}
                  displayChangesDiff={displayChangesDiff}
                  location={location}
                  canEditDocument={canEditDocument}
                />
              </Fragment>
            )}
          </StatusBarFlex>
        </StatusBarContainer>
      )}
    </Media>
  );
};

export default withTenantContext(StatusBar);

export const _EditStatusBar = ({
  document,
  viewer,
  isOverride,
  policyActions
}) => (
  <Media query={{ minWidth: theme.breakpoints[1] }}>
    {isDesktop => (
      <Flex
        id="status_bar"
        alignItems="center"
        justifyContent="space-between"
        pt={[0, 0, 2]}
        px={3}
      >
        <StatusBarFlex>
          <StatusDisplay status={document.status} />
          {isDesktop && <PkDisplay pk={document.pk} />}
        </StatusBarFlex>
        <StatusBarFlex>
          {isDesktop ? (
            policyActions?.links?.revisions && (
              <AllVersionsLink viewer={viewer} document={document} />
            )
          ) : (
            <Info
              document={document}
              isPreview={false}
              isViewDocument={false}
            />
          )}
          <Print
            document={document}
            viewer={viewer}
            applicabilityExpanded={true}
            isPreview={false}
            isDraft={!isOverride}
            displayChangesDiff={false}
            includePastApprovals={false}
          />
          <Share
            document={document}
            viewer={viewer}
            includeRss={false}
            isPreview={false}
            displayChangesDiff={false}
          />
        </StatusBarFlex>
      </Flex>
    )}
  </Media>
);

export const EditStatusBar = memo(withTenantContext(_EditStatusBar));
