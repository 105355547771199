import { useEffect, useState } from "react";

import { getRootUrl } from "pstat-anywhere/utils/urls";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";

const useGettingBlocCopies = taskId => {
  const blocCopiesTask = useTaskResult(taskId);
  const { result } = blocCopiesTask;
  const [blocCopies, setBlocCopies] = useState([]);

  useEffect(() => {
    if (result) {
      const { blocs } = result;
      if (blocs && blocs.length > 0) {
        setBlocCopies(
          blocs.map(({ name, bloc_id }) => ({
            blocPk: bloc_id,
            name,
            url: `${getRootUrl()}/bloc/${bloc_id}`
          }))
        );
      }
    }
  }, [result]);

  return {
    blocCopies
  };
};

export default useGettingBlocCopies;
