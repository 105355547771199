import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation OverrideMutation(
    $documentPk: Int!
    $tagInput: [String]
    $effectiveDate: PStatDate
    $effectiveDateTimedelta: Int
    $effectiveDateAlgorithm: String
    $originationDate: PStatDate
    $approvalDate: PStatDate
    $legacyEffectiveDate: PStatDate
    $name: String
    $html: String
    $author: Int
    $category: Int
    $bloc: Int
    $flowTemplate: Int
    $activeLifespan: Int
    $expirationDate: PStatDate
    $restricted: Boolean
    $isAForm: Boolean
    $actionComment: String
    $regulations: [Int]
    $approver: Int
    $approvedDate: PStatDate
    $revisionDates: [Date]
    $onesourceDocuments: [OneSourceDocumentInputType]
    $locations: [Int]
  ) {
    override(
      documentPk: $documentPk

      tagInput: $tagInput

      effectiveDate: $effectiveDate
      effectiveDateTimedelta: $effectiveDateTimedelta
      effectiveDateAlgorithm: $effectiveDateAlgorithm
      originationDate: $originationDate
      approvalDate: $approvalDate
      legacyEffectiveDate: $legacyEffectiveDate

      approver: $approver
      approvedDate: $approvedDate

      name: $name
      html: $html
      author: $author
      category: $category
      bloc: $bloc
      flowTemplate: $flowTemplate
      activeLifespan: $activeLifespan
      expirationDate: $expirationDate
      restricted: $restricted
      isAForm: $isAForm

      actionComment: $actionComment
      regulations: $regulations
      revisionDates: $revisionDates
      onesourceDocuments: $onesourceDocuments
      locations: $locations
    ) {
      ok
      reasons
      redirectUrl
    }
  }
`;

export const overrideDocumentMutation = (environment, variables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
