import { themeGet } from "styled-system";
import system from "system-components";

export const ModalFooter = system(
  {
    width: 1,
    bg: "nav.95",
    position: "fixed",
    bottom: 0
  },
  "space",
  "display",
  "position"
).extend`
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  @media (min-width: ${themeGet("breakpoints.1")} ) {
    position: static;
    bottom:auto;
  }
`;
