/**
 * @flow
 * @relayHash 4d692651b86397b50dfc9df07f9235ae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type MyAcknowledgmentHistoryQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
  filter?: ?string,
  q?: ?string,
|};
export type MyAcknowledgmentHistoryQueryResponse = {|
  +myDocumentsToAcknowledge: ?{|
    +totalCount: ?number,
    +acknowledgedCount: ?number,
    +incompleteCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +waitTime: ?string,
        +dateAcknowledged: ?string,
        +totalAcknowledged: ?number,
        +totalAssigned: ?number,
        +line: ?{|
          +pk: ?number,
          +document: ?{|
            +pk: ?number,
            +name: string,
            +hasAttachments: ?boolean,
            +restricted: boolean,
            +bloc: {|
              +name: string
            |},
            +applicabilityType: ?ApplicabilityType,
          |},
        |},
      |}
    |}>,
  |}
|};
export type MyAcknowledgmentHistoryQuery = {|
  variables: MyAcknowledgmentHistoryQueryVariables,
  response: MyAcknowledgmentHistoryQueryResponse,
|};
*/


/*
query MyAcknowledgmentHistoryQuery(
  $after: String
  $first: Int
  $sort: String
  $filter: String
  $q: String
) {
  myDocumentsToAcknowledge(showAll: true, after: $after, first: $first, sort: $sort, filter: $filter, q: $q) {
    totalCount
    acknowledgedCount
    incompleteCount
    edges {
      node {
        waitTime
        dateAcknowledged
        totalAcknowledged
        totalAssigned
        line {
          pk
          document {
            pk
            name
            hasAttachments
            restricted
            bloc {
              name
              id
            }
            applicabilityType
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "q",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q",
    "type": "String"
  },
  {
    "kind": "Literal",
    "name": "showAll",
    "value": true,
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "acknowledgedCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "incompleteCount",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "waitTime",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateAcknowledged",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAcknowledged",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAssigned",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MyAcknowledgmentHistoryQuery",
  "id": "e90fe4c2cba926b0ce3cc16275c51c50",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MyAcknowledgmentHistoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myDocumentsToAcknowledge",
        "storageKey": null,
        "args": v1,
        "concreteType": "CountableConnectionAcknowledgmentConnection",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentDocumentLineType",
                "plural": false,
                "selections": [
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentLineType",
                    "plural": false,
                    "selections": [
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v9,
                          v10,
                          v11,
                          v12,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": [
                              v10
                            ]
                          },
                          v13
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyAcknowledgmentHistoryQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myDocumentsToAcknowledge",
        "storageKey": null,
        "args": v1,
        "concreteType": "CountableConnectionAcknowledgmentConnection",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentDocumentLineType",
                "plural": false,
                "selections": [
                  v5,
                  v6,
                  v7,
                  v8,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentLineType",
                    "plural": false,
                    "selections": [
                      v9,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v9,
                          v10,
                          v11,
                          v12,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": [
                              v10,
                              v14
                            ]
                          },
                          v13,
                          v14
                        ]
                      },
                      v14
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query MyAcknowledgmentHistoryQuery(\n  $after: String\n  $first: Int\n  $sort: String\n  $filter: String\n  $q: String\n) {\n  myDocumentsToAcknowledge(showAll: true, after: $after, first: $first, sort: $sort, filter: $filter, q: $q) {\n    totalCount\n    acknowledgedCount\n    incompleteCount\n    edges {\n      node {\n        waitTime\n        dateAcknowledged\n        totalAcknowledged\n        totalAssigned\n        line {\n          pk\n          document {\n            pk\n            name\n            hasAttachments\n            restricted\n            bloc {\n              name\n              id\n            }\n            applicabilityType\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = 'ffdf9a653f2b33d067638be596a653e9';
module.exports = node;
