import graphql from "babel-plugin-relay/macro";

const DuplicateDocumentsQuery = graphql`
  query DuplicateDocumentsQuery (
    $after: String
    $first: Int
    ) {
    duplicateDocuments {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            name
            count
            url
          }
        }
      }
      error {
        statusCode
      }
    }
  }
`;

export default DuplicateDocumentsQuery;
