import { Flex } from "grid-styled";
import PropTypes from "prop-types";
import { Component, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { getRootUrl } from "../../utils/urls";
import DropdownMenu from "../partials/DropdownMenu";
import { StyledFontAwesomeIcon } from "../partials/icons";
import MenuLink, { SelectedMenuLabel } from "../partials/MenuLink";

import { RouterLink } from "./RouterLink";

import { TextInputWithIcon } from "pstat-design-system/inputs/TextInput";

const SiteSelectorDropdownContainer = styled.div`
  display: inline-block;
`;

const MobileTenantName = styled.div`
  display: inline-block;
  &:focus {
    outline: 0;
  }
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

const DesktopTenantName = styled.span`
  display: none;
  &:focus {
    outline: 0;
  }
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: inline;
  }
`;

export const TenantNameContainer = styled.div`
  display: inline-block;
  font-weight: medium;
  font-size: ${themeGet("fontSizes.3")};
  font-family: ${themeGet("fonts.font")};
  color: ${themeGet("colors.nav.100")};
  padding: ${themeGet("space.1")}px;
  border: 1px dashed;
  border-color: transparent;
  text-align: left;

  &:focus {
    outline: 0;
  }
  ${DesktopTenantName}:focus > &,
  ${MobileTenantName}:focus > & {
    border: dashed 1px ${themeGet("nav.100")};
    border-radius: 6px;
  }
`;

const DropdownSelectorContainer = styled.div.attrs({
  role: "button",
  tabIndex: "0"
})`
  display: inline-block;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: inline-block;
  }
  &:focus {
    outline: 0;
  }
`;

const DropdownItem = styled.li`
  display: block;
  float: none;
`;

const ChangeTenant = styled.div`
  display: "none";
  border: 1px dashed;
  border-color: transparent;
  font-family: ${themeGet("fonts.font")};
  font-size: ${themeGet("fontSizes.2")};
  border-radius: ${themeGet("radii.1")}px;
  padding: ${themeGet("space.1")}px;
  color: ${themeGet("colors.primary.0")};

  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: block;
  }
  &:hover,
  &:active {
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
  ${DropdownSelectorContainer}:focus > & {
    border: dashed 1px ${themeGet("colors.nav.100")};
  }
`;

const MapMarkerIconContainer = styled.span`
  border: 1px dashed;
  border-color: transparent;
  border-radius: ${themeGet("radii.1")}px;
  padding-top: ${themeGet("space.1")}px;
  padding-bottom: ${themeGet("space.1")}px;

  &:focus {
    outline: 0;
  }
  ${DropdownSelectorContainer}:focus & {
    border: dashed 1px ${themeGet("colors.nav.100")};
  }
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

export const AccessibleTenantsDropdownItems = props => {
  const { t } = useTranslation();
  const [filterQuery, setFilterQuery] = useState("");
  const handleSearchValue = event => setFilterQuery(event.target.value);

  const filterTenantsByName = tenants => {
    const query = filterQuery.toLowerCase();
    return tenants.filter(
      tenant => tenant.name.toLowerCase().indexOf(query) !== -1
    );
  };

  const filteredTenants = filterTenantsByName(props.accessibleTenants);
  const tenantItems = filteredTenants.map(tenant => {
    let href = tenant.url;
    if (props.isAdminPage) {
      href = `${tenant.url}admin/`;
    }
    return (
      <DropdownItem key={tenant.id}>
        {props.tenant.id === tenant.id ? (
          <SelectedMenuLabel>{tenant.name}</SelectedMenuLabel>
        ) : (
          <MenuLink href={href}>{tenant.name}</MenuLink>
        )}
      </DropdownItem>
    );
  });
  return (
    <Fragment>
      <Flex px={4} py={2}>
        <TextInputWithIcon
          icon={["far", "search"]}
          side="left"
          value={filterQuery}
          onChange={handleSearchValue}
          placeholder={t("siteSelector.filterLocation")}
        />
      </Flex>
      {tenantItems}
    </Fragment>
  );
};

const DropdownSelector = props => {
  const { t } = useTranslation();
  return (
    <DropdownSelectorContainer
      onClick={props.toggle}
      onKeyDown={props.handleKeyDown}
    >
      <ChangeTenant tabIndex="-1">
        {t("siteSelector.changeLocation")}
      </ChangeTenant>
      <MapMarkerIconContainer tabIndex="-1">
        <StyledFontAwesomeIcon icon={["far", "map-marker"]} color="primary.0" />
      </MapMarkerIconContainer>
    </DropdownSelectorContainer>
  );
};

class SiteSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.isMultiTenant = this.isMultiTenant.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  toggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  close() {
    this.setState({ isMenuOpen: false });
  }

  isMultiTenant() {
    return (
      this.props.accessibleTenants && this.props.accessibleTenants.length > 1
    );
  }

  handleKeyDown(event) {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.toggle();
    }
  }

  render() {
    const { accessibleTenants, isAdminPage, tenant } = this.props;
    return (
      <div>
        <DesktopTenantName tabIndex="-1">
          <RouterLink to={{ pathname: `${getRootUrl()}/` }}>
            <TenantName name={tenant.name} />
          </RouterLink>
          <br />
        </DesktopTenantName>

        <SiteSelectorDropdownContainer>
          {this.isMultiTenant() && (
            <DropdownMenu
              dropdownSelector={props => (
                <DropdownSelector
                  handleKeyDown={this.handleKeyDown}
                  {...props}
                />
              )}
              expanded={this.state.isMenuOpen}
              rtl={false}
              toggle={this.toggle}
              closeMenu={this.close}
            >
              <AccessibleTenantsDropdownItems
                tenant={tenant}
                accessibleTenants={accessibleTenants}
                isAdminPage={isAdminPage}
              />
            </DropdownMenu>
          )}
        </SiteSelectorDropdownContainer>

        <MobileTenantName tabIndex="-1">
          <RouterLink to={{ pathname: `${getRootUrl()}/` }}>
            <TenantName name={tenant.name} />
          </RouterLink>
        </MobileTenantName>
      </div>
    );
  }
}

SiteSelector.propTypes = {
  tenant: PropTypes.object.isRequired,
  accessibleTenants: PropTypes.arrayOf(PropTypes.object)
};

export const TenantName = ({ name }) => (
  <TenantNameContainer>
    {name.length <= 48 ? name : name.substring(0, 45) + "..."}
  </TenantNameContainer>
);

export default SiteSelector;
