/**
 * @flow
 * @relayHash 37fd7aacb4dbbb4fbdeb6f6fc24aa74e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type rejectImportMutationVariables = {|
  importPk: number,
  comment: string,
|};
export type rejectImportMutationResponse = {|
  +rejectImportDocument: ?{|
    +ok: ?boolean,
    +error: ?{|
      +statusCode: ?number
    |},
    +redirectPath: ?string,
  |}
|};
export type rejectImportMutation = {|
  variables: rejectImportMutationVariables,
  response: rejectImportMutationResponse,
|};
*/


/*
mutation rejectImportMutation(
  $importPk: Int!
  $comment: String!
) {
  rejectImportDocument(importPk: $importPk, comment: $comment) {
    ok
    error {
      statusCode
    }
    redirectPath
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "importPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "rejectImportDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "importPk",
        "variableName": "importPk",
        "type": "Int!"
      }
    ],
    "concreteType": "RejectImportsMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectPath",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "rejectImportMutation",
  "id": "3359e5564e16235100ccec842b855126",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "rejectImportMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "rejectImportMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation rejectImportMutation(\n  $importPk: Int!\n  $comment: String!\n) {\n  rejectImportDocument(importPk: $importPk, comment: $comment) {\n    ok\n    error {\n      statusCode\n    }\n    redirectPath\n  }\n}\n";
(node/*: any*/).hash = 'b3622cb0251f127533bece5e185f21ff';
module.exports = node;
