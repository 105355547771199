import VisuallyHidden from "@reach/visually-hidden";
import { Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import system from "system-components";

import theme from "pstat-anywhere/themes/policystat/theme";
import { parseDocumentStatusToTranslationKey } from "pstat-anywhere/utils/document";
import { Badge } from "pstat-design-system/Badge";
import { Text } from "pstat-design-system/text";

export const StatusDisplay = ({ status }) => {
  const { t } = useTranslation();
  const formattedStatus = t(parseDocumentStatusToTranslationKey(status));

  return (
    <StatusBarItem mr={[0, 0, 1, 3]}>
      <VisuallyHidden>
        {t("view.infoModal.modalPolicyStatus.visuallyHidden", {
          formattedStatus
        })}
      </VisuallyHidden>
      <Media query={{ minWidth: theme.breakpoints[1] }}>
        {isDesktop =>
          isDesktop && (
            <Text aria-hidden="true">
              {t("view.infoModal.modalPolicyStatus.title")}
            </Text>
          )
        }
      </Media>
      <Badge aria-hidden="true">{formattedStatus}</Badge>
    </StatusBarItem>
  );
};

export const PkDisplay = ({ pk }) => {
  const { t } = useTranslation();
  return (
    <StatusBarItem mr={[0, 0, 1, 3]}>
      <VisuallyHidden>
        {t("view.infoModal.pkDisplay.visuallyHidden", { pk })}
      </VisuallyHidden>
      <Text aria-hidden="true">{t("view.infoModal.pkDisplay.title")}</Text>
      <Badge aria-hidden="true">{pk}</Badge>
    </StatusBarItem>
  );
};

export const StatusBarItem = system({
  is: Flex,
  height: "100%",
  alignItems: "center"
});
StatusBarItem.displayName = "StatusBarItem";

export const StatusBarFlex = system({
  is: Flex,
  height: "52px",
  alignItems: "center"
});
StatusBarFlex.displayName = "StatusBarFlex";

export const StatusBarContainer = system({
  is: Flex,
  id: "status_bar",
  alignItems: "center",
  justifyContent: "space-between",
  pt: [0, 0, 2],
  px: 3
});
StatusBarContainer.displayName = "StatusBarContainer";
