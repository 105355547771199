import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation RejectPendingDocumentMutation($documentPk: Int!, $comment: String!) {
    rejectPendingDocument(documentPk: $documentPk, comment: $comment) {
      ok
      reasons
      document {
        pk
      }
    }
  }
`;

export const rejectPendingDocumentMutation = (
  environment,
  variables,
  callback
) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
