import graphql from "babel-plugin-relay/macro";
import { useEffect, useRef, useState } from "react";
import { fetchQuery } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const TASK_STATUSES = {
  failure: "FAILURE",
  pending: "PENDING",
  success: "SUCCESS"
};

const query = graphql`
  query useTaskResultQuery($taskId: UUID!) {
    getTaskStatus(taskId: $taskId) {
      status
      result
    }
  }
`;

const useTaskResult = taskId => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const resultCache = useRef({});

  const reset = () => {
    setLoading(false);
    setResult(null);
    setError(null);
  };

  useEffect(() => {
    let taskPollInterval = null;
    const fetchStatus = taskId => {
      const variables = { taskId: taskId };
      fetchQuery(environment, query, variables).then(data => checkStatus(data));
    };

    const checkStatus = data => {
      if (!data) {
        clearInterval(taskPollInterval);
        setLoading(false);
        return;
      }
      if (!data.getTaskStatus) {
        clearInterval(taskPollInterval);
        setLoading(false);
        return;
      }
      const { status, result } = data.getTaskStatus;
      const parsedResult = JSON.parse(result);

      if (status === TASK_STATUSES.pending) {
        clearInterval(taskPollInterval);
        return;
      }
      if (status === TASK_STATUSES.success) {
        if (parsedResult.timeout) {
          setError(true);
        }
        setResult(parsedResult);
        resultCache.current[taskId] = parsedResult;
        setLoading(false);
        clearInterval(taskPollInterval);
      } else if (status === TASK_STATUSES.failure) {
        setError(true);
        setLoading(false);
        clearInterval(taskPollInterval);
      }
    };

    if (!taskId || taskId === "") {
      setLoading(false);
    } else {
      const cachedResult = resultCache.current[taskId];
      if (cachedResult) {
        setResult(cachedResult);
      } else {
        setLoading(true);
        fetchStatus(taskId);
        taskPollInterval = setInterval(() => {
          fetchStatus(taskId);
        }, 2000);
      }
    }

    return () => clearInterval(taskPollInterval);
  }, [taskId]);

  return { loading, result, error, reset };
};

export default useTaskResult;
