import BrowserProtocol from "farce/lib/BrowserProtocol";
import queryMiddleware from "farce/lib/queryMiddleware";
import { Route, makeRouteConfig } from "found";
import { Resolver } from "found-relay";
import { ScrollManager } from "found-scroll";
import createFarceRouter from "found/lib/createFarceRouter";
import createRender from "found/lib/createRender";
import { Component, Fragment, useEffect, useRef } from "react";

import acknowledgmentRoutes from "./components/acknowledgments/routes";
import administrationRoutes from "./components/administration/routes";
import authenticationRoutes from "./components/authentication/routes";
import blocRoutes from "./components/bloc/routes";
import documentBuilderRoutes from "./components/document_builder/routes";
import documentControlRoutes, {
  noNavDocumentControlRoutes
} from "./components/document_control/routes";
import documentDraftRoutes from "./components/document_draft/routes";
import documentReportRoutes from "./components/document_reports/routes";
import documentSearch from "./components/document_search/routes";
import documentTokenRoutes from "./components/document_token_access/routes";
import homeRoutes from "./components/home/routes";
import implementationRoutes from "./components/implementation/routes";
import integrationsRoutes from "./components/integrations/routes";
import notificationsRoutes from "./components/notification/routes";
import {
  PageWithNav,
  PageWithNavLoading,
  PageWithNavQuery
} from "./components/pages/PageWithNav";
import PageWithoutNav from "./components/pages/PageWithoutNav";
import systemTemplatesRoutes from "./components/system_document_templates/routes";
import { useTenant } from "./context_providers/TenantContext";
import { withViewer } from "./context_providers/ViewerContext";
import theme from "./themes/policystat/theme";
import { getRootUrl } from "./utils/urls";

import { clearToasts } from "pstat-design-system/message/manageMessages";
import { PageNotFoundErrorPage } from "pstat-design-system/ErrorPages";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { ContentContainerFlex } from "pstat-anywhere/components/pages";

// based on https://reacttraining.com/react-router/web/guides/scroll-restoration/scroll-to-top
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const PageWithNavScrollWrapper = props => (
  <ScrollToTop {...props}>
    <PageWithNav {...props} />
  </ScrollToTop>
);

const ClearPersistentToastsOnPageChange = ({ children, router }) => {
  const previousPath = useRef(null);
  useEffect(() => {
    const removeTransitionHook = router.addTransitionHook(location => {
      if (location.pathname !== previousPath.current) {
        clearToasts();
      }
      previousPath.current = location.pathname;
    });
    return () => removeTransitionHook();
  });
  return <Fragment>{children}</Fragment>;
};

export const routeConfig = makeRouteConfig(
  <Route
    path={`${getRootUrl()}/`}
    Component={ClearPersistentToastsOnPageChange}
  >
    <Route
      Component={PageWithNavScrollWrapper}
      query={PageWithNavQuery}
      render={({ Component, props }) => {
        if (props) {
          return <Component {...props} />;
        } else {
          return <PageWithNavLoading />;
        }
      }}
    >
      {homeRoutes}
      {/* the home routes don't work in alphabetical order for some reason, so leave it here */}
      {acknowledgmentRoutes}
      {administrationRoutes}
      {authenticationRoutes}
      {documentBuilderRoutes}
      {documentControlRoutes}
      {documentDraftRoutes}
      {documentReportRoutes}
      {documentSearch}
      {implementationRoutes}
      {notificationsRoutes}
      {integrationsRoutes}
      {blocRoutes}
      {systemTemplatesRoutes}
    </Route>
    <Route Component={PageWithoutNav}>
      {documentTokenRoutes}
      {noNavDocumentControlRoutes}
    </Route>
    <Route
      Component={PageWithNavScrollWrapper}
      query={PageWithNavQuery}
      render={({ Component, props }) => {
        if (props) {
          return <Component {...props} />;
        } else {
          return <PageWithNavLoading />;
        }
      }}
    >
      <Route
        path="*"
        Component={PageNotFoundErrorPage}
        render={({ Component, props }) => (
          <ContentContainerFlex>
            <Component {...props} />
          </ContentContainerFlex>
        )}
      />
    </Route>
  </Route>
);

const render = createRender({
  renderError: ({ error }) => (
    <div>{error.status === 404 ? "Page not found" : "Error"}</div>
  )
});

const isPolicyPage = location => {
  return location.pathname.startsWith("/policy/");
};

const getHeadingPixelCoordinates = location => {
  if (!location.hash) {
    return null;
  }
  // remove the # and decode in case they used some special characters
  const elementId = decodeURI(location.hash.slice(1));
  let element = document.getElementById(elementId);
  if (!element) {
    // for supporting legacy links that use the name attribute instead of id
    const elementsByName = document.getElementsByName(elementId);
    element = elementsByName.length ? elementsByName[0] : null;
    if (!element) {
      return null;
    }
  }
  return element.getBoundingClientRect();
};

// this is needed because the view policy page has a nav bar always at the top
// that covers the linked headings when they are linked to.
// So if we are on the view policy page and we are navigating to a heading link,
// then don't scroll as far to not hide the heading
const shouldUpdateScroll = (prevRenderArgs, { location }) => {
  if (isPolicyPage(location)) {
    const coordinates = getHeadingPixelCoordinates(location);
    if (coordinates) {
      if (coordinates.y) {
        return [0, coordinates.y - theme.tertiaryNavHeight];
      } else {
        // IE 11 behavior, there is no elementBoundingReact.y, they're a bit more detailed
        // See https://msdn.microsoft.com/en-us/library/hh826029(v=vs.85).aspx
        return [0, coordinates.top - theme.tertiaryNavHeight];
      }
    }
  }

  // default scroll behavior
  return true;
};

const RelayRouter = createFarceRouter({
  routeConfig: routeConfig,
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],

  render: renderArgs => (
    <ScrollManager
      renderArgs={renderArgs}
      shouldUpdateScroll={shouldUpdateScroll}
    >
      {render(renderArgs)}
    </ScrollManager>
  )
});

const RouterWrapper = props => {
  const tenant = useTenant();
  return (
    <RelayRouter
      resolver={new Resolver(props.environment)}
      matchContext={{ viewer: props.viewer, tenant }}
    />
  );
};

export const Router = withLabelContext(withViewer(RouterWrapper));
