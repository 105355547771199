import { useTranslation } from "react-i18next";

import { getRootUrl } from "../../utils/urls";
import MenuLink from "../partials/MenuLink";

import { doLogout } from "./LogoutMutation";

const LogoutMenuLink = props => {
  const handleLogout = function () {
    doLogout(() => {
      props.closeMenu();
      window.location.replace((getRootUrl() || "/") + "?lo=1");
    });
  };
  const { t } = useTranslation();
  return (
    <MenuLink onClick={handleLogout}>
      {t("nav.primary.myAccount.logout")}
    </MenuLink>
  );
};

export default LogoutMenuLink;
