import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "react-radio-group";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Radio from "pstat-design-system/inputs/Radio";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Label, Text } from "pstat-design-system/text";

const PrintModal = props => {
  const { documentLabel } = props.labels;
  const { t } = useTranslation();
  return (
    <Modal
      contentLabel={t("documentControl.print.modal.contentLabel", {
        documentLabel
      })}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                id="print-modal-print-button"
                buttonStyle="secondary"
                onClick={props.print}
                data-testid="print-modal-print-button"
              >
                {t("documentControl.print.header")}
              </Button>
            </Box>
            <Box>
              <Button buttonStyle="tertiary" onClick={props.onClose}>
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      {props.isLoading ? (
        <Flex justify="center" align="center" mt={10}>
          <Box>
            <LoadingSpinner size={100} />
          </Box>
        </Flex>
      ) : (
        <Flex flexDirection="column" px={5} py={3} flex={1}>
          <Box py={1}>
            <Text>{t("documentControl.print.modal.description")}</Text>
          </Box>
          {props.includeWatermarkOption && (
            <Box py={1}>
              <RadioGroup
                name="watermark"
                onChange={props.updateShowWatermark}
                selectedValue={props.showWatermark}
              >
                <Label>
                  {t("documentControl.print.modal.showPDFWatermark")}
                </Label>
                <Flex>
                  <Radio label={t("yes")} value="yes" />
                  <Radio label={t("no")} value="no" />
                </Flex>
              </RadioGroup>
            </Box>
          )}
          {props.includeHistoryOption && (
            <Box py={1}>
              <RadioGroup
                name="history"
                onChange={props.updateShowHistory}
                selectedValue={props.showHistory}
              >
                <Label>Show History</Label>
                <Flex>
                  <Radio label={t("yes")} value="yes" />
                  <Radio label={t("no")} value="no" />
                </Flex>
              </RadioGroup>
            </Box>
          )}
        </Flex>
      )}
    </Modal>
  );
};

export default withLabelContext(PrintModal);
