import graphql from "babel-plugin-relay/macro";

const blocCopiesQuery = graphql`
  query BlocCopiesQuery($tenantPks: [Int]!, $excludeBlocPk: Int!) {
    blocCopies(tenantPks: $tenantPks, excludeBlocPk: $excludeBlocPk) {
      taskId
    }
  }
`;

export default blocCopiesQuery;
