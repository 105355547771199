import graphql from "babel-plugin-relay/macro";

export const DraftsPageQuery = graphql`
  query DraftsPageQuery(
    $showAll: Boolean
    $first: Int
    $after: String
    $sort: String
    $searchQuery: String
  ) {
    drafts(showAll: $showAll, sort: $sort, searchQuery: $searchQuery) {
      error {
        statusCode
        message
      }
      drafts(first: $first, after: $after) {
        totalCount
        myCount
        edges {
          node {
            pk
            savedCopy {
              pk
              name
              restricted
              category {
                name
              }
              bloc {
                name
              }
              applicabilityType
            }
            document {
              pk
            }
            isEditDraft
            saverDisplay
            createdDate
            modifiedDate
          }
        }
      }
    }
  }
`;

export default DraftsPageQuery;
