import { Route } from "found";


import { CustomerLogoQuery } from "../authentication/CustomerLogoQuery";
import LoginPage from "../authentication/LoginPage";

import HomeContent from "./HomeContent";
import { HomeContentBaseQuery } from "./HomeContentBaseQuery";
import { HomeContentQuery } from "./HomeContentQuery";

import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";

const routes = (
  <Route
    getComponent={({ context }) => {
      if (context.viewer) {
        return props => (
          <PageTitleWrapper titleKey="home.homeContent.documentTitle">
            <HomeContent {...props} />
          </PageTitleWrapper>
        );
      } else {
        return props => (
          <PageTitleWrapper titleKey="loginPage.documentTitle">
            <LoginPage {...props} />
          </PageTitleWrapper>
        );
      }
    }}
    getQuery={({ context }) => {
      if (context.viewer) {
        if (context.tenant.loadHomeQueriesIndependently) {
          return HomeContentBaseQuery;
        } else {
          return HomeContentQuery;
        }
      } else {
        return CustomerLogoQuery;
      }
    }}
    data={{ routeName: "home" }}
    render={({ Component, props, match }) => {
      return (
        <Component
          {...props}
          loadQueriesIndependently={
            match.context.tenant.loadHomeQueriesIndependently
          }
        />
      );
    }}
  />
);

export default routes;
