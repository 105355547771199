/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type editor_editorPropertiesOverride$ref: FragmentReference;
export type editor_editorPropertiesOverride = {|
  +rtlSupport: ?boolean,
  +newCkeditor: ?boolean,
  +ckeditor5: ?boolean,
  +ckeditor5AiEnabled: ?boolean,
  +ckeditor5LicenseKey: ?string,
  +$refType: editor_editorPropertiesOverride$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "editor_editorPropertiesOverride",
  "type": "DocumentOverrideType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rtlSupport",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "newCkeditor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5AiEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5LicenseKey",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '082f4732e05f839a6c3d94c3800d0cf5';
module.exports = node;
