/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type editor_editorPropertiesOverride$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PolicyEditor_editorPropertiesOverride$ref: FragmentReference;
export type PolicyEditor_editorPropertiesOverride = {|
  +ckeditor5: ?boolean,
  +$fragmentRefs: editor_editorPropertiesOverride$ref,
  +$refType: PolicyEditor_editorPropertiesOverride$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "PolicyEditor_editorPropertiesOverride",
  "type": "DocumentOverrideType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "editor_editorPropertiesOverride",
      "args": null
    }
  ]
};

(node/*: any*/).hash = '8fe805da0aa4f2be28c0b8e75bd56793';
module.exports = node;
