import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { ContentContainerFlex } from "../pages";

import ManageNotifications from "./ManageNotifications";
import { clearAllNotifications } from "./NoticeClearMutation";
import NotificationsTable from "./NotificationsTable";

import SideBarMenu from "pstat-design-system/SideBarMenu";
import { Button } from "pstat-design-system/shared/Button";
import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import {
  TERTIARY_NAVBAR_HEIGHT,
  TopNav
} from "pstat-anywhere/components/partials/tertiary_nav";

function SideBar({ titleName, onItemClick, activeItem, mt, top }) {
  const menuItems = [
    {
      translationKey: "notifications.sidebar.manage",
      href: "#manage"
    },
    {
      translationKey: "notifications.sidebar.myNotifications",
      href: "#mynotifications"
    }
  ];
  return (
    <SideBarMenu
      id="sidebar"
      width={[1, 1, 1 / 6]}
      p={[0, 0, 3]}
      mt={mt}
      titleName={titleName}
      menuItems={menuItems}
      forceActiveItem={activeItem}
      onItemClick={onItemClick}
      top={top}
    />
  );
}

function Notifications({ location, environment, labels, router }) {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState(1); // As per design

  function handleActiveItemChange(item) {
    setActiveItem(item);
  }

  const handleClearNotifications = useCallback(() => {
    const callback = (response, errors) => {
      if (response.noticeClearAll?.ok) {
        window.location.reload();
      } else {
        createErrorMessage(t("notifications.clearAll.error"));
      }
    };

    clearAllNotifications({ callback });
  }, [t]);

  const sidebarProps = {
    titleName: t("tables.notification.header"),
    activeItem: activeItem,
    onItemClick: handleActiveItemChange
  };
  if (activeItem === 0) {
    sidebarProps.top = TERTIARY_NAVBAR_HEIGHT;
  }
  const sidebar = <SideBar {...sidebarProps} />;

  const SaveButtonContainer = styled.div`
    background-color: white;
    width: 100%;
    padding-left: ${themeGet("space.7")}px;
    padding-right: ${themeGet("space.4")}px;
    padding-top: ${themeGet("space.1")}px;
    padding-bottom: ${themeGet("space.1")}px;
    position: absolute;
    left: 0;
    z-index: ${themeGet("layers.activeFloatingUi")};
  `;

  return activeItem === 0 ? (
    <ManageNotifications
      labels={labels}
      environment={environment}
      sidebar={sidebar}
    />
  ) : (
    <Fragment>
      <TopNav>
        <SaveButtonContainer>
          <Button buttonStyle="primary" onClick={handleClearNotifications}>
            {t("notifications.secondaryNav.clearAll")}
          </Button>
        </SaveButtonContainer>
      </TopNav>
      <ContentContainerFlex wrap>
        <Fragment>
          {sidebar}
          <NotificationsTable
            id="results_table"
            environment={environment}
            location={location}
            router={router}
          />
        </Fragment>
      </ContentContainerFlex>
    </Fragment>
  );
}

export default withLabelContext(Notifications);
