import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation ChooseSiteModalMutation($id: Int!) {
    setHomeSite(tenantId: $id) {
      ok
    }
  }
`;

export const setHomeSite = (
  environment,
  tenantId,
  sucessCalback,
  errorCallback
) => {
  const variables = { id: tenantId };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (response.setHomeSite.ok) {
        sucessCalback();
      } else {
        errorCallback();
      }
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
