import { withUniqueId } from "@team-griffin/react-unique-id";
import PropTypes from "prop-types";
import { createContext, useContext } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { fontFamily } from "pstat-design-system/utils";
import colors from "pstat-anywhere/themes/policystat/colors";

const CheckboxGroupContext = createContext({});

// COPIED FROM react-radio-box AND TRANSFORMED INTO CHECKBOX
// CONSIDER MAKING A SEPARATE PACKAGE
// TODO ensure the selected values are properly handled
export const Checkbox = props => {
  const { name, selectedValues, onChange } = useContext(CheckboxGroupContext);
  const optional = {};
  if (selectedValues) {
    optional.checked = selectedValues.indexOf(props.value) >= 0;
  }
  if (typeof onChange === "function") {
    optional.onChange = onChange.bind(null, props.value);
  }
  return (
    <input
      {...props}
      aria-checked={optional.checked}
      type="checkbox"
      name={name}
      {...optional}
    />
  );
};

export const CheckboxGroup = props => {
  const {
    Component,
    name,
    selectedValues,
    onChange,
    selectAllCheckboxState,
    children,
    ...rest
  } = props;
  let updatedSelectedValues = selectedValues;
  if (selectAllCheckboxState) {
    if (
      selectAllCheckboxState === ALL_SELECTED ||
      selectAllCheckboxState === SOME_SELECTED
    ) {
      updatedSelectedValues = [...selectedValues, SELECT_ALL_CHECKBOX_VALUE];
    }
  }
  const checkboxGroup = {
    name,
    selectedValues: updatedSelectedValues,
    onChange,
    someSelected: selectAllCheckboxState === SOME_SELECTED
  };

  return (
    <CheckboxGroupContext.Provider value={checkboxGroup}>
      <Component px={3} {...rest}>
        {children}
      </Component>
    </CheckboxGroupContext.Provider>
  );
};

CheckboxGroup.defaultProps = {
  Component: system({}, "space")
};

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  selectedValues: PropTypes.array,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object
  ])
};

// END COPY FROM react-radio-group
// https://www.a11ymatters.com/pattern/checkbox/#custom-checkbox-design
// clip is deprecated, use clip-path
const Input = styled(Checkbox)`
  position: absolute;
  clip-path: rect(0 0 0 0);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

// original CSS from https://medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3

const BaseLabel = styled.label`
  ${fontFamily};
  font-size: ${themeGet("fontSizes.2")};
  color: ${themeGet("colors.nav.0")};
  position: relative;
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};

  /*24px width of fake checkbox + 4px distance between fake checkbox and text*/
  padding-left: 28px;

  height: ${({ height }) => height || "auto"};

  &::before,
  &::after {
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  &::before {
    box-sizing: border-box;
    height: 24px;
    width: 24px;

    left: 0px;
    top: 0px;

    border: 1px solid ${themeGet("colors.nav.80")};
    border-radius: 6px;
    background-color: ${({ disabled }) =>
      disabled ? themeGet("colors.nav.80") : themeGet("colors.nav.100")};

    box-shadow: ${({ disabled }) =>
      disabled ? `none` : `inset 2px 2px ${colors["nav"][90]}`};
  }
`;

const Label = styled(BaseLabel)`
  /*Checkmark of the fake checkbox*/
  &::after {
    box-sizing: border-box;
    height: 8px;
    width: 12px;

    left: 6px;
    top: 6px;

    border-left: 2px solid ${themeGet("colors.nav.100")};
    border-bottom: 2px solid ${themeGet("colors.nav.100")};

    transform: rotate(-45deg);
    background-color: ${themeGet("colors.secondary.0")};
  }
`;

export const SelectAllSomeSelectedLabel = styled(BaseLabel)`
  /*Line of the fake checkbox*/
  &::after {
    box-sizing: border-box;
    height: 8px;
    width: 12px;

    left: 6px;
    top: 6px;

    border-bottom: 3px solid ${themeGet("colors.nav.100")};

    background-color: ${themeGet("colors.secondary.0")};
  }
`;

const Container = system({
  lineHeight: "24px",
  py: 1
}).extend`

/*Hide the checkmark by default*/
${Input} + ${BaseLabel}::after {
  content: none;
}

/* make outer dot blue on check */
${Input}:checked + ${BaseLabel}::before {
  background-color: ${themeGet("colors.secondary.0")};
  box-shadow: none;
}

/*Unhide inner dot on the checked state*/
${Input}:checked + ${BaseLabel}::after {
  content: "";
}

/*Adding focus styles on the outer-box of the fake radio button*/
${Input}:focus + ${BaseLabel}::before {
  border: 1px solid ${themeGet("colors.secondary.0")};
  box-shadow: none;
}
`;

const PstatCheckbox = ({
  renderLabel,
  label,
  testId,
  labelHeight,
  disabled,
  ...props
}) => (
  <Container>
    <Input
      id={props.id}
      value={props.value}
      data-testid={testId}
      disabled={disabled}
    />
    <Label
      htmlFor={props.id}
      height={labelHeight}
      disabled={disabled}
      data-tip={props["data-tip"]}
      data-for={props["data-for"]}
      data-event={props["data-event"]}
      data-event-off={props["data-event-off"]}
    >
      {renderLabel ? renderLabel() : label}
    </Label>
  </Container>
);

const CheckboxWithId = withUniqueId({})(PstatCheckbox);

export default CheckboxWithId;

export const SelectAllCheckbox = ({
  id,
  label,
  testId,
  labelHeight,
  ariaLabel
}) => {
  const { someSelected } = useContext(CheckboxGroupContext);
  return (
    <Container>
      <Input
        id={id}
        value={SELECT_ALL_CHECKBOX_VALUE}
        data-testid={testId}
        aria-label={ariaLabel}
      />
      {someSelected ? (
        <SelectAllSomeSelectedLabel htmlFor={id} height={labelHeight}>
          {label}
        </SelectAllSomeSelectedLabel>
      ) : (
        <Label htmlFor={id} height={labelHeight}>
          {label}
        </Label>
      )}
    </Container>
  );
};

export const ALL_SELECTED = "ALL",
  NONE_SELECTED = "NONE",
  SOME_SELECTED = "SOME",
  SELECT_ALL_CHECKBOX_VALUE = "select-all-checkbox";
