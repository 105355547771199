import { Route } from "found";

import DraftsPage, { RESULTS_PER_PAGE } from "./DraftsPage";
import DraftsPageQuery from "./DraftsPageQuery";

import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";
import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";


const routes = (
  <Route path="draft">
    <Route
      path="mine"
      Component={props => (
        <PageTitleWrapper titleKey="documentDraft.mine.documentTitle">
          <DraftsPage {...props} />
        </PageTitleWrapper>
      )}
      query={DraftsPageQuery}
      data={{
        routeName: "document_draft_mine"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        showAll: false,
        sort: location.query.sort,
        first: RESULTS_PER_PAGE,
        after: location.query.after,
        searchQuery: location.query.searchQuery
      })}
      render={({ Component, props, retry }) => (
        <ErrorCheckWrapper queryName="drafts" {...props}>
          <Component showAll={false} retry={retry} {...props} />
        </ErrorCheckWrapper>
      )}
    />
    <Route
      path="all"
      Component={props => (
        <PageTitleWrapper titleKey="documentDraft.all.documentTitle">
          <DraftsPage {...props} />
        </PageTitleWrapper>
      )}
      query={DraftsPageQuery}
      data={{
        routeName: "document_create"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        showAll: true,
        sort: location.query.sort,
        first: RESULTS_PER_PAGE,
        after: location.query.after,
        searchQuery: location.query.searchQuery
      })}
      render={({ Component, props, retry }) => (
        <ErrorCheckWrapper queryName="drafts" {...props}>
          <Component showAll={true} retry={retry} {...props} />
        </ErrorCheckWrapper>
      )}
    />
  </Route>
);

export default routes;
