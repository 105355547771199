import VisuallyHidden from "@reach/visually-hidden";
import { withRouter } from "found";
import { Component } from "react";
import Autosuggest from "react-autosuggest";
import { useTranslation, withTranslation } from "react-i18next";
import {
  Highlight,
  Snippet,
  connectAutoComplete,
  connectStateResults
} from "react-instantsearch-dom";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { withLabelContext } from "../../../context_providers/LabelContext";
import { getRootUrl } from "../../../utils/urls";
import { RelativePositioner } from "../SearchResults/SearchResults";

import { fontFamily } from "pstat-design-system/utils";
import { Text } from "pstat-design-system/text";
import { FocusLink } from "pstat-design-system/Link";
import SearchInput from "pstat-design-system/inputs/SearchInput";
import { withMixpanelEvents } from "pstat-anywhere/utils/useMixpanelEvents";
import { triggerQuickSearchEvent } from "pstat-anywhere/utils/googleTagManager";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import { withSettings } from "pstat-anywhere/context_providers/SettingsContext";

const HitWrapper = system({
  px: [1],
  py: [2]
}).extend`
  cursor: pointer;
  & strong {
    font-weight: bolder;
  }
`;

const SpacerDiv = system({
  mb: [1]
});

export const QuickSearchHit = ({ labels, ...hit }) => {
  const { t } = useTranslation();
  return (
    <HitWrapper>
      <SpacerDiv>
        <Text color="secondary.0">
          <VisuallyHidden>
            {t(
              "documentSearch.quickSearch.desktop.quickSearchHit.documentLabel.visuallyHidden",
              { documentLabel: labels.documentLabel }
            )}
          </VisuallyHidden>
          <Highlight attribute="name" hit={hit} tagName="strong" />
        </Text>
      </SpacerDiv>
      <SpacerDiv>
        <VisuallyHidden>
          {t(
            "documentSearch.quickSearch.desktop.quickSearchHit.categoryLabel.visuallyHidden",
            { categoryLabel: labels.categoryLabel }
          )}
        </VisuallyHidden>
        <Text size="small" color="nav.0">
          {hit.category_name}
        </Text>
      </SpacerDiv>
      <SpacerDiv>
        <VisuallyHidden>
          {t(
            "documentSearch.quickSearch.desktop.quickSearchHit.previewText.visuallyHidden"
          )}
        </VisuallyHidden>
        <Text size="small" fontStyle="italic" color="nav.25">
          <Snippet attribute="text" hit={hit} tagName="strong" />
        </Text>
      </SpacerDiv>
    </HitWrapper>
  );
};

class _AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestionHighlighted: undefined,
      value: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.onSuggestionHighlighted = this.onSuggestionHighlighted.bind(this);
    this.navigateToSearchPage = this.navigateToSearchPage.bind(this);
  }

  componentDidMount() {
    const { mixpanelApiToken } = this.props.settings;
    const {
      mixpanel,
      viewer: { username }
    } = this.props;
    mixpanel.init(mixpanelApiToken, username);
  }

  handleChange(event) {
    // on click on suggestion,
    // event.target is the element clicked on.
    if (event.target.value !== undefined) {
      this.setState({ value: event.target.value });
    }
  }

  handleClear() {
    this.setState({ value: "" });
    this.searchInput.focus();
  }

  handleKeyDown(event) {
    const ENTER_KEY_CODE = 13;
    if (event.keyCode === ENTER_KEY_CODE || event.which === ENTER_KEY_CODE) {
      const suggestion = this.state.suggestionHighlighted;
      event.preventDefault();
      if (!suggestion) {
        this.navigateToSearchPage();
      }
    }
  }

  onStatsMouseDown = event => {
    // we need to stop the even propagation
    // otherwise react-autosuggest will prevent
    // the suggestion from being hidden on click
    event.stopPropagation();
    this.navigateToSearchPage();
  };
  navigateToSearchPage() {
    this.searchInput.blur();
    const to = {
      pathname: `${getRootUrl()}/search/`,
      query: { q: this.state.value }
    };
    this.props.router.push(to);
  }

  renderInputComponent = inputProps => {
    const { t } = this.props;
    const { ref, search, ...otherProps } = inputProps;
    const documentLabel = this.props.labels.documentLabelPlural.toLowerCase();
    const placeholder = t(
      "documentSearch.quickSearch.desktop.autocomplete.inputComponent.placeholder",
      { documentLabel }
    );
    return (
      <SearchInput
        inputRef={ref}
        onSearch={search}
        placeholder={placeholder}
        {...otherProps}
      />
    );
  };

  onSuggestionSelected(event, { suggestion, suggestionIndex }) {
    triggerQuickSearchEvent(
      this.state.value,
      suggestion.id,
      suggestion.name,
      suggestion.category_name,
      suggestionIndex
    );
    const { subdomain, pk } = this.props.tenant;
    const data = {
      resultIndex: suggestionIndex,
      tenant: subdomain,
      tenant_id: pk
    };

    this.props.mixpanel.sendEvent("search_dropdown_selected", data);
    this.props.router.push(
      getRootUrl() + "/policy/" + suggestion.id + "/latest"
    );
  }

  onSuggestionHighlighted({ suggestion }) {
    this.setState({ suggestionHighlighted: suggestion });
  }

  storeInputReference = autosuggest => {
    // this is how react-autosuggests recommends to get the ref to the input
    // https://github.com/moroshko/react-autosuggest/blob/master/FAQ.md#how-do-i-get-the-input-element
    if (autosuggest !== null) {
      this.searchInput = autosuggest.input;
    }
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <RelativePositioner>
        <div {...containerProps} role="none">
          <ConnectedSuggestionsContainer
            searchResults={this.props.searchResults}
            onStatsMouseDown={this.onStatsMouseDown}
          >
            {children}
          </ConnectedSuggestionsContainer>
        </div>
      </RelativePositioner>
    );
  };

  render() {
    return (
      <Autosuggest
        renderInputComponent={this.renderInputComponent}
        suggestions={this.props.hits}
        onSuggestionsFetchRequested={({ value }) => this.props.refine(value)}
        onSuggestionsClearRequested={() => this.props.refine("")}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionHighlighted={this.onSuggestionHighlighted}
        focusInputOnSuggestionClick={false}
        getSuggestionValue={hit => hit.name}
        renderSuggestion={hit => (
          <QuickSearchHit {...hit} labels={this.props.labels} />
        )}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        inputProps={{
          value: this.state.value,
          onChange: this.handleChange,
          onClear: this.handleClear,
          onKeyDown: this.handleKeyDown,
          search: this.navigateToSearchPage
        }}
        getSectionSuggestions={section => section.hits}
        ref={this.storeInputReference}
        containerProps={{
          role: "none"
        }}
      />
    );
  }
}

export const AutoComplete = withTenantContext(
  withMixpanelEvents(withSettings(withTranslation()(_AutoComplete)))
);
export const AutoCompleteDesktop = withLabelContext(
  withRouter(connectAutoComplete(connectStateResults(AutoComplete)))
);

// TODO cleanup all the fixed values
const HitsContainer = styled("div")`
  ${fontFamily};
  width: 100%;
  position: absolute;
  margin-top: ${themeGet("space.2")}px;

  background-color: ${themeGet("colors.nav.100")};
  border-radius: 6px;
  box-shadow: 0px 2px 2px 2px ${themeGet("colors.nav.90")};

  & ul::after {
    bottom: 100%;
    left: 40px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${themeGet("colors.nav.100")};
    border-width: 8px;
    margin-left: -8px;
  }

  & ul {
    list-style: none;
    margin: 0;
    padding: 0 ${themeGet("space.3")}px;
  }
  & ul li {
    border: solid 1px transparent;
    border-top: solid 1px ${themeGet("colors.nav.80")};
  }
  & ul li:first-child {
    border-top: solid 1px transparent;
    margin-top: ${themeGet("space.2")}px;
  }

  & ul li:last-child {
    margin-bottom: ${themeGet("space.2")}px;
  }

  & ul li.react-autosuggest__suggestion--highlighted {
    border-radius: 6px;
    border: solid 1px ${themeGet("colors.nav.80")} !important;
    background-color: ${themeGet("colors.nav.95")};
  }
  & ul li.react-autosuggest__suggestion--highlighted + li {
    border-top: solid 1px transparent !important;
  }
`;

const Stats = system({
  color: "nav.0",
  px: [4],
  py: [3],
  bg: "nav.95",
  borderRadius: "0 0 6px 6px"
});

export const ConnectedSuggestionsContainer = ({
  searchResults,
  onStatsMouseDown,
  children
}) => {
  const { t } = useTranslation();
  // we need to use mouse down for the FocusLink
  // because that is the event react-autosuggest is listening to
  // to determine if the click was inside the suggestion container
  // and if it is, it doesn't hide it.
  return children && searchResults && searchResults.query ? (
    searchResults.nbHits > 0 ? (
      <HitsContainer>
        {children}
        <Stats>
          {t(
            "documentSearch.quickSearch.desktop.connectedSuggestionsContainer.showCounter",
            { hitsLength: searchResults.hits.length }
          )}
          <Text>
            <FocusLink onMouseDown={onStatsMouseDown}>
              {t(
                "documentSearch.quickSearch.desktop.connectedSuggestionsContainer.allResults",
                { query: searchResults.query }
              )}
            </FocusLink>
          </Text>
        </Stats>
      </HitsContainer>
    ) : (
      <HitsContainer>
        <ul>
          <li>
            <HitWrapper>
              {t(
                "documentSearch.quickSearch.desktop.connectedSuggestionsContainer.noResults",
                { query: searchResults.query }
              )}
            </HitWrapper>
          </li>
        </ul>
      </HitsContainer>
    )
  ) : null;
};
