/**
 * @flow
 * @relayHash 8fff7974fe35fa27c1c4b888511e22e5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnsubscribedFromCommentsQueryVariables = {|
  documentPk: number
|};
export type UnsubscribedFromCommentsQueryResponse = {|
  +unsubscribedDocument: ?{|
    +document: ?{|
      +name: string,
      +link: ?string,
    |},
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type UnsubscribedFromCommentsQuery = {|
  variables: UnsubscribedFromCommentsQueryVariables,
  response: UnsubscribedFromCommentsQueryResponse,
|};
*/


/*
query UnsubscribedFromCommentsQuery(
  $documentPk: Int!
) {
  unsubscribedDocument(pk: $documentPk) {
    document {
      name
      link
      id
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "documentPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "link",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UnsubscribedFromCommentsQuery",
  "id": "774c906b1c2ffd2a0744f3ba15696723",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnsubscribedFromCommentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "unsubscribedDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "UnsubscribedDocumentType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          v4
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UnsubscribedFromCommentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "unsubscribedDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "UnsubscribedDocumentType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
node.text = "query UnsubscribedFromCommentsQuery(\n  $documentPk: Int!\n) {\n  unsubscribedDocument(pk: $documentPk) {\n    document {\n      name\n      link\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'fea6c8112607fe05d87c3db5e6e3c118';
module.exports = node;
