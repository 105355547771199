import { Box, Flex } from "grid-styled";
import { Fragment } from "react";
import { themeGet } from "styled-system";
import system from "system-components";

import { StyledFontAwesomeIcon } from "../../partials/icons";
import { H2 } from "../text";

import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";
import theme from "pstat-anywhere/themes/policystat/theme";

const ModalHeaderContainer = system({
  is: Flex,
  bg: "nav.100",
  justifyContent: "space-between",
  alignItems: "center",
  width: 1,
  pl: 5,
  pr: 4,
  py: 4,
  borderBottom: "1px solid",
  borderColor: "nav.80"
});

const CloseButtonContainer = system({
  is: Box
}).extend`
  &:focus {
    outline: 0;
  }
`;

const FocusBorder = system({
  display: "inline-block",
  border: 1,
  borderColor: "transparent",
  borderRadius: 1,
  p: 1,
  tabIndex: "-1"
}).extend`
  &:focus {
    outline: 0;
  }
  ${CloseButtonContainer}:focus > & {
    border: dashed 1px ${themeGet("nav.25")};
    borderRadius: ${themeGet("radii.1")};
  }
`;

const CloseButton = props => (
  <FocusBorder>
    <StyledFontAwesomeIcon
      icon={["fal", "times"]}
      size="lg"
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      color="nav.55"
      aria-label="close"
      ml={0}
      mr={0}
    />
  </FocusBorder>
);

const ModalHeader = ({ contentLabel, onRequestClose, tooltipText }) => {
  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onRequestClose();
    }
  };

  return (
    <ModalHeaderContainer>
      <Box>
        {tooltipText ? (
          <Fragment>
            <H2 color="nav.0" fontWeight={theme.fontWeights.normal}>
              {contentLabel}
              <StyledFontAwesomeIcon
                icon={["fal", "question-circle"]}
                color="secondary.0"
                size="sm"
                data-tip
                data-for="modal-tooltip"
                data-event={HOVER_EVENTS}
                data-event-off={HOVER_EVENTS_OFF}
                data-place="bottom"
                tabIndex="0"
              />
            </H2>
            <Tooltip name="modal-tooltip">{tooltipText}</Tooltip>
          </Fragment>
        ) : (
          <H2 color="nav.0" fontWeight={theme.fontWeights.normal}>
            {contentLabel}
          </H2>
        )}
      </Box>
      <CloseButtonContainer
        tabIndex="0"
        onKeyDown={handleKeyDown}
        role="button"
        aria-label="Close"
        data-testid="modal-header-close-button"
        id="modal-header-close-button"
      >
        <CloseButton onClick={onRequestClose} />
      </CloseButtonContainer>
    </ModalHeaderContainer>
  );
};

export default ModalHeader;
