import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation ForgotPasswordMutation($email: String!, $captcha: String!) {
    forgotPassword(email: $email, captcha: $captcha) {
      ok
      reasons
    }
  }
`;

export const doForgotPassword = (email, captcha, callback) => {
  const variables = {
    email: email,
    captcha: captcha
  };

  // TODO decide how to handle errors
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};
