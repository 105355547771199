import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { userDisplay } from "pstat-anywhere/utils/userDisplay";
import { Text } from "pstat-design-system/text/paragraph";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

export default function SignaturesTable({ signatures, showStepDescription }) {
  const { t } = useTranslation();
  return signatures.map(
    ({ approvalStep, approver, approvedDate, proxySigner, required }, i) => (
      <Flex
        width="100%"
        justifyContent="space-between"
        mb={2}
        key={i}
        position="relative"
      >
        {showStepDescription && (
          <Flex width="30%">
            <Text color="nav.25">
              {approvalStep && (required || required === undefined) ? approvalStep.name : ""}
            </Text>
          </Flex>
        )}
        <Flex width="30%">
          <Text color="nav.25">{userDisplay(approver, "reversedNames")}</Text>
          {proxySigner && (
            <Fragment>
              <Text
                color="nav.25"
                data-for={`proxy-signer-${i}`}
                data-tip
                data-event={HOVER_EVENTS}
                data-event-off={HOVER_EVENTS_OFF}
                data-delay-hide="2000"
              >
                {t("documentControl.view.approvalSignatures.proxy")}
              </Text>
              <Tooltip name={`proxy-signer-${i}`}>
                <Text color="nav.100">
                  {userDisplay(proxySigner, "reversedNames")}
                </Text>
              </Tooltip>
            </Fragment>
          )}
        </Flex>
        <Flex width="30%">
          <Text color="nav.25">
            {approvedDate === "N/A"
              ? t("documentControl.view.approvalSignatures.pending")
              : approvedDate}
          </Text>
        </Flex>
      </Flex>
    )
  );
}
