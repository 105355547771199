import isEmpty from "lodash/isEmpty";
import { Component, Fragment, memo } from "react";
import { withTranslation } from "react-i18next";
import system from "system-components";

import { editCommentOnDocument } from "../EditCommentMutation";

import HistoryCardHeader from "./HistoryCardHeader";
import HistoryItem from "./HistoryItem";

import Panel from "pstat-design-system/Panel";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import { environment } from "pstat-anywhere/relay";

// TODO: Remove onActivation prop from this component below in render
// When we will have /revisions page for policy
// For now, the url is correct, but onActivation force this component to doing nothing

// Exporting for testing purpose
export const StyledNoActionsMessage = system({
  is: "section",
  mt: 5,
  width: "100%"
});

class HistoryCard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      filterEdits: true,
      filterApprovals: true,
      filterComments: true,
      openedEditCommentAction: null
    };

    this.toggleApprovals = this.toggleApprovals.bind(this);
    this.toggleEdits = this.toggleEdits.bind(this);
    this.toggleComments = this.toggleComments.bind(this);
  }

  isEdit(actionType) {
    return !(
      actionType === "comment" ||
      actionType === "document_tenant_approved" ||
      actionType === "document_approved"
    );
  }

  isApproval(actionType) {
    return (
      actionType === "document_tenant_approved" ||
      actionType === "document_approved"
    );
  }

  isComment(actionType) {
    return actionType === "comment";
  }

  get filteredActions() {
    const actions = this.props.actions;

    if (!actions) {
      return null;
    }

    return actions.filter(action => {
      if (this.state.filterEdits && this.isEdit(action.actionType)) {
        return true;
      }
      if (this.state.filterApprovals && this.isApproval(action.actionType)) {
        return true;
      }
      if (this.state.filterComments && this.isComment(action.actionType)) {
        return true;
      }

      return false;
    });
  }

  toggleEdits() {
    this.setState({
      filterEdits: !this.state.filterEdits
    });
  }

  toggleApprovals() {
    this.setState({
      filterApprovals: !this.state.filterApprovals
    });
  }

  toggleComments() {
    this.setState({
      filterComments: !this.state.filterComments
    });
  }

  get filterHandlers() {
    return {
      edits: this.toggleEdits,
      approvals: this.toggleApprovals,
      comments: this.toggleComments
    };
  }

  handleOpenEditComment = action => {
    this.setState({ openedEditCommentAction: action });
  };
  handleDeleteComment = action => {
    const { document } = this.props;
    editCommentOnDocument({
      environment,
      document,
      comment: "",
      action,
      callback: this.deleteCommentCallback
    });
  };
  handleEditCommentSubmit = ({ action, newComment }) => {
    const { document } = this.props;
    editCommentOnDocument({
      environment,
      document,
      comment: newComment,
      action,
      callback: this.editCommentCallback
    });
  };
  editCommentCallback = (response, errors) => {
    if (errors) {
      errors.forEach(error => {
        createErrorMessage(error);
      });
    } else if (response.reasons) {
      Object.keys(response.reasons).forEach(reasonKey => {
        createErrorMessage(response.reasons[reasonKey]);
      });
    } else if (!errors && response.ok) {
      this.setState({ openedEditCommentAction: null });
    }
  };
  deleteCommentCallback = (response, errors) => {
    const { t } = this.props;
    if (errors) {
      errors.forEach(error => {
        createErrorMessage(error);
      });
    } else if (
      response.editComment.reasons &&
      !isEmpty(response.editComment.reasons)
    ) {
      Object.keys(response.editComment.reasons).forEach(reasonKey => {
        createErrorMessage(response.editComment.reasons[reasonKey]);
      });
    } else if (!errors && response.editComment.ok) {
      createSuccessMessage(
        t("documentControl.view.commentForm.deleteComment.successMessage")
      );
    }
  };
  render() {
    const { t, document } = this.props;
    if (this.filteredActions === null) {
      return null;
    }
    const actionsEmpty = this.filteredActions.length === 0;
    const approvalWorkflow = document?.approvalWorkflow ?? {};
    const currentApprovalStepId = approvalWorkflow.currentStep?.id;
    return (
      <Panel
        id="history"
        renderHeader={() => (
          <HistoryCardHeader
            handlers={this.filterHandlers}
            filterComments={this.state.filterComments}
            filterEdits={this.state.filterEdits}
            filterApprovals={this.state.filterApprovals}
          />
        )}
      >
        {!actionsEmpty ? (
          <Fragment>
            {this.filteredActions.map(action => (
              <HistoryItem
                key={action.id}
                action={action}
                isApproval={this.isApproval(action.actionType)}
                onOpenEditComment={this.handleOpenEditComment}
                onDeleteComment={this.handleDeleteComment}
                onEditCommentSubmit={this.handleEditCommentSubmit}
                editCommentOpened={
                  this.state.openedEditCommentAction &&
                  action.pk === this.state.openedEditCommentAction?.pk
                }
                workflowTitle={approvalWorkflow.name}
                approvalSteps={approvalWorkflow.steps}
                currentStepId={currentApprovalStepId}
              />
            ))}
          </Fragment>
        ) : (
          <StyledNoActionsMessage>
            {t("documentControl.view.historyCard.empty")}
          </StyledNoActionsMessage>
        )}
      </Panel>
    );
  }
}

export default memo(withTranslation()(HistoryCard));
