import graphql from "babel-plugin-relay/macro";
import { createFragmentContainer } from "react-relay";

import Content from "pstat-anywhere/components/view/content/Content";

const TemplateContent = ({ template }) => (
  <Content name={template.name} html={template.html} />
);

export default createFragmentContainer(
  TemplateContent,
  graphql`
    fragment Content_template on SystemDocumentTemplateType {
      name
      html
    }
  `
);
