import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentContainer } from "../../pages";

import ScheduledDocumentsCSVQuery from "./graphql/ScheduledDocumentsCSVQuery";

import { AdvancedReportPage } from "pstat-anywhere/components/pages/reports";
import { SearchBar } from "pstat-anywhere/components/pages/reports/components";
import useQueryVariables from "pstat-anywhere/components/pages/reports/hooks/useQueryVariables";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import titleCase from "pstat-anywhere/utils/titleCase";



const SearchBarFilter = ({ location, router }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(
    location.query.search_query || ""
  );

  useEffect(() => {
    const queryParams = { ...location.query };
    if (searchQuery) {
      queryParams.search_query = searchQuery;
    } else {
      delete queryParams.search_query;
    }

    const searchChanged =
      queryParams.search_query !== location.query.search_query;
    if (searchChanged) {
      const to = {
        pathname: location.pathname,
        query: queryParams
      };
      router.push(to);
    }
    // the linter wants location and router added to the dependencies array but that breaks
    // the whole page because it gets into an infinite loop reloading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <SearchBar
      placeholder={t(
        "documentReports.awaitingActivation.searchFilterPlaceholder"
      )}
      initialValue={searchQuery}
      onChange={setSearchQuery}
    />
  );
};

const ScheduledDoumentsReport = ({
  scheduledDocuments,
  route,
  router,
  location,
  labels,
  showAll
}) => {
  const { t } = useTranslation();
  const { variables } = useQueryVariables({ location });
  return (
    <ContentContainer>
      <AdvancedReportPage
        variables={variables}
        data={scheduledDocuments?.documents}
        route={route}
        router={router}
        location={location}
        tabs={[
          {
            id: "scheduled_report_mine",
            textKey: "documentReports.tabs.justMine",
            path: "policy_reports/awaiting_activation/scheduled/mine"
          },
          {
            id: "scheduled_report_all",
            textKey: "documentReports.tabs.all",
            path: "policy_reports/awaiting_activation/scheduled"
          }
        ]}
        headerText={t("documentReports.awaitingActivation.scheduled.title", {
          documentLabelPlural: titleCase(labels.documentLabelPlural)
        })}
        noContentMessage={t(
          "documentReports.awaitingActivation.scheduled.noResults",
          {
            documentLabelPlural: labels.documentLabelPlural.toLowerCase()
          }
        )}
        showTabsPanel={true}
        tableType={"scheduledDocuments"}
        frozenColumnTable={true}
        csvQueryName="scheduledDocumentsCsv"
        csvQueryArgs={{ showAll, search_query: location?.query?.search_query }}
        CSVQuery={ScheduledDocumentsCSVQuery}
        renderFilterPanel={() =>
          location && <SearchBarFilter location={location} router={router} />
        }
      />
    </ContentContainer>
  );
};

export default withLabelContext(ScheduledDoumentsReport);
