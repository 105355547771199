import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { LabelField } from "pstat-design-system/inputs/Labels";
import SearchInput from "pstat-design-system/inputs/SearchInput";

const CatalogSearchInput = ({ catalogSearch, onChange, onSearch, loading }) => {
  const { t } = useTranslation();

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSearch();
    }
  };

  return (
    <Container>
      <LabelField
        name={t("onesource.edit.catalogSearchLabel")}
        required={false}
        htmlFor="catalog_search"
      />
      <SearchInput
        id="catalog_search"
        value={catalogSearch}
        onChange={onChange}
        placeholder={t("onesource.edit.catalogSearchPlaceholder")}
        onSearch={onSearch}
        loading={loading}
        onKeyDown={handleKeyDown}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 342px;
`;

export default CatalogSearchInput;
