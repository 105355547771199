import graphql from "babel-plugin-relay/macro";

export const documentChangesQuery = graphql`
  query documentChangesQuery($fromPk: Int!, $toPk: Int!) {
    documentChanges(fromId: $fromPk, toId: $toPk) {
      initialHtml
      initialDocument {
        id
        pk
        name
        author {
          pk
          userDisplay
          firstName
          lastName
          profile {
            title
          }
        }
        category {
          pk
          name
        }
        bloc {
          id
          name
          tenants(sort: "name") {
            pk
            name
          }
        }
        parent {
          id
        }
        status
        restricted
        effectiveDate(formatted: true)
        approvalDate(formatted: true)
        expirationDate(formatted: true)
        revisionDate(formatted: true)
        statusChangedDate
        originationDate(formatted: true)
        legacyEffectiveDate(formatted: true)
        created
        modified
        headerImageUrl
        rssFeedUrl
        rawEffectiveDate: effectiveDate
        effectiveDateTimedelta

        attachments {
          title
          fileUrl
        }
        tags {
          pk
          name
        }
        approvalSignatures {
          approver {
            firstName
            lastName
            title
          }
          proxySigner {
            firstName
            lastName
            title
          }
          approvalStep {
            name
          }
          approvedDate(formatted: true)
          required
        }
        pastApprovalSignatures {
          approver {
            firstName
            lastName
            title
          }
          proxySigner {
            firstName
            lastName
            title
          }
          approvalStep {
            name
          }
          approvedDate(formatted: true)
          required
        }
        regulations {
          pk
          name
        }
        onesourceDocuments {
          pk
          name
          viewUrl
          revisionInformation
        }
      }
      taskId
    }
  }
`;

export default documentChangesQuery;
