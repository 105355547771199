/**
 * @flow
 * @relayHash 654934fe1ce49f8fe1efa021c6306425
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnpublishedDocumentsForTemplateCsvQueryVariables = {|
  pk: number
|};
export type UnpublishedDocumentsForTemplateCsvQueryResponse = {|
  +hiddenSystemDocumentTemplatesDocumentsCsv: ?{|
    +taskId: ?string
  |}
|};
export type UnpublishedDocumentsForTemplateCsvQuery = {|
  variables: UnpublishedDocumentsForTemplateCsvQueryVariables,
  response: UnpublishedDocumentsForTemplateCsvQueryResponse,
|};
*/


/*
query UnpublishedDocumentsForTemplateCsvQuery(
  $pk: Int!
) {
  hiddenSystemDocumentTemplatesDocumentsCsv(pk: $pk) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hiddenSystemDocumentTemplatesDocumentsCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pk",
        "variableName": "pk",
        "type": "Int!"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UnpublishedDocumentsForTemplateCsvQuery",
  "id": "640abc4763c1dc11979e6a641fd114e5",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnpublishedDocumentsForTemplateCsvQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UnpublishedDocumentsForTemplateCsvQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query UnpublishedDocumentsForTemplateCsvQuery(\n  $pk: Int!\n) {\n  hiddenSystemDocumentTemplatesDocumentsCsv(pk: $pk) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '541f0c67498ba8cda6bd67141fb2ec8a';
module.exports = node;
