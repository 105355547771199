// @flow

import { Component, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";
import styled from "styled-components";

import deleteDocumentAttachment from "./deleteDocumentAttachmentMutation";
import documentAttachments from "./documentAttachmentsQuery";
import editAttachment from "./editAttachmentMutation";
import newDocumentAttachment from "./newDocumentAttachmentMutation";
import newDocumentSaveAttachment from "./newDocumentSaveAttachmentMutation";

import Panel from "pstat-design-system/Panel";
import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import { LabelField } from "pstat-design-system/inputs/Labels";
import {
  AttachmentFormElement,
  FileUploadFormElement
} from "pstat-design-system/inputs/FormElements";
import {
  DOCUMENT_CONTENT_TYPE,
  DOCUMENT_SAVE_CONTENT_TYPE
} from "pstat-anywhere/utils/document";
import { environment } from "pstat-anywhere/relay";

export const fetchAttachments = (objectPk, objectType, setAttachments) => {
  fetchQuery(environment, documentAttachments, {
    objectPk: objectPk,
    objectType: objectType
  }).then(data => {
    if (!data) {
      return;
    }
    const attachments = data.documentAttachments;
    if (!attachments) {
      setAttachments([]);
      return;
    }
    const attachmentsWithLabel = attachments.map(attachment => {
      return { ...attachment, label: attachment.title };
    });
    setAttachments(attachmentsWithLabel);
  });
};

class Attachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      file: null,
      filename: ""
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.updateFilename = this.updateFilename.bind(this);
    this.handleAttachmentChange = this.props.handleAttachmentChange;
  }

  uploadFile() {
    const { document, documentSave, isOverridePage } = this.props;
    const variables = {
      title: this.state.filename,
      file: this.state.file // need to include the file in the variables for tests of the mutation to work
    };
    let mutation = null;
    let mutationFieldName = null;
    if (isOverridePage) {
      variables["documentPk"] = document.pk;
      mutation = newDocumentAttachment;
      mutationFieldName = "newDocumentAttachment";
    } else {
      variables["documentSavePk"] = documentSave.pk;
      mutation = newDocumentSaveAttachment;
      mutationFieldName = "newDocumentSaveAttachment";
    }
    const uploadables = {
      file: this.state.file
    };
    mutation(environment, variables, uploadables, (data, errors) => {
      if (errors) {
        throw new Error();
      }
      if (data[mutationFieldName].ok) {
        this.clearFile();
        this.fetchAttachmentsList();
        this.handleAttachmentChange();
      }
    });
  }

  clearFile = () => {
    this.setState({
      file: null,
      filename: ""
    });
  };

  updateFilename(file) {
    this.setState({
      file: file,
      filename: file.name
    });
  }

  componentDidMount() {
    this.fetchAttachmentsList();
  }

  fetchAttachmentsList = () => {
    const {
      document,
      documentSave,
      setAttachments,
      isOverridePage
    } = this.props;
    if (isOverridePage) {
      fetchAttachments(document.pk, DOCUMENT_CONTENT_TYPE, setAttachments);
    } else {
      fetchAttachments(
        documentSave.pk,
        DOCUMENT_SAVE_CONTENT_TYPE,
        setAttachments
      );
    }
  };

  handleAttachmentTitleChange = (attachmentPk, title) => {
    const attachmentsCopy = [...this.props.attachments];
    const updatedAttachments = attachmentsCopy.map(attachment => {
      if (attachment.pk === attachmentPk) {
        return { ...attachment, title };
      }
      return attachment;
    });
    this.props.updateAttachments(updatedAttachments);
  };

  deleteAttachment = attachmentPk => {
    const variables = { attachmentPk };
    deleteDocumentAttachment(environment, variables, (data, errors) => {
      if (errors) {
        throw new Error();
      }
      if (data.deleteDocumentAttachment.ok) {
        this.fetchAttachmentsList();
        this.handleAttachmentChange();
      }
    });
  };

  renameAttachment = (attachmentPk, title, setRenaming) => {
    const { t } = this.props;
    if (title.length === 0) {
      return;
    }
    setRenaming(true);
    const variables = { attachmentPk, title };
    const uploadables = null;
    editAttachment(environment, variables, uploadables, (data, errors) => {
      setRenaming(false);
      if (errors) {
        createErrorMessage(t("documentControl.create.attachments.renameError"));
      }
      if (data.editAttachment.ok) {
        this.fetchAttachmentsList();
        this.handleAttachmentChange();
      } else {
        createErrorMessage(t("documentControl.create.attachments.renameError"));
      }
    });
  };

  replaceAttachment = (attachmentPk, title, file, setReplacing) => {
    const { t } = this.props;
    if (title.length === 0) {
      return;
    }
    setReplacing(true);
    const variables = { attachmentPk, title, file };
    const uploadables = { file };
    editAttachment(environment, variables, uploadables, (data, errors) => {
      setReplacing(false);
      if (errors) {
        createErrorMessage(
          t("documentControl.create.attachments.replaceError")
        );
      }
      if (data.editAttachment.ok) {
        this.fetchAttachmentsList();
        this.props.handleAttachmentChange();
      } else {
        createErrorMessage(
          t("documentControl.create.attachments.replaceError")
        );
      }
    });
  };

  render() {
    const { fileID, t, attachments, editor, ckeditor5 } = this.props;

    return (
      <Panel
        name={t("documentControl.edit.attachmentsSection")}
        hideHeaderBorder={true}
        id="attachments"
      >
        <AddAttachmentContainer>
          <AddAttachment
            fileID={fileID}
            uploadFile={this.uploadFile}
            onFileChange={this.updateFilename}
            filename={this.state.filename}
          />
        </AddAttachmentContainer>
        <AttachmentList
          attachments={attachments}
          onChange={this.handleAttachmentTitleChange}
          onDelete={this.deleteAttachment}
          onRename={this.renameAttachment}
          onReplaceFile={this.replaceAttachment}
          editor={editor}
          ckeditor5={ckeditor5}
        />
      </Panel>
    );
  }
}

Attachments.defaultProps = {
  fileID: "fileID"
};

export default withTranslation()(Attachments);

const AttachmentListContainer = styled.div``;

const AttachmentList = ({
  attachments,
  onChange,
  onDelete,
  onRename,
  onReplaceFile,
  editor,
  ckeditor5
}) => (
  <AttachmentListContainer>
    {attachments.map((attachment, i) => (
      <AttachmentItem
        key={i}
        attachment={attachment}
        onChange={onChange}
        onDelete={onDelete}
        onRename={onRename}
        onReplaceFile={onReplaceFile}
        editor={editor}
        ckeditor5={ckeditor5}
      />
    ))}
  </AttachmentListContainer>
);

const AttachmentItemContainer = styled.div`
  margin-top: 27px;
`;

const AttachmentItem = ({
  attachment,
  onChange,
  onDelete,
  onRename,
  onReplaceFile,
  editor,
  ckeditor5
}) => {
  const { pk, title, fileUrl, label, isImage } = attachment;
  const [renaming, setRenaming] = useState(false);
  const [replacing, setReplacing] = useState(false);
  const handleChange = event => {
    const title = event.target.value;
    onChange(pk, title);
  };
  const handleClear = () => onChange(pk, "");
  const handleDelete = event => onDelete(pk);
  const handleRename = event => onRename(pk, title, setRenaming);
  const handleReplaceFile = file => {
    onReplaceFile(pk, title, file, setReplacing);
  };
  const handleInsertImage = event => {
    if (ckeditor5) {
      editor.execute("insertImage", {
        source: [
          {
            src: fileUrl,
            alt: title,
            title: title
          }
        ]
      });
    } else {
      const imgElement = new window.CKEDITOR.dom.element("img");
      const attributes = {
        src: fileUrl,
        title: title,
        alt: title
      };
      imgElement.setAttributes(attributes);
      editor.insertElement(imgElement);
      editor.widgets.initOn(imgElement, "image", attributes);
    }
  };
  return (
    <AttachmentItemContainer>
      <AttachmentFormElement
        attachmentPk={pk}
        onClear={handleClear}
        label={label}
        value={title}
        onChange={handleChange}
        allowRename={title.length > 0}
        handleRename={handleRename}
        handleReplaceFile={handleReplaceFile}
        handleDelete={handleDelete}
        renaming={renaming}
        replacing={replacing}
        isImage={isImage}
        editorLoading={!editor}
        handleInsertImage={handleInsertImage}
        isMissingFile={!fileUrl}
        fileUrl={fileUrl}
      />
    </AttachmentItemContainer>
  );
};

const AddAttachmentContainer = styled.div`
  margin-top: 19px;
`;

export const AddAttachment = ({
  fileID,
  onFileChange,
  filename,
  uploadFile,
  disabled,
  errors,
  uploadLabel
}) => {
  const { t } = useTranslation();
  const chooseFile = () => {
    // trigger file upload
    document.getElementById(fileID).click();
  };
  const handleFileChange = event => {
    const file = event.target.files[0];
    onFileChange(file);
  };

  return (
    <div>
      <LabelField
        name={
          uploadLabel || t("documentControl.create.attachments.addAttachment")
        }
      />
      <FileUploadFormElement
        fileID={fileID}
        chooseFile={chooseFile}
        uploadFile={uploadFile}
        onFileChange={handleFileChange}
        filename={filename}
        disabled={disabled}
        errors={errors}
      />
    </div>
  );
};
