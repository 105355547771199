/**
 * @flow
 * @relayHash 96a4b1c17c2246e38c3d4a4edddfe5b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SiteSelectionQueryVariables = {|
  sort?: ?string
|};
export type SiteSelectionQueryResponse = {|
  +blocsIdentity: ?{|
    +blocs: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +tenants: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +name: string,
        +subdomain: ?string,
        +created: ?string,
      |}>,
    |}>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |},
  +blocsAll: ?{|
    +blocs: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
      +tenants: ?$ReadOnlyArray<?{|
        +pk: ?number
      |}>,
    |}>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |},
  +currentTenant: ?{|
    +identityBloc: ?{|
      +pk: ?number,
      +name: string,
    |}
  |},
|};
export type SiteSelectionQuery = {|
  variables: SiteSelectionQueryVariables,
  response: SiteSelectionQueryResponse,
|};
*/


/*
query SiteSelectionQuery(
  $sort: String
) {
  blocsIdentity(sort: $sort) {
    blocs {
      pk
      tenants {
        pk
        name
        subdomain
        created
        id
      }
      id
    }
    error {
      statusCode
      message
    }
  }
  blocsAll {
    blocs {
      pk
      name
      tenants {
        pk
        id
      }
      id
    }
    error {
      statusCode
      message
    }
  }
  currentTenant {
    identityBloc {
      pk
      name
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subdomain",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SiteSelectionQuery",
  "id": "587976fa79eb4b93e7e572b27738f86a",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SiteSelectionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocsIdentity",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5
                ]
              }
            ]
          },
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocsAll",
        "storageKey": null,
        "args": null,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v2
                ]
              }
            ]
          },
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "identityBloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SiteSelectionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocsIdentity",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v4,
                  v5,
                  v7
                ]
              },
              v7
            ]
          },
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocsAll",
        "storageKey": null,
        "args": null,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v2,
                  v7
                ]
              },
              v7
            ]
          },
          v6
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "identityBloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v2,
              v3,
              v7
            ]
          },
          v7
        ]
      }
    ]
  }
};
})();
node.text = "query SiteSelectionQuery(\n  $sort: String\n) {\n  blocsIdentity(sort: $sort) {\n    blocs {\n      pk\n      tenants {\n        pk\n        name\n        subdomain\n        created\n        id\n      }\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n  blocsAll {\n    blocs {\n      pk\n      name\n      tenants {\n        pk\n        id\n      }\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n  currentTenant {\n    identityBloc {\n      pk\n      name\n      id\n    }\n    id\n  }\n}\n";
(node/*: any*/).hash = '831cf65f9ad8df39c5aa14112655bd91';
module.exports = node;
