/**
 * @flow
 * @relayHash fe9662fe3c84cb8003ca7b59339c7050
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NoticeSettingInputType = {
  noticeType?: ?number,
  send?: ?boolean,
};
export type NoticeSettingsMutationVariables = {|
  settings: $ReadOnlyArray<?NoticeSettingInputType>
|};
export type NoticeSettingsMutationResponse = {|
  +noticeSettings: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type NoticeSettingsMutation = {|
  variables: NoticeSettingsMutationVariables,
  response: NoticeSettingsMutationResponse,
|};
*/


/*
mutation NoticeSettingsMutation(
  $settings: [NoticeSettingInputType]!
) {
  noticeSettings(settings: $settings) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "settings",
    "type": "[NoticeSettingInputType]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "noticeSettings",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "settings",
        "variableName": "settings",
        "type": "[NoticeSettingInputType]!"
      }
    ],
    "concreteType": "NoticeSettings",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "NoticeSettingsMutation",
  "id": "700d335957278aab1cd78479cc3c64f1",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NoticeSettingsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "NoticeSettingsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation NoticeSettingsMutation(\n  $settings: [NoticeSettingInputType]!\n) {\n  noticeSettings(settings: $settings) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = '295d9223ec71ddf81b79c506237beb58';
module.exports = node;
