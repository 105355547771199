import { forwardRef } from "react";
import { themeGet } from "styled-system";
import system from "system-components";

import theme from "../../../themes/policystat/theme";

const defaultSystemProps = {
  is: "td",
  color: "secondary.0",
  pl: 1,
  pt: 3, // needs to be split to allow for overridding pb on its own
  pb: 3,
  border: 1,
  borderColor: "nav.90"
};

const TD = system(
  defaultSystemProps,
  "borderTop",
  "borderColor",
  "maxWidth",
  "minWidth",
  "space",
  "width"
).extend`
  @media (max-width: ${themeGet("breakpoints.1")}) {
    tbody tr &:first-child {
        padding-top: ${theme.space[3]}px;
        padding-bottom:  ${theme.space[1]}px;
    }
    tbody tr &:last-child {
      padding-top: ${theme.space[1]}px;
      padding-bottom:  ${theme.space[3]}px;
    }
    tbody tr:first-child & {
      padding-top: 0px;
    }
    tbody tr:last-child & {
      padding-bottom: 0px;
    }
  }
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break:break-all; /*IE HACK */ 
  word-break:break-word; 
  &:focus {
    outline: 0;
  }
  tr &:first-child {
    border-left: none;
  }
  tr &:last-child {
    border-right: none;
  }
`;

//The IE Hack mentioned is quite simple; IE11 dont support break-word, so it'll ignore it use break-all.
//On modern browsers, the break-word is supported, so the browser will override break-all with break-word
//because it's the latest attribute.

const RefForwardingTD = forwardRef((props, ref) => (
  <TD {...props} innerRef={ref}>
    {props.children}
  </TD>
));
export { RefForwardingTD as TD };

// had to add the border and color in the 'extend, for some reason color wasn't working
// if added outside of it
export const StackingTD = system({
  is: TD,
  display: ["block", "block", "table-cell"]
}).extend`
  border: none;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    border: ${themeGet("borders.1")};
    border-color: ${themeGet("colors.nav.90")};
  }
`;

export const FrozenColumnTD = system({
  is: RefForwardingTD,
  position: "absolute",
  width: 150,
  left: 0,
  top: "auto",
  border: 0,
  borderTop: 1,
  borderColor: "nav.90"
}).extend`
  table tr:first-child & {
    border-top: ${themeGet("borders.1")};
    border-top-color: ${themeGet("colors.nav.80")};
  }
  table tr:last-child & {
    border-bottom: ${themeGet("borders.1")};
    border-bottom-color: ${themeGet("colors.nav.90")};
  }
`;

export const FrozenTableTD = system({
  is: RefForwardingTD,
  border: 0,
  borderTop: 1,
  borderLeft: 1,
  borderColor: "nav.90"
}).extend`
  vertical-align: top;
  table tr:first-child & {
    border-top: ${themeGet("borders.1")};
    border-top-color: ${themeGet("colors.nav.80")};
  }
  table tr:last-child & {
    border-bottom: ${themeGet("borders.1")};
    border-bottom-color: ${themeGet("colors.nav.90")};
  }
`;

export default TD;
