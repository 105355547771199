import styled from "styled-components";
import { themeGet } from "styled-system";

import {
  DuoToneFontAwesomeIcon,
  StyledFontAwesomeIcon
} from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";

const BaseIconContainer = styled("div")`
  min-width: 28px;
  height: 28px;
  padding-top: ${themeGet("space.1")}px;
  margin-right: ${themeGet("space.4")}px;
  border-radius: 50%;
  & > ${StyledFontAwesomeIcon} {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`;
const ErrorIconOuterContainer = styled(BaseIconContainer)`
  background-color: ${themeGet("colors.banners.error.common")};
`;
const SuccessIconOuterContainer = styled(BaseIconContainer)`
  background-color: ${themeGet("colors.banners.success.common")};
`;
const WarningIconOuterContainer = styled(BaseIconContainer)`
  background-color: ${themeGet("colors.banners.warning.common")};
`;
const InfoExclamationIconOuterContainer = styled(BaseIconContainer)`
  background-color: ${themeGet("colors.banners.info.common")};
  margin-right: ${themeGet("space.2")}px;
`;

const InfoIconOuterContainer = styled(BaseIconContainer)`
  position: relative;
  background-color: ${themeGet("colors.banners.info.dark")};
  margin-right: ${themeGet("space.3")}px;
`;

const CenteredInfoIcon = styled(DuoToneFontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export function ErrorIcon() {
  return (
    <ErrorIconOuterContainer>
      <StyledFontAwesomeIcon
        icon={["fal", "info-circle"]}
        color="banners.error.dark"
        bg="nav.100"
        mr={0}
      />
    </ErrorIconOuterContainer>
  );
}

export function SuccessIcon() {
  return (
    <SuccessIconOuterContainer>
      <StyledFontAwesomeIcon
        icon={["fal", "check-circle"]}
        color="banners.success.dark"
        bg="nav.100"
        mr={0}
      />
    </SuccessIconOuterContainer>
  );
}

export function WarningIcon() {
  return (
    <WarningIconOuterContainer>
      <StyledFontAwesomeIcon
        icon={["fal", "info-circle"]}
        color="banners.warning.dark"
        bg="nav.100"
        mr={0}
      />
    </WarningIconOuterContainer>
  );
}

export function InfoIcon() {
  return (
    <InfoIconOuterContainer>
      <CenteredInfoIcon
        icon={["fad", "info-circle"]}
        primaryColor={theme.colors.banners.info.dark}
        secondaryColor={theme.colors.nav["100"]}
        bg="nav.100"
        ml={0}
        mr={0}
        size="lg"
      />
    </InfoIconOuterContainer>
  );
}

export function InfoExclamationIcon() {
  return (
    <InfoExclamationIconOuterContainer>
      <StyledFontAwesomeIcon
        icon={["far", "exclamation-circle"]}
        color="banners.info.dark"
        bg="nav.100"
        mr={0}
      />
    </InfoExclamationIconOuterContainer>
  );
}

export function MaintenanceBannerIcon() {
  const MaintenanceIconContainer = styled("div")`
    min-width: 28px;
  `;
  return (
    <MaintenanceIconContainer>
      <StyledFontAwesomeIcon
        icon={["fas", "exclamation-triangle"]}
        color="nav.100"
        bg="banners.maintenance.dark"
        mr={0}
      />
    </MaintenanceIconContainer>
  );
}

export function TrainingBannerIcon() {
  const TrainingIconContainer = styled("div")`
    min-width: 28px;
  `;
  return (
    <TrainingIconContainer>
      <StyledFontAwesomeIcon
        icon={["fas", "exclamation-triangle"]}
        color="nav.0"
        bg="banners.training.dark"
        mr={0}
      />
    </TrainingIconContainer>
  );
}
