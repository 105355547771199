import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import resources from "./locales";

// TODO: we temporally remove i18next backend due to problems with deployment
// It gonna be really nice improvement to add it back and resolve problems with deploy
i18n
  .use(LanguageDetector) // For automatic language indication
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    lng: "en",
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    keySeparator: false
  });

export default i18n;
