import graphql from "babel-plugin-relay/macro";
import Media from "react-media";
import { createFragmentContainer } from "react-relay";

import Info from "pstat-anywhere/components/system_document_templates/view_template/Info";
import {
  PkDisplay,
  StatusBarContainer,
  StatusBarFlex,
  StatusDisplay
} from "pstat-anywhere/components/view/StatusBar.jsx";
import theme from "pstat-anywhere/themes/policystat/theme";

export const StatusBar = ({ template }) => {
  return (
    <StatusBarContainer>
      <StatusBarFlex>
        <StatusDisplay status={template.status} />
        <Media query={{ minWidth: theme.breakpoints[1] }}>
          {isDesktop => isDesktop && <PkDisplay pk={template.pk} />}
        </Media>
      </StatusBarFlex>
      <StatusBarFlex>
        <Media query={{ minWidth: theme.breakpoints[1] }}>
          {isDesktop => !isDesktop && <Info template={template} />}
        </Media>
      </StatusBarFlex>
    </StatusBarContainer>
  );
};

const StatusBarFragmentContainer = createFragmentContainer(
  StatusBar,
  graphql`
    fragment StatusBar_template on SystemDocumentTemplateType {
      pk
      status
      ...Info_template
    }
  `
);

export default StatusBarFragmentContainer;
