/**
 * @flow
 * @relayHash c21d056a6db3891727ddf14076d1e718
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditCommentOnDocumentInput = {
  comment: string,
  actionPk: number,
  clientMutationId?: ?string,
};
export type EditCommentMutationVariables = {|
  input: EditCommentOnDocumentInput
|};
export type EditCommentMutationResponse = {|
  +editComment: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +documentAction: ?{|
      +id: string,
      +pk: ?number,
      +canEdit: ?boolean,
      +actionDate: any,
      +actionType: string,
      +comment: string,
      +isExternal: boolean,
    |},
  |}
|};
export type EditCommentMutation = {|
  variables: EditCommentMutationVariables,
  response: EditCommentMutationResponse,
|};
*/


/*
mutation EditCommentMutation(
  $input: EditCommentOnDocumentInput!
) {
  editComment(input: $input) {
    ok
    reasons
    documentAction {
      id
      pk
      canEdit
      actionDate
      actionType
      comment
      isExternal
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EditCommentOnDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "editComment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EditCommentOnDocumentInput!"
      }
    ],
    "concreteType": "EditCommentOnDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentAction",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentActionType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pk",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "canEdit",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "actionDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "actionType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "comment",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isExternal",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "EditCommentMutation",
  "id": "a527fc8ee3c53c712d21e01511dc4983",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditCommentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EditCommentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation EditCommentMutation(\n  $input: EditCommentOnDocumentInput!\n) {\n  editComment(input: $input) {\n    ok\n    reasons\n    documentAction {\n      id\n      pk\n      canEdit\n      actionDate\n      actionType\n      comment\n      isExternal\n    }\n  }\n}\n";
(node/*: any*/).hash = 'cf7d93d8e2ceeb576fe69664f71cbc6f';
module.exports = node;
