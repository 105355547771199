import { Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { QuestionIcon } from "pstat-design-system/Icons";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import { LabelField } from "pstat-design-system/inputs/Labels";
import Textarea from "pstat-design-system/inputs/Textarea";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";

// @flow

type Props = {
  id: string,
  onApprove: Function,
  isOpen: boolean,
  variant: | "approval"
    | "finalApprovalNoEffectiveDate"
    | "finalApprovalWithEffectiveDate",
  onClose?: Function
};

export function _ApprovalModal({
  id,
  onApprove,
  isOpen,
  onClose,
  variant,
  labels
}: Props) {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { documentLabel } = labels;
  const isFinalApproval =
    variant === "finalApprovalNoEffectiveDate" ||
    variant === "finalApprovalWithEffectiveDate";
  const selectedValues = isVerified ? ["verified"] : [];
  const submitButtonDisabled = isFinalApproval && !isVerified;
  function handleCommentChange({ target: { value } }) {
    setComment(value);
  }
  function handleSubmit(event) {
    event.preventDefault();
    // Do not call onApprove if not verified
    if ((!isFinalApproval || isVerified) && !processing) {
      setProcessing(true);
      onApprove({ comment });
      onClose();
    }
  }
  function handleChangeIsVerified() {
    setIsVerified(!isVerified);
  }
  const handleCancelKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClose();
    }
  };
  const handleApproveKeyDown = event => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      contentLabel={t("documentControl.approve.modal.contentLabel", {
        documentLabel
      })}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Button
              id="submit_approval_button"
              buttonStyle="secondary"
              data-testid="approval-submit-button"
              disabled={submitButtonDisabled}
              processing={processing}
              onClick={handleSubmit}
              onKeyDown={handleApproveKeyDown}
            >
              {t("buttons.approve")}
            </Button>
            <Button
              buttonStyle="tertiary"
              onClick={onClose}
              onKeyDown={handleCancelKeyDown}
            >
              {t("buttons.cancel")}
            </Button>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={3} pb={5}>
        <form
          name="approval-form"
          id="approval-form"
          onSubmit={handleSubmit}
          data-testid="approval-form"
        >
          <LabelField name={t("documentControl.view.filters.comments")} />
          <Textarea
            rows={4}
            onChange={handleCommentChange}
            value={comment}
            data-testid="approval-comment-textarea"
            width="100%"
            borderRadius={theme.radii[1]}
            my={theme.space[1]}
          />
          {isFinalApproval && (
            <CheckboxGroup
              name="approve-verifying"
              selectedValues={selectedValues}
              onChange={handleChangeIsVerified}
            >
              <Checkbox
                value="verified"
                renderLabel={() => (
                  <Fragment>
                    <Text color="nav.0" size="normal">
                      {t(
                        variant === "finalApprovalNoEffectiveDate"
                          ? "documentControl.approve.modal.verifyLabel.noEffectiveDate"
                          : "documentControl.approve.modal.verifyLabel.withEffectiveDate",
                        { documentLabel: documentLabel.toLowerCase() }
                      )}
                    </Text>
                    <QuestionIcon />
                  </Fragment>
                )}
              />
            </CheckboxGroup>
          )}
        </form>
      </Flex>
    </Modal>
  );
}

export default withLabelContext(_ApprovalModal);
