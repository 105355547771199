import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ContentContainerFlex } from "pstat-anywhere/components/pages";
import { WarningBanner } from "pstat-design-system/banner";
import { Text } from "pstat-design-system/text";

const SystemDocumentTemplateWarningBanner = ({
  isSystemTemplateRetired,
  isPublishedTemplate,
  isNewVersionTemplateAvailable,
  documentLabel
}) => {
  const { t } = useTranslation();

  let messageTitleKey = null;
  let messageBodyKey = null;
  if (isSystemTemplateRetired) {
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showRetiredBanner";
  } else if (!isPublishedTemplate) {
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showUnpublishedBannerMsg";
    messageTitleKey =
      "documentControl.view.systemDocumentTemplate.showUnpublishedBannerTitle";
  } else if (isNewVersionTemplateAvailable) {
    messageBodyKey =
      "documentControl.view.systemDocumentTemplate.showNewVersionAvailableBanner";
  }
  return (
    <Fragment>
      {messageBodyKey && (
        <ContentContainerFlex>
          <WarningBanner width={"100%"}>
            <Flex flexDirection="column">
              {messageTitleKey ? (
                <Text color="nav.0" fontWeight="medium">
                  {t(messageTitleKey, { documentLabel })}
                </Text>
              ) : (
                ""
              )}
              <Text color="nav.0" mt={1}>
                {t(messageBodyKey, { documentLabel })}
              </Text>
            </Flex>
          </WarningBanner>
        </ContentContainerFlex>
      )}
    </Fragment>
  );
};

export default SystemDocumentTemplateWarningBanner;
