import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { getPublishedTemplateRecord } from "pstat-anywhere/components/system_document_templates/utils";
import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation UnpublishTemplateMutation(
    $input: UnpublishSystemDocumentTemplateInput!
  ) {
    unpublishSystemDocumentTemplate(input: $input) {
      ok
      result
      error
    }
  }
`;

function unpublishTemplate(templatePk, location, callback) {
  const variables = {
    input: {
      systemDocumentTemplate: templatePk
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    },
    updater: store => {
      const storedPublishedTemplates = getPublishedTemplateRecord(
        store,
        templatePk,
        location
      );
      if (!storedPublishedTemplates) {
        return;
      }
      const totalCount = storedPublishedTemplates.getValue("totalCount");
      storedPublishedTemplates.setValue(totalCount - 1, "totalCount");

      const edgesRecords = storedPublishedTemplates.getLinkedRecords("edges");
      const updatedRecords = edgesRecords.filter(
        item => item.getLinkedRecord("node").getValue("pk") !== templatePk
      );
      storedPublishedTemplates.setLinkedRecords(updatedRecords, "edges");
    }
  });
}

export default unpublishTemplate;
