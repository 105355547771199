import { Box } from "grid-styled";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import styled from "styled-components";
import { position, space, themeGet } from "styled-system";

import theme from "../../themes/policystat/theme";

import { SmallOnlyFlex } from "./flexUtils";
import { StyledFontAwesomeIcon } from "./icons";

import { layer } from "pstat-design-system/utils";
import { Text } from "pstat-design-system/text";

const DropdownContainer = styled.div`
  width: 100%;
  overflow-x: visible;
  ${layer};
`;

const DesktopDropdownContainer = styled(DropdownContainer)`
  left: 0;
  height: auto;
  position: absolute;
  overflow-y: visible;
  background-color: ${themeGet("colors.nav.100")};
  color: ${themeGet("colors.nav.0")};
  transition: none;
  ${props => props.up && `top: 0;`}
`;

const MobileDropdownContainer = styled(DropdownContainer)`
  position: fixed;
  height: 100%;
  left: ${props => (props.expanded ? `0` : `120%`)};
  background-color: ${themeGet("colors.nav.0")};
  color: ${themeGet("colors.nav.100")};
  overflow-y: scroll;
  transition: left 0.3s ease;
`;

DropdownContainer.defaultProps = {
  layer: "activeFloatingUi"
};

DropdownContainer.displayName = "DropdownContainer";

const DropdownListContainer = styled.div`
  ${space};
  visibility: ${props => (props.expanded ? "visible" : "hidden")};
`;

const DesktopDropdownListContainer = styled(DropdownListContainer)`
  display: block;
  position: absolute;
  width: ${props => (props.width ? props.width : "auto")};
  min-width: 100%;
  ${props => (props.rtl ? `right: 0` : `left: 0`)};
  ${props => props.up && `bottom: 0px`}
`;

const DropdownList = styled.ul.attrs({
  role: "menu"
})`
  ${space};
  opacity: ${props => (props.expanded ? 1 : 0)};
  transition: visibility 0.25s linear, opacity 0.25s linear;
`;

const DesktopDropdownList = styled(DropdownList)`
  display: block;
  list-style-type: none;

  border: 2px solid ${themeGet("colors.nav.90")};
  border-radius: 12px;
  background-color: ${themeGet("colors.nav.100")};
  box-shadow: 0 0 15px 0 rgba(24, 45, 74, 0.25);

  &:before,
  &:after {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    border: 16px solid transparent; /* arrow size */
  }

  &:before {
    ${props =>
      props.up ? `border-top-color:` : `border-bottom-color:`} ${themeGet(
      "colors.nav.100"
    )}; /* arrow color */

    /* positioning */
    position: absolute;
    ${props => (props.up ? `bottom:` : `top:`)} -13px;

    ${props => (props.rtl ? `right: ` : `left: `)} 8px;
    z-index: 2;
  }

  &:after {
    ${props =>
      props.up ? `border-top-color:` : `border-bottom-color:`} ${themeGet(
      "colors.nav.90"
    )}; /* arrow color */

    /* positioning */
    position: absolute;
    ${props => (props.up ? `bottom:` : `top:`)} -15px;
    ${props => (props.rtl ? `right: ` : `left: `)} 8px;
    z-index: 1;
  }
`;

DropdownList.displayName = "DropdownList";

const FullHeightDiv = styled.div`
  height: inherit;
  display: inline-block;
  position: static;
  ${position}
`;

const DropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DropdownSelectorContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: inherit;
  z-index: ${themeGet("layers.activeFloatingUi")};
`;

const ToggleMenuControl = styled.a`
  display: inline-block;

  &:focus {
    outline: 0;
  }
`;

const ToggleMenuFocusBorder = styled.div`
  border: 1px dashed;
  border-color: transparent;
  border-radius: ${themeGet("radii.1")}px;
  padding: ${themeGet("space.1")}px;

  &:focus {
    outline: 0;
  }
  ${ToggleMenuControl}:focus > & {
    border: dashed 1px ${themeGet("nav.100")};
  }
`;

const CloseMenuControl = styled.a`
  display: inline-block;

  &:focus {
    outline: 0;
  }
`;

const CloseMenuFocusBorder = ToggleMenuFocusBorder.extend`
  ${CloseMenuControl}:focus > & {
    border: dashed 1px ${themeGet("nav.100")};
  }
`;

export const Dropdown = props => {
  const keyboardToggle = (event, toggleFunc) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleFunc();
    }
  };

  const handlePointerLeave = event => {
    if (props.desktop) {
      props.closeMenu();
    }
  };
  const { customSelectorContainer } = props;
  const SelectorContainer =
    customSelectorContainer || DropdownSelectorContainer;

  const { t } = useTranslation();
  let DropdownContainerComponent = MobileDropdownContainer;
  let DropdownListContainerComponent = DropdownListContainer;
  let DropdownListComponent = DropdownList;
  if (props.desktop) {
    DropdownContainerComponent = DesktopDropdownContainer;
    DropdownListContainerComponent = DesktopDropdownListContainer;
    DropdownListComponent = DesktopDropdownList;
  }
  return (
    <FullHeightDiv>
      {props.expanded && props.desktop && (
        <DropdownOverlay
          onClick={props.closeMenu}
          data-testid="dropdownmenu-overlay"
        />
      )}
      <FullHeightDiv
        onPointerLeave={handlePointerLeave}
        data-testid="dropdownmenu-pointerleave"
        position="relative"
      >
        <SelectorContainer>{props.dropdownSelector(props)}</SelectorContainer>
        <DropdownContainerComponent expanded={props.expanded} up={props.up}>
          {!props.desktop && (
            <SmallOnlyFlex
              justify="space-between"
              align="center"
              width="100%"
              p={4}
              borderBottom="1px solid"
              height="52px"
            >
              <Box>
                <ToggleMenuControl
                  onClick={props.toggle}
                  onKeyDown={event => keyboardToggle(event, props.toggle)}
                  tabIndex={props.expanded ? "0" : "-1"}
                >
                  <ToggleMenuFocusBorder tabIndex="-1">
                    <StyledFontAwesomeIcon
                      icon={["fal", "caret-left"]}
                      size="lg"
                      color="primary.0"
                    />
                    <Text size="normal" color="inherit">
                      {t("dropdownMenu.backToMenu")}
                    </Text>
                  </ToggleMenuFocusBorder>
                </ToggleMenuControl>
              </Box>
              <Box>
                <CloseMenuControl
                  onClick={props.closeMenu}
                  onKeyDown={event => keyboardToggle(event, props.closeMenu)}
                  tabIndex={props.expanded ? "0" : "-1"}
                  data-testid="submenu-fa-close"
                >
                  <CloseMenuFocusBorder tabIndex="-1">
                    <StyledFontAwesomeIcon
                      icon={["far", "times"]}
                      size="lg"
                      color="primary.0"
                    />
                  </CloseMenuFocusBorder>
                </CloseMenuControl>
              </Box>
            </SmallOnlyFlex>
          )}
          <DropdownListContainerComponent
            expanded={props.expanded}
            rtl={props.rtl}
            up={props.up}
            width={props.width}
          >
            <DropdownListComponent
              expanded={props.expanded}
              my={[4]}
              p={0}
              rtl={props.rtl}
              up={props.up}
            >
              {props.children}
            </DropdownListComponent>
          </DropdownListContainerComponent>
        </DropdownContainerComponent>
      </FullHeightDiv>
    </FullHeightDiv>
  );
};

const DropdownMenu = props => {
  return (
    <Media query={{ minWidth: theme.breakpoints[1] }}>
      {matches =>
        matches ? (
          <Dropdown desktop={true} {...props} />
        ) : (
          <Dropdown {...props} />
        )
      }
    </Media>
  );
};

DropdownMenu.propTypes = {
  dropdownSelector: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  rtl: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired
};

DropdownMenu.defaultProps = {
  rtl: false
};

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
