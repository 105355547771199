/**
 * @flow
 * @relayHash e51573712c515fdbf67961ce6ac2b98d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type TokenViewPolicyQueryVariables = {|
  tokenId: any
|};
export type TokenViewPolicyQueryResponse = {|
  +documentTokenAccessView: ?{|
    +id: string,
    +pk: ?number,
    +name: string,
    +html: ?string,
    +author: {|
      +pk: ?number,
      +userDisplay: ?string,
      +firstName: string,
      +lastName: string,
      +profile: ?{|
        +title: ?string
      |},
    |},
    +category: {|
      +pk: ?number,
      +name: string,
    |},
    +bloc: {|
      +id: string,
      +name: string,
      +tenants: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +name: string,
      |}>,
    |},
    +parent: ?{|
      +id: string
    |},
    +isAForm: boolean,
    +status: ?DocumentStatus,
    +restricted: boolean,
    +effectiveDate: ?string,
    +approvalDate: ?string,
    +expirationDate: ?string,
    +revisionDate: ?string,
    +statusChangedDate: ?string,
    +originationDate: ?string,
    +legacyEffectiveDate: ?string,
    +created: ?string,
    +modified: ?any,
    +headerImageUrl: ?string,
    +attachments: ?$ReadOnlyArray<?{|
      +title: ?string,
      +fileUrl: ?string,
    |}>,
    +tags: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
    |}>,
  |}
|};
export type TokenViewPolicyQuery = {|
  variables: TokenViewPolicyQueryVariables,
  response: TokenViewPolicyQueryResponse,
|};
*/


/*
query TokenViewPolicyQuery(
  $tokenId: UUID!
) {
  documentTokenAccessView(tokenId: $tokenId) {
    id
    pk
    name
    html
    author {
      pk
      userDisplay
      firstName
      lastName
      profile {
        title
        id
      }
      id
    }
    category {
      pk
      name
      id
    }
    bloc {
      id
      name
      tenants {
        pk
        name
        id
      }
    }
    parent {
      id
    }
    isAForm
    status
    restricted
    effectiveDate(formatted: true)
    approvalDate(formatted: true)
    expirationDate(formatted: true)
    revisionDate(formatted: true)
    statusChangedDate
    originationDate(formatted: true)
    legacyEffectiveDate(formatted: true)
    created
    modified
    headerImageUrl
    attachments {
      title
      fileUrl
      id
    }
    tags {
      pk
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tokenId",
    "type": "UUID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "tokenId",
    "variableName": "tokenId",
    "type": "UUID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "html",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userDisplay",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v10 = [
  v3,
  v4
],
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "parent",
  "storageKey": null,
  "args": null,
  "concreteType": "RLDatixDocumentType",
  "plural": false,
  "selections": [
    v2
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isAForm",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "formatted",
    "value": true,
    "type": "Boolean"
  }
],
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": v15,
  "storageKey": "effectiveDate(formatted:true)"
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": v15,
  "storageKey": "approvalDate(formatted:true)"
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": v15,
  "storageKey": "expirationDate(formatted:true)"
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDate",
  "args": v15,
  "storageKey": "revisionDate(formatted:true)"
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "statusChangedDate",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "originationDate",
  "args": v15,
  "storageKey": "originationDate(formatted:true)"
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": v15,
  "storageKey": "legacyEffectiveDate(formatted:true)"
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "modified",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "headerImageUrl",
  "args": null,
  "storageKey": null
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileUrl",
  "args": null,
  "storageKey": null
},
v27 = [
  v3,
  v4,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TokenViewPolicyQuery",
  "id": "d5d13e22b9b0b8a71be9a05ce72221ca",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TokenViewPolicyQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentTokenAccessView",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentType",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "UserType",
            "plural": false,
            "selections": [
              v3,
              v6,
              v7,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "ProfileType",
                "plural": false,
                "selections": [
                  v9
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryType",
            "plural": false,
            "selections": v10
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v2,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": v10
              }
            ]
          },
          v11,
          v12,
          v13,
          v14,
          v16,
          v17,
          v18,
          v19,
          v20,
          v21,
          v22,
          v23,
          v24,
          v25,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "attachments",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAttachmentType",
            "plural": true,
            "selections": [
              v9,
              v26
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagType",
            "plural": true,
            "selections": v10
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TokenViewPolicyQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentTokenAccessView",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentType",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "UserType",
            "plural": false,
            "selections": [
              v3,
              v6,
              v7,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "ProfileType",
                "plural": false,
                "selections": [
                  v9,
                  v2
                ]
              },
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryType",
            "plural": false,
            "selections": v27
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v2,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": v27
              }
            ]
          },
          v11,
          v12,
          v13,
          v14,
          v16,
          v17,
          v18,
          v19,
          v20,
          v21,
          v22,
          v23,
          v24,
          v25,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "attachments",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAttachmentType",
            "plural": true,
            "selections": [
              v9,
              v26,
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tags",
            "storageKey": null,
            "args": null,
            "concreteType": "TagType",
            "plural": true,
            "selections": v27
          }
        ]
      }
    ]
  }
};
})();
node.text = "query TokenViewPolicyQuery(\n  $tokenId: UUID!\n) {\n  documentTokenAccessView(tokenId: $tokenId) {\n    id\n    pk\n    name\n    html\n    author {\n      pk\n      userDisplay\n      firstName\n      lastName\n      profile {\n        title\n        id\n      }\n      id\n    }\n    category {\n      pk\n      name\n      id\n    }\n    bloc {\n      id\n      name\n      tenants {\n        pk\n        name\n        id\n      }\n    }\n    parent {\n      id\n    }\n    isAForm\n    status\n    restricted\n    effectiveDate(formatted: true)\n    approvalDate(formatted: true)\n    expirationDate(formatted: true)\n    revisionDate(formatted: true)\n    statusChangedDate\n    originationDate(formatted: true)\n    legacyEffectiveDate(formatted: true)\n    created\n    modified\n    headerImageUrl\n    attachments {\n      title\n      fileUrl\n      id\n    }\n    tags {\n      pk\n      name\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '397d869a445e47ae9f2b10c9994d582d';
module.exports = node;
