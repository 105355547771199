import { Flex } from "grid-styled";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import system from "system-components";

import { createErrorMessage } from "../../../design-system/message/manageMessages";

import PrintModal from "./PrintModal";
import { printDocument } from "./PrintMutation";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { PermissionDenied403Error } from "pstat-anywhere/errors";
import { environment } from "pstat-anywhere/relay";
import {
  POLICY_ACTIONS,
  triggerPolicyEvent
} from "pstat-anywhere/utils/googleTagManager";
import { FocusLink } from "pstat-design-system/Link";
import { Text } from "pstat-design-system/text";



const Item = system({
  is: Flex,
  height: "100%",
  alignItems: "center"
});

export class Print extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      showWatermark: "yes",
      showHistory: "no",
      loading: false,
      error: null
    };

    this.newTab = null;
    this.print = this.print.bind(this);
    this.updateShowWatermark = this.updateShowWatermark.bind(this);
    this.updateShowHistory = this.updateShowHistory.bind(this);
    this.mutationComplete = this.mutationComplete.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }

  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.error && this.state.error?.statusCode === 403) {
      throw new PermissionDenied403Error(null, {
        tenantsToRedirect: this.state.error.tenantsToRedirect,
        documentPk: this.props.document.pk
      });
    }
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  isSiteAdmin() {
    const viewer = this.props.viewer || {};
    const userAuth = viewer.userAuth || {};
    const isSiteAdmin = userAuth.isSiteAdmin || undefined;
    return isSiteAdmin;
  }

  shouldShowModal() {
    return this.includeWatermarkOption() || this.includeHistoryOption();
  }

  handlePrint() {
    if (this.shouldShowModal()) {
      this.toggleModal();
    } else {
      this.print();
    }
  }

  updateShowWatermark(value) {
    this.setState({
      showWatermark: value
    });
  }

  updateShowHistory(value) {
    this.setState({
      showHistory: value
    });
  }

  print() {
    this.setState({
      loading: true
    });
    this.newTab = window.open(`/policy/${this.props.document.pk}/print`);
    printDocument(
      environment,
      this.props.document,
      // we want to default to having a watermark
      this.state.showWatermark === "yes",
      this.state.showHistory === "yes",
      this.props.applicabilityExpanded,
      this.props.isDraft,
      this.mutationComplete,
      this.props.displayChangesDiff,
      this.props.includePastApprovals,
      this.props.beforeActionPk,
      this.props.afterActionPk
    );
  }

  mutationComplete(response) {
    const { t } = this.props;
    this.setState({
      loading: false
    });
    const result = response.printDocument;
    if (result.ok) {
      if (!this.props.isPreview) {
        const document = this.props.document;
        triggerPolicyEvent(
          POLICY_ACTIONS.PRINT,
          document.pk,
          document.name,
          document.category.pk,
          document.category.name
        );
      }
      const { url } = result;
      this.newTab.location.href = url;
    } else if (result.error.statusCode === 403) {
      this.setState({
        error: {
          statusCode: 403,
          tenantsToRedirect: result?.tenantsToRedirect
        }
      });
    } else {
      createErrorMessage(t("documentControl.print.errorMessage"));
    }

    if (this.shouldShowModal()) {
      this.closeModal();
    }
  }

  includeWatermarkOption() {
    return this.isSiteAdmin();
  }

  includeHistoryOption() {
    return this.props.document.actions !== null;
  }

  render() {
    const { t } = this.props;
    const includeWatermarkOption = this.includeWatermarkOption();
    const includeHistoryOption = this.includeHistoryOption();
    return (
      <Item>
        <FocusLink onActivation={this.handlePrint} id="print-modal-link">
          <StyledFontAwesomeIcon icon={["fal", "print"]} size="lg" />
          <Text color="secondary.0">{t("documentControl.print.header")}</Text>
        </FocusLink>
        {(includeWatermarkOption || includeHistoryOption) && (
          <PrintModal
            isOpen={this.state.isModalOpen}
            isLoading={this.state.loading}
            onClose={this.closeModal}
            print={this.print}
            includeWatermarkOption={includeWatermarkOption}
            includeHistoryOption={includeHistoryOption}
            showWatermark={this.state.showWatermark}
            updateShowWatermark={this.updateShowWatermark}
            showHistory={this.state.showHistory}
            updateShowHistory={this.updateShowHistory}
            displayChangesDiff={this.props.displayChangesDiff}
          />
        )}
      </Item>
    );
  }
}

export default withTranslation()(Print);
