/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TokenAccess_tokens$ref: FragmentReference;
export type TokenAccess_tokens = $ReadOnlyArray<{|
  +tokenId: ?any,
  +description: string,
  +dateLastAccessed: ?string,
  +url: ?string,
  +$refType: TokenAccess_tokens$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "TokenAccess_tokens",
  "type": "DocumentAccessTokenType",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tokenId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dateLastAccessed",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = 'bc69c516f6752481b95dcc697fca5dd6';
module.exports = node;
