/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type editor_editorProperties$ref: FragmentReference;
export type editor_editorProperties = {|
  +rtlSupport: ?boolean,
  +newCkeditor: ?boolean,
  +ckeditor5: ?boolean,
  +ckeditor5AiEnabled: ?boolean,
  +ckeditor5LicenseKey: ?string,
  +$refType: editor_editorProperties$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "editor_editorProperties",
  "type": "DocumentEditType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rtlSupport",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "newCkeditor",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5AiEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ckeditor5LicenseKey",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '4ff6e61d0f3699ced9f957560b5dccca';
module.exports = node;
