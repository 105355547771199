/**
 * @flow
 * @relayHash bef50629c06418ee52496aa03e2912eb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PrintDocumentInput = {
  documentPk: number,
  watermark?: ?boolean,
  showChanges?: ?boolean,
  showHistory?: ?boolean,
  beforeActionPk?: ?number,
  afterActionPk?: ?number,
  includePastApprovals?: ?boolean,
  approvalActionsToInclude?: ?$ReadOnlyArray<?number>,
  draft?: ?boolean,
  html?: ?boolean,
  showApplicability?: ?boolean,
  clientMutationId?: ?string,
};
export type PrintMutationVariables = {|
  input: PrintDocumentInput
|};
export type PrintMutationResponse = {|
  +printDocument: ?{|
    +ok: boolean,
    +reasons: ?any,
    +url: ?string,
    +error: ?{|
      +statusCode: ?number
    |},
    +tenantsToRedirect: ?$ReadOnlyArray<?{|
      +name: string,
      +url: ?string,
    |}>,
  |}
|};
export type PrintMutation = {|
  variables: PrintMutationVariables,
  response: PrintMutationResponse,
|};
*/


/*
mutation PrintMutation(
  $input: PrintDocumentInput!
) {
  printDocument(input: $input) {
    ok
    reasons
    url
    error {
      statusCode
    }
    tenantsToRedirect {
      name
      url
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PrintDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "PrintDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reasons",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "PrintMutation",
  "id": "f735ccac9857809e0190b1de2b3495da",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PrintMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "printDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "PrintDocumentPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantsToRedirect",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantType",
            "plural": true,
            "selections": [
              v6,
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PrintMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "printDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "PrintDocumentPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantsToRedirect",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantType",
            "plural": true,
            "selections": [
              v6,
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation PrintMutation(\n  $input: PrintDocumentInput!\n) {\n  printDocument(input: $input) {\n    ok\n    reasons\n    url\n    error {\n      statusCode\n    }\n    tenantsToRedirect {\n      name\n      url\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '37d9fe4307cb36afb2ca067ca27a2bf1';
module.exports = node;
