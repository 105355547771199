import { useTranslation } from "react-i18next";

import ViewPolicy from "./ViewPolicy";

import {
  POLICY_ACTIONS,
  TriggerEventOnMount,
  triggerPolicyEvent
} from "pstat-anywhere/utils/googleTagManager";
import LoadingModal from "pstat-design-system/modal/LoadingModal";


const ViewPolicyPage = props => {
  const { t } = useTranslation();
  if (props && props.documentView) {
    return (
      <TriggerEventOnMount
        triggerEvent={() => {
          const document = props.documentView.document;
          if (document) {
            triggerPolicyEvent(
              POLICY_ACTIONS.VIEW,
              document.pk,
              document.name,
              document.category.pk,
              document.category.name
            );
          }
        }}
      >
        <ViewPolicy {...props} />
      </TriggerEventOnMount>
    );
  } else {
    return <LoadingModal isOpen={true} text={t("loading")} />;
  }
};

export default ViewPolicyPage;
