import { useDrop } from "react-dnd";
// @flow
import { themeGet } from "styled-system";
import system from "system-components";

import { DraggableItemTypes } from "./";

import { Text } from "pstat-design-system/text";

export const VersionPickerItemContainer = system({
  py: 4,
  pl: 4,
  borderBottom: 1,
  borderColor: "nav.80",
  width: "100%",
  position: "relative",
  display: "flex",
  flexWrap: "wrap"
}).extend`
  justify-content: ${props => props.justifyContent};
  background-color: ${props => themeGet("colors." + props.bg)}
`;

export const DroppableVersionItemSpan = system({
  is: Text,
  width: "100%",
  display: "flex",
  fontSize: 1,
  fontWeight: "medium"
});

type DroppableVersionItemType = {
  onDrop: Function
};

export default function DroppableVersionItem({
  onDrop,
  ...props
}: DroppableVersionItemType) {
  const [, drop] = useDrop({
    accept: DraggableItemTypes.ARROW,
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  });
  return <VersionPickerItemContainer {...props} innerRef={drop} />;
}
