import system from "system-components";

const CommentDisplayBox = system({
  bg: "nav.95",
  px: 4,
  py: 3,
  mt: 1,
  width: "100%",
  borderLeft: "solid 4px",
  borderColor: "nav.55",
  position: "relative"
});

export default CommentDisplayBox;
