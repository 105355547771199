/**
 * @flow
 * @relayHash f3f39d8b4b225af7b5cc10cdd6cc60e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type acceptImportMutationVariables = {|
  importPk: number,
  comment: string,
|};
export type acceptImportMutationResponse = {|
  +acceptImportDocument: ?{|
    +ok: ?boolean,
    +error: ?{|
      +statusCode: ?number
    |},
    +redirectPath: ?string,
  |}
|};
export type acceptImportMutation = {|
  variables: acceptImportMutationVariables,
  response: acceptImportMutationResponse,
|};
*/


/*
mutation acceptImportMutation(
  $importPk: Int!
  $comment: String!
) {
  acceptImportDocument(importPk: $importPk, comment: $comment) {
    ok
    error {
      statusCode
    }
    redirectPath
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "importPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "acceptImportDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "importPk",
        "variableName": "importPk",
        "type": "Int!"
      }
    ],
    "concreteType": "AcceptImportMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectPath",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "acceptImportMutation",
  "id": "f717152c5c5d041503938ed9ed5f6bd9",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "acceptImportMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "acceptImportMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation acceptImportMutation(\n  $importPk: Int!\n  $comment: String!\n) {\n  acceptImportDocument(importPk: $importPk, comment: $comment) {\n    ok\n    error {\n      statusCode\n    }\n    redirectPath\n  }\n}\n";
(node/*: any*/).hash = 'ad60bc2e5da71e60abf7f65508b6218e';
module.exports = node;
