import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation AcceptSystemDocumentTemplateMutation(
    $input: AcceptSystemDocumentTemplateInput!
  ) {
    acceptSystemDocumentTemplate(input: $input) {
      ok
      redirectPath
      error
    }
  }
`;

function acceptSystemDocumentTemplate(environment, documentPk, callback) {
  const variables = {
    input: {
      document: documentPk
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    }
  });
}

export default acceptSystemDocumentTemplate;
