import { useState } from "react";
import { useTranslation } from "react-i18next";

import SearchField from "pstat-design-system/inputs/SearchInput";

const LippincottSearchBar = ({ onSearch, value }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState(value);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      onSearch(searchQuery);
    }
  };
  return (
    <SearchField
      onChange={ev => setSearchQuery(ev.target.value)}
      onSearch={() => onSearch(searchQuery)}
      placeholder={t("documentControl.view.lippincottSearch.placeholder")}
      value={searchQuery}
      onKeyDown={handleKeyDown}
      onClear={() => setSearchQuery("")}
    />
  );
};

export default LippincottSearchBar;
