import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInfoCircle as faInfoCircleDuotone,
  faQuestionCircle as faQuestionCircleDuotone
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faCaretLeft,
  faCaretRight,
  faCheckCircle,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faFolderOpen,
  faInfoCircle,
  faLayerGroup,
  faMinusSquare,
  faPaperclip,
  faPencil,
  faPlusSquare,
  faPrint,
  faQuestionCircle,
  faShareAlt,
  faShareSquare,
  faSmile,
  faTimes as faTimesLight,
  faTrashAlt as faTrashAltLight
} from "@fortawesome/pro-light-svg-icons";
import {
  faBan,
  faBars,
  faCheck,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faExclamationCircle,
  faExternalLink,
  faFileArchive,
  faFileCsv,
  faHistory,
  faHome,
  faInfo,
  faLink,
  faListOl,
  faMapMarker,
  faMinus,
  faMinusSquare as faMinusSquareRegular,
  faPlus,
  faPlusSquare as faPlusSquareRegular,
  faQuestionCircle as faQuestionCircleRegular,
  faSearch,
  faSortDown,
  faSortUp,
  faTimes
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCaretDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faCircle,
  faClock,
  faEdit,
  faExclamationTriangle as faExclamationTriangleSolid,
  faEye,
  faInfoCircle as faInfoCircleSolid,
  faListAlt,
  faLock,
  faRss,
  faTimesCircle,
  faTrashAlt,
  faUserShield
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { color, space } from "styled-system";

// Initialize fontawesome icons
library.add(
  faBan,
  faBars,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronLeftSolid,
  faChevronRight,
  faChevronRightSolid,
  faChevronUp,
  faCircle,
  faClock,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExclamationTriangleSolid,
  faExternalLink,
  faEye,
  faFileArchive,
  faFileCsv,
  faFolderOpen,
  faHistory,
  faHome,
  faInfo,
  faInfoCircle,
  faInfoCircleDuotone,
  faInfoCircleSolid,
  faLayerGroup,
  faLink,
  faListAlt,
  faListOl,
  faLock,
  faMapMarker,
  faMinus,
  faMinusSquare,
  faMinusSquareRegular,
  faPaperclip,
  faPencil,
  faPlus,
  faPlusSquare,
  faPlusSquareRegular,
  faPrint,
  faQuestionCircle,
  faQuestionCircleDuotone,
  faQuestionCircleRegular,
  faRss,
  faSearch,
  faShareAlt,
  faShareSquare,
  faSmile,
  faSortDown,
  faSortUp,
  faTimes,
  faTimesCircle,
  faTimesLight,
  faTrashAlt,
  faTrashAltLight,
  faUserShield
);

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon).attrs(props => ({
  focusable: "false",
  style: props.style
}))`
  ${space};
  ${color};
`;

export const StyledSVGIcon = styled("svg").attrs(props => ({
  focusable: "false"
}))`
  overflow: visible;
  ${space};
  ${color};
`;

StyledSVGIcon.defaultProps = {
  mr: 1,
  ml: 1
};

export const DuoToneFontAwesomeIcon = styled(StyledFontAwesomeIcon)`
  --fa-primary-color: ${props => props.primaryColor};
  --fa-secondary-color: ${props => props.secondaryColor};
  --fa-secondary-opacity: 1;
`;

StyledFontAwesomeIcon.defaultProps = {
  mr: 1,
  ml: 1
};

export const IconPositionerUpperRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  ${space};
`;

export const LocalizedApplicabilityIcon = ({ documentId }) => (
  <StyledSVGIcon
    width="22px"
    height="18px"
    role="img"
    focusable="false"
    viewBox="0 0 22 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-tip
    data-for={`system-bloc-tooltip-${documentId}`}
    data-delay-show="500"
    data-testid="localized-bloc-icon"
  >
    <g
      id="EPS-specific"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="bu"
        transform="translate(1.000000, 1.000000)"
        fill="#225698"
        fill-rule="nonzero"
        stroke="#225698"
        stroke-width="0.1"
      >
        <path
          d="M20.05,-0.05 L20.05,16.05 L12.95,16.05 L12.95,14.45 L18.45,14.45 L18.45,1.55 L10.55,1.55 L10.55,5.5536267 L8.95,4.22029337 L8.95,-0.05 L20.05,-0.05 Z M6,2.93491459 L12.05,7.97658125 L12.05,16.05 L-0.05,16.05 L-0.05,7.97658125 L6,2.93491459 Z M6,5.01821041 L1.55,8.72654375 L1.55,14.45 L10.45,14.45 L10.45,8.72654375 L6,5.01821041 Z M7.55,8.45 L7.55,11.55 L4.45,11.55 L4.45,8.45 L7.55,8.45 Z M14.05,8.95 L14.05,11.05 L12.95,11.05 L12.95,8.95 L14.05,8.95 Z M17.05,8.95 L17.05,11.05 L14.95,11.05 L14.95,8.95 L17.05,8.95 Z M14.05,5.95 L14.05,8.05 L12.9753439,8.05 L11.95,6.71384885 L11.95,5.95 L14.05,5.95 Z M17.05,5.95 L17.05,8.05 L14.95,8.05 L14.95,5.95 L17.05,5.95 Z M14.05,2.95 L14.05,5.05 L11.95,5.05 L11.95,2.95 L14.05,2.95 Z M17.05,2.95 L17.05,5.05 L14.95,5.05 L14.95,2.95 L17.05,2.95 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </StyledSVGIcon>
);

export const SystemApplicabilityIcon = ({ documentId }) => (
  <StyledSVGIcon
    width="22px"
    height="18px"
    role="img"
    focusable="false"
    viewBox="0 0 22 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-tip
    data-for={`system-bloc-tooltip-${documentId}`}
    data-testid="system-bloc-icon"
    data-delay-show="500"
  >
    <g
      id="EPS-specific"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="All-site-search---start"
        transform="translate(-913.000000, -973.000000)"
        fill="#225698"
        fill-rule="nonzero"
        stroke="#FFFFFF"
      >
        <g transform="translate(914.000000, 974.000000)">
          <path
            d="M9.5,1.5 L9.5,14.5 L12.5,14.5 L12.5,11 L15.5,11 L15.5,14.5 L18.5,14.5 L18.5,1.5 L9.5,1.5 Z M8,0 L9.5,0 L18.5,0 L20,0 L20,1.5 L20,14.5 L20,16 L18.5,16 L9.5,16 L8,16 L8,14.5 L8,1.5 L8,0 Z M10.75,2.75 L13.25,2.75 L13.25,5.25 L10.75,5.25 L10.75,2.75 Z M17.25,2.75 L17.25,5.25 L14.75,5.25 L14.75,2.75 L17.25,2.75 Z M10.75,6.75 L13.25,6.75 L13.25,9.25 L10.75,9.25 L10.75,6.75 Z M17.25,6.75 L17.25,9.25 L14.75,9.25 L14.75,6.75 L17.25,6.75 Z"
            id="Shape"
            stroke-width="0.3"
          ></path>
          <path
            d="M10.5,2.5 L10.5,16.5 L-0.5,16.5 L-0.5,2.5 L10.5,2.5 Z M8.25,4.71875 L1.75,4.71875 L1.75,14.28125 L3.25,14.28125 L3.25,11.4375 L6.75,11.4375 L6.75,14.28125 L8.25,14.28125 L8.25,4.71875 Z M4.875,7.984375 L4.875,11.015625 L1.79166667,11.015625 L1.79166667,7.984375 L4.875,7.984375 Z M8.20833333,7.984375 L8.20833333,11.015625 L5.125,11.015625 L5.125,7.984375 L8.20833333,7.984375 Z M4.875,4.734375 L4.875,7.765625 L1.79166667,7.765625 L1.79166667,4.734375 L4.875,4.734375 Z M8.20833333,4.734375 L8.20833333,7.765625 L5.125,7.765625 L5.125,4.734375 L8.20833333,4.734375 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </StyledSVGIcon>
);

export const LocalApplicabilityIcon = ({ documentId }) => (
  <StyledFontAwesomeIcon
    icon={["far", "home"]}
    size="lg"
    data-tip
    data-for={`system-bloc-tooltip-${documentId}`}
    data-testid="home-bloc-icon"
    data-delay-show="500"
    color="secondary.0"
  />
);
