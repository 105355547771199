/**
 * @flow
 * @relayHash 24a4c52b7bbaaffaebb4cb996694618b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProxyLoginReverseMutationVariables = {||};
export type ProxyLoginReverseMutationResponse = {|
  +reverseProxyLogin: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
  |}
|};
export type ProxyLoginReverseMutation = {|
  variables: ProxyLoginReverseMutationVariables,
  response: ProxyLoginReverseMutationResponse,
|};
*/


/*
mutation ProxyLoginReverseMutation {
  reverseProxyLogin {
    ok
    reasons
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "reverseProxyLogin",
    "storageKey": null,
    "args": null,
    "concreteType": "ReverseProxyLogin",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ProxyLoginReverseMutation",
  "id": "9e310c68921a00cc0d96a93232f79e59",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProxyLoginReverseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "ProxyLoginReverseMutation",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "mutation ProxyLoginReverseMutation {\n  reverseProxyLogin {\n    ok\n    reasons\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = 'c372d889376a662f5bfeda44464995ac';
module.exports = node;
