import { Box, Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { environment } from "pstat-anywhere/relay";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Modal from "pstat-design-system/modal";
import { DownloadButton } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";

const ExportModalText = styled(Text)`
  margin-bottom: ${themeGet("space.5")}px;
`;

const ErrorMessage = ({ message }) => (
  <ExportModalText>{message}</ExportModalText>
);

const LoadingMessage = ({ message }) => (
  <Fragment>
    <ExportModalText>{message}</ExportModalText>
    <Box alignSelf="center">
      <LoadingSpinner size={75} />
    </Box>
  </Fragment>
);

const DownloadMessage = ({ handleDownloadCSV, handleKeyDown, t }) => (
  <Fragment>
    <ExportModalText>
      {t("documentReports.export.exportModal.successMessage")}
    </ExportModalText>
    <Box alignSelf={["auto", "auto", "center"]}>
      <DownloadButton onClick={handleDownloadCSV} onKeyDown={handleKeyDown}>
        <Text color="secondary.0">
          <StyledFontAwesomeIcon icon={["fal", "download"]} />
          {t("buttons.downloadCSV")}
        </Text>
      </DownloadButton>
    </Box>
  </Fragment>
);

const CSVTaskLoader = ({ handleDownloadCSV, t, taskId }) => {
  const { error, loading, result } = useTaskResult(taskId);
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleDownloadCSV(result);
    }
  };

  if (error || !taskId) {
    return (
      <ErrorMessage
        message={t("documentReports.export.exportModal.errorMessage")}
      />
    );
  }
  if (loading) {
    return (
      <LoadingMessage
        message={t("documentReports.export.exportModal.loadingMessage")}
      />
    );
  }
  return (
    <DownloadMessage
      handleDownloadCSV={() => handleDownloadCSV(result)}
      handleKeyDown={handleKeyDown}
      t={t}
    />
  );
};

const ExportModal = ({
  csvQuery,
  csvQueryArgs,
  isOpen,
  onAfterOpen,
  onClose,
  queryName
}) => {
  const handleDownloadCSV = csvUrl => {
    window.location.assign(csvUrl);
    onClose();
  };

  const { t } = useTranslation();

  return (
    <Modal
      contentLabel={t("documentReports.export.exportModal.contentLabel")}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
    >
      <Flex flexDirection="column" px={5} py={4}>
        <QueryRenderer
          environment={environment}
          query={csvQuery}
          variables={csvQueryArgs || {}}
          render={({ props, error }) => {
            if (error) {
              return <ErrorMessage />;
            }
            if (props && props[queryName]) {
              return (
                <CSVTaskLoader
                  taskId={props[queryName].taskId}
                  handleDownloadCSV={handleDownloadCSV}
                  t={t}
                />
              );
            } else {
              return (
                <LoadingMessage
                  message={t(
                    "documentReports.export.exportModal.loadingMessage"
                  )}
                />
              );
            }
          }}
        />
      </Flex>
    </Modal>
  );
};

export default ExportModal;
