// @flow
import { withRouter } from "found";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { getRootUrl } from "../../utils/urls";
import { ContentContainerFlex } from "../pages";

import { LoginWrapper } from "./Common";
import { PasswordResetConfirmForm } from "./ForgotPasswordResetForm";
import useCheckPasswordResetToken from "./useCheckPasswordResetToken";

import { Text } from "pstat-design-system/text/paragraph";
import { H1 } from "pstat-design-system/text/headings";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import theme from "pstat-anywhere/themes/policystat/theme";

const INTERNAL_RESET_URL_TOKEN = "set-password";
const INTERNAL_RESET_SESSION_TOKEN = "_password_reset_token";

export const ForgotPasswordConfirmPage = props => {
  return (
    <ContentContainerFlex wrap px={[3, 3, 0]}>
      <LoginWrapper>
        <PasswordResetConfirm {...props} />
      </LoginWrapper>
    </ContentContainerFlex>
  );
};

export const PasswordResetTokenInvalid = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <H1>{t("authentication.forgotPassword.tokenInvalid.title")}</H1>
      <p>
        <Text>
          {t("authentication.forgotPassword.tokenInvalid.paragraph1")}
        </Text>
      </p>
      <p>
        <Text>
          {t("authentication.forgotPassword.tokenInvalid.paragraph2")}
        </Text>
      </p>
      <p>
        <Text>
          {t("authentication.forgotPassword.tokenInvalid.paragraph3")}
        </Text>
      </p>
      <p>
        <RouterFocusLink to={`${getRootUrl()}/authorization/login_help/`}>
          {t("authentication.forgotPassword.tokenInvalid.toLoginHelpLink")}
        </RouterFocusLink>
      </p>
    </Fragment>
  );
};

const _PasswordResetConfirm = props => {
  const { router } = props;
  const { uidb64 } = props.params;
  let { token } = props.params;
  let isTokenFromSession = false;
  if (token === INTERNAL_RESET_URL_TOKEN) {
    isTokenFromSession = true;
    token = window.sessionStorage.getItem(INTERNAL_RESET_SESSION_TOKEN);
  } else {
    window.sessionStorage.setItem(INTERNAL_RESET_SESSION_TOKEN, token);
  }
  const { loading, result } = useCheckPasswordResetToken(uidb64, token);
  if (loading) {
    return <LoadingSpinner size={theme.space[5]} />;
  }
  if (!result) {
    return <PasswordResetTokenInvalid />;
  }
  if (isTokenFromSession) {
    const params = { ...props.params };
    params.token = token;
    return <PasswordResetConfirmForm {...props} params={params} />;
  }
  router.push(
    window.location.pathname.replace(token, INTERNAL_RESET_URL_TOKEN)
  );
  return <Fragment>...</Fragment>;
};
const PasswordResetConfirm = withRouter(_PasswordResetConfirm);
