import graphql from "babel-plugin-relay/macro";

const PendingDocumentsCSVQuery = graphql`
  query PendingDocumentsCSVQuery(
    $showAll: Boolean
    $search_query: String
    $pending_approver: Int
    $bloc: Int
  ) {
    pendingDocumentsCsv(
      showAll: $showAll
      q: $search_query
      pendingApproverId: $pending_approver
      blocId: $bloc
    ) {
      taskId
    }
  }
`;

export default PendingDocumentsCSVQuery;
