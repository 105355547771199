/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Properties_tags$ref: FragmentReference;
export type Properties_tags = $ReadOnlyArray<{|
  +pk: ?number,
  +name: string,
  +$refType: Properties_tags$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Properties_tags",
  "type": "TagType",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pk",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '4c47accc3347ca1d2f9071b5b5dfd77c';
module.exports = node;
