import { Base64 } from "js-base64";

const CURSOR_PREFIX = "arrayconnection";

export const calculatePageCount = (numberOfResults, hitsPerPage) => {
  return Math.ceil(numberOfResults / hitsPerPage);
};

export const calculateOffset = (pageNumber, hitsPerPage) => {
  // need the offset of the record BEFORE the first record on the page
  return pageNumber * hitsPerPage - 1;
};

export const calculatePage = (offset, hitsPerPage) => {
  return Math.floor((offset + 1) / hitsPerPage);
};

export const cursorToOffset = cursor => {
  let decodedCursor;
  try {
    decodedCursor = Base64.decode(cursor);
  } catch (error) {
    return 0;
  }
  if (decodedCursor.startsWith(`${CURSOR_PREFIX}:`)) {
    // arrayconnection:<offset>
    const offsetString = decodedCursor.split(":")[1];
    const offset = parseInt(offsetString, 10);
    if (isNaN(offset)) {
      return 0;
    }
    return offset;
  } else {
    return 0;
  }
};

export const offsetToCursor = offset => {
  const cursor = `${CURSOR_PREFIX}:${offset}`;
  return Base64.encode(cursor);
};

export const getInitialPage = (cursor, hitsPerPage) => {
  if (!cursor) {
    return 0;
  }
  const offset = cursorToOffset(cursor);
  return calculatePage(offset, hitsPerPage);
};
