import { Box, Flex } from "grid-styled";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";
import system from "system-components";

import EpStandardContentQuery from "pstat-anywhere/components/integrations/verge/EpStandardContentQuery";
import { useEnvironment } from "pstat-anywhere/context_providers/EnvironmentContext";
import { ErrorBanner } from "pstat-design-system/banner";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { ExpandablePanel } from "pstat-design-system/Panel";
import MenuButton from "pstat-design-system/shared/MenuButton";
import { Text } from "pstat-design-system/text";

const StandardCard = system({
  is: Flex,
  justify: "space-between",
  height: "65px",
  borderRadius: 1,
  alignItems: "center",
  mb: 2,
  border: 1,
  borderColor: "nav.80"
});

const StandardContent = system({
  is: Flex,
  flexDirection: "column",
  width: "100%",
  m: 1
});

const TitleLine = system({
  is: Box,
  m: 1
});

const TitleStandard = system({
  is: Text,
  color: "nav.25"
});

const ValueStandard = system({
  is: Text,
  color: "nav.0"
});

const StandardBody = ({
  isLoading,
  associationPk,
  name,
  chapter,
  handlerOpenEpContentPanel
}) => {
  const { t } = useTranslation();
  return (
    <StandardCard key={associationPk}>
      {isLoading ? (
        <Box>
          <LoadingSpinner size={25} />
          <Text color="nav.25">{t("inputs.loading")}</Text>
        </Box>
      ) : (
        <Fragment>
          <StandardContent>
            <TitleLine>
              <TitleStandard>
                {t("documentControl.view.vergeStandards.content.standardTitle")}
              </TitleStandard>
              <ValueStandard>{name}</ValueStandard>
            </TitleLine>
            <TitleLine>
              <TitleStandard>
                {t("documentControl.view.vergeStandards.content.chapterTitle")}
              </TitleStandard>
              <ValueStandard>{chapter}</ValueStandard>
            </TitleLine>
          </StandardContent>
          <MenuButton onClick={handlerOpenEpContentPanel}>
            {t("documentControl.view.vergeStandards.content.openLink")}
          </MenuButton>
        </Fragment>
      )}
    </StandardCard>
  );
};

export const StandardBlock = ({
  associationPk,
  environment,
  openEpContentPanel
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!content) {
      fetchQuery(environment, EpStandardContentQuery, {
        associationPk: associationPk
      })
        .then(response => {
          const { epContent } = response;
          if (!epContent) {
            return;
          }
          setIsLoading(false);
          const { data, error } = epContent;
          if (error) {
            setError(error);
            return;
          }
          setContent(data);
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  }, [content, associationPk, environment]);

  const handlerOpenEpContentPanel = () => {
    openEpContentPanel({ associationPk, ...content });
  };

  const { chapter, name } = content || {};
  return (
    <Fragment>
      {error ? (
        <ErrorBanner
          width={1}
          message={t(
            "documentControl.view.vergeStandards.content.errorMessage"
          )}
        />
      ) : (
        <StandardBody
          isLoading={isLoading}
          associationPk={associationPk}
          name={name}
          chapter={chapter}
          handlerOpenEpContentPanel={handlerOpenEpContentPanel}
        />
      )}
    </Fragment>
  );
};

export const VergeStandardsPanel = ({ associations, openEpContentPanel }) => {
  const { t } = useTranslation();
  const environment = useEnvironment();
  return (
    <ExpandablePanel
      headerText={t("documentControl.view.vergeStandards.panel.title")}
      id="standards"
    >
      {associations && associations.length > 0 ? (
        associations.map(({ pk }) => (
          <StandardBlock
            key={pk}
            associationPk={pk}
            environment={environment}
            openEpContentPanel={openEpContentPanel}
          />
        ))
      ) : (
        <Box mb={2}>
          <Text>{t("documentControl.view.vergeStandards.content.empty")}</Text>
        </Box>
      )}
    </ExpandablePanel>
  );
};
