import graphql from "babel-plugin-relay/macro";

const DocumentAcceptImportPageQuery = graphql`
  query DocumentAcceptImportPageQuery($documentImportPk: Int!) {
    documentToAcceptView(documentImportPk: $documentImportPk) {
      documentToAccept {
        document {
          pk
          name
          bloc {
            name
          }
          category {
            name
          }
          author {
            firstName
            lastName
            profile {
              title
            }
          }
          expirationDate
          revisionDates(forceShowAllRevisions: true)
        }
        documentImport {
          pk
          importFile {
            name
            url
            zipName
            zipUrl
          }
        }
        policyActions
      }
      error {
        statusCode
        message
      }
    }
  }
`;
export default DocumentAcceptImportPageQuery;
