// @flow

import { useTranslation } from "react-i18next";
import system from "system-components";

import { TERTIARY_NAVBAR_HEIGHT } from "pstat-anywhere/components/partials/tertiary_nav/TertiaryNav";
import theme from "pstat-anywhere/themes/policystat/theme";
import { FloatingButton } from "pstat-design-system/shared/Button";

const ButtonContainer = system({
  is: "div",
  bottom: 0,
  left: 0,
  position: "fixed",
  // move the button above the bottom nav bar on mobile screen sizes
  // theme.space[2] === 8
  mb: [
    TERTIARY_NAVBAR_HEIGHT + theme.space[2],
    TERTIARY_NAVBAR_HEIGHT + theme.space[2],
    6
  ]
});

export function TOCButton({ onClick, tocExpanded, ...props }) {
  const { t } = useTranslation();
  return (
    <ButtonContainer data-testid="toc-button-container">
      <FloatingButton
        onClick={onClick}
        aria-label={t(
          "documentControl.view.tableOfContents.tab.tabButton.ariaLabel"
        )}
        icon={tocExpanded ? ["far", "times"] : ["fas", "list-alt"]}
        {...props}
        data-testid="toc-floating-button"
      />
    </ButtonContainer>
  );
}

export default TOCButton;
