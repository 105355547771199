import { useTranslation, withTranslation } from "react-i18next";

import LippincottSearchBar from "./LippincottSearchBar";

import Panel from "pstat-design-system/Panel";

const LippincottSearchPanel = ({ onSearch }) => {
  const { t } = useTranslation();
  return (
    <Panel
      id="lippincottSearch"
      hideHeaderBorder={true}
      name={t("documentControl.view.lippincottSearch.header")}
    >
      <LippincottSearchBar onSearch={onSearch} />
    </Panel>
  );
};

export default withTranslation()(LippincottSearchPanel);
