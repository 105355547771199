import { useTranslation } from "react-i18next";

import { doProxyLoginReverse } from "./ProxyLoginReverseMutation";

import MenuButton from "pstat-anywhere/components/design-system/shared/MenuButton";

const ProxyLoginReverseMenuLink = props => {
  const { t } = useTranslation();
  return (
    <MenuButton
      color="nav.0"
      bg="nav.95"
      px={2}
      py={1}
      display="inline-flex"
      width="100%"
      onClick={() => doProxyLoginReverse()}
      onKeyDown={event => {
        if (event.key === "Enter") {
          doProxyLoginReverse();
          event.preventDefault();
        }
      }}
    >
      {t("nav.primary.myAccount.proxyLoginReverse")}
    </MenuButton>
  );
};

export default ProxyLoginReverseMenuLink;
