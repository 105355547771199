import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import { createErrorMessage } from "pstat-design-system/message/manageMessages";

const useShowDocumentChanges = (taskId, successHandler) => {
  const { t } = useTranslation();
  const showChangesTaskResult = useTaskResult(taskId);
  const { loading, error, result } = showChangesTaskResult;

  const showErrorMsg = useCallback(() => {
    createErrorMessage(
      t("documentControl.acknowledge.bar.showChanges.errorToast")
    );
  }, [t]);

  useEffect(() => {
    if (!loading) {
      if (!error && result) {
        successHandler(result.diff);
      } else if (error) {
        showErrorMsg();
      }
    }
  }, [loading, error, result, successHandler, showErrorMsg]);
  return {
    loading: loading,
    error: error,
    result
  };
};

export default useShowDocumentChanges;
