import graphql from "babel-plugin-relay/macro";
import { Box, Flex } from "grid-styled";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import ReactTooltip from "react-tooltip";
import system from "system-components";

import { createNewToken } from "./CreateTokenMutation";
import { deleteToken } from "./DeleteTokenMutation";
import { renameToken } from "./RenameTokenMutation";

import Dropdown, {
  DropdownFooterContainer
} from "pstat-anywhere/components/partials/Dropdown";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { validateAndFormatDate } from "pstat-anywhere/utils/dateHelpers";
import { TrashIcon } from "pstat-design-system/Icons";
import InlineInputWrapper from "pstat-design-system/InlineInputWrapper";
import CopyLink from "pstat-design-system/inputs/CopyLink";
import TextInput from "pstat-design-system/inputs/TextInput";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import { Button } from "pstat-design-system/shared/Button";
import { H3 } from "pstat-design-system/text/headings";
import { Text } from "pstat-design-system/text/paragraph";


const TrashButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      className="link-delete-button"
      buttonStyle="tertiary"
      onClick={onClick}
      py={0}
      px={2}
      aria-label={t(
        "documentControl.view.share.modal.readOnlyLinks.deleteButtonLabel"
      )}
    >
      <TrashIcon />
    </Button>
  );
};

const DeleteButton = ({
  showConfirmationAbove,
  tokenId,
  documentPk,
  location
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [waitingToDeleteToken, setWaitingToDeleteToken] = useState(false);
  const handleClose = () => setExpanded(false);
  const handleDeleteClicked = () => {
    setWaitingToDeleteToken(true);
    deleteToken(tokenId, documentPk, location, (response, errors) => {
      if (response.deleteDocumentAccessToken.ok) {
        createSuccessMessage(
          t(
            "documentControl.view.share.modal.readOnlyLinks.deleteSuccessMessage"
          )
        );
        setExpanded(false);
      } else {
        createErrorMessage(
          t("documentControl.view.share.modal.readOnlyLinks.deleteErrorMessage")
        );
      }
      setWaitingToDeleteToken(false);
    });
  };
  return (
    <Dropdown
      expanded={expanded}
      above={showConfirmationAbove}
      renderToggleComponent={() => (
        <TrashButton onClick={() => setExpanded(!expanded)} />
      )}
      renderFooter={() => (
        <DropdownFooterContainer>
          <Flex width={1} justifyContent="space-between">
            <Button
              className="delete-link-confirmation-button"
              buttonStyle="danger"
              onClick={handleDeleteClicked}
              processing={waitingToDeleteToken}
            >
              {t("documentControl.view.share.modal.readOnlyLinks.delete")}
            </Button>
            <Button buttonStyle="tertiary" onClick={handleClose}>
              {t("buttons.cancel")}
            </Button>
          </Flex>
        </DropdownFooterContainer>
      )}
    >
      <Text>
        {t("documentControl.view.share.modal.readOnlyLinks.deleteConfirmation")}
      </Text>
    </Dropdown>
  );
};

const Header = ({ documentLabel, onNewLinkButtonClicked }) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between" mb={5}>
      <Box pr={5}>
        <H3 color="nav.0" fontWeight="normal">
          {t("documentControl.view.share.modal.readOnlyLinks.heading")}
        </H3>
        <Text>
          {t(
            "documentControl.view.share.modal.readOnlyLinks.headingDescription",
            { documentLabel }
          )}
        </Text>
      </Box>
      <Box>
        <Button
          id="new-link-button"
          buttonStyle="secondary"
          onClick={onNewLinkButtonClicked}
        >
          {t("documentControl.view.share.modal.readOnlyLinks.create")}
        </Button>
      </Box>
    </Flex>
  );
};

const DescriptionTextInput = system({
  is: TextInput,
  width: "100%"
}).extend`
  ${props =>
    props.removeRightRadius
      ? `
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  `
      : ``}
`;

const DescriptionInput = ({
  tokenDescription,
  tokenId,
  documentPk,
  location
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState(tokenDescription);
  const [waitingToRenameToken, setWaitingToRenameToken] = useState(false);
  const descriptionChanged = tokenDescription !== description;

  const handleSaveClicked = () => {
    setWaitingToRenameToken(true);
    renameToken(
      documentPk,
      tokenId,
      description,
      location,
      (response, errors) => {
        if (response.renameDocumentAccessToken.ok) {
          createSuccessMessage(
            t(
              "documentControl.view.share.modal.readOnlyLinks.renameSuccessMessage"
            )
          );
        } else {
          createErrorMessage(
            t(
              "documentControl.view.share.modal.readOnlyLinks.renameErrorMessage"
            )
          );
        }
        setWaitingToRenameToken(false);
      }
    );
  };
  return (
    <InlineInputWrapper width="100%">
      <Flex width="100%" alignItems="center">
        <Box flex={1}>
          <DescriptionTextInput
            className="description-input"
            value={description}
            maxlength={200}
            onChange={event => setDescription(event.target.value)}
            removeRightRadius={descriptionChanged}
          />
        </Box>
        {descriptionChanged && (
          <Button
            className="save-description-button"
            buttonStyle="tertiary"
            onClick={handleSaveClicked}
            processing={waitingToRenameToken}
          >
            Save
          </Button>
        )}
      </Flex>
    </InlineInputWrapper>
  );
};

const GridCell = system({
  pr: 5,
  mb: 4
}).extend`
  -ms-grid-column: ${props => props.column};
  -ms-grid-row: ${props => props.row};
`;

const GridHeaderCell = system({
  is: GridCell,
  pb: 2,
  mb: 2,
  borderBottom: 1,
  borderColor: "nav.80"
});

const LinkRowHeaderText = system({
  is: Text,
  display: "inline",
  color: "nav.25",
  fontSize: 1,
  fontWeight: "medium"
});

const DesktopLinkRow = ({
  row,
  token,
  numberOfLinks,
  index,
  documentPk,
  location
}) => (
  <Fragment>
    <GridCell row={row} column={1}>
      <DescriptionInput
        tokenDescription={token.description}
        tokenId={token.tokenId}
        documentPk={documentPk}
        location={location}
      />
    </GridCell>
    <GridCell row={row} column={2}>
      <CopyLink fieldsetWidth="100%" url={token.url} />
    </GridCell>
    <GridCell row={row} column={3}>
      <LastReadDate date={token.dateLastAccessed} />
    </GridCell>
    <GridCell row={row} column={4} pr={0}>
      <DeleteButton
        showConfirmationAbove={numberOfLinks - index <= 3}
        tokenId={token.tokenId}
        documentPk={documentPk}
        location={location}
      />
    </GridCell>
  </Fragment>
);

const NewLinkRow = ({ isDesktop, documentPk, location, onNewLinkCreated }) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState("");
  const [waitingForNewToken, setWaitingForNewToken] = useState(false);
  const handleDescriptionChange = event => setDescription(event.target.value);
  const handleCreateNewLinkClicked = () => {
    setWaitingForNewToken(true);
    createNewToken(documentPk, description, location, (response, errors) => {
      if (response.createDocumentAccessToken.ok) {
        createSuccessMessage(
          t(
            "documentControl.view.share.modal.readOnlyLinks.createSuccessMessage"
          )
        );
        onNewLinkCreated();
      } else {
        createErrorMessage(
          t("documentControl.view.share.modal.readOnlyLinks.createErrorMessage")
        );
      }
      setWaitingForNewToken(false);
    });
  };
  return (
    <Fragment>
      {isDesktop ? (
        <DesktopNewLink
          description={description}
          onDescriptionChange={handleDescriptionChange}
          onCreateNewLinkClicked={handleCreateNewLinkClicked}
          waitingForNewToken={waitingForNewToken}
        />
      ) : (
        <MobileNewLink
          description={description}
          onDescriptionChange={handleDescriptionChange}
          onCreateNewLinkClicked={handleCreateNewLinkClicked}
          waitingForNewToken={waitingForNewToken}
        />
      )}
    </Fragment>
  );
};

const DesktopNewLink = ({
  description,
  onDescriptionChange,
  onCreateNewLinkClicked,
  waitingForNewToken
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <GridCell row={2} column={1}>
        <TextInput
          id="new-link-description-input"
          value={description}
          onChange={onDescriptionChange}
          maxlength={200}
          aria-label={t(
            "documentControl.view.share.modal.readOnlyLinks.descriptionLabel"
          )}
          width="100%"
        />
      </GridCell>
      <GridCell row={2} column={2}>
        <Button
          id="new-link-create-button"
          buttonStyle="secondary"
          onClick={onCreateNewLinkClicked}
          processing={waitingForNewToken}
          disabled={description.length === 0}
        >
          {t("documentControl.view.share.modal.readOnlyLinks.createNewLink")}
        </Button>
      </GridCell>
      <GridCell row={2} column={3}></GridCell>
      <GridCell row={2} column={4}></GridCell>
    </Fragment>
  );
};

const MobileNewLink = ({
  description,
  onDescriptionChange,
  onCreateNewLinkClicked,
  waitingForNewToken
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <LinkRowHeaderText mb={1} display="inline-block">
        {t("documentControl.view.share.modal.readOnlyLinks.descriptionHeader")}
      </LinkRowHeaderText>
      <Flex mb={4}>
        <Box flex={1}>
          <TextInput
            id="new-link-description-input"
            value={description}
            onChange={onDescriptionChange}
            maxlength={200}
            aria-label={t(
              "documentControl.view.share.modal.readOnlyLinks.descriptionLabel"
            )}
            width="100%"
          />
        </Box>
        <Button
          id="new-link-create-button"
          buttonStyle="secondary"
          onClick={onCreateNewLinkClicked}
          processing={waitingForNewToken}
          disabled={description.length === 0}
        >
          Add Share Link
        </Button>
      </Flex>
    </Fragment>
  );
};

const MobileRowSeparator = system({
  borderBottom: 1,
  borderColor: "nav.80",
  mb: 5,
  mt: 5,
  width: "100%"
});

const MobileLinkRow = ({
  token,
  numberOfLinks,
  index,
  documentPk,
  location
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <LinkRowHeaderText mb={1} display="inline-block">
        {t("documentControl.view.share.modal.readOnlyLinks.descriptionHeader")}
      </LinkRowHeaderText>
      <Flex>
        <Box flex={1}>
          <DescriptionInput
            tokenDescription={token.description}
            tokenId={token.tokenId}
            documentPk={documentPk}
            location={location}
          />
        </Box>
        <DeleteButton
          showConfirmationAbove={numberOfLinks - index <= 1}
          tokenId={token.tokenId}
          documentPk={documentPk}
          location={location}
        />
      </Flex>
      <LinkRowHeaderText mb={1} mt={4} display="inline-block">
        {t("documentControl.view.share.modal.readOnlyLinks.linkHeader")}
      </LinkRowHeaderText>
      <CopyLink fieldsetWidth="100%" url={token.url} />
    </Fragment>
  );
};

const LinkRowHeaders = props => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <GridHeaderCell row={1} column={1}>
        <LinkRowHeaderText>
          {t(
            "documentControl.view.share.modal.readOnlyLinks.descriptionHeader"
          )}
        </LinkRowHeaderText>
      </GridHeaderCell>
      <GridHeaderCell row={1} column={2}>
        <LinkRowHeaderText>
          {t("documentControl.view.share.modal.readOnlyLinks.linkHeader")}
        </LinkRowHeaderText>
      </GridHeaderCell>
      <GridHeaderCell row={1} column={3}>
        <LinkRowHeaderText>
          {t("documentControl.view.share.modal.readOnlyLinks.lastReadHeader")}
        </LinkRowHeaderText>
      </GridHeaderCell>
      <GridHeaderCell row={1} column={4} pr={0}>
        <LinkRowHeaderText>
          {t("documentControl.view.share.modal.readOnlyLinks.trashHeader")}
        </LinkRowHeaderText>
      </GridHeaderCell>
    </Fragment>
  );
};

const LastReadDate = props => (
  <Text className="link-last-read-date" color="nav.25">
    {validateAndFormatDate({
      date: props.date,
      formatString: "shortMonthName-shortDay-year"
    })}
  </Text>
);

const TokenAccessContainer = system({
  p: 5,
  pb: 0,
  bg: "nav.95"
});

const GridContainer = system({
  display: "grid"
}).extend`
  display: -ms-grid;
  grid-template-columns: 1fr 1fr auto auto;
  -ms-grid-columns: 1fr 1fr auto auto;
  align-items: center;
`;

const TokenAccessContent = ({
  tokens,
  labels,
  showNewLinkRow,
  setShowNewLinkRow,
  documentPk,
  location
}) => {
  if (!tokens) {
    return null;
  }
  const { documentLabel } = labels;
  const numberOfLinks = tokens.length;
  let rowIndexBase = 2; // first row of links is second row in grid
  if (showNewLinkRow) {
    rowIndexBase = 3;
  }
  return (
    <Fragment>
      {tokens && (
        <TokenAccessContainer>
          <Header
            documentLabel={documentLabel}
            onNewLinkButtonClicked={() => setShowNewLinkRow(true)}
          />
          <GridContainer>
            {numberOfLinks > 0 ? (
              <Fragment>
                <LinkRowHeaders />
                {showNewLinkRow && (
                  <NewLinkRow
                    isDesktop={true}
                    documentPk={documentPk}
                    location={location}
                    onNewLinkCreated={() => setShowNewLinkRow(false)}
                  />
                )}
                {tokens.map((token, index) => (
                  <DesktopLinkRow
                    key={token.tokenId}
                    row={index + rowIndexBase}
                    token={token}
                    numberOfLinks={numberOfLinks}
                    index={index}
                    documentPk={documentPk}
                    location={location}
                  />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                {showNewLinkRow && (
                  <Fragment>
                    <LinkRowHeaders />
                    <NewLinkRow
                      isDesktop={true}
                      documentPk={documentPk}
                      location={location}
                      onNewLinkCreated={() => setShowNewLinkRow(false)}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </GridContainer>
        </TokenAccessContainer>
      )}
    </Fragment>
  );
};

const MobileTokenAccessContent = ({
  tokens,
  showNewLinkRow,
  setShowNewLinkRow,
  labels,
  documentPk,
  location
}) => {
  if (!tokens) {
    return null;
  }
  const { documentLabel } = labels;
  const numberOfLinks = tokens.length;
  return (
    <Fragment>
      {tokens && (
        <TokenAccessContainer pb={5}>
          <Header
            documentLabel={documentLabel}
            onNewLinkButtonClicked={() => setShowNewLinkRow(true)}
          />
          {showNewLinkRow && (
            <NewLinkRow
              isDesktop={false}
              documentPk={documentPk}
              location={location}
              onNewLinkCreated={() => setShowNewLinkRow(false)}
            />
          )}
          {tokens.map((token, index) => (
            <Fragment key={token.tokenId}>
              <MobileLinkRow
                token={token}
                numberOfLinks={numberOfLinks}
                index={index}
                documentPk={documentPk}
                location={location}
              />
              {index !== numberOfLinks - 1 && <MobileRowSeparator />}
            </Fragment>
          ))}
        </TokenAccessContainer>
      )}
    </Fragment>
  );
};

const TokenAccess = ({ isDesktop, ...otherProps }) => {
  useEffect(() => {
    // This is needed for the copy link tooltip to show up after a new link is added
    ReactTooltip.rebuild();
  });
  const [showNewLinkRow, setShowNewLinkRow] = useState(false);
  return (
    <div id="read-only-links">
      {isDesktop ? (
        <TokenAccessContent
          showNewLinkRow={showNewLinkRow}
          setShowNewLinkRow={setShowNewLinkRow}
          {...otherProps}
        />
      ) : (
        <MobileTokenAccessContent
          showNewLinkRow={showNewLinkRow}
          setShowNewLinkRow={setShowNewLinkRow}
          {...otherProps}
        />
      )}
    </div>
  );
};

export default withLabelContext(
  createFragmentContainer(TokenAccess, {
    tokens: graphql`
      fragment TokenAccess_tokens on DocumentAccessTokenType
      @relay(plural: true) {
        tokenId
        description
        dateLastAccessed
        url
      }
    `
  })
);
