import { Fragment, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";

import {
  ActiveBox,
  FocusBlockingFlex,
  FocusBorderSpan,
  FullHeightFlex,
  InactiveBox,
  SecondaryExternalLink,
  SecondaryNavDisabledText,
  SecondaryNavLink
} from "./";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { isDraft } from "pstat-anywhere/utils/document";
import LinkButton from "pstat-design-system/shared/MenuButton";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";


const NavLink = ({ id, url, active, external, name, ...otherProps }) => {
  return (
    <Fragment>
      {external ? (
        <SecondaryExternalLink
          id={id}
          href={url}
          aria-label={otherProps["aria-label"]}
          {...otherProps}
        >
          <FocusBlockingFlex tabIndex="-1">
            <FocusBorderSpan>{name}</FocusBorderSpan>
          </FocusBlockingFlex>
        </SecondaryExternalLink>
      ) : (
        <SecondaryNavLink
          id={id}
          to={url || "#"}
          aria-label={otherProps["aria-label"]}
          {...otherProps}
        >
          <FocusBlockingFlex tabIndex="-1">
            <FocusBorderSpan>{name}</FocusBorderSpan>
          </FocusBlockingFlex>
        </SecondaryNavLink>
      )}
    </Fragment>
  );
};

const NavLinkButton = ({ id, name, onClick, ...otherProps }) => {
  return (
    <InactiveBox>
      <LinkButton id={id} onClick={onClick} px={0}>
        <FocusBlockingFlex tabIndex="-1">
          <FocusBorderSpan>{name}</FocusBorderSpan>
        </FocusBlockingFlex>
      </LinkButton>
    </InactiveBox>
  );
};

export const CloneLinkButton = ({ onClick }) => {
  return <NavLinkButton id="clone" name="Clone" onClick={onClick} />;
};

const DeleteDraftButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <NavLinkButton
      id="draftDelete"
      name={t("documentControl.view.secondaryNav.navOptions.delete.name")}
      onClick={onClick}
    />
  );
};

const OptionBox = props => {
  const { id, active, name, disabled } = props;
  const BoxComponent = active ? ActiveBox : InactiveBox;
  return (
    // this makes it similar to buttons
    <BoxComponent>
      {disabled ? (
        <SecondaryNavDisabledText
          id={id}
          data-tip
          data-for="edit-scheduled-doc-tooltip"
          data-event={HOVER_EVENTS}
          data-event-off={HOVER_EVENTS_OFF}
          data-place="bottom"
        >
          <FocusBlockingFlex tabIndex="-1">
            <FocusBorderSpan color="nav.55">{name}</FocusBorderSpan>
          </FocusBlockingFlex>
        </SecondaryNavDisabledText>
      ) : (
        <NavLink {...props} />
      )}
    </BoxComponent>
  );
};

// Using React.forwardRef for accessing innerRef in NavOptions.
// More info at https://reactjs.org/docs/forwarding-refs.html
const _NavOptions = forwardRef(
  (
    {
      document,
      labels,
      policyActions,
      route,
      showCurrentPage,
      cloneToggle,
      deleteDraftToggle,
      newSavedDraftUrl
    },
    ref
  ) => {
    const { t } = useTranslation();
    const currentRouteName = route?.data?.routeName;

    const isAcceptImportPage =
      currentRouteName === "implementation_accept_import";
    const isViewPage = currentRouteName === "document_view";
    const isEditPage = currentRouteName === "document_edit";
    const isCreatePage = currentRouteName === "document_create";
    const isOverridePage = currentRouteName === "document_override";
    const isDeletePage = currentRouteName === "document_delete";
    const isApprovePage = currentRouteName === "document_approve";
    const isRetirePage = currentRouteName === "document_retire";
    const isAllVersionsPage = currentRouteName === "document_revisions";

    let links = policyActions?.links;
    const disableEdit = policyActions?.disableEdit;

    if (!links) {
      if (!isCreatePage) {
        return null;
      }
      links = {};
    }

    return (
      <FullHeightFlex
        justifyContent="flex-start"
        alignItems="center"
        innerRef={ref}
      >
        {links.download && (
          <OptionBox
            id="download_link"
            url={links.download[0]}
            name={t(
              "documentControl.view.secondaryNav.navOptions.download.name"
            )}
            active={false}
            external={true}
          />
        )}
        {links.view && !isApprovePage && !(isViewPage && !showCurrentPage) && (
          <OptionBox
            id="view_link"
            url={
              (isEditPage || isCreatePage) &&
              (newSavedDraftUrl || links.draft_view?.length)
                ? links.draft_view?.length
                  ? links.draft_view[0]
                  : newSavedDraftUrl
                : links.view[0]
            }
            name={
              isAcceptImportPage
                ? t(
                    "documentControl.view.secondaryNav.navOptions.viewLive.name",
                    { documentLabel: labels.documentLabel }
                  )
                : t("documentControl.view.secondaryNav.navOptions.view.name")
            }
            active={isViewPage}
            aria-label={
              document &&
              t("documentControl.view.secondaryNav.navOptions.view.ariaLabel", {
                documentLabel: labels.documentLabel,
                documentName: document.name
              })
            }
          />
        )}
        {links.edit && !(isEditPage && !showCurrentPage) && (
          <Fragment>
            <OptionBox
              id="edit_link"
              url={links.edit[0]}
              name={t("documentControl.view.secondaryNav.navOptions.edit.name")}
              active={isEditPage}
              disabled={disableEdit}
            />
            <Tooltip name="edit-scheduled-doc-tooltip">
              {t("documentControl.view.secondaryNav.navOptions.edit.tooltip", {
                documentLabel: labels.documentLabel.toLowerCase(),
                effectiveDateLabel: labels.effectiveDateLabel.toLowerCase()
              })}
            </Tooltip>
          </Fragment>
        )}
        {links.review && !(isEditPage && !showCurrentPage) && (
          <OptionBox
            id="edit_link"
            url={links.review[0]}
            name={t("documentControl.view.secondaryNav.navOptions.review.name")}
            active={isEditPage}
            disabled={disableEdit}
          />
        )}
        <Fragment>
          {links.copy_as_new && !isApprovePage && cloneToggle && (
            <CloneLinkButton onClick={cloneToggle} />
          )}
        </Fragment>
        {links.retire && !(isRetirePage && !showCurrentPage) && (
          <OptionBox
            id="retire_link"
            url={links.retire[0]}
            name={t("documentControl.view.secondaryNav.navOptions.retire.name")}
            active={isRetirePage}
          />
        )}
        {links.override && !(isOverridePage && !showCurrentPage) && (
          <OptionBox
            id="override_link"
            url={links.override[0]}
            name={t(
              "documentControl.view.secondaryNav.navOptions.override.name"
            )}
            active={isOverridePage}
          />
        )}
        <Fragment>
          {isEditPage ||
          isCreatePage ||
          (document && isDraft(document.status) && isViewPage) ? (
            <DeleteDraftButton onClick={deleteDraftToggle} />
          ) : (
            links.delete &&
            !(isDeletePage && !showCurrentPage) && (
              <OptionBox
                id="delete_link"
                url={links.delete[0]}
                name={t(
                  "documentControl.view.secondaryNav.navOptions.delete.name"
                )}
                active={isDeletePage}
              />
            )
          )}
        </Fragment>
        <Fragment>
          {links.approve && !isApprovePage && (
            <OptionBox
              id="approval_page_link"
              url={links.approve[0]}
              name={t(
                "documentControl.view.secondaryNav.navOptions.approve.name"
              )}
            />
          )}
        </Fragment>
        <Media query={{ minWidth: theme.breakpoints[1] }}>
          {isDesktop =>
            !isDesktop && (
              <Fragment>
                {links.revisions &&
                  !(isAllVersionsPage && !showCurrentPage) && (
                    <OptionBox
                      id="revisions_link"
                      url={links.revisions[0]}
                      name={t(
                        "documentControl.acknowledge.desktopBar.allVersionsLink"
                      )}
                      active={isAllVersionsPage}
                    />
                  )}
              </Fragment>
            )
          }
        </Media>
        {links.accept_imports && (
          <OptionBox
            id="accept_imports"
            url={links.accept_imports[0]}
            name={"Accept Completed Imports"}
            active={false}
          />
        )}
        {links.import && (
          <OptionBox
            id="import_link"
            url={links.import[0]}
            name={t("documentControl.import.desktopBar.ImportLink")}
            external={true}
          />
        )}
      </FullHeightFlex>
    );
  }
);
export const NavOptions = withLabelContext(_NavOptions);
