import graphql from "babel-plugin-relay/macro";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { SingleSelectDropdownFormElement } from "pstat-design-system/inputs/FormElements";
import { Text } from "pstat-design-system/text/paragraph";

export const ManufacturersQuery = graphql`
  query ManufacturerInputQuery {
    manufacturersData {
      data {
        manufactureId
        name
      }
      error {
        statusCode
        message
      }
    }
  }
`;

const ManufacturerInput = ({
  onManufacturerChange,
  manufacturersData,
  error
}) => {
  const { t } = useTranslation();
  const [manufacturerId, setManufacturerId] = useState(null);

  const handleChange = event => {
    const manufactureId = event.target.value;
    setManufacturerId(manufactureId);
    if (manufactureId === null) {
      onManufacturerChange(null);
    } else {
      onManufacturerChange(event.target.valueName);
    }
  };
  const handleClear = () => {
    setManufacturerId(null);
    onManufacturerChange(null);
  };
  const formatOptions = queryOptions =>
    queryOptions.map(manufacturer => ({
      pk: manufacturer.manufactureId,
      name: manufacturer.name
    }));

  const loading = !manufacturersData?.data;
  let options = [];
  let errorResponse = null;
  if (!loading) {
    options.push({ pk: null, name: t("onesource.edit.manufacturerAll") });
    options.push(...formatOptions(manufacturersData.data));

    errorResponse = manufacturersData.error;
  }
  if (error || errorResponse) {
    return <ErrorText />;
  }
  return (
    <SingleSelectDropdownFormElement
      id="manufacturer_input"
      label={t("onesource.edit.manufacturerLabel")}
      options={options}
      onChange={handleChange}
      onClear={handleClear}
      value={manufacturerId}
      placeholder={t("onesource.edit.manufacturerPlaceholder")}
      loading={loading}
      suggestionsLimit={20}
    />
  );
};

export default ManufacturerInput;

const ErrorText = props => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Text display="block" color="nav.0" fontWeight={500} fontSize={1} mb={2}>
        {t("onesource.edit.manufacturerLabel")}
      </Text>
      <Text color="error.0">{t("onesource.edit.manufacturerError")}</Text>
    </Fragment>
  );
};
