/**
 * @flow
 * @relayHash 77d0a7d09dfdacc776bb91da5a9cabce
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteUserAcknowledgmentMutationVariables = {|
  recurringAckPk: number
|};
export type DeleteUserAcknowledgmentMutationResponse = {|
  +deleteUserCertification: ?{|
    +ok: ?boolean
  |}
|};
export type DeleteUserAcknowledgmentMutation = {|
  variables: DeleteUserAcknowledgmentMutationVariables,
  response: DeleteUserAcknowledgmentMutationResponse,
|};
*/


/*
mutation DeleteUserAcknowledgmentMutation(
  $recurringAckPk: Int!
) {
  deleteUserCertification(recurringAckPk: $recurringAckPk) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "recurringAckPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteUserCertification",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "recurringAckPk",
        "variableName": "recurringAckPk",
        "type": "Int"
      }
    ],
    "concreteType": "DeleteUserCertificationMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeleteUserAcknowledgmentMutation",
  "id": "8fef8238310ff68c5037e8f668c1ffd0",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteUserAcknowledgmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteUserAcknowledgmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation DeleteUserAcknowledgmentMutation(\n  $recurringAckPk: Int!\n) {\n  deleteUserCertification(recurringAckPk: $recurringAckPk) {\n    ok\n  }\n}\n";
(node/*: any*/).hash = '1111b20217c6d3d181bbf01dd20950f2';
module.exports = node;
