import graphql from "babel-plugin-relay/macro";

export const HomeContentQuery = graphql`
  query HomeContentQuery {
    pendingDocuments(showAll: false) {
      documents(first: 5) {
        totalCount
        edges {
          node {
            id
            pk
            name
            bloc {
              name
            }
            applicabilityType
            category {
              name
            }
            effectiveDate
            restricted
            legacyEffectiveDate
          }
        }
      }
      viewOnlyPks
    }
    documentsToReviewHome {
      documents {
        pk
        name
        bloc {
          name
        }
        applicabilityType
        category {
          name
        }
        expirationDate
        restricted
      }
      totalCount
      buckets
      counts {
        templateCount
      }
    }
    myDocumentsToAcknowledge(first: 5) {
      totalCount
      edges {
        node {
          totalAcknowledged
          totalAssigned
          line {
            pk
            document {
              pk
              name
              hasAttachments
              effectiveDate
              legacyEffectiveDate
              restricted
              category {
                name
              }
              bloc {
                name
              }
              applicabilityType
            }
          }
        }
      }
    }
    recentlyRevisedDocuments {
      documents(first: 5) {
        totalCount
        edges {
          node {
            pk
            name
            bloc {
              name
            }
            category {
              name
            }
            bloc {
              name
            }
            applicabilityType
            revisedFromParent
            effectiveDate
            legacyEffectiveDate
            restricted
            revisedFromParentStatus
          }
        }
      }
      buckets
    }
    currentTenant {
      ...HomeContent_currentTenant
    }
  }
`;
