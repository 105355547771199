import graphql from "babel-plugin-relay/macro";
import { formatInTimeZone } from "date-fns-tz";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { PrimaryNavFragmentContainer } from "../nav/PrimaryNav";
import {
  SecondaryNavFlex,
  SecondaryNavFragmentContainer
} from "../nav/SecondaryNav";
import { FlexHeader } from "../partials/HamburgerHeader";

import ErrorBoundary from "./ErrorBoundary";

import { Content, PageContainer, SkipLink } from "./index";

import ChooseSiteModal from "pstat-anywhere/components/bloc/ChooseSiteModal";
import { withSettings } from "pstat-anywhere/context_providers/SettingsContext";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import { environment } from "pstat-anywhere/relay";
import { FORMAT_STRINGS } from "pstat-anywhere/utils/dateHelpers";
import {
  isMaintenanceInProgress,
  isUpcomingMaintenance
} from "pstat-anywhere/utils/maintenance";
import { strToBoolean } from "pstat-anywhere/utils/typeTools";
import { addRedirect, getRootUrl } from "pstat-anywhere/utils/urls";
import {
  DuringMaintenanceBanner,
  PreMaintenanceBanner,
  TrainingBanner
} from "pstat-design-system/banner";
import Footer from "pstat-design-system/Footer";




// TODO add end to end tests for anonymous URLs
const anonymousUrls = [
  "home",
  "authorization_login_help",
  "authorization_password_reset_confirm"
];

const getRouteName = routes => {
  let routeName = undefined;
  if (routes === undefined || !routes.length) {
    return routeName;
  }
  for (let route of routes) {
    if (route.data && route.data.routeName) {
      routeName = route.data.routeName;
    }
  }
  return routeName;
};

const _PageWithNav = props => {
  const { t } = useTranslation();
  const {
    settings: { showChangesWarningBanner }
  } = props;
  const routeName = getRouteName(props.routes);

  if (!props.viewer && anonymousUrls.indexOf(routeName) === -1) {
    window.location.replace(
      `${getRootUrl()}/${addRedirect(
        window.location.pathname,
        window.location.search
      )}`
    );
    return <div data-testid="empty-render" />;
  } else {
    const upcomingMaintenanceWindow = isUpcomingMaintenance();
    let friendlyUpcomingMaintenance = null;
    if (upcomingMaintenanceWindow) {
      friendlyUpcomingMaintenance = formatInTimeZone(
        upcomingMaintenanceWindow,
        "America/Indiana/Indianapolis",
        FORMAT_STRINGS["monthName-day-year-time-tz"]
      );
    }

    return (
      <PageContainer>
        <header>
          <nav aria-label={t("pages.pageWithNav.skipToContent")}>
            <SkipLink>{t("pages.pageWithNav.skipToContent")}</SkipLink>
          </nav>
          {isMaintenanceInProgress() && <DuringMaintenanceBanner />}
          {upcomingMaintenanceWindow && (
            <PreMaintenanceBanner
              maintenanceWindow={friendlyUpcomingMaintenance}
            />
          )}
          {strToBoolean(showChangesWarningBanner) && <TrainingBanner />}
        </header>
        {props.viewer && (
          <Fragment>
            <nav aria-label={t("nav.navBar.ariaLabel")}>
              <PrimaryNavFragmentContainer
                tenant={props.currentTenant}
                accessibleTenants={props.accessibleTenants}
                documentTemplates={
                  props.currentTenant.documentSettings.documentTemplates
                }
                helpLinks={props.helpLinks}
                usersCanProxy={props.usersCanProxy}
                routeName={routeName}
                unseenCount={props.notifications?.unseenCount}
              />

              <SecondaryNavFragmentContainer
                tenant={props.currentTenant}
                accessibleTenants={props.accessibleTenants}
              />
            </nav>
            {props.viewer.forceChooseHomeTenant && (
              <ChooseSiteModal
                environment={environment}
                accessibleTenants={props.accessibleTenants}
              />
            )}
          </Fragment>
        )}

        <main>
          <Content id="content">
            <ErrorBoundary>{props.children}</ErrorBoundary>
          </Content>
        </main>
        <Footer />
      </PageContainer>
    );
  }
};

export const PageWithNav = withSettings(withViewer(_PageWithNav));
export const PageWithNavQuery = graphql`
  query PageWithNavQuery {
    currentTenant {
      ...PrimaryNav_tenant
      ...SecondaryNav_tenant
      documentSettings {
        documentTemplates {
          ...PrimaryNav_documentTemplates
        }
      }
    }
    accessibleTenants {
      ...PrimaryNav_accessibleTenants
      ...SecondaryNav_accessibleTenants
      pk
      name
      isAllBlocs
      url
    }

    helpLinks {
      name
      helpLink
      showPopup
    }

    usersCanProxy {
      pk
      userDisplay
    }
    notifications(first: 0) {
      unseenCount
    }
  }
`;

export default PageWithNav;

export const PageWithNavLoading = props => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <header>
        <nav aria-label={t("pages.pageWithNav.skipToContent")}>
          <SkipLink>{t("pages.pageWithNav.skipToContent")}</SkipLink>
        </nav>
      </header>
      <nav aria-label={t("nav.navBar.ariaLabel")}>
        <FlexHeader />
        <SecondaryNavFlex height={70} />
      </nav>
      <main>
        <Content id="content">
          <ErrorBoundary>{props.children}</ErrorBoundary>
        </Content>
      </main>
      <Footer />
    </PageContainer>
  );
};
