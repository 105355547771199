import { createContext, forwardRef } from "react";

export const DocumentSettingsContext = createContext({});

export const withDocumentSettingsContext = Component =>
  forwardRef((props, ref) => (
    <DocumentSettingsContext.Consumer>
      {documentSettings => (
        <Component {...props} documentSettings={documentSettings} ref={ref} />
      )}
    </DocumentSettingsContext.Consumer>
  ));
DocumentSettingsContext.displayName = "DocumentSettingsContext";
