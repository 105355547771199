/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SecondaryNav_tenant$ref: FragmentReference;
export type SecondaryNav_tenant = {|
  +id: string,
  +subdomain: ?string,
  +name: string,
  +$refType: SecondaryNav_tenant$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SecondaryNav_tenant",
  "type": "TenantType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subdomain",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '38a6b80103f45cef474c54a603bdbc75';
module.exports = node;
