/**
 * @flow
 * @relayHash d15b93f1423cf050b33128e8ad5cb4e2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PublishSystemDocumentTemplatesInput = {
  systemDocumentTemplates: $ReadOnlyArray<?number>,
  blocs: $ReadOnlyArray<?number>,
  clientMutationId?: ?string,
};
export type PublishTemplatesMutationVariables = {|
  input: PublishSystemDocumentTemplatesInput
|};
export type PublishTemplatesMutationResponse = {|
  +publishSystemDocumentTemplates: ?{|
    +ok: ?boolean,
    +result: ?any,
    +error: ?any,
  |}
|};
export type PublishTemplatesMutation = {|
  variables: PublishTemplatesMutationVariables,
  response: PublishTemplatesMutationResponse,
|};
*/


/*
mutation PublishTemplatesMutation(
  $input: PublishSystemDocumentTemplatesInput!
) {
  publishSystemDocumentTemplates(input: $input) {
    ok
    result
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PublishSystemDocumentTemplatesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "publishSystemDocumentTemplates",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "PublishSystemDocumentTemplatesInput!"
      }
    ],
    "concreteType": "PublishSystemDocumentTemplatesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "error",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "PublishTemplatesMutation",
  "id": "563fc520a83060ba85bf65c317ffd277",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PublishTemplatesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "PublishTemplatesMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation PublishTemplatesMutation(\n  $input: PublishSystemDocumentTemplatesInput!\n) {\n  publishSystemDocumentTemplates(input: $input) {\n    ok\n    result\n    error\n  }\n}\n";
(node/*: any*/).hash = 'c38d36e51ab9731677dc7655a67ec9a2';
module.exports = node;
