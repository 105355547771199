let shouldUpdateClient = false;

export const scheduleClientForUpdate = () => {
  shouldUpdateClient = true;
};

export const clientIsOutdated = latestClientVersion => {
  return latestClientVersion !== window.VERSION;
};

const checkForClientUpdate = () => {
  if (shouldUpdateClient) {
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  }
};

export default checkForClientUpdate;
