import { useState } from "react";

import { environment } from "pstat-anywhere/relay";

export default function usePolicyAction({
  documentPk,
  policyActionMutation,
  policyActionCallback,
  eventBuilder,
  isCommentsRequired
}) {
  const [comment, setComment] = useState("");
  const [actionProcessing, setActionProcessing] = useState(false);
  function handleCommentChange(event) {
    setComment(event.target.value);
  }

  function defaultBuilder(documentPk, comment) {
    return {
      actionComment: comment,
      documentPk
    };
  }

  function handleSubmit() {
    if (comment || !isCommentsRequired) {
      setActionProcessing(true);
      const builder = eventBuilder || defaultBuilder;
      const input = builder(documentPk, comment);
      policyActionMutation(environment, input, policyActionCallback);
    }
  }

  return {
    actionProcessing,
    comment,
    handleSubmit,
    handleCommentChange
  };
}
