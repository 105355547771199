const APPROVABLE_OPTIONS = {
  NO: "NO",
  REQUIRED: "REQUIRED",
  OPTIONAL: "OPTIONAL"
};

export const isRequiredApproval = document => {
  return document.approvable === APPROVABLE_OPTIONS.REQUIRED;
};

export const isOptionalApproval = document => {
  return document.approvable === APPROVABLE_OPTIONS.OPTIONAL;
};
