import { Children, cloneElement } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { Text } from "pstat-design-system/text";

export const MenuContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  width: 100%;

  @media (min-width: ${themeGet("breakpoints.1")}) {
    width: auto;
    min-height: auto;
    ${props => (props.rtl ? "margin-left: auto" : "")};
    ${props => (props.hideLarge ? "display: none" : "")};
    height: 52px;
  }
`;

const MenuItem = styled.li`
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: inline-block;
    height: 100%;
    vertical-align: top;
  }
`;

export const MenuDivider = styled.div`
  height: 0;
  overflow: hidden;
  border-top: 1px solid ${themeGet("colors.nav.55")};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

export const MenuText = styled(Text)({
  px: 10,
  pb: 4,
  color: "nav.100"
}).extend`
  display: inline-block;
  font-style: italic;
  width: "100%";
  color: ${themeGet("colors.nav.100")};
  padding-left: ${themeGet("space.10")}px;
  padding-right: ${themeGet("space.10")}px;
  padding-bottom: ${themeGet("space.4")}px;
`;

export const Menu = ({ children, closeMenu, ...props }) => {
  const handleKeyDown = event => {
    const ENTER_KEY_CODE = 13;
    if (event.keyCode === ENTER_KEY_CODE || event.which === ENTER_KEY_CODE) {
      closeMenu();
    }
  };
  return (
    <MenuContainer {...props}>
      {Children.map(
        children,
        child =>
          // child can be null if viewer is guest
          child && (
            <MenuItem>
              {cloneElement(child, {
                closeMenu,
                onKeyDown: handleKeyDown
              })}
            </MenuItem>
          )
      )}
    </MenuContainer>
  );
};

export default Menu;
