/**
 * @flow
 * @relayHash 845a5f6ce9caff79327b9855045195a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onesourceQueryVariables = {|
  manufacturer?: ?string,
  catalogNumber?: ?string,
  documentId?: ?string,
|};
export type onesourceQueryResponse = {|
  +onesourceDocumentsSearch: ?{|
    +data: ?$ReadOnlyArray<?{|
      +documentId: ?string,
      +documentName: ?string,
      +manufacturerId: ?string,
      +manufacturerName: ?string,
      +instrumentCategoryNumber: ?string,
      +revisionInformation: ?string,
      +viewUrl: ?string,
    |}>,
    +resultsLink: ?string,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +totalCount: ?number,
  |}
|};
export type onesourceQuery = {|
  variables: onesourceQueryVariables,
  response: onesourceQueryResponse,
|};
*/


/*
query onesourceQuery(
  $manufacturer: String
  $catalogNumber: String
  $documentId: String
) {
  onesourceDocumentsSearch(manufacturer: $manufacturer, catalogNumber: $catalogNumber, documentId: $documentId) {
    data {
      documentId
      documentName
      manufacturerId
      manufacturerName
      instrumentCategoryNumber
      revisionInformation
      viewUrl
      id
    }
    resultsLink
    error {
      statusCode
      message
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "manufacturer",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "catalogNumber",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "documentId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "catalogNumber",
    "variableName": "catalogNumber",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "documentId",
    "variableName": "documentId",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "manufacturer",
    "variableName": "manufacturer",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "documentId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "documentName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "manufacturerId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "manufacturerName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "instrumentCategoryNumber",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionInformation",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "viewUrl",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resultsLink",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "onesourceQuery",
  "id": "12a0cf0caace9f911252bc29e63cccab",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "onesourceQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "onesourceDocumentsSearch",
        "storageKey": null,
        "args": v1,
        "concreteType": "ListOneSourceDocumentsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "data",
            "storageKey": null,
            "args": null,
            "concreteType": "OneSourceIFUType",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8
            ]
          },
          v9,
          v10,
          v11
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "onesourceQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "onesourceDocumentsSearch",
        "storageKey": null,
        "args": v1,
        "concreteType": "ListOneSourceDocumentsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "data",
            "storageKey": null,
            "args": null,
            "concreteType": "OneSourceIFUType",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v9,
          v10,
          v11
        ]
      }
    ]
  }
};
})();
node.text = "query onesourceQuery(\n  $manufacturer: String\n  $catalogNumber: String\n  $documentId: String\n) {\n  onesourceDocumentsSearch(manufacturer: $manufacturer, catalogNumber: $catalogNumber, documentId: $documentId) {\n    data {\n      documentId\n      documentName\n      manufacturerId\n      manufacturerName\n      instrumentCategoryNumber\n      revisionInformation\n      viewUrl\n      id\n    }\n    resultsLink\n    error {\n      statusCode\n      message\n    }\n    totalCount\n  }\n}\n";
(node/*: any*/).hash = 'e88d596206a38e3f16d03e9d86d4766e';
module.exports = node;
