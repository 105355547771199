import { createContext, forwardRef, useContext } from "react";

export const LabelContext = createContext({});

export const withLabelContext = Component =>
  forwardRef((props, ref) => (
    <LabelContext.Consumer>
      {labels => <Component {...props} labels={labels} ref={ref} />}
    </LabelContext.Consumer>
  ));

export const useLabels = () => {
  const context = useContext(LabelContext);
  if (context === undefined) {
    throw new Error("useLabels must be used within a LabelContext.Provider");
  }
  return context;
};
LabelContext.displayName = "LabelContext";
