/**
 * @flow
 * @relayHash 1c859520c60a29b362455d36f56cfe2a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PreviewQueryVariables = {|
  documentPk?: ?number,
  documentSavePk?: ?number,
  name?: ?string,
  html?: ?string,
  author?: ?number,
  category?: ?number,
  bloc?: ?number,
  flowTemplate?: ?number,
  activeLifespan?: ?number,
  expirationDate?: ?any,
  legacyEffectiveDate?: ?any,
  effectiveDate?: ?any,
  effectiveDateTimedelta?: ?number,
  effectiveDateAlgorithm?: ?string,
  approvalDate?: ?any,
  restricted?: ?boolean,
  hasParent?: ?boolean,
|};
export type PreviewQueryResponse = {|
  +documentPreview: ?{|
    +name: ?string,
    +html: ?string,
    +author: ?{|
      +pk: ?number,
      +userDisplay: ?string,
      +firstName: string,
      +lastName: string,
      +profile: ?{|
        +title: ?string
      |},
    |},
    +category: ?{|
      +pk: ?number,
      +name: string,
    |},
    +bloc: ?{|
      +id: string,
      +name: string,
      +tenants: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +name: string,
      |}>,
    |},
    +restricted: ?boolean,
    +approvalDate: ?string,
    +expirationDate: ?string,
    +effectiveDate: ?string,
    +originationDate: ?string,
    +documentEditing: ?{|
      +pk: ?number
    |},
    +attachments: ?$ReadOnlyArray<?{|
      +title: ?string,
      +fileUrl: ?string,
    |}>,
    +showChangesTaskId: ?string,
    +nameChanges: ?string,
    +headerImageUrl: ?string,
  |}
|};
export type PreviewQuery = {|
  variables: PreviewQueryVariables,
  response: PreviewQueryResponse,
|};
*/


/*
query PreviewQuery(
  $documentPk: Int
  $documentSavePk: Int
  $name: String
  $html: String
  $author: Int
  $category: Int
  $bloc: Int
  $flowTemplate: Int
  $activeLifespan: Int
  $expirationDate: PStatDate
  $legacyEffectiveDate: PStatDate
  $effectiveDate: PStatDate
  $effectiveDateTimedelta: Int
  $effectiveDateAlgorithm: String
  $approvalDate: PStatDate
  $restricted: Boolean
  $hasParent: Boolean
) {
  documentPreview(documentPk: $documentPk, documentSavePk: $documentSavePk, name: $name, html: $html, author: $author, category: $category, bloc: $bloc, flowTemplate: $flowTemplate, activeLifespan: $activeLifespan, expirationDate: $expirationDate, legacyEffectiveDate: $legacyEffectiveDate, effectiveDate: $effectiveDate, effectiveDateTimedelta: $effectiveDateTimedelta, effectiveDateAlgorithm: $effectiveDateAlgorithm, approvalDate: $approvalDate, restricted: $restricted, hasParent: $hasParent) {
    name
    html
    author {
      pk
      userDisplay
      firstName
      lastName
      profile {
        title
        id
      }
      id
    }
    category {
      pk
      name
      id
    }
    bloc {
      id
      name
      tenants {
        pk
        name
        id
      }
    }
    restricted
    approvalDate
    expirationDate
    effectiveDate
    originationDate
    documentEditing {
      pk
      id
    }
    attachments {
      title
      fileUrl
      id
    }
    showChangesTaskId
    nameChanges
    headerImageUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "documentSavePk",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "html",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "author",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "category",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bloc",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flowTemplate",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "activeLifespan",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expirationDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "legacyEffectiveDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDateTimedelta",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "effectiveDateAlgorithm",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approvalDate",
    "type": "PStatDate",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "restricted",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hasParent",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "activeLifespan",
    "variableName": "activeLifespan",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "approvalDate",
    "variableName": "approvalDate",
    "type": "PStatDate"
  },
  {
    "kind": "Variable",
    "name": "author",
    "variableName": "author",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "bloc",
    "variableName": "bloc",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "documentPk",
    "variableName": "documentPk",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "documentSavePk",
    "variableName": "documentSavePk",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "effectiveDate",
    "variableName": "effectiveDate",
    "type": "PStatDate"
  },
  {
    "kind": "Variable",
    "name": "effectiveDateAlgorithm",
    "variableName": "effectiveDateAlgorithm",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "effectiveDateTimedelta",
    "variableName": "effectiveDateTimedelta",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "expirationDate",
    "variableName": "expirationDate",
    "type": "PStatDate"
  },
  {
    "kind": "Variable",
    "name": "flowTemplate",
    "variableName": "flowTemplate",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "hasParent",
    "variableName": "hasParent",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "html",
    "variableName": "html",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "legacyEffectiveDate",
    "variableName": "legacyEffectiveDate",
    "type": "PStatDate"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "restricted",
    "variableName": "restricted",
    "type": "Boolean"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "html",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userDisplay",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v9 = [
  v4,
  v2
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "originationDate",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileUrl",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showChangesTaskId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "nameChanges",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "headerImageUrl",
  "args": null,
  "storageKey": null
},
v20 = [
  v4,
  v2,
  v10
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PreviewQuery",
  "id": "f1cbd5de748d654f51d5e411f1a68414",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PreviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentPreview",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentPreviewType",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "UserType",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "ProfileType",
                "plural": false,
                "selections": [
                  v8
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryType",
            "plural": false,
            "selections": v9
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v10,
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": v9
              }
            ]
          },
          v11,
          v12,
          v13,
          v14,
          v15,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentEditing",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v4
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "attachments",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAttachmentType",
            "plural": true,
            "selections": [
              v8,
              v16
            ]
          },
          v17,
          v18,
          v19
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PreviewQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentPreview",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentPreviewType",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "author",
            "storageKey": null,
            "args": null,
            "concreteType": "UserType",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "profile",
                "storageKey": null,
                "args": null,
                "concreteType": "ProfileType",
                "plural": false,
                "selections": [
                  v8,
                  v10
                ]
              },
              v10
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "category",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryType",
            "plural": false,
            "selections": v20
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v10,
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantType",
                "plural": true,
                "selections": v20
              }
            ]
          },
          v11,
          v12,
          v13,
          v14,
          v15,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentEditing",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v4,
              v10
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "attachments",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAttachmentType",
            "plural": true,
            "selections": [
              v8,
              v16,
              v10
            ]
          },
          v17,
          v18,
          v19
        ]
      }
    ]
  }
};
})();
node.text = "query PreviewQuery(\n  $documentPk: Int\n  $documentSavePk: Int\n  $name: String\n  $html: String\n  $author: Int\n  $category: Int\n  $bloc: Int\n  $flowTemplate: Int\n  $activeLifespan: Int\n  $expirationDate: PStatDate\n  $legacyEffectiveDate: PStatDate\n  $effectiveDate: PStatDate\n  $effectiveDateTimedelta: Int\n  $effectiveDateAlgorithm: String\n  $approvalDate: PStatDate\n  $restricted: Boolean\n  $hasParent: Boolean\n) {\n  documentPreview(documentPk: $documentPk, documentSavePk: $documentSavePk, name: $name, html: $html, author: $author, category: $category, bloc: $bloc, flowTemplate: $flowTemplate, activeLifespan: $activeLifespan, expirationDate: $expirationDate, legacyEffectiveDate: $legacyEffectiveDate, effectiveDate: $effectiveDate, effectiveDateTimedelta: $effectiveDateTimedelta, effectiveDateAlgorithm: $effectiveDateAlgorithm, approvalDate: $approvalDate, restricted: $restricted, hasParent: $hasParent) {\n    name\n    html\n    author {\n      pk\n      userDisplay\n      firstName\n      lastName\n      profile {\n        title\n        id\n      }\n      id\n    }\n    category {\n      pk\n      name\n      id\n    }\n    bloc {\n      id\n      name\n      tenants {\n        pk\n        name\n        id\n      }\n    }\n    restricted\n    approvalDate\n    expirationDate\n    effectiveDate\n    originationDate\n    documentEditing {\n      pk\n      id\n    }\n    attachments {\n      title\n      fileUrl\n      id\n    }\n    showChangesTaskId\n    nameChanges\n    headerImageUrl\n  }\n}\n";
(node/*: any*/).hash = '00bfd4ea42db317379eab080d216938f';
module.exports = node;
