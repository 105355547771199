import copy from "copy-to-clipboard";
import { withRouter } from "found";
import { Box, Flex } from "grid-styled";
import parse from "html-react-parser";
import attributesToProps from "html-react-parser/lib/attributes-to-props";
import domToReact from "html-react-parser/lib/dom-to-react";
import { Component, Fragment, createRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withTranslation } from "react-i18next";
import ModalImage from "react-modal-image";
import ReactTooltip from "react-tooltip";
import system from "system-components";

import HorizontalScrollControl from "pstat-design-system/utils/AddHorizontalScrollControls";
import Tooltip from "pstat-design-system/Tooltip";
import {
  LeftScrollButton,
  RightScrollButton
} from "pstat-design-system/tables/PolicyTable";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const CopyLinkText = system({
  is: "span",
  color: "secondary.0"
}).extend`
  visibility: ${props => (props.isHovered ? `visible` : `hidden`)};
  white-space: nowrap;
`;

const FixedAspectIFrameContainer = system({
  overflow: "hidden",
  pt: "56.25%", //Yeah, i know, this sets the aspect ratio to 16:9 this *IS* a hack, but... IE11
  position: "relative" //https://www.benmarshall.me/responsive-iframes/
});

const FixedAspectIFrame = system({
  is: "iframe",
  border: "0",
  height: "100% !important",
  width: "100% !important",
  left: "0",
  position: "absolute",
  top: "0"
});

const CopyIconContainer = system({
  is: "span",
  fontSize: 2
}).extend`
  cursor: pointer;
  white-space: nowrap;
`;

class _CopyLinkIcon extends Component {
  constructor(props) {
    super(props);

    this.iconRef = createRef();
    const origin = document.location.origin;
    const pathname = document.location.pathname;
    this.cleanedHeadingId = this.props.headingId.replace(/\n/g, "");
    this.hash = `#${this.cleanedHeadingId}`;
    this.headingUrl = `${origin}${pathname}${this.hash}`;
    this.tooltipTimeout = null;
  }

  componentWillUnmount() {
    clearTimeout(this.tooltipTimeout);
  }

  handleCopy = () => {
    const to = {
      pathname: document.location.pathname,
      hash: this.hash
    };
    this.props.router.push(to);
  };

  handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      copy(this.headingUrl);
      ReactTooltip.show(this.iconRef.current);
      this.tooltipTimeout = setTimeout(() => {
        ReactTooltip.hide(this.iconRef.current);
      }, 2000);
      this.handleCopy();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <CopyIconContainer
          data-tip={true}
          data-for={`copy-heading-link-tooltip-${this.cleanedHeadingId}`}
          data-event="mousedown"
          data-event-off="mouseup"
          data-delay-hide="2000"
          innerRef={this.iconRef}
          onKeyDown={this.handleKeyDown}
        >
          <CopyToClipboard text={this.headingUrl} onCopy={this.handleCopy}>
            <span>
              <StyledFontAwesomeIcon
                icon={["fal", "share-square"]}
                color="secondary.0"
                tabIndex={0}
                role="button"
              />

              <CopyLinkText isHovered={this.props.isHovered}>
                {t("buttons.share.copyLinkText")}
              </CopyLinkText>
            </span>
          </CopyToClipboard>
        </CopyIconContainer>
        <Tooltip name={`copy-heading-link-tooltip-${this.cleanedHeadingId}`}>
          <span>{t("buttons.share.tooltip")}</span>
        </Tooltip>
      </Fragment>
    );
  }
}
const CopyLinkIcon = withTranslation()(_CopyLinkIcon);
export const CopyLinkIconWithRouter = withRouter(CopyLinkIcon);

const isHeading = ({ name }) =>
  name === "h1" ||
  name === "h2" ||
  name === "h3" ||
  name === "h4" ||
  name === "h5" ||
  name === "h6";

const isImage = ({ name }) => name === "img";

const isIFrame = ({ name }) => name === "iframe";

const hasId = ({ attribs }) => attribs.id !== undefined;

const createHeadingComponent = heading => {
  const headingProps = attributesToProps(heading.attribs);
  return props => {
    return (
      <Flex
        is={heading.name}
        alignItems="baseline"
        {...headingProps}
        {...props}
      >
        {props.children}
      </Flex>
    );
  };
};

const RenderHeader = props => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <props.Heading
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      justifyContent={props.isCentered ? "center" : ""}
    >
      <Box is="span">{domToReactWithReplace(props.children)}</Box>
      <Box is="span">
        <CopyLinkIconWithRouter
          headingId={props.headingId}
          isHovered={isHovered}
        />
      </Box>
    </props.Heading>
  );
};

const TableOverflowContainer = system({
  mb: 3,
  position: "relative"
}).extend`overflow-x: auto;`;

const InlineImageContainer = system({
  display: "inline"
}).extend`
  & > div {
    display: inline;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  & div > img {
    width: inherit;
    height: inherit;
  }
`;

const replaceNode = domNode => {
  if (isHeading(domNode) && hasId(domNode)) {
    const children = domNode.children;
    const Heading = createHeadingComponent(domNode);
    const headingId = domNode.attribs.id;
    const headingClasses = domNode.attribs.class || "";
    const isCentered = headingClasses.indexOf("center") !== -1;

    return (
      <RenderHeader
        children={children}
        Heading={Heading}
        headingId={headingId}
        isCentered={isCentered}
      />
    );
  } else if (isImage(domNode)) {
    return (
      <InlineImageContainer
        style={{
          width: domNode?.attribs.width ? `${domNode.attribs.width}px` : null,
          height: domNode?.attribs.height
            ? `${domNode.attribs.height}px`
            : null,
          maxHeight: domNode?.attribs.height
            ? `${domNode.attribs.height}px`
            : null
        }}
      >
        <ModalImage
          {...domNode.attribs}
          {...domNode}
          small={domNode.attribs.src}
          large={domNode.attribs.src}
        />
      </InlineImageContainer>
    );
  } else if (isIFrame(domNode)) {
    return (
      <FixedAspectIFrameContainer>
        <FixedAspectIFrame
          {...domNode.attribs}
          height={"100%"}
          width={"100%"}
        />
      </FixedAspectIFrameContainer>
    );
  } else if (domNode.name === "table") {
    return (
      <TableOverflowContainer>
        <HorizontalScrollControl
          render={contentRef => (
            <table ref={contentRef} {...attributesToProps(domNode.attribs)}>
              {domToReactWithReplace(domNode.children)}
            </table>
          )}
          LeftButton={LeftScrollButton}
          RightButton={RightScrollButton}
        />
      </TableOverflowContainer>
    );
  } else {
    return null;
  }
};

const domToReactWithReplace = nodes =>
  domToReact(nodes, { replace: replaceNode });

export const createPolicyContent = html => {
  return parse(html, { replace: replaceNode });
};

export default createPolicyContent;
