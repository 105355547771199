import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text/paragraph";

const SubmitModal = props => {
  const { t } = useTranslation();
  const isEnterKey = event => event.key === "Enter";
  const handleSubmitKeyDown = event => {
    if (isEnterKey(event)) {
      handleSubmit(event);
    }
  };
  const handleCancelKeyDown = event => {
    if (isEnterKey(event)) {
      props.onClose();
    }
  };
  const handleSubmit = event => {
    props.onSubmit();
  };
  return (
    <Modal
      id={"confirmation_modal"}
      contentLabel={props.title}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                id="confirmation_modal_submit_button"
                buttonStyle="danger"
                onClick={handleSubmit}
                onKeyDown={handleSubmitKeyDown}
                processing={props.processing}
              >
                {props.submitButtonText}
              </Button>
            </Box>
            <Box>
              <Button
                buttonStyle="tertiary"
                onClick={props.onClose}
                onKeyDown={handleCancelKeyDown}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={3} pb={5}>
        <Text>{props.text}</Text>
      </Flex>
    </Modal>
  );
};

export default withLabelContext(SubmitModal);
