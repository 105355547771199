const CODES_MAPPING = {
  "policystat.com": "tracking_codes/policystat/index",
  "pstatbeta.com": "tracking_codes/pstatbeta/index"
};

export default function doInjectHotjarCode(currentOrigin) {
  // Inject Hotjar only if site is running on one of "white listed" domain
  if (currentOrigin.includes("policystat.com")) {
    require(`./${CODES_MAPPING["policystat.com"]}`);
  } else if (currentOrigin.includes("pstatbeta.com")) {
    require(`./${CODES_MAPPING["pstatbeta.com"]}`);
  }
}
