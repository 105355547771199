import graphql from "babel-plugin-relay/macro";
import { Fragment } from "react";
import { createFragmentContainer } from "react-relay";

import Info, {
  ApprovedDate,
  AuthorContainer,
  CategoryContainer,
  ReferencesContainer,
  ReferencesFragmentContainer,
  ReferencesLinkBadge,
  RevisionDate,
  SectionDivider,
  StatusAndPkSection
} from "pstat-anywhere/components/view/Info";
import {
  AuthorSearchLinkFragmentContainer,
  CategorySearchLinkFragmentContainer
} from "pstat-anywhere/components/view/Properties";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import { useTenant } from "pstat-anywhere/context_providers/TenantContext";

const InfoButton = ({ template, startOpen }) => {
  const labels = useLabels();
  const tenant = useTenant();
  return (
    <Info objectLabel={labels.documentLabel} startOpen={startOpen}>
      <InfoModalContent template={template} labels={labels} tenant={tenant} />
    </Info>
  );
};

export default createFragmentContainer(
  InfoButton,
  graphql`
    fragment Info_template on SystemDocumentTemplateType {
      pk
      status
      approvalDate(formatted: true)
      revisionDate(formatted: true)
      author {
        ...Properties_author
      }
      category {
        ...Properties_category
      }
      tags {
        ...Info_references
      }
    }
  `
);

const InfoModalContent = ({ template, labels, tenant }) => (
  <Fragment>
    <StatusAndPkSection pk={template.pk} status={template.status} />
    <SectionDivider />
    <ApprovedDate
      documentSettings={tenant.documentSettings}
      labels={labels}
      date={template.approvalDate}
    />
    <RevisionDate
      documentSettings={tenant.documentSettings}
      labels={labels}
      date={template.revisionDate}
    />
    <SectionDivider />
    <AuthorContainer documentSettings={tenant.documentSettings} labels={labels}>
      <AuthorSearchLinkFragmentContainer author={template.author} />
    </AuthorContainer>
    <CategoryContainer
      documentSettings={tenant.documentSettings}
      labels={labels}
    >
      <CategorySearchLinkFragmentContainer category={template.category} />
    </CategoryContainer>
    <ReferencesContainer
      references={template.tags}
      documentSettings={tenant.documentSettings}
      labels={labels}
    >
      <ReferencesFragmentContainer
        references={template.tags}
        renderReference={(pk, text) => (
          <ReferencesLinkBadge pk={pk} text={text} />
        )}
      />
    </ReferencesContainer>
  </Fragment>
);
