/**
 * @flow
 * @relayHash f884afe9dd56b5a5c2809bee283f8dca
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ImportFromWordQueryVariables = {||};
export type ImportFromWordQueryResponse = {|
  +documentBuild: ?{|
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +redirectUrl: ?string,
  |}
|};
export type ImportFromWordQuery = {|
  variables: ImportFromWordQueryVariables,
  response: ImportFromWordQueryResponse,
|};
*/


/*
query ImportFromWordQuery {
  documentBuild {
    error {
      statusCode
      message
    }
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentBuild",
    "storageKey": null,
    "args": null,
    "concreteType": "DocumentBuildType",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ImportFromWordQuery",
  "id": "3d090935e20d06a887e177f4e1188453",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ImportFromWordQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "ImportFromWordQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query ImportFromWordQuery {\n  documentBuild {\n    error {\n      statusCode\n      message\n    }\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = '6937f43486c4aec61316e7c6cee5964b';
module.exports = node;
