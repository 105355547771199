/**
 * @flow
 * @relayHash 8c50d070eb2fd16c29d2839f5b4cb223
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type getWorkflowTemplateQueryVariables = {|
  pk: number
|};
export type getWorkflowTemplateQueryResponse = {|
  +flowTemplate: ?{|
    +id: string,
    +pk: ?number,
    +name: string,
    +steps: ?$ReadOnlyArray<?{|
      +name: ?string,
      +approvers: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +firstName: string,
        +lastName: string,
      |}>,
      +includeDocumentOwner: boolean,
    |}>,
  |}
|};
export type getWorkflowTemplateQuery = {|
  variables: getWorkflowTemplateQueryVariables,
  response: getWorkflowTemplateQueryResponse,
|};
*/


/*
query getWorkflowTemplateQuery(
  $pk: Int!
) {
  flowTemplate(pk: $pk) {
    id
    pk
    name
    steps {
      name
      approvers {
        pk
        firstName
        lastName
        id
      }
      includeDocumentOwner
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "includeDocumentOwner",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "getWorkflowTemplateQuery",
  "id": "3b33893f4ac245a9cef628f4f93dea7c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "getWorkflowTemplateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "flowTemplate",
        "storageKey": null,
        "args": v1,
        "concreteType": "WorkflowTemplateType",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "steps",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalStepType",
            "plural": true,
            "selections": [
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "approvers",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalStepUserType",
                "plural": true,
                "selections": [
                  v3,
                  v5,
                  v6
                ]
              },
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "getWorkflowTemplateQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "flowTemplate",
        "storageKey": null,
        "args": v1,
        "concreteType": "WorkflowTemplateType",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "steps",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalStepType",
            "plural": true,
            "selections": [
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "approvers",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalStepUserType",
                "plural": true,
                "selections": [
                  v3,
                  v5,
                  v6,
                  v2
                ]
              },
              v7,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query getWorkflowTemplateQuery(\n  $pk: Int!\n) {\n  flowTemplate(pk: $pk) {\n    id\n    pk\n    name\n    steps {\n      name\n      approvers {\n        pk\n        firstName\n        lastName\n        id\n      }\n      includeDocumentOwner\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '1631c817099516aed32f99af362e1cb6';
module.exports = node;
