import system from "system-components";

import { fontFamily, fontStyle, lineHeight } from "pstat-design-system/utils";

const fontSizes = {
  normal: 2,
  small: 1,
  tiny: 0
};

export const Text = system(
  {
    is: "span",
    color: "nav.0",
    fontWeight: "normal"
  },
  props => {
    const sizeName = props.size || "normal";
    const themeFontSizes = props.theme.fontSizes || {};

    return { fontSize: themeFontSizes[fontSizes[sizeName]] };
  },
  "fontSize",
  "fontWeight",
  "color",
  "borderColor",
  "borders",
  "borderRadius",
  "space",
  "display",
  "position",
  "width",
  "textAlign"
).extend`
  ${fontFamily};
  ${lineHeight};
  ${fontStyle};
`;

Text.displayName = "Text";

export const SubmitExportButton = system({
  is: "input",
  color: "secondary.0",
  ml: 4,
  border: 0,
  p: 0,
  fontSize: 15
}).extend`
  cursor: pointer;
  text-align: right;
  background: none;
`;
SubmitExportButton.displayName = "SubmitExportButton";

// TODO fix this P so the props pass through properly again
// or just throw out the P component
export const P = system({
  is: Text,
  mb: ""
});

P.displayName = "P";

export const Error = props => <Text {...props} color="messages.error" />;

Error.displayName = "Error";

export const Warning = props => <Text {...props} color="messages.warning" />;

Warning.displayName = "Warning";

export const Success = props => <Text {...props} color="messages.success" />;

Success.displayName = "Success";

export const Label = Text.extend`
  vertical-align: middle;
`;

Label.defaultProps = {
  is: "span",
  color: "nav.0",
  size: "small",
  fontWeight: "medium"
};

Label.displayName = "Label";

export const ErrorLabel = props => (
  <Text {...props} size="small" color="messages.error" fontWeight="medium" />
);

ErrorLabel.displayName = "ErrorLabel";
