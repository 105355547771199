import { useTranslation } from "react-i18next";

import { ExpandablePanel } from "pstat-design-system/Panel";
import { Text } from "pstat-design-system/text";

const ApplicabilityPanel = props => {
  const { t } = useTranslation();
  const { document } = props;
  const tenants = document.bloc.tenants;
  const tenantNames = tenants.map(tenant => {
    return tenant.name;
  });
  const tenantList = tenantNames.join(", ");
  return (
    <ExpandablePanel
      headerText={t("tables.labels.applicability")}
      id="applicability-section"
      expandedExternal={props.expanded}
      onExpandedChanged={props.onExpandedChanged}
    >
      <Text>{tenantList}</Text>
    </ExpandablePanel>
  );
};

export default ApplicabilityPanel;
