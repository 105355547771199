import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";

import theme from "../../../themes/policystat/theme";

import { ClearableTextInput } from "./TextInput";

import { SearchButton } from "pstat-design-system/shared/Button";
import InlineInputWrapper from "pstat-design-system/InlineInputWrapper";

const SearchInput = system({
  is: ClearableTextInput,
  border: 2,
  borderColor: "nav.90",
  py: 3,
  height: "44px"
}).extend`
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  &:focus {
    border: ${themeGet("borders.2")} ${themeGet("colors.primary.0")};
    border-right: none;
  }

  // For mobile devices - set font-size for disabling auto-zoom
  @media (max-width: ${theme.breakpoints[1]}) {
    font-size:16px;
  }
`;

const SearchInlineWrapper = system({
  is: InlineInputWrapper,
  width: 1,
  p: 0
});

const SearchField = ({
  inputRef,
  onSearch,
  placeholder,
  loading,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const documentLabelPlural = "documentLabelPlural";
  return (
    <SearchInlineWrapper>
      <Flex width="100%" alignItems="center">
        <Box flex={1}>
          <SearchInput
            id="search_input"
            placeholder={
              placeholder ||
              t("inputs.searchInput.placeholder", { documentLabelPlural })
            }
            aria-label={t(
              "documentSearch.quickSearch.inputComponent.placeholder"
            )}
            {...otherProps}
            innerRef={inputRef}
          />
        </Box>
        <SearchButton id="search_button" onClick={onSearch} loading={loading} />
      </Flex>
    </SearchInlineWrapper>
  );
};

export default SearchField;
