import graphql from "babel-plugin-relay/macro";

export const OverridePolicyQuery = graphql`
  query OverridePolicyQuery($documentPk: Int!) {
    documentOverride(pk: $documentPk) {
      document {
        ...PolicyEditor_document
      }
      canLocalize
      editForm {
        name
        author {
          pk
          firstName
          lastName
          title
        }
        category {
          pk
          name
        }
        categoryChoices {
          pk
          name
        }
        flowTemplate {
          pk
          name
        }
        flowTemplateChoices {
          pk
          name
        }
        bloc {
          pk
          name
        }
        blocChoices {
          pk
          name
        }
        html
        activeLifespan
        expirationDate
        isAForm
        restricted
        legacyEffectiveDate
        approvalDate
        regulations
        onesourceDocuments {
          oneSourceDocumentId
          catalogNumber
          manufacturerName
          viewUrl
          revisionInformation
        }
      }
      tagForm {
        tags {
          pk
          name
        }
      }
      scheduledEffectiveDateForm {
        effectiveDate
        effectiveDateTimedelta
        effectiveDateAlgorithm
        originationDate
      }
      preimportRevisionForm {
        revisionDates
      }
      systemDocumentTemplateForm {
        blocs {
          pk
          name
        }
      }
      error {
        statusCode
        message
      }
      tenantsToRedirect {
        name
        url
      }
      ...PolicyEditor_editorPropertiesOverride
      redirectUrl
      policyActions
    }
  }
`;

export default OverridePolicyQuery;
