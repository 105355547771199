import graphql from "babel-plugin-relay/macro";

const NewAndRecentlyRevisedCSVQuery = graphql`
  query NewAndRecentlyRevisedCSVQuery {
    recentlyRevisedDocumentsCsv {
      taskId
    }
  }
`;

export default NewAndRecentlyRevisedCSVQuery;
