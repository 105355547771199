import { Route } from "found";
import { Fragment, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { HITS_PER_PAGE } from "../pages/reports";

import ScheduledDocumentsQuery from "./awaiting_activation/graphql/ScheduledDocumentsQuery";
import PendingPoliciesReport from "./awaiting_activation/PendingPoliciesReport";
import ScheduledDocumentsReport from "./awaiting_activation/ScheduledDocumentsReport";
import DuplicatePoliciesReport from "./duplicates/DuplicatePoliciesReport";
import DuplicateDocumentsQuery from "./duplicates/graphql/DuplicateDocumentsQuery";
import DocumentsForReviewReport from "./expiring/DocumentsForReviewReport";
import NewAndRecentlyRevisedReport from "./revised_recent/NewAndRecentlyRevisedReport";
import VergeAssociatedDocumentsQuery from "./verge_standards_associated/VergeAssociatedDocumentsQuery";
import VergeAssociatedDocumentsReport from "./verge_standards_associated/VergeAssociatedDocumentsReport";

import titleCase from "pstat-anywhere/utils/titleCase";
import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";
import { PageWrapper } from "pstat-anywhere/utils/pageWrappers";
import {
  useLabels,
  withLabelContext
} from "pstat-anywhere/context_providers/LabelContext";
import { ContentContainer } from "pstat-anywhere/components/pages";

function PolicyReportsWrapper(props) {
  return <ContentContainer>{props.children}</ContentContainer>;
}

const ReportDocumentTitleWrapper = withLabelContext(
  ({ labels, titleKey, Component, ...props }) => {
    const { t } = useTranslation();
    return (
      <Fragment>
        <Helmet>
          <title>{t(titleKey, labels)}</title>
        </Helmet>
        <Component {...props} />
      </Fragment>
    );
  }
);

const ScheduledDocumentsRoute = props => (
  <ErrorCheckWrapper queryName="scheduledDocuments" {...props}>
    <ReportDocumentTitleWrapper
      titleKey="documentReports.awaitingActivation.scheduled.htmlTitle"
      Component={ScheduledDocumentsReport}
      {...props}
    />
  </ErrorCheckWrapper>
);

const VergeAssociatedDocumentsRoute = props => {
  const { documentLabelPlural } = useLabels();
  const { t } = useTranslation();

  const forcedTitle = useMemo(() => {
    return t("documentReports.associatedStandards.title", {
      documentLabelPlural: titleCase(documentLabelPlural)
    });
  }, [documentLabelPlural, t]);

  return (
    <PageWrapper
      forcedTitle={forcedTitle}
      component={VergeAssociatedDocumentsReport}
      queryName="documentsWithVergeStandards"
      {...props}
    />
  );
};

const getReportParams = location => ({
  sort: location.query.sort,
  first: HITS_PER_PAGE,
  after: location.query.after,
  q: location.query.search_query
});

const routes = (
  <Route path="policy_reports" Component={PolicyReportsWrapper}>
    <Route
      path="revised/recent"
      data={{ routeName: "revised_recent_all" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.revisedRecent.documentTitle"
          Component={NewAndRecentlyRevisedReport}
          {...props}
        />
      )}
    />
    <Route
      path="expiring"
      data={{ routeName: "expiring_all" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.expiring.documentTitle"
          Component={DocumentsForReviewReport}
          {...props}
        />
      )}
    />
    <Route
      path="expiring/mine"
      data={{ routeName: "expiring_mine" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.expiring.documentTitle"
          Component={DocumentsForReviewReport}
          {...props}
        />
      )}
    />
    <Route
      path="expiring/template"
      data={{ routeName: "expiring_templates_all" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.expiring.documentTitle"
          Component={DocumentsForReviewReport}
          {...props}
        />
      )}
    />
    <Route
      path="expiring/template/mine"
      data={{ routeName: "expiring_templates_mine" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.expiring.documentTitle"
          Component={DocumentsForReviewReport}
          {...props}
        />
      )}
    />
    <Route
      path="awaiting_activation/pending"
      data={{ routeName: "awaiting_activation_mine" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.pending.documentTitle"
          Component={PendingPoliciesReport}
          {...props}
        />
      )}
    />
    <Route
      path="awaiting_activation/pending/all"
      data={{ routeName: "awaiting_activation_all" }}
      Component={props => (
        <ReportDocumentTitleWrapper
          titleKey="documentReports.pending.documentTitle"
          Component={PendingPoliciesReport}
          {...props}
        />
      )}
    />
    <Route
      path="awaiting_activation/scheduled"
      data={{ routeName: "scheduled_report_all" }}
      query={ScheduledDocumentsQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location),
        showAll: true
      })}
      render={({ props }) => (
        <ScheduledDocumentsRoute {...props} showAll={true} />
      )}
    />
    <Route
      path="awaiting_activation/scheduled/mine"
      data={{ routeName: "scheduled_report_mine" }}
      query={ScheduledDocumentsQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location),
        showAll: false
      })}
      render={({ props }) => (
        <ScheduledDocumentsRoute {...props} showAll={false} />
      )}
    />
    <Route
      path="duplicates"
      data={{ routeName: "duplicates_all" }}
      query={DuplicateDocumentsQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location)
      })}
      render={({ props }) => (
        <ErrorCheckWrapper queryName="duplicateDocuments" {...props}>
          <ReportDocumentTitleWrapper
            titleKey="documentReports.duplicates.documentTitle"
            Component={DuplicatePoliciesReport}
            {...props}
          />
        </ErrorCheckWrapper>
      )}
    />
    <Route
      path="standards/associated"
      data={{ routeName: "associated_standards_report_all" }}
      query={VergeAssociatedDocumentsQuery}
      prepareVariables={(params, { location }) => ({
        ...params,
        ...getReportParams(location),
        justMine: false
      })}
      render={({ props }) => <VergeAssociatedDocumentsRoute {...props} />}
    />
  </Route>
);

export default routes;
