import graphql from "babel-plugin-relay/macro";

const TemplateManagementPageQuery = graphql`
  query TemplateManagementPageQuery(
    $after: String
    $first: Int
    $sort: String
  ) {
    systemDocumentTemplates(sort: $sort) {
      systemDocumentTemplates(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            status
            name
            html
            author {
              firstName
              lastName
              title
            }
            category {
              name
            }
            approvalDate
            revisionDate
            tags {
              name
            }
            document {
              pk
            }
          }
        }
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default TemplateManagementPageQuery;
