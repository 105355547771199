/**
 * @flow
 * @relayHash 5ab1430cf490196c34813fbee64782e9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteGroupAcknowledgmentMutationVariables = {|
  recurringAckPk: number
|};
export type DeleteGroupAcknowledgmentMutationResponse = {|
  +deleteGroupCertification: ?{|
    +ok: ?boolean
  |}
|};
export type DeleteGroupAcknowledgmentMutation = {|
  variables: DeleteGroupAcknowledgmentMutationVariables,
  response: DeleteGroupAcknowledgmentMutationResponse,
|};
*/


/*
mutation DeleteGroupAcknowledgmentMutation(
  $recurringAckPk: Int!
) {
  deleteGroupCertification(recurringAckPk: $recurringAckPk) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "recurringAckPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteGroupCertification",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "recurringAckPk",
        "variableName": "recurringAckPk",
        "type": "Int"
      }
    ],
    "concreteType": "DeleteGroupCertificationMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeleteGroupAcknowledgmentMutation",
  "id": "61ed59ea6428a2bc250ae08466ab9940",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteGroupAcknowledgmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteGroupAcknowledgmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation DeleteGroupAcknowledgmentMutation(\n  $recurringAckPk: Int!\n) {\n  deleteGroupCertification(recurringAckPk: $recurringAckPk) {\n    ok\n  }\n}\n";
(node/*: any*/).hash = '46b4e36ae9b6668efb1f1f0192537c4e';
module.exports = node;
