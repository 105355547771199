import graphql from "babel-plugin-relay/macro";

export const NotificationsQuery = graphql`
  query NotificationsQuery(
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    notifications(first: $first, after: $after, before: $before, last: $last) {
      totalCount
      unseenCount
      notificationRssFeedUrl
      edges {
        node {
          id
          pk
          noticeType {
            id
            label
          }
          message
          added
          url
          unseen
        }
      }
    }
  }
`;
