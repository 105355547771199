/**
 * @flow
 * @relayHash ccc23f88edbdcd198a8cbb2073fbe12e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type MyAcknowledgmentsForLineQueryVariables = {|
  linePk: number,
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type MyAcknowledgmentsForLineQueryResponse = {|
  +acknowledgmentsByLine: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +dateActive: ?string,
        +dateAcknowledged: ?string,
        +dateAssigned: ?string,
        +revised: ?string,
        +waitTime: ?string,
        +document: ?{|
          +pk: ?number,
          +name: string,
          +status: ?DocumentStatus,
          +category: {|
            +name: string
          |},
        |},
      |}
    |}>,
  |}
|};
export type MyAcknowledgmentsForLineQuery = {|
  variables: MyAcknowledgmentsForLineQueryVariables,
  response: MyAcknowledgmentsForLineQueryResponse,
|};
*/


/*
query MyAcknowledgmentsForLineQuery(
  $linePk: Int!
  $after: String
  $first: Int
  $sort: String
) {
  acknowledgmentsByLine(linePk: $linePk, after: $after, first: $first, sort: $sort) {
    totalCount
    edges {
      node {
        dateActive
        dateAcknowledged
        dateAssigned
        revised
        waitTime
        document {
          pk
          name
          status
          category {
            name
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "linePk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "linePk",
    "variableName": "linePk",
    "type": "Int!"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateActive",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateAcknowledged",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateAssigned",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revised",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "waitTime",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MyAcknowledgmentsForLineQuery",
  "id": "c411d98b6d6f75301711b32c31466c93",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MyAcknowledgmentsForLineQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "acknowledgmentsByLine",
        "storageKey": null,
        "args": v1,
        "concreteType": "CountableConnectionAcknowledgmentsByLineConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentsByLineEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentByLineType",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "document",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v8,
                      v9,
                      v10,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryType",
                        "plural": false,
                        "selections": [
                          v9
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MyAcknowledgmentsForLineQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "acknowledgmentsByLine",
        "storageKey": null,
        "args": v1,
        "concreteType": "CountableConnectionAcknowledgmentsByLineConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentsByLineEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentByLineType",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "document",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v8,
                      v9,
                      v10,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryType",
                        "plural": false,
                        "selections": [
                          v9,
                          v11
                        ]
                      },
                      v11
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query MyAcknowledgmentsForLineQuery(\n  $linePk: Int!\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  acknowledgmentsByLine(linePk: $linePk, after: $after, first: $first, sort: $sort) {\n    totalCount\n    edges {\n      node {\n        dateActive\n        dateAcknowledged\n        dateAssigned\n        revised\n        waitTime\n        document {\n          pk\n          name\n          status\n          category {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = '215c04e265c6e95df33f622b09d96668';
module.exports = node;
