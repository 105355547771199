/**
 * @flow
 * @relayHash 06b98189eaf1bff3bc86ce9e99db4120
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type EditScheduledEffectiveDateInput = {
  documentPk: number,
  effectiveDate?: ?any,
  effectiveDateTimedelta?: ?number,
  effectiveDateAlgorithm: string,
  clientMutationId?: ?string,
};
export type EditScheduledEffectiveDateMutationVariables = {|
  input: EditScheduledEffectiveDateInput
|};
export type EditScheduledEffectiveDateMutationResponse = {|
  +editScheduledEffectiveDate: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +document: ?{|
      +id: string,
      +pk: ?number,
      +status: ?DocumentStatus,
      +effectiveDate: ?string,
      +actions: ?$ReadOnlyArray<?{|
        +id: string,
        +pk: ?number,
        +actionType: string,
        +actionDate: any,
        +comment: string,
        +canEdit: ?boolean,
        +isExternal: boolean,
        +user: ?{|
          +id: string,
          +userDisplay: ?string,
        |},
      |}>,
    |},
  |}
|};
export type EditScheduledEffectiveDateMutation = {|
  variables: EditScheduledEffectiveDateMutationVariables,
  response: EditScheduledEffectiveDateMutationResponse,
|};
*/


/*
mutation EditScheduledEffectiveDateMutation(
  $input: EditScheduledEffectiveDateInput!
) {
  editScheduledEffectiveDate(input: $input) {
    ok
    reasons
    document {
      id
      pk
      status
      effectiveDate(formatted: true)
      actions(isEditPage: false) {
        id
        pk
        actionType
        actionDate
        comment
        canEdit
        isExternal
        user {
          id
          userDisplay(userDisplayType: FORWARD)
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EditScheduledEffectiveDateInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "editScheduledEffectiveDate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EditScheduledEffectiveDateInput!"
      }
    ],
    "concreteType": "EditScheduledEffectiveDatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "document",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentType",
        "plural": false,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "effectiveDate",
            "args": [
              {
                "kind": "Literal",
                "name": "formatted",
                "value": true,
                "type": "Boolean"
              }
            ],
            "storageKey": "effectiveDate(formatted:true)"
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actions",
            "storageKey": "actions(isEditPage:false)",
            "args": [
              {
                "kind": "Literal",
                "name": "isEditPage",
                "value": false,
                "type": "Boolean"
              }
            ],
            "concreteType": "DocumentActionType",
            "plural": true,
            "selections": [
              v1,
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "actionType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "actionDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "comment",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "canEdit",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isExternal",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "UserType",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "userDisplay",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "userDisplayType",
                        "value": "FORWARD",
                        "type": "UserDisplayType"
                      }
                    ],
                    "storageKey": "userDisplay(userDisplayType:\"FORWARD\")"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "EditScheduledEffectiveDateMutation",
  "id": "36190098aaffb798902f12bdd2b4dcf1",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditScheduledEffectiveDateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "EditScheduledEffectiveDateMutation",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
node.text = "mutation EditScheduledEffectiveDateMutation(\n  $input: EditScheduledEffectiveDateInput!\n) {\n  editScheduledEffectiveDate(input: $input) {\n    ok\n    reasons\n    document {\n      id\n      pk\n      status\n      effectiveDate(formatted: true)\n      actions(isEditPage: false) {\n        id\n        pk\n        actionType\n        actionDate\n        comment\n        canEdit\n        isExternal\n        user {\n          id\n          userDisplay(userDisplayType: FORWARD)\n        }\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = 'ca6e538563da30bbbc1240d5d7643a8a';
module.exports = node;
