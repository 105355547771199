import uniqBy from "lodash/uniqBy";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { doNoticeSettingsUpdate } from "../../NoticeSettingsMutation";

import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";

export default function useManageNotifications() {
  const { t } = useTranslation();

  const [settings, setSettings] = useState([]);
  const [loaded, setLoaded] = useState(false);

  function handleFieldChange(changedPk) {
    const newSettings = settings.map(setting => {
      if (setting.noticeType.pk === +changedPk) {
        return {
          ...setting,
          send: !setting.send
        };
      }
      return setting;
    });
    setSettings(newSettings);
  }

  function mapSettingsToSections() {
    const groups = uniqBy(settings, "noticeType.whenSent");
    const sections = groups.map(group => ({
      name: group.noticeType.whenSent,
      fields: settings
        .filter(
          setting => setting.noticeType.whenSent === group.noticeType.whenSent
        )
        .map(setting => ({ ...setting.noticeType, send: setting.send }))
    }));
    return sections;
  }

  function handleSettingsUpdate() {
    const noticeSettingsInput = settings.map(setting => ({
      noticeType: setting.noticeType.pk,
      send: setting.send
    }));
    doNoticeSettingsUpdate(noticeSettingsInput, onSettingsUpdateSuccess);
  }

  function onSettingsUpdateSuccess(response) {
    if (response.noticeSettings.ok) {
      createSuccessMessage(t("notifications.manage.saved"));
    } else {
      createErrorMessage(response.error);
    }
  }

  function handleDataLoaded(settings) {
    if (!loaded) {
      setLoaded(true);
      setSettings(settings);
    }
  }
  return {
    handleFieldChange,
    mapSettingsToSections,
    loaded,
    handleDataLoaded,
    handleSettingsUpdate
  };
}
