import { Box, Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import discardDraft from "./discardDraftMutation";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import { environment } from "pstat-anywhere/relay";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text/paragraph";


const ExistingDraftModal = props => {
  const [acceptDiscardDraftWarning, setAcceptDiscardDraftWarning] = useState(
    false
  );
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const { isOpen, onClose, labels, author, documentSavePk, viewer } = props;

  const isEnterKey = event => event.key === "Enter";
  const handleDiscardDraftKeyDown = event => {
    if (isEnterKey(event)) {
      handleDiscardDraft(event);
    }
  };
  const handleWorkFromDraftKeyDown = event => {
    if (isEnterKey(event)) {
      props.onClose();
    }
  };
  const handleDiscardDraft = event => {
    const variables = {
      documentSaveId: documentSavePk
    };
    discardDraft(environment, variables, (response, errors) => {
      if (errors) {
        throw new Error("error discarding draft");
      }
      if (response.discardDraft.ok) {
        window.location.reload();
      } else {
        throw new Error(response.discardDraft.reasons);
      }
    });
    setProcessing(true);
  };

  return (
    <Modal
      contentLabel={t("documentControl.edit.existingDraftModal.heading")}
      isOpen={isOpen}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                buttonStyle="secondary"
                onClick={onClose}
                onKeyDown={handleWorkFromDraftKeyDown}
              >
                {t("documentControl.edit.existingDraftModal.workFormDraft")}
              </Button>
            </Box>
            <Box>
              <Button
                buttonStyle="tertiary"
                onClick={handleDiscardDraft}
                onKeyDown={handleDiscardDraftKeyDown}
                disabled={!acceptDiscardDraftWarning}
                processing={processing}
              >
                {t("documentControl.edit.existingDraftModal.discard")}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={3}>
        <Text>
          {viewer.pk === author.pk ? (
            <Fragment>
              {t(
                "documentControl.edit.existingDraftModal.viewerIsAuthorMessage",
                {
                  documentLabel: labels.documentLabel.toLowerCase()
                }
              )}
            </Fragment>
          ) : (
            <Fragment>
              {t("documentControl.edit.existingDraftModal.message", {
                documentLabel: labels.documentLabel.toLowerCase(),
                firstName: author.firstName,
                lastName: author.lastName,
                email: author.email
              })}
            </Fragment>
          )}
        </Text>
        <CheckboxGroup
          name="discard-draft"
          onChange={() =>
            setAcceptDiscardDraftWarning(!acceptDiscardDraftWarning)
          }
          selectedValues={
            acceptDiscardDraftWarning ? [acceptDiscardDraftWarning] : null
          }
          px={0}
        >
          <Checkbox
            value={acceptDiscardDraftWarning}
            label={t("documentControl.edit.existingDraftModal.warning")}
          />
        </CheckboxGroup>
      </Flex>
    </Modal>
  );
};

export default withViewer(withLabelContext(ExistingDraftModal));
