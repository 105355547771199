import graphql from "babel-plugin-relay/macro";
import { fetchQuery } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const getCategoryLifespanQuery = graphql`
  query getCategoryLifespanQuery($categoryPk: Int) {
    getCategoryLifespan(categoryPk: $categoryPk)
  }
`;

const getCategoryLifespan = (categoryPk, setActiveLifespan) => {
  const variables = {
    categoryPk
  };
  fetchQuery(environment, getCategoryLifespanQuery, variables).then(data => {
    if (!data) {
      return;
    }
    const lifespan = data.getCategoryLifespan;
    setActiveLifespan(lifespan);
  });
};

export default getCategoryLifespan;
