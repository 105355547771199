/**
 * @flow
 * @relayHash 7952506579974b7c4c6ef2d98c77b4c2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DefaultExpirationWarningQueryVariables = {|
  lifespan?: ?number,
  categoryPk?: ?number,
  documentPk?: ?number,
  initial?: ?boolean,
|};
export type DefaultExpirationWarningQueryResponse = {|
  +expirationDefaultWarning: ?{|
    +lifespan: ?number,
    +defaultLifespan: ?number,
    +categoryName: ?string,
  |}
|};
export type DefaultExpirationWarningQuery = {|
  variables: DefaultExpirationWarningQueryVariables,
  response: DefaultExpirationWarningQueryResponse,
|};
*/


/*
query DefaultExpirationWarningQuery(
  $lifespan: Int
  $categoryPk: Int
  $documentPk: Int
  $initial: Boolean
) {
  expirationDefaultWarning(lifespan: $lifespan, categoryPk: $categoryPk, documentPk: $documentPk, initial: $initial) {
    lifespan
    defaultLifespan
    categoryName
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "lifespan",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categoryPk",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "initial",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "expirationDefaultWarning",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "categoryPk",
        "variableName": "categoryPk",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "documentPk",
        "variableName": "documentPk",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "initial",
        "variableName": "initial",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "lifespan",
        "variableName": "lifespan",
        "type": "Int"
      }
    ],
    "concreteType": "ExpirationDefaultWarningType",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lifespan",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "defaultLifespan",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "categoryName",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DefaultExpirationWarningQuery",
  "id": "3577b4f0279588e8bd189d4b0f6a3cf6",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DefaultExpirationWarningQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DefaultExpirationWarningQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query DefaultExpirationWarningQuery(\n  $lifespan: Int\n  $categoryPk: Int\n  $documentPk: Int\n  $initial: Boolean\n) {\n  expirationDefaultWarning(lifespan: $lifespan, categoryPk: $categoryPk, documentPk: $documentPk, initial: $initial) {\n    lifespan\n    defaultLifespan\n    categoryName\n  }\n}\n";
(node/*: any*/).hash = '5fa225dacc0afe8b1d123e85fe579e3b';
module.exports = node;
