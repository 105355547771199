import { Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { TextareaFormElement } from "pstat-design-system/inputs/FormElements";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";

// @flow

type Props = {
  id: string,
  onReject: Function,
  isOpen: boolean,
  onClose?: Function
};

export function _RejectModal({ id, onReject, isOpen, onClose, labels }: Props) {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { documentLabel } = labels;
  function handleCommentChange({ target: { value } }) {
    setComment(value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!comment) {
      setError(
        t("documentControl.reject.commentRequiredMessage", {
          documentLabel: documentLabel.toLowerCase()
        })
      );
    } else if (!processing) {
      setProcessing(true);
      onReject({ comment, successCallback: cleanup, failureCallback: errorCleanup });
      onClose();
    }
  }

  function cleanup() {
    setProcessing(false);
    setComment("");
    setError(null);
  }
  const errorCleanup = () => {
    setProcessing(false);
    setError(null);
  }
  const handleCancelKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      onClose();
    }
  };
  const handleRejectKeyDown = event => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      contentLabel={t("documentControl.reject.modal.contentLabel", {
        documentLabel
      })}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Button
              id="submit_reject_button"
              buttonStyle="secondary"
              data-testid="reject-submit-button"
              disabled={processing}
              processing={processing}
              onClick={handleSubmit}
              onKeyDown={handleRejectKeyDown}
            >
              {t("buttons.reject")}
            </Button>
            <Button
              buttonStyle="tertiary"
              onClick={onClose}
              onKeyDown={handleCancelKeyDown}
            >
              {t("buttons.cancel")}
            </Button>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={3} pb={5}>
        <form
          name="reject-form"
          id="reject-form"
          onSubmit={handleSubmit}
          data-testid="reject-form"
        >
          <TextareaFormElement
            label={t("documentControl.view.filters.comments")}
            rows={4}
            onChange={handleCommentChange}
            value={comment}
            dataTestid="reject-comment-textarea"
            width="100%"
            borderRadius={theme.radii[1]}
            my={theme.space[1]}
            required={true}
            errors={error ? [error] : null}
          />
        </form>
      </Flex>
    </Modal>
  );
}

export default withLabelContext(_RejectModal);
