import styled from "styled-components";
import { themeGet } from "styled-system";

import innerHtmlStyles from "./innerhtmlstyles";
import tocStyles from "./tocstyles";

import { fontFamily, lineHeight } from "pstat-design-system/utils";
import { diffStyles } from "pstat-design-system/diff";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const PolicyRenderer = styled.div`
  overflow-x: auto;

  & :not(${StyledFontAwesomeIcon}) {
    ${lineHeight};
    ${fontFamily};
    font-size: ${themeGet("fontSizes.2")};
    color: ${themeGet("colors.nav.0")};
    word-wrap: break-word;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }

  ${innerHtmlStyles}
  ${tocStyles}
  ${diffStyles}
`;

export default PolicyRenderer;
