import enTranslation from "./en/translation";
import frTranslation from "./fr/translation";

const locales = {
  en: {
    translation: enTranslation
  },
  fr: {
    translation: frTranslation
  }
};

export default locales;
