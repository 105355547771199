/**
 * @flow
 * @relayHash 9582de757a2689f3b8c21ef643234fc2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NoticeClearMutationVariables = {||};
export type NoticeClearMutationResponse = {|
  +noticeClearAll: ?{|
    +ok: ?boolean
  |}
|};
export type NoticeClearMutation = {|
  variables: NoticeClearMutationVariables,
  response: NoticeClearMutationResponse,
|};
*/


/*
mutation NoticeClearMutation {
  noticeClearAll {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "noticeClearAll",
    "storageKey": null,
    "args": null,
    "concreteType": "NoticeClear",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "NoticeClearMutation",
  "id": "7fd961c51fa990e441e501dceffc5b66",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NoticeClearMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "NoticeClearMutation",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "mutation NoticeClearMutation {\n  noticeClearAll {\n    ok\n  }\n}\n";
(node/*: any*/).hash = 'a35ca29ce6e3a32b52d0fd09950d6821';
module.exports = node;
