import { Flex } from "grid-styled";
import { QueryRenderer } from "react-relay";

import TokenViewPolicyQuery from "./TokenViewPolicyQuery";

import { PreviewContent } from "pstat-anywhere/components/document_control/PolicyEditor/components/Preview";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";


const TokenViewPolicy = props => {
  return (
    <QueryRenderer
      environment={props.environment}
      query={TokenViewPolicyQuery}
      variables={{ tokenId: props.params.tokenId }}
      render={renderProps => {
        if (renderProps.error) {
          // TODO: have actual error handling
          throw new Error("There was an error requesting this page");
        }
        if (renderProps.props && renderProps.props.documentTokenAccessView) {
          return (
            <PreviewContent
              document={renderProps.props.documentTokenAccessView}
              showPrintAndShare={false}
              {...props}
            />
          );
        } else {
          return (
            <Flex flex="1" justify="center" align="center">
              <LoadingSpinner size={100} />
            </Flex>
          );
        }
      }}
    />
  );
};

export default TokenViewPolicy;
