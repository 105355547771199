import { Box, Flex } from "grid-styled";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import { Link } from "pstat-design-system/Link";
import { RouterLink } from "pstat-design-system/RouterLink";
import { Text } from "pstat-design-system/text";

export { NavOptions } from "./NavOptions";
export {
  TERTIARY_NAVBAR_HEIGHT,
  ActionsDropdown,
  BottomNav,
  DropdownItem,
  TopNav
} from "./TertiaryNav";

export const FullHeightFlex = styled(Flex)`
  height: 100%;
`;

export const FullHeightBox = styled(Box)`
  height: 100%;
`;

export const FocusableDiv = system({
  display: "inline-block",
  role: "button",
  tabIndex: 0
}).extend`
  &:focus {
    outline: 0;
  }
`;

export const FocusBorder = system({
  display: "inline-block",
  border: "dashed 1px",
  borderColor: "transparent",
  borderRadius: 2,
  tabIndex: -1,
  p: 1
}).extend`
  &:focus {
    outline: 0;
  }
  ${FocusableDiv}:focus & {
    border: dashed 1px ${themeGet("nav.25")};
  }
`;

export const ActiveBox = system({
  is: FullHeightBox,
  bg: "nav.95",
  borderTop: "4px solid",
  borderColor: "nav.55"
}).extend`
  white-space: nowrap;
`;
ActiveBox.displayName = "ActiveBox";

export const InactiveBox = system({
  is: ActiveBox,
  bg: "nav.100",
  borderColor: "nav.100"
});

export const SecondaryNavLink = system({
  is: RouterLink,
  height: "100%"
}).extend`
  &:focus {
    border: 0;
    outline: 0;
  }
`;

export const SecondaryExternalLink = system({
  is: Link,
  height: "100%"
});

export const SecondaryNavDisabledText = system({
  is: Text,
  height: "100%"
}).extend`
  cursor: not-allowed;
`;

export const FocusBlockingFlex = system({
  is: Flex,
  alignItems: "center",
  p: 2,
  height: "100%"
}).extend`
  &:focus {
    outline: 0;
  }
`;

export const FocusBorderSpan = system({
  is: Text,
  p: 1,
  border: "dashed 1px",
  borderColor: "transparent",
  borderRadius: 1
}).extend`
  &:focus {
    outline: 0;
  }
  ${SecondaryNavLink}:focus & {
    border: dashed 1px ${themeGet("nav.25")}
  }
`;
