export const getPublishedTemplateRecord = (store, templateId, location) => {
  const { sort, after, rowsPerPage } = location.query || {};
  const sortVars = {};
  if (sort) {
    sortVars.sort = sort;
  }
  const adoptedStore = store
    .getRoot()
    .getLinkedRecord("adoptedSystemDocumentTemplates", sortVars);
  if (!adoptedStore) {
    return;
  }

  const paginationVars = { first: rowsPerPage || 20, after: null || after };
  return adoptedStore.getLinkedRecord(
    "adoptedSystemDocumentTemplates",
    paginationVars
  );
};
