import { Flex } from "grid-styled";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";

import { ViewContentContainer } from "../document_control/view_policy/ViewPolicy";
import { BottomNav, TopNav } from "../partials/tertiary_nav";
import acceptSystemDocumentTemplate from "../system_document_templates/AcceptSystemDocumentTemplateMutation";

import PolicyProperties from "pstat-anywhere/components/document_control/view_policy/policy_properties/PolicyProperties";
import ShowChanges from "pstat-anywhere/components/document_control/view_policy/share/ShowChanges";
import useShowDocumentChanges from "pstat-anywhere/components/document_control/view_policy/show_changes/hooks/useShowDocumentChanges";
import StatusBar, {
  AllVersionsLink
} from "pstat-anywhere/components/document_control/view_policy/StatusBar";
import TableOfContents, {
  AfterTOCContainer,
  useTableOfContents
} from "pstat-anywhere/components/document_control/view_policy/table_of_contents";
import { ContentContainerFlex } from "pstat-anywhere/components/pages";
import AttachmentsPanel from "pstat-anywhere/components/view/Attachments";
import PolicyContent from "pstat-anywhere/components/view/content/Content";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import { useTenant } from "pstat-anywhere/context_providers/TenantContext";
import { environment } from "pstat-anywhere/relay";
import theme from "pstat-anywhere/themes/policystat/theme";
import { shouldShowOriginationDate } from "pstat-anywhere/utils/document";
import { WarningBanner } from "pstat-design-system/banner";
import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import { Button } from "pstat-design-system/shared/Button";


const TemplateReviewPage = ({ documentReview, isDesktop }) => {
  const { t } = useTranslation();
  const tenant = useTenant();
  const { documentLabel } = useLabels();
  const document = documentReview?.document;
  const showChangesTaskId = documentReview?.showChangesTaskId;
  const showOriginationDate = shouldShowOriginationDate(tenant);
  const [activeTOCHeaderId, setActiveTOCHeaderId] = useState(null);
  const [showChanges, setShowChanges] = useState(false);
  const {
    tocExpandedDesktop,
    tocExpandedMobile,
    toggleTableOfContents,
    closeTableOfContents
  } = useTableOfContents(isDesktop);
  const diff = useRef(null);
  const showChangesTaskSuccessHandler = taskResult => {
    diff.current = taskResult;
  };
  const showChangesTaskResult = useShowDocumentChanges(
    showChangesTaskId,
    showChangesTaskSuccessHandler
  );
  return (
    <Navbar
      document={document}
      showChanges={showChanges}
      showChangesTaskResult={showChangesTaskResult}
      setShowChanges={setShowChanges}
      isDesktop={isDesktop}
      tocExpanded={tocExpandedDesktop}
    >
      <TableOfContents
        countAttachments={document.attachments.length}
        html={document.html}
        expanded={isDesktop ? tocExpandedDesktop : tocExpandedMobile}
        activeItemId={activeTOCHeaderId}
        onToggle={toggleTableOfContents}
        onClose={closeTableOfContents}
      />
      <ViewContentContainer
        isDesktop={isDesktop}
        tocExpandedDesktop={tocExpandedDesktop}
      >
        {showChangesTaskId && (
          <ContentContainerFlex>
            <WarningBanner
              width="100%"
              message={t(
                "systemDocumentTemplates.reviewTemplate.showChangeBanner",
                { documentLabel: documentLabel.toLowerCase() }
              )}
            />
          </ContentContainerFlex>
        )}
        <StatusBar
          document={document}
          canEditDocument={false}
          showPrintAndShare={false}
          showWorkflowName={false}
          displayChangesDiff={false}
          includePastApprovals={false}
        />
        {isDesktop && (
          <PolicyProperties
            approvalDate={document.approvalDate}
            effectiveDate={document.effectiveDate}
            revisionDate={document.revisionDate}
            expirationDate={document.expirationDate}
            originationDate={
              showOriginationDate
                ? document.originationDate
                : document.legacyEffectiveDate
            }
            author={document.author}
            category={document.category}
            bloc={document.bloc}
            tags={document.tags}
            headerImageUrl={document.headerImageUrl}
          />
        )}
        <PolicyContent
          name={document.name}
          html={showChanges && diff ? diff.current : document.html}
          isRestricted={document.restricted}
          tableOfContentsCallback={setActiveTOCHeaderId}
        />
        <AttachmentsPanel attachments={document.attachments} />
      </ViewContentContainer>
    </Navbar>
  );
};

const TemplateReviewPageWrapper = props => (
  <Media query={{ minWidth: theme.breakpoints[1] }}>
    {isDesktop => <TemplateReviewPage {...props} isDesktop={isDesktop} />}
  </Media>
);

export default TemplateReviewPageWrapper;

const Navbar = ({
  document,
  showChanges,
  showChangesTaskResult,
  setShowChanges,
  isDesktop,
  tocExpanded,
  children
}) => {
  const props = {
    document,
    showChanges,
    setShowChanges,
    showChangesTaskResult
  };
  return (
    <Fragment>
      {isDesktop ? (
        <Fragment>
          <DesktopNav {...props} />
          <AfterTOCContainer tocExpanded={tocExpanded}>
            {children}
          </AfterTOCContainer>
        </Fragment>
      ) : (
        <MobileNav {...props}>{children}</MobileNav>
      )}
    </Fragment>
  );
};

const DesktopNav = ({
  document,
  showChanges,
  showChangesTaskResult,
  setShowChanges
}) => (
  <TopNav>
    <Flex alignItems="center">
      <AdoptTemplateButton document={document} />
      {!document.isTemplate && <AllVersionsLink document={document} />}
    </Flex>
    <Flex alignItems="center">
      {!document.isTemplate && (
        <ShowChangesToggle
          showChanges={showChanges}
          setShowChanges={setShowChanges}
          showChangesTaskResult={showChangesTaskResult}
        />
      )}
    </Flex>
  </TopNav>
);

const MobileNav = ({
  document,
  showChanges,
  showChangesTaskResult,
  setShowChanges,
  children
}) => (
  <Fragment>
    <TopNav>
      <Flex alignItems="center">
        <AdoptTemplateButton document={document} />
      </Flex>
    </TopNav>
    {children}
    <BottomNav>
      <Flex alignItems="center">
        {!document.isTemplate && <AllVersionsLink document={document} />}
        {!document.isTemplate && (
          <ShowChangesToggle
            showChanges={showChanges}
            setShowChanges={setShowChanges}
            showChangesTaskResult={showChangesTaskResult}
          />
        )}
      </Flex>
    </BottomNav>
  </Fragment>
);

const AdoptTemplateButton = ({ document }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();
  const labels = useLabels();

  const handleReviewTemplate = (document, { documentLabel, categoryLabel }) => {
    setIsProcessing(true);
    acceptSystemDocumentTemplate(
      environment,
      document.pk,
      (response, errors) => {
        const { ok, redirectPath } = response.acceptSystemDocumentTemplate;
        if (errors) {
          errors.forEach(error => {
            createErrorMessage(error);
          });
        } else {
          if (ok) {
            window.location.href = redirectPath;
          } else {
            const error = response.acceptSystemDocumentTemplate?.error;
            if (error && error?.category) {
              createErrorMessage(
                t("systemDocumentTemplates.reviewTemplate.categoryError", {
                  documentLabel: documentLabel.toLowerCase(),
                  categoryLabel: categoryLabel.toLowerCase()
                }),
                { maxLine: "5" }
              );
            } else {
              createErrorMessage(
                t(
                  "documentControl.view.secondaryNav.cloneDocument.unknownErrorMessage"
                )
              );
            }
          }
        }
        setIsProcessing(false);
      }
    );
  };
  return (
    <Button
      buttonStyle="primary"
      onClick={() => handleReviewTemplate(document, labels)}
      disabled={isProcessing}
      data-testid="accept-template-button"
    >
      {t("systemDocumentTemplates.reviewTemplate.reviewButton")}
    </Button>
  );
};

const ShowChangesToggle = ({
  showChanges,
  showChangesTaskResult,
  setShowChanges
}) => {
  const { t } = useTranslation();
  const { documentLabel } = useLabels();
  return (
    <ShowChanges
      showChanges={showChanges}
      checkingChangesDiff={false}
      title={t("systemDocumentTemplates.reviewTemplate.showChanges", {
        documentLabel: documentLabel.toLowerCase()
      })}
      toggleShowChanges={() => setShowChanges(!showChanges)}
      showChangesTaskResult={showChangesTaskResult}
    />
  );
};
