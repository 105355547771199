import { useTranslation } from "react-i18next";
import styled from "styled-components";
import system from "system-components";

import { withTenantContext } from "../../context_providers/TenantContext";

import { Text } from "pstat-design-system/text";

const SiteLogoImg = styled("img")`
  max-height: 150px;
  max-width: 300px;
`;

const SiteName = system({
  is: Text,
  p: 4,
  border: 1,
  borderColor: "nav.80"
});

const SiteLogo = props => {
  const { t } = useTranslation();
  const altText = t("siteLogo.altText", { tenantName: props.tenant.name });
  if (props.src) {
    return <SiteLogoImg src={props.src} alt={altText} />;
  } else {
    return <SiteName id="header-site-name">{props.tenant.name}</SiteName>;
  }
};

export default withTenantContext(SiteLogo);
