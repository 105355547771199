import { Link as FoundLink } from "found";
import { mayBeStubbed } from "react-stubber";

import { FocusLink, Link } from "./Link";

const RouterLink = props => (
  <FoundLink Component={Link} {...props}>
    {props.children}
  </FoundLink>
);

const StubbedRouterLink = mayBeStubbed(RouterLink);

const RouterFocusLink = props => (
  <FoundLink Component={FocusLink} {...props}>
    {props.children}
  </FoundLink>
);

const StubbedRouterFocusLink = mayBeStubbed(RouterFocusLink);

export {
  StubbedRouterLink as default,
  StubbedRouterLink as RouterLink,
  StubbedRouterFocusLink as RouterFocusLink
};
