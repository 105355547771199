import graphql from "babel-plugin-relay/macro";

const TokenViewPolicyQuery = graphql`
  query TokenViewPolicyQuery($tokenId: UUID!) {
    documentTokenAccessView(tokenId: $tokenId) {
      id
      pk
      name
      html
      author {
        pk
        userDisplay
        firstName
        lastName
        profile {
          title
        }
      }
      category {
        pk
        name
      }
      bloc {
        id
        name
        tenants {
          pk
          name
        }
      }
      parent {
        id
      }
      isAForm
      status
      restricted
      effectiveDate(formatted: true)
      approvalDate(formatted: true)
      expirationDate(formatted: true)
      revisionDate(formatted: true)
      statusChangedDate
      originationDate(formatted: true)
      legacyEffectiveDate(formatted: true)
      created
      modified
      headerImageUrl
      attachments {
        title
        fileUrl
      }
      tags {
        pk
        name
      }
    }
  }
`;

export default TokenViewPolicyQuery;
