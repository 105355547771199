import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import ButtonLink from "pstat-design-system/ButtonLink";
import CheckboxComponent, {
  CheckboxGroup,
  SelectAllCheckbox as SelectAllCheckboxComponent
} from "pstat-design-system/inputs/Checkbox";
import { Button } from "pstat-design-system/shared/Button";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

export const Edit = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <StyledFontAwesomeIcon
        data-tip
        data-for="edit-icon-tooltip"
        data-event={HOVER_EVENTS}
        data-event-off={HOVER_EVENTS_OFF}
        data-delay-hide="2000"
        icon={["fas", "edit"]}
        size="xl"
      />
      <Tooltip name="edit-icon-tooltip">
        {t("documentControl.view.secondaryNav.navOptions.edit.name")}
      </Tooltip>
    </Fragment>
  );
};

export const Checkbox = withLabelContext(
  ({
    pk,
    onActionClick,
    disabledTooltipTextKey,
    selectedCheckboxPks,
    labels
  }) => {
    const { t } = useTranslation();
    const disabledTooltipProps = {
      "data-tip": true,
      "data-for": `checkbox-disabled-tooltip-${pk}`,
      "data-event": HOVER_EVENTS,
      "data-event-off": HOVER_EVENTS_OFF
    };
    let checkboxProps = {
      value: `action-checkbox-${pk}`,
      testId: `action-checkbox-${pk}`,
      labelHeight: "16px",
      // want the html to have disabled be true or false and not the string value of the tooltip key
      disabled: disabledTooltipTextKey ? true : false
    };
    if (disabledTooltipTextKey) {
      checkboxProps = { ...checkboxProps, ...disabledTooltipProps };
    }
    return (
      <CheckboxGroup
        py={0}
        px={0}
        name={`action-${pk}`}
        onChange={() => {
          if (!disabledTooltipTextKey) {
            onActionClick(pk);
          }
        }}
        selectedValues={
          selectedCheckboxPks &&
          selectedCheckboxPks.map(pk => `action-checkbox-${pk}`)
        }
      >
        <CheckboxComponent {...checkboxProps} />
        {disabledTooltipTextKey && (
          <Tooltip name={`checkbox-disabled-tooltip-${pk}`}>
            {t(disabledTooltipTextKey, {
              documentLabelPlural: labels.documentLabelPlural.toLowerCase(),
              documentLabelPluralTitle: titleCase(labels.documentLabelPlural)
            })}
          </Tooltip>
        )}
      </CheckboxGroup>
    );
  }
);

export const SelectAllCheckbox = ({
  selectAllCheckboxState,
  onSelectAllCheckboxClick
}) => (
  <CheckboxGroup
    py={0}
    px={0}
    name="select-all"
    onChange={() => onSelectAllCheckboxClick()}
    selectAllCheckboxState={selectAllCheckboxState}
    selectedValues={[]}
  >
    <SelectAllCheckboxComponent
      id="select-all-checkbox"
      ariaLabel="select all"
      testId="select-all-checkbox"
      labelHeight={"16px"}
    />
  </CheckboxGroup>
);

export const AddAndViewOnesourceButtons = ({ onClickAdd, viewUrl }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <TableActionButton mr={2} onClick={onClickAdd}>
        {t("onesource.edit.searchResults.addButton")}
      </TableActionButton>
      <TableActionButtonLink href={viewUrl} target="_blank">
        {t("onesource.edit.searchResults.viewButton")}
      </TableActionButtonLink>
    </Fragment>
  );
};

export const RemoveAndViewOnesourceButtons = ({ onClickRemove, viewUrl }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <TableActionButton mr={2} onClick={onClickRemove}>
        {t("onesource.edit.documentListRemoveButton")}
      </TableActionButton>
      <TableActionButtonLink href={viewUrl} target="_blank">
        {t("onesource.edit.searchResults.viewButton")}
      </TableActionButtonLink>
    </Fragment>
  );
};

const TableActionButton = ({ onClick, children, ...otherProps }) => (
  <Button
    buttonStyle="tertiary"
    height={36}
    py={2}
    onClick={onClick}
    {...otherProps}
  >
    {children}
  </Button>
);

const TableActionButtonLink = ({ children, ...otherProps }) => (
  <ButtonLink buttonStyle="tertiary" height={36} py={2} {...otherProps}>
    {children}
  </ButtonLink>
);
