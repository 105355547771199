import SideBarMenu from "pstat-design-system/SideBarMenu";

// @flow
const getMenuItems = isOverride => {
  let effectiveDate = [
    {
      translationKey: "documentControl.edit.effectiveDateSection",
      href: "#effective_date"
    }
  ];
  let approvalSimulation = [];
  if (isOverride) {
    effectiveDate = [];
    approvalSimulation = [
      {
        translationKey: "documentControl.override.approvalSimulationSection",
        href: "#approval_simulation"
      }
    ];
  }
  const menuItems = [
    {
      translationKey: "documentControl.edit.propertiesSection",
      href: "#properties"
    },
    {
      translationKey: "documentControl.edit.editorSection",
      href: "#editor"
    },
    {
      translationKey: "documentControl.edit.attachmentsSection",
      href: "#attachments"
    },
    ...effectiveDate,
    ...approvalSimulation,
    {
      translationKey: "documentControl.edit.advancedSection",
      href: "#advanced"
    }
  ];
  return menuItems;
};

const EditSideBar = ({ isOverride, ...props }) => (
  <SideBarMenu menuItems={getMenuItems(isOverride)} {...props} />
);

export default EditSideBar;
