import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import system from "system-components";

import ExternalLink from "pstat-design-system/ExternalLink";
import { HorizontalScrollTable } from "pstat-design-system/tables";
import { H3, Text } from "pstat-design-system/text";

const SearchResults = ({
  results,
  error,
  onAddDocument,
  resultsLink,
  totalCount
}) => {
  const { t } = useTranslation();
  const numResults = results ? results.length : 0;
  return (
    <Container>
      <H3 color="nav.0" fontWeight={400} pb={2}>
        {t("onesource.edit.searchResults.heading", {
          totalCount: totalCount,
          numResults: numResults
        })}
      </H3>
      {error ? (
        <Text color="error.0">{t("onesource.edit.searchResults.error")}</Text>
      ) : (
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <HorizontalScrollTable
            tableType="onesourceSearch"
            policies={results}
            numberToDisplay={numResults}
            onClickAdd={onAddDocument}
            rowKey={({ rowData }) => rowData.documentId}
          />
          {totalCount > numResults && (
            <Box p={3} mb={-5}>
              <ExternalLink target="_blank" to={resultsLink}>
                {t("onesource.edit.searchResults.linkResult", {
                  totalCount: totalCount
                })}
              </ExternalLink>
            </Box>
          )}
        </Flex>
      )}
    </Container>
  );
};

const Container = system({
  bg: "nav.100",
  border: 1,
  borderColor: "nav.80",
  borderRadius: 1,
  px: 6,
  py: 5,
  mt: 6
});

export default SearchResults;
