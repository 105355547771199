import { Box, Flex } from "grid-styled";
import React from "react";
import { withTranslation } from "react-i18next";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";

function _InconsistentExpirationDatesModal({
  actualLifespanDays,
  onClose,
  handleDateChange,
  isModalOpen,
  newLifespanDays,
  t,
  labels: { expirationDateLabel }
}) {
  return (
    <Modal
      id={"inconsistent_expiration_dates_modal"}
      contentLabel={t("documentControl.override.inconsistentDatesModal.header")}
      isOpen={isModalOpen}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-around">
            <Box>
              <Button buttonStyle="tertiary" onClick={onClose}>
                {t(
                  "documentControl.override.inconsistentDatesModal.cancelButton"
                )}
              </Button>
            </Box>
            <Box>
              <Button
                id="confirmation_modal_submit_button"
                buttonStyle="danger"
                onClick={handleDateChange}
                processing={false}
              >
                {t(
                  "documentControl.override.inconsistentDatesModal.updateButton"
                )}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex
        flexDirection="column"
        px={5}
        pt={3}
        pb={5}
        data-testid={"inconsistent_expiration_dates_modal_container"}
      >
        <Text>
          {t(
            "documentControl.override.inconsistentDatesModal.actualLifespan.part1",
            { expirationDateLabel: expirationDateLabel?.toLowerCase() }
          )}{" "}
          <Text
            fontWeight="bold"
            data-testid="actual-expiration-date-days-modal"
          >
            {t(
              "documentControl.override.inconsistentDatesModal.lifespan.bold",
              {
                days: actualLifespanDays
              }
            )}
          </Text>{" "}
          {t(
            "documentControl.override.inconsistentDatesModal.actualLifespan.part2"
          )}
        </Text>
        <Text>
          {t(
            "documentControl.override.inconsistentDatesModal.nextLifespan.part1",
            { expirationDateLabel: expirationDateLabel?.toLowerCase() }
          )}{" "}
          <Text fontWeight="bold" data-testid="actual-lifespan-days-modal">
            {t(
              "documentControl.override.inconsistentDatesModal.lifespan.bold",
              {
                days: newLifespanDays
              }
            )}
          </Text>{" "}
          {t(
            "documentControl.override.inconsistentDatesModal.nextLifespan.part2"
          )}
        </Text>
      </Flex>
    </Modal>
  );
}

export default withLabelContext(
  withTranslation()(_InconsistentExpirationDatesModal)
);
