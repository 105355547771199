import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation ProxyLoginMutation($user: Int!) {
    proxyLogin(user: $user) {
      ok
      reasons
      redirectUrl
    }
  }
`;

export const doProxyLogin = userPk => {
  const variables = {
    user: userPk,
    cameFrom: window.location.pathname
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      const { ok, redirectUrl } = response.proxyLogin;
      if (ok) {
        if (redirectUrl === undefined || redirectUrl === null) {
          window.location.reload(true);
        } else {
          window.location.replace(redirectUrl);
        }
      }
    },
    onError: err => {
      if (err) {
        throw new Error(err);
      }
    }
  });
};
