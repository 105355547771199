import { Flex } from "grid-styled";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import theme from "pstat-anywhere/themes/policystat/theme";
import srOnly from "pstat-anywhere/screenReaderOnlyStyles";

export const PageContainer = styled.div`
  background-color: ${themeGet("colors.nav.95")};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const SkipLink = styled.a.attrs({
  href: "#content",
  role: "link"
})`
  ${srOnly};
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth || theme.breakpoints[3]};
  margin: 0 auto;
`;

export const ContentContainerFlex = system(
  {
    is: Flex,
    width: "100%",
    maxWidth: theme.breakpoints[3],
    m: "0 auto",
    ml: "auto"
  },
  "height"
);

ContentContainerFlex.displayName = "ContentContainerFlex";

export const Content = styled(Flex)`
  flex: 1 0 auto;
  flex-direction: column;
  position: relative;
`;
