import { ChasingDots, Circle } from "better-react-spinkit";
import styled from "styled-components";
import { themeGet } from "styled-system";

import colors from "../../themes/policystat/colors";

// TODO: change size prop to be something that gives more context, such as sm or lg

const LoadingSpinnerContainer = styled("div")`
  display: inline-block;
  margin-left: ${themeGet("space.1")}px;
  margin-right: ${themeGet("space.1")}px;
`;

const spinnerTypes = {
  "chasing-dots": ChasingDots,
  circle: Circle
};

const LoadingSpinner = ({
  colorOne,
  colorTwo,
  size,
  type = "chasing-dots"
}) => {
  const SpinnerType = spinnerTypes[type] || ChasingDots;
  return (
    <LoadingSpinnerContainer className="loading-spinner">
      <SpinnerType
        display="inline"
        color={colorOne || colors["secondary"][0]}
        colorTwo={colorTwo || colors["primary"][0]}
        size={size}
      />
    </LoadingSpinnerContainer>
  );
};

export default LoadingSpinner;
