import { useRef, useState } from "react";

import customFetch from "pstat-anywhere/utils/customFetch";

const useNotificationText = (
  usersAlreadyToBeNotified,
  usersOptingOutOfBeingNotified,
  selectedUserMentions,
  selectedGroupMentions
) => {
  const groupUserCache = useRef({});
  const [selectedUsersForGroup, setSelectedUsersForGroup] = useState({});

  async function fetchGroupUsers(group) {
    const cachedGroupUsers = groupUserCache.current[group.pk];
    if (cachedGroupUsers) {
      setSelectedUsersForGroup({
        ...selectedUsersForGroup,
        [group.pk]: cachedGroupUsers
      });
      return;
    }

    const groupsUrl = group.users_uri;
    let groupUsers = await customFetch(groupsUrl)
      .then(response => response.json())
      .then(json => json.objects);
    groupUsers = addPksToUsers(groupUsers);
    groupUserCache.current[group.pk] = groupUsers;
    setSelectedUsersForGroup({
      ...selectedUsersForGroup,
      [group.pk]: groupUsers
    });
  }

  const deduplicateUsers = users => {
    const usersByPk = {};
    for (let i = 0; i < users.length; i++) {
      usersByPk[users[i].pk] = users[i];
    }
    const userPks = Object.keys(usersByPk);
    return userPks.map(userPk => usersByPk[userPk]);
  };

  const addPksToUsers = users => {
    const usersWithPks = users.map(user => {
      if (!user.pk) {
        let userId = user.id;
        if (user.type) {
          userId = parseInt(user.id.split("_")[0]);
        }
        user.pk = userId;
      }
      return user;
    });
    return usersWithPks;
  };

  let usersToNotifyText = null;
  let usersOptedOutText = null;
  if (usersAlreadyToBeNotified) {
    const usersFromGroups = selectedGroupMentions
      .map(group => selectedUsersForGroup[group.pk])
      .filter(users => users)
      .reduce(
        (aggregatedGroupUsers, groupUsers) =>
          aggregatedGroupUsers.concat(groupUsers),
        []
      );
    const deduplicatedUsersToBeNotified = deduplicateUsers([
      ...usersAlreadyToBeNotified,
      ...selectedUserMentions,
      ...usersFromGroups
    ]);
    const filteredUsersToBeNotified = deduplicatedUsersToBeNotified.filter(
      user =>
        !usersOptingOutOfBeingNotified.some(
          userOptingOut => userOptingOut.pk === user.pk
        )
    );

    usersToNotifyText = buildListOfUsers(filteredUsersToBeNotified);
    usersOptedOutText = buildListOfUsers(usersOptingOutOfBeingNotified);
  }

  return {
    usersToNotifyText,
    usersOptedOutText,
    fetchGroupUsers
  };
};

const getUserName = user => {
  const firstName = user.firstName || user.first_name;
  const lastName = user.lastName || user.last_name;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else {
    return lastName;
  }
};

const buildListOfUsers = users => {
  if (users.length === 0) {
    return "";
  }
  if (users.length === 1) {
    return getUserName(users[0]);
  }
  if (users.length === 2) {
    return `${getUserName(users[0])} and ${getUserName(users[1])}`;
  }
  const firstUsers = users.slice(0, -1);
  const lastUser = users[users.length - 1];
  let usersToNotifyText = firstUsers.map(user => getUserName(user)).join(", ");
  usersToNotifyText += `, and ${getUserName(lastUser)}`;
  return usersToNotifyText;
};

export default useNotificationText;
