import graphql from "babel-plugin-relay/macro";

export const SearchResultsQuery = graphql`
  query SearchResultsQuery(
    $after: String
    $authors: [Int]
    $before: String
    $categories: [Int]
    $first: Int
    $last: Int
    $q: String
    $references: [Int]
    $sort: String
    $titleOnly: Boolean
  ) {
    search(
      authors: $authors
      categories: $categories
      q: $q
      references: $references
      sort: $sort
      titleOnly: $titleOnly
    ) {
      searchResults(
        after: $after
        before: $before
        first: $first
        last: $last
      ) {
        totalCount
        edges {
          node {
            id
            pk
            name
            preview
            hasAttachments
            bloc {
              name
              pk
            }
            applicabilityType
            category {
              name
            }
            effectiveDate
            legacyEffectiveDate
            revisionDate
            approvalDate
            restricted
            documentsOfTemplate {
              documents {
                pk
                name
                preview
                hasAttachments
                bloc {
                  name
                  pk
                }
                applicabilityType
                category {
                  name
                }
                effectiveDate
                legacyEffectiveDate
                revisionDate
                approvalDate
                restricted
              }
            }
          }
        }
      }
      guestAccessToken
    }
  }
`;

export default SearchResultsQuery;
