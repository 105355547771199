/**
 * @flow
 * @relayHash 8ea0f560d060f7ff1bed4a0d9d1effd1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type HomeContentQueriesMyDocumentsToAcknowledgeQueryVariables = {||};
export type HomeContentQueriesMyDocumentsToAcknowledgeQueryResponse = {|
  +myDocumentsToAcknowledge: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +totalAcknowledged: ?number,
        +totalAssigned: ?number,
        +line: ?{|
          +pk: ?number,
          +document: ?{|
            +pk: ?number,
            +name: string,
            +hasAttachments: ?boolean,
            +effectiveDate: ?string,
            +legacyEffectiveDate: ?string,
            +restricted: boolean,
            +category: {|
              +name: string
            |},
            +bloc: {|
              +name: string
            |},
            +applicabilityType: ?ApplicabilityType,
          |},
        |},
      |}
    |}>,
  |}
|};
export type HomeContentQueriesMyDocumentsToAcknowledgeQuery = {|
  variables: HomeContentQueriesMyDocumentsToAcknowledgeQueryVariables,
  response: HomeContentQueriesMyDocumentsToAcknowledgeQueryResponse,
|};
*/


/*
query HomeContentQueriesMyDocumentsToAcknowledgeQuery {
  myDocumentsToAcknowledge(first: 5) {
    totalCount
    edges {
      node {
        totalAcknowledged
        totalAssigned
        line {
          pk
          document {
            pk
            name
            hasAttachments
            effectiveDate
            legacyEffectiveDate
            restricted
            category {
              name
              id
            }
            bloc {
              name
              id
            }
            applicabilityType
            id
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5,
    "type": "Int"
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAcknowledged",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalAssigned",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v10 = [
  v5
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v13 = [
  v5,
  v12
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "HomeContentQueriesMyDocumentsToAcknowledgeQuery",
  "id": "1e6a7c18ad25abeb40d38132f7bd3f18",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "HomeContentQueriesMyDocumentsToAcknowledgeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myDocumentsToAcknowledge",
        "storageKey": "myDocumentsToAcknowledge(first:5)",
        "args": v0,
        "concreteType": "CountableConnectionAcknowledgmentConnection",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentDocumentLineType",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentLineType",
                    "plural": false,
                    "selections": [
                      v4,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v6,
                          v7,
                          v8,
                          v9,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryType",
                            "plural": false,
                            "selections": v10
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": v10
                          },
                          v11
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "HomeContentQueriesMyDocumentsToAcknowledgeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myDocumentsToAcknowledge",
        "storageKey": "myDocumentsToAcknowledge(first:5)",
        "args": v0,
        "concreteType": "CountableConnectionAcknowledgmentConnection",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "CountableConnectionAcknowledgmentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "AcknowledgmentDocumentLineType",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "line",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentLineType",
                    "plural": false,
                    "selections": [
                      v4,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4,
                          v5,
                          v6,
                          v7,
                          v8,
                          v9,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CategoryType",
                            "plural": false,
                            "selections": v13
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "bloc",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BlocType",
                            "plural": false,
                            "selections": v13
                          },
                          v11,
                          v12
                        ]
                      },
                      v12
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query HomeContentQueriesMyDocumentsToAcknowledgeQuery {\n  myDocumentsToAcknowledge(first: 5) {\n    totalCount\n    edges {\n      node {\n        totalAcknowledged\n        totalAssigned\n        line {\n          pk\n          document {\n            pk\n            name\n            hasAttachments\n            effectiveDate\n            legacyEffectiveDate\n            restricted\n            category {\n              name\n              id\n            }\n            bloc {\n              name\n              id\n            }\n            applicabilityType\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = '26093366aee7bf1c31f4fa59f8281f7d';
module.exports = node;
