import graphql from "babel-plugin-relay/macro";

const DuplicateDocumentsCSVQuery = graphql`
  query DuplicateDocumentsCSVQuery {
    duplicateDocumentsCsv {
      taskId
    }
  }
`;

export default DuplicateDocumentsCSVQuery;
