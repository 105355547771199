import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { withViewer } from "../../context_providers/ViewerContext";

import { doProxyLogin } from "./ProxyLoginMutation";

import MenuButton from "pstat-anywhere/components/design-system/shared/MenuButton";

const ProxyLoginMenuLink = props => {
  const [expanded, setExpanded] = useState(false);
  const { usersCanProxy } = props;
  const bg = "nav.95";
  const { t } = useTranslation();
  return (
    <Fragment>
      <MenuButton
        color="nav.0"
        bg={bg}
        px={2}
        py={1}
        display="inline-flex"
        width="100%"
        onClick={() => setExpanded(!expanded)}
        onKeyDown={event => {
          if (event.key === "Enter") {
            setExpanded(!expanded);
            event.preventDefault();
          }
        }}
      >
        {t("nav.primary.myAccount.proxyLogin")}
      </MenuButton>
      {expanded && (
        <Fragment>
          {usersCanProxy.map((user, index) => {
            return (
              <MenuButton
                color="nav.0"
                px={2}
                py={2}
                key={index}
                bg={bg}
                onClick={() => doProxyLogin(user.pk)}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    doProxyLogin(user.pk);
                    event.preventDefault();
                  }
                }}
              >
                {user.userDisplay}
              </MenuButton>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
};

export default withViewer(ProxyLoginMenuLink);
