import { Box, Flex } from "grid-styled";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";

import { FocusBorder } from "pstat-anywhere/components/document_search/SearchFilters/components";
import epStandardContentChangesMutation from "pstat-anywhere/components/integrations/verge/EpStandardContentChangesMutation";
import { Dropdown } from "pstat-anywhere/components/partials/DropdownMenu";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { DropdownItem } from "pstat-anywhere/components/partials/tertiary_nav";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import { ErrorBanner, WarningBanner } from "pstat-design-system/banner";
import ExternalLink from "pstat-design-system/ExternalLink";
import { FocusLink } from "pstat-design-system/Link";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import MenuButton from "pstat-design-system/shared/MenuButton";
import { H2, Text } from "pstat-design-system/text";
import { layer } from "pstat-design-system/utils";

const EpViewMenuPositionner = system({
  bg: "nav.95",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "fixed",
  width: "30%",
  top: 0,
  bottom: 0,
  right: 0,
  layer: "activeFloatingUi"
}).extend`
  ${layer};
  box-shadow: -2px 2px 0px 0px ${themeGet("colors.nav.90")};
  overflow: auto;
  min-height: 100%;
`;

export const OverflowFlex = system({
  is: Flex,
  bg: "nav.100",
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1
}).extend`
  overflow: auto;
`;

const ControlsFlex = system({
  is: Flex,
  bg: "nav.100",
  lineHeight: "54px",
  borderBottom: "1px solid",
  borderTop: "1px solid",
  borderColor: "nav.90"
});

const ButtonBox = system({
  is: Box,
  width: "50%",
  textAlign: "center",
  borderRight: "1px solid",
  borderColor: "nav.90"
}).extend`
 &:hover {
   cursor: pointer;
   background-color: ${themeGet("colors.nav.95")};
 }
`;

const TitleFlex = system({
  is: Flex,
  lineHeight: "52px",
  width: "100%",
  borderBottom: "1px solid",
  borderColor: "nav.90"
});

const TitleBox = system({
  is: Box,
  textAlign: "center",
  alignContent: "center",
  width: "100%",
  display: "inline-flex",
  p: 2
});

const CloseMenuBox = system({
  is: Box,
  width: "60px",
  textAlign: "center",
  borderRight: "1px solid",
  borderColor: "nav.90"
}).extend`
 &:hover {
   cursor: pointer;
   background-color: ${themeGet("colors.nav.95")};
 }
`;

const PropertyLine = system({
  is: Flex,
  lineHeight: "22px",
  flexDirection: "row",
  width: "50%",
  mb: 2
});

const PrintButton = ({ url }) => {
  const { t } = useTranslation();

  const handlePrint = useCallback(() => {
    if (url) {
      window.open(url);
    }
  }, [url]);

  return (
    <ButtonBox className="print-button">
      <MenuButton onClick={handlePrint}>
        {t("documentControl.view.epContenMenu.print")}
      </MenuButton>
    </ButtonBox>
  );
};

const VersionsButton = ({
  hasLatestVersion,
  showAssociatedVersion,
  showLatestVersion
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  return (
    <ButtonBox className="versions-button">
      <MenuButton>
        <Dropdown
          dropdownSelector={() => (
            <p onClick={() => setExpanded(true)}>
              {t("documentControl.view.epContenMenu.versions")}
              <StyledFontAwesomeIcon icon={["fa", "caret-down"]} size="1x" />
            </p>
          )}
          expanded={expanded}
          rtl={true}
          toggle={() => setExpanded(!expanded)}
          desktop={true} //looks like the flyout is not reponsive right now
          closeMenu={() => setExpanded(false)}
          up={false}
        >
          {hasLatestVersion ? (
            <Fragment>
              <DropdownItem onClick={showLatestVersion}>
                {t("documentControl.view.epContenMenu.latestVersion")}
              </DropdownItem>
              <DropdownItem onClick={showAssociatedVersion}>
                {t("documentControl.view.epContenMenu.associatedVersion")}
              </DropdownItem>
            </Fragment>
          ) : (
            <DropdownItem>
              {t("documentControl.view.epContenMenu.onlyVersion")}
            </DropdownItem>
          )}
        </Dropdown>
      </MenuButton>
    </ButtonBox>
  );
};

const ShowChangesButton = ({ showChanges, onClickShowChanges }) => {
  const { t } = useTranslation();
  return (
    <ButtonBox className="show-changes-button">
      <MenuButton onClick={onClickShowChanges}>
        {showChanges
          ? t("documentControl.view.epContenMenu.hideChanges")
          : t("documentControl.view.epContenMenu.showChanges")}
      </MenuButton>
    </ButtonBox>
  );
};

const MenuTitle = ({
  isLoading,
  isError,
  onClose,
  title,
  printUrl,
  hasLatestVersion,
  handlerShowChanges,
  showChanges,
  showAssociatedVersion,
  showLatestVersion
}) => (
  <Flex flexDirection="column">
    <TitleFlex bg="nav.100" alignItems="center">
      <CloseMenuBox
        tabIndex="0"
        onClick={onClose}
        className="standard-close-button"
      >
        <FocusBorder tabIndex="-1">
          <StyledFontAwesomeIcon icon={["far", "times"]} />
        </FocusBorder>
      </CloseMenuBox>
      <TitleBox>
        <H2 className="standard-name">{title}</H2>
      </TitleBox>
    </TitleFlex>
    {!isLoading && !isError && (
      <ControlsFlex justify="space-between" align="center" height={20}>
        <PrintButton url={printUrl} />
        <VersionsButton
          hasLatestVersion={hasLatestVersion}
          showAssociatedVersion={showAssociatedVersion}
          showLatestVersion={showLatestVersion}
        />
        {hasLatestVersion && (
          <ShowChangesButton
            showChanges={showChanges}
            onClickShowChanges={handlerShowChanges}
          />
        )}
      </ControlsFlex>
    )}
  </Flex>
);

const MenuFooter = ({ url }) => {
  const { t } = useTranslation();
  return (
    <Flex p={2} bg="nav.100" justifyContent="center" className="standard-url">
      <ExternalLink target="_blank" to={url}>
        {t("documentControl.view.epContenMenu.viewInVerge")}
      </ExternalLink>
    </Flex>
  );
};

const ItemProperty = ({ label, value, className }) => (
  <PropertyLine className={className}>
    <Text color="nav.25" mr={2}>
      {label}
    </Text>
    <Text color="nav.50"> {value} </Text>
  </PropertyLine>
);

const MenuProperties = ({ standard, body, chapter }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column">
      <ItemProperty
        className="standard-standard"
        label={t("documentControl.view.epContenMenu.standard")}
        value={standard}
      />
      <ItemProperty
        className="standard-body"
        label={t("documentControl.view.epContenMenu.body")}
        value={body}
      />
      <ItemProperty
        className="standard-chapter"
        label={t("documentControl.view.epContenMenu.chapter")}
        value={chapter}
      />
    </Flex>
  );
};

const MenuContent = ({ content, isVisibleChanges, taskLoading, taskError }) => {
  const { t } = useTranslation();
  if (isVisibleChanges) {
    if (taskLoading) {
      return (
        <Flex justify="center" align="center">
          <Box>
            <LoadingSpinner size={50} />
          </Box>
        </Flex>
      );
    }

    if (taskError) {
      return (
        <ErrorBanner
          width={"100%"}
          message={t(
            "documentControl.view.vergeStandards.content.errorMessage"
          )}
        />
      );
    }
  }

  return (
    <Flex pt={5} className="standard-description">
      <Box width="100%">
        {isVisibleChanges ? (
          <Text dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <Text>{content}</Text>
        )}
      </Box>
    </Flex>
  );
};

const useShowChanges = (associationPk, fromVersion, toVersion) => {
  const [taskId, setTaskId] = useState(null);
  const { error, loading, result } = useTaskResult(taskId);
  const [versionChanges, setVersionChanges] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (!loading) {
      if (error) {
        setErrorMsg(error);
      } else if (result) {
        const { changes_html, error: msg } = result;
        setVersionChanges(changes_html);
        setErrorMsg(msg);
      }
    }
  }, [loading, error, result]);

  const mutationCallback = data => {
    const { taskId, ok, error } = data.documentLineEpChanges || {};
    if (ok) {
      setTaskId(taskId);
    } else {
      setErrorMsg(error.message);
    }
  };

  const getChanges = () => {
    if (!versionChanges || errorMsg) {
      epStandardContentChangesMutation(
        associationPk,
        fromVersion,
        toVersion,
        mutationCallback
      );
    }
  };

  return {
    errorMsg,
    getChanges,
    loading,
    versionChanges
  };
};

const useContent = (content, isLatestVersion) => {
  const { associatedVersion, validFromVersion: toVersion } = content;

  const { validFromVersion: fromVersion } = associatedVersion || {};
  const basedContent = isLatestVersion ? content : associatedVersion;

  return {
    ...basedContent,
    fromVersion,
    toVersion,
    hasLatestVersion: !!associatedVersion
  };
};

export const EpAssociationViewMenu = ({
  onClose,
  content,
  isLoading = false
}) => {
  const { t } = useTranslation();
  const [isVisibleChanges, setIsVisibleChanges] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(true);

  const {
    name,
    chapter,
    standard,
    body,
    description,
    url,
    printUrl,
    associationPk,
    fromVersion,
    toVersion,
    hasLatestVersion
  } = useContent(content || {}, isLatestVersion);
  const {
    errorMsg: taskError,
    loading: taskLoading,
    getChanges,
    versionChanges
  } = useShowChanges(associationPk, fromVersion, toVersion);

  useEffect(() => {
    hideChanges();
  }, [isLatestVersion]);

  const hideChanges = () => setIsVisibleChanges(false);
  const showChanges = () => setIsVisibleChanges(true);
  const showLatestVersion = () => setIsLatestVersion(true);
  const showAssociatedVersion = () => setIsLatestVersion(false);
  const handlerShowChanges = () => {
    if (isVisibleChanges) {
      hideChanges();
    } else {
      getChanges();
      showChanges();
    }
  };

  return (
    <EpViewMenuPositionner id="standard-side-panel">
      <OverflowFlex>
        <Box>
          <MenuTitle
            isLoading={isLoading}
            isError={!content}
            onClose={onClose}
            title={name}
            hasLatestVersion={hasLatestVersion}
            showChanges={isVisibleChanges}
            handlerShowChanges={handlerShowChanges}
            printUrl={printUrl}
            showLatestVersion={showLatestVersion}
            showAssociatedVersion={showAssociatedVersion}
          />
          {!content ? (
            <ErrorBanner
              width={1}
              message={t(
                "documentControl.view.vergeStandards.content.errorMessage"
              )}
            />
          ) : (
            <Fragment>
              {isLoading ? (
                <Flex justifyContent="center" p={4}>
                  <LoadingSpinner size={50} />
                </Flex>
              ) : (
                <Box p={5}>
                  {hasLatestVersion && isLatestVersion && (
                    <WarningBanner width={1}>
                      <Text color="nav.0">
                        {t(
                          "documentControl.view.vergeStandards.content.associatedVersion"
                        )}
                        <FocusLink onActivation={showAssociatedVersion}>
                          {t(
                            "documentControl.view.vergeStandards.content.associatedVersion.link"
                          )}
                        </FocusLink>
                      </Text>
                    </WarningBanner>
                  )}
                  <MenuProperties
                    chapter={chapter}
                    body={body}
                    standard={standard}
                  />
                  <MenuContent
                    isVisibleChanges={isVisibleChanges}
                    taskLoading={taskLoading}
                    content={isVisibleChanges ? versionChanges : description}
                    taskError={taskError}
                  />
                </Box>
              )}
            </Fragment>
          )}
        </Box>
        <Box>
          <MenuFooter url={url} />
        </Box>
      </OverflowFlex>
    </EpViewMenuPositionner>
  );
};
