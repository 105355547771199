/**
 * @flow
 * @relayHash d22c2fc7333550a8678b92d468d29322
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptSystemDocumentTemplateInput = {
  document: number,
  clientMutationId?: ?string,
};
export type AcceptSystemDocumentTemplateMutationVariables = {|
  input: AcceptSystemDocumentTemplateInput
|};
export type AcceptSystemDocumentTemplateMutationResponse = {|
  +acceptSystemDocumentTemplate: ?{|
    +ok: ?boolean,
    +redirectPath: ?string,
    +error: ?any,
  |}
|};
export type AcceptSystemDocumentTemplateMutation = {|
  variables: AcceptSystemDocumentTemplateMutationVariables,
  response: AcceptSystemDocumentTemplateMutationResponse,
|};
*/


/*
mutation AcceptSystemDocumentTemplateMutation(
  $input: AcceptSystemDocumentTemplateInput!
) {
  acceptSystemDocumentTemplate(input: $input) {
    ok
    redirectPath
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AcceptSystemDocumentTemplateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "acceptSystemDocumentTemplate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AcceptSystemDocumentTemplateInput!"
      }
    ],
    "concreteType": "AcceptSystemDocumentTemplatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectPath",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "error",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "AcceptSystemDocumentTemplateMutation",
  "id": "274fe7003e460634f3ba01a1088077d0",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AcceptSystemDocumentTemplateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "AcceptSystemDocumentTemplateMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation AcceptSystemDocumentTemplateMutation(\n  $input: AcceptSystemDocumentTemplateInput!\n) {\n  acceptSystemDocumentTemplate(input: $input) {\n    ok\n    redirectPath\n    error\n  }\n}\n";
(node/*: any*/).hash = 'a930bfa9ae997d6a96e3ed90775eb923';
module.exports = node;
