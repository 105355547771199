import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation AssignAcknowledgmentMutation(
    $docIds: [Int!]
    $userIds: [Int!]
    $groupIds: [Int!]
    $includeRecurring: Boolean
    $selectAll: Boolean
  ) {
    createCertification(
      docIds: $docIds
      userIds: $userIds
      groupIds: $groupIds
      includeRecurring: $includeRecurring
      selectAll: $selectAll
    ) {
      ok
      reasons
    }
  }
`;

export function doAssignAcknowledgment({
  docIds,
  userIds,
  groupIds,
  includeRecurring,
  selectAll,
  callback
}) {
  const variables = {
    docIds,
    userIds,
    groupIds,
    includeRecurring,
    selectAll
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    }
  });
}
