import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import { CopyLinkIconWithRouter } from "../../../view/content/ContentParser";

import actionStrings from "./actionStrings";

import ViewActionApprovalWorkflow from "pstat-anywhere/components/document_control/view_policy/history/ViewActionApprovalWorkflow";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { validateAndFormatDate } from "pstat-anywhere/utils/dateHelpers";
import { FocusLink } from "pstat-design-system/Link";
import { Text } from "pstat-design-system/text";



const INVALID_ACTION_KEY = "documentControl.view.headline.invalidAction";

// TODO: Remove onActivation prop from this component below in render,
// when we would implement needed component to display
const StyledApprovalWorkflowLink = system({
  is: FocusLink,
  ml: 1
}).extend`
  text-decoration: underline;
  line-height: 22px;
`;

export const getActionString = (actionType, t, labels) => {
  return actionStrings(t, labels)[actionType] || t(INVALID_ACTION_KEY);
};

const ViewApprovalWorflowControl = ({ actionId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const onClick = event => {
    setIsOpen(true);
    event.preventDefault();
  };

  const onCloseModal = () => setIsOpen(false);

  return (
    <Fragment>
      <StyledApprovalWorkflowLink
        onActivation={onClick}
        className="view-approval-workflow-by-action-link"
      >
        {t("policyProperties.approvalWorkflow")}
      </StyledApprovalWorkflowLink>
      <ViewActionApprovalWorkflow
        actionId={actionId}
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      />
    </Fragment>
  );
};

const Headline = ({
  user: { userDisplay },
  actionType,
  actionDate,
  id,
  pk,
  isApproval,
  labels
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const translatedActionString = getActionString(actionType, t, labels);

  const formattedActionDate = useMemo(
    () =>
      validateAndFormatDate({
        date: actionDate,
        formatString: "monthName-day-year-time-tz"
      }),
    [actionDate]
  );

  const ariaLabel = `${t(
    "documentControl.view.headline.actionStringAndUserDisplay",
    {
      actionString: translatedActionString,
      userDisplay
    }
  )} ${t("documentControl.view.headline.date", {
    date: formattedActionDate
  })}`;
  return (
    <Text
      color="nav.25"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label={ariaLabel}
    >
      <Text fontWeight="bold" color="nav.25" id={`history-card-headline-${id}`}>
        {t("documentControl.view.headline.actionStringAndUserDisplay", {
          actionString: translatedActionString,
          userDisplay
        })}
      </Text>
      {t("documentControl.view.headline.date", {
        date: formattedActionDate
      })}
      {isApproval && <ViewApprovalWorflowControl actionId={pk} />}
      <CopyLinkIconWithRouter
        headingId={`history-card-headline-${id}`}
        isHovered={isHovered}
      />
    </Text>
  );
};

export default withLabelContext(Headline);
