import { ToastContainer } from "react-toastify";
import styled, { ThemeProvider } from "styled-components";

import theme from "pstat-anywhere/themes/policystat/theme";

const MAX_TOASTS_ON_SCREEN = 3;
export const TOAST_CONTAINER_ID = "toast-container";

const StyledToastContainer = styled(ToastContainer)`
  bottom: 0;
  padding: 0;

  & .toast {
    background: transparent;
    box-shadow: none;
    color: inherit;
    padding: 0;
  }
`;

export default function MessagesContainer() {
  return (
    <ThemeProvider theme={theme}>
      <StyledToastContainer
        containerId={TOAST_CONTAINER_ID}
        position="bottom-right"
        autoClose={5000}
        closeButton={false}
        hideProgressBar={true}
        newestOnTop={true}
        draggable
        className={"container"}
        toastClassName={"toast"}
        limit={MAX_TOASTS_ON_SCREEN}
      />
    </ThemeProvider>
  );
}
