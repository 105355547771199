import { Box } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { ContentContainerFlex } from "../../pages";
import { NotificationSettingsQuery } from "../NoticeSettingsQuery";

import useManageNotifications from "./hooks";

import { TopNav } from "pstat-anywhere/components/partials/tertiary_nav";
import { PermissionDenied403Error } from "pstat-anywhere/errors";
import Card from "pstat-design-system/Card";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { Button } from "pstat-design-system/shared/Button";
import Divider from "pstat-design-system/shared/Divider";
import { Text } from "pstat-design-system/text/paragraph";



const SaveButtonContainer = styled.div`
  background-color: white;
  width: 100%;
  padding-left: ${themeGet("space.7")}px;
  padding-right: ${themeGet("space.4")}px;
  padding-top: ${themeGet("space.1")}px;
  padding-bottom: ${themeGet("space.1")}px;
  position: absolute;
  left: 0;
  z-index: ${themeGet("layers.activeFloatingUi")};
`;

export default function ManageNotifications({ environment, sidebar }) {
  const { t } = useTranslation();
  const {
    handleFieldChange,
    mapSettingsToSections,
    handleDataLoaded,
    handleSettingsUpdate
  } = useManageNotifications();
  return (
    <Fragment>
      <TopNav>
        <SaveButtonContainer>
          <Button buttonStyle="primary" onClick={handleSettingsUpdate}>
            {t("buttons.save")}
          </Button>
        </SaveButtonContainer>
      </TopNav>
      <ContentContainerFlex wrap>
        {sidebar}
        <Box width={[1, 1, 5 / 6]} p={[0, 0, 3]}>
          <Card>
            <QueryRenderer
              environment={environment}
              query={NotificationSettingsQuery}
              variables={{}}
              render={data => {
                const queryProps = data.props;
                if (!queryProps) {
                  return <LoadingSpinner size={100} />;
                }
                const { settings, error } = queryProps.noticeSettings;
                if (error) {
                  if (error.statusCode === 403) {
                    throw new PermissionDenied403Error(error.message);
                  }
                  throw new Error(`${error.statusCode}: ${error.message}`);
                }

                handleDataLoaded(settings);
                const sections = mapSettingsToSections();

                return sections.map(section => (
                  <section key={section.name}>
                    <Text>{t("notifications.manage.emailMe")}</Text>
                    <Text fontWeight="bold">{section.name} </Text>
                    <Text>{t(`notifications.manage.when`)}</Text>
                    {section.fields.map(field => (
                      <CheckboxGroup
                        pt={2}
                        pb={1}
                        px={0}
                        name={field.pk}
                        onChange={handleFieldChange}
                        selectedValues={field.send ? [`${field.pk}`] : []}
                      >
                        <Checkbox
                          value={`${field.pk}`}
                          renderLabel={() => (
                            <Text>
                              {field.description.charAt(0).toUpperCase() +
                                field.description.slice(1)}
                            </Text>
                          )}
                        />
                      </CheckboxGroup>
                    ))}
                    <Divider />
                  </section>
                ));
              }}
            />
          </Card>
        </Box>
      </ContentContainerFlex>
    </Fragment>
  );
}
