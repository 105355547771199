import { createContext, forwardRef } from "react";

export const TenantSamlContext = createContext({});

export const withTenantSamlContext = Component =>
  forwardRef((props, ref) => (
    <TenantSamlContext.Consumer>
      {samlSettings => {
        return <Component {...props} samlSettings={samlSettings} ref={ref} />;
      }}
    </TenantSamlContext.Consumer>
  ));
TenantSamlContext.displayName = "TenantSamlContext";
