import { Flex } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import {
  ErrorIcon,
  InfoIcon,
  MaintenanceBannerIcon,
  SuccessIcon,
  TrainingBannerIcon,
  WarningIcon
} from "./BannerIcons";

import { Text } from "pstat-design-system/text";
import { Link } from "pstat-design-system/Link";
import { getRootUrl } from "pstat-anywhere/utils/urls";

type BannerProps = {
  type: "error" | "info" | "success" | "warning",
  message: string
};
type SpecificBannerProps = {
  message: string
};

const ICONS_MAPPING = {
  error: ErrorIcon,
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  maintenance: MaintenanceBannerIcon,
  training: TrainingBannerIcon
};

const BannerContainer = system({
  is: "section",
  width: 385,
  p: 4,
  mt: 1,
  mb: 6,
  mx: 0,
  display: "flex"
}).extend`
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-bottom-left-radius: ${themeGet("radii.1")}px;
  border-bottom-right-radius: ${themeGet("radii.1")}px;
  border-top: 3px solid
    ${({ type }) => {
      if (type === "error") {
        return themeGet("colors.banners.error.dark");
      } else if (type === "info") {
        return themeGet("colors.banners.info.dark");
      } else if (type === "warning") {
        return themeGet("colors.banners.warning.dark");
      } else {
        return themeGet("colors.banners.success.dark");
      }
    }};
  background-color: ${({ type }) => {
    if (type === "error") {
      return themeGet("colors.banners.error.light");
    } else if (type === "info") {
      return themeGet("colors.banners.info.light");
    } else if (type === "warning") {
      return themeGet("colors.banners.warning.light");
    } else {
      return themeGet("colors.banners.success.light");
    }
  }};
`;

export default function Banner({
  type,
  message,
  children,
  ...otherProps
}: BannerProps) {
  const IconComponent = ICONS_MAPPING[type];
  return (
    <BannerContainer type={type} {...otherProps}>
      <IconComponent />
      {children ? (
        <Fragment>{children}</Fragment>
      ) : (
        <Flex alignItems="center">
          <Text color="nav.0">{message}</Text>
        </Flex>
      )}
    </BannerContainer>
  );
}

export function ErrorBanner({ message, ...props }: SpecificBannerProps) {
  return <Banner message={message} {...props} type="error" />;
}

export function SuccessBanner({ message, ...props }: SpecificBannerProps) {
  return <Banner message={message} {...props} type="success" />;
}

export function WarningBanner({ message, ...props }: SpecificBannerProps) {
  return <Banner message={message} {...props} type="warning" />;
}

export function InfoBanner({ message, ...props }: SpecificBannerProps) {
  return <Banner message={message} {...props} type="info" />;
}

const MaintenanceBannerContainer = styled("section")`
  width: ${props => props.width || "100%"};
  padding: 12px;
  display: flex;
  min-height: 46px;
  background-color: ${props =>
    props.backgroundColor || themeGet("colors.banners.maintenance.dark")};
`;

const WhiteText = props => (
  <Text color="nav.100" {...props}>
    {props.children}
  </Text>
);
export function PreMaintenanceBanner({
  maintenanceWindow,
  ...props
}: SpecificBannerProps) {
  const IconComponent = ICONS_MAPPING["maintenance"];
  const { t } = useTranslation();
  return (
    <MaintenanceBannerContainer id="upcoming_maintenance_banner" {...props}>
      <IconComponent />
      <WhiteText>
        <span>
          {t("maintenance.upcoming.text1")} {maintenanceWindow + "."}{" "}
          {t("maintenance.upcoming.text2")}{" "}
        </span>
        <Link
          target="_blank"
          href={`${getRootUrl()}/whats_new/update_process/`}
          color="nav.100"
          light
          textDecoration="underline"
        >
          {t("maintenance.upcoming.linkText")}
        </Link>
      </WhiteText>
    </MaintenanceBannerContainer>
  );
}

export function DuringMaintenanceBanner({
  message,
  ...props
}: SpecificBannerProps) {
  const IconComponent = ICONS_MAPPING["maintenance"];
  const { t } = useTranslation();
  return (
    <MaintenanceBannerContainer id="maintenance_in_progress_banner" {...props}>
      <IconComponent />
      <WhiteText>
        <span>{t("maintenance.inProgress.part1")}</span>{" "}
        <Link
          target="_blank"
          href={`${getRootUrl()}/whats_new/update_process/`}
          color="nav.100"
          light
          textDecoration="underline"
        >
          {t("maintenance.inProgress.linkText")}
        </Link>{" "}
        {t("maintenance.inProgress.part2")}
        <WhiteText fontWeight="bold">
          {t("maintenance.inProgress.boldedText")}
        </WhiteText>
        {t("maintenance.inProgress.part3")}
      </WhiteText>
    </MaintenanceBannerContainer>
  );
}

export const TrainingBanner = props => {
  const IconComponent = ICONS_MAPPING["training"];
  const { t } = useTranslation();
  return (
    <MaintenanceBannerContainer
      backgroundColor={themeGet("colors.banners.training.dark")}
      {...props}
    >
      <IconComponent />
      <Text>{t("training.banner.text")}</Text>
    </MaintenanceBannerContainer>
  );
};
