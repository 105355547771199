import { Flex } from "grid-styled";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import system from "system-components";

import DropdownMenu from "pstat-anywhere/components/partials/DropdownMenu";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import MenuLink from "pstat-anywhere/components/partials/MenuLink";
import titleCase from "pstat-anywhere/utils/titleCase";
import { Badge } from "pstat-design-system/Badge";

const ButtonBadge = system({
  is: "button",
  textAlign: "left",
  bg: "transparent",
  border: 1,
  borderColor: "primary.0",
  borderRadius: 3,
  px: 4,
  py: 1,
  my: 1,
  fontSize: [3, 3, 8],
  fontWeight: "light",
  color: "nav.0"
}).extend`
  cursor: pointer;
`;

export const StyledTabBadge = system({
  is: Badge,
  color: "nav.100",
  bg: "error.0",
  fontSize: 1,
  ml: -4,
  mb: 7,
  mr: 0,
  pl: 0,
  pr: 0,
  border: 0
});

const PolicyType = props => {
  const { onClick, title, totalCounter, fontSize } = props;
  return (
    <Flex alignItems="center" justify="space-between" jusflexWrap="wrap">
      <ButtonBadge
        onClick={onClick}
        data-testid="bloc-selector-button"
        data-tip={true}
        data-for="applicability-tenants-tooltip"
        fontSize={fontSize}
      >
        {title}{" "}
        <StyledFontAwesomeIcon size="xs" icon={["far", "chevron-down"]} />
      </ButtonBadge>
      {totalCounter !== null && (
        <StyledTabBadge
          id="due_for_review_total_count"
          data-testid="due-for-review-total-count"
        >
          {totalCounter}
        </StyledTabBadge>
      )}
    </Flex>
  );
};

const PolicyTypeItem = styled.li`
  display: block;
  float: none;
`;

const PolicyTypeItems = ({ policyTypes }) => (
  <Fragment>
    {policyTypes.map(({ name, counter, url, to }) => (
      <PolicyTypeItem>
        <MenuLink href={url} to={to}>
          {titleCase(name)} ({counter})
        </MenuLink>
      </PolicyTypeItem>
    ))}
  </Fragment>
);

const DropdownSelectorContainer = styled.div`
  position: relative;
  z-index: auto;
`;

export const PolicyTypeSelector = ({ policyTypes, title, fontSize }) => {
  const [expanded, setExpanded] = useState(false);
  const closeDropdown = () => setExpanded(false);
  const toggle = () => setExpanded(!expanded);
  const isCounterNotAvailable = policyTypes.some(
    ({ counter }) => counter === undefined || counter === null
  );
  const totalCounter = isCounterNotAvailable
    ? null
    : policyTypes.reduce((total, { counter }) => total + counter, 0);
  return (
    <DropdownMenu
      expanded={expanded}
      toggle={toggle}
      closeMenu={closeDropdown}
      customSelectorContainer={DropdownSelectorContainer}
      dropdownSelector={() => (
        <PolicyType
          title={title}
          totalCounter={totalCounter}
          onClick={toggle}
          fontSize={fontSize}
        />
      )}
    >
      <PolicyTypeItems policyTypes={policyTypes} />
    </DropdownMenu>
  );
};
