import graphql from "babel-plugin-relay/macro";
import { Box } from "grid-styled";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { themeGet } from "styled-system";
import system from "system-components";

import { StyledFontAwesomeIcon } from "../partials/icons";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { FieldError } from "pstat-design-system/inputs/FormElements";
import { FocusLink } from "pstat-design-system/Link";
import {
  PanelContent,
  PanelHeader,
  PanelWrapper
} from "pstat-design-system/Panel";
import { H2 } from "pstat-design-system/text";


const AttachmentsPanel = ({ attachments }) => {
  const { t } = useTranslation();
  const links = attachments.map((attachment, index) => (
    <Attachment
      key={index}
      url={attachment.fileUrl}
      title={attachment.title}
      t={t}
    />
  ));
  return (
    <Box width={1} px={[0, 0, 3]}>
      <PanelWrapper id="attachments">
        <AttachmentHeaderContainer>
          <H2 color="nav.0" fontWeight="normal">
            {t("view.attachments.panel.header")}
          </H2>
        </AttachmentHeaderContainer>
        <AttachmentContentContainer>{links}</AttachmentContentContainer>
      </PanelWrapper>
    </Box>
  );
};

export default AttachmentsPanel;

export const AttachmentsFragmentContainer = createFragmentContainer(
  AttachmentsPanel,
  graphql`
    fragment Attachments_attachments on DocumentAttachmentType
    @relay(plural: true) {
      title
      fileUrl
    }
  `
);

const AttachmentHeaderContainer = system({
  is: PanelHeader,
  width: [1, 1, 1 / 3],
  display: "inline-block",
  border: "none"
}).extend`
  vertical-align: top;
`;

const AttachmentContentContainer = system({
  is: PanelContent,
  width: [1, 1, 2 / 3],
  display: "inline-block",
  m: 0
});

const AttachmentLink = system({
  is: FocusLink,
  width: ["auto"]
}).extend`
  &:hover {
    text-decoration: none;
  }
`;

const AttachmentLinkContent = system({
  is: "span"
}).extend`
  & {
    text-decoration: none;
    background: linear-gradient(
      to bottom,
      ${themeGet("colors.primary.0")} 0%,
      ${themeGet("colors.primary.0")} 100%
    );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 1px 2px;
    transition: background-size 0.2s;
    line-height: ${themeGet("fontSizes.3")};
    width: "auto";
  }
  ${AttachmentLink}:hover &,
  ${AttachmentLink}:active & {
    background-size: 1px calc(${themeGet("fontSizes.2")} + ${themeGet(
  "space.2"
)}px);
  }
`;

const AttachmentContainer = system({
  display: "inline-block",
  width: ["100%"],
  m: 0
}).extend`
  vertical-align: top;
`;

const AttachmentContent = system({
  display: "flex"
});

const WordWrapContainer = system({
  display: "table", // needed for Firefox
  width: "100%"
}).extend`
  table-layout: fixed; /* needed for Firefox */
  word-break: break-word;
  word-wrap: break-word; /* needed for IE11 and Firefox */
`;

const _Attachment = ({ url, title, t, labels }) => {
  const documentLabel = labels.documentLabel.toLowerCase();
  return (
    <AttachmentContainer>
      {url ? (
        <AttachmentLink href={url} target="_blank">
          <AttachmentContent>
            <div>
              <StyledFontAwesomeIcon
                icon={["fal", "paperclip"]}
                size="lg"
                color="secondary.0"
              />
            </div>
            <div>
              <WordWrapContainer>
                <AttachmentLinkContent>{title}</AttachmentLinkContent>
              </WordWrapContainer>
            </div>
          </AttachmentContent>
        </AttachmentLink>
      ) : (
        <AttachmentContent>
          <WordWrapContainer>
            <FieldError iconSize="lg">
              {t("view.attachments.missingFileError", {
                fileName: title,
                documentLabel
              })}
            </FieldError>
          </WordWrapContainer>
        </AttachmentContent>
      )}
    </AttachmentContainer>
  );
};
const Attachment = withLabelContext(_Attachment);
