import { borderRadius } from "polished";
import styled from "styled-components";

// this FieldSet only keeps rounded corners on the left of the first, and right of the last element.
// (it would be pretty troll if we also supported RTL display, since this won't work properly)
const InlineInputWrapper = styled.div`
  display: inline-block;
  border: none;
  white-space: nowrap;
  > *:not(:first-child):not(:last-child) {
    ${borderRadius("left", "0px")} ${borderRadius("right", "0px")};
  }
  > input:not(:first-child):not(:last-child) {
    border-left: none;
    border-right: none;
  }
  > *:first-child:not(:last-child) {
    ${borderRadius("right", "0px")};
  }
  > input:first-child:not(:last-child) {
    border-right: none;
  }
  > *:last-child:not(:first-child) {
    ${borderRadius("left", "0px")};
  }
  > input:last-child:not(:first-child) {
    border-left: none;
  }
  width: ${props => (props.width ? props.width : "auto")};
`;

export default InlineInputWrapper;
