import graphql from "babel-plugin-relay/macro";
import { withRouter } from "found";
import { Flex } from "grid-styled";
import { Component, Fragment } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";

import { isRequiredApproval } from "../../approve_policy/utils";
import { rejectPendingDocumentMutation } from "../../reject_policy/RejectPendingDocumentMutation";
import { approvePendingDocument } from "../ApprovePendingDocumentMutation";
import cloneDocument from "../CloneDocumentMutation";
import { AfterTOCContainer } from "../table_of_contents";

import { acknowledgePolicy } from "pstat-anywhere/components/document_control/acknowledge_policy/AcknowledgePolicyMutation";
import ApprovalModal from "pstat-anywhere/components/document_control/approve_policy/ApprovalModal";
import RejectModal from "pstat-anywhere/components/document_control/reject_policy/RejectModal";
import ClonePolicyModal from "pstat-anywhere/components/document_control/view_policy/ClonePolicyModal";
import ShowChanges from "pstat-anywhere/components/document_control/view_policy/share/ShowChanges";
import DiscardDraftModal from "pstat-anywhere/components/document_draft/DiscardDraftModal";
import {
  BottomNav,
  NavOptions,
  TopNav
} from "pstat-anywhere/components/partials/tertiary_nav";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withViewer } from "pstat-anywhere/context_providers/ViewerContext";
import { PermissionDenied403Error } from "pstat-anywhere/errors";
import { environment } from "pstat-anywhere/relay";
import { isDraft } from "pstat-anywhere/utils/document";
import { canViewInDevelopmentWork } from "pstat-anywhere/utils/permissions";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import {
  createErrorMessage,
  createRejectSuccessMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import { Button } from "pstat-design-system/shared/Button";
import MobileHorizontalScrollControl from "pstat-design-system/utils/AddHorizontalScrollControls";


const _SmallScreenSecondaryNav = ({
  document,
  children,
  viewer,
  onAcknowledge,
  onAcknowledgeKeyDown,
  labels,
  approveHandler,
  showChangesToggle,
  showChangesTaskResult,
  displayChangesDiff,
  handleDisplayChangesDiff,
  acknowledgeButtonProcessing,
  onReject,
  isApprovalPage,
  policyActions,
  route,
  pageHasReachedTheEnd,
  cloneToggle,
  deleteDraftToggle,
  checkingChangesDiff
}) => {
  const { t } = useTranslation();
  const handleApprovalKeyDown = event => {
    if (event.key === "Enter") {
      approveHandler();
    }
  };
  const showApprovalButton = isApprovalPage && isRequiredApproval(document);
  const showAcknowledgeButton = document.shouldBeAcknowledged;
  return (
    <Fragment>
      {!viewer.isGuest && (
        <TopNav
          aria-label={t(
            "documentControl.view.secondaryNav.smallScreenNav.ariaLabel",
            labels.documentLabel
          )}
        >
          <MobileHorizontalScrollControl
            render={contentRef => (
              <NavOptions
                showCurrentPage={true}
                document={document}
                ref={contentRef}
                policyActions={policyActions}
                route={route}
                cloneToggle={cloneToggle}
                deleteDraftToggle={deleteDraftToggle}
              />
            )}
          />
        </TopNav>
      )}
      {children}
      {(showApprovalButton || showAcknowledgeButton || showChangesToggle) && (
        <BottomNav>
          {showApprovalButton && (
            <Flex alignItems="center">
              <Button
                id="approve_button"
                buttonStyle="primary"
                onClick={approveHandler}
                onKeyDown={handleApprovalKeyDown}
              >
                {t("buttons.approve")}
              </Button>
              {document.rejectable && (
                <Button buttonStyle="secondary" onClick={onReject}>
                  {t("buttons.reject")}
                </Button>
              )}
            </Flex>
          )}
          {showAcknowledgeButton && (
            <Button
              onClick={onAcknowledge}
              onKeyDown={onAcknowledgeKeyDown}
              buttonStyle="primary"
              processing={acknowledgeButtonProcessing}
              disabled={!pageHasReachedTheEnd}
            >
              {t("buttons.acknowledge")}
            </Button>
          )}
          {showChangesToggle && (
            <Flex alignItems="center">
              <ShowChanges
                checkingChangesDiff={checkingChangesDiff}
                showChanges={displayChangesDiff}
                title={t("documentControl.acknowledge.bar.changesTitle.short")}
                toggleShowChanges={handleDisplayChangesDiff}
                showChangesTaskResult={showChangesTaskResult}
              />
            </Flex>
          )}
        </BottomNav>
      )}
    </Fragment>
  );
};
const SmallScreenSecondaryNav = withLabelContext(_SmallScreenSecondaryNav);
SmallScreenSecondaryNav.displayName = "SmallScreenSecondaryNav";

const LargeScreenSecondaryNav = ({
  approveHandler,
  document,
  children,
  viewer,
  onAcknowledge,
  onAcknowledgeKeyDown,
  showChangesToggle,
  showChangesTaskResult,
  displayChangesDiff,
  handleDisplayChangesDiff,
  acknowledgeButtonProcessing,
  tocExpanded,
  onReject,
  isApprovalPage,
  policyActions,
  route,
  pageHasReachedTheEnd,
  cloneToggle,
  deleteDraftToggle,
  checkingChangesDiff
}) => {
  const { t } = useTranslation();
  const handleApprovalKeyDown = event => {
    if (event.key === "Enter") {
      approveHandler();
    }
  };
  return (
    <Fragment>
      <TopNav id="secondary-nav">
        <Flex data-testid="secondary-nav-buttons" alignItems="center">
          {!viewer.isGuest && (
            <Fragment>
              {isApprovalPage && isRequiredApproval(document) && (
                <Fragment>
                  <Button
                    id="approve_button"
                    buttonStyle="primary"
                    onClick={approveHandler}
                    onKeyDown={handleApprovalKeyDown}
                  >
                    {t("buttons.approve")}
                  </Button>
                  {document.rejectable && (
                    <Button buttonStyle="secondary" onClick={onReject}>
                      {t("buttons.reject")}
                    </Button>
                  )}
                  {canViewInDevelopmentWork(viewer) && (
                    <Button buttonStyle="tertiary">
                      {t("buttons.comment")}
                    </Button>
                  )}
                </Fragment>
              )}
              {document.shouldBeAcknowledged && (
                <Button
                  onClick={onAcknowledge}
                  onKeyDown={onAcknowledgeKeyDown}
                  buttonStyle="primary"
                  processing={acknowledgeButtonProcessing}
                  disabled={!pageHasReachedTheEnd}
                >
                  {t("buttons.acknowledge")}
                </Button>
              )}
              <NavOptions
                showCurrentPage={true}
                document={document}
                policyActions={policyActions}
                route={route}
                cloneToggle={cloneToggle}
                deleteDraftToggle={deleteDraftToggle}
              />
            </Fragment>
          )}
        </Flex>
        {showChangesToggle && (
          <Flex alignItems="center">
            {showChangesToggle && (
              <ShowChanges
                showChanges={displayChangesDiff}
                checkingChangesDiff={checkingChangesDiff}
                title={
                  displayChangesDiff
                    ? t("documentControl.acknowledge.bar.changesTitle.newest")
                    : t("documentControl.acknowledge.bar.changesTitle.show")
                }
                toggleShowChanges={handleDisplayChangesDiff}
                showChangesTaskResult={showChangesTaskResult}
              />
            )}
          </Flex>
        )}
      </TopNav>
      <AfterTOCContainer tocExpanded={tocExpanded}>
        {children}
      </AfterTOCContainer>
    </Fragment>
  );
};

class _SecondaryNavWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalModalOpen: false,
      acknowledgeButtonProcessing: false,
      rejectModalOpen: false,
      cloneModalOpen: false,
      cloneProcessing: false,
      discardDraftOpen: false,
      error: null
    };
  }

  toggleApprovalModal = () => {
    this.setState({
      approvalModalOpen: !this.state.approvalModalOpen
    });
  };

  toggleRejectModal = () => {
    this.setState({
      rejectModalOpen: !this.state.rejectModalOpen
    });
  };

  toggleCloneModal = () => {
    this.setState({
      cloneModalOpen: !this.state.cloneModalOpen
    });
  };

  toggleDiscardDraftModal = () => {
    this.setState({
      discardDraftOpen: !this.state.discardDraftOpen
    });
  };

  doReject = ({ comment, successCallback, failureCallback }) => {
    const { document, relay } = this.props;
    if (document.approvable !== "NO") {
      rejectPendingDocumentMutation(
        relay.environment,
        { documentPk: document.pk, comment },
        (response, errors) =>
          this.onRejectCompleted(response, successCallback, failureCallback)
      );
    }
  };

  doApproval = ({ comment = "" }) => {
    const { document, relay } = this.props;
    // do the check again, because why not
    if (document.approvable !== "NO") {
      this.props.setShouldBlockAlreadyApprovedPage(true);
      approvePendingDocument(
        relay.environment,
        document.pk,
        comment,
        this.onApprovalCompleted
      );
    }
  };

  doClone = dstTenantPk => {
    const { document, t } = this.props;
    this.setState({ cloneProcessing: true });
    cloneDocument(environment, document.pk, dstTenantPk, (response, errors) => {
      const { ok, redirectUrl, reasons } = response.copyDocument;
      if (errors) {
        errors.forEach(error => {
          createErrorMessage(error);
        });
      } else {
        if (ok) {
          window.location.href = redirectUrl + "?clone=true";
        } else if (reasons && reasons.length > 0) {
          reasons.forEach(reason => {
            createErrorMessage(reason);
          });
        } else if (reasons.status_code === 403) {
          this.setState({
            error: {
              status_code: 403,
              tenantsToRedirect: response?.copyDocument?.tenantsToRedirect,
              message: reasons?.message
            }
          });
        } else {
          createErrorMessage(
            t(
              "documentControl.view.secondaryNav.cloneDocument.unknownErrorMessage"
            )
          );
        }
      }
      this.setState({ cloneProcessing: false });
      this.toggleCloneModal();
    });
  };

  onRejectCompleted = (response, successCallback, failureCallback) => {
    const { t, router } = this.props;
    const { documentLabel } = this.props.labels;
    const documentLabelLowercase = documentLabel.toLowerCase();
    const { ok } = response.rejectPendingDocument;
    if (ok) {
      createRejectSuccessMessage(
        t("documentControl.reject.successMessage", {
          documentLabel: documentLabelLowercase
        })
      );
      successCallback();
      router.push(`${getRootUrl()}/`);
    } else {
      createErrorMessage(
        t("documentControl.reject.errorMessage", {
          documentLabel: documentLabelLowercase
        })
      );
      failureCallback();
    }
  };

  onApprovalCompleted = () => {
    const { t, router } = this.props;
    createSuccessMessage(
      t(
        "documentControl.view.secondaryNav.wrapper.onApprovalCompleted.successMessage"
      )
    );
    router.push(`${getRootUrl()}/`);
  };

  handleAcknowledge = () => {
    if (!this.state.acknowledgeButtonProcessing) {
      const { relay, document } = this.props;
      acknowledgePolicy(
        relay.environment,
        document.pk,
        this.handleAcknowledgeRequestCallback
      );
      this.setState({ acknowledgeButtonProcessing: true });
    }
  };

  handleAcknowledgeRequestCallback = (response, errors) => {
    const { t } = this.props;
    const { documentLabel } = this.props.labels;
    const {
      acknowledgePolicy: { ok, reasons }
    } = response;
    if (errors) {
      errors.forEach(error => {
        createErrorMessage(error);
      });
      this.setState({ acknowledgeButtonProcessing: false });
    } else {
      if (ok) {
        createSuccessMessage(
          t(
            "documentControl.view.secondaryNav.wrapper.handleAcknowledgeRequestCallback.successMessage",
            { documentLabel }
          )
        );
        setTimeout(
          () => window.location.replace(`${getRootUrl()}/acknowledgment/mine`),
          3000
        );
      } else if (reasons && reasons.length > 0) {
        reasons.forEach(reason => {
          createErrorMessage(reason);
        });
        this.setState({ acknowledgeButtonProcessing: false });
      } else {
        createErrorMessage(
          t(
            "documentControl.view.secondaryNav.wrapper.handleAcknowledgeRequestCallback.unknownErrorMessage"
          )
        );
        this.setState({ acknowledgeButtonProcessing: false });
      }
    }
  };
  handleAcknowledgeButtonKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.handleAcknowledge();
    }
  };

  componentDidUpdate(_, prevState) {
    if (!prevState.error && this.state.error) {
      throw new PermissionDenied403Error(this.state.error?.message || null, {
        tenantsToRedirect: this.state.error.tenantsToRedirect,
        documentPk: this.props.document.pk
      });
    }
  }

  render() {
    const {
      children,
      showChangesToggle,
      showChangesTaskResult,
      displayChangesDiff,
      handleDisplayChangesDiff,
      document,
      viewer,
      isApprovalPage,
      tocExpanded,
      policyActions,
      route,
      pageHasReachedTheEnd,
      showCloneAction,
      documentSave,
      isDesktop = true
    } = this.props;
    const {
      approvalModalOpen,
      rejectModalOpen,
      acknowledgeButtonProcessing,
      cloneModalOpen,
      cloneProcessing,
      discardDraftOpen
    } = this.state;
    const navProps = {
      onReject: this.toggleRejectModal,
      approveHandler: this.toggleApprovalModal,
      document: document,
      children: children,
      viewer: viewer,
      onAcknowledge: this.handleAcknowledge,
      onAcknowledgeKeyDown: this.handleAcknowledgeButtonKeyDown,
      checkingChangesDiff: this.props.checkingChangesDiff,
      showChangesToggle: showChangesToggle,
      showChangesTaskResult: showChangesTaskResult,
      displayChangesDiff: displayChangesDiff,
      handleDisplayChangesDiff: handleDisplayChangesDiff,
      acknowledgeButtonProcessing: acknowledgeButtonProcessing,
      isApprovalPage: isApprovalPage,
      policyActions: policyActions,
      route: route,
      pageHasReachedTheEnd: pageHasReachedTheEnd,
      cloneToggle: showCloneAction ? this.toggleCloneModal : null,
      deleteDraftToggle: this.toggleDiscardDraftModal
    };
    return (
      <Fragment>
        {isDesktop ? (
          <LargeScreenSecondaryNav {...navProps} tocExpanded={tocExpanded} />
        ) : (
          <SmallScreenSecondaryNav {...navProps} />
        )}
        <ApprovalModal
          id="approval_modal"
          isOpen={approvalModalOpen}
          variant="approval"
          onClose={this.toggleApprovalModal}
          onApprove={this.doApproval}
        />
        <RejectModal
          id="reject_modal"
          isOpen={rejectModalOpen}
          onClose={this.toggleRejectModal}
          onReject={this.doReject}
        />
        {showCloneAction && (
          <ClonePolicyModal
            id="clone_modal"
            isOpen={cloneModalOpen}
            isProcessing={cloneProcessing}
            onClose={this.toggleCloneModal}
            onClone={this.doClone}
          />
        )}
        {isDraft(document.status) && (
          <DiscardDraftModal
            isOpen={discardDraftOpen}
            onClose={this.toggleDiscardDraftModal}
            documentSavePk={documentSave?.pk}
          />
        )}
      </Fragment>
    );
  }
}

export const SecondaryNavWrapper = withRouter(
  withLabelContext(withTranslation()(_SecondaryNavWrapper))
);
export default createFragmentContainer(
  withViewer(SecondaryNavWrapper),
  graphql`
    fragment SecondaryNav_document on DocumentType {
      id
      pk
      name
      status
      approvable
      rejectable
      shouldBeAcknowledged
    }
  `
);
