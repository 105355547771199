import { Fragment } from "react";

import AutocompleteTextInput from "../AutocompleteTextInput";

import useUserAndUserGroupAutocomplete from "./hooks";

import Tooltip from "pstat-design-system/Tooltip";
import {DeleteIconBadge} from "pstat-design-system/Badge";

function UserOrUserGroupAutocompleteInput({
  selectedSuggestions,
  onSuggestionSelected,
  selectMultiple,
  users,
  groups,
  ...props
}) {
  const {
    suggestions,
    fetchSuggestions,
    getSuggestionValue
  } = useUserAndUserGroupAutocomplete({ users, groups });
  const renderSuggestionBadge = (suggestion, remove) => {
    if (suggestion.type === "user") {
      const splittedDisplayName = suggestion.display_name.split(":");
      let title;
      let selectBadgeProps = {
        value: `${suggestion.first_name} ${suggestion.last_name}`,
        onClick: remove
      };
      if (splittedDisplayName.length > 1) {
        title = splittedDisplayName[1].trim();
        selectBadgeProps["data-tip"] = true;
        selectBadgeProps["data-for"] = `user-${suggestion.id}-title-tooltip`;
      }
      return (
        <Fragment>
          <DeleteIconBadge {...selectBadgeProps} />
          {title && (
            <Tooltip name={`user-${suggestion.id}-title-tooltip`}>
              {title}
            </Tooltip>
          )}
        </Fragment>
      );
    } else if (suggestion.type === "group") {
      return <DeleteIconBadge value={suggestion.name} onClick={remove} />;
    }
  };

  return (
    <AutocompleteTextInput
      selectedSuggestions={selectedSuggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionDeselected={onSuggestionSelected}
      suggestions={suggestions}
      onSuggestionsFetchRequested={fetchSuggestions}
      getSuggestionValue={getSuggestionValue}
      selectMultiple={selectMultiple}
      renderSuggestionBadge={renderSuggestionBadge}
      {...props}
    />
  );
}

export const UserAutocompleteInput = props => (
  <UserOrUserGroupAutocompleteInput {...props} users={true} groups={false} />
);

export const UserGroupAutocompleteInput = props => (
  <UserOrUserGroupAutocompleteInput {...props} users={false} groups={true} />
);

const UserAndUserGroupAutocompleteInput = props => (
  <UserOrUserGroupAutocompleteInput {...props} users={true} groups={true} />
);

export default UserAndUserGroupAutocompleteInput;
