import { cloneElement, useState } from "react";

export default function useExpandableContainer({
  size,
  children,
  drillExpandableContainerProps
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleIsExpanded() {
    setIsExpanded(!isExpanded);
  }
  function shouldShowResizer(forcedHeight) {
    if (!size) {
      return false;
    }
    const { height } = size;
    if (!height) {
      return false;
    }
    if (forcedHeight) {
      return height > forcedHeight;
    }
    return height > 215;
  }
  let content = children;
  if (drillExpandableContainerProps) {
    content = cloneElement(children, { isExpanded, shouldShowResizer });
  }
  return {
    isExpanded,
    toggleIsExpanded,
    shouldShowResizer,
    content
  };
}
