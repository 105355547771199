import Linkify from "react-linkify";
import styled from "styled-components";

import { Link } from "pstat-design-system/Link";
import { Cell } from "pstat-design-system/tables/Cells";
import TD from "pstat-design-system/tables/TD";
import ExpandableContainer from "pstat-design-system/utils/ExpandableContainer";

const NotificationTD = styled(TD)`
  display: block;
  white-space: pre-line;
  tbody tr:first-child &:first-child {
    padding-top: 0;
  }
  tbody tr &:not(:first-child) {
    border-top: none;
  }
  padding: 0px;
  border: 0;
`;

const CellContentExpandableWrapper = styled.div`
  ${props => (props.isExpanded ? "" : "max-height: 170px; overflow: hidden;")};
  padding-bottom: ${props => (props.shouldShowResizer(170) ? "52" : "0")}px;
`;

function LinkDecorator(decoratedHref, decoratedText, key) {
  return (
    <Link href={decoratedHref} key={key}>
      {decoratedText}
    </Link>
  );
}

function LinkifiedNotificationTD({ children, ...props }) {
  return (
    <Linkify componentDecorator={LinkDecorator}>
      <NotificationTD {...props}>
        <ExpandableContainer
          drillExpandableContainerProps={true}
          forcedHeight={165}
        >
          <CellContentExpandableWrapper>
            {children}
          </CellContentExpandableWrapper>
        </ExpandableContainer>
      </NotificationTD>
    </Linkify>
  );
}

function NotificationCell(props) {
  return <Cell TDComp={LinkifiedNotificationTD} {...props} />;
}

export default NotificationCell;
