import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation newDocumentAttachmentMutation(
    $documentPk: Int!
    $title: String!
    $file: Upload!
  ) {
    newDocumentAttachment(
      documentPk: $documentPk
      title: $title
      attachmentFile: $file
    ) {
      ok
    }
  }
`;

const newDocumentAttachment = (
  environment,
  variables,
  uploadables,
  callback
) => {
  commitMutation(environment, {
    mutation,
    variables,
    uploadables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default newDocumentAttachment;
