import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation UnsubscribeFromCommentsMutation($documentPk: Int!) {
    unsubscribeFromComments(documentPk: $documentPk) {
      ok
      reasons
    }
  }
`;

export const unsubscribeFromComments = (environment, document, callback) => {
  const variables = {
    documentPk: document.pk
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
