import { Flex } from "grid-styled";
import { themeGet } from "styled-system";
import system from "system-components";

import { Link } from "./Link";
import RouterLink from "./RouterLink";
import { Text } from "./text";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

export const Badge = system({
  is: Text,
  display: "inline-block",
  textAlign: "center",
  border: "1px solid",
  borderColor: "nav.80",
  borderRadius: 3, // is this really reasonable?  we go with a scale of 0, 6, 12, then 27?!
  ml: 1,
  mr: 1,
  pl: 2,
  pr: 2,
  fontSize: [1],
  fontWeight: "medium",
  lineHeight: "28px",
  minWidth: "28px"
});

Badge.displayName = "Badge";

const FocusHolder = system({
  is: Flex,
  alignItems: "center",
  tabIndex: "-1",
  height: "100%"
}).extend`
  &:focus {
    outline: 0;
  }
`;

const FocusBorder = system({
  border: 1,
  borderColor: "transparent",
  borderRadius: 3,
  py: "1px",
  tabIndex: "-1"
}).extend`
  &:focus {
    outline: 0;
  }

  ${Link}:focus & {
    border: dashed 1px;
    border-color: ${props =>
      props.light ? themeGet("colors.nav.100") : themeGet("colors.nav.25")};
  }
`;

const LinkedBadgeContainer = system({
  is: Badge,
  px: 2,
  mx: 0
}).extend`
  color: ${props =>
    props.light
      ? themeGet("colors.primary.0")
      : themeGet("colors.secondary.0")};
  border-color: ${themeGet("colors.primary.0")};
  text-decoration: underline;

  ${Link}:hover &, ${Link}:active & {
    border-color: ${themeGet("colors.primary.40")};
    cursor: pointer;
  }
`;

const LinkedBadgeContent = props => (
  <FocusHolder>
    <FocusBorder light={props.light}>
      <LinkedBadgeContainer light={props.light}>
        {props.children}
      </LinkedBadgeContainer>
    </FocusBorder>
  </FocusHolder>
);

export const LinkedBadge = props => {
  const { light } = props;
  return (
    <Link light={light} mx={1} {...props}>
      <LinkedBadgeContent>{props.children}</LinkedBadgeContent>
    </Link>
  );
};

export const DeleteIconBadge = ({ onClick, ...otherProps }) => {
  const handleKeyDown = event => {
    const isEnterKey = event => event.key === "Enter";
    if (isEnterKey(event)) {
      onClick(event);
    }
  };
  return (
    <Badge my={1} {...otherProps}>
      <Flex alignItems="center" flexDirection="row">
        {otherProps.value}
        <StyledFontAwesomeIcon
          icon={["far", "times"]}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          tabIndex="0"
          data-testid="badge-delete-icon"
        />
      </Flex>
    </Badge>
  );
};

export const RouterLinkedBadge = props => {
  const { light } = props;
  return (
    <RouterLink light={light} mx={1} {...props}>
      <LinkedBadgeContent>{props.children}</LinkedBadgeContent>
    </RouterLink>
  );
};
