import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { themeGet } from "styled-system";

// TODO: replace rest of tooltips to use a contant for event types
export const HOVER_EVENTS = "mouseover focus";
export const HOVER_EVENTS_OFF = "mouseout blur";

const StyledTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip.custom-theme {
    background-color: ${themeGet("colors.nav.10")};
    color: ${themeGet("colors.nav.100")};
    font-family: ${themeGet("fonts.font")};
    font-size: ${themeGet("fontSizes.2")};
    border-radius: ${themeGet("radii.1")}px;
    padding: ${themeGet("space.3")}px;
    max-width: 180px;
    text-align: center;
    white-space: pre-wrap;
  }

  &.__react_component_tooltip.custom-theme:after {
    border-top-color: ${themeGet("colors.nav.10")};
    border-top-width: ${themeGet("space.2")}px;
    border-left-width: ${themeGet("space.2")}px;
    border-right-width: ${themeGet("space.2")}px;
    bottom: -${themeGet("space.2")}px;
  }
`;
const Tooltip = props => (
  <StyledTooltip id={props.name} className="custom-theme" effect="solid">
    {props.children}
  </StyledTooltip>
);

export default Tooltip;
