import { useTranslation } from "react-i18next";

import { ContentContainer } from "pstat-anywhere/components/pages";
import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";
import LocalDocumentsForTemplateCsvQuery from "pstat-anywhere/components/system_document_templates/LocalDocumentsForTemplateCsvQuery";
import UnpublishedDocumentsForTemplateCsvQuery from "pstat-anywhere/components/system_document_templates/UnpublishedDocumentsForTemplateCsvQuery";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import Breadcrumb from "pstat-design-system/Breadcrumb";

const PublishedTemplatesBreadcrumb = () => {
  const { t } = useTranslation();
  return (
    <Breadcrumb
      text={t("systemDocumentTemplates.localDocumentsForTemplate.breadcrumb")}
      to={`${getRootUrl()}/template/published`}
    />
  );
};

const LocalDocumentsForTemplatePage = ({
  blocSystemDocumentTemplatesDocuments,
  variables,
  location,
  route,
  router,
  routeParams
}) => {
  const {
    blocSystemDocumentTemplates,
    systemDocumentTemplateName,
    unpublishedDocumentsCount
  } = blocSystemDocumentTemplatesDocuments || {};
  const totalCount = blocSystemDocumentTemplates?.totalCount;
  const edges = getUpdatedEdges(blocSystemDocumentTemplates?.edges);
  const data = {
    edges,
    totalCount
  };
  return (
    <LocalDocumentsForTemplate
      data={data}
      showSubscribed={true}
      templatePk={routeParams?.pk}
      systemDocumentTemplateName={systemDocumentTemplateName}
      publishedCount={totalCount}
      unpublishedCount={unpublishedDocumentsCount}
      variables={variables}
      route={route}
      router={router}
      location={location}
      csvQuery={LocalDocumentsForTemplateCsvQuery}
      csvQueryName="blocSystemDocumentTemplatesDocumentsCsv"
    />
  );
};

export default LocalDocumentsForTemplatePage;

export const UnpublishedDocumentsForTemplatePage = ({
  hiddenSystemDocumentTemplatesDocuments,
  variables,
  location,
  route,
  router,
  routeParams
}) => {
  const { documents, systemDocumentTemplateName, publishedDocumentsCount } =
    hiddenSystemDocumentTemplatesDocuments || {};
  const totalCount = documents?.totalCount;
  const edges = getUpdatedEdges(documents?.edges);
  const data = {
    edges,
    totalCount
  };
  return (
    <LocalDocumentsForTemplate
      data={data}
      showSubscribed={false}
      templatePk={routeParams?.pk}
      systemDocumentTemplateName={systemDocumentTemplateName}
      publishedCount={publishedDocumentsCount}
      unpublishedCount={totalCount}
      variables={variables}
      route={route}
      router={router}
      location={location}
      csvQuery={UnpublishedDocumentsForTemplateCsvQuery}
      csvQueryName="hiddenSystemDocumentTemplatesDocumentsCsv"
    />
  );
};

const LocalDocumentsForTemplate = ({
  data,
  templatePk,
  systemDocumentTemplateName,
  publishedCount,
  unpublishedCount,
  showSubscribed,
  variables,
  route,
  router,
  location,
  csvQuery,
  csvQueryName
}) => {
  const { t } = useTranslation();
  const labels = useLabels();
  const documentLabelPlural = titleCase(labels.documentLabelPlural);
  const tabs = [
    {
      id: "documents_for_template_subscribed",
      textKey:
        "systemDocumentTemplates.localDocumentsForTemplate.tabs.subscribedTab",
      path: `template/${templatePk}/subscribed`,
      count: publishedCount
    },
    {
      id: "documents_for_template_unsubscribed",
      textKey:
        "systemDocumentTemplates.localDocumentsForTemplate.tabs.unsubscribedTab",
      path: `template/${templatePk}/unsubscribed`,
      count: unpublishedCount
    }
  ];
  let tableType = "localDocumentsForTemplate";
  let emptyMessageTextKey =
    "systemDocumentTemplates.localDocumentsForTemplate.emptyMessage";
  if (!showSubscribed) {
    tableType = "unpublishedDocumentsForTemplate";
    emptyMessageTextKey =
      "systemDocumentTemplates.localDocumentsForTemplate.unsubscribedEmptyMessage";
  }
  return (
    <ContentContainer>
      <AdvancedReportPage
        variables={variables}
        data={data}
        tabs={tabs}
        route={route}
        router={router}
        location={location}
        headerText={t(
          "systemDocumentTemplates.localDocumentsForTemplate.header",
          { documentLabelPlural, templateName: systemDocumentTemplateName }
        )}
        noContentMessage={t(emptyMessageTextKey, {
          documentLabelPlural: documentLabelPlural.toLowerCase(),
          templateName: systemDocumentTemplateName
        })}
        showPagination={true}
        showHitsPerPageSelector={true}
        showTabsPanel={true}
        tableType={tableType}
        customOnRow={() => {}}
        showActionButtonPanel={true}
        frozenColumnTable={true}
        BreadcrumbComponent={PublishedTemplatesBreadcrumb}
        csvQueryArgs={{ pk: templatePk }}
        CSVQuery={csvQuery}
        csvQueryName={csvQueryName}
      />
    </ContentContainer>
  );
};

const getUpdatedEdges = edges => {
  return edges?.map(edge => {
    const tenant = edge.node.bloc.tenants[0];
    return {
      node: {
        ...edge.node,
        tenantName: tenant.name
      }
    };
  });
};
