/**
 * @flow
 * @relayHash 13e223c3a45d0a197b146e2ef0ed097d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcknowledgementListQueryVariables = {|
  linePk: number
|};
export type AcknowledgementListQueryResponse = {|
  +userAndUserGroupsAcknowlegmentByLine: ?{|
    +users: ?$ReadOnlyArray<?{|
      +display: ?string,
      +recurringAckPk: ?number,
    |}>,
    +userGroups: ?$ReadOnlyArray<?{|
      +display: ?string,
      +recurringAckPk: ?number,
    |}>,
  |}
|};
export type AcknowledgementListQuery = {|
  variables: AcknowledgementListQueryVariables,
  response: AcknowledgementListQueryResponse,
|};
*/


/*
query AcknowledgementListQuery(
  $linePk: Int!
) {
  userAndUserGroupsAcknowlegmentByLine(linePk: $linePk) {
    users {
      display
      recurringAckPk
    }
    userGroups {
      display
      recurringAckPk
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "linePk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "display",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "recurringAckPk",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userAndUserGroupsAcknowlegmentByLine",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "linePk",
        "variableName": "linePk",
        "type": "Int!"
      }
    ],
    "concreteType": "UserAndUserGroupsType",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "users",
        "storageKey": null,
        "args": null,
        "concreteType": "ItemAssignedAcknowledgmentsType",
        "plural": true,
        "selections": v1
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userGroups",
        "storageKey": null,
        "args": null,
        "concreteType": "ItemAssignedAcknowledgmentsType",
        "plural": true,
        "selections": v1
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AcknowledgementListQuery",
  "id": "a804e6304eba98dd0925714e23ce0c11",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AcknowledgementListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "AcknowledgementListQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
node.text = "query AcknowledgementListQuery(\n  $linePk: Int!\n) {\n  userAndUserGroupsAcknowlegmentByLine(linePk: $linePk) {\n    users {\n      display\n      recurringAckPk\n    }\n    userGroups {\n      display\n      recurringAckPk\n    }\n  }\n}\n";
(node/*: any*/).hash = '00d6135f7572757419935b0737a18c86';
module.exports = node;
