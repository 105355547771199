import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation acceptImportMutation($importPk: Int!, $comment: String!) {
    acceptImportDocument(importPk: $importPk, comment: $comment) {
      ok
      error {
        statusCode
      }
      redirectPath
    }
  }
`;

const acceptImport = (environment, variables, callback) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default acceptImport;
