/**
 * @flow
 * @relayHash 3fea32f58921f9521ec6b441b1b9f756
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchFiltersQueryVariables = {||};
export type SearchFiltersQueryResponse = {|
  +authors: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +userDisplay: ?string,
  |}>,
  +categoriesWithActiveDocuments: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +name: string,
  |}>,
  +tagsOnActiveDocuments: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +name: string,
  |}>,
|};
export type SearchFiltersQuery = {|
  variables: SearchFiltersQueryVariables,
  response: SearchFiltersQueryResponse,
|};
*/


/*
query SearchFiltersQuery {
  authors {
    pk
    userDisplay
    id
  }
  categoriesWithActiveDocuments {
    pk
    name
    id
  }
  tagsOnActiveDocuments {
    pk
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "userDisplay",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  v0,
  v2
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  v0,
  v2,
  v4
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SearchFiltersQuery",
  "id": "5c3d1b99c1826d9831462da113fbdd1a",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SearchFiltersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authors",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": true,
        "selections": [
          v0,
          v1
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categoriesWithActiveDocuments",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryType",
        "plural": true,
        "selections": v3
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tagsOnActiveDocuments",
        "storageKey": null,
        "args": null,
        "concreteType": "TagType",
        "plural": true,
        "selections": v3
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchFiltersQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authors",
        "storageKey": null,
        "args": null,
        "concreteType": "UserType",
        "plural": true,
        "selections": [
          v0,
          v1,
          v4
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "categoriesWithActiveDocuments",
        "storageKey": null,
        "args": null,
        "concreteType": "CategoryType",
        "plural": true,
        "selections": v5
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tagsOnActiveDocuments",
        "storageKey": null,
        "args": null,
        "concreteType": "TagType",
        "plural": true,
        "selections": v5
      }
    ]
  }
};
})();
node.text = "query SearchFiltersQuery {\n  authors {\n    pk\n    userDisplay\n    id\n  }\n  categoriesWithActiveDocuments {\n    pk\n    name\n    id\n  }\n  tagsOnActiveDocuments {\n    pk\n    name\n    id\n  }\n}\n";
(node/*: any*/).hash = '5ef76f70be10c3940cd16a71cf7ec23f';
module.exports = node;
