import { createContext, forwardRef, useContext } from "react";
// Signed-in user context
export const ViewerContext = createContext({});

// Higher Order Component for working with user context
export const withViewer = Component =>
  forwardRef((props, ref) => (
    <ViewerContext.Consumer>
      {viewer => <Component {...props} viewer={viewer} ref={ref} />}
    </ViewerContext.Consumer>
  ));

export const useViewer = () => {
  const context = useContext(ViewerContext);
  if (context === undefined) {
    throw new Error("useViewer must be used within a ViewerContext.Provider");
  }
  return context;
};
ViewerContext.displayName = "ViewerContext";
