import { createContext, forwardRef } from "react";
// Settings context
export const SettingsContext = createContext({});

// Higher Order Component for working with settings context
export const withSettings = Component =>
  forwardRef((props, ref) => (
    <SettingsContext.Consumer>
      {settings => <Component {...props} settings={settings} ref={ref} />}
    </SettingsContext.Consumer>
  ));
SettingsContext.displayName = "SettingsContext";
