/**
 * @flow
 * @relayHash c52c50a8102299ca3b0481a5aeff3659
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ForgotPasswordResetMutationVariables = {|
  uidb64: string,
  token: string,
  newPassword1: string,
  newPassword2: string,
|};
export type ForgotPasswordResetMutationResponse = {|
  +resetPassword: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type ForgotPasswordResetMutation = {|
  variables: ForgotPasswordResetMutationVariables,
  response: ForgotPasswordResetMutationResponse,
|};
*/


/*
mutation ForgotPasswordResetMutation(
  $uidb64: String!
  $token: String!
  $newPassword1: String!
  $newPassword2: String!
) {
  resetPassword(uidb64: $uidb64, token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "uidb64",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "newPassword1",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "newPassword2",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resetPassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "newPassword1",
        "variableName": "newPassword1",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "newPassword2",
        "variableName": "newPassword2",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "uidb64",
        "variableName": "uidb64",
        "type": "String!"
      }
    ],
    "concreteType": "ForgotPasswordReset",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ForgotPasswordResetMutation",
  "id": "4e9cb385de1dec6e87151012a9c8873c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ForgotPasswordResetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ForgotPasswordResetMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation ForgotPasswordResetMutation(\n  $uidb64: String!\n  $token: String!\n  $newPassword1: String!\n  $newPassword2: String!\n) {\n  resetPassword(uidb64: $uidb64, token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = 'f1901c6818b59b49b17c931cb305d3ba';
module.exports = node;
