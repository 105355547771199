import graphql from "babel-plugin-relay/macro";

const ManyEpStandardContentQuery = graphql`
  query ManyEpStandardContentQuery($associationsPk: [Int]!) {
    manyEps(associationsPk: $associationsPk) {
      data {
        documentLineEpAssociationId
        name
        lastChanged
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default ManyEpStandardContentQuery;
