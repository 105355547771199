import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { LoadingModal as BaseLoadingModal } from "pstat-design-system/modal/StyledReactModal";
import { Text } from "pstat-design-system/text";

const LoadingModal = ({ id, isOpen, text, parentSelector }) => {
  let getParentSelector = () =>
    document.getElementById("content") || document.body;
  if (parentSelector) {
    getParentSelector = parentSelector;
  }
  return (
    <BaseLoadingModal
      id={id}
      isOpen={isOpen}
      parentSelector={getParentSelector}
    >
      <LoadingSpinner size={50} />
      {text && (
        <Text color="nav.25" fontWeight="medium" fontSize={1} pt={7}>
          {text}
        </Text>
      )}
    </BaseLoadingModal>
  );
};

export default LoadingModal;
