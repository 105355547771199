import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation CloneDocumentMutation($input: CopyDocumentMutationInput!) {
    copyDocument(input: $input) {
      ok
      reasons
      redirectUrl
      tenantsToRedirect {
        name
        url
      }
    }
  }
`;

const cloneDocument = (environment, documentPk, tenantPk, callback) => {
  const variables = {
    input: {
      documentPk,
      tenantPk
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default cloneDocument;
