/**
 * @flow
 * @relayHash 6d3217c74669b4e8bfb5e247db18e5c5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentAcceptImportPageQueryVariables = {|
  documentImportPk: number
|};
export type DocumentAcceptImportPageQueryResponse = {|
  +documentToAcceptView: ?{|
    +documentToAccept: ?{|
      +document: ?{|
        +pk: ?number,
        +name: string,
        +bloc: {|
          +name: string
        |},
        +category: {|
          +name: string
        |},
        +author: {|
          +firstName: string,
          +lastName: string,
          +profile: ?{|
            +title: ?string
          |},
        |},
        +expirationDate: ?string,
        +revisionDates: ?$ReadOnlyArray<?string>,
      |},
      +documentImport: ?{|
        +pk: ?number,
        +importFile: ?{|
          +name: ?string,
          +url: ?string,
          +zipName: ?string,
          +zipUrl: ?string,
        |},
      |},
      +policyActions: ?any,
    |},
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type DocumentAcceptImportPageQuery = {|
  variables: DocumentAcceptImportPageQueryVariables,
  response: DocumentAcceptImportPageQueryResponse,
|};
*/


/*
query DocumentAcceptImportPageQuery(
  $documentImportPk: Int!
) {
  documentToAcceptView(documentImportPk: $documentImportPk) {
    documentToAccept {
      document {
        pk
        name
        bloc {
          name
          id
        }
        category {
          name
          id
        }
        author {
          firstName
          lastName
          profile {
            title
            id
          }
          id
        }
        expirationDate
        revisionDates(forceShowAllRevisions: true)
        id
      }
      documentImport {
        pk
        importFile {
          name
          url
          zipName
          zipUrl
        }
        id
      }
      policyActions
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentImportPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentImportPk",
    "variableName": "documentImportPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  v3
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDates",
  "args": [
    {
      "kind": "Literal",
      "name": "forceShowAllRevisions",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "revisionDates(forceShowAllRevisions:true)"
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "importFile",
  "storageKey": null,
  "args": null,
  "concreteType": "ImportFileType",
  "plural": false,
  "selections": [
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "zipName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "zipUrl",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "policyActions",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v14 = [
  v3,
  v13
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DocumentAcceptImportPageQuery",
  "id": "4f75a860b8ca559312c83a5889569b41",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DocumentAcceptImportPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentToAcceptView",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentToAcceptPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentToAccept",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentImportAcceptType",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "document",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentType",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "bloc",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BlocType",
                    "plural": false,
                    "selections": v4
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "category",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryType",
                    "plural": false,
                    "selections": v4
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserType",
                    "plural": false,
                    "selections": [
                      v5,
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "profile",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProfileType",
                        "plural": false,
                        "selections": [
                          v7
                        ]
                      }
                    ]
                  },
                  v8,
                  v9
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentImport",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentImportType",
                "plural": false,
                "selections": [
                  v2,
                  v10
                ]
              },
              v11
            ]
          },
          v12
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DocumentAcceptImportPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentToAcceptView",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentToAcceptPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentToAccept",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentImportAcceptType",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "document",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentType",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "bloc",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BlocType",
                    "plural": false,
                    "selections": v14
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "category",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryType",
                    "plural": false,
                    "selections": v14
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "author",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserType",
                    "plural": false,
                    "selections": [
                      v5,
                      v6,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "profile",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProfileType",
                        "plural": false,
                        "selections": [
                          v7,
                          v13
                        ]
                      },
                      v13
                    ]
                  },
                  v8,
                  v9,
                  v13
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentImport",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentImportType",
                "plural": false,
                "selections": [
                  v2,
                  v10,
                  v13
                ]
              },
              v11
            ]
          },
          v12
        ]
      }
    ]
  }
};
})();
node.text = "query DocumentAcceptImportPageQuery(\n  $documentImportPk: Int!\n) {\n  documentToAcceptView(documentImportPk: $documentImportPk) {\n    documentToAccept {\n      document {\n        pk\n        name\n        bloc {\n          name\n          id\n        }\n        category {\n          name\n          id\n        }\n        author {\n          firstName\n          lastName\n          profile {\n            title\n            id\n          }\n          id\n        }\n        expirationDate\n        revisionDates(forceShowAllRevisions: true)\n        id\n      }\n      documentImport {\n        pk\n        importFile {\n          name\n          url\n          zipName\n          zipUrl\n        }\n        id\n      }\n      policyActions\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'bebb4b12a12b1e98da3f8a7a32872989';
module.exports = node;
