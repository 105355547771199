import { Flex } from "grid-styled";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentContainer } from "pstat-anywhere/components/pages";
import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";
import Export from "pstat-anywhere/components/partials/export/Export";
import { TopNav } from "pstat-anywhere/components/partials/tertiary_nav";
import SitesTable from "pstat-anywhere/components/system_document_templates/SiteSelection";
import TemplateManagementPageCsvQuery from "pstat-anywhere/components/system_document_templates/TemplateManagementPageCsvQuery";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { SuccessBanner, WarningBanner } from "pstat-design-system/banner";
import { RouterLink } from "pstat-design-system/RouterLink";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";

const TemplateManagement = ({
  location,
  variables,
  route,
  router,
  systemDocumentTemplates
}) => {
  const { t } = useTranslation();
  const [publishing, setPublishing] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [publishedTemplateCount, setPublishedTemplateCount] = useState(0);
  const [publishedSiteCount, setPublishedSiteCount] = useState(0);
  const [showSites, setShowSites] = useState(false);
  const templates = systemDocumentTemplates?.systemDocumentTemplates;
  const tableProps = {
    templates,
    location,
    variables,
    route,
    router
  };
  const onSuccessfulPublishTemplates = (templateCount, siteCount) => {
    setPublishing(false);
    setShowSites(false);
    setShowSuccessBanner(true);
    setPublishedTemplateCount(templateCount);
    setPublishedSiteCount(siteCount);
  };
  return (
    <Fragment>
      {!showSites && (
        <TopNav>
          <Flex alignItems="center">
            <Button
              buttonStyle="primary"
              onClick={() => setPublishing(true)}
              disabled={publishing}
            >
              {t("systemDocumentTemplates.manage.publishToSitesButton")}
            </Button>
          </Flex>
        </TopNav>
      )}
      <ContentContainer>
        {publishing ? (
          <PublishingTemplates
            onSuccessfulPublishTemplates={onSuccessfulPublishTemplates}
            showSites={showSites}
            setShowSites={setShowSites}
            {...tableProps}
          />
        ) : (
          <Templates
            showSuccessBanner={showSuccessBanner}
            templateCount={publishedTemplateCount}
            siteCount={publishedSiteCount}
            {...tableProps}
          />
        )}
      </ContentContainer>
    </Fragment>
  );
};

export default TemplateManagement;

const TemplatesTable = ({
  templates,
  tableType,
  showTabsPanel,
  alwaysShowActionPanel,
  renderActionPanel,
  variables,
  route,
  router,
  location
}) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = useLabels();
  const tabs = [
    {
      id: "system_templates_manage",
      textKey: "systemDocumentTemplates.tab",
      path: ""
    }
  ];
  return (
    <AdvancedReportPage
      variables={variables}
      data={templates}
      route={route}
      router={router}
      location={location}
      tabs={tabs}
      headerText={t("systemDocumentTemplates.header")}
      renderHeader={() => (
        <Fragment>
          {templates && templates.totalCount ? (
            <Flex alignItems="center" pr={2}>
              <RouterLink to={`${getRootUrl()}/template/published`}>
                <Text color="secondary.0">
                  {t("systemDocumentTemplates.published.header", {
                    documentLabelPlural: titleCase(documentLabelPlural)
                  })}
                </Text>
              </RouterLink>
            </Flex>
          ) : null}
          <Export
            csvQuery={TemplateManagementPageCsvQuery}
            queryName={"systemDocumentTemplatesCsv"}
          />
        </Fragment>
      )}
      noContentMessage={t("systemDocumentTemplates.emptyMessage")}
      showTabsPanel={showTabsPanel}
      tableType={tableType}
      showPagination={true}
      customOnRow={() => {}}
      frozenColumnTable={true}
      showActionButtonPanel={true}
      singularSelectedActionsLabel={t("systemDocumentTemplates.template")}
      pluralSelectedActionsLabel={t("systemDocumentTemplates.templates")}
      renderActionPanel={renderActionPanel}
      alwaysShowActionPanel={alwaysShowActionPanel}
      actionButtonPanelNoneSelectedMessage={t(
        "systemDocumentTemplates.manage.selectTemplatePrompt"
      )}
    />
  );
};

const Templates = ({
  showSuccessBanner,
  templateCount,
  siteCount,
  ...otherProps
}) => {
  return (
    <Fragment>
      {showSuccessBanner && (
        <SuccessfullyPublishedBanner
          templateCount={templateCount}
          siteCount={siteCount}
        />
      )}
      <TemplatesTable
        tableType="manageTemplates"
        showTabsPanel={true}
        alwaysShowActionPanel={false}
        {...otherProps}
      />
    </Fragment>
  );
};

const PublishingTemplates = ({
  onSuccessfulPublishTemplates,
  showSites,
  setShowSites,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const templatesSelectedToPublish = useRef([]);
  const renderActionPanel = selectedTemplates => {
    const handleChooseSitesClick = () => {
      setShowSites(true);
      templatesSelectedToPublish.current = selectedTemplates;
    };
    return (
      <Button
        buttonStyle="primary"
        disabled={selectedTemplates.length === 0}
        onClick={handleChooseSitesClick}
      >
        {t("systemDocumentTemplates.manage.chooseDestinationSitesButton")}
      </Button>
    );
  };
  return (
    <Fragment>
      {showSites ? (
        <SitesTable
          templatesSelectedToPublish={templatesSelectedToPublish.current}
          handleBackToTemplates={() => setShowSites(false)}
          onSuccessfulPublishTemplates={(templateCount, siteCount) => {
            setShowSites(false);
            onSuccessfulPublishTemplates(templateCount, siteCount);
          }}
          {...otherProps}
        />
      ) : (
        <Fragment>
          <AlreadyPublishedBanner />
          <TemplatesTable
            tableType="manageTemplatesWithCheckboxes"
            showTabsPanel={false}
            alwaysShowActionPanel={true}
            renderActionPanel={renderActionPanel}
            {...otherProps}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const AlreadyPublishedBanner = () => {
  const { t } = useTranslation();
  return (
    <WarningBanner
      message={t("systemDocumentTemplates.manage.alreadyPublishedBanner")}
      width={null}
      mx={4}
      mt={6}
    />
  );
};

const SuccessfullyPublishedBanner = ({ templateCount, siteCount }) => {
  const { t } = useTranslation();
  const templateLabelKey =
    templateCount > 1
      ? "systemDocumentTemplates.templates"
      : "systemDocumentTemplates.template";
  const templateLabel = t(templateLabelKey);
  const siteLabelKey =
    siteCount > 1
      ? "systemDocumentTemplates.manage.sites.pluralSitesLabel"
      : "systemDocumentTemplates.manage.sites.singularSitesLabel";
  const siteLabel = t(siteLabelKey);
  const message =
    templateCount === 0 || siteCount === 0
      ? t("systemDocumentTemplates.manage.sites.publishSuccessNoTemplates")
      : t("systemDocumentTemplates.manage.sites.publishSuccess", {
          templateCount,
          templateLabel,
          siteCount,
          siteLabel
        });
  return <SuccessBanner message={message} width={null} mx={4} mt={6} />;
};
