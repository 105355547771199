import { Redirect, Route } from "found";


import CreatePolicyPage from "./create_policy/CreatePolicyPage";
import CreatePolicyQuery from "./create_policy/CreatePolicyQuery";
import DeletePolicy from "./delete_policy/DeletePolicyPage";
import EditPolicyPage from "./edit_policy/EditPolicyPage";
import EditPolicyQuery from "./edit_policy/EditPolicyQuery";
import OverridePolicyPage from "./override_policy/OverridePolicyPage";
import OverridePolicyQuery from "./override_policy/OverridePolicyQuery";
import PolicyRevisions from "./policy_revisions/PolicyRevisions";
import PolicyRevisionsQuery from "./policy_revisions/PolicyRevisionsQuery";
import RetirePolicyPage from "./retire_policy/RetirePolicyPage";
import RetirePolicyQuery from "./retire_policy/RetirePolicyQuery";
import UnsubscribedFromCommentsPage from "./view_policy/UnsubscribedFromCommentsPage";
import UnsubscribedFromCommentsQuery from "./view_policy/UnsubscribedFromCommentsQuery";
import ViewPolicy from "./view_policy/ViewPolicy";
import ViewPolicyPage from "./view_policy/ViewPolicyPage";
import ViewPolicyQuery from "./view_policy/ViewPolicyQuery";

import { checkForRedirect, getRootUrl } from "pstat-anywhere/utils/urls";
import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";
import { LoadingPage, PageWrapper } from "pstat-anywhere/utils/pageWrappers";

const routes = (
  <Route>
    <Route
      path="policy/create"
      query={CreatePolicyQuery}
      data={{
        routeName: "document_create"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        templatePk: location.query.template
          ? `${location.query.template}`
          : null,
        documentSavePk: location.query.documentSavePk
          ? `${location.query.documentSavePk}`
          : null
      })}
      render={({ props }) => {
        const queryName = "documentCreate";
        checkForRedirect(props, queryName);

        return (
          <PageWrapper
            component={CreatePolicyPage}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            titleKey="documentControl.create.documentTitle"
            {...props}
          />
        );
      }}
    />
    {/*
      Create a different path for creating a new document from the nav so that
      if already on the create page it will reload the page.
      Previously navigating to the create page wouldn't reload the page if already there.
    */}
    <Redirect
      from="policy/create/new"
      to={match => {
        const search = match.location.search;
        let path = `${getRootUrl()}/policy/create`;
        if (search) {
          path += search;
        }
        return path;
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/create"
      Component={CreatePolicyPage}
      query={CreatePolicyQuery}
      data={{
        routeName: "document_create"
      }}
      render={({ props }) => {
        checkForRedirect(props, "documentCreate");
        return (
          <ErrorCheckWrapper queryName="documentCreate" {...props}>
            <CreatePolicyPage {...props} />
          </ErrorCheckWrapper>
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/edit"
      query={EditPolicyQuery}
      data={{
        routeName: "document_edit"
      }}
      prepareVariables={(params, { location }) => ({
        ...params,
        continueDraft: !!location.query.continue_draft,
        isEditPage: true
      })}
      render={({ props }) => {
        const queryName = "documentEdit";
        checkForRedirect(props, queryName, true);

        return (
          <PageWrapper
            component={EditPolicyPage}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            titleKey="documentControl.edit.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/admin_override"
      query={OverridePolicyQuery}
      data={{
        routeName: "document_override"
      }}
      render={({ props }) => {
        const queryName = "documentOverride";
        checkForRedirect(props, queryName);

        return (
          <PageWrapper
            component={OverridePolicyPage}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            titleKey="documentControl.override.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/approve"
      query={ViewPolicyQuery}
      data={{
        routeName: "document_approve"
      }}
      prepareVariables={(params, { location }) => {
        let { showchanges } = location.query;
        showchanges = !!showchanges;
        return {
          ...params,
          showChanges: showchanges,
          latest: false,
          ignoreLatestRedirect: true
        };
      }}
      render={({ props }) => {
        const queryName = "documentView";
        checkForRedirect(props, queryName);

        return (
          <PageWrapper
            component={ViewPolicy}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            showLoading={!(props && props.documentView)}
            titleKey="documentControl.approve.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/latest"
      query={ViewPolicyQuery}
      data={{
        routeName: "document_view"
      }}
      prepareVariables={(params, { location }) => {
        let { showchanges } = location.query;
        showchanges = !!showchanges;
        return {
          ...params,
          showChanges: showchanges,
          latest: true
        };
      }}
      render={({ props }) => {
        const queryName = "documentView";
        checkForRedirect(props, queryName, true);

        return (
          <PageWrapper
            component={ViewPolicyPage}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            titleKey="documentControl.view.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)"
      query={ViewPolicyQuery}
      data={{
        routeName: "document_view"
      }}
      prepareVariables={(params, { location }) => {
        let { showchanges } = location.query;
        showchanges = !!showchanges;
        return {
          ...params,
          showChanges: showchanges,
          latest: false
        };
      }}
      render={({ props }) => {
        const queryName = "documentView";
        checkForRedirect(props, queryName, true);

        return (
          <PageWrapper
            component={ViewPolicyPage}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            titleKey="documentControl.view.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/delete"
      query={ViewPolicyQuery}
      data={{
        routeName: "document_delete"
      }}
      prepareVariables={params => {
        return {
          ...params,
          latest: false,
          ignoreLatestRedirect: true
        };
      }}
      render={({ props }) => {
        const queryName = "documentView";
        checkForRedirect(props, queryName);

        return (
          <PageWrapper
            component={DeletePolicy}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            showLoading={!(props && props.documentView)}
            titleKey="documentControl.delete.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/revisions"
      query={PolicyRevisionsQuery}
      data={{
        routeName: "document_revisions"
      }}
      render={({ props }) => {
        const queryName = "documentRevisions";
        const versionsQtty = props?.documentRevisions?.versions?.edges?.length;

        checkForRedirect(props, queryName);
        return (
          <PageWrapper
            component={PolicyRevisions}
            documentName={
              versionsQtty > 0
                ? props.documentRevisions.versions.edges[versionsQtty - 1].node
                    .name
                : ""
            }
            queryName={queryName}
            titleKey="documentControl.revisions.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/retire"
      query={RetirePolicyQuery}
      data={{
        routeName: "document_retire"
      }}
      render={({ props }) => {
        const queryName = "documentRetire";
        checkForRedirect(props, queryName);

        return (
          <PageWrapper
            component={RetirePolicyPage}
            documentName={props ? props[queryName]?.document?.name : ""}
            queryName={queryName}
            showLoading={!(props && props.documentRetire)}
            titleKey="documentControl.retire.documentTitle"
            {...props}
          />
        );
      }}
    />
    <Route
      path="policy/:documentPk(\d+)/commentsunsubscribed"
      query={UnsubscribedFromCommentsQuery}
      data={{
        routeName: "document_unsubscribed_from_comments"
      }}
      render={({ props }) => {
        if (props && props.unsubscribedDocument) {
          return <UnsubscribedFromCommentsPage {...props} />;
        }
      }}
    />
  </Route>
);

export default routes;

export const noNavDocumentControlRoutes = (
  <Route
    path="policy/:documentPk(\d+)/print"
    data={{
      routeName: "document_control_print"
    }}
    render={() => <LoadingPage id="print_loading_modal" />}
  />
);
