import { useState } from "react";
import { useTranslation } from "react-i18next";

import Select from "pstat-design-system/inputs/SelectInput";

const SUPPORTED_LANGUAGES = ["en", "fr"];
// i18n.languages would contain only 1 language on initial load.
// So, put all supported languages for easy mapping in const array

export default function LanguageSelector() {
  const { i18n, t } = useTranslation();
  const [value, setValue] = useState(i18n.language);
  const handleLanguageChange = ({ target }) => {
    i18n.changeLanguage(target.value);
    setValue(target.value);
  };
  return (
    <Select
      value={value}
      name="language-switcher"
      onChange={handleLanguageChange}
      dataTestid="languageSelector"
      ariaLabel={t("nav.navBar.settingsMenu.languageSelector")}
    >
      {SUPPORTED_LANGUAGES.map(lang => (
        <option key={lang} value={lang}>
          {lang.toUpperCase()}
        </option>
      ))}
    </Select>
  );
}
