import graphql from "babel-plugin-relay/macro";

const EpStandardContentQuery = graphql`
  query EpStandardContentQuery($associationPk: Int!) {
    epContent(associationPk: $associationPk) {
      data {
        name
        description
        chapter
        body
        url
        printUrl
        standard
        validFromVersion
        associatedVersion {
          name
          description
          chapter
          standard
          body
          url
          printUrl
          validFromVersion
        }
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default EpStandardContentQuery;
