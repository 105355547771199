import { Box } from "grid-styled";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { isCreatePage } from "../utils";

import {
  ActionsDropdown,
  BottomNav,
  DropdownItem,
  NavOptions,
  TopNav
} from "pstat-anywhere/components/partials/tertiary_nav";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import {
  BanIcon,
  CautionIcon,
  DangerIcon,
  SuccessIcon
} from "pstat-design-system/Icons";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";


const NavBar = ({
  saving,
  error,
  noChangesMade,
  changesSaved,
  autosave,
  openSubmitModal,
  selectedFlowLoading,
  savePolicy,
  previewPolicy,
  toggleAutosave,
  isOverridePage,
  policyActions,
  route,
  document,
  type,
  deleteDraftToggle,
  newSavedDraftUrl,
  isClone
}) => (
  <Media query={{ minWidth: theme.breakpoints[1] }}>
    {isDesktop =>
      isDesktop ? (
        <TopNav>
          <Box py={1}>
            {isOverridePage ? (
              <OverrideButton
                noChangesMade={noChangesMade}
                handleClick={openSubmitModal}
                error={error}
              />
            ) : (
              <Fragment>
                <StartApproval
                  noChangesMade={noChangesMade}
                  handleClick={openSubmitModal}
                  error={error}
                  changesSaved={changesSaved}
                  selectedFlowLoading={selectedFlowLoading}
                  isCreate={isCreatePage(type) && !document}
                  isClone={isClone}
                />
                <SavePolicy
                  changesSaved={changesSaved}
                  handleClick={savePolicy}
                  noChangesMade={noChangesMade}
                  saving={saving}
                />
              </Fragment>
            )}
            <PreviewPolicy
              changesSaved={changesSaved}
              handleClick={previewPolicy}
              saving={saving}
              isOverridePage={isOverridePage}
            />
            <PolicyState
              error={error}
              noChangesMade={noChangesMade}
              autosave={autosave}
              changesSaved={changesSaved}
              saving={saving}
              isOverridePage={isOverridePage}
            />
            {!isOverridePage && (
              <ToggleAutoSave toggleFunc={toggleAutosave} autosave={autosave} />
            )}
          </Box>
          <NavOptions
            document={document}
            policyActions={policyActions}
            route={route}
            deleteDraftToggle={deleteDraftToggle}
            newSavedDraftUrl={newSavedDraftUrl}
          />
        </TopNav>
      ) : (
        <Fragment>
          {policyActions && (
            <TopNav>
              <NavOptions
                showCurrentPage={true}
                document={document}
                policyActions={policyActions}
                route={route}
                deleteDraftToggle={deleteDraftToggle}
                newSavedDraftUrl={newSavedDraftUrl}
              />
            </TopNav>
          )}
          <BottomNav>
            <Box py={1}>
              {isOverridePage ? (
                <OverrideButton
                  noChangesMade={noChangesMade}
                  handleClick={openSubmitModal}
                  error={error}
                  isDesktop={false}
                />
              ) : (
                <SavePolicy
                  changesSaved={changesSaved}
                  handleClick={savePolicy}
                  noChangesMade={noChangesMade}
                  saving={saving}
                />
              )}
              <ActionsDropdown>
                {!isOverridePage && (
                  <StartApproval
                    noChangesMade={noChangesMade}
                    handleClick={openSubmitModal}
                    error={error}
                    changesSaved={changesSaved}
                    selectedFlowLoading={selectedFlowLoading}
                    isDesktop={false}
                  />
                )}
                <PreviewPolicy
                  changesSaved={changesSaved}
                  handleClick={previewPolicy}
                  saving={saving}
                  isDesktop={false}
                  isOverridePage={isOverridePage}
                />
                {!isOverridePage && (
                  <ToggleAutoSave
                    toggleFunc={toggleAutosave}
                    autosave={autosave}
                    isDesktop={false}
                  />
                )}
              </ActionsDropdown>
              <PolicyState
                short={true}
                error={error}
                noChangesMade={noChangesMade}
                autosave={autosave}
                changesSaved={changesSaved}
                saving={saving}
                isOverridePage={isOverridePage}
              />
            </Box>
          </BottomNav>
        </Fragment>
      )
    }
  </Media>
);

NavBar.propTypes = {
  saving: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  noChangesMade: PropTypes.bool.isRequired,
  changesSaved: PropTypes.bool.isRequired,
  autosave: PropTypes.bool.isRequired,
  openSubmitModal: PropTypes.func.isRequired,
  savePolicy: PropTypes.func.isRequired,
  previewPolicy: PropTypes.func.isRequired,
  toggleAutosave: PropTypes.func.isRequired
};

export default NavBar;

export const PolicyState = ({
  short,
  error,
  noChangesMade,
  autosave,
  changesSaved,
  saving,
  isOverridePage
}) => {
  let content = "";
  if (!isOverridePage) {
    if (saving) {
      content = <SavingState autosave={autosave} short={short} />;
    } else if (noChangesMade && !changesSaved) {
      content = <NoChangesMadeState autosave={autosave} short={short} />;
    } else if (!noChangesMade && !changesSaved) {
      content = <NotSavedState autosave={autosave} short={short} />;
    } else if (changesSaved) {
      content = <SavedState autosave={autosave} short={short} />;
    }
  }
  if (error) {
    content = <ErrorState autosave={autosave} short={short} />;
  }
  return <PolicyStateContainer>{content}</PolicyStateContainer>;
};

const PolicyStateContainer = styled.span``;

const SavedStateContainer = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: ${themeGet("colors.messages.success")};
`;

const SavedState = ({ autosave, short }) => {
  const { t } = useTranslation();
  return (
    <SavedStateContainer>
      <SuccessIcon />
      {autosave
        ? short
          ? t("partials.tertiaryNav.savedState.autoShort")
          : t("partials.tertiaryNav.savedState.auto")
        : short
        ? t("partials.tertiaryNav.savedStateShort")
        : t("partials.tertiaryNav.savedState")}
    </SavedStateContainer>
  );
};

const NoChangesMadeStateContainer = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: hsl(218, 15%, 42%);
`;

const NoChangesMadeState = ({ autosave, short }) => {
  const { t } = useTranslation();
  return (
    <NoChangesMadeStateContainer>
      <BanIcon />
      {autosave
        ? short
          ? t("partials.tertiaryNav.noChanges.autoShort")
          : t("partials.tertiaryNav.noChanges.auto")
        : short
        ? t("partials.tertiaryNav.noChangesShort")
        : t("partials.tertiaryNav.noChanges")}
    </NoChangesMadeStateContainer>
  );
};

const ErrorStateContainer = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: ${themeGet("colors.messages.error")};
`;

const ErrorState = ({ autosave, short }) => {
  const { t } = useTranslation();
  return (
    <ErrorStateContainer>
      <DangerIcon />
      {autosave
        ? short
          ? t("partials.tertiaryNav.errorState.autoShort")
          : t("partials.tertiaryNav.errorState.auto")
        : short
        ? t("partials.tertiaryNav.errorStateShort")
        : t("partials.tertiaryNav.errorState")}
    </ErrorStateContainer>
  );
};

const NotSavedStateContainer = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: ${themeGet("colors.messages.warning")};
`;

const NotSavedState = ({ autosave, short }) => {
  const { t } = useTranslation();
  return (
    <NotSavedStateContainer>
      <CautionIcon />
      {autosave
        ? short
          ? t("partials.tertiaryNav.notSavedState.autoShort")
          : t("partials.tertiaryNav.notSavedState.auto")
        : short
        ? t("partials.tertiaryNav.notSavedStateShort")
        : t("partials.tertiaryNav.notSavedState")}
    </NotSavedStateContainer>
  );
};

const SavingStateContainer = styled.span`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: #5c687c;
`;

const SavingState = ({ autosave, short }) => {
  const { t } = useTranslation();
  return (
    <SavingStateContainer>
      <LoadingSpinner />
      {autosave
        ? short
          ? t("partials.tertiaryNav.savingState.autoShort")
          : t("partials.tertiaryNav.savingState.auto")
        : short
        ? t("partials.tertiaryNav.savingStateShort")
        : t("partials.tertiaryNav.savingState")}
    </SavingStateContainer>
  );
};

const ToggleAutoSaveContainer = styled.span`
  color: ${themeGet("colors.secondary.0")};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
`;

const FocusBorderSpan = styled(Text)`
  border: 1px dashed;
  border-color: transparent;
  border-radius: ${themeGet("radii.1")}px;
  padding: ${themeGet("space.1")}px;
  &:focus {
    outline: 0;
  }
  ${ToggleAutoSaveContainer}:focus > & {
    border: dashed 1px ${themeGet("nav.25")};
  }
`;

export const ToggleAutoSave = ({ autosave, toggleFunc, isDesktop = true }) => {
  const { t } = useTranslation();
  const keyboardToggle = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleFunc();
    }
  };

  if (isDesktop) {
    return (
      <ToggleAutoSaveContainer
        tabIndex="0"
        onClick={toggleFunc}
        onKeyDown={keyboardToggle}
        data-testid="toggle-autosave-container"
      >
        <FocusBorderSpan tabIndex={-1}>
          {autosave
            ? t("partials.tertiaryNav.toggleAutoSave.disable")
            : t("partials.tertiaryNav.toggleAutoSave.enable")}
        </FocusBorderSpan>
      </ToggleAutoSaveContainer>
    );
  }
  return (
    <DropdownItem onClick={toggleFunc}>
      {autosave
        ? t("partials.tertiaryNav.toggleAutoSave.disable")
        : t("partials.tertiaryNav.toggleAutoSave.enable")}
    </DropdownItem>
  );
};

const StartApprovalContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export const StartApproval = ({
  noChangesMade,
  changesSaved,
  handleClick,
  error,
  selectedFlowLoading,
  isDesktop = true,
  isCreate = false,
  isClone = false
}) => {
  const { t } = useTranslation();
  const initOpen = noChangesMade && !changesSaved && !selectedFlowLoading;

  let disabled =
    initOpen && !isCreate && !isClone
      ? false
      : !changesSaved || error || selectedFlowLoading;

  const handleClickIfNotDisabled = event => {
    if (!disabled) {
      handleClick(event);
    }
  };
  if (isDesktop) {
    return (
      <SubmitButton
        disabled={disabled}
        onClick={handleClickIfNotDisabled}
        testId="start-approval-button"
      >
        {t("partials.tertiaryNav.startApproval")}
      </SubmitButton>
    );
  }
  return (
    <DropdownItem
      onClick={handleClickIfNotDisabled}
      disabled={disabled}
      testId="start-approval-button"
    >
      {t("partials.tertiaryNav.startApproval")}
    </DropdownItem>
  );
};

const _OverrideButton = ({
  noChangesMade,
  handleClick,
  error,
  isDesktop = true,
  labels
}) => {
  const { t } = useTranslation();
  const disabled = noChangesMade || error;
  const handleClickIfNotDisabled = event => {
    if (!disabled) {
      handleClick(event);
    }
  };
  let buttonText = t("documentControl.override.button", {
    documentLabel: labels.documentLabel
  });
  if (!isDesktop) {
    buttonText = t("documentControl.override.sideBar.title");
  }
  return (
    <SubmitButton disabled={disabled} onClick={handleClickIfNotDisabled}>
      {buttonText}
    </SubmitButton>
  );
};

export const OverrideButton = withLabelContext(_OverrideButton);

const SubmitButton = ({ disabled, onClick, children, testId }) => (
  <StartApprovalContainer>
    <Button
      buttonStyle="primary"
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </Button>
  </StartApprovalContainer>
);

const SavePolicyContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export const SavePolicy = ({
  changesSaved,
  handleClick,
  noChangesMade,
  saving
}) => {
  const { t } = useTranslation();
  return (
    <SavePolicyContainer>
      {changesSaved || noChangesMade || saving ? (
        <Button
          buttonStyle="secondary"
          disabled={true}
          data-testid="save-policy-button"
        >
          {t("buttons.save")}
        </Button>
      ) : (
        <Button
          buttonStyle="secondary"
          onClick={handleClick}
          data-testid="save-policy-button"
        >
          {t("buttons.save")}
        </Button>
      )}
    </SavePolicyContainer>
  );
};

const PreviewPolicyContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
`;

export const PreviewPolicy = ({
  changesSaved,
  handleClick,
  saving,
  isDesktop = true,
  isOverridePage
}) => {
  const { t } = useTranslation();
  const disabled = !isOverridePage && (!changesSaved || saving);
  const handleClickIfNotDisabled = event => {
    if (!disabled) {
      handleClick(event);
    }
  };
  if (isDesktop) {
    return (
      <PreviewPolicyContainer>
        <Button
          buttonStyle="tertiary"
          onClick={handleClick}
          disabled={disabled}
          data-testid="preview-policy-button"
        >
          {t("tables.labels.preview")}
        </Button>
      </PreviewPolicyContainer>
    );
  }
  return (
    <DropdownItem onClick={handleClickIfNotDisabled} disabled={disabled}>
      {t("tables.labels.preview")}
    </DropdownItem>
  );
};
