import { Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { themeGet } from "styled-system";
import system from "system-components";

import { H2 } from "./text";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

export const PanelWrapper = system(
  {
    borderRadius: 1,
    bg: ["transparent", "transparent", "nav.100"],
    px: [0, 0, 6],
    pt: [0, 0, 6], // need pt and pb to be separate props so that pb can be passed in as prop to panel
    pb: [0, 0, 6],
    mb: [0, 0, 6]
  },
  "borderTop",
  "borderColor",
  "height"
).extend`
  @media (min-width: ${themeGet("breakpoints.1")}) {
    box-shadow: ${themeGet("shadows.card")};
  }
  ${props =>
    props.noTopRadius &&
    "border-top-left-radius: 0; border-top-right-radius: 0;"}
  ${props =>
    props.noBottomRadius &&
    "border-bottom-left-radius: 0; border-bottom-right-radius: 0;"}
`;

PanelWrapper.displayName = "PanelWrapper";

export const PanelHeader = system({
  is: "header",
  fontSize: 2,
  px: [3, 3, 0],
  pt: [3, 3, 0],
  pb: [3, 3, 4],
  bg: ["transparent", "transparent", "nav.100"]
}).extend`
  @media (min-width: ${themeGet("breakpoints.1")}) {
    border-bottom: ${themeGet("borders.1")} ${themeGet("colors.nav.80")};
  }
`;

PanelHeader.displayName = "PanelHeader";

const HiddenBorderPanelHeader = system({
  is: PanelHeader,
  pb: [3, 3, 0],
  borderBottom: 0
});

export const PanelContent = system({
  bg: "nav.100",
  p: [3, 3, 0],
  mt: [0, 0, 4]
}).extend`
  counter-reset: toc-autonumber-0 toc-autonumber-1 toc-autonumber-2 toc-autonumber-3 toc-autonumber-4 toc-autonumber-5;
  box-shadow: ${themeGet("shadows.card")};
  @media (min-width: ${themeGet("breakpoints.1")}) {
    box-shadow: none;
  }
`;

PanelContent.displayName = "PanelContent";

const defaultRenderHeader = ({ name }) => (
  <H2 color="nav.0" fontWeight="normal">
    {name}
  </H2>
);

const Panel = ({
  renderHeader = defaultRenderHeader,
  children,
  id,
  isOpen = true,
  hideHeaderBorder = false,
  containerId,
  wrapperProps,
  ...props
}) => (
  <PanelWrapper id={id} {...wrapperProps}>
    {isOpen ? (
      <Fragment>
        {hideHeaderBorder ? (
          <HiddenBorderPanelHeader>
            {renderHeader({ isOpen, ...props })}
          </HiddenBorderPanelHeader>
        ) : (
          <PanelHeader>{renderHeader({ isOpen, ...props })}</PanelHeader>
        )}
      </Fragment>
    ) : (
      <HiddenBorderPanelHeader>
        {renderHeader({ isOpen, ...props })}
      </HiddenBorderPanelHeader>
    )}
    <div id={containerId}>
      {isOpen && <PanelContent>{children}</PanelContent>}
    </div>
  </PanelWrapper>
);

export default Panel;

const HeaderContainer = system({
  display: "inline-flex",
  alignItems: "center",
  tabIndex: "0"
}).extend`
  cursor: pointer;
`;

const Header = props => {
  const iconName = props.expanded ? "minus-square" : "plus-square";

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      props.toggleExpand();
    }
  };
  return (
    <Flex alignItems="center">
      <HeaderContainer
        onClick={props.toggleExpand}
        onKeyDown={handleKeyDown}
        aria-expanded={props.expanded}
        aria-controls={props.containerId}
      >
        <StyledFontAwesomeIcon
          icon={["far", iconName]}
          color="secondary.0"
          size="lg"
          mr="6px"
          verticalAlign="bottom"
        />
        <H2
          id={props.id}
          color="secondary.0"
          display="inline-block"
          fontWeight={400}
        >
          {props.headerText}
        </H2>
      </HeaderContainer>
      {props.children}
    </Flex>
  );
};

export const ExpandablePanel = ({
  id,
  children,
  headerChildren = () => {},
  headerText,
  hideHeaderBorder,
  expandedExternal,
  onExpandedChanged,
  wrapperProps
}) => {
  if (!id) {
    throw new Error("panel needs id");
  }
  const containerId = `${id}-expandable-section`;
  const [expanded, setExpanded] = useState(false);
  return (
    <Panel
      hideHeaderBorder={hideHeaderBorder}
      renderHeader={() => (
        <Header
          id={id}
          expanded={onExpandedChanged ? expandedExternal : expanded}
          toggleExpand={() => {
            if (onExpandedChanged) {
              onExpandedChanged(!expandedExternal);
            } else {
              setExpanded(!expanded);
            }
          }}
          headerText={headerText}
          containerId={containerId}
        >
          {headerChildren()}
        </Header>
      )}
      isOpen={onExpandedChanged ? expandedExternal : expanded}
      containerId={containerId}
      wrapperProps={wrapperProps}
    >
      {children}
    </Panel>
  );
};
