// @flow

import { useTranslation } from "react-i18next";

import ApprovalWorkflowContent, {
  APPROVAL_WORKFLOW_CONTENT_VARIATIONS
} from "../ApprovalWorkflowContent";

import Modal from "pstat-design-system/modal";

type Props = {
  isOpen: boolean,
  title: string,
  onUserRowClick?: Function, // Callback for emailing functionality
  steps: {
    title: string,
    users: {
      isDocumentOwner: boolean,
      firstName: string,
      lastName: string,
      hasApproved: boolean
    }[]
  }[],
  variation: $Values<APPROVAL_WORKFLOW_CONTENT_VARIATIONS>
};

export default function ApprovalWorkflowModal({
  isOpen,
  onClose,
  steps,
  title,
  variation = APPROVAL_WORKFLOW_CONTENT_VARIATIONS.VIEW_ONLY,
  onUserRowClick,
  currentStepId
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal
      id="approval_workflow_modal"
      isOpen={isOpen}
      contentLabel={t("documentControl.approvalWorkflow.modal.contentLabel")}
      onRequestClose={onClose}
    >
      <ApprovalWorkflowContent
        title={title}
        steps={steps}
        onUserRowClick={onUserRowClick}
        variation={variation}
        inModal={true}
        currentStepId={currentStepId}
      />
    </Modal>
  );
}
