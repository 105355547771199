/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Attachments_attachments$ref: FragmentReference;
export type Attachments_attachments = $ReadOnlyArray<{|
  +title: ?string,
  +fileUrl: ?string,
  +$refType: Attachments_attachments$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Attachments_attachments",
  "type": "DocumentAttachmentType",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fileUrl",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '559e8d3fa86800602fffd8df8c53afdf';
module.exports = node;
