/**
 * @flow
 * @relayHash 80fac3cf7d6370663901ef3e0bd2bb2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type SearchResultsQueryVariables = {|
  after?: ?string,
  authors?: ?$ReadOnlyArray<?number>,
  before?: ?string,
  categories?: ?$ReadOnlyArray<?number>,
  first?: ?number,
  last?: ?number,
  q?: ?string,
  references?: ?$ReadOnlyArray<?number>,
  sort?: ?string,
  titleOnly?: ?boolean,
|};
export type SearchResultsQueryResponse = {|
  +search: ?{|
    +searchResults: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +pk: ?number,
          +name: string,
          +preview: ?string,
          +hasAttachments: ?boolean,
          +bloc: {|
            +name: string,
            +pk: ?number,
          |},
          +applicabilityType: ?ApplicabilityType,
          +category: {|
            +name: string
          |},
          +effectiveDate: ?string,
          +legacyEffectiveDate: ?string,
          +revisionDate: ?string,
          +approvalDate: ?string,
          +restricted: boolean,
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +preview: ?string,
              +hasAttachments: ?boolean,
              +bloc: {|
                +name: string,
                +pk: ?number,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +effectiveDate: ?string,
              +legacyEffectiveDate: ?string,
              +revisionDate: ?string,
              +approvalDate: ?string,
              +restricted: boolean,
            |}>
          |},
        |}
      |}>,
    |},
    +guestAccessToken: ?string,
  |}
|};
export type SearchResultsQuery = {|
  variables: SearchResultsQueryVariables,
  response: SearchResultsQueryResponse,
|};
*/


/*
query SearchResultsQuery(
  $after: String
  $authors: [Int]
  $before: String
  $categories: [Int]
  $first: Int
  $last: Int
  $q: String
  $references: [Int]
  $sort: String
  $titleOnly: Boolean
) {
  search(authors: $authors, categories: $categories, q: $q, references: $references, sort: $sort, titleOnly: $titleOnly) {
    searchResults(after: $after, before: $before, first: $first, last: $last) {
      totalCount
      edges {
        node {
          id
          pk
          name
          preview
          hasAttachments
          bloc {
            name
            pk
            id
          }
          applicabilityType
          category {
            name
            id
          }
          effectiveDate
          legacyEffectiveDate
          revisionDate
          approvalDate
          restricted
          documentsOfTemplate {
            documents {
              pk
              name
              preview
              hasAttachments
              bloc {
                name
                pk
                id
              }
              applicabilityType
              category {
                name
                id
              }
              effectiveDate
              legacyEffectiveDate
              revisionDate
              approvalDate
              restricted
              id
            }
          }
        }
      }
    }
    guestAccessToken
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "authors",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categories",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "q",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "references",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "titleOnly",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "authors",
    "variableName": "authors",
    "type": "[Int]"
  },
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories",
    "type": "[Int]"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "references",
    "variableName": "references",
    "type": "[Int]"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "titleOnly",
    "variableName": "titleOnly",
    "type": "Boolean"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "preview",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bloc",
  "storageKey": null,
  "args": null,
  "concreteType": "BlocType",
  "plural": false,
  "selections": [
    v6,
    v5
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v6
  ]
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDate",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "guestAccessToken",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "bloc",
  "storageKey": null,
  "args": null,
  "concreteType": "BlocType",
  "plural": false,
  "selections": [
    v6,
    v5,
    v4
  ]
},
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": [
    v6,
    v4
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SearchResultsQuery",
  "id": "7546fb79fc36510e6947954fe93d1bb5",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SearchResultsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": v1,
        "concreteType": "SearchType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "searchResults",
            "storageKey": null,
            "args": v2,
            "concreteType": "SearchCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SearchCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      v12,
                      v13,
                      v14,
                      v15,
                      v16,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v5,
                              v6,
                              v7,
                              v8,
                              v9,
                              v10,
                              v11,
                              v12,
                              v13,
                              v14,
                              v15,
                              v16
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v17
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchResultsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": v1,
        "concreteType": "SearchType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "searchResults",
            "storageKey": null,
            "args": v2,
            "concreteType": "SearchCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SearchCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      v8,
                      v18,
                      v10,
                      v19,
                      v12,
                      v13,
                      v14,
                      v15,
                      v16,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v5,
                              v6,
                              v7,
                              v8,
                              v18,
                              v10,
                              v19,
                              v12,
                              v13,
                              v14,
                              v15,
                              v16,
                              v4
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v17
        ]
      }
    ]
  }
};
})();
node.text = "query SearchResultsQuery(\n  $after: String\n  $authors: [Int]\n  $before: String\n  $categories: [Int]\n  $first: Int\n  $last: Int\n  $q: String\n  $references: [Int]\n  $sort: String\n  $titleOnly: Boolean\n) {\n  search(authors: $authors, categories: $categories, q: $q, references: $references, sort: $sort, titleOnly: $titleOnly) {\n    searchResults(after: $after, before: $before, first: $first, last: $last) {\n      totalCount\n      edges {\n        node {\n          id\n          pk\n          name\n          preview\n          hasAttachments\n          bloc {\n            name\n            pk\n            id\n          }\n          applicabilityType\n          category {\n            name\n            id\n          }\n          effectiveDate\n          legacyEffectiveDate\n          revisionDate\n          approvalDate\n          restricted\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              preview\n              hasAttachments\n              bloc {\n                name\n                pk\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              effectiveDate\n              legacyEffectiveDate\n              revisionDate\n              approvalDate\n              restricted\n              id\n            }\n          }\n        }\n      }\n    }\n    guestAccessToken\n  }\n}\n";
(node/*: any*/).hash = 'bab4d48a7b2530196dae571e980683c0';
module.exports = node;
