/**
 * @flow
 * @relayHash 31ff646a8487e33378ccb9e0c57f15b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type newDocumentSaveAttachmentMutationVariables = {|
  documentSavePk: number,
  title: string,
  file: any,
|};
export type newDocumentSaveAttachmentMutationResponse = {|
  +newDocumentSaveAttachment: ?{|
    +ok: ?boolean
  |}
|};
export type newDocumentSaveAttachmentMutation = {|
  variables: newDocumentSaveAttachmentMutationVariables,
  response: newDocumentSaveAttachmentMutationResponse,
|};
*/


/*
mutation newDocumentSaveAttachmentMutation(
  $documentSavePk: Int!
  $title: String!
  $file: Upload!
) {
  newDocumentSaveAttachment(documentSavePk: $documentSavePk, title: $title, attachmentFile: $file) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentSavePk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "file",
    "type": "Upload!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "newDocumentSaveAttachment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attachmentFile",
        "variableName": "file",
        "type": "Upload!"
      },
      {
        "kind": "Variable",
        "name": "documentSavePk",
        "variableName": "documentSavePk",
        "type": "Int!"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title",
        "type": "String"
      }
    ],
    "concreteType": "NewDocumentSaveAttachmentMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "newDocumentSaveAttachmentMutation",
  "id": "94acf7f371995ae2549b9f0afbebabd0",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "newDocumentSaveAttachmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "newDocumentSaveAttachmentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation newDocumentSaveAttachmentMutation(\n  $documentSavePk: Int!\n  $title: String!\n  $file: Upload!\n) {\n  newDocumentSaveAttachment(documentSavePk: $documentSavePk, title: $title, attachmentFile: $file) {\n    ok\n  }\n}\n";
(node/*: any*/).hash = '0fa5dd2daed3130afa544aaa9554c315';
module.exports = node;
