export const userDisplay = (user, displayVariant = "default") => {
  if (!user) {
    return "";
  }
  let result = "";
  if (user.firstName || user.lastName) {
    if (!user.firstName) {
      result += user.lastName;
    } else if (!user.lastName) {
      result += user.firstName;
    } else {
      if (displayVariant === "reversedNames") {
        result += `${user.firstName} ${user.lastName}`;
      } else {
        result += `${user.lastName}, ${user.firstName}`;
      }
    }
  }
  const title = user.title || (user.profile && user.profile.title);
  if (title) {
    if (result === "") {
      result += title;
    } else {
      result += `: ${title}`;
    }
  }
  return result;
};
