import graphql from "babel-plugin-relay/macro";

export const DraftsPageCSVQuery = graphql`
  query DraftsPageCSVQuery($showAll: Boolean, $searchQuery: String) {
    draftsCsv(showAll: $showAll, searchQuery: $searchQuery) {
      taskId
    }
  }
`;

export default DraftsPageCSVQuery;
