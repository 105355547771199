// @flow
import { withRouter } from "found";
import { Component, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";

import { withSettings } from "../../context_providers/SettingsContext";
import { getRootUrl } from "../../utils/urls";

import { LoginFormContainer, LoginFormCustomerLogo, LoginWrapper } from "./Common";
import { doForgotPassword } from "./ForgotPasswordMutation";

import { Button } from "pstat-design-system/shared/Button";
import { createSuccessMessage } from "pstat-design-system/message/manageMessages";
import { InputFormElement } from "pstat-design-system/inputs/FormElements";

class _ForgotPasswordForm extends Component {
  constructor(props: Object) {
    super(props);
    this.recaptchaRef = createRef();

    this.state = {
      noChangesMade: true,
      errorFound: false,
      saving: false,
      formError: {
        email: ""
      },
      forgotForm: {
        email: ""
      },
      reasons: []
    };
  }

  handleChange = (event: Event) => {
    let forgotForm = Object.assign({}, this.state.forgotForm);
    const { name, value } = event.target;
    forgotForm[name] = value;
    this.setState({
      forgotForm,
      noChangesMade: false
    });
  };

  validateFields = () => {
    this.resetErrors();
    let isValid = true;
    const required = ["email"];

    const form = this.state.forgotForm;
    const error = Object.assign({}, this.state.formError);
    Object.keys(form).forEach(field => {
      if (required.includes(field)) {
        // Checking for blank fields that are required
        if (!form[field]) {
          error[field] = true;
          isValid = false;
        }
      }
      // More validations
    });
    this.setState({
      formError: error,
      errorFound: !isValid
    });
    return isValid;
  };

  resetErrors = () => {
    const error = Object.assign({}, this.state.formError);
    Object.keys(error).forEach(field => {
      error[field] = false;
    });
    this.setState({
      formError: error,
      errorFound: false
    });
  };

  formSubmit = e => {
    e.preventDefault();
    const { t } = this.props;
    this.setState({ saving: true, reasons: [] }, () => {
      if (this.validateFields()) {
        const form = this.state.forgotForm;
        const recaptchaValue = this.recaptchaRef.current.getValue();
        doForgotPassword(form.email, recaptchaValue, response => {
          const { ok, reasons } = response.forgotPassword;
          if (ok) {
            this.setState({
              saving: false,
              noChangesMade: true
            });
            createSuccessMessage(
              t("authentication.forgotPassword.onCompleted.successMessage", {
                email: form.email
              })
            );
            this.props.router.push(getRootUrl() || "/");
          } else if (reasons) {
            let allReasons = [];
            // Going trough all reasons to show them all
            Object.keys(reasons).forEach(reasonKey => {
              reasons[reasonKey].forEach(message => {
                allReasons.push(message);
              });
            });
            if (allReasons.length > 0) {
              this.setState({ reasons: allReasons });
            }
          }
        });
      } else {
        this.setState({
          saving: false,
          noChangesMade: false // when there are changes not saved
        });
      }
    });
    return false;
  };

  render() {
    const { t, settings } = this.props;
    const { forgotForm, formError } = this.state;
    return (
      <LoginFormContainer onSubmit={this.formSubmit}>
        <InputFormElement
          required={true}
          autosize
          value={forgotForm.email}
          label={t("authentication.forgotPasswordForm.email")}
          onChange={this.handleChange}
          type="email"
          error={formError.email}
          id="email"
          dataTestid="forgot-password-email"
        />
        <ReCAPTCHA
          ref={this.recaptchaRef}
          sitekey={settings.recaptchaPublicKey}
        />
        <Button
          focusBorderProps={{ width: "100%" }}
          width="100%"
          buttonStyle="secondary"
        >
          {t("buttons.forgotPassord")}
        </Button>
      </LoginFormContainer>
    );
  }
}
export const ForgotPasswordForm = withTranslation()(
  withSettings(withRouter(_ForgotPasswordForm))
);

export const ForgotPasswordPage = props => {
  return (
    <LoginFormCustomerLogo customerLogo={props.currentTenant?.customerLogo}>
      <LoginWrapper>
        <ForgotPasswordForm />
      </LoginWrapper>
    </LoginFormCustomerLogo>
  );
};
