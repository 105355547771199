import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer, fetchQuery } from "react-relay";

import ManyEpStandardContentQuery from "./ManyEpStandardContentQuery";
import VergeAssociatedDocumentsCSVQuery from "./VergeAssociatedDocumentsCSVQuery";

import { EpAssociationViewMenu } from "pstat-anywhere/components/integrations/verge/EpAssociationViewMenu";
import EpStandardContentQuery from "pstat-anywhere/components/integrations/verge/EpStandardContentQuery";
import { useEpViewMenu } from "pstat-anywhere/components/integrations/verge/hooks";
import { AdvancedReportPage } from "pstat-anywhere/components/pages/reports";
import { useEnvironment } from "pstat-anywhere/context_providers/EnvironmentContext";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import { useTenant } from "pstat-anywhere/context_providers/TenantContext";
import { PermissionDenied403Error } from "pstat-anywhere/errors";
import titleCase from "pstat-anywhere/utils/titleCase";


export const canAccessVergeAssociatedDocumentsReport = tenant => {
  return (
    tenant.vergeIntegrationEnabled && tenant.documentsWithStandardsReportEnabled
  );
};

const VergeAssociatedDocumentsReport = ({
  route,
  router,
  location,
  labels,
  ...props
}) => {
  const tenant = useTenant();
  const { documentLabelPlural } = useLabels();
  const { t } = useTranslation();
  const environment = useEnvironment();
  const [isLoadingStandard, setIsLoadingStandard] = useState(false);

  const [
    showEpAssociationViewMenu,
    epContent,
    openEpAssociationMenu,
    closeEpAssociationMenu
  ] = useEpViewMenu();

  const tableData = useMemo(() => {
    // TODO remove this condition when removing feature flag gh7450_standards_report
    if (!canAccessVergeAssociatedDocumentsReport(tenant)) {
      throw new PermissionDenied403Error();
    }

    const documents = props?.documentsWithVergeStandards?.documents;
    const manyEpsReponse = props?.manyEps;

    if (!documents || !manyEpsReponse) {
      return {
        totalCount: 0,
        edges: null
      };
    }

    // Transform array to a JSON/dict
    const eps = manyEpsReponse.data.reduce((acc, ep) => {
      acc[ep.documentLineEpAssociationId] = ep;
      return acc;
    }, {});

    const parsedDocuments = documents.edges.map(edge => ({
      node: {
        ...edge.node,
        epAssociationDocuments: edge.node.epAssociationDocuments.map(
          epAssociation => ({
            ...epAssociation,
            epContent: eps[epAssociation.pk]
          })
        )
      }
    }));

    return {
      totalCount: documents.totalCount,
      edges: parsedDocuments
    };
  }, [props?.documentsWithVergeStandards?.documents, props?.manyEps, tenant]);

  const loadEPContent = association => {
    setIsLoadingStandard(true);
    // show the standard name while we load the rest of the data
    openEpAssociationMenu({
      associationPk: association.pk,
      ...association.epContent
    });
    fetchQuery(environment, EpStandardContentQuery, {
      associationPk: association.pk
    })
      .then(response => {
        const { epContent } = response;
        if (!epContent) {
          openEpAssociationMenu(null);
          return;
        }
        setIsLoadingStandard(false);
        const { data, error } = epContent;
        if (error) {
          openEpAssociationMenu(null);
          return;
        }
        openEpAssociationMenu({ associationPk: association.pk, ...data });
      })
      .catch(() => {
        setIsLoadingStandard(false);
        openEpAssociationMenu(null);
      });
  };

  return (
    <Fragment>
      <AdvancedReportPage
        //Route
        route={route}
        router={router}
        location={location}
        // Content
        data={tableData}
        headerText={t("documentReports.associatedStandards.pageTitle", {
          documentLabelPlural: titleCase(documentLabelPlural)
        })}
        noContentMessage={t(
          "documentReports.associatedStandards.reportContent.noContentMessage",
          {
            documentLabelPlural: documentLabelPlural.toLowerCase()
          }
        )}
        tableType={"associated_standards_report"}
        // Tabs
        showTabsPanel={true}
        tabs={[
          {
            id: "associated_standards_report_all",
            textKey: "documentReports.associatedStandards.tabs.all",
            path: "policy_reports/standards/associated"
          }
        ]}
        // CSV
        csvQueryName="documentsWithVergeStandardsCsv"
        csvQueryArgs={{}}
        CSVQuery={VergeAssociatedDocumentsCSVQuery}
        otherTableProps={{ loadEPContent }}
      />
      {showEpAssociationViewMenu && (
        <EpAssociationViewMenu
          isLoading={isLoadingStandard}
          onClose={closeEpAssociationMenu}
          content={epContent}
        />
      )}
    </Fragment>
  );
};

const VergeAssociatedDocumentsReportWithQuery = wrapperProps => {
  const documents = wrapperProps?.documentsWithVergeStandards?.documents;
  const environment = useEnvironment();
  const associationsPk = [];

  if (documents) {
    documents.edges.forEach(edge => {
      const pks = edge.node.epAssociationDocuments.map(
        epAssociation => epAssociation.pk
      );

      associationsPk.push(...pks);
    });

    return (
      <QueryRenderer
        environment={environment}
        variables={{ associationsPk }}
        query={ManyEpStandardContentQuery}
        render={({ props }) => (
          <VergeAssociatedDocumentsReport {...wrapperProps} {...props} />
        )}
      />
    );
  }
  return <VergeAssociatedDocumentsReport {...wrapperProps} />;
};
export default VergeAssociatedDocumentsReportWithQuery;
