import graphql from "babel-plugin-relay/macro";

export const CreatePolicyQuery = graphql`
  query CreatePolicyQuery(
    $documentPk: Int
    $templatePk: Int
    $documentSavePk: Int
  ) {
    documentCreate(
      pk: $documentPk
      templatePk: $templatePk
      documentSavePk: $documentSavePk
    ) {
      document {
        pk
        name
        ...PolicyEditor_document
      }
      documentSave {
        ...PolicyEditor_documentSave
      }
      editForm {
        name
        author {
          pk
          firstName
          lastName
          title
        }
        category {
          pk
          name
        }
        categoryChoices {
          pk
          name
        }
        flowTemplate {
          pk
          name
        }
        flowTemplateChoices {
          pk
          name
        }
        bloc {
          pk
          name
        }
        blocChoices {
          pk
          name
        }
        html
        activeLifespan
        expirationDate
        isAForm
        restricted
        regulations
        onesourceDocuments {
          oneSourceDocumentId
          catalogNumber
          manufacturerName
          revisionInformation
          viewUrl
        }
      }
      tagForm {
        tags {
          pk
          name
        }
      }
      saveForm {
        hasSavedCopy
        continueDraft
        timestamp
      }
      scheduledEffectiveDateForm {
        effectiveDate
        effectiveDateTimedelta
        effectiveDateAlgorithm
      }
      systemDocumentTemplateForm {
        blocs {
          pk
          name
        }
      }
      error {
        statusCode
        message
      }
      ...PolicyEditor_editorProperties
      redirectUrl
      policyActions
      canLocalize(isCreatePage: true)
    }
  }
`;

export default CreatePolicyQuery;
