/**
 * @flow
 * @relayHash a1d2389530fbc370e53d7fb8bccfa5b4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NotificationsQueryVariables = {|
  first?: ?number,
  after?: ?string,
  before?: ?string,
  last?: ?number,
|};
export type NotificationsQueryResponse = {|
  +notifications: ?{|
    +totalCount: ?number,
    +unseenCount: ?number,
    +notificationRssFeedUrl: ?string,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +pk: ?number,
        +noticeType: {|
          +id: string,
          +label: string,
        |},
        +message: string,
        +added: any,
        +url: ?string,
        +unseen: boolean,
      |}
    |}>,
  |}
|};
export type NotificationsQuery = {|
  variables: NotificationsQueryVariables,
  response: NotificationsQueryResponse,
|};
*/


/*
query NotificationsQuery(
  $first: Int
  $after: String
  $before: String
  $last: Int
) {
  notifications(first: $first, after: $after, before: $before, last: $last) {
    totalCount
    unseenCount
    notificationRssFeedUrl
    edges {
      node {
        id
        pk
        noticeType {
          id
          label
        }
        message
        added
        url
        unseen
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "notifications",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last",
        "type": "Int"
      }
    ],
    "concreteType": "NoticeCountableConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "unseenCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "notificationRssFeedUrl",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "NoticeCountableEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "NoticeType",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "pk",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "noticeType",
                "storageKey": null,
                "args": null,
                "concreteType": "NoticeTypeType",
                "plural": false,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "added",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "unseen",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NotificationsQuery",
  "id": "b372758f19ccaaaaabe93171b1352a5a",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationsQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
node.text = "query NotificationsQuery(\n  $first: Int\n  $after: String\n  $before: String\n  $last: Int\n) {\n  notifications(first: $first, after: $after, before: $before, last: $last) {\n    totalCount\n    unseenCount\n    notificationRssFeedUrl\n    edges {\n      node {\n        id\n        pk\n        noticeType {\n          id\n          label\n        }\n        message\n        added\n        url\n        unseen\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = 'ee1d7e10ecbef32251e3657e791fb135';
module.exports = node;
