/**
 * @flow
 * @relayHash 3ddaa1e7fe57e1b73a3f108b2046404c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcknowledgePolicyMutationInput = {
  documentPk?: ?number,
  clientMutationId?: ?string,
};
export type AcknowledgePolicyMutationVariables = {|
  input: AcknowledgePolicyMutationInput
|};
export type AcknowledgePolicyMutationResponse = {|
  +acknowledgePolicy: ?{|
    +ok: ?boolean,
    +reasons: ?$ReadOnlyArray<?string>,
  |}
|};
export type AcknowledgePolicyMutation = {|
  variables: AcknowledgePolicyMutationVariables,
  response: AcknowledgePolicyMutationResponse,
|};
*/


/*
mutation AcknowledgePolicyMutation(
  $input: AcknowledgePolicyMutationInput!
) {
  acknowledgePolicy(input: $input) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AcknowledgePolicyMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "acknowledgePolicy",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "AcknowledgePolicyMutationInput!"
      }
    ],
    "concreteType": "AcknowledgePolicyMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "AcknowledgePolicyMutation",
  "id": "44321703312065c4b6f971fd61f9f676",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AcknowledgePolicyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "AcknowledgePolicyMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation AcknowledgePolicyMutation(\n  $input: AcknowledgePolicyMutationInput!\n) {\n  acknowledgePolicy(input: $input) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = '27b4fc20c5924c138051cf4afa622ac4';
module.exports = node;
