/**
 * @flow
 * @relayHash 0d5757357fd0b82f516a925dc748c8be
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StandardsAndRegulationsInputQueryVariables = {||};
export type StandardsAndRegulationsInputQueryResponse = {|
  +agenciesForTenant: ?$ReadOnlyArray<?{|
    +name: ?string,
    +isCap: ?boolean,
    +programs: ?$ReadOnlyArray<?{|
      +name: ?string,
      +category: ?{|
        +pk: ?number,
        +name: string,
      |},
      +regulations: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +name: string,
      |}>,
    |}>,
  |}>
|};
export type StandardsAndRegulationsInputQuery = {|
  variables: StandardsAndRegulationsInputQueryVariables,
  response: StandardsAndRegulationsInputQueryResponse,
|};
*/


/*
query StandardsAndRegulationsInputQuery {
  agenciesForTenant {
    name
    isCap
    programs {
      name
      category {
        pk
        name
        id
      }
      regulations {
        pk
        name
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isCap",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = [
  v2,
  v0
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  v2,
  v0,
  v4
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "StandardsAndRegulationsInputQuery",
  "id": "7f76dfd93d40938af7c93327898d9a3e",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "StandardsAndRegulationsInputQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "agenciesForTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "MockAgencyType",
        "plural": true,
        "selections": [
          v0,
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "programs",
            "storageKey": null,
            "args": null,
            "concreteType": "MockProgramType",
            "plural": true,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v3
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "regulations",
                "storageKey": null,
                "args": null,
                "concreteType": "RegulationType",
                "plural": true,
                "selections": v3
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StandardsAndRegulationsInputQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "agenciesForTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "MockAgencyType",
        "plural": true,
        "selections": [
          v0,
          v1,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "programs",
            "storageKey": null,
            "args": null,
            "concreteType": "MockProgramType",
            "plural": true,
            "selections": [
              v0,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v5
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "regulations",
                "storageKey": null,
                "args": null,
                "concreteType": "RegulationType",
                "plural": true,
                "selections": v5
              },
              v4
            ]
          },
          v4
        ]
      }
    ]
  }
};
})();
node.text = "query StandardsAndRegulationsInputQuery {\n  agenciesForTenant {\n    name\n    isCap\n    programs {\n      name\n      category {\n        pk\n        name\n        id\n      }\n      regulations {\n        pk\n        name\n        id\n      }\n      id\n    }\n    id\n  }\n}\n";
(node/*: any*/).hash = '2a69e12eec3b7e3f856fedb36bbe1261';
module.exports = node;
