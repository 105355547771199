import { createContext, forwardRef, useContext } from "react";

export const TenantContext = createContext({});

export const withTenantContext = Component =>
  forwardRef((props, ref) => (
    <TenantContext.Consumer>
      {tenant => <Component {...props} tenant={tenant} ref={ref} />}
    </TenantContext.Consumer>
  ));

export const useTenant = () => {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error("useTenant must be used within a TenantContext.Provider");
  }
  return context;
};
TenantContext.displayName = "TenantContext";
