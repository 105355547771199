/**
 * @flow
 * @relayHash 8eb53daf785dfda84192bcdfd13ce725
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ViewTemplatePage_template$ref = any;
export type ViewTemplateQueryVariables = {|
  pk: number
|};
export type ViewTemplateQueryResponse = {|
  +systemDocumentTemplateView: ?{|
    +systemDocumentTemplate: ?{|
      +$fragmentRefs: ViewTemplatePage_template$ref
    |},
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type ViewTemplateQuery = {|
  variables: ViewTemplateQueryVariables,
  response: ViewTemplateQueryResponse,
|};
*/


/*
query ViewTemplateQuery(
  $pk: Int!
) {
  systemDocumentTemplateView(pk: $pk) {
    systemDocumentTemplate {
      ...ViewTemplatePage_template
      id
    }
    error {
      statusCode
      message
    }
  }
}

fragment ViewTemplatePage_template on SystemDocumentTemplateType {
  name
  ...StatusBar_template
  ...TemplateProperties_template
  ...Content_template
  ...Attachments_template
}

fragment StatusBar_template on SystemDocumentTemplateType {
  pk
  status
  ...Info_template
}

fragment TemplateProperties_template on SystemDocumentTemplateType {
  headerImageUrl
  approvalDate(formatted: true)
  revisionDate(formatted: true)
  author {
    ...Properties_author
    id
  }
  category {
    ...Properties_category
    id
  }
  tags {
    ...Properties_tags
    id
  }
}

fragment Content_template on SystemDocumentTemplateType {
  name
  html
}

fragment Attachments_template on SystemDocumentTemplateType {
  attachments(excludeEmbedded: true) {
    ...Attachments_attachments
    id
  }
}

fragment Attachments_attachments on DocumentAttachmentType {
  title
  fileUrl
}

fragment Properties_author on UserType {
  pk
  userDisplay
  firstName
  lastName
  profile {
    title
    id
  }
}

fragment Properties_category on CategoryType {
  pk
  name
}

fragment Properties_tags on TagType {
  pk
  name
}

fragment Info_template on SystemDocumentTemplateType {
  pk
  status
  approvalDate(formatted: true)
  revisionDate(formatted: true)
  author {
    ...Properties_author
    id
  }
  category {
    ...Properties_category
    id
  }
  tags {
    ...Info_references
    id
  }
}

fragment Info_references on TagType {
  pk
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "pk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "formatted",
    "value": true,
    "type": "Boolean"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = [
  v4,
  v3,
  v7
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ViewTemplateQuery",
  "id": "09dd6e80b32e241d3ff48231a979d405",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ViewTemplateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "systemDocumentTemplateView",
        "storageKey": null,
        "args": v1,
        "concreteType": "SystemDocumentTemplateViewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "systemDocumentTemplate",
            "storageKey": null,
            "args": null,
            "concreteType": "SystemDocumentTemplateType",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "ViewTemplatePage_template",
                "args": null
              }
            ]
          },
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewTemplateQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "systemDocumentTemplateView",
        "storageKey": null,
        "args": v1,
        "concreteType": "SystemDocumentTemplateViewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "systemDocumentTemplate",
            "storageKey": null,
            "args": null,
            "concreteType": "SystemDocumentTemplateType",
            "plural": false,
            "selections": [
              v3,
              v4,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "approvalDate",
                "args": v5,
                "storageKey": "approvalDate(formatted:true)"
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "revisionDate",
                "args": v5,
                "storageKey": "revisionDate(formatted:true)"
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "author",
                "storageKey": null,
                "args": null,
                "concreteType": "UserType",
                "plural": false,
                "selections": [
                  v4,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "userDisplay",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "firstName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lastName",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "profile",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProfileType",
                    "plural": false,
                    "selections": [
                      v6,
                      v7
                    ]
                  },
                  v7
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "CategoryType",
                "plural": false,
                "selections": v8
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tags",
                "storageKey": null,
                "args": null,
                "concreteType": "TagType",
                "plural": true,
                "selections": v8
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "headerImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "html",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "attachments",
                "storageKey": "attachments(excludeEmbedded:true)",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeEmbedded",
                    "value": true,
                    "type": "Boolean"
                  }
                ],
                "concreteType": "DocumentAttachmentType",
                "plural": true,
                "selections": [
                  v6,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fileUrl",
                    "args": null,
                    "storageKey": null
                  },
                  v7
                ]
              },
              v7
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
node.text = "query ViewTemplateQuery(\n  $pk: Int!\n) {\n  systemDocumentTemplateView(pk: $pk) {\n    systemDocumentTemplate {\n      ...ViewTemplatePage_template\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n\nfragment ViewTemplatePage_template on SystemDocumentTemplateType {\n  name\n  ...StatusBar_template\n  ...TemplateProperties_template\n  ...Content_template\n  ...Attachments_template\n}\n\nfragment StatusBar_template on SystemDocumentTemplateType {\n  pk\n  status\n  ...Info_template\n}\n\nfragment TemplateProperties_template on SystemDocumentTemplateType {\n  headerImageUrl\n  approvalDate(formatted: true)\n  revisionDate(formatted: true)\n  author {\n    ...Properties_author\n    id\n  }\n  category {\n    ...Properties_category\n    id\n  }\n  tags {\n    ...Properties_tags\n    id\n  }\n}\n\nfragment Content_template on SystemDocumentTemplateType {\n  name\n  html\n}\n\nfragment Attachments_template on SystemDocumentTemplateType {\n  attachments(excludeEmbedded: true) {\n    ...Attachments_attachments\n    id\n  }\n}\n\nfragment Attachments_attachments on DocumentAttachmentType {\n  title\n  fileUrl\n}\n\nfragment Properties_author on UserType {\n  pk\n  userDisplay\n  firstName\n  lastName\n  profile {\n    title\n    id\n  }\n}\n\nfragment Properties_category on CategoryType {\n  pk\n  name\n}\n\nfragment Properties_tags on TagType {\n  pk\n  name\n}\n\nfragment Info_template on SystemDocumentTemplateType {\n  pk\n  status\n  approvalDate(formatted: true)\n  revisionDate(formatted: true)\n  author {\n    ...Properties_author\n    id\n  }\n  category {\n    ...Properties_category\n    id\n  }\n  tags {\n    ...Info_references\n    id\n  }\n}\n\nfragment Info_references on TagType {\n  pk\n  name\n}\n";
(node/*: any*/).hash = '28b56f57062de1a41613761fd5d60a35';
module.exports = node;
