/**
 * @flow
 * @relayHash 958fc86054d6601ca0c2795947c3a599
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReviewType = "TEMPLATE" | "TIME" | "%future added value";
export type DocumentsForReviewCSVQueryVariables = {|
  justMine?: ?boolean,
  includePending?: ?boolean,
  categories?: ?$ReadOnlyArray<?number>,
  daysUntilExpiration?: ?number,
  reviewType?: ?ReviewType,
|};
export type DocumentsForReviewCSVQueryResponse = {|
  +documentsToReviewCsv: ?{|
    +taskId: ?string
  |}
|};
export type DocumentsForReviewCSVQuery = {|
  variables: DocumentsForReviewCSVQueryVariables,
  response: DocumentsForReviewCSVQueryResponse,
|};
*/


/*
query DocumentsForReviewCSVQuery(
  $justMine: Boolean
  $includePending: Boolean
  $categories: [Int]
  $daysUntilExpiration: Int
  $reviewType: ReviewType
) {
  documentsToReviewCsv(justMine: $justMine, includePending: $includePending, categories: $categories, daysUntilExpiration: $daysUntilExpiration, reviewType: $reviewType) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "justMine",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includePending",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categories",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "daysUntilExpiration",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reviewType",
    "type": "ReviewType",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentsToReviewCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "categories",
        "variableName": "categories",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "daysUntilExpiration",
        "variableName": "daysUntilExpiration",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "includePending",
        "variableName": "includePending",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "justMine",
        "variableName": "justMine",
        "type": "Boolean"
      },
      {
        "kind": "Variable",
        "name": "reviewType",
        "variableName": "reviewType",
        "type": "ReviewType"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DocumentsForReviewCSVQuery",
  "id": "77b1864d31e73376e93710c59059a8ed",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DocumentsForReviewCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DocumentsForReviewCSVQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query DocumentsForReviewCSVQuery(\n  $justMine: Boolean\n  $includePending: Boolean\n  $categories: [Int]\n  $daysUntilExpiration: Int\n  $reviewType: ReviewType\n) {\n  documentsToReviewCsv(justMine: $justMine, includePending: $includePending, categories: $categories, daysUntilExpiration: $daysUntilExpiration, reviewType: $reviewType) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '8cd14254780098902efe93e9080ad75e';
module.exports = node;
