import { Box, Flex } from "grid-styled";
import { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";

import { doAssignAcknowledgment } from "pstat-anywhere/components/acknowledgments/assign/AssignAcknowledgmentMutation";
import AssignAcknowledgment from "pstat-anywhere/components/acknowledgments/assign/AssignAcknowledgments";
import AcknowledgementListQuery from "pstat-anywhere/components/acknowledgments/list/AcknowledgementListQuery";
import AcknowledgementsList from "pstat-anywhere/components/acknowledgments/list/AcknowledgementsList";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import { ExpandablePanel } from "pstat-design-system/Panel";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

const _HeaderInfo = ({ t, labels }) => (
  <Fragment>
    <StyledFontAwesomeIcon
      icon={["fal", "question-circle"]}
      color="secondary.0"
      size="lg"
      mx={2}
      data-tip
      data-event={HOVER_EVENTS}
      data-event-off={HOVER_EVENTS_OFF}
      data-for="recurring-ack-panel-tooltip"
    />
    <Tooltip name="recurring-ack-panel-tooltip">
      {t("acknowledgments.panel.tooltip", {
        documentLabel: labels.documentLabel.toLowerCase()
      })}
    </Tooltip>
  </Fragment>
);

const HeaderInfo = withLabelContext(withTranslation()(_HeaderInfo));

const AcknowledgmentsPanel = ({
  t,
  documentId,
  linePk,
  expanded,
  onExpandedChanged
}) => {
  const [users, setUsers] = useState(null);
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    if (!users && !groups) {
      fetchQuery(environment, AcknowledgementListQuery, { linePk }).then(
        data => {
          if (!data) {
            return;
          }
          const {
            users,
            userGroups
          } = data.userAndUserGroupsAcknowlegmentByLine;
          setUsers(users);
          setGroups(userGroups);
        }
      );
    }
  }, [users, groups, linePk]);

  const callbackAssignAck = (response, errors) => {
    showMsg(response, errors, t("acknowledgments.assign.successMessage"));
    clearData();
  };

  const showMsg = (response, errors, msg) => {
    if (errors) {
      errors.forEach(error => {
        createErrorMessage(error);
      });
    } else {
      createSuccessMessage(msg);
    }
  };

  const getSelectedObjectIdsByType = (objects, type) => {
    return objects
      .filter(obj => obj.type === type)
      .map(obj => parseInt(obj.id));
  };

  const handleAssignAcknowledgments = ({
    selectedSuggestions,
    assignAllVersions
  }) => {
    const userIds = getSelectedObjectIdsByType(selectedSuggestions, "user");
    const groupIds = getSelectedObjectIdsByType(selectedSuggestions, "group");

    doAssignAcknowledgment({
      userIds,
      groupIds,
      docIds: [documentId],
      includeRecurring: assignAllVersions,
      callback: callbackAssignAck
    });
  };

  const clearData = () => {
    setUsers(null);
    setGroups(null);
  };

  const showDeletedMsg = (response, errors) => {
    showMsg(response, errors, t("acknowledgments.delete.successMessage"));
  };

  const deleteUserItem = (response, errors, recurringAckPk) => {
    showDeletedMsg(response, errors);
    setUsers(users.filter(user => user.recurringAckPk !== recurringAckPk));
  };

  const deleteGroupItem = (response, errors, recurringAckPk) => {
    showDeletedMsg(response, errors);
    setGroups(groups.filter(group => group.recurringAckPk !== recurringAckPk));
  };

  return (
    <ExpandablePanel
      headerText={t("acknowledgments.panel.header")}
      id="acknowledgments-panel"
      expandedExternal={expanded}
      onExpandedChanged={onExpandedChanged}
      headerChildren={() => <HeaderInfo />}
    >
      <Flex flexDirection="column">
        {users && groups ? (
          <AcknowledgementsList
            users={users}
            groups={groups}
            deleteUserCallback={deleteUserItem}
            deleteGroupCallback={deleteGroupItem}
          />
        ) : (
          <Flex justify="center" align="center" mt={10}>
            <Box>
              <LoadingSpinner size={50} />
            </Box>
          </Flex>
        )}
        <AssignAcknowledgment onAssign={handleAssignAcknowledgments} />
      </Flex>
    </ExpandablePanel>
  );
};

export default withTranslation()(AcknowledgmentsPanel);
