// @flow
import PropTypes from "prop-types";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { QueryRenderer, fetchQuery } from "react-relay";
import styled from "styled-components";
import { themeGet } from "styled-system";
import system from "system-components";

import ApprovalWorkflowContent, {
  APPROVAL_WORKFLOW_CONTENT_VARIATIONS
} from "../../approve_policy/ApprovalWorkflowContent";
import { isCreatePage, isOverridePage } from "../utils";

import getWorkflowTemplateQuery from "./getWorkflowTemplateQuery";
import LocalizePolicy from "./LocalizePolicy";

import InconsistentExpirationDatesModal from "pstat-anywhere/components/document_control/override_policy/InconsistentExpirationDatesModal/InconsistentExpirationDatesModal.jsx";
import SearchResultsQuery from "pstat-anywhere/components/document_search/SearchResults/SearchResultsQuery";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { SiteSelectionQuery } from "pstat-anywhere/components/system_document_templates/SiteSelection";
import { useEnvironment } from "pstat-anywhere/context_providers/EnvironmentContext";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import {
  isActive,
  isOld,
  isPending,
  isRetired
} from "pstat-anywhere/utils/document";
import titleCase from "pstat-anywhere/utils/titleCase";
import {
  InputFormElement,
  LocationsFormInput,
  MultipleDateFormElement,
  RadioFormElementWithDate,
  RadioFormElementWithInput,
  RadioGroupFormElement,
  ReferenceTagsAutocompleteFormElement,
  SingleSelectDropdownFormElement,
  StandardsAndRegulationsFormElement,
  UserAutocompleteFormElement
} from "pstat-design-system/inputs/FormElements";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Panel from "pstat-design-system/Panel";
import { HorizontalScrollTable } from "pstat-design-system/tables";
import { Text } from "pstat-design-system/text";



export const ACTIVE_LIFESPAN = "activeLifespan";
export const EXPIRATION_DATE = "expirationDate";

const RequiredItem = system({
  is: Text,
  color: "messages.error"
}).extend`
  &:before {
    content: "*";
  }
`;

const FormElements = styled.div`
  margin-top: 21px;
`;

const WorkflowContainer = system({
  bg: "nav.95",
  width: "100%",
  border: 1,
  borderColor: "nav.80",
  borderRadius: 1,
  overflowX: "auto"
}).extend`
  overflow-x: auto;
`;

const WorkflowDetails = system({
  color: "secondary.0",
  display: "inline-block",
  mb: 2
}).extend`
  cursor: pointer;
`;

const WorkflowDetailsContainer = system({
  mb: 6
});

const WorkflowDiagram = ({
  selectedFlowPk,
  selectedFlow,
  onSelectedFlowChange,
  selectedFlowLoading,
  onSelectedFlowLoadingChange
}) => {
  const environment = useEnvironment();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const flowCache = useRef({});

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleToggle();
    }
  };
  useEffect(() => {
    const fetchFlow = () => {
      fetchQuery(environment, getWorkflowTemplateQuery, {
        pk: selectedFlowPk
      }).then(data => {
        if (!data) {
          onSelectedFlowLoadingChange(false);
          return;
        }
        flowCache.current[data.flowTemplate.pk] = data.flowTemplate;
        onSelectedFlowChange(data.flowTemplate);
        onSelectedFlowLoadingChange(false);
      });
    };
    if (selectedFlowPk) {
      const cachedFlow = flowCache.current[selectedFlowPk];
      if (cachedFlow) {
        onSelectedFlowChange(cachedFlow);
      } else {
        fetchFlow();
        onSelectedFlowLoadingChange(true);
      }
    }
  }, [
    selectedFlowPk,
    flowCache,
    onSelectedFlowChange,
    onSelectedFlowLoadingChange,
    environment
  ]);

  return (
    <Fragment>
      {(selectedFlow || selectedFlowLoading) && (
        <WorkflowDetailsContainer id="workflow_details">
          <WorkflowDetails
            tabIndex="0"
            onClick={handleToggle}
            onKeyDown={handleKeyDown}
          >
            <StyledFontAwesomeIcon
              icon={open ? ["far", "minus-square"] : ["far", "plus-square"]}
            />
            <Text color="secondary.0">
              {t("documentControl.edit.propertiesSection.workflowDetails")}
            </Text>
          </WorkflowDetails>
          {open && (
            <WorkflowContainer>
              {selectedFlowLoading ? (
                <p>Loading...</p>
              ) : (
                <ApprovalWorkflowContent
                  title={selectedFlow.name}
                  steps={selectedFlow.steps}
                  onUserRowClick={null}
                  variation={APPROVAL_WORKFLOW_CONTENT_VARIATIONS.VIEW_ONLY}
                  inModal={false}
                  currentStepId={null}
                />
              )}
            </WorkflowContainer>
          )}
        </WorkflowDetailsContainer>
      )}
    </Fragment>
  );
};

const HITS_PER_PAGE = 10;

const PointerText = styled(Text)`
  cursor: pointer;
`;

const PointerTextContainer = styled.div`
  margin-bottom: ${themeGet("space.4")}px;
`;

const ResultsContainer = styled.div`
  background-color: ${themeGet("colors.nav.95")};
  border-radius: ${themeGet("radii.1")}px;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: ${themeGet("space.6")}px;
`;

const TableContainer = styled.div`
  background-color: ${themeGet("colors.nav.100")};
  margin: ${themeGet("space.4")}px;
`;

const DuplicateCheckerToggleText = ({
  expanded,
  loading,
  onToggle,
  t,
  documentLabelPlural
}) => {
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      onToggle();
    }
  };
  return (
    <PointerTextContainer>
      <PointerText
        color="secondary.0"
        onClick={onToggle}
        onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        {expanded ? (
          <Fragment>
            <StyledFontAwesomeIcon icon={["far", "times"]} />
            {t("documentControl.create.duplicateChecker.close")}
          </Fragment>
        ) : (
          <Fragment>
            <StyledFontAwesomeIcon icon={["far", "check-double"]} />
            {t("documentControl.create.duplicateChecker.open", {
              documentLabelPlural
            })}
          </Fragment>
        )}
      </PointerText>
      {loading && (
        <div>
          <LoadingSpinner size={15} />
          <Text>
            {t("documentControl.create.duplicateChecker.loadingMessage")}
          </Text>
        </div>
      )}
    </PointerTextContainer>
  );
};

const DuplicateCheckerResults = ({
  query,
  loading,
  setLoading,
  t,
  documentLabelPlural
}) => {
  const fetchResultsCountdown = useRef(null);
  const firstFetch = useRef(true);
  const [documents, setDocuments] = useState([]);
  const environment = useEnvironment();

  useEffect(() => {
    const fetchDuplicates = () => {
      const searchVariables = {
        q: query,
        titleOnly: true,
        first: HITS_PER_PAGE
      };
      fetchQuery(environment, SearchResultsQuery, searchVariables).then(
        data => {
          const documents = data.search.searchResults.edges.map(edge => ({
            ...edge.node
          }));
          setDocuments(documents);
          setLoading(false);
        }
      );
    };
    clearTimeout(fetchResultsCountdown.current);
    setLoading(true);

    // if just expanded this, don't wait to fetch the duplicates
    if (firstFetch.current) {
      firstFetch.current = false;
      fetchDuplicates();
    } else {
      fetchResultsCountdown.current = setTimeout(fetchDuplicates, 2000);
    }
  }, [query, setLoading, environment]);

  return (
    <Fragment>
      {documents.length > 0 && (
        <ResultsContainer>
          {/* TODO: refactor tables so there is a normal table if don't want to sort */}
          <TableContainer>
            <HorizontalScrollTable
              tableType="search"
              policies={documents}
              numberToDisplay={HITS_PER_PAGE}
              noSort={true}
              onSortChange={() => {}}
              hasFrozenColumn={true}
            />
          </TableContainer>
        </ResultsContainer>
      )}
      {!loading && documents.length === 0 && (
        <TableContainer>
          <Text>
            {t("documentControl.create.duplicateChecker.noDuplicates", {
              documentLabelPlural
            })}
          </Text>
        </TableContainer>
      )}
    </Fragment>
  );
};

const DuplicateChecker = ({ query, labels }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const documentLabelPlural = labels.documentLabelPlural.toLowerCase();

  return (
    <Fragment>
      <DuplicateCheckerToggleText
        expanded={expanded}
        loading={loading}
        onToggle={() => setExpanded(!expanded)}
        t={t}
        documentLabelPlural={documentLabelPlural}
      />
      {expanded && (
        <DuplicateCheckerResults
          query={query}
          loading={loading}
          setLoading={setLoading}
          t={t}
          documentLabelPlural={documentLabelPlural}
        />
      )}
    </Fragment>
  );
};

const MemoedFormInput = memo(InputFormElement);
const AuthorInput = memo(UserAutocompleteFormElement);
const ReferenceTagInput = memo(ReferenceTagsAutocompleteFormElement);
const SingleSelectInput = memo(SingleSelectDropdownFormElement);

const PolicyProperties = ({
  document,
  type,
  policyForm,
  onNameChange,
  onNameClear,
  onAuthorChange,
  onAuthorClear,
  onCategoryChange,
  onCategoryClear,
  onFlowTemplateChange,
  onFlowTemplateClear,
  onBlocChange,
  onBlocClear,
  handleActiveLifespanDateCheck,
  onActiveLifespanChange,
  onExpirationDateChange,
  handleInconsistentExpirationDateUpdate,
  onReferencesChange,
  onReferencesClear,
  onApprovalDateChange,
  onOriginationDateChange,
  onEffectiveDateDatetimeChange,
  onLegacyEffectiveDateChange,
  onRegulationsChange,
  onRegulationsClear,
  onLocationsChange,
  onLocationsClear,
  onRevisionDatesChange,
  onRevisionDatesClear,
  errors,
  toggleRadioButtons,
  activeLifespanSelected,
  editForm,
  tagForm,
  preimportRevisionForm,
  selectedFlow,
  onSelectedFlowChange,
  selectedFlowLoading,
  onSelectedFlowLoadingChange,
  scheduledEffectiveDateEnabled,
  t,
  labels,
  tenant,
  basedOnSystemDocumentTemplate,
  canLocalize,
  isTemplate,
  convertingToTemplate,
  setConvertingToTemplate
}) => {
  const environment = useEnvironment();
  const [modalData, setModalData] = useState({
    isOpen: false,
    actualLifespanDays: null,
    newLifeSpanDays: null
  });
  // used to force re-render bloc input after the bloc is changed when the policy is localized
  // so that the new bloc name is shown in the input
  const [blocUpdated, setBlocUpdated] = useState(`bloc_${policyForm.bloc}`);

  // since the approval date is conditionally rendered,
  // this needs to separated to always be executed
  const blocChangeCallback = useCallback(
    event => onBlocChange(event.target.value),
    [onBlocChange]
  );
  const approvalDateChangeCallback = useCallback(
    event => {
      onApprovalDateChange(event.target.value);
    },
    [onApprovalDateChange]
  );
  const originationDateChangeCallback = useCallback(
    event => onOriginationDateChange(event.target.value),
    [onOriginationDateChange]
  );
  const onBlurInconsistentDateCheck = useCallback(
    event =>
      handleActiveLifespanDateCheck({
        newLifeSpanDays: event.target.value,
        newApprovalDate: null,
        setModalData
      }),
    [handleActiveLifespanDateCheck]
  );
  const effectiveDateDatetimeChangeCallback = useCallback(
    event => onEffectiveDateDatetimeChange(event.target.value),
    [onEffectiveDateDatetimeChange]
  );
  const legacyEffectiveDateChangeCallback = useCallback(
    event => onLegacyEffectiveDateChange(event.target.value),
    [onLegacyEffectiveDateChange]
  );
  const regulationsChange = useCallback(
    event => onRegulationsChange(event.target.value),
    [onRegulationsChange]
  );
  let showEffectiveDate = null;
  if (isOverridePage(type)) {
    showEffectiveDate =
      isActive(document.status) ||
      isOld(document.status) ||
      isRetired(document.status);
  }

  const handleApprovalDateBlur = useCallback(
    event => {
      handleActiveLifespanDateCheck({
        newLifeSpanDays: null,
        newApprovalDate: event.target.value,
        setModalData
      });
    },
    [handleActiveLifespanDateCheck]
  );

  const titleLabel = t("tables.labels.title");
  const applicabilityLabel = t("tables.labels.applicability");
  const locationsLabel = t("systemDocumentTemplates.locationsInput.label");

  const variables = {
    documentLabel: labels.documentLabel.toLowerCase(),
    fieldName: applicabilityLabel
  };
  let disabledMessage = t(
    "systemDocumentTemplates.disabledMessage.basedOnSystemDocumentTemplate",
    variables
  );
  if (isTemplate || convertingToTemplate) {
    disabledMessage = t(
      "systemDocumentTemplates.disabledMessage.isTemplate",
      variables
    );
  }

  return (
    <>
      <Panel
        name={t("documentControl.edit.propertiesSection")}
        id="properties"
        hideHeaderBorder={true}
      >
        <RequiredItem>
          {t("documentControl.create.policyProperties.required")}
        </RequiredItem>
        <FormElements>
          <MemoedFormInput
            onClear={onNameClear}
            required={true}
            autosize
            value={policyForm.name}
            label={titleLabel}
            onChange={useCallback(event => onNameChange(event.target.value), [
              onNameChange
            ])}
            type="text"
            name="name"
            errors={errors.name}
            id={"title"}
            dataTestid={"policyProperties-title"}
            disabled={basedOnSystemDocumentTemplate}
            disabledMessage={t(
              "systemDocumentTemplates.disabledMessage.basedOnSystemDocumentTemplate",
              {
                documentLabel: labels.documentLabel.toLowerCase(),
                fieldName: titleLabel
              }
            )}
          />
          {isCreatePage(type) && (
            <DuplicateChecker query={policyForm.name} labels={labels} />
          )}
          <AuthorInput
            id="author_field"
            onClear={onAuthorClear}
            required={true}
            value={policyForm.author}
            label={labels.authorLabel}
            onChange={useCallback(event => onAuthorChange(event.target.value), [
              onAuthorChange
            ])}
            name="author"
            errors={errors.author}
            dataTestid={"policyProperties-author"}
            selectMultiple={false}
            initialSelectedUser={editForm.author}
          />

          <SingleSelectInput
            id="category_field"
            onClear={onCategoryClear}
            required={true}
            value={policyForm.category}
            label={labels.categoryLabel}
            onChange={useCallback(
              event => onCategoryChange(event.target.value),
              [onCategoryChange]
            )}
            name="category"
            errors={errors.category}
            options={editForm.categoryChoices}
            disabled={basedOnSystemDocumentTemplate}
            disabledMessage={t(
              "systemDocumentTemplates.disabledMessage.basedOnSystemDocumentTemplate",
              {
                documentLabel: labels.documentLabel.toLowerCase(),
                fieldName: titleCase(labels.categoryLabel)
              }
            )}
          />

          <SingleSelectInput
            id="approval_flow_field"
            onClear={onFlowTemplateClear}
            required={true}
            value={policyForm.flowTemplate}
            label={t("policyProperties.approvalWorkflow")}
            onChange={useCallback(
              event => onFlowTemplateChange(event.target.value),
              [onFlowTemplateChange]
            )}
            errors={errors.flowTemplate}
            name="flowTemplate"
            options={editForm.flowTemplateChoices}
          />

          <WorkflowDiagram
            selectedFlowPk={policyForm.flowTemplate}
            selectedFlow={selectedFlow}
            onSelectedFlowChange={onSelectedFlowChange}
            selectedFlowLoading={selectedFlowLoading}
            onSelectedFlowLoadingChange={onSelectedFlowLoadingChange}
          />

          {editForm.blocChoices.length > 1 && (
            <SingleSelectInput
              key={blocUpdated}
              id="bloc_field"
              onClear={onBlocClear}
              required={true}
              value={policyForm.bloc}
              label={applicabilityLabel}
              onChange={blocChangeCallback}
              name="bloc"
              errors={errors.bloc}
              options={editForm.blocChoices}
              disabled={
                basedOnSystemDocumentTemplate ||
                convertingToTemplate ||
                isTemplate
              }
              disabledMessage={disabledMessage}
            />
          )}
          {(canLocalize || isTemplate) && (
            <QueryRenderer
              query={SiteSelectionQuery}
              variables={{ sort: "name" }}
              environment={environment}
              render={({ props, error }) => {
                if (error) {
                  throw new Error();
                }
                const identityBlocs = props?.blocsIdentity?.blocs;
                const allBlocs = props?.blocsAll?.blocs;
                const tenantIdentityBloc = props?.currentTenant?.identityBloc;
                return (
                  <Fragment>
                    <LocalizePolicy
                      canLocalize={canLocalize}
                      isTemplate={isTemplate}
                      loadingBlocs={!allBlocs}
                      onLocalize={() => {
                        setConvertingToTemplate(true);
                        const blocPk = policyForm.bloc;
                        const bloc = allBlocs.find(bloc => bloc.pk === blocPk);
                        if (bloc) {
                          const tenantPks = bloc.tenants.map(
                            tenant => tenant.pk
                          );
                          const identityBlocsForBloc = identityBlocs.filter(
                            bloc => tenantPks.indexOf(bloc.tenants[0].pk) > -1
                          );
                          const identityBlocPksForBloc = identityBlocsForBloc.map(
                            bloc => bloc.pk
                          );
                          onLocationsChange(identityBlocPksForBloc);
                        }
                        onBlocChange(tenantIdentityBloc.pk);
                        setBlocUpdated(`bloc_${tenantIdentityBloc.pk}`);
                      }}
                      convertingToTemplate={convertingToTemplate}
                    />
                    {(convertingToTemplate || isTemplate) && (
                      <LocationsFormInput
                        value={policyForm.locations}
                        name="locations"
                        options={allBlocs || []}
                        identityBlocs={identityBlocs}
                        onChange={event =>
                          onLocationsChange(event.target.value)
                        }
                        onClear={onLocationsClear}
                        disabled={
                          !allBlocs || (isOverridePage(type) && isTemplate)
                        }
                        disabledMessage={t(
                          "systemDocumentTemplates.disabledMessage.overrideSystemDocumentTemplateExtension",
                          {
                            fieldName: locationsLabel
                          }
                        )}
                      />
                    )}
                  </Fragment>
                );
              }}
            />
          )}

          <ReviewDate
            activeLifespan={policyForm.activeLifespan}
            expirationDate={policyForm.expirationDate}
            header={t("documentControl.edit.propertiesSection.reviewDate")}
            onBlurInconsistentDateCheck={onBlurInconsistentDateCheck}
            onActiveLifespanChange={useCallback(
              event => onActiveLifespanChange(event.target.value),
              [onActiveLifespanChange]
            )}
            onExpirationDateChange={useCallback(
              event => onExpirationDateChange(event.target.value),
              [onExpirationDateChange]
            )}
            activeLifespanError={errors.active_lifespan}
            expirationDateError={errors.expiration_date}
            // eslint-disable-next-line react-hooks/exhaustive-deps
            toggleRadioButtons={useCallback(toggleRadioButtons, [])}
            activeLifespanSelected={activeLifespanSelected}
            documentStatus={document?.status}
            isOverridePage={isOverridePage(type)}
          />
          {isOverridePage(type) && (
            <Fragment>
              {scheduledEffectiveDateEnabled ? (
                <Fragment>
                  {showEffectiveDate && (
                    <MemoedFormInput
                      required={true}
                      value={policyForm.effectiveDateDatetime}
                      label={t("documentControl.edit.effectiveDateSection", {
                        effectiveDateLabel: labels.effectiveDateLabel
                      })}
                      onChange={effectiveDateDatetimeChangeCallback}
                      type="date"
                      name="effective_date"
                      errors={errors.effective_date}
                      id="effective_date"
                    />
                  )}
                  {!isPending(document.status) && (
                    <MemoedFormInput
                      required={true}
                      value={policyForm.originationDate}
                      label={t(
                        "documentControl.override.properties.originationDate",
                        { originationDateLabel: labels.originationDateLabel }
                      )}
                      onChange={originationDateChangeCallback}
                      type="date"
                      name="origination_date"
                      errors={errors.origination_date}
                      id="origination_date"
                    />
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <MemoedFormInput
                    required={true}
                    value={policyForm.legacyEffectiveDate}
                    label={t(
                      "documentControl.override.properties.legacyEffectiveDate",
                      { originationDateLabel: labels.originationDateLabel }
                    )}
                    onChange={legacyEffectiveDateChangeCallback}
                    type="date"
                    name="legacy_effective_date"
                    errors={errors.legacy_effective_date}
                    id="legacy_effective_date"
                  />
                </Fragment>
              )}
              {!isPending(document.status) && (
                <MemoedFormInput
                  required={true}
                  value={policyForm.approvalDate}
                  label={t("documentControl.override.properties.approvalDate", {
                    approvalDateLabel: labels.approvedDateLabel
                  })}
                  onChange={approvalDateChangeCallback}
                  onBlur={handleApprovalDateBlur}
                  type="date"
                  name="approval_date"
                  errors={errors.approval_date}
                  id="approval_date"
                />
              )}
              {preimportRevisionForm?.revisionDates && (
                <MultipleDateFormElement
                  id={"pre_policystat_revision_dates"}
                  label={t(
                    "documentControl.override.properties.preimportRevisionDates"
                  )}
                  selectedDates={policyForm.revisionDates}
                  onDateSelected={onRevisionDatesChange}
                  onClear={onRevisionDatesClear}
                />
              )}
            </Fragment>
          )}
          <ReferenceTagInput
            id="references_field"
            onClear={onReferencesClear}
            value={policyForm.references}
            label={t("documentControl.create.policyProperties.references", {
              referencesLabelPlural: labels.referencesLabelPlural
            })}
            onChange={useCallback(
              event => onReferencesChange(event.target.value),
              [onReferencesChange]
            )}
            errors={errors.references}
            name="references"
            initialSelectedTags={tagForm.tags}
          />
          {tenant.standardsAndRegulationsEnabled && (
            <StandardsAndRegulationsFormElement
              id="regulations_field"
              onClear={onRegulationsClear}
              value={policyForm.regulations}
              label={t(
                "documentControl.create.policyProperties.standardsAndRegs"
              )}
              onChange={regulationsChange}
              errors={errors.regulations}
              name="regulations"
              initialSelectedRegPks={policyForm.regulations}
            />
          )}
        </FormElements>
      </Panel>
      <InconsistentExpirationDatesModal
        onClose={() => setModalData(data => ({ ...data, isOpen: false }))}
        isModalOpen={modalData.isOpen}
        handleDateChange={useCallback(() => {
          handleInconsistentExpirationDateUpdate(modalData.newLifeSpanDays);
          setModalData(data => ({ ...data, isOpen: false }));
        }, [handleInconsistentExpirationDateUpdate, modalData.newLifeSpanDays])}
        actualLifespanDays={modalData.actualLifespanDays}
        newLifespanDays={modalData.newLifeSpanDays}
      />
    </>
  );
};

PolicyProperties.propTypes = {
  policyForm: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  toggleRadioButtons: PropTypes.func.isRequired,
  reviewDateOptions: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default memo(
  withTranslation()(withTenantContext(withLabelContext(PolicyProperties)))
);

const ReviewDateContainer = styled.div`
  font-family: Roboto;
  font-size: 15px;
  line-height: 1.47;
  text-align: left;
  color: ${themeGet("colors.nav.0")};
  margin-bottom: 30px;
`;

const _ReviewDate = ({
  activeLifespan,
  expirationDate,
  onBlurInconsistentDateCheck,
  onActiveLifespanChange,
  onExpirationDateChange,
  activeLifespanError,
  expirationDateError,
  header,
  toggleRadioButtons,
  activeLifespanSelected,
  documentStatus,
  isOverridePage
}) => {
  const { t } = useTranslation();
  const selectedValue = activeLifespanSelected
    ? ACTIVE_LIFESPAN
    : EXPIRATION_DATE;
  const handleExpirationDateChange = useCallback(
    event => onExpirationDateChange(event),
    [onExpirationDateChange]
  );
  const handleActiveLifespanChange = useCallback(
    event => onActiveLifespanChange(event),
    [onActiveLifespanChange]
  );
  const handleActiveLifespanCheck = useCallback(
    event => onBlurInconsistentDateCheck(event),
    [onBlurInconsistentDateCheck]
  );
  return (
    <ReviewDateContainer>
      {!isOverridePage || isPending(documentStatus) ? (
        <RadioGroupFormElement
          header={header}
          required={true}
          name={"reviewDate"}
          selectedValue={selectedValue}
          onChange={toggleRadioButtons}
        >
          <RadioFormElementWithInput
            id="active_lifespan_field"
            radioValue={ACTIVE_LIFESPAN}
            radioLabel={t("documentControl.create.effectiveDate.days")} // change to expiration date
            inputValue={activeLifespan}
            onInputChange={onActiveLifespanChange}
            type={"number"}
            step={"1"}
            min={"0"}
            errors={selectedValue === ACTIVE_LIFESPAN && activeLifespanError}
            dataTestid="review_date_input"
          />
          <RadioFormElementWithDate
            id="expiration_date_field"
            radioValue={EXPIRATION_DATE}
            radioLabel={t("documentControl.create.effectiveDate.date")}
            inputValue={expirationDate}
            onInputChange={onExpirationDateChange}
            type={"text"}
            errors={selectedValue === EXPIRATION_DATE && expirationDateError}
          />
        </RadioGroupFormElement>
      ) : (
        <Fragment>
          <MemoedFormInput
            required={true}
            label={t("documentControl.override.expirationDate")}
            value={expirationDate}
            onChange={handleExpirationDateChange}
            type="date"
            name="expirationDate"
            errors={expirationDateError}
            id="expiration_date_field"
          />
          <MemoedFormInput
            value={activeLifespan}
            label={t("documentControl.override.activeLifespan")}
            onChange={handleActiveLifespanChange}
            onBlur={handleActiveLifespanCheck}
            type="number"
            step="1"
            min="0"
            name="activeLifespan"
            errors={activeLifespanError}
            id="active_lifespan_field"
          />
        </Fragment>
      )}
    </ReviewDateContainer>
  );
};

_ReviewDate.propTypes = {
  activeLifespan: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired,
  onActiveLifespanChange: PropTypes.func.isRequired,
  onExpirationDateChange: PropTypes.func.isRequired,
  activeLifespanError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  expirationDateError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  header: PropTypes.string.isRequired,
  toggleRadioButtons: PropTypes.func.isRequired,
  activeLifespanSelected: PropTypes.bool.isRequired,
  documentStatus: PropTypes.string.isRequired,
  isOverridePage: PropTypes.bool.isRequired
};

const ReviewDate = memo(_ReviewDate);
