import graphql from "babel-plugin-relay/macro";

const query = graphql`
  query SessionQuery($path: String) {
    currentTenant {
      pk
      name
      subdomain
      showApplicability
      isIsland
      settings {
        labels {
          approvedDateLabel
          authorLabel
          authorLabelPlural
          categoryLabel
          categoryLabelPlural
          documentLabel
          documentLabelPlural
          effectiveDateLabel
          expirationDateLabel
          guestLabel
          originationDateLabel
          referencesLabel
          referencesLabelPlural
          revisionDateLabel
        }
        documentsWithStandardsReportEnabled
        enableAllBlocs
        enableEditWithWord
        standardsAndRegulationsEnabled
        onesourceIntegrationEnabled
        vergeIntegrationEnabled
        enableEnterprise
      }
      samlSettings {
        isActive
        location
        samlRequest(path: $path)
      }
      documentSettings {
        showCommittee
        approvedDateShow
        authorShow
        departmentShow
        effectiveDateShow
        expirationDateShow
        originationDateShow
        referencesShow
        revisionDateShow
        footerHtml
        headerHtml
        editorStyles
        viewStyles
      }
      hasScheduledEffectiveDateEnabled
      acknowledgmentsEnabled
      customerSettings {
        enableEnterprisePolicySharing
      }
      loadHomeQueriesIndependently
    }
    viewer {
      pk
      algoliaApplicationId
      algoliaDocumentsIndexName
      algoliaAccessKey
      isGuest
      isStaff
      canReverseProxyLogin
      userAuth {
        isSiteAdmin
        canCreateDocuments
        canManageCertifications
        canViewReviewSetList
        canAcceptTemplates
      }
      forceChooseHomeTenant
      userDisplay(userDisplayType: SHORT_FORWARD, showStaff: true)
      hasOnesourceToken
      username
    }
    settings {
      recaptchaPublicKey
      showChangesWarningBanner
      mixpanelApiToken
    }
  }
`;

export default query;
