/**
 * @flow
 * @relayHash f79554c7da90ff82679cb3a9653845b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type discardDraftMutationVariables = {|
  documentSaveId: number
|};
export type discardDraftMutationResponse = {|
  +discardDraft: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
  |}
|};
export type discardDraftMutation = {|
  variables: discardDraftMutationVariables,
  response: discardDraftMutationResponse,
|};
*/


/*
mutation discardDraftMutation(
  $documentSaveId: Int!
) {
  discardDraft(documentSaveId: $documentSaveId) {
    ok
    reasons
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentSaveId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "discardDraft",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentSaveId",
        "variableName": "documentSaveId",
        "type": "Int!"
      }
    ],
    "concreteType": "DiscardDraft",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "discardDraftMutation",
  "id": "11f4ef372b3d031d85c839ba6e65ff9c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "discardDraftMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "discardDraftMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation discardDraftMutation(\n  $documentSaveId: Int!\n) {\n  discardDraft(documentSaveId: $documentSaveId) {\n    ok\n    reasons\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = 'c72bb6e2990f2b5e9306a6c33c20d2fa';
module.exports = node;
