let maintenanceInProgress = false;
let upcomingMaintenance = false;

export const setMaintenanceInProgress = inProgress => {
  maintenanceInProgress = inProgress;
};

export const setUpcomingMaintenance = maintenanceDatetime => {
  upcomingMaintenance = maintenanceDatetime;
};

export const isMaintenanceInProgress = () => maintenanceInProgress;
export const isUpcomingMaintenance = () => upcomingMaintenance;
