/**
 * @flow
 * @relayHash 267652f84a830e2375e9023cab897ce8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreatePolicyStartApprovalsInput = {
  documentPk?: ?number,
  actionComment?: ?string,
  documentSaveId?: ?number,
  hasSavedCopy?: ?boolean,
  tagInput?: ?$ReadOnlyArray<?string>,
  effectiveDate?: ?any,
  effectiveDateTimedelta?: ?number,
  effectiveDateAlgorithm?: ?string,
  educators?: ?$ReadOnlyArray<?number>,
  name?: ?string,
  html?: ?string,
  author?: ?number,
  category?: ?number,
  bloc?: ?number,
  flowTemplate?: ?number,
  activeLifespan?: ?number,
  expirationDate?: ?any,
  restricted?: ?boolean,
  isAForm?: ?boolean,
  regulations?: ?$ReadOnlyArray<?number>,
  onesourceDocuments?: ?$ReadOnlyArray<?OneSourceDocumentInputType>,
  locations?: ?$ReadOnlyArray<?number>,
  addApproval?: ?boolean,
  clientMutationId?: ?string,
};
export type OneSourceDocumentInputType = {
  oneSourceDocumentId?: ?string,
  catalogNumber?: ?string,
  manufacturerName?: ?string,
  manufacturerId?: ?string,
  oneSourceUpdated?: ?string,
  revisionInformation?: ?string,
  name?: ?string,
};
export type CreatePolicyStartApprovalsMutationVariables = {|
  input: CreatePolicyStartApprovalsInput
|};
export type CreatePolicyStartApprovalsMutationResponse = {|
  +createPolicyStartApprovals: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
  |}
|};
export type CreatePolicyStartApprovalsMutation = {|
  variables: CreatePolicyStartApprovalsMutationVariables,
  response: CreatePolicyStartApprovalsMutationResponse,
|};
*/


/*
mutation CreatePolicyStartApprovalsMutation(
  $input: CreatePolicyStartApprovalsInput!
) {
  createPolicyStartApprovals(input: $input) {
    ok
    reasons
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreatePolicyStartApprovalsInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createPolicyStartApprovals",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreatePolicyStartApprovalsInput!"
      }
    ],
    "concreteType": "CreatePolicyStartApprovalsPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreatePolicyStartApprovalsMutation",
  "id": "c7d4bd153698cac0cf6858eef0bd046c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreatePolicyStartApprovalsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CreatePolicyStartApprovalsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation CreatePolicyStartApprovalsMutation(\n  $input: CreatePolicyStartApprovalsInput!\n) {\n  createPolicyStartApprovals(input: $input) {\n    ok\n    reasons\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = '9891d9d7e3914ffb3f56700cc3b48253';
module.exports = node;
