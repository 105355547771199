import graphql from "babel-plugin-relay/macro";

const TemplateManagementPageCsvQuery = graphql`
  query TemplateManagementPageCsvQuery {
    systemDocumentTemplatesCsv {
      taskId
    }
  }
`;

export default TemplateManagementPageCsvQuery;
