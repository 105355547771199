import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation EditCommentMutation($input: EditCommentOnDocumentInput!) {
    editComment(input: $input) {
      ok
      reasons
      documentAction {
        id
        pk
        canEdit
        actionDate
        actionType
        comment
        isExternal
      }
    }
  }
`;

export const editCommentOnDocument = ({
  environment,
  document,
  action,
  comment,
  callback
}) => {
  const variables = {
    input: {
      actionPk: action.pk,
      comment
    }
  };
  commitMutation(environment, {
    mutation,
    variables,
    updater: store => {
      const payload = store.getRootField("editComment");
      const newCommentAction = payload.getLinkedRecord("documentAction");
      const storedDocument = store.get(document.id);
      const storedActions =
        storedDocument.getLinkedRecords("actions", { isEditPage: false }) || [];
      const actions = storedActions
        .map(storedAction => {
          if (
            !newCommentAction.getValue("pk") &&
            comment === "" &&
            storedAction.getValue("pk") === action.pk
          ) {
            return null;
          } else if (
            storedAction.getValue("pk") === newCommentAction.getValue("pk")
          ) {
            return newCommentAction;
          }
          return storedAction;
        })
        .filter(action => action);
      storedDocument.setLinkedRecords(actions, "actions", {
        isEditPage: false
      });
    },
    onCompleted: (response, errors) => {
      callback && callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
