import { useTranslation } from "react-i18next";
import system from "system-components";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Panel from "pstat-design-system/Panel";
import { HorizontalScrollTable } from "pstat-design-system/tables";
import { Text } from "pstat-design-system/text";

const DocumentsList = withLabelContext(
  ({ onesourceDocuments, onRemoveDocument, labels }) => {
    const { t } = useTranslation();
    return (
      <Panel
        id="onesource_documents"
        name={t("onesource.edit.documentListHeader", {
          number: onesourceDocuments.length
        })}
        hideHeaderBorder={true}
        wrapperProps={{
          borderTop: 1,
          borderColor: "nav.90",
          noTopRadius: true
        }}
      >
        {onesourceDocuments.length > 0 ? (
          <HorizontalScrollTable
            tableType="onesourceDocuments"
            policies={onesourceDocuments}
            numberToDisplay={100}
            onClickRemove={onRemoveDocument}
            rowKey={({ rowData }) => rowData.documentId}
          />
        ) : (
          <EmptyTextContainer>
            <Text>
              {t("onesource.edit.documentListEmpty", {
                documentLabel: labels.documentLabel.toLowerCase()
              })}
            </Text>
          </EmptyTextContainer>
        )}
      </Panel>
    );
  }
);

export default DocumentsList;

const EmptyTextContainer = system({
  py: 4,
  px: 3
});
