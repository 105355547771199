import { withRouter } from "found";
import { Component, createRef } from "react";
import { withTranslation } from "react-i18next";

import { withLabelContext } from "../../context_providers/LabelContext";
import { getRootUrl } from "../../utils/urls";

import SearchInput from "pstat-design-system/inputs/SearchInput";

class SimpleSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.match.location.query.q
    };
    this.searchInput = createRef();
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleClear = () => {
    this.setState({ value: "" }, this.navigateToSearchPage);
  };

  handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.navigateToSearchPage();
    }
  };

  navigateToSearchPage = () => {
    this.searchInput.current.blur();
    const existingQuery = this.props.match.location.query;
    const query = { ...existingQuery, q: this.state.value };
    const to = {
      pathname: `${getRootUrl()}/search`,
      query
    };
    this.props.router.push(to);
  };

  render() {
    const { t } = this.props;
    const documentLabel = this.props.labels.documentLabelPlural.toLowerCase();
    const { titleOnly } = this.props.match.location.query;
    const placeholder = titleOnly
      ? t("documentSearch.simpleSearch.placeholderTitleOnly", { documentLabel })
      : t("documentSearch.simpleSearch.placeholder", { documentLabel });
    return (
      <SearchInput
        inputRef={this.searchInput}
        onChange={this.handleChange}
        onClear={this.handleClear}
        onKeyDown={this.handleKeyDown}
        onSearch={this.navigateToSearchPage}
        value={this.state.value}
        placeholder={placeholder}
      />
    );
  }
}

const SimpleSearchWithContexts = withTranslation()(
  withLabelContext(withRouter(SimpleSearch))
);

export default SimpleSearchWithContexts;
