/**
 * @flow
 * @relayHash c2b7cd87259f0cff4bc3e16335f39864
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CustomerLogoQueryVariables = {||};
export type CustomerLogoQueryResponse = {|
  +currentTenant: ?{|
    +customerLogo: ?string
  |}
|};
export type CustomerLogoQuery = {|
  variables: CustomerLogoQueryVariables,
  response: CustomerLogoQueryResponse,
|};
*/


/*
query CustomerLogoQuery {
  currentTenant {
    customerLogo
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "customerLogo",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CustomerLogoQuery",
  "id": "87ebcacdf1de85ce8e83399980440459",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CustomerLogoQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          v0
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CustomerLogoQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currentTenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
node.text = "query CustomerLogoQuery {\n  currentTenant {\n    customerLogo\n    id\n  }\n}\n";
(node/*: any*/).hash = '1b54629cfe56a2d2770ff84e4e1ad0d9';
module.exports = node;
