/**
 * @flow
 * @relayHash 7a6206b82ba8379d8ae77ca5c21e3102
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TemplateManagementPageCsvQueryVariables = {||};
export type TemplateManagementPageCsvQueryResponse = {|
  +systemDocumentTemplatesCsv: ?{|
    +taskId: ?string
  |}
|};
export type TemplateManagementPageCsvQuery = {|
  variables: TemplateManagementPageCsvQueryVariables,
  response: TemplateManagementPageCsvQueryResponse,
|};
*/


/*
query TemplateManagementPageCsvQuery {
  systemDocumentTemplatesCsv {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "systemDocumentTemplatesCsv",
    "storageKey": null,
    "args": null,
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TemplateManagementPageCsvQuery",
  "id": "a9e24504d60569aa6a9df1a64e349ce8",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TemplateManagementPageCsvQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "TemplateManagementPageCsvQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query TemplateManagementPageCsvQuery {\n  systemDocumentTemplatesCsv {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '8c99925cf70bc1aa608c449cdad0b676';
module.exports = node;
