/**
 * @flow
 * @relayHash b749b46653de87ee36c7ddfc28d0ec91
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthorableTenantsQueryVariables = {||};
export type AuthorableTenantsQueryResponse = {|
  +authorableTenants: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +name: string,
  |}>
|};
export type AuthorableTenantsQuery = {|
  variables: AuthorableTenantsQueryVariables,
  response: AuthorableTenantsQueryResponse,
|};
*/


/*
query AuthorableTenantsQuery {
  authorableTenants {
    pk
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AuthorableTenantsQuery",
  "id": "82b50348696a8b40a1e36f6238a7602c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AuthorableTenantsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authorableTenants",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": true,
        "selections": [
          v0,
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthorableTenantsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "authorableTenants",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantType",
        "plural": true,
        "selections": [
          v0,
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
node.text = "query AuthorableTenantsQuery {\n  authorableTenants {\n    pk\n    name\n    id\n  }\n}\n";
(node/*: any*/).hash = '3062dca74bc67eb81eb3b4a792e8868b';
module.exports = node;
