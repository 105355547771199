/**
 * @flow
 * @relayHash bd4e80884eb1588d289190104b6dd571
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Approvable = "NO" | "OPTIONAL" | "REQUIRED" | "%future added value";
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type ApprovePendingDocumentMutationVariables = {|
  documentPk: number,
  comment: string,
|};
export type ApprovePendingDocumentMutationResponse = {|
  +approvePendingDocument: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +document: ?{|
      +id: string,
      +pk: ?number,
      +status: ?DocumentStatus,
      +approvable: Approvable,
      +effectiveDate: ?string,
      +approvalDate: ?string,
      +expirationDate: ?string,
      +revisionDate: ?string,
      +originationDate: ?string,
      +legacyEffectiveDate: ?string,
      +actions: ?$ReadOnlyArray<?{|
        +id: string,
        +actionType: string,
        +actionDate: any,
        +comment: string,
        +isExternal: boolean,
        +user: ?{|
          +id: string,
          +userDisplay: ?string,
        |},
      |}>,
      +approvalWorkflow: ?{|
        +name: ?string,
        +steps: ?$ReadOnlyArray<?{|
          +name: ?string,
          +approvers: ?$ReadOnlyArray<?{|
            +firstName: string,
            +lastName: string,
            +isDocumentOwner: ?boolean,
            +hasApproved: ?boolean,
          |}>,
        |}>,
      |},
    |},
  |}
|};
export type ApprovePendingDocumentMutation = {|
  variables: ApprovePendingDocumentMutationVariables,
  response: ApprovePendingDocumentMutationResponse,
|};
*/


/*
mutation ApprovePendingDocumentMutation(
  $documentPk: Int!
  $comment: String!
) {
  approvePendingDocument(documentPk: $documentPk, comment: $comment) {
    ok
    reasons
    document {
      id
      pk
      status
      approvable
      effectiveDate(formatted: true)
      approvalDate(formatted: true)
      expirationDate(formatted: true)
      revisionDate(formatted: true)
      originationDate(formatted: true)
      legacyEffectiveDate(formatted: true)
      actions {
        id
        actionType
        actionDate
        comment
        isExternal
        user {
          id
          userDisplay(userDisplayType: FORWARD)
        }
      }
      approvalWorkflow {
        name
        steps {
          name
          approvers {
            firstName
            lastName
            isDocumentOwner
            hasApproved
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "comment",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "comment",
    "variableName": "comment",
    "type": "String!"
  },
  {
    "kind": "Variable",
    "name": "documentPk",
    "variableName": "documentPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reasons",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvable",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "formatted",
    "value": true,
    "type": "Boolean"
  }
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "effectiveDate",
  "args": v8,
  "storageKey": "effectiveDate(formatted:true)"
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": v8,
  "storageKey": "approvalDate(formatted:true)"
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": v8,
  "storageKey": "expirationDate(formatted:true)"
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDate",
  "args": v8,
  "storageKey": "revisionDate(formatted:true)"
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "originationDate",
  "args": v8,
  "storageKey": "originationDate(formatted:true)"
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyEffectiveDate",
  "args": v8,
  "storageKey": "legacyEffectiveDate(formatted:true)"
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "actions",
  "storageKey": null,
  "args": null,
  "concreteType": "DocumentActionType",
  "plural": true,
  "selections": [
    v4,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actionType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "actionDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isExternal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "UserType",
      "plural": false,
      "selections": [
        v4,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "userDisplay",
          "args": [
            {
              "kind": "Literal",
              "name": "userDisplayType",
              "value": "FORWARD",
              "type": "UserDisplayType"
            }
          ],
          "storageKey": "userDisplay(userDisplayType:\"FORWARD\")"
        }
      ]
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isDocumentOwner",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasApproved",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ApprovePendingDocumentMutation",
  "id": "67d522fc52f643a4491b2123a59904fb",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ApprovePendingDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvePendingDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "ApprovePendingDocumentMutation",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "approvalWorkflow",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalWorkflowType",
                "plural": false,
                "selections": [
                  v16,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "steps",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalStepType",
                    "plural": true,
                    "selections": [
                      v16,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvers",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalStepUserType",
                        "plural": true,
                        "selections": [
                          v17,
                          v18,
                          v19,
                          v20
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApprovePendingDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvePendingDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "ApprovePendingDocumentMutation",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "document",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentType",
            "plural": false,
            "selections": [
              v4,
              v5,
              v6,
              v7,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "approvalWorkflow",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalWorkflowType",
                "plural": false,
                "selections": [
                  v16,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "steps",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalStepType",
                    "plural": true,
                    "selections": [
                      v16,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvers",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalStepUserType",
                        "plural": true,
                        "selections": [
                          v17,
                          v18,
                          v19,
                          v20,
                          v4
                        ]
                      },
                      v4
                    ]
                  },
                  v4
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation ApprovePendingDocumentMutation(\n  $documentPk: Int!\n  $comment: String!\n) {\n  approvePendingDocument(documentPk: $documentPk, comment: $comment) {\n    ok\n    reasons\n    document {\n      id\n      pk\n      status\n      approvable\n      effectiveDate(formatted: true)\n      approvalDate(formatted: true)\n      expirationDate(formatted: true)\n      revisionDate(formatted: true)\n      originationDate(formatted: true)\n      legacyEffectiveDate(formatted: true)\n      actions {\n        id\n        actionType\n        actionDate\n        comment\n        isExternal\n        user {\n          id\n          userDisplay(userDisplayType: FORWARD)\n        }\n      }\n      approvalWorkflow {\n        name\n        steps {\n          name\n          approvers {\n            firstName\n            lastName\n            isDocumentOwner\n            hasApproved\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n";
(node/*: any*/).hash = '51077c134bc8758a665ccd8fccd26845';
module.exports = node;
