// @flow
import { useDrag } from "react-dnd";
import { themeGet } from "styled-system";
import system from "system-components";

import { DraggableItemTypes } from "./";

import theme from "pstat-anywhere/themes/policystat/theme";

type DraggablePickerButtonProps = {
  canDrag: boolean,
  indexType: "startItemIndex" | "endItemIndex",
  top?: string,
  bottom?: string
};

export default function DraggablePickerButton({
  canDrag,
  indexType,
  ...props
}: DraggablePickerButtonProps) {
  const [, drag] = useDrag({
    item: { type: DraggableItemTypes.ARROW, indexType },
    canDrag,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  });

  return <ScrollPickerButton {...props} innerRef={drag} />;
}

const ScrollPickerButton = system({
  is: "button",
  width: "22px",
  height: "30px",
  border: 1,
  borderColor: "secondary.0",
  position: "absolute",
  left: 0,
  zIndex: theme.layers.textOverlay
}).extend`
  outline: none;
  background-image: linear-gradient(to bottom, ${themeGet(
    "colors.secondary.15"
  )}, ${themeGet("colors.secondary.15")} 49%, ${themeGet(
  "colors.secondary.0"
)} 49%, ${themeGet("colors.secondary.0")});
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  cursor: pointer;
  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    margin: -8px -9px;
    border-top: solid 1px ${themeGet("colors.secondary.15")};
    border-right: solid 1px ${themeGet("colors.secondary.15")};
    width: 15px;
    height: 15px;
    transform: rotate(32deg) skewY(30deg); /* create a rhombus */
    background: linear-gradient(-48deg, ${themeGet(
      "colors.secondary.0"
    )}, ${themeGet("colors.secondary.0")} 49%, ${themeGet(
  "colors.secondary.15"
)} 49%, ${themeGet("colors.secondary.15")});
    content: '';
}
`;
