import { Route } from "found";

import MyAcknowledgmentsForLine from "./for_line/MyAcknowledgmentsForLine";
import MyAcknowledgmentHistory from "./mine/all/MyAcknowledgmentHistory";
import MyPoliciesToAcknowledgeReport from "./mine/MyPoliciesToAcknowledgeReport";

import { ContentContainer } from "pstat-anywhere/components/pages";
import { environment } from "pstat-anywhere/relay";
import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";


const AcknowledgmentsWrapper = ({ children }) => (
  <ContentContainer>{children}</ContentContainer>
);

const routes = (
  <Route path="acknowledgment" Component={AcknowledgmentsWrapper}>
    <Route
      path="mine"
      Component={props => (
        <PageTitleWrapper titleKey="acknowledgments.mine.documentTitle">
          <MyPoliciesToAcknowledgeReport {...props} />
        </PageTitleWrapper>
      )}
      prepareVariables={(params, { location }) => ({
        ...params,
        queryName: location.query.name
      })}
      render={({ Component, props }) => (
        <Component {...props} environment={environment}>
          {props.children}
        </Component>
      )}
    />
    <Route
      path="mine/all"
      Component={props => (
        <PageTitleWrapper titleKey="acknowledgments.mine.documentTitle.all">
          <MyAcknowledgmentHistory {...props} />
        </PageTitleWrapper>
      )}
      prepareVariables={(params, { location }) => ({
        ...params,
        queryName: location.query.name
      })}
      render={({ Component, props }) => (
        <Component {...props} environment={environment}>
          {props.children}
        </Component>
      )}
    />
    <Route
      path="line/:linePk(\d+)"
      Component={MyAcknowledgmentsForLine}
      prepareVariables={(params, { location }) => ({
        ...params,
        queryName: location.query.name
      })}
      render={({ Component, props }) => (
        <Component {...props} environment={environment}>
          {props.children}
        </Component>
      )}
    />
  </Route>
);

export default routes;
