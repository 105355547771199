import { useTranslation } from "react-i18next";

import DuplicateDocumentsCSVQuery from "./graphql/DuplicateDocumentsCSVQuery";

import { ContentContainer } from "pstat-anywhere/components/pages";
import { AdvancedReportPage } from "pstat-anywhere/components/pages/reports";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";


const DuplicatePoliciesReport = ({
  duplicateDocuments,
  route,
  router,
  location,
  labels
}) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = labels;
  const documentLabelLowerPlural = {
    documentLabelPlural: labels.documentLabelPlural.toLowerCase()
  };
  const tabs = [
    {
      id: "duplicates_all",
      textKey: "documentReports.duplicates.tabs.all",
      path: "policy_reports/duplicates"
    }
  ];

  return (
    <ContentContainer>
      <AdvancedReportPage
        csvQueryName="duplicateDocumentsCsv"
        CSVQuery={DuplicateDocumentsCSVQuery}
        data={duplicateDocuments?.documents}
        route={route}
        router={router}
        location={location}
        headerText={t("documentReports.duplicates.header", {
          documentLabelPlural
        })}
        noContentMessage={t(
          "documentReports.duplicates.reportContent.noContentMessage",
          documentLabelLowerPlural
        )}
        showActionButtonPanel={true}
        showTabsPanel={true}
        tabs={tabs}
        tableType="duplicatePolicies"
        showPagination={true}
        otherTableProps={{ cleanCustomBodyRow: true }}
        headerTooltip={t(
          "documentReports.duplicates.tooltipHeader",
          documentLabelLowerPlural
        )}
      />
    </ContentContainer>
  );
};

export default withLabelContext(DuplicatePoliciesReport);
