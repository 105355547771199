// @flow

import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import system from "system-components";

import theme from "../../../../themes/policystat/theme";
import { StyledFontAwesomeIcon } from "../../../partials/icons";

import { FLEX_HEADER_HEIGHT } from "pstat-anywhere/components/partials/HamburgerHeader";
import tocStyles from "pstat-anywhere/components/view/content/tocstyles";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import type { HeadingNode } from "pstat-anywhere/utils/domHelpers";
import RouterLink from "pstat-design-system/RouterLink";
import { STYLED_FLOATING_BUTTON_HEIGHT } from "pstat-design-system/shared/Button";
import { H3, Text } from "pstat-design-system/text";


// Estimated SECONDARY_NAVBAR_HEIGHT
const SECONDARY_NAVBAR_HEIGHT = 70;
// For extra padding and adjustments
const EXTRA_HEIGHT = 36;
// Total should be about 270px, as of current layout
const TOC_CONFLICT_HEIGHT =
  FLEX_HEADER_HEIGHT +
  SECONDARY_NAVBAR_HEIGHT +
  theme.tertiaryNavHeight +
  STYLED_FLOATING_BUTTON_HEIGHT +
  EXTRA_HEIGHT;

const TocOl = styled("ol")`
  list-style: none;
`;

TocOl.displayName = "TocOl";

export const TocItem = ({
  item,
  onClick,
  activeItemId,
  level
}: {
  item: HeadingNode
}) => {
  const getOffset = level => {
    let total = 0;
    for (let i = 0; i <= level; i++) {
      total += theme.space[i];
    }
    total += theme.space[4];
    return total;
  };
  item.content = item.content.replace(/<img[^>]*>/gi, "");
  let active = null;
  if (activeItemId) {
    active = activeItemId === item.id;
  }

  const offset = getOffset(level);

  if (item.content === "" || item.content === "&nbsp;") {
    return (
      <Fragment>
        {item.children && (
          <TocLevel
            sublevels={item.children}
            activeItemId={activeItemId}
            level={level + 1}
          />
        )}
      </Fragment>
    );
  }

  const color = active ? "white" : "secondary.0";
  return (
    <Fragment>
      <Text
        is="li"
        className={item.className}
        bg={active ? "secondary.0" : ""}
        id={`toc-${item.id}`}
        fontWeight={item.level % 2 === 0 ? "medium" : "normal"}
        size={item.level % 2 === 0 ? "small" : "normal"}
        pl={offset}
        mb={1}
        color={color}
      >
        <RouterLink
          to={{
            pathname: window.location.pathname,
            hash: `#${item.id}`
          }}
          color={color}
          pl={1}
        >
          <span dangerouslySetInnerHTML={{ __html: item.content }} />
        </RouterLink>
      </Text>

      {item.children && (
        <TocLevel
          sublevels={item.children}
          activeItemId={activeItemId}
          level={level + 1}
        />
      )}
    </Fragment>
  );
};

const TocSection = ({ id, icon, label, count, activeItemId }) => {
  const { t } = useTranslation();
  let active = null;
  if (activeItemId) {
    active = activeItemId === id;
  }
  const color = active ? "white" : "secondary.0";

  return (
    <Fragment>
      <Text
        is="ul"
        bg={active ? "secondary.0" : ""}
        color={color}
        fontWeight="normal"
        size="normal"
        my={3}
        pl={4}
      >
        <StyledFontAwesomeIcon
          data-testid="toc-section-icon"
          icon={icon}
          color={color}
          mx="0"
        />
        <RouterLink
          to={{
            pathname: window.location.pathname,
            hash: "#" + id
          }}
          color={color}
          pl={1}
        >
          {t(label, { count })}
        </RouterLink>
      </Text>
      <AttachmentsDivider />
    </Fragment>
  );
};

export const TocLevel = ({
  sublevels,
  activeItemId,
  level
}: {
  sublevels: Array<HeadingNode>
}) => {
  if (sublevels.length > 0) {
    return (
      <TocOl>
        {sublevels.map(child => (
          <TocItem
            activeItemId={activeItemId}
            item={child}
            key={child.id}
            level={level}
          />
        ))}
      </TocOl>
    );
  }
  return null;
};

const AttachmentsDivider = system({
  mb: 2,
  mr: 5,
  borderBottom: 2,
  borderColor: "nav.90"
});

const TocContainer = system({
  bg: "nav.100",
  pb: theme.space[1],
  minHeight: ["120px", "120px", "180px"],
  maxHeight: "25vh",
  boxShadow: `0px 2px 32px 0 rgba(0, 0, 0, 0.16), 0px 1px 6px 0 rgba(0, 0, 0, 0.06)`,
  borderRadius: `0 ${theme.radii[1]}px ${theme.radii[1]}px 0`
}).extend`
  overflow-y: auto;
  z-index: ${theme.layers.secondaryNav - 1};
  ${tocStyles};
  & ol {
    margin-top: ${theme.space[2]}px;
  }
  & ol li {
    margin-bottom: ${theme.space[1]}px
  }
  width: 100%; // depends on this element not being position:absolute
  @media (min-width: ${theme.breakpoints[1]}) {
    width: 320px;
    max-height: calc(100vh - ${TOC_CONFLICT_HEIGHT}px);
  }
`;

TocContainer.displayName = "TocContainer";

const TocItemsContainer = system({
  is: "section",
  pl: 0
});

const TableOfContents = withTenantContext(
  ({
    tenant,
    rootNode,
    countAttachments,
    countStandards,
    countOneSource,
    activeItemId
  }: {
    rootNode: HeadingNode
  }) => {
    const { t } = useTranslation();
    return (
      <TocContainer data-testid="toc-expanded-component">
        <H3 bg="nav.10" size="lg" color="nav.100" height={50} px={2} py={3}>
          <StyledFontAwesomeIcon
            icon={["fas", "list-alt"]}
            color="nav.100"
            mr={2}
          />
          {t("documentControl.view.tableOfContents.tab.TOC.header")}
        </H3>
        <TocItemsContainer>
          <TocSection
            id={"attachments"}
            icon={["fal", "paperclip"]}
            label={"documentControl.view.tableOfContents.attachments.header"}
            count={countAttachments}
            activeItemId={activeItemId}
          />
          {countOneSource > 0 && (
            <TocSection
              id={"onesource-documents-panel"}
              icon={["fal", "paperclip"]}
              label={"documentControl.view.tableOfContents.onesource.header"}
              count={countOneSource}
              activeItemId={activeItemId}
            />
          )}
          {countStandards !== undefined && (
            <TocSection
              id={"standards"}
              icon={["far", "link"]}
              label={
                "documentControl.view.tableOfContents.vergeStandards.header"
              }
              count={countStandards}
              activeItemId={activeItemId}
            />
          )}
          <TocLevel
            sublevels={rootNode.children}
            activeItemId={activeItemId}
            level={0}
          />
        </TocItemsContainer>
      </TocContainer>
    );
  }
);

export default TableOfContents;
