import styled from "styled-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import theme from "pstat-anywhere/themes/policystat/theme";

const ClearIcon = styled(StyledFontAwesomeIcon).attrs({
  icon: ["far", "times"]
})`
  color: ${theme.colors.nav[25]};
  position: absolute;
  top: ${theme.space[3]}px;
  right: ${theme.space[3]}px;
`;

export default ClearIcon;
