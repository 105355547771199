import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation NoticeSettingsMutation($settings: [NoticeSettingInputType]!) {
    noticeSettings(settings: $settings) {
      ok
      reasons
    }
  }
`;

export const doNoticeSettingsUpdate = (settings, callback) => {
  const variables = {
    settings
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
