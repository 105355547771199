import PropTypes from "prop-types";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import {
  RadioFormElementWithDate,
  RadioFormElementWithInput,
  RadioGroupFormElement
} from "pstat-design-system/inputs/FormElements";
import Radio from "pstat-design-system/inputs/Radio";
import Panel from "pstat-design-system/Panel";
import { H2 } from "pstat-design-system/text/headings";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";

// @flow

export const EFFECTIVE_DATE_UPON_APPROVAL = "effectiveDateUponApproval";
export const EFFECTIVE_DATE_DATETIME = "effectiveDateDatetime";
export const EFFECTIVE_DATE_TIMEDELTA = "effectiveDateTimedelta";

export const getEffectiveDateAlgorithm = effectiveDateSelectedOption => {
  if (effectiveDateSelectedOption === EFFECTIVE_DATE_UPON_APPROVAL) {
    return "immediate";
  } else if (effectiveDateSelectedOption === EFFECTIVE_DATE_DATETIME) {
    return "datetime";
  } else {
    return "timedelta";
  }
};

const LearnMoreLink = styled.a`
  color: ${themeGet("colors.nav.0")};
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  float: right;
  min-width: fit-content;
  &::before {
    content: "${props => props.tag}";
    margin-right: 0.25rem;
    color: ${themeGet("colors.disabled.tag")};
  }
  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

const Header = ({ labels, disabled }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {disabled && (
        <LearnMoreLink
          href="https://rldatix-public.zendesk.com/hc/en-us/articles/10341098389660-What-is-Scheduled-Effective-Date-"
          tag={t("documentControl.edit.effectiveDateSection.disabled.tag")}
        >
          {t("documentControl.edit.effectiveDateSection.disabled.link")}
        </LearnMoreLink>
      )}
      <H2
        id="effective_date"
        color={disabled ? "disabled.header" : "nav.0"}
        display="inline-block"
        fontWeight="normal"
      >
        {t("documentControl.edit.effectiveDateSection", {
          effectiveDateLabel: labels.effectiveDateLabel
        })}
      </H2>
      <StyledFontAwesomeIcon
        icon={["fal", "question-circle"]}
        color="secondary.0"
        size="lg"
        data-tip
        data-for="effective-date-tooltip"
        data-event={HOVER_EVENTS}
        data-event-off={HOVER_EVENTS_OFF}
        tabIndex="0"
      />
      <Tooltip name="effective-date-tooltip">
        {t("documentControl.edit.effectiveDateSection.tooltip", {
          documentLabel: labels.documentLabel.toLowerCase(),
          authorLabelPlural: titleCase(labels.authorLabelPlural)
        })}
      </Tooltip>
    </Fragment>
  );
};

const EffectiveDate = ({
  policyForm,
  onEffectiveDateDatetimeChange,
  onEffectiveDateTimedeltaChange,
  errors,
  toggleRadioButtons,
  effectiveDateSelectedOption,
  isScheduled,
  labels,
  tenant
}) => (
  <Panel
    renderHeader={() => (
      <Header
        labels={labels}
        disabled={!tenant.hasScheduledEffectiveDateEnabled}
      />
    )}
    hideHeaderBorder={true}
  >
    <ActiveDate
      effectiveDateDatetime={policyForm.effectiveDateDatetime}
      effectiveDateTimedelta={policyForm.effectiveDateTimedelta}
      errors={errors.effective_date}
      onEffectiveDateDatetimeChange={onEffectiveDateDatetimeChange}
      onEffectiveDateTimedeltaChange={onEffectiveDateTimedeltaChange}
      toggleRadioButtons={toggleRadioButtons}
      effectiveDateSelectedOption={effectiveDateSelectedOption}
      isScheduled={isScheduled}
      disabled={!tenant.hasScheduledEffectiveDateEnabled}
    />
  </Panel>
);

EffectiveDate.propTypes = {
  policyForm: PropTypes.object.isRequired,
  onEffectiveDateDatetimeChange: PropTypes.func.isRequired,
  onEffectiveDateTimedeltaChange: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  toggleRadioButtons: PropTypes.func.isRequired,
  effectiveDateOptions: PropTypes.object.isRequired
};

export const ActiveDate = withLabelContext(
  ({
    effectiveDateDatetime,
    effectiveDateTimedelta,
    onEffectiveDateDatetimeChange,
    onEffectiveDateTimedeltaChange,
    errors,
    toggleRadioButtons,
    effectiveDateSelectedOption,
    isScheduled,
    labels,
    disabled
  }) => {
    const { t } = useTranslation();
    const { documentLabel } = labels;
    return (
      <RadioGroupFormElement
        header={t("documentControl.create.effectiveDate.policyMadeActive", {
          documentLabel
        })}
        selectedValue={effectiveDateSelectedOption}
        onChange={toggleRadioButtons}
        name={"effectiveDate"}
      >
        {isScheduled ? (
          <Radio
            value={EFFECTIVE_DATE_UPON_APPROVAL}
            label={t("documentControl.create.effectiveDate.makeActive")}
            inline={true}
            disabled={disabled}
            disabledTooltipText={t(
              "documentControl.edit.effectiveDateSection.disabled.tooltip"
            )}
          />
        ) : (
          <Fragment>
            <Radio
              value={EFFECTIVE_DATE_UPON_APPROVAL}
              label={t("documentControl.create.effectiveDate.uponApproval")}
              inline={true}
              disabled={disabled}
              disabledTooltipText={t(
                "documentControl.edit.effectiveDateSection.disabled.tooltip"
              )}
            />
            <RadioFormElementWithInput
              radioValue={EFFECTIVE_DATE_TIMEDELTA}
              radioLabel={t("documentControl.create.effectiveDate.days")}
              inputValue={effectiveDateTimedelta}
              onInputChange={event =>
                onEffectiveDateTimedeltaChange(event.target.value)
              }
              type={"number"}
              min={1}
              errors={errors}
              disabled={disabled}
              disabledTooltipText={t(
                "documentControl.edit.effectiveDateSection.disabled.tooltip"
              )}
            />
          </Fragment>
        )}
        <RadioFormElementWithDate
          radioValue={EFFECTIVE_DATE_DATETIME}
          radioLabel={t("documentControl.create.effectiveDate.date")}
          inputValue={effectiveDateDatetime}
          onInputChange={event =>
            onEffectiveDateDatetimeChange(event.target.value)
          }
          type={"text"}
          errors={errors}
          disabled={disabled}
          disabledTooltipText={t(
            "documentControl.edit.effectiveDateSection.disabled.tooltip"
          )}
        />
      </RadioGroupFormElement>
    );
  }
);

ActiveDate.propTypes = {
  effectiveDateDatetime: PropTypes.string.isRequired,
  effectiveDateTimedelta: PropTypes.number.isRequired,
  onEffectiveDateDatetimeChange: PropTypes.func.isRequired,
  onEffectiveDateTimedeltaChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  toggleRadioButtons: PropTypes.func.isRequired,
  effectiveDateOption: PropTypes.string.isRequired
};

export default withLabelContext(withTenantContext(EffectiveDate));
