import { Box, Flex } from "grid-styled";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import MyAcknowledgmentsForLineQuery from "./MyAcknowledgmentsForLineQuery";

import {
  ReportHeader,
  ReportPage
} from "pstat-anywhere/components/pages/reports";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import Card from "pstat-design-system/Card";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { HorizontalScrollTable } from "pstat-design-system/tables";


const PageContent = props => (
  <QueryRenderer
    environment={props.environment}
    query={MyAcknowledgmentsForLineQuery}
    variables={props.variables}
    render={renderProps => {
      const queryProps = renderProps.props;
      if (queryProps && queryProps.acknowledgmentsByLine) {
        const result = queryProps.acknowledgmentsByLine;
        props.onDataUpdate(result);
        const documents = result.edges.map(edge => {
          const { document, ...otherFields } = edge.node;
          return {
            ...document,
            ...otherFields
          };
        });
        return (
          <HorizontalScrollTable
            tableType="my_acknowledgments_for_line"
            policies={documents}
            sort={props.variables.sort}
            onSortChange={props.onSortChange}
            noSort={false}
            hasFrozenColumn={true}
          />
        );
      } else {
        return (
          <Flex justify="center" align="center" mt={10}>
            <Box>
              <LoadingSpinner size={100} />
            </Box>
          </Flex>
        );
      }
    }}
  />
);

class MyAcknowledgmentsForLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalNumberOfResults: 0,
      hitsPerPage: 50
    };
  }

  handleDataUpdate = data => {
    const updatedData = {};
    let updated = false;
    if (this.state.totalNumberOfResults !== data.totalCount) {
      updatedData.totalNumberOfResults = data.totalCount;
      updated = true;
    }
    if (this.state.acknowledgedCount !== data.acknowledgedCount) {
      updatedData.acknowledgedCount = data.acknowledgedCount;
      updated = true;
    }
    if (this.state.incompleteCount !== data.incompleteCount) {
      updatedData.incompleteCount = data.incompleteCount;
      updated = true;
    }

    if (updated) {
      this.setState(updatedData);
    }
  };

  handlePageChange = afterCursor => {
    const queryParams = { ...this.props.location.query };
    delete queryParams.after;
    if (afterCursor) {
      queryParams.after = afterCursor;
    }
    const linePk = this.props.params.linePk;
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/line/${linePk}`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleSortChange = sort => {
    const queryParams = { ...this.props.location.query };
    queryParams.sort = sort;
    const to = {
      pathname: `${getRootUrl()}/acknowledgment/line/${
        this.props.params.linePk
      }`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleHitsPerPageChange = hitsPerPage => {
    this.setState({ hitsPerPage: hitsPerPage }, this.handlePageChange(null));
  };

  render() {
    const { t, location } = this.props;
    const { after, sort } = location.query;
    const variables = {
      linePk: this.props.params.linePk,
      first: this.state.hitsPerPage,
      after: after,
      sort: sort
    };

    return (
      <ReportPage
        totalNumberOfResults={this.state.totalNumberOfResults}
        hitsPerPage={this.state.hitsPerPage}
        onHitsPerPageChange={this.handleHitsPerPageChange}
        showItemCount={true}
        showHitsPerPage={true}
      >
        <ReportHeader
          title={t("acknowledgments.forLine.myAcknowledgmentsForLine.header")}
        />
        <Card>
          <PageContent
            variables={variables}
            onDataUpdate={this.handleDataUpdate}
            onSortChange={this.handleSortChange}
            environment={this.props.environment}
          />
        </Card>
      </ReportPage>
    );
  }
}

export default withTranslation()(MyAcknowledgmentsForLine);
