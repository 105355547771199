import { useRef, useState } from "react";

import AutocompleteTextInput from "./AutocompleteTextInput";

import customFetch from "pstat-anywhere/utils/customFetch";

const TAG_SEARCH_URL_PATH = "/ajax_select/ajax_lookup/tag_search";

const ReferenceTagsAutoCompleteInput = props => {
  const [suggestions, setSuggestions] = useState([]);
  // using ref not as a normal ref to a component
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const suggestionsCache = useRef({});

  const fetchTags = async ({ value }) => {
    const cachedSuggestions = suggestionsCache.current[value];
    if (cachedSuggestions) {
      setSuggestions(cachedSuggestions);
      return;
    }

    const url = `${TAG_SEARCH_URL_PATH}?term=${value}`;
    const tagSuggestions = await customFetch(url).then(response =>
      response.json()
    );
    const suggestions = tagSuggestions.map(suggestion => {
      return {
        id: parseInt(suggestion.pk, 10),
        name: suggestion.value
      };
    });

    if (shouldAddNewTagSuggestion(value, suggestions)) {
      suggestions.push({
        isNew: true,
        id: null,
        name: value
      });
    }
    suggestionsCache.current[value] = suggestions;

    setSuggestions(suggestions);
  };

  const shouldAddNewTagSuggestion = (value, suggestions) => {
    const suggestionsThatExactlyMatchSearchTerm = suggestions.filter(
      suggestion => suggestion.name === value
    );
    return (
      value.length > 0 &&
      (suggestions.length === 0 ||
        suggestionsThatExactlyMatchSearchTerm.length === 0)
    );
  };

  const getSuggestionValue = suggestion => suggestion.name;

  return (
    <AutocompleteTextInput
      selectedSuggestions={props.selectedSuggestions}
      onSuggestionSelected={props.onSuggestionSelected}
      onSuggestionDeselected={props.onSuggestionSelected}
      suggestions={suggestions}
      onSuggestionsFetchRequested={fetchTags}
      getSuggestionValue={getSuggestionValue}
      selectMultiple={true}
      {...props}
    />
  );
};

export default ReferenceTagsAutoCompleteInput;
