/**
 * @flow
 * @relayHash 61321fc83ddcc27dec10ebd8a5c1f84f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdoptedSystemDocumentTemplatesQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type AdoptedSystemDocumentTemplatesQueryResponse = {|
  +adoptedSystemDocumentTemplates: ?{|
    +adoptedSystemDocumentTemplates: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: ?string,
          +total: ?number,
          +adopted: ?number,
          +document: ?{|
            +pk: ?number
          |},
        |}
      |}>,
    |},
    +error: ?{|
      +statusCode: ?number
    |},
    +otherTabTotal: ?number,
  |}
|};
export type AdoptedSystemDocumentTemplatesQuery = {|
  variables: AdoptedSystemDocumentTemplatesQueryVariables,
  response: AdoptedSystemDocumentTemplatesQueryResponse,
|};
*/


/*
query AdoptedSystemDocumentTemplatesQuery(
  $after: String
  $first: Int
  $sort: String
) {
  adoptedSystemDocumentTemplates(sort: $sort) {
    adoptedSystemDocumentTemplates(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          total
          adopted
          document {
            pk
            id
          }
        }
      }
    }
    error {
      statusCode
    }
    otherTabTotal
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "total",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "adopted",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "otherTabTotal",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AdoptedSystemDocumentTemplatesQuery",
  "id": "b2d88aa7ea16e7aa2ff55f008e5aab8b",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AdoptedSystemDocumentTemplatesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adoptedSystemDocumentTemplates",
        "storageKey": null,
        "args": v1,
        "concreteType": "AdoptedSystemDocumentTemplateListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adoptedSystemDocumentTemplates",
            "storageKey": null,
            "args": v2,
            "concreteType": "AdoptedSystemDocumentTemplatesConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AdoptedSystemDocumentTemplatesEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdoptedSystemDocumentTemplateType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v8,
          v9
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdoptedSystemDocumentTemplatesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adoptedSystemDocumentTemplates",
        "storageKey": null,
        "args": v1,
        "concreteType": "AdoptedSystemDocumentTemplateListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adoptedSystemDocumentTemplates",
            "storageKey": null,
            "args": v2,
            "concreteType": "AdoptedSystemDocumentTemplatesConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AdoptedSystemDocumentTemplatesEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdoptedSystemDocumentTemplateType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "id",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v8,
          v9
        ]
      }
    ]
  }
};
})();
node.text = "query AdoptedSystemDocumentTemplatesQuery(\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  adoptedSystemDocumentTemplates(sort: $sort) {\n    adoptedSystemDocumentTemplates(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          total\n          adopted\n          document {\n            pk\n            id\n          }\n        }\n      }\n    }\n    error {\n      statusCode\n    }\n    otherTabTotal\n  }\n}\n";
(node/*: any*/).hash = '9a749050a5ea17dec0c3791386822474';
module.exports = node;
