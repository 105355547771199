import { css } from "styled-components";
import { themeGet } from "styled-system";

import { lineHeight } from "pstat-design-system/utils";

const media = {
  handheld: (...args) => css`
    @media (max-width: ${themeGet("breakpoints.0")}) {
      ${css(...args)};
    }
  `
};

// this is used for html that is rendered by react
// eg. for policy content
// eg. for comments that contain markdown
const innerHtmlStyles = css`
  & a {
    font-size: ${themeGet("fontSizes.2")};
    color: ${themeGet("colors.secondary.0")};
    text-decoration: none;
    background: linear-gradient(
      to bottom,
      ${themeGet("colors.primary.0")} 0%,
      ${themeGet("colors.primary.0")} 100%
    );
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 1px 2px;
    transition: background-size 0.2s;
  }
  & a:hover,
  & a:active {
    background-size: 1px calc(${themeGet("fontSizes.2")} + 2px);
  }
  & a:focus {
    outline: dashed 1px;
    outline-color: ${themeGet("colors.nav.25")};
  }

  /* for CK5, the default CK5 styles override these without the added .ck-content class */
  & blockquote,
  & .ck-content blockquote {
    color: ${themeGet("colors.nav.25")};
    margin-left: ${themeGet("space.10")}px;
    margin-right: ${themeGet("space.10")}px;
    border-left: solid 4px ${themeGet("colors.nav.80")};
    padding-left: ${themeGet("space.3")}px;

    ${media.handheld`
      margin: 0;
      padding-left: ${themeGet("space.2")}px;
    `};
  }

  /* for CK5, the default CK5 styles override these without the added .ck-content class */
  & blockquote *,
  & .ck-content blockquote * {
    color: ${themeGet("colors.nav.25")};
  }

  & .center {
    text-align: center;
  }

  & em {
    font-style: italic;
  }

  & figure {
    display: table;
  }

  & figcaption {
    caption-side: bottom;
    font-size: ${themeGet("fontSizes.1")};
    font-style: italic;
    padding-bottom: ${themeGet("space.2")}px;
    border-bottom: ${themeGet("borders.1")};
    border-color: ${themeGet("colors.nav.80")};
    margin-top: ${themeGet("space.4")}px;
    margin-bottom: ${themeGet("space.7")}px;
  }

  & h1 {
    font-weight: ${themeGet("fontWeights.bold")};
    font-size: ${themeGet("fontSizes.8")};
    ${media.handheld`
      font-size: ${themeGet("fontSizes.6")}
    `};
    margin-bottom: ${themeGet("space.4")}px;
  }

  & h2 {
    font-weight: ${themeGet("fontWeights.bold")};
    font-size: ${themeGet("fontSizes.8")};
    ${media.handheld`
      font-size: ${themeGet("fontSizes.6")}
    `};
    margin-bottom: ${themeGet("space.4")}px;
  }

  & h3 {
    font-weight: ${themeGet("fontWeights.bold")};
    font-size: ${themeGet("fontSizes.5")};
    margin-bottom: ${themeGet("space.4")}px;
  }

  & h4 {
    font-weight: ${themeGet("fontWeights.bold")};
    font-size: ${themeGet("fontSizes.4")};
    margin-bottom: ${themeGet("space.3")}px;
  }

  & h5 {
    font-weight: ${themeGet("fontWeights.bold")};
    font-size: ${themeGet("fontSizes.3")};
    margin-bottom: ${themeGet("space.3")}px;
  }

  & h6 {
    font-weight: ${themeGet("fontWeights.bold")};
    font-size: ${themeGet("fontSizes.2")};
    margin-bottom: ${themeGet("space.3")}px;
  }

  & h1 strong,
  & h2 strong,
  & h3 strong,
  & h4 strong,
  & h5 strong,
  & h6 strong {
    font-weight: ${themeGet("fontWeights.bold")};
  }

  & hr {
    border: 0;
    border-top: ${themeGet("borders.1")};
    border-color: ${themeGet("colors.nav.80")};
    margin: 0;
    margin-bottom: ${themeGet("space.4")}px;
  }
  & img {
    max-width: 100%;
    vertical-align: middle;
  }

  & #editor-content iframe {
    width: unset !important;
    height: unset !important;
    width: 100% !important;
    aspect-ratio: 16/9;
  }

  & .highlight {
    background: ${themeGet("colors.primary.0")};
  }

  & .underline {
    text-decoration: underline;
  }

  & li {
    margin-bottom: ${themeGet("space.3")}px;
  }

  & li:first-child {
    margin-top: ${themeGet("space.3")}px;
  }

  & ol {
    list-style: upper-alpha;
    margin-bottom: ${themeGet("space.3")}px;
    padding-left: ${themeGet("space.8")}px;
  }

  & ol ol {
    list-style: decimal;
    margin-left: ${themeGet("space.8")}px;
  }

  & ol ol ol {
    list-style: lower-alpha;
  }

  & ol ol ol ol {
    list-style: lower-roman;
  }

  & ol ol ol ol ol {
    list-style: lower-alpha;
  }

  & ol ol ol ol ol ol {
    list-style: lower-roman;
  }

  & ol.list-decimal {
    list-style-type: decimal;
  }
  & ol.list-lower-alpha {
    list-style-type: lower-alpha;
  }
  & ol.list-upper-alpha {
    list-style-type: upper-alpha;
  }
  & ol.list-lower-roman {
    list-style-type: lower-roman;
  }
  & ol.list-upper-roman {
    list-style-type: upper-roman;
  }

  & p {
    ${lineHeight};
    margin-bottom: ${themeGet("space.3")}px;
  }

  & pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 20px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre;
    white-space: pre-wrap;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    font-family: Monaco, Menlo, Consolas, "Courier New", monospace;

    color: #333;
  }

  & strong {
    font-weight: ${themeGet("fontWeights.medium")};
  }

  & table {
    text-align: left;
    border-spacing: 0;
    padding: 0;
    border-collapse: collapse;
    margin-bottom: ${themeGet("space.3")}px;
  }

  & th {
    padding: 12px;
    border: thin solid ${themeGet("colors.nav.80")};
  }

  & thead {
    background-color: ${themeGet("colors.nav.95")};
    border: thin solid ${themeGet("colors.nav.80")};
  }

  & tr {
    border-top: thin solid ${themeGet("colors.nav.80")};
    border-bottom: thin solid ${themeGet("colors.nav.80")};
  }

  & td {
    border: thin solid ${themeGet("colors.nav.80")};
    empty-cells: show;
    padding: 3px 5px;
    vertical-align: top;
    padding: 12px;
  }

  & ul {
    list-style: disc;
    margin-bottom: ${themeGet("space.3")}px;
    padding-left: ${themeGet("space.8")}px;
  }

  & ul ul {
    list-style: circle;
    margin-left: ${themeGet("space.8")}px;
  }

  & ul ul ul {
    list-style: square;
  }

  & ol ul {
    margin-left: ${themeGet("space.8")}px;
  }

  & ul ol {
    margin-left: ${themeGet("space.8")}px;
  }
`;

export default innerHtmlStyles;
