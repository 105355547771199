import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation AddCommentMutation($input: AddCommentToDocumentInput!) {
    addComment(input: $input) {
      ok
      reasons
      documentAction {
        id
        pk
        canEdit
        actionType
        actionDate
        comment
        isExternal
        user {
          id
          userDisplay
        }
      }
    }
  }
`;

export const addCommentToDocument = (
  environment,
  document,
  documentToAddComment,
  comment,
  isEditPage,
  callback,
  userPks,
  groupPks
) => {
  const variables = {
    input: {
      documentPk: documentToAddComment.pk,
      comment,
      userPks,
      groupPks
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    updater: store => {
      const payload = store.getRootField("addComment");
      const newCommentAction = payload.getLinkedRecord("documentAction");
      const storedDocument = store.get(document.id);
      if (!storedDocument) {
        return;
      }
      const storedActions =
        storedDocument.getLinkedRecords("actions", { isEditPage }) || [];
      const actions = [...storedActions, newCommentAction];
      storedDocument.setLinkedRecords(actions, "actions", { isEditPage });
    },
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
