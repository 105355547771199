import { useTranslation } from "react-i18next";

import LoadingModal from "pstat-design-system/modal/LoadingModal";

const ShowChangesLoadingModal = ({ isOpen }) => {
  const { t } = useTranslation();
  return (
    <LoadingModal
      isOpen={isOpen}
      parentSelector={() =>
        document.getElementById("view-content") || document.body
      }
      text={t("documentControl.showChangesLoading")}
    />
  );
};

export default ShowChangesLoadingModal;
