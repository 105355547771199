import PolicyEditor from "pstat-anywhere/components/document_control/PolicyEditor";
import { OVERRIDE_TYPE } from "pstat-anywhere/components/document_control/PolicyEditor/utils";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";

// @flow
type Props = {
  documentOverride: Object
};

const OverridePolicyPage = (props: Props) => {
  if (props.documentOverride && props.documentOverride.document) {
    return (
      <PolicyEditor
        error={false}
        document={props.documentOverride.document}
        editForm={props.documentOverride.editForm}
        tagForm={props.documentOverride.tagForm}
        saveForm={props.documentOverride.saveForm}
        scheduledEffectiveDateForm={
          props.documentOverride.scheduledEffectiveDateForm
        }
        systemDocumentTemplateForm={
          props.documentOverride.systemDocumentTemplateForm
        }
        preimportRevisionForm={props.documentOverride.preimportRevisionForm}
        policyActions={props.documentOverride.policyActions}
        route={props.route}
        editorPropertiesOverride={props.documentOverride}
        type={OVERRIDE_TYPE}
        enabledAutoSaveTimeout={false}
        canLocalize={props.documentOverride.canLocalize}
      />
    );
  } else {
    return <LoadingSpinner size={100} />;
  }
};

export default OverridePolicyPage;
