import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { getColumnPresets } from "pstat-design-system/tables/PolicyTable/config";
import { frozen } from "pstat-design-system/tables/utils/transforms";

// TODO: lot of the these columns are going to be repeated across the different tables.
// Might want to look into doing something like pstat where every possible column is defined once
// and then you can pick which ones you want for whatever table

export const setLabelsFromGraphQL = (columns, labels) => {
  return columns.map(column => {
    const { graphqlLabel, graphqlLabelName } = column;
    if (graphqlLabelName) {
      const graphqlLabel = titleCase(labels[graphqlLabelName]);
      column.header.label = graphqlLabel;
      column.cell.props.label = graphqlLabel;
    } else if (graphqlLabel) {
      const label = titleCase(labels[graphqlLabel.labelName]);
      column.header.label = graphqlLabel.getLabelText(label);
      column.cell.props.label = graphqlLabel.getLabelText(label);
    }
    return column;
  });
};

export const setFrozenColumn = columns => {
  const leftColumn = columns[0];
  leftColumn.frozen = true;
  if (!leftColumn.header.transforms) {
    leftColumn.header.transforms = [];
  }
  leftColumn.header.transforms.push(frozen);
};

// given the sort string from the URL, get the sort object needed for sortabular
// to initialize the column that is starting as sorted
// For example, if sortString is "name", need to get
//
// 0: {
//   direction: "asc",
//   position: 0
// }
//
// from the column definitions above
export const getSort = (tableType, sortString, t, otherArgs = {}) => {
  const columns = getColumnPresets(null, null, t, otherArgs)[tableType];
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].sort && columns[i].sort[sortString]) {
      return columns[i].sort[sortString];
    }
  }
  return null;
};

const getDefaultSortColumn = (tableType, t, otherArgs = {}) => {
  const columns = getColumnPresets(null, null, t, otherArgs)[tableType];
  const defaultColumns = columns.filter(
    column => column.sort && column.sort.default
  );

  return defaultColumns ? defaultColumns[0] : null;
};
/*
 * return the default sort object
 */
export const getDefaultSortObject = (tableType, t, otherArgs = {}) => {
  const defaultSortColumn = getDefaultSortColumn(tableType, t, otherArgs);

  return defaultSortColumn
    ? defaultSortColumn.sort[defaultSortColumn.sort.default]
    : null;
};

/*
 * returns default sort string
 */
export const getDefaultSortString = (tableType, t) => {
  const defaultSortColumn = getDefaultSortColumn(tableType, t);

  return defaultSortColumn ? defaultSortColumn.sort.default : null;
};

// this is the reverse of getSort. Given the current column sort obj
// from sortabular, need to get the correct string to send to the backend
// for sorting
// For example if the sortDirection is "asc" and the columnIndex is 3,
// this looks through the column definition above, gets the column at index 3,
// and then finds the matching string for the sort direction, which would be
// "bloc"
export const getSortString = (
  tableType,
  sortDirection,
  columnIndex,
  t,
  otherArgs = {}
) => {
  const columns = getColumnPresets(null, null, t, otherArgs)[tableType];
  const sortedColumn = columns[columnIndex];
  const sortStrings = Object.keys(sortedColumn.sort);

  for (let i = 0; i < sortStrings.length; i++) {
    const sortObj = sortedColumn.sort[sortStrings[i]];
    if (sortObj[columnIndex].direction === sortDirection) {
      return sortStrings[i];
    }
  }
  return null;
};

export const onRow = row => ({
  to: {
    pathname: `${getRootUrl()}/policy/${row.pk}/latest`
  }
});

export const parseVariablesWithDefaultSort = ({
  variables,
  tableType,
  t,
  noDefaultSort
}) => {
  if (variables.sort || noDefaultSort) {
    return variables;
  }

  return {
    ...variables,
    sort: getDefaultSortString(tableType, t)
  };
};
