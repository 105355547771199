import graphql from "babel-plugin-relay/macro";

export const SearchFiltersQuery = graphql`
  query SearchFiltersQuery {
    authors {
      pk
      userDisplay
    }
    categoriesWithActiveDocuments {
      pk
      name
    }
    tagsOnActiveDocuments {
      pk
      name
    }
  }
`;

export default SearchFiltersQuery;
