// @flow

// tried playing with enum but not a good idea
type Levels = number;
export type HeadingNode = {
  id: string,
  parent: ?HeadingNode,
  level: Levels,
  content: string,
  children: Array<HeadingNode>,
  className: string
};

export const htmlStringToDocument = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html").documentElement;

  return doc;
};

export const getAllHeadingsWithIds = (doc: HTMLElement) => {
  if (doc) {
    return doc.querySelectorAll(
      "h1[id], h2[id], h3[id], h4[id], h5[id], h6[id]"
    );
  }
};

/**
 * Given a flat NodeList of HTMLelements,
 * create a nested array structure
 * @param {NodeList<HTMLElement>} nodelist
 */
const buildToCFromNodeList = (
  nodelist: NodeList<HTMLElement>,
  minLevel: number
) => {
  const root = makeNullHeadingNode(null, minLevel);

  let lastNode = root;

  for (let i = 0; i < nodelist.length; i++) {
    let currentEl = nodelist[i];

    let lastLevel = lastNode.level;
    let currentLevel = getHeadingLevel(currentEl);

    if (currentLevel === lastLevel) {
      // is a sibling, add to the current node's parent
      lastNode = makeHeadingObject(currentEl, lastNode.parent);
    } else if (currentLevel > lastLevel) {
      // add as a descendant
      lastNode = addBelow(currentEl, lastNode);
    } else {
      lastNode = addAbove(currentEl, lastNode);
    }
  }

  return root;
};

const getHeadingLevel = (heading: HTMLElement) => {
  const level = (parseInt(heading.nodeName.split("H").join(""), 10): Levels);
  return level;
};

/**
 * Given a heading and a leaf, add the needed empty nodes
 * in between before creating a node for the heading
 * @param {*} heading
 * @param {*} leaf
 */
export const addBelow = (heading: HTMLElement, leaf: HeadingNode) => {
  const targetLevel = getHeadingLevel(heading);
  let currentNode = leaf;

  // create the necessary null nodes in between

  for (
    let currentLevel = leaf.level;
    currentLevel < targetLevel - 1;
    ++currentLevel
  ) {
    currentNode = makeNullHeadingNode(currentNode);
  }

  return makeHeadingObject(heading, currentNode);
};

/**
 * Given a heading and a leaf, traverse upwards
 * and find an appropriate home to insert the new heading
 * @param {*} heading
 * @param {*} leaf
 */
export const addAbove = (heading: HTMLElement, leaf: HeadingNode) => {
  const targetLevel = getHeadingLevel(heading);

  // we don't have to worry about skip level here -
  // we create all levels on the way down
  // we just need to follow the parents until we find the right level

  let currentNode = leaf;

  // find who would be the sibling of this new heading
  while (currentNode.level > targetLevel && currentNode.parent) {
    currentNode = currentNode.parent;
  }

  // add it as a sibling

  return makeHeadingObject(heading, currentNode.parent);
};

export const makeHeadingObject = (
  heading: HTMLElement,
  parent: ?HeadingNode
) => {
  const newObj: HeadingNode = {
    id: heading.id,
    level: getHeadingLevel(heading),
    children: [],
    content: heading.innerHTML,
    className: heading.className,
    parent: parent
  };

  if (parent) {
    parent.children.push(newObj);
  }
  return newObj;
};

export const makeNullHeadingNode = (
  parent: ?HeadingNode,
  baseLevel: number = 1
) => {
  const newObj: HeadingNode = {
    id: "",
    level: parent ? parent.level + 1 : baseLevel - 1,
    children: [],
    content: "",
    parent: parent,
    className: ""
  };
  if (parent) {
    parent.children.push(newObj);
  }
  return newObj;
};

const getBaseLevelOfHeadings = (headingsList: NodeList<HTMLElement>) => {
  const headingsArray: Array<HTMLElement> = Array.from(headingsList);

  return Math.min(...headingsArray.map(heading => getHeadingLevel(heading)), 6);
};

export const documentToHeadingTree = (doc: HTMLElement) => {
  const headings = getAllHeadingsWithIds(doc);

  const baseLevel = getBaseLevelOfHeadings(headings);

  return buildToCFromNodeList(headings, baseLevel);
};

export const htmlStringToHeadingTree = (html: string) => {
  const doc = htmlStringToDocument(html);
  if (doc) {
    return documentToHeadingTree(doc);
  } else {
    // return empty 'tree'
    return makeNullHeadingNode();
  }
};
