import { Component, forwardRef } from "react";
import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import colors from "pstat-anywhere/themes/policystat/colors";
import theme from "pstat-anywhere/themes/policystat/theme";
import AutosizeInput from "pstat-design-system/inputs/AutosizeInput";
import ClearIcon from "pstat-design-system/inputs/ClearIcon";

const TextInput = system(
  {
    is: "input",
    borderRadius: 1,
    pl: [3],
    pr: [3],
    py: [3], // no guidance for this, testing
    fontFamily: theme.fontFamily,
    fontSize: 2,
    fontWeight: "normal",
    bg: "nav.100",
    boxShadow: `inset 1px 1px 0 0 ${colors.nav["90"]}`,
    border: theme.borders[1],
    borderColor: "nav.80",
    color: "nav.0",
    focus: {
      border: theme.borders[1],
      borderColor: "secondary.0"
    },
    disabledStyle: {
      "background-color": colors.nav["95"],
      cursor: "not-allowed",
      color: colors.nav["55"]
    }
  },
  "space",
  "width",
  "maxWidth",
  "fontSize",
  "fontWeight",
  "height"
).extend`
  box-sizing: border-box;

  /* cheat - can't use outline */
  outline: none;

  &::placeholder {
    color: ${colors.nav["25"]};
  }

  &:invalid:not(:focus) {
    border: solid 2px ${colors.messages["error"]};
  }
  ${props =>
    props.error &&
    `
    &:not(:focus) {
      border: solid 2px ${colors.messages["error"]};
    }
  `}

  ${props =>
    props.removeIEClearIcon &&
    `
    &::-ms-clear {
      width : 0;
      height: 0;
    }
  `}
`;

TextInput.displayName = "TextInput";

export const ForwardingTextInput = forwardRef((props, ref) => {
  return <TextInput {...props} innerRef={ref} />;
});

const BorderlessTextInput = system({
  is: ForwardingTextInput,
  border: 0,
  boxShadow: "none",
  focus: {
    border: 0
  }
}).extend`
  &:invalid:not(:focus) {
    border: 0;
  }
`;

BorderlessTextInput.displayName = "BorderlessTextInput";

const TextContainer = system(
  {
    borderRadius: 1
  },
  "flex",
  "width",
  "minWidth"
).extend`
  display: inline-block;
  position: relative;

  &:first-child:not(:last-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &:not(:first-child):not(:last-child) {
    border-radius: 0px;
  }
`;

TextContainer.displayName = "TextContainer";

const InputIcon = system({
  is: StyledFontAwesomeIcon,
  color: "nav.55"
}).extend`
  position: absolute;
  ${props =>
    props.side === "left" &&
    `
    top: ${theme.space[3]}px;
    left: ${theme.space[3]}px;
  `}
  ${props =>
    props.side === "right" &&
    `
    top: ${theme.space[3]}px;
    right: ${theme.space[3]}px;
  `}
  ${props => !props.iconClickable && "pointer-events: none;"}
`;

InputIcon.displayName = "InputIcon";

const BaseTextInputWithIcon = ({
  icon,
  onClick,
  forwardedRef,
  side,
  iconClickable,
  containerProps,
  ...otherProps
}) => {
  const inputProps = {
    innerRef: forwardedRef,
    borderRadius: "inherit",
    width: "100%",
    removeIEClearIcon: true,
    ...otherProps
  };
  if (side === "right") {
    inputProps.pr = 10;
  } else {
    inputProps.pl = 10;
  }
  return (
    <TextContainer width="100%" {...containerProps}>
      <InputIcon
        icon={icon}
        size="lg"
        onClick={onClick}
        side={side}
        iconClickable={iconClickable}
      />
      <TextInput {...inputProps} />
    </TextContainer>
  );
};

export const TextInputWithIcon = forwardRef((props, ref) => {
  return <BaseTextInputWithIcon {...props} forwardedRef={ref} />;
});

export class BaseClearableTextInput extends Component {
  handleClear = () => {
    const { onClear } = this.props;
    onClear && onClear();
  };

  render() {
    const {
      forwardedRef,
      onClear,
      id,
      error,
      type,
      name,
      label,
      value,
      onChange,
      dataTestid,
      required,
      autosize,
      disabled,
      ...otherProps
    } = this.props;
    return (
      <TextContainer width={!autosize && "100%"}>
        {onClear &&
          this.props.value &&
          this.props.value !== "" &&
          !disabled && (
            <ClearIcon
              size="lg"
              onClick={this.handleClear}
              data-testid="fa-clear-input-icon"
            />
          )}
        {autosize ? (
          <AutosizeInput
            id={id}
            error={error}
            type={type}
            name={name || (label && label.toLowerCase())}
            value={value}
            onChange={onChange}
            aria-label={label}
            label={label}
            data-testid={dataTestid}
            required={required}
            innerRef={forwardedRef}
            borderRadius="inherit"
            {...otherProps}
            pr={8}
            width="100%"
            maxWidth="100%"
            removeIEClearIcon={true}
            disabled={disabled}
          />
        ) : (
          <TextInput
            id={id}
            error={error}
            type={type}
            name={name || (label && label.toLowerCase())}
            value={value}
            onChange={onChange}
            aria-label={label}
            data-testid={dataTestid}
            required={required}
            innerRef={forwardedRef}
            borderRadius="inherit"
            {...otherProps}
            pr={8}
            width="100%"
            removeIEClearIcon={true}
            disabled={disabled}
          />
        )}
      </TextContainer>
    );
  }
}

export const ClearableTextInput = forwardRef((props, ref) => {
  return <BaseClearableTextInput {...props} forwardedRef={ref} />;
});

export class BorderlessBaseClearableTextInput extends Component {
  constructor(props) {
    super(props);

    this.handleClear = this.handleClear.bind(this);
  }

  handleClear() {
    if (this.props.onClear) {
      this.props.onClear();
    }
  }

  render() {
    const { forwardedRef, onClear, type, ...otherProps } = this.props;
    return (
      <TextContainer flex={1} minWidth={type === "date" ? 200 : 100}>
        {onClear && this.props.value && this.props.value !== "" && (
          <ClearIcon
            size="lg"
            onClick={this.handleClear}
            data-testid="fa-clear-input-icon"
          />
        )}
        <BorderlessTextInput
          innerRef={forwardedRef}
          borderRadius="inherit"
          type={type}
          {...otherProps}
          pr={8}
          width="100%"
          removeIEClearIcon={true}
        />
      </TextContainer>
    );
  }
}

export const BorderlessClearableTextInput = forwardRef((props, ref) => {
  return <BorderlessBaseClearableTextInput {...props} forwardedRef={ref} />;
});

export default TextInput;
