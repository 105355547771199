/**
 * @flow
 * @relayHash ec070c6e9c7287429788e4e8fab7324f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnsubscribeFromCommentsMutationVariables = {|
  documentPk: number
|};
export type UnsubscribeFromCommentsMutationResponse = {|
  +unsubscribeFromComments: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type UnsubscribeFromCommentsMutation = {|
  variables: UnsubscribeFromCommentsMutationVariables,
  response: UnsubscribeFromCommentsMutationResponse,
|};
*/


/*
mutation UnsubscribeFromCommentsMutation(
  $documentPk: Int!
) {
  unsubscribeFromComments(documentPk: $documentPk) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "unsubscribeFromComments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentPk",
        "variableName": "documentPk",
        "type": "Int!"
      }
    ],
    "concreteType": "UnsubscribeFromCommentsMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UnsubscribeFromCommentsMutation",
  "id": "8548e292435e27d34fc3e8ac07eab5f0",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnsubscribeFromCommentsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UnsubscribeFromCommentsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation UnsubscribeFromCommentsMutation(\n  $documentPk: Int!\n) {\n  unsubscribeFromComments(documentPk: $documentPk) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = '83657000a9a3b1764166bfc3a69be272';
module.exports = node;
