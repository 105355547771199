import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { ContentContainerFlex } from "../../pages";
import { Dropdown } from "../DropdownMenu";

import { Text } from "pstat-design-system/text";
import Sticky from "pstat-design-system/Sticky";
import { Button } from "pstat-design-system/shared/Button";
import theme from "pstat-anywhere/themes/policystat/theme";

// @flow

export const TERTIARY_NAVBAR_HEIGHT = 52;

const NavContainer = styled.div`
  height: ${TERTIARY_NAVBAR_HEIGHT}px;
  background-color: ${themeGet("colors.nav.100")};
  box-shadow: 2px 2px 0 0 hsla(216, 17%, 83%, 0.5);
`;

const TopStickyContainer = ({ children }) => (
  <Sticky
    id="tertiary_nav"
    offset={0}
    zIndex={theme.layers.tertiaryNav}
    width="100%"
  >
    {children}
  </Sticky>
);

const BottomContainer = styled.div`
  bottom: 0;
  position: fixed;
  border-top: ${themeGet("borders.1")};
  border-color: ${themeGet("colors.nav.80")};
  z-index: ${themeGet("layers.activeFloatingUi")};
  width: 100%;
`;

const Nav = ({ children }) => (
  <NavContainer>
    <ContentContainerFlex
      data-testid="tertiary-nav-container"
      justifyContent="space-between"
      px={4}
      height="100%"
    >
      {children}
    </ContentContainerFlex>
  </NavContainer>
);

export const TopNav = ({ children }) => (
  <TopStickyContainer>
    <Nav>{children}</Nav>
  </TopStickyContainer>
);

const StyledBottomNavSpacer = styled.div`
  height: ${TERTIARY_NAVBAR_HEIGHT}px;
`;

const BottomNavSpacer = props => {
  const footer = document.getElementById("footer");
  if (footer) {
    return ReactDOM.createPortal(<StyledBottomNavSpacer />, footer);
  }
  return null;
};

export const BottomNav = ({ children }) => {
  return (
    <Fragment>
      <BottomContainer>
        <Nav>{children}</Nav>
      </BottomContainer>
      <BottomNavSpacer />
    </Fragment>
  );
};

const DropdownListItem = styled.li`
  padding-top: ${themeGet("space.4")}px;
  padding-bottom: ${themeGet("space.4")}px;
  padding-left: ${themeGet("space.5")}px;
  padding-right: ${themeGet("space.5")}px;
  min-width: 200px;
  border-bottom: ${themeGet("borders.1")};
  border-color: ${themeGet("colors.nav.90")};
  background-color: ${themeGet("colors.nav.100")};

  cursor: ${props => (props.disabled ? `not-allowed` : `pointer`)};
  white-space: nowrap;
`;

export const DropdownItem = ({
  children,
  disabled,
  onClick,
  ...otherProps
}) => {
  const handleKeydown = event => {
    if (disabled) {
      return;
    }
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onClick(event);
    }
  };
  return (
    <DropdownListItem
      tabIndex="0"
      onClick={onClick}
      onKeyDown={handleKeydown}
      disabled={disabled}
      {...otherProps}
    >
      <Text color={disabled ? "nav.90" : null}>{children}</Text>
    </DropdownListItem>
  );
};

export const ActionsDropdown = props => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Dropdown
      dropdownSelector={props => (
        <Button buttonStyle="tertiary" onClick={() => setExpanded(true)}>
          Actions...
        </Button>
      )}
      expanded={expanded}
      rtl={false}
      toggle={() => setExpanded(!expanded)}
      desktop={true}
      closeMenu={() => setExpanded(false)}
      up={true}
    >
      {props.children}
    </Dropdown>
  );
};
