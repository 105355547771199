/**
 * @flow
 * @relayHash 24e820fac3bba88f0e7d9d772aae756d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UnpublishSystemDocumentTemplateInput = {
  systemDocumentTemplate: number,
  clientMutationId?: ?string,
};
export type UnpublishTemplateMutationVariables = {|
  input: UnpublishSystemDocumentTemplateInput
|};
export type UnpublishTemplateMutationResponse = {|
  +unpublishSystemDocumentTemplate: ?{|
    +ok: ?boolean,
    +result: ?any,
    +error: ?any,
  |}
|};
export type UnpublishTemplateMutation = {|
  variables: UnpublishTemplateMutationVariables,
  response: UnpublishTemplateMutationResponse,
|};
*/


/*
mutation UnpublishTemplateMutation(
  $input: UnpublishSystemDocumentTemplateInput!
) {
  unpublishSystemDocumentTemplate(input: $input) {
    ok
    result
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UnpublishSystemDocumentTemplateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "unpublishSystemDocumentTemplate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UnpublishSystemDocumentTemplateInput!"
      }
    ],
    "concreteType": "UnpublishSystemDocumentTemplatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "result",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "error",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UnpublishTemplateMutation",
  "id": "dd037f25861679b7eb50c0ae69871173",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnpublishTemplateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UnpublishTemplateMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation UnpublishTemplateMutation(\n  $input: UnpublishSystemDocumentTemplateInput!\n) {\n  unpublishSystemDocumentTemplate(input: $input) {\n    ok\n    result\n    error\n  }\n}\n";
(node/*: any*/).hash = '890673aa72dff3839de74a63bc43aa64';
module.exports = node;
