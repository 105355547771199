/**
 * @flow
 * @relayHash a05796c62661fea2e41c7fc3e83f335d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentBuildMutationInput = {
  documentFile: any,
  clientMutationId?: ?string,
};
export type documentBuildMutationVariables = {|
  input: DocumentBuildMutationInput
|};
export type documentBuildMutationResponse = {|
  +documentBuild: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +taskId: ?string,
    +name: ?string,
  |}
|};
export type documentBuildMutation = {|
  variables: documentBuildMutationVariables,
  response: documentBuildMutationResponse,
|};
*/


/*
mutation documentBuildMutation(
  $input: DocumentBuildMutationInput!
) {
  documentBuild(input: $input) {
    ok
    reasons
    taskId
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DocumentBuildMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentBuild",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DocumentBuildMutationInput!"
      }
    ],
    "concreteType": "DocumentBuildMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "documentBuildMutation",
  "id": "4a9a2434e92d0b4397e5acf1fb4ef04d",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "documentBuildMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "documentBuildMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation documentBuildMutation(\n  $input: DocumentBuildMutationInput!\n) {\n  documentBuild(input: $input) {\n    ok\n    reasons\n    taskId\n    name\n  }\n}\n";
(node/*: any*/).hash = 'a176d3487b9e9fdc83f0d0f81a57df76';
module.exports = node;
