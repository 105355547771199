import { Flex } from "grid-styled";
import system from "system-components";

export const CaptionContainer = system({
  bg: "transparent",
  mt: 5,
  pb: 2,
  px: [4, 4, 0]
});

export const Caption = props => (
  <CaptionContainer {...props}>
    <Flex alignItems="center" justify="space-between">
      {props.children}
    </Flex>
  </CaptionContainer>
);

export default Caption;
