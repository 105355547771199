import { useTranslation } from "react-i18next";

import { ContentContainer } from "../../pages";

import { AdvancedReportPage } from "pstat-anywhere/components/pages/reports";
import useQueryVariables from "pstat-anywhere/components/pages/reports/hooks/useQueryVariables";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import titleCase from "pstat-anywhere/utils/titleCase";


const addIsEditableToVersions = (versions, editablePks) => {
  if (versions?.edges) {
    const versionEdges = versions.edges;
    const updatedVersionEdges = versionEdges.map(edge => {
      const version = edge.node;
      let updatedVersion;
      if (version.pk && editablePks.indexOf(version.pk) > -1) {
        updatedVersion = { ...version, isEditable: true };
      } else {
        updatedVersion = { ...version, isEditable: false };
      }
      return { ...edge, node: updatedVersion };
    });
    versions.edges = updatedVersionEdges;
  }
};

const PolicyRevisions = ({
  documentRevisions,
  route,
  router,
  location,
  labels,
  tenant
}) => {
  const { t } = useTranslation();
  const { variables } = useQueryVariables({ location });
  const versions = {
    totalCount:
      documentRevisions?.versions.totalCount +
      documentRevisions?.preimportVersions.totalCount,
    edges: documentRevisions?.versions.edges.concat(
      documentRevisions?.preimportVersions.edges
    )
  };
  const editablePks = documentRevisions?.editablePks;
  addIsEditableToVersions(versions, editablePks);
  return (
    <ContentContainer>
      <AdvancedReportPage
        variables={variables}
        data={versions}
        route={route}
        router={router}
        location={location}
        headerText={t("documentControl.revisions.heading", {
          documentLabel: titleCase(labels.documentLabel)
        })}
        noContentMessage={t("documentControl.revisions.noResults")}
        showActionButtonPanel={false}
        showTabsPanel={false}
        tableType={
          tenant.hasScheduledEffectiveDateEnabled
            ? "documentRevisionsWithSED"
            : "documentRevisions"
        }
        frozenColumnTable={true}
        noSort={true}
        rowKey={({ rowData }) => `${rowData.pk}_${rowData.approvalDate}`}
      />
    </ContentContainer>
  );
};

export default withTenantContext(withLabelContext(PolicyRevisions));
