/**
 * @flow
 * @relayHash 211f52612f4fb51606459a13d49efc81
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AttachmentContentType = "DOCUMENT" | "DOCUMENT_IMPORT" | "DOCUMENT_SAVE" | "%future added value";
export type documentAttachmentsQueryVariables = {|
  objectPk: number,
  objectType: AttachmentContentType,
|};
export type documentAttachmentsQueryResponse = {|
  +documentAttachments: ?$ReadOnlyArray<?{|
    +pk: ?number,
    +title: ?string,
    +fileUrl: ?string,
    +isImage: ?boolean,
  |}>
|};
export type documentAttachmentsQuery = {|
  variables: documentAttachmentsQueryVariables,
  response: documentAttachmentsQueryResponse,
|};
*/


/*
query documentAttachmentsQuery(
  $objectPk: Int!
  $objectType: AttachmentContentType!
) {
  documentAttachments(objectPk: $objectPk, objectType: $objectType) {
    pk
    title
    fileUrl
    isImage
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "objectPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "objectType",
    "type": "AttachmentContentType!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objectPk",
    "variableName": "objectPk",
    "type": "Int!"
  },
  {
    "kind": "Variable",
    "name": "objectType",
    "variableName": "objectType",
    "type": "AttachmentContentType!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileUrl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isImage",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "documentAttachmentsQuery",
  "id": "ab062853a7ce17f0ca9cd3d823930e89",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "documentAttachmentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentAttachments",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentAttachmentType",
        "plural": true,
        "selections": [
          v2,
          v3,
          v4,
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "documentAttachmentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentAttachments",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentAttachmentType",
        "plural": true,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
node.text = "query documentAttachmentsQuery(\n  $objectPk: Int!\n  $objectType: AttachmentContentType!\n) {\n  documentAttachments(objectPk: $objectPk, objectType: $objectType) {\n    pk\n    title\n    fileUrl\n    isImage\n    id\n  }\n}\n";
(node/*: any*/).hash = '1b866da70676a9d9ba4b1af2471c4fb7';
module.exports = node;
