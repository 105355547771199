import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { approvePendingDocument } from "../view_policy/ApprovePendingDocumentMutation";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import { TextareaFormElement } from "pstat-design-system/inputs/FormElements";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import { ExpandablePanel } from "pstat-design-system/Panel";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";
import Tooltip, {
  HOVER_EVENTS,
  HOVER_EVENTS_OFF
} from "pstat-design-system/Tooltip";


const OptionalApproval = ({ document, labels }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const handleCommentChange = event => {
    const comment = event.target.value;
    setComment(comment);
  };
  const doApproval = () => {
    approvePendingDocument(
      environment,
      document.pk,
      comment,
      onApprovalCompleted
    );
  };
  const onApprovalCompleted = (response, errors) => {
    if (errors) {
      createErrorMessage(
        t("documentControl.approve.optionalApproval.errorMessage")
      );
    } else if (!response.approvePendingDocument.ok) {
      const reasons = response.approvePendingDocument.reasons;
      if (reasons) {
        const reasonKeys = Object.keys(reasons);
        const error = reasons[reasonKeys[0]];
        createErrorMessage(error);
      }
    } else {
      createSuccessMessage(
        t(
          "documentControl.view.secondaryNav.wrapper.onApprovalCompleted.successMessage"
        )
      );
    }
  };
  return (
    <ExpandablePanel
      id="optional_approval"
      headerText={t("documentControl.approve.optionalApproval.heading")}
      headerChildren={() => (
        <Fragment>
          <StyledFontAwesomeIcon
            icon={["far", "question-circle"]}
            color="secondary.0"
            size="lg"
            tabIndex="0"
            data-tip
            data-for="optional-approval-tooltip"
            data-event={HOVER_EVENTS}
            data-event-off={HOVER_EVENTS_OFF}
          />
          <Tooltip name="optional-approval-tooltip">
            {t("documentControl.approve.optionalApproval.tooltip", {
              documentLabel: labels.documentLabel.toLowerCase()
            })}
          </Tooltip>
        </Fragment>
      )}
    >
      <Form
        comment={comment}
        onCommentChange={handleCommentChange}
        labels={labels}
        onApproval={doApproval}
      />
    </ExpandablePanel>
  );
};

const Form = ({ comment, onCommentChange, labels, onApproval }) => {
  const { t } = useTranslation();
  const documentLabel = labels.documentLabel.toLowerCase();
  const handleClickSubmit = event => {
    onApproval();
  };
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      onApproval();
    }
  };
  return (
    <Fragment>
      <TextareaFormElement
        value={comment}
        placeholder={t(
          "documentControl.approve.optionalApproval.formPlaceholder"
        )}
        onChange={onCommentChange}
        label={t("documentControl.approve.optionalApproval.formLabel")}
        rows={4}
      />
      <Button
        buttonStyle="secondary"
        onClick={handleClickSubmit}
        onKeyDown={handleKeyDown}
      >
        {t("documentControl.approve.optionalApproval.submitButton")}
      </Button>
      <Text display="block" mt={4}>
        {t("documentControl.approve.optionalApproval.infoMessage", {
          documentLabel
        })}
      </Text>
    </Fragment>
  );
};

export default withLabelContext(OptionalApproval);
