/**
 * @flow
 * @relayHash 2922221c8160dd96388c0feaa8ff8b05
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlocDetailPageQueryVariables = {|
  blocPk: number,
  sort?: ?string,
|};
export type BlocDetailPageQueryResponse = {|
  +blocDetails: ?{|
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +bloc: ?{|
      +pk: ?number,
      +name: string,
      +tenants: ?$ReadOnlyArray<?{|
        +pk: ?number,
        +name: string,
        +subdomain: ?string,
        +created: ?string,
      |}>,
      +documentSettings: ?{|
        +pk: ?number
      |},
      +isEditable: ?boolean,
    |},
  |},
  +canAdminTenants: ?{|
    +documentSettings: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +display: ?string,
    |}>
  |},
|};
export type BlocDetailPageQuery = {|
  variables: BlocDetailPageQueryVariables,
  response: BlocDetailPageQueryResponse,
|};
*/


/*
query BlocDetailPageQuery(
  $blocPk: Int!
  $sort: String
) {
  blocDetails(pk: $blocPk) {
    error {
      statusCode
      message
    }
    bloc {
      pk
      name
      tenants(sort: $sort) {
        pk
        name
        subdomain
        created
        id
      }
      documentSettings {
        pk
        id
      }
      isEditable
      id
    }
  }
  canAdminTenants {
    documentSettings {
      pk
      display
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "blocPk",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "blocPk",
    "type": "Int!"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subdomain",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isEditable",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "display",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BlocDetailPageQuery",
  "id": "e360e4c1c5101104dcd7825dcec51ffa",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BlocDetailPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocDetails",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocDetailsPageType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": v5,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v3,
                  v4,
                  v6,
                  v7
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentSettingsType",
                "plural": false,
                "selections": [
                  v3
                ]
              },
              v8
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "canAdminTenants",
        "storageKey": null,
        "args": null,
        "concreteType": "CanAdminTenantsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": true,
            "selections": [
              v3,
              v9
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BlocDetailPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocDetails",
        "storageKey": null,
        "args": v1,
        "concreteType": "BlocDetailsPageType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bloc",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": false,
            "selections": [
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "tenants",
                "storageKey": null,
                "args": v5,
                "concreteType": "TenantType",
                "plural": true,
                "selections": [
                  v3,
                  v4,
                  v6,
                  v7,
                  v10
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "documentSettings",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentSettingsType",
                "plural": false,
                "selections": [
                  v3,
                  v10
                ]
              },
              v8,
              v10
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "canAdminTenants",
        "storageKey": null,
        "args": null,
        "concreteType": "CanAdminTenantsType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": true,
            "selections": [
              v3,
              v9,
              v10
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query BlocDetailPageQuery(\n  $blocPk: Int!\n  $sort: String\n) {\n  blocDetails(pk: $blocPk) {\n    error {\n      statusCode\n      message\n    }\n    bloc {\n      pk\n      name\n      tenants(sort: $sort) {\n        pk\n        name\n        subdomain\n        created\n        id\n      }\n      documentSettings {\n        pk\n        id\n      }\n      isEditable\n      id\n    }\n  }\n  canAdminTenants {\n    documentSettings {\n      pk\n      display\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = 'c3a6ab6ca9ccf27e10f390bab633731a';
module.exports = node;
