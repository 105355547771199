import { useRef, useState } from "react";

import { INPUT_FOCUSED } from "../../AutocompleteTextInput";

import customFetch from "pstat-anywhere/utils/customFetch";

export default function useUserAndUserGroupAutocomplete({ users, groups }) {
  const [suggestions, setSuggestions] = useState([]);

  const userSearchCache = useRef({});
  const groupSearchCache = useRef({});

  const userCache = useRef({});
  const groupCache = useRef({});

  function fetchSuggestions({ value, reason }) {
    if (reason === INPUT_FOCUSED) {
      value = "";
    }

    const userSuggestions = fetchUsersSuggestions(value);
    const groupSuggestions = fetchGroupSuggestions(value);

    return Promise.all([userSuggestions, groupSuggestions]).then(
      suggestions => {
        const [userSuggestions, groupSuggestions] = suggestions;
        updateSuggestions(userSuggestions, groupSuggestions);
        return suggestions;
      }
    );
  }

  async function fetchGroupSuggestions(value) {
    if (!groups) {
      return [];
    }

    const cachedGroupSuggestions = groupSearchCache.current[value];
    if (cachedGroupSuggestions) {
      return cachedGroupSuggestions;
    }

    const groupsUrl = `/api/v1/groups/search.json?q=${value}`;
    const groupSuggestions = await customFetch(groupsUrl)
      .then(response => response.json())
      .then(json => json.objects);

    updateGroupsCache(value, groupSuggestions);

    return groupSuggestions;
  }

  function updateGroupsCache(value, results) {
    groupSearchCache.current = {
      ...groupSearchCache.current,
      [value]: results
    };

    results.forEach(group => {
      groupCache.current[group.id] = group;
    });
  }

  async function fetchUsersSuggestions(value) {
    if (!users) {
      return [];
    }

    const cachedUserSuggestions = userSearchCache.current[value];
    if (cachedUserSuggestions) {
      return cachedUserSuggestions;
    }

    const usersUrl = `/api/v1/users/search.json?q=${value}`;
    const userSuggestions = await customFetch(usersUrl)
      .then(response => response.json())
      .then(json => json.objects);

    updateUsersCache(value, userSuggestions);

    return userSuggestions;
  }

  function updateUsersCache(value, results) {
    userSearchCache.current = {
      ...userSearchCache.current,
      [value]: results
    };

    results.forEach(user => {
      userCache.current[user.id] = user;
    });
  }

  function updateSuggestions(userSuggestions, groupSuggestions) {
    const typedUserSuggestions = addTypeInfoToSuggestion(
      userSuggestions,
      "user"
    );
    const typedGroupSuggestions = addTypeInfoToSuggestion(
      groupSuggestions,
      "group"
    );
    setSuggestions([...typedUserSuggestions, ...typedGroupSuggestions]);
  }

  function addTypeInfoToSuggestion(suggestions, type) {
    return suggestions.map(suggestion => {
      // Using parseInt because this _${type} thingy causing type to being repeated N - 1 times
      // For each next N suggestion
      suggestion.id = `${parseInt(suggestion.id)}_${type}`;
      suggestion.type = type;
      return suggestion;
    });
  }

  function getSuggestionValue(suggestion) {
    if (suggestion.type === "user") {
      return suggestion.display_name;
    } else if (suggestion.type === "group") {
      return `${suggestion.name}`;
    }
  }

  function getSuggestionById(suggestionId) {
    const splitId = suggestionId.split("_");
    const id = splitId[0];
    const type = splitId[1];

    if (type === "user") {
      return userCache.current[id];
    }

    return groupCache.current[id];
  }

  return {
    fetchSuggestions,
    suggestions,
    getSuggestionValue,
    getSuggestionById
  };
}
