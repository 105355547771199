import graphql from "babel-plugin-relay/macro";
import CKEditor from "ckeditor4-react";
import { Suspense, lazy, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { createFragmentContainer } from "react-relay";
import styled from "styled-components";
import { themeGet } from "styled-system";

import AlwaysShowBlocks from "./alwaysShowBlocks/AlwaysShowBlocks";

import Sticky from "pstat-design-system/Sticky";
import { SIDEBAR_MOBILE_HEIGHT } from "pstat-design-system/SideBarMenu";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import theme from "pstat-anywhere/themes/policystat/theme";
import ContentRenderer from "pstat-anywhere/components/view/content/ContentRenderer";
import { TERTIARY_NAVBAR_HEIGHT } from "pstat-anywhere/components/partials/tertiary_nav";

const CKEditor5 = lazy(() => import("./CKEditor5"));

const CKWrapper = styled.div`
  position: relative;
  /* makes the buttons in the editor toolbar smaller */
  & #editor-toolbar {
    font-size: 13px;
  }
`;

const EditorRenderer = styled(ContentRenderer)`
  & #editor-content ol,
  & #editor-content ul {
    border-left: solid 20px #b9c4d0;
    margin-left: 30px;
    padding-left: 5px;
  }

  & #editor-content td {
    border: 1px solid ${themeGet("colors.nav.80")};
  }
`;

export const Editor = props => {
  const [loading, setLoading] = useState(true);
  const editorWrapper = useRef(null);
  const waitingToUpdateChanges = useRef(false);
  const config = {
    customConfig: `${window.MEDIA_URL}lib/ckeditor-common/editor_config.js`
  };
  const editorProperties =
    props.editorProperties || props.editorPropertiesOverride;
  const {
    ckeditor5,
    ckeditor5AiEnabled,
    ckeditor5LicenseKey,
    newCkeditor,
    rtlSupport
  } = editorProperties;
  const { t } = useTranslation();
  const ckeditorPath = newCkeditor ? "ckeditor-new" : "ckeditor";
  CKEditor.editorUrl = `${window.MEDIA_URL}lib/${ckeditorPath}/ckeditor.js`;

  // ckeditor customConfig expects this to be set
  window.PolicyStat = window.PolicyStat || {};
  window.PolicyStat.rtlSupport = rtlSupport;
  const setUp = event => {
    const editor = event.editor;
    editor.lang.clipboard.securityMsg = t("editor.securityMessage");
    editor.lang.clipboard.pasteMsg = t("editor.pasteMessage");

    editor.on("beforeCommandExec", event => {
      if (event.data.name === "paste") {
        event.editor._.forcePasteDialog = true;
      }

      // Don't show the paste dialog for Ctrl+Shift+V
      if (
        event.data.name === "pastetext" &&
        event.data.commandData.from === "keystrokeHandler"
      ) {
        event.cancel();
      }
    });
    props.setEditor(editor);
  };

  const grammarlyWorkaround = event => {
    event.editor.editable().data("gramm", "false");
  };

  const handleChange = event => {
    if (waitingToUpdateChanges.current) {
      return;
    }
    waitingToUpdateChanges.current = true;
    setTimeout(() => {
      props.onChange(event.editor.getData());
      waitingToUpdateChanges.current = false;
    }, 1000);
  };

  const handleDataReady = event => {
    if (waitingToUpdateChanges.current) {
      return;
    }
    waitingToUpdateChanges.current = true;
    setTimeout(() => {
      props.onDataReady(event.editor.getData());
      waitingToUpdateChanges.current = false;
    }, 1000);
  };

  const toolbarWidth = editorWrapper.current
    ? editorWrapper.current.getBoundingClientRect().width
    : null;
  return (
    <Media query={{ minWidth: theme.breakpoints[1] }}>
      {isDesktop => (
        <CKWrapper className="ck-wrapper" innerRef={editorWrapper}>
          <Sticky
            id="editor-controls"
            offset={
              isDesktop
                ? TERTIARY_NAVBAR_HEIGHT
                : TERTIARY_NAVBAR_HEIGHT + SIDEBAR_MOBILE_HEIGHT
            }
            zIndex={theme.layers.tertiaryNav - 1}
            loading={loading}
            width={toolbarWidth}
          >
            <div id="editor-toolbar"></div>
          </Sticky>
          <Helmet>
            <style>{props.editorStyles}</style>
          </Helmet>
          <EditorRenderer>
            <div className="document">
              <div id="editor-content" className="document_body">
                {ckeditor5 || props.isTemplate || props.isBasedOnTemplate ? (
                  <Suspense fallback={<LoadingSpinner />}>
                    <CKEditor5
                      content={props.content}
                      handleChange={handleChange}
                      setEditor={props.setEditor}
                      onDataReady={handleDataReady}
                      isTemplate={props.isTemplate}
                      isBasedOnTemplate={props.isBasedOnTemplate}
                      aiAssistantEnabled={ckeditor5AiEnabled}
                      licenseKey={ckeditor5LicenseKey}
                    />
                  </Suspense>
                ) : (
                  <AlwaysShowBlocks>
                    <CKEditor
                      data={props.content}
                      config={config}
                      type="inline"
                      onInstanceReady={event => {
                        setUp(event);
                        grammarlyWorkaround(event);
                        setLoading(false);
                      }}
                      onDataReady={handleDataReady}
                      onChange={handleChange}
                    />
                  </AlwaysShowBlocks>
                )}
              </div>
            </div>
          </EditorRenderer>
        </CKWrapper>
      )}
    </Media>
  );
};

export default createFragmentContainer(
  Editor,
  graphql`
    fragment editor_editorProperties on DocumentEditType {
      rtlSupport
      newCkeditor
      ckeditor5
      ckeditor5AiEnabled
      ckeditor5LicenseKey
    }
    fragment editor_editorPropertiesOverride on DocumentOverrideType {
      rtlSupport
      newCkeditor
      ckeditor5
      ckeditor5AiEnabled
      ckeditor5LicenseKey
    }
  `
);
