import { style } from "styled-system";

const layer = style({
  // React prop name
  prop: "layer",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "z-index",
  // key for theme values
  key: "layers",
  // accessor function for transforming the value
  transformValue: n => n,
  // add a fallback scale object or array, if theme is not present
  scale: {
    textOverlay: 1,
    nav: 100,
    secondaryNav: 50,
    tertiaryNav: 25,
    activeFloatingUi: 150,
    modal: 201,
    modalBg: 200
  }
});

export default layer;
