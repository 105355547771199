import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation SaveDocumentMutation(
    $documentSaveId: Int
    $tagInput: [String]
    $effectiveDate: PStatDate
    $effectiveDateTimedelta: Int
    $effectiveDateAlgorithm: String
    $name: String
    $html: String
    $author: Int
    $category: Int
    $bloc: Int
    $flowTemplate: Int
    $activeLifespan: Int
    $expirationDate: PStatDate
    $restricted: Boolean
    $isAForm: Boolean
    $regulations: [Int]
    $onesourceDocuments: [OneSourceDocumentInputType]
    $locations: [Int]
  ) {
    saveDraft(
      documentSaveId: $documentSaveId
      tagInput: $tagInput
      effectiveDate: $effectiveDate
      effectiveDateTimedelta: $effectiveDateTimedelta
      effectiveDateAlgorithm: $effectiveDateAlgorithm
      name: $name
      html: $html
      author: $author
      category: $category
      bloc: $bloc
      flowTemplate: $flowTemplate
      activeLifespan: $activeLifespan
      expirationDate: $expirationDate
      restricted: $restricted
      isAForm: $isAForm
      regulations: $regulations
      onesourceDocuments: $onesourceDocuments
      locations: $locations
    ) {
      ok
      reasons
      documentSaveId
      saveCommentUrl
      draftViewUrl
      documentSave {
        pk
        savedCopy {
          pk
        }
      }
    }
  }
`;

export const saveDocument = (environment, documentSave, callback) => {
  const variables = { ...documentSave };
  variables.documentSaveId = documentSave.pk;

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
