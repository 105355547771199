import graphql from "babel-plugin-relay/macro";

export const LippincottSearchQuery = graphql`
  query LippincottSearchQuery($q: String, $showAll: Boolean) {
    lippincottSearch(q: $q, showAll: $showAll) {
      taskId
    }
  }
`;

export default LippincottSearchQuery;