/**
 * @flow
 * @relayHash 92d04eb6cb5dee878364cb5c975865f4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EpStandardContentChangesMutationVariables = {|
  documentLineEpAssociation: number,
  fromVersion: string,
  toVersion: string,
|};
export type EpStandardContentChangesMutationResponse = {|
  +documentLineEpChanges: ?{|
    +taskId: ?string,
    +ok: ?boolean,
    +error: ?{|
      +message: ?string
    |},
  |}
|};
export type EpStandardContentChangesMutation = {|
  variables: EpStandardContentChangesMutationVariables,
  response: EpStandardContentChangesMutationResponse,
|};
*/


/*
mutation EpStandardContentChangesMutation(
  $documentLineEpAssociation: Int!
  $fromVersion: String!
  $toVersion: String!
) {
  documentLineEpChanges(documentLineEpAssociationId: $documentLineEpAssociation, fromVersion: $fromVersion, toVersion: $toVersion) {
    taskId
    ok
    error {
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentLineEpAssociation",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "fromVersion",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "toVersion",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentLineEpChanges",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentLineEpAssociationId",
        "variableName": "documentLineEpAssociation",
        "type": "Int!"
      },
      {
        "kind": "Variable",
        "name": "fromVersion",
        "variableName": "fromVersion",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "toVersion",
        "variableName": "toVersion",
        "type": "String!"
      }
    ],
    "concreteType": "DocumentLineEpChangesMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "EpStandardContentChangesMutation",
  "id": "490e2c11062f90c1febbec07e5e9410f",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EpStandardContentChangesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EpStandardContentChangesMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation EpStandardContentChangesMutation(\n  $documentLineEpAssociation: Int!\n  $fromVersion: String!\n  $toVersion: String!\n) {\n  documentLineEpChanges(documentLineEpAssociationId: $documentLineEpAssociation, fromVersion: $fromVersion, toVersion: $toVersion) {\n    taskId\n    ok\n    error {\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'f1a9fdf6cd66b88ab4baa761a7ebdcd8';
module.exports = node;
