/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type commentForm_document$ref: FragmentReference;
export type commentForm_document = {|
  +id: string,
  +pk: ?number,
  +canComment: ?boolean,
  +subscribedToComments: ?boolean,
  +usersNotificationComment: ?{|
    +usersNotificationCommentOn: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +firstName: string,
      +lastName: string,
    |}>,
    +usersNotificationCommentOff: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +firstName: string,
      +lastName: string,
    |}>,
  |},
  +$refType: commentForm_document$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = [
  v0,
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "firstName",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "lastName",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "commentForm_document",
  "type": "DocumentType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "canComment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subscribedToComments",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "usersNotificationComment",
      "storageKey": null,
      "args": null,
      "concreteType": "UserCommentNotificationType",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "usersNotificationCommentOn",
          "storageKey": null,
          "args": null,
          "concreteType": "UserType",
          "plural": true,
          "selections": v1
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "usersNotificationCommentOff",
          "storageKey": null,
          "args": null,
          "concreteType": "UserType",
          "plural": true,
          "selections": v1
        }
      ]
    }
  ]
};
})();

(node/*: any*/).hash = '292c116bca38b09cec26d6ef62c1b69f';
module.exports = node;
