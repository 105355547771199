import { Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { WarningBanner } from "pstat-design-system/banner";
import { RouterLink } from "pstat-design-system/RouterLink";
import { Text } from "pstat-design-system/text";

const BlueBarContainer = styled.div`
  background-color: ${themeGet("colors.nav.25")};
  width: 100%;
  justify-content: center;
  height: 60px;
  display: flex;
  align-items: center;
`;

function DocumentApprovedMessage({ labels: { documentLabel }, policyUrl }) {
  const { t } = useTranslation();
  const lowerCaseDocumentLabel = documentLabel.toLowerCase();
  return (
    <Flex justify="center" flexWrap="wrap">
      <BlueBarContainer>
        <Text color="nav.100">
          {t("documentControl.view.documentAlreadyApproved.header", {
            documentLabel
          })}
        </Text>
      </BlueBarContainer>
      <WarningBanner
        width="90%"
        message={
          <div>
            <p>
              {t(
                "documentControl.view.documentAlreadyApproved.messageFirstLine",
                {
                  documentLabel: lowerCaseDocumentLabel
                }
              )}
            </p>
            <p>
              {t(
                "documentControl.view.documentAlreadyApproved.messageSecondLine",
                {
                  documentLabel: lowerCaseDocumentLabel
                }
              )}
            </p>
          </div>
        }
      />
      <Flex width="100%" justify="center" mt={1}>
        <Text>
          {t("documentControl.view.documentAlreadyApproved.linkDescription", {
            documentLabel: lowerCaseDocumentLabel
          })}
          <RouterLink to={{ pathname: policyUrl }}>
            {" "}
            {t("documentControl.view.documentAlreadyApproved.linkText")}
          </RouterLink>
        </Text>
      </Flex>
    </Flex>
  );
}

export default withLabelContext(DocumentApprovedMessage);
