import { Flex } from "grid-styled";
import styled from "styled-components";
import { borders, height, themeGet } from "styled-system";

// TODO remove this border-color later
export const SmallOnlyFlex = styled(Flex)`
  ${borders};
  ${height};

  border-color: #97a0ad;

  @media (min-width: ${themeGet("breakpoints.1")}) {
    display: none;
  }
`;

SmallOnlyFlex.displayName = SmallOnlyFlex;
