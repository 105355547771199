import { useEffect, useState } from "react";

import { doBulkApprove } from "../graphql/BulkApproveMutation";

import useQueryVariables from "pstat-anywhere/components/pages/reports/hooks/useQueryVariables";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";


export default function usePendingDocumentsReport({
  router,
  route,
  location,
  t,
  labels
}) {
  const [taskId, setTaskId] = useState(null);
  const { error, loading, result } = useTaskResult(taskId);

  useEffect(() => {
    if (result) {
      const numberApproved = result.num_documents_approved;
      const documentLabel =
        numberApproved > 1 ? labels.documentLabelPlural : labels.documentLabel;
      createSuccessMessage(
        t("documentReports.awaitingActivation.successfulBulkApprove", {
          numApproved: numberApproved,
          documentLabel: documentLabel.toLowerCase()
        })
      );
      router.push(getRootUrl());
    }
  }, [result, labels.documentLabel, labels.documentLabelPlural, router, t]);
  useEffect(() => {
    if (error) {
      createErrorMessage(
        t("documentReports.awaitingActivation.errorBulkApprove", {
          documentLabelPlural: labels.documentLabelPlural.toLowerCase()
        })
      );
    }
  }, [error, labels.documentLabelPlural, t]);

  let showAll = false;
  if (route?.data?.routeName === "awaiting_activation_all") {
    showAll = true;
  }

  const { variables } = useQueryVariables({ location });
  const { search_query, pending_approver, bloc } = location.query;
  variables.search_query = search_query;
  variables.pending_approver = pending_approver;
  variables.bloc = bloc;

  function handleBulkApprove(selectedItemPks, comment) {
    doBulkApprove({
      documentPks: selectedItemPks,
      comment,
      callback: successBulkApproveCallback
    });
  }

  function successBulkApproveCallback(response, errors) {
    setTaskId(response.bulkApprove.taskId);
  }

  return {
    variables: {
      ...variables,
      showAll
    },
    handleBulkApprove,
    loading
  };
}
