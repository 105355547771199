import { withRouter } from "found";
import { Box, Flex } from "grid-styled";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import documentBuildMutation from "./documentBuildMutation";

import { AddAttachment } from "pstat-anywhere/components/document_control/PolicyEditor/components/Attachments";
import { ContentContainerFlex } from "pstat-anywhere/components/pages";
import { withDataStore } from "pstat-anywhere/context_providers/DataStoreContext";
import { environment } from "pstat-anywhere/relay";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Panel from "pstat-design-system/Panel";
import { Button } from "pstat-design-system/shared/Button";


export const CREATE_FROM_WORD_KEY = "createFromWord";

const ImportFromWordPage = ({ documentBuild }) => {
  return documentBuild ? <ImportFromWord /> : <LoadingSpinner size={100} />;
};

export default ImportFromWordPage;

const _ImportFromWord = ({ router, dataStore }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [name, setName] = useState(null);

  const isEnterKey = event => event.key === "Enter";
  const task = useTaskResult(taskId);

  const handleClose = () => {
    router.push(getRootUrl() || "/");
  };
  const handleCancelKeyDown = event => {
    if (isEnterKey(event)) {
      handleClose();
    }
  };
  const handleSubmitUpload = () => {
    const variables = {
      input: {
        documentFile: file
      }
    };
    const uploadables = {
      documentFile: file
    };
    setLoading(true);
    setErrorMessage(null);
    documentBuildMutation(
      environment,
      variables,
      uploadables,
      (data, errors) => {
        if (errors) {
          throw new Error();
        }
        if (data.documentBuild.ok) {
          const taskId = data.documentBuild.taskId;
          const name = data.documentBuild.name;
          setTaskId(taskId);
          setName(name);
        } else {
          setLoading(false);
          setErrorMessage(data.documentBuild.reasons.document_file);
        }
      }
    );
  };
  const handleSubmitUploadKeyDown = event => {
    if (isEnterKey(event)) {
      handleSubmitUpload(event);
    }
  };
  const handleFileChange = file => {
    setFile(file);
  };
  useEffect(() => {
    if (task.result) {
      if (task.result.status) {
        dataStore[CREATE_FROM_WORD_KEY] = {
          html: Base64.decode(task.result.html),
          name: name
        };
        setLoading(false);
        router.push({
          pathname: `${getRootUrl()}/policy/create`,
          query: {
            createFromWord: true,
            documentSavePk: task.result.document_save_pk
          }
        });
      } else {
        setLoading(false);
        setErrorMessage(task.result.message);
      }
    }
    if (task.error) {
      setLoading(false);
      setErrorMessage(t("documentBuild.taskError"));
    }
  }, [dataStore, name, router, task.result, task.error, t]);

  const filename = file ? file.name : null;
  const formErrors = errorMessage ? [errorMessage] : null;
  return (
    <ContentContainerFlex wrap>
      <Box width={1} py={[0, 0, 6]} px={[0, 0, 4]} role="main">
        <Panel name={t("documentBuild.title")}>
          <Flex flexDirection="column" pb={70}>
            <AddAttachment
              fileID="word_import"
              onFileChange={handleFileChange}
              filename={filename}
              disabled={loading || task.loading}
              errors={formErrors}
              uploadLabel={t("documentBuild.customUploadLabel")}
            />
          </Flex>
          <Flex width={1} justifyContent="space-between">
            <Box>
              <Button
                buttonStyle="secondary"
                onClick={handleSubmitUpload}
                onKeyDown={handleSubmitUploadKeyDown}
                disabled={!file}
                processing={loading || task.loading}
              >
                {t("documentControl.create.importFromWord.upload")}
              </Button>
            </Box>
            <Box>
              <Button
                buttonStyle="tertiary"
                onClick={handleClose}
                onKeyDown={handleCancelKeyDown}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Flex>
        </Panel>
      </Box>
    </ContentContainerFlex>
  );
};

const ImportFromWord = withDataStore(withRouter(_ImportFromWord));
