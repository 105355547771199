import styled from "styled-components";
import media from "styled-media-query";
import { themeGet } from "styled-system";

import { StyledFontAwesomeIcon } from "../partials/icons";

export const IconContainer = styled.div`
  height: 19px;
  font-size: 19px;
  text-align: left;
  display: inline;
`;

export const Icon = styled.i`
  height: 19px;
  font-size: 19px;
  text-align: left;
`;

export const MenuIcon = () => (
  <IconContainer>
    <StyledFontAwesomeIcon icon={["far", "bars"]} color="primary.0" mr={2} />
  </IconContainer>
);

export const StyledSearchIcon = styled(StyledFontAwesomeIcon)`
  width: 18px;
  ${media.lessThan("medium")`
    color: ${themeGet("colors.primary.0")};
    margin-right: 4px;
  `};
`;

export const SearchIcon = () => (
  <StyledSearchIcon icon={["far", "search"]} color="secondary.0" />
);

const StyledDangerIcon = styled(StyledFontAwesomeIcon)`
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: ${themeGet("colors.messages.error")};
  margin-right: 5px;
`;

export const DangerIcon = () => (
  <StyledDangerIcon icon={["fal", "exclamation-triangle"]} />
);

const StyledCautionIcon = styled(StyledDangerIcon)`
  color: ${themeGet("colors.messages.warning")};
`;

export const CautionIcon = () => (
  <StyledCautionIcon icon={["fal", "exclamation-triangle"]} />
);

const StyledSuccessIcon = styled(StyledFontAwesomeIcon)`
  font-size: 19px;
  text-align: left;
  color: ${themeGet("colors.messages.success")};
  width: 17px;
  height: 19px;
  margin-right: 5px;
`;

export const SuccessIcon = () => (
  <StyledSuccessIcon icon={["fal", "check-circle"]} />
);

const StyledBanIcon = styled(StyledFontAwesomeIcon)`
  font-size: 17px;
  line-height: 1.18;
  text-align: left;
  color: hsl(218, 15%, 42%);
  width: 15px;
  height: 20px;
  margin-right: 5px;
`;

export const BanIcon = () => <StyledBanIcon icon={["far", "ban"]} />;

const StyledTrashIcon = styled(StyledFontAwesomeIcon)`
  line-height: 1.16;
  text-align: center;
  color: ${themeGet("colors.messages.error")};
`;

export const TrashIcon = () => (
  <StyledTrashIcon icon={["fal", "trash-alt"]} size="lg" />
);

const StyledQuestionIcon = styled(StyledFontAwesomeIcon)`
  font-size: 19px;
  text-align: left;
  color: ${themeGet("colors.secondary.0")};
`;

export const QuestionIcon = () => (
  <StyledQuestionIcon icon={["fal", "question-circle"]} />
);

const StyledSmileIcon = styled(StyledFontAwesomeIcon)`
  font-size: 60px;
  color: ${themeGet("colors.nav.55")};
  &.svg-inline--fa {
    width: ${props => props.width || "auto"};
    margin-bottom: ${props => props.marginbottom || "0"}px;
  }
`;

export const SmileIcon = ({ onClick, ...props }) => {
  const { marginBottom, ...otherProps } = props;
  return (
    <StyledSmileIcon
      onClick={onClick}
      icon={["fal", "smile"]}
      {...otherProps}
      marginbottom={marginBottom}
    />
  );
};

export const StyledArrow = styled(StyledFontAwesomeIcon)`
  font-size: 12px;
  line-height: 1.67;
  text-align: left;
  color: ${themeGet("colors.secondary.0")};
  margin-left: 5px;
`;
