/**
 * @flow
 * @relayHash 4cace142a1e101ecfc2024a9f16a5144
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CopyDocumentMutationInput = {
  tenantPk: number,
  documentPk: number,
  clientMutationId?: ?string,
};
export type CloneDocumentMutationVariables = {|
  input: CopyDocumentMutationInput
|};
export type CloneDocumentMutationResponse = {|
  +copyDocument: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
    +tenantsToRedirect: ?$ReadOnlyArray<?{|
      +name: string,
      +url: ?string,
    |}>,
  |}
|};
export type CloneDocumentMutation = {|
  variables: CloneDocumentMutationVariables,
  response: CloneDocumentMutationResponse,
|};
*/


/*
mutation CloneDocumentMutation(
  $input: CopyDocumentMutationInput!
) {
  copyDocument(input: $input) {
    ok
    reasons
    redirectUrl
    tenantsToRedirect {
      name
      url
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CopyDocumentMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CopyDocumentMutationInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reasons",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "redirectUrl",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CloneDocumentMutation",
  "id": "a62ceaf28b085475d3093da63701b3a8",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CloneDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyDocumentMutationPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantsToRedirect",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantType",
            "plural": true,
            "selections": [
              v5,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CloneDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyDocumentMutationPayload",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantsToRedirect",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantType",
            "plural": true,
            "selections": [
              v5,
              v6,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation CloneDocumentMutation(\n  $input: CopyDocumentMutationInput!\n) {\n  copyDocument(input: $input) {\n    ok\n    reasons\n    redirectUrl\n    tenantsToRedirect {\n      name\n      url\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = 'c98dd4035e5aa464a64161d4920db586';
module.exports = node;
