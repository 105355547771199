import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation EditBlocMutation($input: ChangeBlocInput!) {
    changeBloc(input: $input) {
      ok
      error
      bloc {
        pk
        name
      }
    }
  }
`;

const editBloc = (
  environment,
  blocPk,
  name,
  documentSettingsPk,
  tenantPks,
  callback
) => {
  const variables = {
    input: {
      blocPk,
      name,
      documentSettings: documentSettingsPk,
      tenants: tenantPks
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};

export default editBloc;
