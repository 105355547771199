import { Fragment } from "react";
import sizeMe from "react-sizeme";

import {
  BlurOverflow,
  ReadMoreButtonPositioner,
  ReadMoreContainer
} from "./components";
import useExpandableContainer from "./hooks";

import { ReadMoreButton } from "pstat-design-system/shared/Button";

function ExpandableContainer({
  children,
  size = { height: 220 },
  drillExpandableContainerProps = false,
  buttonPositionToContent = "sibling",
  forcedHeight
}) {
  // buttonPosition is a dirty trick.
  const {
    isExpanded,
    shouldShowResizer,
    toggleIsExpanded,
    content
  } = useExpandableContainer({ size, drillExpandableContainerProps, children });
  const Button = () => (
    <ReadMoreButtonPositioner
      shouldShowResizer={() => shouldShowResizer(forcedHeight)}
    >
      {isExpanded ? "" : <BlurOverflow />}
      <ReadMoreButton onClick={toggleIsExpanded} isExpanded={isExpanded} />
    </ReadMoreButtonPositioner>
  );
  return (
    <Fragment>
      <ReadMoreContainer>
        {content}
        {buttonPositionToContent === "sibling" && <Button />}
      </ReadMoreContainer>
      {buttonPositionToContent === "parentSibling" && <Button />}
    </Fragment>
  );
}

// Create the sizeMe config
const config = { monitorHeight: true, monitorWidth: false };
// Call SizeMe with the config to get back the HOC.
const sizeMeHOC = sizeMe(config);
export default sizeMeHOC(ExpandableContainer);
