import graphql from "babel-plugin-relay/macro";

export default graphql`
  query MyPoliciesToAcknowledgeReportQuery(
    $first: Int
    $sort: String
    $after: String
  ) {
    myDocumentsToAcknowledge(first: $first, sort: $sort, after: $after) {
      totalCount
      edges {
        node {
          totalAcknowledged
          totalAssigned
          waitTime
          line {
            pk
            document {
              pk
              name
              hasAttachments
              effectiveDate
              legacyEffectiveDate
              restricted
              bloc {
                name
              }
              applicabilityType
            }
          }
        }
      }
    }
  }
`;
