/**
 * @flow
 * @relayHash 7cac16d1764a63d835d3279422d8cd17
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SearchResultsCSVQueryVariables = {|
  q?: ?string,
  titleOnly?: ?boolean,
  authors?: ?$ReadOnlyArray<?number>,
  categories?: ?$ReadOnlyArray<?number>,
  references?: ?$ReadOnlyArray<?number>,
  sort?: ?string,
|};
export type SearchResultsCSVQueryResponse = {|
  +searchCsv: ?{|
    +taskId: ?string
  |}
|};
export type SearchResultsCSVQuery = {|
  variables: SearchResultsCSVQueryVariables,
  response: SearchResultsCSVQueryResponse,
|};
*/


/*
query SearchResultsCSVQuery(
  $q: String
  $titleOnly: Boolean
  $authors: [Int]
  $categories: [Int]
  $references: [Int]
  $sort: String
) {
  searchCsv(q: $q, titleOnly: $titleOnly, authors: $authors, categories: $categories, references: $references, sort: $sort) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "q",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "titleOnly",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "authors",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categories",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "references",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "searchCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "authors",
        "variableName": "authors",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "categories",
        "variableName": "categories",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "q",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "references",
        "variableName": "references",
        "type": "[Int]"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "titleOnly",
        "variableName": "titleOnly",
        "type": "Boolean"
      }
    ],
    "concreteType": "CSVQueryTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SearchResultsCSVQuery",
  "id": "47300d72a153ddfa61c5b6d59a9d3789",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SearchResultsCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SearchResultsCSVQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query SearchResultsCSVQuery(\n  $q: String\n  $titleOnly: Boolean\n  $authors: [Int]\n  $categories: [Int]\n  $references: [Int]\n  $sort: String\n) {\n  searchCsv(q: $q, titleOnly: $titleOnly, authors: $authors, categories: $categories, references: $references, sort: $sort) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '883e6ed99059e9328e3bb6905059482b';
module.exports = node;
