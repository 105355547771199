"use strict";

require("core-js/modules/es.symbol");
require("core-js/modules/es.symbol.description");
require("core-js/modules/es.symbol.async-iterator");
require("core-js/modules/es.symbol.has-instance");
require("core-js/modules/es.symbol.is-concat-spreadable");
require("core-js/modules/es.symbol.iterator");
require("core-js/modules/es.symbol.match");
require("core-js/modules/es.symbol.replace");
require("core-js/modules/es.symbol.search");
require("core-js/modules/es.symbol.species");
require("core-js/modules/es.symbol.split");
require("core-js/modules/es.symbol.to-primitive");
require("core-js/modules/es.symbol.to-string-tag");
require("core-js/modules/es.symbol.unscopables");
require("core-js/modules/es.array.concat");
require("core-js/modules/es.array.copy-within");
require("core-js/modules/es.array.every");
require("core-js/modules/es.array.fill");
require("core-js/modules/es.array.filter");
require("core-js/modules/es.array.find");
require("core-js/modules/es.array.find-index");
require("core-js/modules/es.array.flat");
require("core-js/modules/es.array.flat-map");
require("core-js/modules/es.array.for-each");
require("core-js/modules/es.array.from");
require("core-js/modules/es.array.includes");
require("core-js/modules/es.array.index-of");
require("core-js/modules/es.array.is-array");
require("core-js/modules/es.array.iterator");
require("core-js/modules/es.array.join");
require("core-js/modules/es.array.last-index-of");
require("core-js/modules/es.array.map");
require("core-js/modules/es.array.of");
require("core-js/modules/es.array.reduce");
require("core-js/modules/es.array.reduce-right");
require("core-js/modules/es.array.slice");
require("core-js/modules/es.array.some");
require("core-js/modules/es.array.sort");
require("core-js/modules/es.array.species");
require("core-js/modules/es.array.splice");
require("core-js/modules/es.array.unscopables.flat");
require("core-js/modules/es.array.unscopables.flat-map");
require("core-js/modules/es.array-buffer.constructor");
require("core-js/modules/es.array-buffer.is-view");
require("core-js/modules/es.array-buffer.slice");
require("core-js/modules/es.data-view");
require("core-js/modules/es.date.now");
require("core-js/modules/es.date.to-iso-string");
require("core-js/modules/es.date.to-json");
require("core-js/modules/es.date.to-primitive");
require("core-js/modules/es.date.to-string");
require("core-js/modules/es.function.bind");
require("core-js/modules/es.function.has-instance");
require("core-js/modules/es.function.name");
require("core-js/modules/es.json.to-string-tag");
require("core-js/modules/es.map");
require("core-js/modules/es.math.acosh");
require("core-js/modules/es.math.asinh");
require("core-js/modules/es.math.atanh");
require("core-js/modules/es.math.cbrt");
require("core-js/modules/es.math.clz32");
require("core-js/modules/es.math.cosh");
require("core-js/modules/es.math.expm1");
require("core-js/modules/es.math.fround");
require("core-js/modules/es.math.hypot");
require("core-js/modules/es.math.imul");
require("core-js/modules/es.math.log10");
require("core-js/modules/es.math.log1p");
require("core-js/modules/es.math.log2");
require("core-js/modules/es.math.sign");
require("core-js/modules/es.math.sinh");
require("core-js/modules/es.math.tanh");
require("core-js/modules/es.math.to-string-tag");
require("core-js/modules/es.math.trunc");
require("core-js/modules/es.number.constructor");
require("core-js/modules/es.number.epsilon");
require("core-js/modules/es.number.is-finite");
require("core-js/modules/es.number.is-integer");
require("core-js/modules/es.number.is-nan");
require("core-js/modules/es.number.is-safe-integer");
require("core-js/modules/es.number.max-safe-integer");
require("core-js/modules/es.number.min-safe-integer");
require("core-js/modules/es.number.parse-float");
require("core-js/modules/es.number.parse-int");
require("core-js/modules/es.number.to-fixed");
require("core-js/modules/es.object.assign");
require("core-js/modules/es.object.create");
require("core-js/modules/es.object.define-getter");
require("core-js/modules/es.object.define-properties");
require("core-js/modules/es.object.define-property");
require("core-js/modules/es.object.define-setter");
require("core-js/modules/es.object.entries");
require("core-js/modules/es.object.freeze");
require("core-js/modules/es.object.from-entries");
require("core-js/modules/es.object.get-own-property-descriptor");
require("core-js/modules/es.object.get-own-property-descriptors");
require("core-js/modules/es.object.get-own-property-names");
require("core-js/modules/es.object.get-prototype-of");
require("core-js/modules/es.object.is");
require("core-js/modules/es.object.is-extensible");
require("core-js/modules/es.object.is-frozen");
require("core-js/modules/es.object.is-sealed");
require("core-js/modules/es.object.keys");
require("core-js/modules/es.object.lookup-getter");
require("core-js/modules/es.object.lookup-setter");
require("core-js/modules/es.object.prevent-extensions");
require("core-js/modules/es.object.seal");
require("core-js/modules/es.object.set-prototype-of");
require("core-js/modules/es.object.to-string");
require("core-js/modules/es.object.values");
require("core-js/modules/es.parse-int");
require("core-js/modules/es.promise");
require("core-js/modules/es.promise.finally");
require("core-js/modules/es.reflect.apply");
require("core-js/modules/es.reflect.construct");
require("core-js/modules/es.reflect.define-property");
require("core-js/modules/es.reflect.delete-property");
require("core-js/modules/es.reflect.get");
require("core-js/modules/es.reflect.get-own-property-descriptor");
require("core-js/modules/es.reflect.get-prototype-of");
require("core-js/modules/es.reflect.has");
require("core-js/modules/es.reflect.is-extensible");
require("core-js/modules/es.reflect.own-keys");
require("core-js/modules/es.reflect.prevent-extensions");
require("core-js/modules/es.reflect.set");
require("core-js/modules/es.reflect.set-prototype-of");
require("core-js/modules/es.regexp.constructor");
require("core-js/modules/es.regexp.exec");
require("core-js/modules/es.regexp.flags");
require("core-js/modules/es.regexp.to-string");
require("core-js/modules/es.set");
require("core-js/modules/es.string.code-point-at");
require("core-js/modules/es.string.ends-with");
require("core-js/modules/es.string.from-code-point");
require("core-js/modules/es.string.includes");
require("core-js/modules/es.string.iterator");
require("core-js/modules/es.string.match");
require("core-js/modules/es.string.pad-end");
require("core-js/modules/es.string.pad-start");
require("core-js/modules/es.string.raw");
require("core-js/modules/es.string.repeat");
require("core-js/modules/es.string.replace");
require("core-js/modules/es.string.search");
require("core-js/modules/es.string.split");
require("core-js/modules/es.string.starts-with");
require("core-js/modules/es.string.trim");
require("core-js/modules/es.string.trim-end");
require("core-js/modules/es.string.trim-start");
require("core-js/modules/es.string.anchor");
require("core-js/modules/es.string.big");
require("core-js/modules/es.string.blink");
require("core-js/modules/es.string.bold");
require("core-js/modules/es.string.fixed");
require("core-js/modules/es.string.fontcolor");
require("core-js/modules/es.string.fontsize");
require("core-js/modules/es.string.italics");
require("core-js/modules/es.string.link");
require("core-js/modules/es.string.small");
require("core-js/modules/es.string.strike");
require("core-js/modules/es.string.sub");
require("core-js/modules/es.string.sup");
require("core-js/modules/es.typed-array.float32-array");
require("core-js/modules/es.typed-array.float64-array");
require("core-js/modules/es.typed-array.int8-array");
require("core-js/modules/es.typed-array.int16-array");
require("core-js/modules/es.typed-array.int32-array");
require("core-js/modules/es.typed-array.uint8-array");
require("core-js/modules/es.typed-array.uint8-clamped-array");
require("core-js/modules/es.typed-array.uint16-array");
require("core-js/modules/es.typed-array.uint32-array");
require("core-js/modules/es.typed-array.copy-within");
require("core-js/modules/es.typed-array.every");
require("core-js/modules/es.typed-array.fill");
require("core-js/modules/es.typed-array.filter");
require("core-js/modules/es.typed-array.find");
require("core-js/modules/es.typed-array.find-index");
require("core-js/modules/es.typed-array.for-each");
require("core-js/modules/es.typed-array.from");
require("core-js/modules/es.typed-array.includes");
require("core-js/modules/es.typed-array.index-of");
require("core-js/modules/es.typed-array.iterator");
require("core-js/modules/es.typed-array.join");
require("core-js/modules/es.typed-array.last-index-of");
require("core-js/modules/es.typed-array.map");
require("core-js/modules/es.typed-array.of");
require("core-js/modules/es.typed-array.reduce");
require("core-js/modules/es.typed-array.reduce-right");
require("core-js/modules/es.typed-array.reverse");
require("core-js/modules/es.typed-array.set");
require("core-js/modules/es.typed-array.slice");
require("core-js/modules/es.typed-array.some");
require("core-js/modules/es.typed-array.sort");
require("core-js/modules/es.typed-array.subarray");
require("core-js/modules/es.typed-array.to-locale-string");
require("core-js/modules/es.typed-array.to-string");
require("core-js/modules/es.weak-map");
require("core-js/modules/es.weak-set");
require("core-js/modules/web.dom-collections.for-each");
require("core-js/modules/web.dom-collections.iterator");
require("core-js/modules/web.immediate");
require("core-js/modules/web.queue-microtask");
require("core-js/modules/web.timers");
require("core-js/modules/web.url");
require("core-js/modules/web.url.to-json");
require("core-js/modules/web.url-search-params");
require("regenerator-runtime/runtime");
if (typeof Promise === "undefined") {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require("promise/lib/rejection-tracking").enable();
  window.Promise = require("promise/lib/es6-extensions.js");
}

// fetch() polyfill for making API calls.
require("whatwg-fetch");

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require("object-assign");

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === "test") {
  require("raf").polyfill(global);
}