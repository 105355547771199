import { useState } from "react";

import { getRootUrl } from "pstat-anywhere/utils/urls";

export default function useNotificationsTable({ location, router }) {
  const [totalNumberOfResults, setTotalNumberOfResults] = useState(0);
  const [hitsPerPage, setHitsPerPage] = useState(10);

  function handleTotalNumberOfResultsUpdate(newTotalCount) {
    if (newTotalCount !== totalNumberOfResults) {
      setTotalNumberOfResults(newTotalCount);
    }
  }

  function handlePageChange(afterCursor) {
    const queryParams = { ...location.query };
    delete queryParams.after;
    if (afterCursor) {
      queryParams.after = afterCursor;
    }
    const to = {
      pathname: `${getRootUrl()}/notification/`,
      query: queryParams
    };
    router.push(to);
  }

  return {
    totalNumberOfResults,
    hitsPerPage,
    setHitsPerPage,
    handlePageChange,
    handleTotalNumberOfResultsUpdate
  };
}
