/**
 * @flow
 * @relayHash 57c1772d15af626d7ee85a1ee33c1f65
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlocChoicesQueryVariables = {||};
export type BlocChoicesQueryResponse = {|
  +blocChoicesPendingReport: ?{|
    +blocs: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
    |}>
  |}
|};
export type BlocChoicesQuery = {|
  variables: BlocChoicesQueryVariables,
  response: BlocChoicesQueryResponse,
|};
*/


/*
query BlocChoicesQuery {
  blocChoicesPendingReport {
    blocs {
      pk
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BlocChoicesQuery",
  "id": "1d5f7cb859cdebeb0494641a24afac2e",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BlocChoicesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocChoicesPendingReport",
        "storageKey": null,
        "args": null,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v0,
              v1
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BlocChoicesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "blocChoicesPendingReport",
        "storageKey": null,
        "args": null,
        "concreteType": "BlocsPageType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blocs",
            "storageKey": null,
            "args": null,
            "concreteType": "BlocType",
            "plural": true,
            "selections": [
              v0,
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query BlocChoicesQuery {\n  blocChoicesPendingReport {\n    blocs {\n      pk\n      name\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = 'f1946588e6a2c0e4254cb937082004fc';
module.exports = node;
