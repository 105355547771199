/**
 * @flow
 * @relayHash 03d0414dd58cbb3daa4d24fbaa0115f7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateDocumentAccessTokenInput = {
  documentPk: number,
  description?: ?string,
  clientMutationId?: ?string,
};
export type CreateTokenMutationVariables = {|
  input: CreateDocumentAccessTokenInput
|};
export type CreateTokenMutationResponse = {|
  +createDocumentAccessToken: ?{|
    +ok: ?boolean,
    +token: ?{|
      +tokenId: ?any,
      +description: string,
      +dateLastAccessed: ?string,
      +url: ?string,
    |},
  |}
|};
export type CreateTokenMutation = {|
  variables: CreateTokenMutationVariables,
  response: CreateTokenMutationResponse,
|};
*/


/*
mutation CreateTokenMutation(
  $input: CreateDocumentAccessTokenInput!
) {
  createDocumentAccessToken(input: $input) {
    ok
    token {
      tokenId
      description
      dateLastAccessed
      url
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateDocumentAccessTokenInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateDocumentAccessTokenInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ok",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tokenId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dateLastAccessed",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateTokenMutation",
  "id": "7f82b517e957faef3250910c9a93c920",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateTokenMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDocumentAccessToken",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDocumentAccessTokenPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAccessTokenType",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateTokenMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createDocumentAccessToken",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateDocumentAccessTokenPayload",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "token",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentAccessTokenType",
            "plural": false,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "mutation CreateTokenMutation(\n  $input: CreateDocumentAccessTokenInput!\n) {\n  createDocumentAccessToken(input: $input) {\n    ok\n    token {\n      tokenId\n      description\n      dateLastAccessed\n      url\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '6508952b9ee55dfb32a36c09dc298924';
module.exports = node;
