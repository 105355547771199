import { Box, Flex } from "grid-styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import TableSearchBar from "pstat-design-system/inputs/TableSearchBar";
import InlineInputWrapper from "pstat-design-system/InlineInputWrapper";

const SearchInlineInputWrapper = system({
  is: InlineInputWrapper,
  width: 1,
  p: 4,
  pl: 0,
  pr: 0
});

function DraftsSearch({ handleSearch, initValue }) {
  const [searchQuery, setSearchQuery] = useState(initValue);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(searchQuery);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SearchInlineInputWrapper>
      <Flex width="100%" alignItems="center">
        <Box flex={1}>
          <TableSearchBar
            pt={0}
            boxShadow={"none"}
            onChange={ev => setSearchQuery(ev.target.value)}
            placeholder={t("documentDraft.search")}
            value={searchQuery}
            data-testid="draft-search-input"
          />
        </Box>
      </Flex>
    </SearchInlineInputWrapper>
  );
}

export default DraftsSearch;
