import styled, { css } from "styled-components";
import { themeGet } from "styled-system";

export const diffStyles = css`
  & del *,
  & ins * {
    width: fit-content;
  }
  & del,
  & del * {
    color: ${themeGet("colors.messages.error")};
  }
  & del img {
    border: 3px solid ${themeGet("colors.messages.error")};
  }
  & ins,
  & ins * {
    color: ${themeGet("colors.messages.success")};
  }
  & ins img {
    border: 3px solid ${themeGet("colors.messages.success")};
  }
`;

// This component - just container for styles applying.
// It doesn't contain any logic, but just styling for <del> and <ins> elements
const DiffContainer = styled("section")`
  ${diffStyles}
`;

export const MockDiffContent = () => (
  <main>
    <h1>
      This is mocked header. <ins>This was added</ins>
      <del>And this was deleted</del>
    </h1>
    <section>
      And this is just some text.{" "}
      <ins>This is pretty important addition, it was added.</ins>
      <del>This was some redundant text, now it's gone.</del>
      After deleted text goes some other, still relevant, information. Blah blah
      blah, I am relevant text, blah blah blah.
    </section>
  </main>
);

export default DiffContainer;
