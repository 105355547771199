import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { color } from "styled-system";

import { Link } from "pstat-design-system/Link";

const copyrightYear = new Date().getFullYear();

const FooterFlexContainer = styled(Flex).attrs({
  bg: "nav.95",
  align: "center",
  justify: "center",
  flexDirection: "column",
  width: "100%",
  py: 9
})`
  ${color};
`;

const Logo = styled.img`
  max-width: 200px;
`;

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Link href="https://rldatix.com/" color="nav.25" fontSize={0}>
      {t("footer.copyright", { year: copyrightYear })}
    </Link>
  );
};

const StyledFooter = styled.footer`
  margin-top: auto;
`;

const Footer = () => (
  <StyledFooter>
    <FooterFlexContainer id="footer">
      <Box>
        <Link href="https://rldatix.com/">
          <Logo
            src={`${window.S3_IMG_URL}rldatix-policystat_logo.png`}
            alt="rldatix policystat logo"
          />
        </Link>
      </Box>
      <Box>
        <Copyright />
      </Box>
    </FooterFlexContainer>
  </StyledFooter>
);

export default Footer;
