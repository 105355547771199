/**
 * @flow
 * @relayHash ea7c67b8c4d37c656b4c48dc71b3e518
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SubscribeToCommentsMutationVariables = {|
  documentPk: number
|};
export type SubscribeToCommentsMutationResponse = {|
  +subscribeToComments: ?{|
    +ok: ?boolean,
    +reasons: ?any,
  |}
|};
export type SubscribeToCommentsMutation = {|
  variables: SubscribeToCommentsMutationVariables,
  response: SubscribeToCommentsMutationResponse,
|};
*/


/*
mutation SubscribeToCommentsMutation(
  $documentPk: Int!
) {
  subscribeToComments(documentPk: $documentPk) {
    ok
    reasons
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "subscribeToComments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentPk",
        "variableName": "documentPk",
        "type": "Int!"
      }
    ],
    "concreteType": "SubscribeToCommentsMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SubscribeToCommentsMutation",
  "id": "06b9460ddca05abd401c65bb4e882bb4",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SubscribeToCommentsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SubscribeToCommentsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation SubscribeToCommentsMutation(\n  $documentPk: Int!\n) {\n  subscribeToComments(documentPk: $documentPk) {\n    ok\n    reasons\n  }\n}\n";
(node/*: any*/).hash = 'cdbc4234b29c2f0dcb6dbc6f82c3d3eb';
module.exports = node;
