/**
 * @flow
 * @relayHash 229a4006639c9daa980e83c802e8a349
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewAndRecentlyRevisedCSVQueryVariables = {||};
export type NewAndRecentlyRevisedCSVQueryResponse = {|
  +recentlyRevisedDocumentsCsv: ?{|
    +taskId: ?string
  |}
|};
export type NewAndRecentlyRevisedCSVQuery = {|
  variables: NewAndRecentlyRevisedCSVQueryVariables,
  response: NewAndRecentlyRevisedCSVQueryResponse,
|};
*/


/*
query NewAndRecentlyRevisedCSVQuery {
  recentlyRevisedDocumentsCsv {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "recentlyRevisedDocumentsCsv",
    "storageKey": null,
    "args": null,
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewAndRecentlyRevisedCSVQuery",
  "id": "aede579c89f618b90ac683dea8ae2c89",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewAndRecentlyRevisedCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "NewAndRecentlyRevisedCSVQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query NewAndRecentlyRevisedCSVQuery {\n  recentlyRevisedDocumentsCsv {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = 'ca6d7a4867573147e88395c6313cdc69';
module.exports = node;
