/**
 * @flow
 * @relayHash b15bf866483954f6275af5ecc1d2b8a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ManyEpStandardContentQueryVariables = {|
  associationsPk: $ReadOnlyArray<?number>
|};
export type ManyEpStandardContentQueryResponse = {|
  +manyEps: ?{|
    +data: ?$ReadOnlyArray<?{|
      +documentLineEpAssociationId: ?string,
      +name: ?string,
      +lastChanged: ?string,
    |}>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type ManyEpStandardContentQuery = {|
  variables: ManyEpStandardContentQueryVariables,
  response: ManyEpStandardContentQueryResponse,
|};
*/


/*
query ManyEpStandardContentQuery(
  $associationsPk: [Int]!
) {
  manyEps(associationsPk: $associationsPk) {
    data {
      documentLineEpAssociationId
      name
      lastChanged
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "associationsPk",
    "type": "[Int]!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "manyEps",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "associationsPk",
        "variableName": "associationsPk",
        "type": "[Int]!"
      }
    ],
    "concreteType": "ManyDocLineEpsStandard",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "data",
        "storageKey": null,
        "args": null,
        "concreteType": "ManyDocLineEpsStandardData",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "documentLineEpAssociationId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastChanged",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ManyEpStandardContentQuery",
  "id": "ab3630bb6134a01b9021764e1f0d742c",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ManyEpStandardContentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ManyEpStandardContentQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query ManyEpStandardContentQuery(\n  $associationsPk: [Int]!\n) {\n  manyEps(associationsPk: $associationsPk) {\n    data {\n      documentLineEpAssociationId\n      name\n      lastChanged\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'e7b24d553bad980e336852ad80a09a9f';
module.exports = node;
