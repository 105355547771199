/**
 * @flow
 * @relayHash 6ecd61bb36c5ae310e7768156bace4e7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthenticateWithOnesourceMutationVariables = {|
  redirectUrl: string
|};
export type AuthenticateWithOnesourceMutationResponse = {|
  +onesourceAuthUrl: ?{|
    +redirectPath: ?string,
    +error: ?{|
      +statusCode: ?number
    |},
  |}
|};
export type AuthenticateWithOnesourceMutation = {|
  variables: AuthenticateWithOnesourceMutationVariables,
  response: AuthenticateWithOnesourceMutationResponse,
|};
*/


/*
mutation AuthenticateWithOnesourceMutation(
  $redirectUrl: String!
) {
  onesourceAuthUrl(redirectUrl: $redirectUrl) {
    redirectPath
    error {
      statusCode
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "redirectUrl",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "onesourceAuthUrl",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "redirectUrl",
        "variableName": "redirectUrl",
        "type": "String!"
      }
    ],
    "concreteType": "OneSourceAuthUrlMutation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectPath",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "AuthenticateWithOnesourceMutation",
  "id": "8116bcf6e640d8a0d7f866a1bada4d16",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AuthenticateWithOnesourceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthenticateWithOnesourceMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation AuthenticateWithOnesourceMutation(\n  $redirectUrl: String!\n) {\n  onesourceAuthUrl(redirectUrl: $redirectUrl) {\n    redirectPath\n    error {\n      statusCode\n    }\n  }\n}\n";
(node/*: any*/).hash = 'bf14128726c49c4bd9a367156208d925';
module.exports = node;
