import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation BulkReviewMutation($documentPks: [Int], $comment: String!) {
    bulkReview(documentPks: $documentPks, comment: $comment) {
      taskId
    }
  }
`;

export default function doBulkReview({ documentPks, comment, callback }) {
  const variables = {
    documentPks,
    comment
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => console.error(err)
  });
}
