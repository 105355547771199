import graphql from "babel-plugin-relay/macro";

const NewAndRecentlyRevisedQuery = graphql`
  query NewAndRecentlyRevisedQuery($after: String, $first: Int, $sort: String) {
    recentlyRevisedDocuments(sort: $sort) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            bloc {
              name
            }
            applicabilityType
            category {
              name
            }
            revisedFromParentStatus
            effectiveDate
            restricted
            hasAttachments
          }
        }
      }
      buckets
      rssFeedUrl
    }
  }
`;

export default NewAndRecentlyRevisedQuery;
