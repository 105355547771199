import { CREATE_FROM_WORD_KEY } from "../../document_builder/ImportFromWord";

import PolicyEditor from "pstat-anywhere/components/document_control/PolicyEditor";
import { CREATE_TYPE } from "pstat-anywhere/components/document_control/PolicyEditor/utils";
import { withDataStore } from "pstat-anywhere/context_providers/DataStoreContext";
import {
  POLICY_ACTIONS,
  TriggerEventOnMount,
  triggerPolicyEvent
} from "pstat-anywhere/utils/googleTagManager";


// @flow

type Props = {
  documentCreate: Object,
  dataStore: Object,
  match: Object
};
function _CreatePolicyPage({ documentCreate, dataStore, match, route }: Props) {
  if (documentCreate) {
    let createFromWordData = null;
    if (match) {
      const createFromWord = match.location.query.createFromWord;
      if (createFromWord) {
        createFromWordData = dataStore[CREATE_FROM_WORD_KEY];
        dataStore[CREATE_FROM_WORD_KEY] = null;
      }
    }
    return (
      <TriggerEventOnMount
        triggerEvent={() => {
          const document = documentCreate.document;
          const editForm = documentCreate.editForm;
          if (document) {
            triggerPolicyEvent(
              POLICY_ACTIONS.EDIT,
              document.pk,
              document.name,
              editForm.category.pk,
              editForm.category.name
            );
          }
        }}
      >
        <PolicyEditor
          document={documentCreate.document}
          documentSave={documentCreate.documentSave}
          editForm={documentCreate.editForm}
          tagForm={documentCreate.tagForm}
          saveForm={documentCreate.saveForm}
          editorProperties={documentCreate}
          scheduledEffectiveDateForm={documentCreate.scheduledEffectiveDateForm}
          systemDocumentTemplateForm={documentCreate.systemDocumentTemplateForm}
          policyActions={documentCreate.policyActions}
          route={route}
          type={CREATE_TYPE}
          createFromWord={createFromWordData}
          enabledAutoSaveTimeout={true}
          canLocalize={documentCreate.canLocalize}
        />
      </TriggerEventOnMount>
    );
  }
  return null;
}

export default withDataStore(_CreatePolicyPage);
