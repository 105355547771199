import { Box } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Panel from "pstat-design-system/Panel";
import { Text } from "pstat-design-system/text";

const RevisionsPanel = props => {
  const { t } = useTranslation();

  return (
    <Fragment>
      {props.revisionDates && (
        <Box width={1} px={[0, 0, 3]}>
          <Panel
            id="allRevisionDates"
            hideHeaderBorder={true}
            name={t("documentControl.view.revisionDates")}
          >
            <Text>{props.revisionDates.map(x => `${x}`).join(", ")}</Text>
          </Panel>
        </Box>
      )}
    </Fragment>
  );
};

export default RevisionsPanel;
