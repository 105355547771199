import VisuallyHidden from "@reach/visually-hidden";
import { Component, Fragment, createRef } from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { StyledFontAwesomeIcon } from "../../partials/icons";
import Link from "../RouterFocusLink";
import { Label, Text } from "../text";

import { FrozenColumnTD, FrozenTableTD, StackingTD, TD } from "./TD";
import { FrozenTH, FrozenTableTH, TH } from "./TH";

export const Cell = ({
  color = "secondary.0",
  TDComp = TD,
  CellSpan = Text,
  ContentComponent,
  to,
  TDRef,
  pk,
  pl,
  py,
  ...props
}) => {
  return (
    <TDComp tabIndex="-1" {...props} py={0} pl={0} innerRef={TDRef}>
      {props.label && <VisuallyHidden>{`${props.label},`}</VisuallyHidden>}
      <CellSpan
        color={props.valid ? color : "messages.error"}
        fontStyle={props.fontStyle ? props.fontStyle : ""}
        fontSize={props.fontSize}
        to={to || null}
        py={py || 3}
        pl={pl || 1}
        width="100%"
        height="100%"
        display="block"
      >
        {ContentComponent ? (
          <ContentComponent pk={pk} {...props} />
        ) : (
          <Fragment>
            {props.valid ? (
              ""
            ) : (
              <StyledFontAwesomeIcon icon={["fal", "exclamation-triangle"]} />
            )}{" "}
            {props.children}
          </Fragment>
        )}
      </CellSpan>
    </TDComp>
  );
};

Cell.defaultProps = {
  valid: 1
};

export const StackingCell = ({ to, ...props }) => {
  const { rowKey, rowIndex, columnIndex, ...otherProps } = props;
  return (
    <Cell
      TDComp={StackingTD}
      CellSpan={to ? Link : Text}
      {...otherProps}
      to={to}
    />
  );
};

export class FrozenTableCell extends Component {
  constructor(props) {
    super(props);

    this.frozenTD = createRef();
  }

  componentDidMount() {
    const td = this.frozenTD.current;
    if (td) {
      const tr = td.parentElement;
      if (td.clientHeight > tr.clientHeight) {
        tr.style.height = `${td.clientHeight}px`;
      }
    }
  }

  render() {
    if (this.props.column && this.props.column.frozen) {
      return (
        <Cell
          TDComp={FrozenColumnTD}
          CellSpan={this.props.to ? Link : Text}
          {...this.props}
          TDRef={this.frozenTD}
        />
      );
    }
    return (
      <Cell
        TDComp={FrozenTableTD}
        CellSpan={this.props.to ? Link : Text}
        {...this.props}
        minWidth={[200, 75, 50]}
        maxWidth={300}
      />
    );
  }
}

const HeadingLabel = styled(Label)`
  &.sort {
    color: ${themeGet("colors.secondary.0")};
    cursor: pointer;
  }
  &.sort-asc,
  &.sort-desc {
    text-decoration: underline;
  }
`;

export const HeaderCell = ({
  children,
  THComponent = TH,
  ContentComponent,
  ...props
}) => {
  let sortArrow = null;
  if (props.className) {
    if (props.className.indexOf("sort-asc") > -1) {
      sortArrow = (
        <StyledFontAwesomeIcon
          icon={["far", "sort-down"]}
          size="lg"
          color="secondary.0"
        />
      );
    } else if (props.className.indexOf("sort-desc") > -1) {
      sortArrow = (
        <StyledFontAwesomeIcon
          icon={["far", "sort-up"]}
          size="lg"
          color="secondary.0"
        />
      );
    }
  }
  return (
    <THComponent style={props.style}>
      {ContentComponent ? (
        <ContentComponent {...props} />
      ) : (
        <Fragment>
          <HeadingLabel {...props}>{children}</HeadingLabel>
          {sortArrow}
        </Fragment>
      )}
    </THComponent>
  );
};

export const FrozenTableHeaderCell = props => {
  let THComponent = FrozenTableTH;
  if (props.frozen) {
    THComponent = FrozenTH;
  }
  return <HeaderCell THComponent={THComponent} {...props} />;
};
