import { Box, Flex } from "grid-styled";
import PropTypes from "prop-types";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import titleCase from "pstat-anywhere/utils/titleCase";
import { FocusLink } from "pstat-design-system/Link";
import Sticky from "pstat-design-system/Sticky";
import { H1 } from "pstat-design-system/text/headings";
import MobileHorizontalScrollControl from "pstat-design-system/utils/AddHorizontalScrollControls";

export const SIDEBAR_MOBILE_HEIGHT = 42;

const SideBarMenuContent = ({
  titleName,
  menuItems,
  onItemClick,
  forceActiveItem,
  renderLink,
  isDesktop,
  updateOnScroll = true
}) => {
  const [activeItem, setActiveItem] = useState(0);
  const scrollFromLink = useRef(false);
  const handleKeyDown = (event, itemNumber) => {
    if (event.key === "Enter") {
      handleItemClick(event, itemNumber);
    }
  };
  const handleItemClick = (event, itemNumber) => {
    scrollFromLink.current = true;
    setTimeout(() => {
      scrollFromLink.current = false;
    }, 1000);

    setActiveItem(itemNumber);
    onItemClick && onItemClick(itemNumber);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setActiveItemCallback = useCallback(handleItemClick, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyDownCallback = useCallback(handleKeyDown, []);

  useEffect(() => {
    if (updateOnScroll) {
      const updateActiveElementOnScroll = event => {
        if (!scrollFromLink.current) {
          setActiveItem(getActiveElementAfterScroll(menuItems));
        }
      };
      window.addEventListener("scroll", updateActiveElementOnScroll);
      return () =>
        window.removeEventListener("scroll", updateActiveElementOnScroll);
    }
  }, [menuItems, updateOnScroll]);

  return isDesktop ? (
    <Fragment>
      <H1 color="nav.0" mb={3}>
        {titleName}
      </H1>
      <SideBarLinks
        menuItems={menuItems}
        activeItem={forceActiveItem || activeItem}
        handleClick={setActiveItemCallback}
        handleKeyDown={handleKeyDownCallback}
        renderLink={renderLink}
      />
    </Fragment>
  ) : (
    <RelativePositioner>
      <MobileHorizontalScrollControl
        render={theRef => (
          <Flex id="sidebar_mobile_container" innerRef={theRef} flex={1}>
            <SideBarLinks
              menuItems={menuItems}
              activeItem={activeItem}
              handleClick={setActiveItemCallback}
              handleKeyDown={handleKeyDownCallback}
              renderLink={renderLink}
            />
          </Flex>
        )}
      />
    </RelativePositioner>
  );
};

const getActiveElementAfterScroll = menuItems => {
  let highestVisibleElementIndex = 0;
  for (let i = 0; i < menuItems.length; i++) {
    const elementIdSelector = menuItems[i].href;
    // slice off # from id
    const element = document.getElementById(elementIdSelector.slice(1));
    if (element) {
      const { top } = element.getBoundingClientRect();
      if (top <= 100) {
        highestVisibleElementIndex = i;
      }
    }
  }
  return highestVisibleElementIndex;
};

const SideBarMenu = ({ id = "sidebar", mt, p, top, width, ...otherProps }) => {
  return (
    <Media query={{ minWidth: theme.breakpoints[1] }}>
      {isDesktop =>
        isDesktop ? (
          <Box alignSelf="stretch" p={p} width={width} mt={mt}>
            <Sticky id={id} offset={top ? top : 0}>
              <SideBarMenuContent isDesktop={isDesktop} {...otherProps} />
            </Sticky>
          </Box>
        ) : (
          <Sticky
            id={id}
            offset={top ? top : 0}
            mt={mt}
            p={p}
            width={width}
            zIndex={theme.layers.tertiaryNav}
          >
            <SideBarMenuContent isDesktop={isDesktop} {...otherProps} />
          </Sticky>
        )
      }
    </Media>
  );
};

SideBarMenu.propTypes = {
  titleName: PropTypes.string.isRequired
};

export default SideBarMenu;

const _SideBarLinks = ({
  menuItems,
  activeItem,
  handleClick,
  handleKeyDown,
  labels,
  renderLink
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {menuItems.map((item, index) => {
        const isActive = activeItem === index;
        let text = null;
        if (item === "line") {
          return (
            <Media query={{ minWidth: theme.breakpoints[1] }}>
              {isDesktop => isDesktop && <HorizontalLine />}
            </Media>
          );
        }
        if (
          item.translationKey === "documentControl.edit.effectiveDateSection"
        ) {
          text = t(item.translationKey, {
            effectiveDateLabel: titleCase(labels.effectiveDateLabel)
          });
        } else {
          text = t(item.translationKey);
        }
        if (renderLink) {
          return renderLink(item, text, index, isActive);
        }
        return (
          <SideBarLink
            key={index}
            active={isActive}
            light={isActive}
            href={item.href}
            target={item.target}
            onClick={event => handleClick(event, index)}
            onKeyDown={event => handleKeyDown(event, index)}
          >
            {text}
          </SideBarLink>
        );
      })}
    </Fragment>
  );
};

const SideBarLinks = memo(withLabelContext(_SideBarLinks));

const RelativePositioner = styled("div")`
  background-color: ${themeGet("colors.nav.95")};
  position: relative;
  height: ${SIDEBAR_MOBILE_HEIGHT}px;
`;

export const SideBarLink = styled(FocusLink)`
  flex: 1;
  display: inline-flex;
  justify-content: center;
  @media screen and (min-width: ${themeGet("breakpoints.1")}) {
    display: block;
  }
  color: ${props =>
    props.active ? themeGet("colors.nav.100") : themeGet("colors.secondary.0")};
  height: "44px";
  border-bottom: ${props => (props.noBorder ? "none" : themeGet("borders.1"))};
  border-color: ${themeGet("colors.nav.80")};
  padding: ${themeGet("space.2")}px;
  &:hover {
    text-decoration: none;
    background-color: ${themeGet("colors.nav.10")};
    color: ${themeGet("colors.nav.100")};
  }
  background-color: ${props =>
    props.active ? themeGet("colors.nav.10") : themeGet("colors.nav.95")};
  white-space: nowrap;
  @media (min-width: ${themeGet("breakpoints.1")}) {
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
  }
`;

export const ChangelogLink = styled(SideBarLink)`
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  flex-direction: column; // ensure label is always above the link
  ::before {
    content: "${props => props.label}";
    font-size: 8px; // no option in theme.js
    font-weight: 600;
    background: ${themeGet("colors.primary.0")};
    margin-left: ${themeGet("space.1")}px; // align with child Link content
    padding: 2px ${themeGet("space.1")}px;
    border-radius: ${themeGet("radii.2")}px;
  }
`;
ChangelogLink.displayName = "ChangelogLink";

const HorizontalLine = styled.div`
  width: 100%;
  border-top: ${themeGet("borders.2")};
  border-color: ${themeGet("colors.nav.80")};
  margin-top: ${themeGet("space.2")}px;
  margin-bottom: ${themeGet("space.2")}px;
`;
