import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import { StyledFontAwesomeIcon } from "../icons";

import ExportModal from "./ExportModal";

import { Text } from "pstat-design-system/text";
import MenuButton from "pstat-design-system/shared/MenuButton";

class Export extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  close = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <MenuButton onClick={this.toggle}>
          <StyledFontAwesomeIcon icon={["fal", "download"]} />
          <Text color="secondary.0">
            {t("documentReports.export.exportLink")}
          </Text>
        </MenuButton>
        <ExportModal
          isOpen={this.state.isOpen}
          onClose={this.close}
          csvQuery={this.props.csvQuery}
          queryName={this.props.queryName}
          csvQueryArgs={this.props.csvQueryArgs}
        />
      </Fragment>
    );
  }
}

Export.propTypes = {
  csvQuery: PropTypes.func.isRequired
};

export default withTranslation()(Export);
