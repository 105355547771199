import { isValid } from "date-fns";
import { Box, Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ActiveDate,
  EFFECTIVE_DATE_DATETIME,
  EFFECTIVE_DATE_TIMEDELTA,
  EFFECTIVE_DATE_UPON_APPROVAL,
  getEffectiveDateAlgorithm
} from "../PolicyEditor/components/EffectiveDate";

import editEffectiveDate from "./EditScheduledEffectiveDateMutation";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import titleCase from "pstat-anywhere/utils/titleCase";
import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";



const EditEffectiveDateModal = ({ isOpen, onClose, labels, document }) => {
  // if the date is None on the backend it will a string like 'Upon Approval'
  const effectiveDateIsADate = effectiveDate =>
    isValid(new Date(effectiveDate));
  const getInitialEffectiveDate = effectiveDate => {
    if (effectiveDateIsADate(effectiveDate)) {
      return effectiveDate;
    }
    return null;
  };
  const { t } = useTranslation();
  const initialEffectiveDate = getInitialEffectiveDate(
    document.rawEffectiveDate
  );
  let initialSelectedOption = EFFECTIVE_DATE_UPON_APPROVAL;
  if (document.effectiveDateTimedelta) {
    initialSelectedOption = EFFECTIVE_DATE_TIMEDELTA;
  } else if (initialEffectiveDate) {
    initialSelectedOption = EFFECTIVE_DATE_DATETIME;
  }
  const [
    effectiveDateSelectedOption,
    setEffectiveDateSelectedOption
  ] = useState(initialSelectedOption);
  const [effectiveDateDatetime, setEffectiveDateDatetime] = useState(
    initialEffectiveDate
  );
  const [effectiveDateTimedelta, setEffectiveDateTimedelta] = useState(
    document.effectiveDateTimedelta
  );
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const checkChangesMade = (
    effectiveDateDatetime,
    effectiveDateTimedelta,
    effectiveDateAlgorithm
  ) => {
    const effectiveDateChanged = initialEffectiveDate !== effectiveDateDatetime;
    const timedelta = effectiveDateTimedelta
      ? parseInt(effectiveDateTimedelta, 10)
      : effectiveDateTimedelta;
    const effectiveDateTimedeltaChanged =
      document.effectiveDateTimedelta !== timedelta;
    const effectiveDateAlgorithmChanged =
      initialSelectedOption !== effectiveDateAlgorithm;
    const dateChanged =
      effectiveDateChanged ||
      effectiveDateTimedeltaChanged ||
      effectiveDateAlgorithmChanged;
    setChangesMade(dateChanged);
  };

  const handleUpdateEffectiveDate = () => {
    const variables = {
      input: {
        documentPk: document.pk,
        effectiveDate: effectiveDateDatetime,
        effectiveDateTimedelta,
        effectiveDateAlgorithm: getEffectiveDateAlgorithm(
          effectiveDateSelectedOption
        )
      }
    };
    setLoading(true);
    editEffectiveDate(environment, variables, (response, errors) => {
      setLoading(false);
      if (errors) {
        createErrorMessage(
          t("documentControl.view.editEffectiveDate.error", {
            effectiveDateLabel: labels.effectiveDateLabel.toLowerCase()
          })
        );
        onClose();
      }
      if (response.editScheduledEffectiveDate.ok) {
        createSuccessMessage(
          t("documentControl.view.editEffectiveDate.success", {
            effectiveDateLabel: titleCase(labels.effectiveDateLabel)
          })
        );
        onClose();
      } else {
        const reasons = response.editScheduledEffectiveDate.reasons;
        if (reasons?.effective_date) {
          setErrors([reasons.effective_date]);
        }
      }
    });
  };
  const handleUpdateEffectiveDateKeyDown = event => {
    if (event.key === "Enter") {
      handleUpdateEffectiveDate();
    }
  };
  const handleCancelKeyDown = event => {
    if (event.key === "Enter") {
      onClose();
    }
  };
  const toggleEffectiveDateOption = option => {
    setEffectiveDateSelectedOption(option);
    checkChangesMade(effectiveDateDatetime, effectiveDateTimedelta, option);
  };
  const handleEffectiveDateDatetimeChange = effectiveDate => {
    toggleEffectiveDateOption(EFFECTIVE_DATE_DATETIME);
    setEffectiveDateDatetime(effectiveDate);
    checkChangesMade(
      effectiveDate,
      effectiveDateTimedelta,
      effectiveDateSelectedOption
    );
  };
  const handleEffectiveDateTimedeltaChange = effectiveDateTimedelta => {
    toggleEffectiveDateOption(EFFECTIVE_DATE_TIMEDELTA);
    setEffectiveDateTimedelta(effectiveDateTimedelta);
    checkChangesMade(
      effectiveDateDatetime,
      effectiveDateTimedelta,
      effectiveDateSelectedOption
    );
  };
  return (
    <Modal
      id="edit_scheduled_effective_date_modal"
      contentLabel={t("documentControl.edit.effectiveDateSection", {
        effectiveDateLabel: labels.effectiveDateLabel
      })}
      isOpen={isOpen}
      onRequestClose={onClose}
      tooltipText={t("documentControl.edit.effectiveDateSection.tooltip", {
        documentLabel: labels.documentLabel.toLowerCase(),
        authorLabelPlural: titleCase(labels.authorLabelPlural)
      })}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                buttonStyle="secondary"
                onClick={handleUpdateEffectiveDate}
                onKeyDown={handleUpdateEffectiveDateKeyDown}
                processing={loading}
                disabled={!changesMade}
              >
                {t("buttons.save")}
              </Button>
            </Box>
            <Box>
              <Button
                buttonStyle="tertiary"
                onClick={onClose}
                onKeyDown={handleCancelKeyDown}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={4}>
        <ActiveDate
          effectiveDateDatetime={effectiveDateDatetime}
          effectiveDateTimedelta={effectiveDateTimedelta}
          onEffectiveDateDatetimeChange={handleEffectiveDateDatetimeChange}
          onEffectiveDateTimedeltaChange={handleEffectiveDateTimedeltaChange}
          errors={errors}
          toggleRadioButtons={toggleEffectiveDateOption}
          effectiveDateSelectedOption={effectiveDateSelectedOption}
          isScheduled={document.status === "SCHEDULED"}
        />
      </Flex>
    </Modal>
  );
};

export default withLabelContext(EditEffectiveDateModal);
