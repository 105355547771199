import { withRouter } from "found";
import { Flex } from "grid-styled";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ContentContainer } from "../../pages";

import LippincottSearchBar from "./LippincottSearchBar";

import {
  ReportHeader,
  ReportPage
} from "pstat-anywhere/components/pages/reports";
import useTaskResult from "pstat-anywhere/utils/useTaskResult";
import Card from "pstat-design-system/Card";
import { FocusLink } from "pstat-design-system/Link";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { SortablePolicyTable } from "pstat-design-system/tables";
import { EmptyTabContainer } from "pstat-design-system/Tabs";
import { Error, Text } from "pstat-design-system/text";



export const RESULTS_PER_PAGE = 50;

const ShowAllLinkContainer = styled.div`
  margin-top: 5px;
`;

const ActionItem = styled.div`
  float: left;
`;

const _LippincottSearchPage = ({
  lippincottSearch,
  location,
  router,
  textLoading
}) => {
  const taskId = lippincottSearch?.taskId;
  const [searchQuery, setSearchQuery] = useState(location.query?.q);
  const [showAll, setShowAll] = useState(location.query?.showAll);
  const { loading, result } = useTaskResult(taskId);
  const { t } = useTranslation();
  const didMountRef = useRef(false);
  const docList = result?.procedure_list || [];
  const numResults = result?.num_results;
  const error = result?.error;
  const displayShowAllButton =
    !loading && !error && !showAll && docList.length < numResults;

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }
    const queryParams = {
      q: searchQuery
    };
    if (showAll) {
      queryParams.showAll = showAll;
    }
    const to = {
      pathname: location.pathname,
      query: queryParams
    };
    router.push(to);
  }, [showAll, searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLoadingSpinner = () => {
    return (
      <Fragment>
        {textLoading ? (
          <Text>Loading...</Text>
        ) : (
          <Flex justifyContent="center">
            <LoadingSpinner size={100} />
          </Flex>
        )}
      </Fragment>
    );
  };

  const renderTableContent = () => {
    return (
      <Fragment>
        {error ? (
          <Error> {error} </Error>
        ) : (
          <SortablePolicyTable
            id="results_table"
            policies={docList}
            tableType="lippincott"
            noSort={true}
            onSortChange={() => {}}
            showPagination={false}
            customOnRow={() => {}}
          />
        )}
      </Fragment>
    );
  };

  return (
    <ContentContainer>
      <ReportPage showPagination={false}>
        <ReportHeader title={t("lippincottSearch.header")}>
          {displayShowAllButton && (
            <ActionItem>
              <ShowAllLinkContainer>
                <FocusLink
                  dataTestid="linkShowAll"
                  onActivation={() => setShowAll(!showAll)}
                >
                  <Text color="secondary.0">
                    {t("lippincottSearch.showAll")}
                  </Text>
                </FocusLink>
              </ShowAllLinkContainer>
            </ActionItem>
          )}
        </ReportHeader>
        {!loading && !error && (
          <EmptyTabContainer
            totalCount={numResults}
            currentPageResultsCount={docList.length}
          />
        )}
        <Card>
          <Fragment>
            <LippincottSearchBar
              onSearch={value => {
                setSearchQuery(value);
                setShowAll(false);
              }}
              value={searchQuery}
            />
            {!lippincottSearch || loading
              ? renderLoadingSpinner()
              : renderTableContent()}
          </Fragment>
        </Card>
      </ReportPage>
    </ContentContainer>
  );
};

export default withRouter(_LippincottSearchPage);
