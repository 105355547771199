import { Box, Flex } from "grid-styled";
import { Component, Fragment, useRef } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import * as Table from "reactabular-table";
import * as sort from "sortabular";
import styled from "styled-components";
import system from "system-components";
import * as resolve from "table-resolver";

import Export from "pstat-anywhere/components/partials/export/Export";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { BucketContainer } from "pstat-anywhere/components/reports/Buckets";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import theme from "pstat-anywhere/themes/policystat/theme";
import { RouterLinkedBadge } from "pstat-design-system/Badge";
import Card from "pstat-design-system/Card";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { Caption } from "pstat-design-system/tables/Caption";
import CardTable, {
  FixedLayoutCardTable
} from "pstat-design-system/tables/CardTable";
import {
  FrozenTableCell,
  FrozenTableHeaderCell,
  HeaderCell,
  StackingCell
} from "pstat-design-system/tables/Cells";
import COL from "pstat-design-system/tables/COL";
import { getColumnPresets } from "pstat-design-system/tables/PolicyTable/config";
import { LinkedTR, TRHiddenSmall } from "pstat-design-system/tables/TR";
import {
  getDefaultSortObject,
  getSort,
  getSortString,
  onRow,
  setFrozenColumn,
  setLabelsFromGraphQL
} from "pstat-design-system/tables/utils/helpers";
import { H2 } from "pstat-design-system/text";
import { Text } from "pstat-design-system/text/paragraph";
import HorizontalScrollControl from "pstat-design-system/utils/AddHorizontalScrollControls";

const PolicyTable = ({
  headingContent,
  tableType,
  policies,
  components,
  numberToDisplay,
  labels,
  link,
  csvQuery,
  csvQueryName,
  tenant,
  totalCount = policies?.length,
  rowKey,
  BucketsComponent,
  PrefixHeadingComponent,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const loading = !policies;

  let showLegacyEffectiveDate;
  if (
    tenant.hasScheduledEffectiveDateEnabled === true ||
    typeof tenant.hasScheduledEffectiveDateEnabled === "undefined"
  ) {
    showLegacyEffectiveDate = false;
  } else {
    showLegacyEffectiveDate = true;
  }

  let columns = setLabelsFromGraphQL(
    getColumnPresets(null, showLegacyEffectiveDate, t, {
      ...otherProps,
      singleTenantCustomer: tenant.singleTenantCustomer
    })[tableType],
    labels
  );

  const resolvedRows = resolve.resolve({
    columns: columns,
    method: resolve.nested
  })(policies);

  const isEmptyTable = policies?.length === 0;
  return (
    <Fragment>
      <Caption>
        <Flex align="center">
          {PrefixHeadingComponent && <PrefixHeadingComponent />}
          <H2 fontWeight="normal" color="nav.0">
            {PrefixHeadingComponent
              ? headingContent.titleWithoutType
              : headingContent.title}
          </H2>
          {!loading && (
            <RouterLinkedBadge
              to={link}
              aria-label={`${totalCount} ${headingContent.label}`}
            >
              {totalCount}
            </RouterLinkedBadge>
          )}
        </Flex>
        {csvQuery && <Export csvQuery={csvQuery} queryName={csvQueryName} />}
      </Caption>
      {BucketsComponent && !isEmptyTable && (
        <BucketContainer pt={0} id={`${tableType}-buckets`}>
          <BucketsComponent />
        </BucketContainer>
      )}
      {isEmptyTable ? (
        <Card
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          minHeight="86px"
        >
          <Text> {headingContent.noContent} </Text>
        </Card>
      ) : (
        <Card py={0}>
          {loading ? (
            <Flex justify="center" align="center" p={4}>
              <Box>
                <LoadingSpinner size={50} />
              </Box>
            </Flex>
          ) : (
            <Table.Provider components={components} columns={columns}>
              <Media query={{ minWidth: theme.breakpoints[1] }}>
                {
                  matches =>
                    matches ? (
                      <colgroup>
                        <COL />
                        <COL />
                        <COL />
                        <COL />
                      </colgroup>
                    ) : null // returning null needs to for tests working properly
                }
              </Media>
              <Table.Header headerRows={resolve.headerRows({ columns })} />
              <Table.Body
                rows={resolvedRows.slice(0, numberToDisplay)}
                rowKey={rowKey || "pk"}
                onRow={onRow}
              />
            </Table.Provider>
          )}
        </Card>
      )}
    </Fragment>
  );
};

PolicyTable.defaultProps = {
  numberToDisplay: 5,
  components: {
    table: FixedLayoutCardTable,
    header: {
      wrapper: "thead",
      row: TRHiddenSmall,
      cell: HeaderCell
    },
    body: {
      wrapper: "tbody",
      row: LinkedTR,
      cell: StackingCell
    }
  },
  tableType: "default"
};

PolicyTable.displayName = "PolicyTable";

class _SortablePolicyTable extends Component {
  constructor(props) {
    // Removing default customization
    if (props.cleanCustomBodyRow) {
      delete props.components.body.row;
    }

    super(props);
    let sortColumn = null;
    let otherArgs = {
      singleTenantCustomer: this.props.tenant.singleTenantCustomer
    };
    if (!props.noSort) {
      if (props.sort) {
        sortColumn = getSort(props.tableType, props.sort, props.t, otherArgs);
      }
      if (!sortColumn) {
        sortColumn = getDefaultSortObject(props.tableType, props.t, otherArgs);
      }
    }

    this.state = {
      sortingColumns: sortColumn
    };
  }

  // remove the 'none' sort state
  sortingOrder = {
    FIRST: "asc",
    asc: "desc",
    desc: "asc"
  };

  sortable = sort.sort({
    getSortingColumns: () => this.state.sortingColumns || [],
    onSort: selectedColumn => {
      const sortedColumn = sort.byColumn({
        sortingColumns: this.state.sortingColumns,
        sortingOrder: this.sortingOrder,
        selectedColumn
      });
      const sortString = getSortString(
        this.props.tableType,
        sortedColumn[selectedColumn].direction,
        selectedColumn,
        this.props.t,
        { singleTenantCustomer: this.props.tenant.singleTenantCustomer }
      );
      this.props.onSortChange(sortString);

      this.setState({
        sortingColumns: sortedColumn
      });
    }
  });

  render = () => {
    const {
      id,
      tableType,
      policies,
      components,
      labels,
      tenant,
      frozenColumn,
      horizontalScroll,
      t,
      customOnRow,
      ...otherProps
    } = this.props;
    let showLegacyEffectiveDate;
    if (
      tenant.hasScheduledEffectiveDateEnabled === true ||
      typeof tenant.hasScheduledEffectiveDateEnabled === "undefined"
    ) {
      showLegacyEffectiveDate = false;
    } else {
      showLegacyEffectiveDate = true;
    }
    const columns = setLabelsFromGraphQL(
      getColumnPresets(this.sortable, showLegacyEffectiveDate, t, {
        ...otherProps,
        singleTenantCustomer: tenant.singleTenantCustomer
      })[tableType],
      labels
    );
    if (frozenColumn) {
      setFrozenColumn(columns);
    }

    const resolvedRows = resolve.resolve({
      columns: columns,
      method: resolve.nested
    })(policies);

    return policies.length > 0 ? (
      <Table.Provider id={id} components={components} columns={columns}>
        <Table.Header headerRows={resolve.headerRows({ columns })} />
        <Table.Body
          rows={resolvedRows}
          rowKey={this.props.rowKey || "pk"}
          onRow={customOnRow || onRow}
          ref={body => {
            // this is weird but it is the way to get a ref to the table body
            // https://reactabular.js.org/#/table/components?a=getting-refs
            const bodyRef = body && body.getRef();
            if (this.props.getRef) {
              this.props.getRef(bodyRef);
            }
          }}
        />
      </Table.Provider>
    ) : null;
  };
}

_SortablePolicyTable.defaultProps = {
  numberToDisplay: 15,
  components: {
    table: FixedLayoutCardTable,
    header: {
      wrapper: "thead",
      row: TRHiddenSmall,
      cell: HeaderCell
    },
    body: {
      wrapper: "tbody",
      row: LinkedTR,
      cell: StackingCell
    }
  },
  tableType: "default"
};

_SortablePolicyTable.displayName = "SortablePolicyTable";

export const SortablePolicyTable = withTranslation()(
  withTenantContext(withLabelContext(_SortablePolicyTable))
);

export default withTenantContext(withLabelContext(PolicyTable));

const RelativePositioner = styled("div")`
  position: relative;
  width: 100%;
`;

const ScrollDiv = system({
  ml: 150
}).extend`
  ${props =>
    props.isScrolled && `box-shadow: -1px 0 2px 0 ${theme.colors.nav[80]}`};
  overflow-x: auto;
`;

const LeftScrollButtonWrapper = system({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "100%",
  width: 46
}).extend`
  background: linear-gradient(270deg, rgba(232,234,237,0.16) 0%, rgba(232,234,237,0.85) 100%);
`;

const RightScrollButtonWrapper = system({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "100%",
  width: 46
}).extend`
  background: linear-gradient(90deg, rgba(232,234,237,0.16) 0%, rgba(232,234,237,0.85) 100%);
`;

const calculateNumberOfIcons = wrapperElement => {
  const tableHeight = wrapperElement.getBoundingClientRect().height;
  let numberOfArrows = 1;
  if (tableHeight > 300) {
    numberOfArrows = Math.floor(tableHeight / 300);
  }
  return numberOfArrows;
};

export const LeftScrollButton = props => {
  const buttonWrapperRef = useRef(null);
  const arrows = [];
  if (buttonWrapperRef.current) {
    const numberOfArrows = calculateNumberOfIcons(buttonWrapperRef.current);
    for (let i = 0; i < numberOfArrows; i++) {
      arrows.push(
        <StyledFontAwesomeIcon
          key={i}
          icon={["fas", "chevron-left"]}
          size="lg"
          color="secondary.0"
          ml="auto"
          mr="auto"
        />
      );
    }
  }
  return (
    <LeftScrollButtonWrapper innerRef={buttonWrapperRef}>
      {arrows}
    </LeftScrollButtonWrapper>
  );
};

export const RightScrollButton = props => {
  const buttonWrapperRef = useRef(null);
  const arrows = [];
  if (buttonWrapperRef.current) {
    const numberOfArrows = calculateNumberOfIcons(buttonWrapperRef.current);
    for (let i = 0; i < numberOfArrows; i++) {
      arrows.push(
        <StyledFontAwesomeIcon
          key={i}
          icon={["fas", "chevron-right"]}
          size="lg"
          color="secondary.0"
          ml="auto"
          mr="auto"
        />
      );
    }
  }
  return (
    <RightScrollButtonWrapper innerRef={buttonWrapperRef}>
      {arrows}
    </RightScrollButtonWrapper>
  );
};

class _HorizontalScrollTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrolled: false
    };
  }

  checkIfScrolled = event => {
    const scrollDiv = event.target;
    const isScrolled = scrollDiv.scrollLeft !== 0;
    this.setState({ isScrolled: isScrolled });
  };

  render() {
    const { t, tableType } = this.props;
    const columns = getColumnPresets(null, false, t, {})[tableType];
    let frozenColumnWidth = 150;
    if (
      columns[0].props &&
      columns[0].props.style &&
      columns[0].props.style.width
    ) {
      frozenColumnWidth = columns[0].props.style.width;
    }

    return (
      <RelativePositioner>
        <ScrollDiv
          ml={this.props.hasFrozenColumn ? frozenColumnWidth : 0}
          isScrolled={this.state.isScrolled}
          onScroll={this.checkIfScrolled}
        >
          <HorizontalScrollControl
            render={contentRef => (
              <SortablePolicyTable
                {...this.props}
                frozenColumn={this.props.hasFrozenColumn}
                getRef={ref => (contentRef.current = ref)}
              />
            )}
            leftOffset={this.props.hasFrozenColumn ? frozenColumnWidth : 0}
            LeftButton={LeftScrollButton}
            RightButton={RightScrollButton}
          />
        </ScrollDiv>
      </RelativePositioner>
    );
  }
}

_HorizontalScrollTable.defaultProps = {
  numberToDisplay: 15,
  components: {
    table: CardTable,
    header: {
      wrapper: "thead",
      row: "tr",
      cell: FrozenTableHeaderCell
    },
    body: {
      wrapper: "tbody",
      row: "tr",
      cell: FrozenTableCell
    }
  },
  tableType: "default"
};

export const HorizontalScrollTable = withTranslation()(_HorizontalScrollTable);
