import styled from "styled-components";
import { themeGet } from "styled-system";

const Divider = styled.div`
  border-top: ${themeGet("borders.1")};
  border-color: ${themeGet("colors.nav.80")};
  margin-top: ${({ mt }) => {
    if (mt || mt <= 0) {
      return mt;
    } else {
      return themeGet("space.4");
    }
  }}px;
  margin-bottom: ${themeGet("space.4")}px;
`;

export default Divider;
