import { Route } from "found";

import NotificationsPage from "./Notifications";

import { ContentContainer } from "pstat-anywhere/components/pages";
import { environment } from "pstat-anywhere/relay";
import { PageTitleWrapper } from "pstat-anywhere/utils/pageWrappers";


const ContentWrapper = ({ children }) => (
  <ContentContainer>{children}</ContentContainer>
);

const routes = (
  <Route>
    <Route
      path="notification"
      Component={props => (
        <PageTitleWrapper titleKey="notifications.documentTitle">
          <NotificationsPage {...props} />
        </PageTitleWrapper>
      )}
      render={({ Component, props }) => (
        <ContentWrapper>
          <Component {...props} environment={environment}>
            {props.children}
          </Component>
        </ContentWrapper>
      )}
      data={{
        routeName: "notification_notices"
      }}
    />
  </Route>
);

export default routes;
