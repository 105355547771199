/**
 * @flow
 * @relayHash 18d3fc763c5b25d2edd68ea47a74578f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProxyLoginMutationVariables = {|
  user: number
|};
export type ProxyLoginMutationResponse = {|
  +proxyLogin: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectUrl: ?string,
  |}
|};
export type ProxyLoginMutation = {|
  variables: ProxyLoginMutationVariables,
  response: ProxyLoginMutationResponse,
|};
*/


/*
mutation ProxyLoginMutation(
  $user: Int!
) {
  proxyLogin(user: $user) {
    ok
    reasons
    redirectUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "user",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "proxyLogin",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "user",
        "variableName": "user",
        "type": "Int!"
      }
    ],
    "concreteType": "ProxyLoginUser",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectUrl",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ProxyLoginMutation",
  "id": "85ab21f1e9c3f5c8aa01ab4bdb8191e3",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProxyLoginMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ProxyLoginMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation ProxyLoginMutation(\n  $user: Int!\n) {\n  proxyLogin(user: $user) {\n    ok\n    reasons\n    redirectUrl\n  }\n}\n";
(node/*: any*/).hash = 'e07c53e3cf3779b62ae4808d311595af';
module.exports = node;
