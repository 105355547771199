import { Flex } from "grid-styled";
import { Component, Fragment, createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { themeGet } from "styled-system";
import system from "system-components";

import { isRequiredApproval } from "../approve_policy/utils";

import {
  ApplicabilityTenantsTootip,
  PreviewValueSpan
} from "./policy_properties/PolicyProperties";
import { ApprovalStatusLink, showEffectiveBadge } from "./StatusBar";

import { RegulationItem } from "pstat-anywhere/components/document_control/view_policy/standards_and_regulations/StandardsAndRegulationsPanel";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import Info, {
  ApprovedDate,
  AuthorContainer,
  CategoryContainer,
  DateDisplay,
  Item,
  KeyValuePair,
  References,
  ReferencesContainer,
  ReferencesLinkBadge,
  RevisionDate,
  SectionDivider,
  StatusAndPkSection
} from "pstat-anywhere/components/view/Info";
import {
  AuthorSearchLink,
  CategorySearchLink
} from "pstat-anywhere/components/view/Properties";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import { LinkedBadge } from "pstat-design-system/Badge";
import { Text } from "pstat-design-system/text";



const ApprovalStatus = ({ approvalSteps, workflowTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  function handleModalToggle() {
    setIsOpen(!isOpen);
  }
  return (
    <Item onClick={handleModalToggle}>
      <ApprovalStatusLink
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        workflowTitle={workflowTitle}
        approvalSteps={approvalSteps}
      />
    </Item>
  );
};

export const EffectiveDate = ({ effectiveDate }) => (
  <Item>
    <Text display="block">Effective</Text>
    <LinkedBadge display="inline-block" height="inherit" mx={0}>
      {effectiveDate}
    </LinkedBadge>
  </Item>
);

const Focuser = system({
  display: "inline-block",
  tabIndex: "0"
}).extend`
  &:focus {
    outline: 0;
  }
`;

const FocusBorder = system({
  border: 1,
  borderColor: "transparent",
  borderRadius: 1,
  display: "inline-block",
  p: 1,
  tabIndex: "-1"
}).extend`
  &:focus {
    outline: 0;
  }

  ${Focuser}:focus & {
    border: dashed 1px;
    border-color: ${themeGet("colors.nav.25")}
  }
`;

class Applicability extends Component {
  constructor(props) {
    super(props);

    this.applicabilityRef = createRef();
    this.tooltipTimeout = null;
  }

  componentWillUnmount() {
    clearTimeout(this.tooltipTimeout);
  }

  handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      ReactTooltip.show(this.applicabilityRef.current);
      this.tooltipTimeout = setTimeout(() => {
        ReactTooltip.hide(this.applicabilityRef.current);
      }, 2000);
    }
  };

  render() {
    const { document } = this.props;
    return (
      <Focuser
        data-tip={true}
        data-for="applicability-tenants-tooltip"
        data-event="mousedown"
        data-event-off="mouseup"
        data-delay-hide="2000"
        innerRef={this.applicabilityRef}
        onKeyDown={this.handleKeyDown}
      >
        <FocusBorder>
          <StyledFontAwesomeIcon
            icon={["fal", "question-circle"]}
            color="secondary.0"
          />
          <Text color="secondary.0">{document.bloc.name}</Text>
          <ApplicabilityTenantsTootip bloc={document.bloc} />
        </FocusBorder>
      </Focuser>
    );
  }
}

const Reference = ({ pk, text, isLastReference, isPreview }) => {
  let tagText = text;
  if (!isLastReference) {
    tagText += ",";
  }
  return (
    <Fragment>
      {isPreview ? (
        <PreviewValueSpan>{tagText}</PreviewValueSpan>
      ) : (
        <ReferencesLinkBadge pk={pk} text={text} />
      )}
    </Fragment>
  );
};

const Regulations = ({ regulations }) => {
  return regulations.map((regulation, index) => {
    return (
      <RegulationItem
        {...regulation}
        isLast={index === regulations.length - 1}
      />
    );
  });
};

const ViewDocumentInfoModalContent = ({
  document,
  labels,
  tenant,
  isPreview,
  t
}) => (
  <Fragment>
    <Flex wrap="wrap">
      <StatusAndPkSection pk={document.pk} status={document.status} />
      <Flex wrap="wrap">
        {isRequiredApproval(document) && (
          <ApprovalStatus
            workflowTitle={
              document.approvalWorkflow && document.approvalWorkflow.name
            }
            approvalSteps={
              document.approvalWorkflow && document.approvalWorkflow.steps
            }
          />
        )}
        {showEffectiveBadge(tenant, document) && (
          <EffectiveDate {...document} />
        )}
      </Flex>
    </Flex>
    <SectionDivider />
    <Flex wrap="wrap">
      {tenant.documentSettings.originationDateShow && (
        <DateDisplay
          label={labels.originationDateLabel}
          value={
            tenant.hasScheduledEffectiveDateEnabled === true
              ? document.originationDate
              : document.legacyEffectiveDate
          }
        />
      )}
      <ApprovedDate
        documentSettings={tenant.documentSettings}
        labels={labels}
        date={document.approvalDate}
      />
      {tenant.hasScheduledEffectiveDateEnabled &&
        tenant.documentSettings.effectiveDateShow && (
          <DateDisplay
            label={labels.effectiveDateLabel}
            value={document.effectiveDate}
          />
        )}
      <RevisionDate
        documentSettings={tenant.documentSettings}
        labels={labels}
        date={document.revisionDate}
      />
      {tenant.documentSettings.expirationDateShow && (
        <DateDisplay
          label={labels.expirationDateLabel}
          value={document.expirationDate}
        />
      )}
    </Flex>
    <SectionDivider />
    <AuthorContainer documentSettings={tenant.documentSettings} labels={labels}>
      {isPreview ? (
        <PreviewValueSpan>
          {document.author.firstName} {document.author.lastName}
        </PreviewValueSpan>
      ) : (
        <AuthorSearchLink author={document.author} />
      )}
    </AuthorContainer>
    <CategoryContainer
      documentSettings={tenant.documentSettings}
      labels={labels}
    >
      {isPreview ? (
        <PreviewValueSpan>{document.category.name}</PreviewValueSpan>
      ) : (
        <CategorySearchLink category={document.category} />
      )}
    </CategoryContainer>
    <KeyValuePair label={t("tables.labels.applicability")}>
      <div>
        <Applicability document={document} />
      </div>
    </KeyValuePair>
    <ReferencesContainer
      references={document.tags}
      documentSettings={tenant.documentSettings}
      labels={labels}
    >
      <References
        references={document.tags}
        renderReference={(pk, text, isLastReference) => (
          <Reference
            pk={pk}
            text={text}
            isLastReference={isLastReference}
            isPreview={isPreview}
          />
        )}
      />
    </ReferencesContainer>
    {document.regulations && document.regulations.length > 0 && (
      <Fragment>
        <KeyValuePair
          id="standards-and-regulations"
          label={t("nav.primary.manage.standardsAndRegs")}
        >
          <Flex wrap="wrap">
            <Regulations regulations={document.regulations} />
          </Flex>
        </KeyValuePair>
      </Fragment>
    )}
  </Fragment>
);

const EditDocumentInfoModalContent = ({ document }) => (
  <StatusAndPkSection pk={document.pk} status={document.status} />
);

const _Info = props => {
  const {
    document,
    labels,
    tenant,
    isPreview,
    isViewDocument,
    startOpen
  } = props;
  const { documentLabel } = labels;
  const { t } = useTranslation();
  return (
    <Info objectLabel={documentLabel} startOpen={startOpen}>
      {isViewDocument ? (
        <ViewDocumentInfoModalContent
          document={document}
          labels={labels}
          tenant={tenant}
          isPreview={isPreview}
          t={t}
        />
      ) : (
        <EditDocumentInfoModalContent document={document} />
      )}
    </Info>
  );
};

export default withTenantContext(withLabelContext(_Info));
