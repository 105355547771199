import system from "system-components";

import { Text } from "pstat-design-system/text";

export const RootContainer = system({
  is: "section",
  py: 5,
  px: 4,
  maxWidth: "695px",
  display: "flex",
  flexWrap: "wrap",
  textAlign: "left"
});

export const StepContainer = system({
  is: "section",
  width: "213px",
  display: "flex",
  flexWrap: "wrap",
  textAlign: "left",
  alignContent: "flex-start",
  mx: 1
});

export const StepUsersContainer = system({
  is: "section",
  border: 1,
  borderRadius: 1,
  borderColor: "nav.80",
  py: 3,
  px: 4,
  mt: 1,
  width: "100%",
  bg: "nav.100"
});

export const StepSpan = system({
  is: Text,
  color: "nav.25",
  width: "100%",
  display: "block"
});

export const StepTitle = system({
  is: StepSpan,
  fontWeight: 500
});

export const StepCurrentMarker = system({
  is: Text,
  color: "nav.100",
  display: "inline-block",
  border: 0,
  bg: "nav.0",
  borderRadius: 2,
  mx: 2,
  p: 1,
  fontSize: "10px"
});

export const Divider = system({
  width: "100%",
  height: "1px",
  bg: "nav.80",
  my: 1
});
