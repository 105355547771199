/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Properties_author$ref = any;
type Properties_category$ref = any;
type Properties_tags$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TemplateProperties_template$ref: FragmentReference;
export type TemplateProperties_template = {|
  +headerImageUrl: ?string,
  +approvalDate: ?string,
  +revisionDate: ?string,
  +author: ?{|
    +$fragmentRefs: Properties_author$ref
  |},
  +category: ?{|
    +$fragmentRefs: Properties_category$ref
  |},
  +tags: ?$ReadOnlyArray<?{|
    +$fragmentRefs: Properties_tags$ref
  |}>,
  +$refType: TemplateProperties_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "formatted",
    "value": true,
    "type": "Boolean"
  }
];
return {
  "kind": "Fragment",
  "name": "TemplateProperties_template",
  "type": "SystemDocumentTemplateType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "headerImageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "approvalDate",
      "args": v0,
      "storageKey": "approvalDate(formatted:true)"
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "revisionDate",
      "args": v0,
      "storageKey": "revisionDate(formatted:true)"
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "author",
      "storageKey": null,
      "args": null,
      "concreteType": "UserType",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Properties_author",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "category",
      "storageKey": null,
      "args": null,
      "concreteType": "CategoryType",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Properties_category",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tags",
      "storageKey": null,
      "args": null,
      "concreteType": "TagType",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "Properties_tags",
          "args": null
        }
      ]
    }
  ]
};
})();

(node/*: any*/).hash = '583e45b86102501461495f652fd1df38';
module.exports = node;
