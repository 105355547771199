/**
 * @flow
 * @relayHash 87a80cd0dfa7a7fd7482d116fa54b4ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentRevisionStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "PREIMPORT" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type RevisionStatus = "NEW" | "REVISED" | "UNCHANGED" | "%future added value";
export type PolicyRevisionsQueryVariables = {|
  documentPk: number
|};
export type PolicyRevisionsQueryResponse = {|
  +documentRevisions: ?{|
    +versions: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: ?string,
          +categoryName: ?string,
          +status: ?DocumentRevisionStatus,
          +approvalDate: ?string,
          +effectiveDate: ?string,
          +revisedFromParentStatus: ?RevisionStatus,
        |}
      |}>,
    |},
    +preimportVersions: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: ?string,
          +categoryName: ?string,
          +status: ?DocumentRevisionStatus,
          +approvalDate: ?string,
          +effectiveDate: ?string,
          +revisedFromParentStatus: ?RevisionStatus,
        |}
      |}>,
    |},
    +editablePks: ?$ReadOnlyArray<?number>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type PolicyRevisionsQuery = {|
  variables: PolicyRevisionsQueryVariables,
  response: PolicyRevisionsQueryResponse,
|};
*/


/*
query PolicyRevisionsQuery(
  $documentPk: Int!
) {
  documentRevisions(documentPk: $documentPk) {
    versions {
      totalCount
      edges {
        node {
          pk
          name
          categoryName
          status
          approvalDate
          effectiveDate
          revisedFromParentStatus
        }
      }
    }
    preimportVersions {
      totalCount
      edges {
        node {
          pk
          name
          categoryName
          status
          approvalDate
          effectiveDate
          revisedFromParentStatus
        }
      }
    }
    editablePks
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "documentPk",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "DocumentRevisionCountableEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentRevisionType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pk",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "categoryName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "approvalDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "effectiveDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "revisedFromParentStatus",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "documentRevisions",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentPk",
        "variableName": "documentPk",
        "type": "Int!"
      }
    ],
    "concreteType": "DocumentRevisionsType",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "versions",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentRevisionCountableConnection",
        "plural": false,
        "selections": v1
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "preimportVersions",
        "storageKey": null,
        "args": null,
        "concreteType": "DocumentRevisionCountableConnection",
        "plural": false,
        "selections": v1
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "editablePks",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PolicyRevisionsQuery",
  "id": "c3e7ceb11e08b8f000e7d4883d1fc9d3",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PolicyRevisionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "PolicyRevisionsQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
node.text = "query PolicyRevisionsQuery(\n  $documentPk: Int!\n) {\n  documentRevisions(documentPk: $documentPk) {\n    versions {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          categoryName\n          status\n          approvalDate\n          effectiveDate\n          revisedFromParentStatus\n        }\n      }\n    }\n    preimportVersions {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          categoryName\n          status\n          approvalDate\n          effectiveDate\n          revisedFromParentStatus\n        }\n      }\n    }\n    editablePks\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'cbdbfc07c2ab2c662e7c607e4dbfc5bd';
module.exports = node;
