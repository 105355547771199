import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";

import { H2 } from "pstat-design-system/text";
import { Text } from "pstat-design-system/text/paragraph";

export default function Header({ showStepDescription }) {
  const { t } = useTranslation();
  return (
    <Flex flexWrap="wrap" justifyContent="space-between">
      <Box width="100%" mb={6}>
        <H2 fontWeight="400">
          {t("documentControl.view.approvalSignatures.header")}
        </H2>
      </Box>
      {showStepDescription && (
        <Box width="30%">
          <Text fontWeight="medium" fontSize={1} color="secondary.0">
            {t("documentControl.view.approvalSignatures.stepDescription")}
          </Text>
        </Box>
      )}
      <Box width="30%">
        <Text fontWeight="medium" fontSize={1} color="secondary.0">
          {t("documentControl.view.approvalSignatures.approver")}
        </Text>
      </Box>
      <Box width="30%">
        <Text fontWeight="medium" fontSize={1} color="secondary.0">
          {t("documentControl.view.approvalSignatures.date")}
        </Text>
      </Box>
    </Flex>
  );
}
