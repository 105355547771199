/**
 * @flow
 * @relayHash 245779fe4a2c18f1cd9cb12baba628dd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type pendingApproversNonRelayQueryVariables = {||};
export type pendingApproversNonRelayQueryResponse = {|
  +pendingApprovers: ?$ReadOnlyArray<?{|
    +userDisplay: ?string,
    +id: string,
  |}>
|};
export type pendingApproversNonRelayQuery = {|
  variables: pendingApproversNonRelayQueryVariables,
  response: pendingApproversNonRelayQueryResponse,
|};
*/


/*
query pendingApproversNonRelayQuery {
  pendingApprovers {
    userDisplay
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pendingApprovers",
    "storageKey": null,
    "args": null,
    "concreteType": "UserType",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userDisplay",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "pendingApproversNonRelayQuery",
  "id": "883cd5daf3141810871b2711609f85fb",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "pendingApproversNonRelayQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "pendingApproversNonRelayQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
node.text = "query pendingApproversNonRelayQuery {\n  pendingApprovers {\n    userDisplay\n    id\n  }\n}\n";
(node/*: any*/).hash = '0b43948ddd469cfbc11ecf15e3dc5216';
module.exports = node;
