import { Box } from "grid-styled";
import { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";
import * as Table from "reactabular-table";
import * as resolve from "table-resolver";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { NotificationsQuery } from "../NotificationsQuery";

import NotificationCell from "./components/NotificationCell";
import NotificationTR from "./components/NotificationTR";
import useNotificationsTable from "./hooks";

import { Text } from "pstat-design-system/text/paragraph";
import { formatDate } from "pstat-design-system/tables/utils/formatters";
import { FixedLayoutCardTable } from "pstat-design-system/tables/CardTable";
import Pagination from "pstat-design-system/Pagination";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { SmileIcon } from "pstat-design-system/Icons";
import { Link } from "pstat-design-system/Link";
import Card from "pstat-design-system/Card";
import theme from "pstat-anywhere/themes/policystat/theme";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";


const onRow = row => {
  return {
    bg: row.node.unseen ? "primary.80" : "nav.100"
  };
};

const StyledRssFeedContainer = styled.div`
  width:100%;
  text-align: right;
  padding-bottom: ${themeGet('space.2')}px;
`
const StyledBox = styled(Box)`
  position:relative;
  top: ${themeGet('space.10')}px;

   @media (max-width: ${themeGet("breakpoints.1")}) {
    top: ${themeGet('space.4')}px;
  }
`
function NotificationsTable({
  t,
  components,
  columns,
  environment,
  location,
  router
}) {
  const {
    hitsPerPage,
    totalNumberOfResults,
    setHitsPerPage,
    handlePageChange,
    handleTotalNumberOfResultsUpdate
  } = useNotificationsTable({ location, router });
  const queryVariables = {
    after: location?.query?.after || "",
    first: hitsPerPage
  };
  return (
    <StyledBox width={[1, 1, 5 / 6]} p={[0, 0, 3]}>
      <QueryRenderer
        environment={environment}
        query={NotificationsQuery}
        variables={queryVariables}
        render={({ props }) => {
          if (!props) {
            return <LoadingSpinner size={100} />;
          }

          const {
            notifications: { edges, totalCount, notificationRssFeedUrl }
          } = props;
          handleTotalNumberOfResultsUpdate(totalCount);
          const resolvedRows = resolve.resolve({
            columns,
            method: resolve.nested
          })(edges);
          return (
            <Fragment>
              {notificationRssFeedUrl && (
                <StyledRssFeedContainer>
                  <Link href={notificationRssFeedUrl}>
                    <StyledFontAwesomeIcon
                      icon={["fa", "rss"]}
                      color="secondary.0"
                      />
                    {t('documentControl.view.share.modal.rssFeed.title')}
                  </Link>
                </StyledRssFeedContainer>)
              }
              <Card>
                <Fragment>
                  {totalCount > 0 ? (
                    <Table.Provider
                      id="results_table"
                      components={components}
                      columns={columns}
                    >
                      <Table.Body rows={resolvedRows} rowKey="pk" onRow={onRow} />
                    </Table.Provider>
                  ) : (
                    <Fragment>
                      <SmileIcon width="100%" marginBottom={theme.space[6]} />
                      <Text
                        is="p"
                        width="100%"
                        color="nav.25"
                        fontSize={2}
                        textAlign="center"
                      >
                        {t("notifications.emptyResults.large")}
                      </Text>
                    </Fragment>
                  )}
                </Fragment>
              </Card>
            </Fragment>
          );
        }}
      />
      <Pagination
        totalNumberOfResults={totalNumberOfResults}
        hitsPerPage={queryVariables.first}
        afterCursor={queryVariables.after}
        onPageChange={handlePageChange}
        showItemCount={true}
        onHitsPerPageChange={setHitsPerPage}
      />
    </StyledBox>
  );
}

NotificationsTable.displayName = "NotificationsTable";

NotificationsTable.defaultProps = {
  components: {
    table: FixedLayoutCardTable,
    body: {
      wrapper: "tbody",
      row: NotificationTR,
      cell: NotificationCell
    }
  },
  columns: [
    {
      property: "node.added",
      cell: {
        props: {
          color: "nav.25",
          pt: 0,
          px: [3, 3, 6]
        },
        formatters: [formatDate]
      }
    },
    {
      property: "node.noticeType.label",
      cell: {
        props: {
          color: "nav.0",
          pb: 1,
          px: [3, 3, 6]
        }
      }
    },
    {
      property: "node.message",
      cell: {
        props: {
          pt: 0,
          pb: 1,
          color: "nav.0",
          px: [3, 3, 6]
        }
      }
    }
  ]
};

export default withTranslation()(NotificationsTable);
