import { Flex } from "grid-styled";
import { useTranslation } from "react-i18next";

import LoginForm from "pstat-anywhere/components/authentication/LoginForm";
import Modal from "pstat-design-system/modal";

const LoginModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      contentLabel={t("guest.loginModal.header")}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Flex justifyContent="center">
        <LoginForm
          hardRedirect={false}
          cleanBorders={true}
          onLoginHelpClicked={onClose}
          width={300}
        />
      </Flex>
    </Modal>
  );
};

export default LoginModal;
