import graphql from "babel-plugin-relay/macro";

export const ViewTemplateQuery = graphql`
  query ViewTemplateQuery($pk: Int!) {
    systemDocumentTemplateView(pk: $pk) {
      systemDocumentTemplate {
        ...ViewTemplatePage_template
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default ViewTemplateQuery;
