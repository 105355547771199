import { Box, Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import discardDraft from "pstat-anywhere/components/document_control/PolicyEditor/components/discardDraftMutation";
import { environment } from "pstat-anywhere/relay";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text/paragraph";

const DiscardDraftModal = ({ isOpen, onClose, documentSavePk, successCallback }) => {
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const isEnterKey = event => event.key === "Enter";
  const handleDiscardDraftKeyDown = event => {
    if (isEnterKey(event)) {
      handleDiscardDraft(event);
    }
  };
  const handleCancelKeyDown = event => {
    if (isEnterKey(event)) {
      onClose();
    }
  };
  const redirectingAfterDeletingDraft = ({ redirectUrl }) => {
    window.location.href = redirectUrl;
  };

  const handleDiscardDraft = event => {
    const variables = {
      documentSaveId: documentSavePk
    };

    const callback = successCallback || redirectingAfterDeletingDraft;
    discardDraft(environment, variables, (response, errors) => {
      if (errors) {
        throw new Error("error discarding draft");
      }
      if (response.discardDraft.ok) {
        onClose();
        setProcessing(false);
        callback(response.discardDraft);
      } else {
        throw new Error(response.discardDraft.reasons);
      }
    });
    setProcessing(true);
  };

  return (
    <Modal
      contentLabel={t("documentDraft.discardDraftModal.header")}
      isOpen={isOpen}
      onRequestClose={onClose}
      renderFooter={() => (
        <ModalFooter>
          <Flex width={1} px={5} py={3} justifyContent="space-between">
            <Box>
              <Button
                id="delete_draft_button"
                buttonStyle="danger"
                onClick={handleDiscardDraft}
                onKeyDown={handleDiscardDraftKeyDown}
                processing={processing}
              >
                {t("documentDraft.discardDraftModal.discardButton")}
              </Button>
            </Box>
            <Box>
              <Button
                buttonStyle="tertiary"
                onClick={onClose}
                onKeyDown={handleCancelKeyDown}
              >
                {t("buttons.cancel")}
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      )}
    >
      <Flex flexDirection="column" px={5} pt={3}>
        <Text>{t("documentDraft.discardDraftModal.text")}</Text>
      </Flex>
    </Modal>
  );
};

export default DiscardDraftModal;
