import graphql from "babel-plugin-relay/macro";

export const PolicyRevisionsQuery = graphql`
  query PolicyRevisionsQuery($documentPk: Int!) {
    documentRevisions(documentPk: $documentPk) {
      versions {
        totalCount
        edges {
          node {
            pk
            name
            categoryName
            status
            approvalDate
            effectiveDate
            revisedFromParentStatus
          }
        }
      }
      preimportVersions {
        totalCount
        edges {
          node {
            pk
            name
            categoryName
            status
            approvalDate
            effectiveDate
            revisedFromParentStatus
          }
        }
      }
      editablePks
      error {
        statusCode
        message
      }
    }
  }
`;

export default PolicyRevisionsQuery;
