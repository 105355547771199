import { Fragment, useState } from "react";
import system from "system-components";

import { BorderlessClearableTextInput } from "./TextInput";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import colors from "pstat-anywhere/themes/policystat/colors";
import theme from "pstat-anywhere/themes/policystat/theme";
import { DeleteIconBadge } from "pstat-design-system/Badge";


const BACKSPACE_KEY = "Backspace";

const MultiSelectContainer = system({
  display: "inline-flex",
  alignItems: "center",
  flexWrap: "wrap",
  borderRadius: 1,
  fontFamily: theme.fontFamily,
  fontSize: 2,
  fontWeight: "normal",
  bg: "nav.100",
  boxShadow: `inset 1px 1px 0 0 ${colors.nav["90"]}`,
  border: theme.borders[1],
  borderColor: "nav.80",
  color: "nav.0",
  width: "100%"
}).extend`
  box-sizing: border-box;

  outline: none;

  ${props =>
    props.hasFocus &&
    `
    border-color: ${colors.secondary["0"]};
  `}

  ${props =>
    props.disabled &&
    `
    background-color: ${colors.nav["95"]};
    cursor: not-allowed;
  `}

  ${props =>
    !props.isValid &&
    `
    border: solid 2px ${colors.messages["error"]};
  `}
`;

const MultiSelectInput = ({
  selectedItems,
  getItemValue,
  getItemPk,
  renderItemBadge,
  onItemDeselected,
  disabled,
  required,
  inputRef,
  isValid,
  inputIcon,
  value,
  onKeyDown,
  onFocus,
  onBlur,
  type,
  ...otherProps
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleBackspaceKeyDown = event => {
    const backspaceKeyPressed = event.key === BACKSPACE_KEY;
    const inputIsEmpty = value === "";
    if (backspaceKeyPressed && inputIsEmpty) {
      const lastItemIndex = selectedItems.length - 1;
      if (lastItemIndex >= 0) {
        const item = selectedItems[lastItemIndex];
        const pkToRemove = getItemPk(item);
        removeSelectedItem(pkToRemove, item.name);
      }
    }
  };
  const getItemNames = items => {
    return items.map(item => item.name);
  };
  const getItemPks = items => {
    return items.map(item => getItemPk(item));
  };
  const removeSelectedItem = (pk, name) => {
    const selectedItemsCopy = [...selectedItems];
    const searchValue = pk || name;
    const getValues = pk ? getItemPks : getItemNames;
    const values = getValues(selectedItemsCopy);
    const indexToRemove = values.indexOf(searchValue);
    if (indexToRemove > -1) {
      selectedItemsCopy.splice(indexToRemove, 1);
      onItemDeselected(selectedItemsCopy);
    }
  };
  return (
    <MultiSelectContainer
      hasFocus={hasFocus}
      disabled={disabled}
      isValid={isValid}
    >
      {inputIcon && (
        <StyledFontAwesomeIcon
          icon={inputIcon}
          color="nav.55"
          size="lg"
          ml={2}
        />
      )}
      {selectedItems.map(item => (
        <Fragment key={getItemPk(item)}>
          {renderItemBadge ? (
            <Fragment>
              {renderItemBadge(item, () =>
                removeSelectedItem(getItemPk(item), item.name)
              )}
            </Fragment>
          ) : (
            <DeleteIconBadge
              value={getItemValue(item)}
              onClick={() => removeSelectedItem(getItemPk(item), item.name)}
            />
          )}
        </Fragment>
      ))}
      <BorderlessClearableTextInput
        {...otherProps}
        type={type || "text"}
        value={value}
        ref={inputRef}
        onKeyDown={event => {
          if (onKeyDown) {
            onKeyDown(event);
          }
          handleBackspaceKeyDown(event);
        }}
        onFocus={event => {
          if (onFocus) {
            onFocus(event);
          }
          setHasFocus(true);
        }}
        onBlur={event => {
          if (onBlur) {
            onBlur(event);
          }
          setHasFocus(false);
        }}
        disabled={disabled}
        required={required}
      />
    </MultiSelectContainer>
  );
};

export default MultiSelectInput;
