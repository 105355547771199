import system from "system-components";

import BlurOverflow from "./BlurOverflow";

export const ReadMoreContainer = system({
  pb: [3, 3, 0]
});

export const ReadMoreButtonPositioner = system(
  {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%"
  },
  props => ({
    display: props.shouldShowResizer() ? "block" : "none"
  })
);

export { BlurOverflow };
