import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation CreatePolicyStartApprovalsMutation(
    $input: CreatePolicyStartApprovalsInput!
  ) {
    createPolicyStartApprovals(input: $input) {
      ok
      reasons
      redirectUrl
    }
  }
`;

export const createPolicyStartApprovals = (
  environment,
  startApprovalsVariables,
  callback
) => {
  const variables = {
    input: startApprovalsVariables
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
};
