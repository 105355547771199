import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

const mutation = graphql`
  mutation ApprovePendingDocumentMutation(
    $documentPk: Int!
    $comment: String!
  ) {
    approvePendingDocument(documentPk: $documentPk, comment: $comment) {
      ok
      reasons
      document {
        id
        pk
        status
        approvable
        effectiveDate(formatted: true)
        approvalDate(formatted: true)
        expirationDate(formatted: true)
        revisionDate(formatted: true)
        originationDate(formatted: true)
        legacyEffectiveDate(formatted: true)
        actions {
          id
          actionType
          actionDate
          comment
          isExternal
          user {
            id
            userDisplay(userDisplayType: FORWARD)
          }
        }
        approvalWorkflow {
          name
          steps {
            name
            approvers {
              firstName
              lastName
              isDocumentOwner
              hasApproved
            }
          }
        }
      }
    }
  }
`;

export const approvePendingDocument = (
  environment,
  documentPk,
  comment,
  callback
) => {
  const variables = {
    documentPk,
    comment
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => console.error(err)
  });
};
