import { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import { getRootUrl } from "../../utils/urls";

import { Divider, LoginFormContainer } from "./Common";
import { doLogin } from "./LoginMutation";

import { Button } from "pstat-design-system/shared/Button";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import { InputFormElement } from "pstat-design-system/inputs/FormElements";
import { ErrorBanner } from "pstat-design-system/banner";

// @flow

class SimpleLogin extends Component {
  constructor(props: Object) {
    super(props);

    this.state = {
      noChangesMade: true,
      errorFound: false,
      saving: false,
      formError: {
        username: "",
        password: ""
      },
      authForm: {
        username: "",
        password: ""
      },
      reasons: []
    };
  }

  handleChange = (event: Event) => {
    let authForm = Object.assign({}, this.state.authForm);
    const { name, value } = event.target;
    authForm[name] = value;
    this.setState({
      authForm,
      noChangesMade: false
    });
  };

  validateFields = () => {
    this.resetErrors();
    let isValid = true;
    const required = ["username", "password"];

    const form = this.state.authForm;
    const error = Object.assign({}, this.state.formError);
    Object.keys(form).forEach(field => {
      if (required.includes(field)) {
        // Checking for blank fields that are required
        if (!form[field]) {
          error[field] = true;
          isValid = false;
        }
      }
      // More validations
    });
    this.setState({
      formError: error,
      errorFound: !isValid
    });
    return isValid;
  };

  resetErrors = () => {
    const error = Object.assign({}, this.state.formError);
    Object.keys(error).forEach(field => {
      error[field] = false;
    });
    this.setState({
      formError: error,
      errorFound: false
    });
  };

  formSubmit = e => {
    e.preventDefault();
    this.setState({ saving: true, reasons: [] }, () => {
      if (this.validateFields()) {
        const form = this.state.authForm;
        const url = new URL(window.location.href);
        const next = url.searchParams.get("next");
        // log the user in
        doLogin(form.username, form.password, next, response => {
          const { ok, reasons, redirectUrl } = response.login;
          if (ok) {
            this.setState({
              saving: false,
              noChangesMade: true
            });
            const redirectTo = redirectUrl || "/";
            window.location.replace(redirectTo);
          } else if (reasons) {
            let allReasons = [];
            // Going trough all reasons to show them all
            Object.keys(reasons).forEach(reasonKey => {
              reasons[reasonKey].forEach(message => {
                allReasons.push(message);
              });
            });
            if (allReasons.length > 0) {
              this.setState({ reasons: allReasons });
            }
          }
        });
      } else {
        this.setState({
          saving: false,
          noChangesMade: false // when there are changes not saved
        });
      }
    });
    return false;
  };

  render() {
    const { t, cleanBorders, onLoginHelpClicked } = this.props;
    const { authForm, formError, reasons } = this.state;
    return (
      <Fragment>
        {reasons.map((reason, index) => (
          <ErrorBanner message={reason} key={index} />
        ))}
        <LoginFormContainer
          onSubmit={this.formSubmit}
          hideShadow={cleanBorders}
        >
          <InputFormElement
            required={true}
            name="username"
            value={authForm.username}
            label={t("authentication.loginForm.username")}
            onChange={this.handleChange}
            type="text"
            hasError={formError.username}
            id={"username"}
            dataTestid="login-username"
          />

          <InputFormElement
            required={true}
            name="password"
            value={authForm.password}
            label={t("authentication.loginForm.password")}
            onChange={this.handleChange}
            type="password"
            hasError={formError.password}
            dataTestid="login-password"
          />

          <Button
            focusBorderProps={{ width: "100%" }}
            width="100%"
            buttonStyle="secondary"
          >
            {t("buttons.login")}
          </Button>
          <Divider />
          <RouterFocusLink
            to={`${getRootUrl()}/authorization/login_help/`}
            onClick={onLoginHelpClicked}
          >
            {t("authentication.loginForm.cantLoginLink")}
          </RouterFocusLink>
        </LoginFormContainer>
      </Fragment>
    );
  }
}

export default withTranslation()(SimpleLogin);
