import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation ForgotPasswordResetMutation(
    $uidb64: String!
    $token: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    resetPassword(
      uidb64: $uidb64
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      ok
      reasons
    }
  }
`;

export const doResetPassword = (
  uidb64,
  token,
  newPassword1,
  newPassword2,
  callback
) => {
  const variables = {
    uidb64: uidb64,
    token: token,
    newPassword1: newPassword1,
    newPassword2: newPassword2
  };

  // TODO decide how to handle errors
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response);
    },
    onError: err => console.error(err)
  });
};
