import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { getDocumentViewRecord, getStoredTokens } from "./utils";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation RenameTokenMutation($input: RenameDocumentAccessTokenInput!) {
    renameDocumentAccessToken(input: $input) {
      ok
      token {
        tokenId
        description
        dateLastAccessed
        url
      }
    }
  }
`;

export function renameToken(
  documentPk,
  tokenId,
  description,
  location,
  callback
) {
  const variables = {
    input: {
      documentPk,
      tokenId,
      description
    }
  };

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response, errors),
    onError: error => {
      throw new Error(error);
    },
    updater: store => {
      const payload = store.getRootField("renameDocumentAccessToken");
      const renamedToken = payload.getLinkedRecord("token");

      const documentViewRecord = getDocumentViewRecord(
        store,
        documentPk,
        location
      );
      if (!documentViewRecord) {
        return;
      }
      const storedTokens = getStoredTokens(documentViewRecord);

      const tokens = storedTokens.map(token => {
        if (token.getValue("tokenId") === renamedToken.getValue("tokenId")) {
          return renamedToken;
        }
        return token;
      });
      documentViewRecord.setLinkedRecords(tokens, "documentAccessTokens");
    }
  });
}
