import { Box } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import innerHtmlStyles from "../../../view/content/innerhtmlstyles";
import CommentInput from "../comment_form/CommentInput";
import useCommentForm from "../comment_form/hooks";

import CommentDisplayBox from "./CommentDisplayBox";
import Headline from "./Headline";

import { Text } from "pstat-design-system/text";
import { Button } from "pstat-design-system/shared/Button";
import { htmlStringToDocument } from "pstat-anywhere/utils/domHelpers";
import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";

const CommentRenderer = system({
  is: Text,
  color: "nav.0",
  fontWeight: "normal"
}).extend`
  ${innerHtmlStyles};
  p:last-child {
    margin-bottom: 0;
  }
`;

const EditIconsWrapper = system({
  is: Box,
  position: "absolute"
}).extend`
  top: 30%;
  right: 1%;
  cursor: pointer;
`;

const HistoryItem = ({
  action,
  isApproval,
  editCommentOpened,
  onDeleteComment,
  onOpenEditComment,
  onEditCommentSubmit,
  workflowTitle,
  approvalSteps,
  currentStepId
}) => {
  const initialValue = htmlStringToDocument(action.comment);
  const { t } = useTranslation();
  const {
    commentProcessing,
    value,
    handleTextChange,
    handleMentionAdd,
    getData,
    isMentionSelected
  } = useCommentForm({
    document,
    initialValue: initialValue?.textContent
  });
  const approvalParams = { workflowTitle, approvalSteps, currentStepId };
  function handleEditCommentSubmit() {
    onEditCommentSubmit({ action, newComment: value });
  }
  function handleDeleteClick() {
    onDeleteComment(action);
  }
  function handleDeleteKeyDown(event) {
    if (event.key === "Enter" || event.key === " ") {
      onDeleteComment(action);
    }
  }
  function handleEditCommentOpenClick() {
    onOpenEditComment(action);
  }
  function handleEditCommentOpenKeyDown(event) {
    if (event.key === "Enter" || event.key === " ") {
      onOpenEditComment(action);
    }
  }
  return (
    <Box
      pt={3}
      data-testid={`history-item-${action.actionType}-${action.id}`}
      className="history-item-container"
    >
      <Headline {...action} isApproval={isApproval} {...approvalParams} />
      {action.comment && (
        <CommentDisplayBox>
          <CommentRenderer
            dangerouslySetInnerHTML={{ __html: action.comment }}
          />
          <Fragment>
            {action.canEdit && (
              <EditIconsWrapper>
                <StyledFontAwesomeIcon
                  icon={["fal", "pencil"]}
                  data-testid={`edit-action-${action.id}`}
                  onClick={handleEditCommentOpenClick}
                  onKeyDown={handleEditCommentOpenKeyDown}
                />
                <StyledFontAwesomeIcon
                  icon={["fal", "trash-alt"]}
                  onClick={handleDeleteClick}
                  onKeyDown={handleDeleteKeyDown}
                />
              </EditIconsWrapper>
            )}
          </Fragment>
        </CommentDisplayBox>
      )}
      {action.comment && action.canEdit && editCommentOpened && (
        <Box>
          <CommentInput
            value={value}
            handleTextChange={handleTextChange}
            handleMentionAdd={handleMentionAdd}
            getData={getData}
            isMentionSelected={isMentionSelected}
          />
          <Button
            buttonStyle="secondary"
            onClick={handleEditCommentSubmit}
            disabled={value === "" || value === action.comment}
            processing={commentProcessing}
          >
            {t("buttons.comment")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HistoryItem;
