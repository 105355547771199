/**
 * @flow
 * @relayHash 09d6ea2846307d7d932a5641494b3fb9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ScheduledDocumentsCSVQueryVariables = {|
  showAll?: ?boolean,
  search_query?: ?string,
|};
export type ScheduledDocumentsCSVQueryResponse = {|
  +scheduledDocumentsCsv: ?{|
    +taskId: ?string
  |}
|};
export type ScheduledDocumentsCSVQuery = {|
  variables: ScheduledDocumentsCSVQueryVariables,
  response: ScheduledDocumentsCSVQueryResponse,
|};
*/


/*
query ScheduledDocumentsCSVQuery(
  $showAll: Boolean
  $search_query: String
) {
  scheduledDocumentsCsv(showAll: $showAll, q: $search_query) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search_query",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduledDocumentsCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "search_query",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "showAll",
        "variableName": "showAll",
        "type": "Boolean"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ScheduledDocumentsCSVQuery",
  "id": "aa0d4f0045ddb07d10cd83170f5b0655",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ScheduledDocumentsCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ScheduledDocumentsCSVQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query ScheduledDocumentsCSVQuery(\n  $showAll: Boolean\n  $search_query: String\n) {\n  scheduledDocumentsCsv(showAll: $showAll, q: $search_query) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = '595554c93ada915a9fe37f64e2043b3e';
module.exports = node;
