import { Box, Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionButtonContainer } from "./components";
import useAdvancedReportPage from "./hooks/useAdvancedReportPage";
import useQueryVariables from "./hooks/useQueryVariables";

import {
  ReportHeader,
  ReportPage
} from "pstat-anywhere/components/pages/reports";
import Export from "pstat-anywhere/components/partials/export/Export";
import { HeaderLink } from "pstat-anywhere/components/partials/HeaderLinks";
import { BucketContainer } from "pstat-anywhere/components/reports/Buckets";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Card from "pstat-design-system/Card";
import { InputFormElement } from "pstat-design-system/inputs/FormElements";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { Button } from "pstat-design-system/shared/Button";
import {
  HorizontalScrollTable,
  SortablePolicyTable
} from "pstat-design-system/tables";
import { StyledTabBadge, Tab, TabContainer } from "pstat-design-system/Tabs";
import { Text } from "pstat-design-system/text/paragraph";


export const DefaultActionPanel = ({
  selectedItemPks,
  onActionButtonClick,
  actionTextKey,
  loading
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  function handleCommentChange(event) {
    setComment(event.target.value);
  }
  function handleActionButtonClick(event) {
    onActionButtonClick(selectedItemPks, comment);
  }
  return (
    <Fragment>
      <InputFormElement
        value={comment}
        placeholder={t("documentControl.view.commentForm.commentPanel.header")}
        name="report-action-comment"
        onChange={handleCommentChange}
        ContainerProps={{ mb: 0, display: "flex" }}
      />
      <Button
        buttonStyle="primary"
        onClick={handleActionButtonClick}
        ml={2}
        processing={loading}
      >
        {t(actionTextKey)}
      </Button>
    </Fragment>
  );
};

function ReportContent({
  items,
  sort,
  onSortChange,
  labels: { documentLabelPlural, documentLabel },
  totalCount,
  currentPageResultsCount,
  currentRouteName,
  onTabChange,
  tableType,
  onActionClick,
  onSelectAllCheckboxClick,
  selectAllCheckboxState,
  selectedItemPks,
  showActionButtonPanel,
  singularSelectedActionsLabel,
  pluralSelectedActionsLabel,
  showTabsPanel,
  tabs,
  noContentMessage,
  noSort,
  hitsPerPage,
  TableComponent = SortablePolicyTable,
  renderActionPanel,
  renderFilterPanel,
  rowKey,
  alwaysShowActionPanel,
  actionButtonPanelNoneSelectedMessage,
  customOnRow,
  otherTableProps
}) {
  const { t } = useTranslation();
  let selectedActionsLabel = null;
  if (selectedItemPks.length > 1) {
    selectedActionsLabel = pluralSelectedActionsLabel
      ? pluralSelectedActionsLabel
      : documentLabelPlural.toLowerCase();
  } else {
    selectedActionsLabel = singularSelectedActionsLabel
      ? singularSelectedActionsLabel
      : documentLabel.toLowerCase();
  }
  return (
    <Fragment>
      {showTabsPanel && !showActionButtonPanel && (
        <TabContainer
          selectedTabId={currentRouteName}
          onSelectedTabChange={onTabChange}
          totalCount={totalCount}
          currentPageResultsCount={currentPageResultsCount}
        >
          {tabs.map(({ id, textKey, count }) => (
            <Tab id={id} key={id}>
              <Text color="nav.25">{t(textKey)}</Text>
              {count !== undefined && <StyledTabBadge>{count}</StyledTabBadge>}
            </Tab>
          ))}
        </TabContainer>
      )}
      {(alwaysShowActionPanel || (showActionButtonPanel && !showTabsPanel)) && (
        <ActionButtonContainer>
          {selectedItemPks.length > 0 ? (
            <Flex alignItems="center" height="100%">
              <Text color="nav.100">
                {t(
                  "documentReports.advancedReportPage.action.selectedItemsCount",
                  {
                    count: selectedItemPks.length,
                    label: selectedActionsLabel
                  }
                )}
              </Text>
            </Flex>
          ) : (
            <Flex alignItems="center" height="100%">
              {actionButtonPanelNoneSelectedMessage && (
                <Text color="nav.100">
                  {actionButtonPanelNoneSelectedMessage}
                </Text>
              )}
            </Flex>
          )}
          <Flex alignItems="center" height="100%" justify="flex-end" flex="1">
            {renderActionPanel()}
          </Flex>
        </ActionButtonContainer>
      )}
      <Card>
        {renderFilterPanel()}
        {items ? (
          <Fragment>
            {items.length ? (
              <Fragment>
                <TableComponent
                  tableType={tableType}
                  policies={items}
                  numberToDisplay={hitsPerPage}
                  borderRadius="0"
                  sort={sort}
                  onSortChange={onSortChange}
                  onActionClick={onActionClick}
                  selectedCheckboxPks={selectedItemPks}
                  onSelectAllCheckboxClick={onSelectAllCheckboxClick}
                  selectAllCheckboxState={selectAllCheckboxState}
                  noSort={noSort}
                  rowKey={rowKey}
                  customOnRow={customOnRow}
                  {...otherTableProps}
                />
              </Fragment>
            ) : (
              <Text>{noContentMessage}</Text>
            )}
          </Fragment>
        ) : (
          <Flex justify="center" align="center" mt={10}>
            <Box>
              <LoadingSpinner size={100} />
            </Box>
          </Flex>
        )}
      </Card>
    </Fragment>
  );
}

function AdvancedReportPage({
  headerText,
  csvQueryArgs,
  route,
  router,
  location,
  labels,
  CSVQuery,
  csvQueryName,
  tabs,
  data,
  showActionButtonPanel,
  showTabsPanel,
  tableType,
  noContentMessage,
  noSort,
  onSortChange,
  frozenColumnTable = false,
  alwaysShowActionPanel = false,
  actionButtonPanelNoneSelectedMessage = "",
  renderActionPanel,
  singularSelectedActionsLabel,
  pluralSelectedActionsLabel,
  renderFilterPanel = () => null,
  rowKey,
  showPagination = true,
  showHitsPerPageSelector = false,
  customOnRow,
  renderHeader,
  BreadcrumbComponent,
  otherTableProps,
  initiallySelectedPks = [],
  BucketsComponent,
  isCheckboxDisabled,
  headerTooltip,
  rssFeedUrl,
  onRowSelection,
  PrefixHeaderComponent
}) {
  const items = showPagination ? data?.edges?.map(edge => edge.node) : data;
  const { variables, hitsPerPage } = useQueryVariables({
    location
  });
  const {
    currentRouteName,
    selectedItemPks,
    updatedItems,
    selectAllCheckboxState,
    handleTabChange,
    handleSortChange,
    handleCheckboxClick,
    handleClearSelection,
    handleSelectAllCheckboxClick
  } = useAdvancedReportPage({
    route,
    router,
    location,
    tabs,
    items,
    initiallySelectedPks,
    isCheckboxDisabled,
    onRowSelection
  });
  const { t } = useTranslation();
  const hasSelectedItems = selectedItemPks.length > 0;
  return (
    <ReportPage
      hitsPerPage={hitsPerPage}
      totalNumberOfResults={data?.totalCount}
      showPagination={showPagination}
      showItemCount={showHitsPerPageSelector}
      showHitsPerPage={showHitsPerPageSelector}
    >
      <ReportHeader
        title={headerText}
        BreadcrumbComponent={BreadcrumbComponent}
        tooltip={headerTooltip}
        PrefixHeaderComponent={PrefixHeaderComponent}
      >
        {rssFeedUrl && (
          <HeaderLink
            href={rssFeedUrl}
            linkIcon={["fa", "rss"]}
            linkLabel={t("documentControl.view.share.modal.rssFeed.title")}
          />
        )}
        {CSVQuery && (
          <Export
            csvQuery={CSVQuery}
            csvQueryArgs={csvQueryArgs}
            queryName={csvQueryName}
          />
        )}
        {renderHeader && <Fragment>{renderHeader()}</Fragment>}
      </ReportHeader>
      {BucketsComponent && (
        <BucketContainer id="buckets-container">
          <BucketsComponent />
        </BucketContainer>
      )}
      <ReportContent
        items={updatedItems}
        labels={labels}
        sort={variables.sort}
        onSortChange={onSortChange ? onSortChange : handleSortChange}
        onTabChange={handleTabChange}
        currentRouteName={currentRouteName}
        totalCount={showPagination ? data?.totalCount : items?.length}
        currentPageResultsCount={
          showPagination ? data?.edges?.length : items?.length
        }
        onActionClick={handleCheckboxClick}
        onSelectAllCheckboxClick={handleSelectAllCheckboxClick}
        selectAllCheckboxState={selectAllCheckboxState}
        tabs={tabs}
        showActionButtonPanel={showActionButtonPanel && hasSelectedItems}
        showTabsPanel={showTabsPanel && !hasSelectedItems}
        tableType={tableType}
        noContentMessage={noContentMessage}
        noSort={noSort}
        selectedItemPks={selectedItemPks}
        hitsPerPage={hitsPerPage}
        TableComponent={
          frozenColumnTable ? HorizontalScrollTable : SortablePolicyTable
        }
        alwaysShowActionPanel={alwaysShowActionPanel}
        actionButtonPanelNoneSelectedMessage={
          actionButtonPanelNoneSelectedMessage
        }
        singularSelectedActionsLabel={singularSelectedActionsLabel}
        pluralSelectedActionsLabel={pluralSelectedActionsLabel}
        renderActionPanel={() =>
          renderActionPanel(selectedItemPks, {
            handleClearSelection
          })
        }
        renderFilterPanel={renderFilterPanel}
        renderHeader={renderHeader}
        rowKey={rowKey}
        showPagination={showPagination}
        customOnRow={customOnRow}
        otherTableProps={otherTableProps}
      />
    </ReportPage>
  );
}

export default withLabelContext(AdvancedReportPage);
