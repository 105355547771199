/**
 * @flow
 * @relayHash 6aed0224075a7a36c35c3520b62b2213
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DraftsPageCSVQueryVariables = {|
  showAll?: ?boolean,
  searchQuery?: ?string,
|};
export type DraftsPageCSVQueryResponse = {|
  +draftsCsv: ?{|
    +taskId: ?string
  |}
|};
export type DraftsPageCSVQuery = {|
  variables: DraftsPageCSVQueryVariables,
  response: DraftsPageCSVQueryResponse,
|};
*/


/*
query DraftsPageCSVQuery(
  $showAll: Boolean
  $searchQuery: String
) {
  draftsCsv(showAll: $showAll, searchQuery: $searchQuery) {
    taskId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "showAll",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "draftsCsv",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "searchQuery",
        "variableName": "searchQuery",
        "type": "String"
      },
      {
        "kind": "Variable",
        "name": "showAll",
        "variableName": "showAll",
        "type": "Boolean"
      }
    ],
    "concreteType": "CSVTaskResult",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "taskId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DraftsPageCSVQuery",
  "id": "b9386800d745a91e74c695536fc4ca36",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DraftsPageCSVQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DraftsPageCSVQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query DraftsPageCSVQuery(\n  $showAll: Boolean\n  $searchQuery: String\n) {\n  draftsCsv(showAll: $showAll, searchQuery: $searchQuery) {\n    taskId\n  }\n}\n";
(node/*: any*/).hash = 'c8110b37f1816c7fddeac2aeba69c46f';
module.exports = node;
