import system from "system-components";

import { fontFamily, lineHeight } from "pstat-design-system/utils";

export const H1 = system(
  {
    is: "h1",
    fontSize: [6, 6, 8],
    fontWeight: "light"
  },
  "color",
  "display",
  "space"
).extend`
  ${lineHeight};
  ${fontFamily};
`;

export const H2 = system(
  {
    is: "h2",
    fontSize: [4, 4, 5],
    fontWeight: "light"
  },
  "color",
  "display",
  "space"
).extend`
  ${lineHeight};
  ${fontFamily};
`;

export const H3 = system(
  {
    is: "h3",
    fontSize: [4],
    fontWeight: "light"
  },
  "color",
  "display",
  "space",
  "width",
  "height"
).extend`
  ${lineHeight};
  ${fontFamily};
`;

export const H4 = system(
  {
    is: "h4",
    fontSize: [3],
    fontWeight: "light"
  },
  "color",
  "display",
  "space"
).extend`
  ${lineHeight};
  ${fontFamily};
`;

export const H5 = system(
  {
    is: "h5",
    fontSize: [2],
    fontWeight: "light"
  },
  "color",
  "display",
  "space"
).extend`
  ${lineHeight};
  ${fontFamily};
`;
