/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Content_template$ref: FragmentReference;
export type Content_template = {|
  +name: ?string,
  +html: ?string,
  +$refType: Content_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "Content_template",
  "type": "SystemDocumentTemplateType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "html",
      "args": null,
      "storageKey": null
    }
  ]
};

(node/*: any*/).hash = '9ea2b1ef508c4188573c9ff66999b430';
module.exports = node;
