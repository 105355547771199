import { createContext, useContext, useState } from "react";

const OnesourceDocumentsContext = createContext({});

export const OnesourceDocumentsProvider = ({
  children,
  defaultOnesourceDocuments
}) => {
  const [onesourceDocuments, setOnesourceDocuments] = useState(
    defaultOnesourceDocuments
  );
  const value = { onesourceDocuments, setOnesourceDocuments };
  return (
    <OnesourceDocumentsContext.Provider value={value}>
      {children}
    </OnesourceDocumentsContext.Provider>
  );
};

export const useOnesourceDocuments = () => {
  const context = useContext(OnesourceDocumentsContext);
  if (context === undefined) {
    throw new Error(
      "useOnesourceDocuments must be used within a OnesourceDocumentsProvider"
    );
  }
  return context;
};
