import { withRouter } from "found";

import { PageContainer, PaginationContainer } from "./components";

import Pagination from "pstat-design-system/Pagination";


function _ReportPage({
  match,
  router,
  children,
  totalNumberOfResults,
  hitsPerPage,
  showItemCount,
  onHitsPerPageChange,
  showPagination = true,
  showHitsPerPage = false
}) {
  const { after } = match.location.query;
  const handlePageChange = afterCursor => {
    const queryParams = { ...match.location.query };
    delete queryParams.after;
    if (afterCursor) {
      queryParams.after = afterCursor;
    }
    updateURLParams(queryParams);
  };
  const handleHitsPerPageChange = hitsPerPage => {
    if (onHitsPerPageChange) {
      onHitsPerPageChange(hitsPerPage);
      return;
    }
    const queryParams = { ...match.location.query };
    delete queryParams.after;
    queryParams.rowsPerPage = hitsPerPage;
    updateURLParams(queryParams);
  };
  const updateURLParams = queryParams => {
    const to = {
      pathname: match.location.pathname,
      query: queryParams
    };
    router.push(to);
  };

  return (
    <PageContainer>
      {children}
      <PaginationContainer>
        {showPagination && (
          <Pagination
            totalNumberOfResults={totalNumberOfResults}
            hitsPerPage={hitsPerPage}
            afterCursor={after}
            onPageChange={handlePageChange}
            showItemCount={showItemCount}
            onHitsPerPageChange={showHitsPerPage && handleHitsPerPageChange}
          />
        )}
      </PaginationContainer>
    </PageContainer>
  );
}

export default withRouter(_ReportPage);
