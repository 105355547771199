import graphql from "babel-plugin-relay/macro";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import publishSystemDocumentTemplates from "./PublishTemplatesMutation";

import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";
import {
  ActiveFilterBox,
  FilterDropdown,
  SearchBar
} from "pstat-anywhere/components/pages/reports/components";
import { useEnvironment } from "pstat-anywhere/context_providers/EnvironmentContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { WarningBanner } from "pstat-design-system/banner";
import { BreadcrumbButton } from "pstat-design-system/Breadcrumb";
import AutocompleteTextInput from "pstat-design-system/inputs/AutocompleteTextInput";
import { LabelField } from "pstat-design-system/inputs/Labels";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import { Button } from "pstat-design-system/shared/Button";


const SitesTable = ({
  route,
  router,
  location,
  templatesSelectedToPublish,
  handleBackToTemplates,
  onSuccessfulPublishTemplates
}) => {
  const { t } = useTranslation();
  const environment = useEnvironment();
  const [sort, setSort] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBlocs, setSelectedBlocs] = useState([]);
  const [waitingForPublish, setWaitingForPublish] = useState(false);
  const [rowsSelected, setRowsSelected] = useState(false);
  const renderActionPanel = (selectedSites, identityBlocs) => {
    const templateCount = templatesSelectedToPublish.length;
    const templateLabelKey =
      templateCount > 1
        ? "systemDocumentTemplates.templates"
        : "systemDocumentTemplates.template";
    const templatesLabel = titleCase(t(templateLabelKey));
    return (
      <Button
        buttonStyle="primary"
        disabled={selectedSites.length === 0}
        onClick={() =>
          publishTemplates(
            templatesSelectedToPublish,
            selectedSites,
            identityBlocs
          )
        }
        processing={waitingForPublish}
      >
        {t("systemDocumentTemplates.manage.sites.publishToSitesButton", {
          templatesCount: templatesSelectedToPublish.length,
          templatesLabel
        })}
      </Button>
    );
  };
  const tabs = [
    {
      id: "system_templates_manage",
      textKey: "systemDocumentTemplates.tab",
      path: ""
    }
  ];
  const handleSortChange = sort => setSort(sort);
  const filterTenants = tenants => {
    if (!tenants) {
      return tenants;
    }
    const tenantsForBlocs = selectedBlocs.reduce((tenantPks, bloc) => {
      const pks = bloc.tenants.map(tenant => tenant.pk);
      return [...tenantPks, ...pks];
    }, []);
    return tenants.filter(tenant => {
      return (
        includedByBlocFilter(tenant, tenantsForBlocs) &&
        includedBySearch(tenant, searchQuery)
      );
    });
  };
  const includedBySearch = tenant => {
    if (!searchQuery) {
      return true;
    }
    const fieldsToSearch = ["name", "subdomain"];
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    for (let field of fieldsToSearch) {
      const fieldText = tenant[field].toLowerCase();
      const containsSearch = fieldText.indexOf(lowerCaseSearchQuery) > -1;
      if (containsSearch) {
        return true;
      }
    }
    return false;
  };
  const includedByBlocFilter = (tenant, tenantsForBlocs) => {
    if (selectedBlocs.length === 0) {
      return true;
    }
    return tenantsForBlocs.indexOf(tenant.pk) > -1;
  };
  const handleRowSelection = pks => {
    setRowsSelected(pks.length > 0);
  };
  const publishTemplates = (
    selectedTemplates,
    selectedSites,
    identityBlocs
  ) => {
    setWaitingForPublish(true);
    const selectedIdentityBlocs = identityBlocs.filter(bloc => {
      const blocSitePk = bloc.tenants[0].pk;
      return selectedSites.indexOf(blocSitePk) > -1;
    });
    const selectedIdentityBlocPks = selectedIdentityBlocs.map(bloc => bloc.pk);
    publishSystemDocumentTemplates(
      selectedTemplates,
      selectedIdentityBlocPks,
      (data, errors) => {
        setWaitingForPublish(false);
        const result = data?.publishSystemDocumentTemplates;
        if (errors || !result?.ok || result?.errors) {
          createErrorMessage(
            t("systemDocumentTemplates.manage.sites.publishError")
          );
          return;
        }
        const successfulTemplates = result.result.publish_success;
        let templatesCount = 0;
        let publishedSitePks = [];
        const filterOutAlreadySeenSites = pk =>
          publishedSitePks.indexOf(pk) === -1;
        for (let templatePk in successfulTemplates) {
          templatesCount += 1;
          const sitePks = successfulTemplates[templatePk];
          const newSitePks = sitePks.filter(filterOutAlreadySeenSites);
          publishedSitePks = [...publishedSitePks, ...newSitePks];
        }
        const publishedSitesCount = publishedSitePks.length;
        onSuccessfulPublishTemplates(templatesCount, publishedSitesCount);
      }
    );
  };
  return (
    <QueryRenderer
      query={SiteSelectionQuery}
      variables={{ sort }}
      environment={environment}
      render={({ props, error }) => {
        if (error) {
          throw Error();
        }
        const identityBlocs = props?.blocsIdentity?.blocs;
        const filterBlocs = props?.blocsAll?.blocs;
        const tenants = identityBlocs?.map(bloc => bloc.tenants[0]);
        const filteredTenants = filterTenants(tenants);
        return (
          <Fragment>
            {rowsSelected && (searchQuery || selectedBlocs.length > 0) && (
              <WarningBanner
                message={t(
                  "systemDocumentTemplates.manage.sites.hiddenSitesWarning"
                )}
                width={null}
                mx={4}
                mt={6}
              />
            )}
            <AdvancedReportPage
              data={filteredTenants}
              onSortChange={handleSortChange}
              route={route}
              router={router}
              location={location}
              tabs={tabs}
              headerText={t("systemDocumentTemplates.manage.sites.header")}
              noContentMessage={t(
                "systemDocumentTemplates.manage.sites.emptyMessage"
              )}
              showTabsPanel={true}
              tableType="manageTemplatesTenants"
              showPagination={false}
              customOnRow={() => {}}
              frozenColumnTable={true}
              showActionButtonPanel={true}
              singularSelectedActionsLabel={t(
                "systemDocumentTemplates.manage.sites.singularSitesLabel"
              )}
              pluralSelectedActionsLabel={t(
                "systemDocumentTemplates.manage.sites.pluralSitesLabel"
              )}
              renderActionPanel={selectedSites =>
                renderActionPanel(selectedSites, identityBlocs)
              }
              alwaysShowActionPanel={false}
              BreadcrumbComponent={() => (
                <TemplatesBreadcrumb onClick={handleBackToTemplates} />
              )}
              renderFilterPanel={() => (
                <SearchBarFilter
                  onSearchQueryChange={setSearchQuery}
                  blocs={filterBlocs}
                  selectedBlocs={selectedBlocs}
                  onBlocsSelected={setSelectedBlocs}
                />
              )}
              onRowSelection={handleRowSelection}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default SitesTable;

export const SiteSelectionQuery = graphql`
  query SiteSelectionQuery($sort: String) {
    blocsIdentity(sort: $sort) {
      blocs {
        pk
        tenants {
          pk
          name
          subdomain
          created
        }
      }
      error {
        statusCode
        message
      }
    }
    blocsAll {
      blocs {
        pk
        name
        tenants {
          pk
        }
      }
      error {
        statusCode
        message
      }
    }
    currentTenant {
      identityBloc {
        pk
        name
      }
    }
  }
`;

const TemplatesBreadcrumb = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <BreadcrumbButton
      text={t("systemDocumentTemplates.header")}
      onClick={onClick}
      mt={4}
    />
  );
};

const SearchBarFilter = ({
  onSearchQueryChange,
  blocs,
  selectedBlocs,
  onBlocsSelected
}) => {
  const { t } = useTranslation();
  const [blocExpanded, setBlocExpanded] = useState(false);

  const deselectBloc = blocToDeselect => {
    const newSelectedBlocs = selectedBlocs.filter(
      bloc => blocToDeselect.pk !== bloc.pk
    );
    onBlocsSelected(newSelectedBlocs);
  };

  return (
    <Fragment>
      <SearchBar
        placeholder={t(
          "systemDocumentTemplates.manage.sites.searchPlaceholder"
        )}
        onChange={onSearchQueryChange}
        changesTimeoutTime={100}
      >
        <FilterDropdown
          expanded={blocExpanded}
          onClose={() => setBlocExpanded(false)}
          onToggle={() => setBlocExpanded(!blocExpanded)}
          filterName={t(
            "systemDocumentTemplates.manage.sites.blocsFilterLabel"
          )}
          expandedFilterName={t(
            "systemDocumentTemplates.manage.sites.blocsFilterDropdownLabel"
          )}
        >
          {blocs ? (
            <BlocsInput
              blocs={blocs}
              selectedBlocs={selectedBlocs}
              onBlocSelected={onBlocsSelected}
            />
          ) : (
            <LoadingSpinner />
          )}
        </FilterDropdown>
      </SearchBar>
      {selectedBlocs.map(bloc => {
        return (
          <ActiveFilterBox key={bloc.pk} onClick={() => deselectBloc(bloc)}>
            {bloc.name}
          </ActiveFilterBox>
        );
      })}
    </Fragment>
  );
};

const BlocsInput = ({ blocs, selectedBlocs, onBlocSelected }) => {
  const { t } = useTranslation();
  const getSuggestionValue = bloc => bloc.name;
  return (
    <Fragment>
      <LabelField
        name={t("systemDocumentTemplates.manage.sites.blocsFilterLabel")}
        htmlFor="blocs_filter_input"
      />
      <AutocompleteTextInput
        id="blocs_filter_input"
        selectedSuggestions={selectedBlocs}
        onSuggestionSelected={onBlocSelected}
        onSuggestionDeselected={onBlocSelected}
        suggestions={blocs}
        getSuggestionPk={bloc => bloc.pk}
        getSuggestionValue={getSuggestionValue}
        selectMultiple={true}
      />
    </Fragment>
  );
};
