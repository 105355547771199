import graphql from "babel-plugin-relay/macro";

export const SearchResultsCSVQuery = graphql`
  query SearchResultsCSVQuery(
    $q: String
    $titleOnly: Boolean
    $authors: [Int]
    $categories: [Int]
    $references: [Int]
    $sort: String
  ) {
    searchCsv(
      q: $q
      titleOnly: $titleOnly
      authors: $authors
      categories: $categories
      references: $references
      sort: $sort
    ) {
      taskId
    }
  }
`;

export default SearchResultsCSVQuery;
