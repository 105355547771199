import graphql from "babel-plugin-relay/macro";

const MyAcknowledgmentHistoryQuery = graphql`
  query MyAcknowledgmentHistoryQuery(
    $after: String
    $first: Int
    $sort: String
    $filter: String
    $q: String
  ) {
    myDocumentsToAcknowledge(
      showAll: true
      after: $after
      first: $first
      sort: $sort
      filter: $filter
      q: $q
    ) {
      totalCount
      acknowledgedCount
      incompleteCount
      edges {
        node {
          waitTime
          dateAcknowledged
          totalAcknowledged
          totalAssigned
          line {
            pk
            document {
              pk
              name
              hasAttachments
              restricted
              bloc {
                name
              }
              applicabilityType
            }
          }
        }
      }
    }
  }
`;

export default MyAcknowledgmentHistoryQuery;
