import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation DeleteGroupAcknowledgmentMutation($recurringAckPk: Int!) {
    deleteGroupCertification(recurringAckPk: $recurringAckPk) {
      ok
    }
  }
`;

const doDeleteGroupRecurringAcknowledgment = (recurringAckPk, callback) => {
  const variables = { recurringAckPk };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) =>
      callback(response, errors, recurringAckPk),
    onError: error => {
      throw new Error(error);
    }
  });
};

export default doDeleteGroupRecurringAcknowledgment;
