import graphql from "babel-plugin-relay/macro";

const ScheduledDocumentsCSVQuery = graphql`
  query ScheduledDocumentsCSVQuery($showAll: Boolean, $search_query: String) {
    scheduledDocumentsCsv(showAll: $showAll, q: $search_query) {
      taskId
    }
  }
`;

export default ScheduledDocumentsCSVQuery;
