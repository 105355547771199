import { Box, Flex } from "grid-styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useLabels,
  withLabelContext
} from "pstat-anywhere/context_providers/LabelContext";
import { WarningBanner } from "pstat-design-system/banner";
import Checkbox, { CheckboxGroup } from "pstat-design-system/inputs/Checkbox";
import { TextareaFormElement } from "pstat-design-system/inputs/FormElements";
import Modal, { ModalFooter } from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { Text } from "pstat-design-system/text";

const MIN_EDIT_COMMENT_LENGTH = 8;

const _StartApprovalsModal = props => {
  const { t } = useTranslation();
  const handleSubmit = (comment, markApproval) => {
    props.onSubmit(comment, markApproval, props.onClose);
  };
  const documentLabel = props.labels.documentLabel.toLowerCase();
  let tooltipText = t("documentControl.edit.startApprovals.tooltip");
  if (props.isExistingDocument) {
    tooltipText += t(
      "documentControl.edit.startApprovals.tooltipExistingDocument",
      { documentLabel }
    );
  } else {
    tooltipText += t("documentControl.edit.startApprovals.tooltipNewDocument", {
      documentLabel
    });
  }
  return (
    <SubmitModal
      id={props.id}
      modalTitle={t("documentControl.edit.startApprovals.heading")}
      tooltipText={tooltipText}
      submitButtonText={t("documentControl.edit.startApprovals.heading")}
      isOpen={props.isOpen}
      commentRequired={props.commentRequired}
      onClose={props.onClose}
      onSubmit={handleSubmit}
      loading={props.loading}
      canMarkApproval={props.canMarkApproval}
      error={props.error}
      BannerComponent={
        props.showTemplatePlaceholderReminder && EPSPlaceholderReminderBanner
      }
    />
  );
};
export const StartApprovalsModal = withLabelContext(_StartApprovalsModal);

const EPSPlaceholderReminderBanner = () => {
  const { t } = useTranslation();
  const { documentLabel } = useLabels();
  return (
    <WarningBanner width={[1, 1, 460]}>
      <Text color="nav.0" pb={2}>
        <Text color="nav.0" pb={2} fontWeight="medium">
          {t("systemDocumentTemplates.startApprovalsBanner.reminder")}
        </Text>
        {t("systemDocumentTemplates.startApprovalsBanner.reminderText", {
          documentLabel: documentLabel.toLowerCase()
        })}
      </Text>
    </WarningBanner>
  );
};

export const OverrideDocumentModal = props => {
  const { t } = useTranslation();
  const handleSubmit = (comment, markApproval) => {
    props.onSubmit(comment, props.onClose);
  };
  let modalTitle = t("documentControl.override.sideBar.title");
  return (
    <SubmitModal
      id={props.id}
      modalTitle={modalTitle}
      submitButtonText={modalTitle}
      isOpen={props.isOpen}
      commentRequired={props.commentRequired}
      onClose={props.onClose}
      onSubmit={handleSubmit}
      loading={props.loading}
      canMarkApproval={false}
      error={props.error}
    />
  );
};

const SubmitModal = props => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [markApproval, setMarkApproval] = useState(true);
  const disabled =
    props.commentRequired && comment.length < MIN_EDIT_COMMENT_LENGTH;
  const isEnterKey = event => event.key === "Enter";
  const handleSubmitKeyDown = event => {
    if (isEnterKey(event)) {
      handleSubmit(event);
    }
  };
  const handleCancelKeyDown = event => {
    if (isEnterKey(event)) {
      props.onClose();
    }
  };
  const handleCommentChange = event => {
    const comment = event.target.value;
    setComment(comment);
  };
  const handleSubmit = event => {
    props.onSubmit(comment, markApproval);
  };
  const BannerComponent = props.BannerComponent;
  return (
    <Modal
      id={props.id}
      contentLabel={props.modalTitle}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      tooltipText={props.tooltipText}
      renderFooter={() => (
        <SubmitModalFooter
          submitButtonText={props.submitButtonText}
          handleSubmit={handleSubmit}
          handleSubmitKeyDown={handleSubmitKeyDown}
          handleCancelKeyDown={handleCancelKeyDown}
          onClose={props.onClose}
          disabled={disabled}
          loading={props.loading}
        />
      )}
    >
      <Flex flexDirection="column" px={5} py={3}>
        {BannerComponent && <BannerComponent />}
        <TextareaFormElement
          id="submit_modal_comment_box"
          label={t("documentControl.edit.startApprovals.label")}
          value={comment}
          onChange={handleCommentChange}
          required={props.commentRequired}
          errors={props.error}
        />
        {props.canMarkApproval && (
          <MarkApprovalCheckBox
            markApproval={markApproval}
            setMarkApproval={setMarkApproval}
          />
        )}
      </Flex>
    </Modal>
  );
};

const MarkApprovalCheckBox = ({ markApproval, setMarkApproval }) => {
  const { t } = useTranslation();
  return (
    <CheckboxGroup
      name="mark-approval"
      onChange={() => setMarkApproval(!markApproval)}
      selectedValues={markApproval ? [markApproval] : null}
      px={0}
    >
      <Checkbox
        value={markApproval}
        label={t("documentControl.edit.startApprovals.markApproval")}
      />
    </CheckboxGroup>
  );
};

const SubmitModalFooter = props => {
  const { t } = useTranslation();
  return (
    <ModalFooter>
      <Flex width={1} px={5} py={3} justifyContent="space-between">
        <Box>
          <Button
            id="submit_modal_submit_button"
            buttonStyle="secondary"
            onClick={props.handleSubmit}
            onKeyDown={props.handleSubmitKeyDown}
            disabled={props.disabled}
            processing={props.loading}
          >
            {props.submitButtonText}
          </Button>
        </Box>
        <Box>
          <Button
            buttonStyle="tertiary"
            onClick={props.onClose}
            onKeyDown={props.handleCancelKeyDown}
          >
            {t("buttons.cancel")}
          </Button>
        </Box>
      </Flex>
    </ModalFooter>
  );
};
