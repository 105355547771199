import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation BulkApproveMutation($documentPks: [Int], $comment: String!) {
    bulkApprove(documentPks: $documentPks, comment: $comment) {
      taskId
    }
  }
`;

export function doBulkApprove({ documentPks, comment, callback }) {
  const variables = {
    documentPks,
    comment
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      callback(response, errors);
    },
    onError: err => {
      throw new Error(err);
    }
  });
}
