import graphql from "babel-plugin-relay/macro";

export const AdoptedSystemDocumentTemplatesQuery = graphql`
  query AdoptedSystemDocumentTemplatesQuery(
    $after: String
    $first: Int
    $sort: String
  ) {
    adoptedSystemDocumentTemplates(sort: $sort) {
      adoptedSystemDocumentTemplates(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            total
            adopted
            document {
              pk
            }
          }
        }
      }
      error {
        statusCode
      }
      otherTabTotal
    }
  }
`;

export default AdoptedSystemDocumentTemplatesQuery;
