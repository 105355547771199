/**
 * @flow
 * @relayHash a068a5d56caf1ea2696e2102a014934a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DuplicateDocumentsQueryVariables = {|
  after?: ?string,
  first?: ?number,
|};
export type DuplicateDocumentsQueryResponse = {|
  +duplicateDocuments: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: ?string,
          +count: ?number,
          +url: ?string,
        |}
      |}>,
    |},
    +error: ?{|
      +statusCode: ?number
    |},
  |}
|};
export type DuplicateDocumentsQuery = {|
  variables: DuplicateDocumentsQueryVariables,
  response: DuplicateDocumentsQueryResponse,
|};
*/


/*
query DuplicateDocumentsQuery(
  $after: String
  $first: Int
) {
  duplicateDocuments {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          name
          count
          url
        }
      }
    }
    error {
      statusCode
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "duplicateDocuments",
    "storageKey": null,
    "args": null,
    "concreteType": "DuplicatesDocumentsReportType",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documents",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "after",
            "variableName": "after",
            "type": "String"
          },
          {
            "kind": "Variable",
            "name": "first",
            "variableName": "first",
            "type": "Int"
          }
        ],
        "concreteType": "DuplicateDocumentConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalCount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "DuplicateDocumentEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "DuplicateDocumentType",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "count",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "url",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "error",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DuplicateDocumentsQuery",
  "id": "2fbd532d08d7b70f7e26912f4be7779b",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DuplicateDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DuplicateDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "query DuplicateDocumentsQuery(\n  $after: String\n  $first: Int\n) {\n  duplicateDocuments {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          name\n          count\n          url\n        }\n      }\n    }\n    error {\n      statusCode\n    }\n  }\n}\n";
(node/*: any*/).hash = '6b8e7f7a43355f78ddd77584f6b180da';
module.exports = node;
