import graphql from "babel-plugin-relay/macro";

export const getWorkflowTemplateQuery = graphql`
  query getWorkflowTemplateQuery($pk: Int!) {
    flowTemplate(pk: $pk) {
      id
      pk
      name
      steps {
        name
        approvers {
          pk
          firstName
          lastName
        }
        includeDocumentOwner
      }
    }
  }
`;

export default getWorkflowTemplateQuery;
