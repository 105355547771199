import { Box, Flex } from "grid-styled";
import { withTranslation } from "react-i18next";

import ExternalLink from "pstat-design-system/ExternalLink";
import Panel from "pstat-design-system/Panel";
import { Text } from "pstat-design-system/text";

const _OneSourceDocumentItem = ({ t, name, viewUrl, revisionInformation }) => {
  return (
    <Box>
      <ExternalLink target="_blank" to={viewUrl}>
        {name}
      </ExternalLink>
      {revisionInformation && (
        <Text>
          {t("documentControl.view.oneSource.panel.revisionInformation.title", {
            data: revisionInformation
          })}
        </Text>
      )}
    </Box>
  );
};
export const OneSourceDocumentItem = withTranslation()(_OneSourceDocumentItem);

const OneSourceDocumentsPanel = ({ t, documents }) => (
  <Panel
    id="onesource-documents-panel"
    hideHeaderBorder={true}
    name={t("documentControl.view.oneSource.panel.title")}
  >
    <Flex flexDirection="column">
      {documents.map((document, index) => {
        return <OneSourceDocumentItem key={index} {...document} />;
      })}
    </Flex>
  </Panel>
);
export default withTranslation()(OneSourceDocumentsPanel);
