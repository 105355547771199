import { useState } from "react";
import { useTranslation } from "react-i18next";

import { isOverridePage } from "../../../PolicyEditor/utils";
import { addCommentToDocument } from "../../AddCommentMutation";

import useNotificationText from "./useNotificationText";

import {
  createErrorMessage,
  createSuccessMessage
} from "pstat-design-system/message/manageMessages";
import useUserAndUserGroupAutocomplete from "pstat-design-system/inputs/UserAndUserGroupAutocompleteInput/hooks";
import { environment } from "pstat-anywhere/relay";

export default function useCommentForm({
  document,
  documentSave,
  isEditPage,
  type,
  initialValue
}) {
  const [commentProcessing, setCommentProcessing] = useState(false);
  const [value, setValue] = useState(initialValue || "");
  const [selectedUserMentions, setSelectedUserMentions] = useState([]);
  const [selectedGroupMentions, setSelectedGroupMentions] = useState([]);

  const { t } = useTranslation();
  const {
    fetchSuggestions,
    getSuggestionValue,
    getSuggestionById
  } = useUserAndUserGroupAutocomplete({ users: true, groups: true });
  const usersNotificationComment = document?.usersNotificationComment;
  const usersAlreadyToBeNotified =
    usersNotificationComment?.usersNotificationCommentOn;
  const usersOptingOutOfBeingNotified =
    usersNotificationComment?.usersNotificationCommentOff;
  const {
    usersToNotifyText,
    usersOptedOutText,
    fetchGroupUsers
  } = useNotificationText(
    usersAlreadyToBeNotified,
    usersOptingOutOfBeingNotified,
    selectedUserMentions,
    selectedGroupMentions
  );

  function getData(search, callback) {
    fetchSuggestions({ value: search }).then(suggestions => {
      const [userSuggestions, groupSuggestions] = suggestions;
      const mappedSuggestions = [...userSuggestions, ...groupSuggestions].map(
        suggestion => ({
          ...suggestion,
          id: suggestion.id,
          display: getSuggestionValue(suggestion)
        })
      );
      callback(mappedSuggestions);
    });
  }

  function handleTextChange(event) {
    setValue(event.target.value);
    verifySelectedMentionsAreStillSelected(event.target.value);
  }

  function verifySelectedMentionsAreStillSelected(inputValue) {
    // When user use @mentioning - there might be a case that user selected someone from dropdown
    // and then deleted mention from input field (due to typo or miss click or whatever).
    // However, there's no callback which would clean selectedMentions
    // So we have to compare that manually to ensure that non-selected mentions
    // Wouldn't be notified
    const filteredUserMentions = selectedUserMentions.filter(mention =>
      inputValue.includes(mention.display)
    );
    const filteredGroupMentions = selectedGroupMentions.filter(mention =>
      inputValue.includes(mention.display)
    );
    if (filteredUserMentions.length !== selectedUserMentions.length) {
      setSelectedUserMentions(filteredUserMentions);
    }
    if (filteredGroupMentions.length !== selectedGroupMentions.length) {
      setSelectedGroupMentions(filteredGroupMentions);
    }
  }

  function handleMentionAdd(id, display) {
    const selectedMention = getSuggestionById(id);

    if (!selectedMention) {
      return;
    }

    if (selectedMention.type === "user") {
      setSelectedUserMentions([
        ...selectedUserMentions,
        { ...selectedMention, id, display, pk: parseInt(id) }
      ]);
    } else {
      const newGroup = { ...selectedMention, id, display, pk: parseInt(id) };
      setSelectedGroupMentions([...selectedGroupMentions, newGroup]);
      fetchGroupUsers(newGroup);
    }
  }

  function isMentionSelected(id, display) {
    return value.includes(display);
  }

  function handleSubmit() {
    setCommentProcessing(true);
    const savedCopy = documentSave && documentSave.savedCopy;
    let documentToAddComment = document;
    if (!isOverridePage(type) && savedCopy) {
      documentToAddComment = savedCopy;
    }
    const userPksToNotify = [];
    const groupPksToNotify = [];
    selectedUserMentions.forEach(mention => {
      userPksToNotify.push(mention.pk);
    });
    selectedGroupMentions.forEach(mention => {
      groupPksToNotify.push(mention.pk);
    });
    addCommentToDocument(
      environment,
      document,
      documentToAddComment,
      value,
      isEditPage,
      (response, errors) => {
        const {
          addComment: { ok }
        } = response;
        if (errors) {
          errors.forEach(error => {
            createErrorMessage(error);
          });
        } else if (ok) {
          createSuccessMessage(
            t("documentControl.view.commentForm.commentPanel.successMessage")
          );
        }
        setCommentProcessing(false);
        setValue("");
        setSelectedUserMentions([]);
        setSelectedGroupMentions([]);
      },
      userPksToNotify,
      groupPksToNotify
    );
  }
  return {
    value,
    commentProcessing,
    handleTextChange,
    handleMentionAdd,
    handleSubmit,
    t,
    getData,
    isMentionSelected,
    usersToNotifyText,
    usersOptedOutText
  };
}
