/**
 * @flow
 * @relayHash e29e7a53ffb3f02fc5a0d3043919cf4e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ManufacturerInputQueryVariables = {||};
export type ManufacturerInputQueryResponse = {|
  +manufacturersData: ?{|
    +data: ?$ReadOnlyArray<?{|
      +manufactureId: ?string,
      +name: ?string,
    |}>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type ManufacturerInputQuery = {|
  variables: ManufacturerInputQueryVariables,
  response: ManufacturerInputQueryResponse,
|};
*/


/*
query ManufacturerInputQuery {
  manufacturersData {
    data {
      manufactureId
      name
      id
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "manufactureId",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ManufacturerInputQuery",
  "id": "44671217869f4e15267915698f0227c1",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ManufacturerInputQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "manufacturersData",
        "storageKey": null,
        "args": null,
        "concreteType": "ListManufacturersType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "data",
            "storageKey": null,
            "args": null,
            "concreteType": "OneSourceManufactureType",
            "plural": true,
            "selections": [
              v0,
              v1
            ]
          },
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ManufacturerInputQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "manufacturersData",
        "storageKey": null,
        "args": null,
        "concreteType": "ListManufacturersType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "data",
            "storageKey": null,
            "args": null,
            "concreteType": "OneSourceManufactureType",
            "plural": true,
            "selections": [
              v0,
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          },
          v2
        ]
      }
    ]
  }
};
})();
node.text = "query ManufacturerInputQuery {\n  manufacturersData {\n    data {\n      manufactureId\n      name\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = '8ca174893d71bb7c019efc0f4f89829d';
module.exports = node;
