/**
 * @flow
 * @relayHash 4b4c91167d4d8715ff33b764de4a3df0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RetireDocumentInput = {
  documentPk: number,
  actionComment: string,
  clientMutationId?: ?string,
};
export type RetirePolicyMutationVariables = {|
  input: RetireDocumentInput
|};
export type RetirePolicyMutationResponse = {|
  +retireDocument: ?{|
    +ok: ?boolean,
    +reasons: ?any,
    +redirectPath: ?string,
  |}
|};
export type RetirePolicyMutation = {|
  variables: RetirePolicyMutationVariables,
  response: RetirePolicyMutationResponse,
|};
*/


/*
mutation RetirePolicyMutation(
  $input: RetireDocumentInput!
) {
  retireDocument(input: $input) {
    ok
    reasons
    redirectPath
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "RetireDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "retireDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "RetireDocumentInput!"
      }
    ],
    "concreteType": "RetireDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "ok",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "reasons",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "redirectPath",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "RetirePolicyMutation",
  "id": "cc646fc17720508923edaeada797f0cd",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RetirePolicyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "RetirePolicyMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
node.text = "mutation RetirePolicyMutation(\n  $input: RetireDocumentInput!\n) {\n  retireDocument(input: $input) {\n    ok\n    reasons\n    redirectPath\n  }\n}\n";
(node/*: any*/).hash = '84a70d76bdaf28536e25f7a282cd85fc';
module.exports = node;
