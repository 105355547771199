import { withTranslation } from "react-i18next";

import { TagSearchLink } from "pstat-anywhere/components/view/Properties";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import Panel from "pstat-design-system/Panel";

export const TextItem = ({ pk, name, isLast }) => (
  <TagSearchLink p={1} pk={pk} text={`${name}${isLast ? "" : ", "}`} />
);

const ReferencesPanel = ({ t, labels, tags }) => {
  return (
    <Panel id="references" name={labels.referencesLabel}>
      {tags.map((item, index) => (
        <TextItem key={index} {...item} isLast={index === tags.length - 1} />
      ))}
    </Panel>
  );
};

export default withTranslation()(withLabelContext(ReferencesPanel));
