import graphql from "babel-plugin-relay/macro";

export const RetirePolicyQuery = graphql`
  query RetirePolicyQuery($documentPk: Int!) {
    documentRetire(pk: $documentPk) {
      document {
        id
        pk
        name
        html
        author {
          pk
          userDisplay
          firstName
          lastName
          profile {
            title
          }
        }
        category {
          pk
          name
        }
        bloc {
          id
          name
          tenants(sort: "name") {
            pk
            name
          }
        }
        parent {
          id
        }
        isAForm
        status
        restricted
        effectiveDate(formatted: true)
        approvalDate(formatted: true)
        expirationDate(formatted: true)
        revisionDate(formatted: true)
        revisionDates
        statusChangedDate
        originationDate(formatted: true)
        legacyEffectiveDate(formatted: true)
        created
        modified
        approvable
        headerImageUrl
        rssFeedUrl

        attachments {
          title
          fileUrl
        }
        tags {
          pk
          name
        }
        approvalSignatures {
          approver {
            firstName
            lastName
            title
          }
          proxySigner {
            firstName
            lastName
            title
          }
          approvalStep {
            name
          }
          approvedDate(formatted: true)
          required
        }
        pastApprovalSignatures {
          approver {
            firstName
            lastName
            title
          }
          proxySigner {
            firstName
            lastName
            title
          }
          approvalStep {
            name
          }
          approvedDate(formatted: true)
          required
        }
        regulations {
          pk
          name
        }
        onesourceDocuments {
          pk
          name
          viewUrl
          revisionInformation
        }
        draftVersionLink
        pendingVersionLink
        scheduledVersionLink
      }
      policyActions
      error {
        statusCode
        message
      }
    }
  }
`;

export default RetirePolicyQuery;
