import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import { environment } from "pstat-anywhere/relay";

const mutation = graphql`
  mutation EpStandardContentChangesMutation(
    $documentLineEpAssociation: Int!
    $fromVersion: String!
    $toVersion: String!
  ) {
    documentLineEpChanges(
      documentLineEpAssociationId: $documentLineEpAssociation
      fromVersion: $fromVersion
      toVersion: $toVersion
    ) {
      taskId
      ok
      error {
        message
      }
    }
  }
`;

const epStandardContentChangesMutation = (
  documentLineEpAssociation,
  fromVersion,
  toVersion,
  callback
) => {
  const variables = {
    documentLineEpAssociation,
    fromVersion,
    toVersion
  };
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => callback(response),
    onError: error => {
      throw new Error(error);
    }
  });
};

export default epStandardContentChangesMutation;
