/**
 * @flow
 * @relayHash b95b622bc20f0f0b05d789859fce0bae
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BlocTenantsPageQueryVariables = {|
  sort?: ?string
|};
export type BlocTenantsPageQueryResponse = {|
  +canAdminTenants: ?{|
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
    +tenants: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
      +subdomain: ?string,
      +created: ?string,
    |}>,
    +documentSettings: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +display: ?string,
    |}>,
  |}
|};
export type BlocTenantsPageQuery = {|
  variables: BlocTenantsPageQueryVariables,
  response: BlocTenantsPageQueryResponse,
|};
*/


/*
query BlocTenantsPageQuery(
  $sort: String
) {
  canAdminTenants(sort: $sort) {
    error {
      statusCode
      message
    }
    tenants {
      pk
      name
      subdomain
      created
      id
    }
    documentSettings {
      pk
      display
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "subdomain",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "created",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "display",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "BlocTenantsPageQuery",
  "id": "fcf287aa33b6a53c7201cf20a413c7b2",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "BlocTenantsPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "canAdminTenants",
        "storageKey": null,
        "args": v1,
        "concreteType": "CanAdminTenantsType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenants",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantType",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": true,
            "selections": [
              v3,
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BlocTenantsPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "canAdminTenants",
        "storageKey": null,
        "args": v1,
        "concreteType": "CanAdminTenantsType",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenants",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantType",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v8
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documentSettings",
            "storageKey": null,
            "args": null,
            "concreteType": "DocumentSettingsType",
            "plural": true,
            "selections": [
              v3,
              v7,
              v8
            ]
          }
        ]
      }
    ]
  }
};
})();
node.text = "query BlocTenantsPageQuery(\n  $sort: String\n) {\n  canAdminTenants(sort: $sort) {\n    error {\n      statusCode\n      message\n    }\n    tenants {\n      pk\n      name\n      subdomain\n      created\n      id\n    }\n    documentSettings {\n      pk\n      display\n      id\n    }\n  }\n}\n";
(node/*: any*/).hash = '1c1ca55ab7f8b4122148891ea4a88fce';
module.exports = node;
