import graphql from "babel-plugin-relay/macro";
import { useTranslation } from "react-i18next";
import { fetchQuery } from "react-relay";

import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import { WarningBanner } from "pstat-design-system/banner";

const expirationDefaultWarningQuery = graphql`
  query DefaultExpirationWarningQuery(
    $lifespan: Int
    $categoryPk: Int
    $documentPk: Int
    $initial: Boolean
  ) {
    expirationDefaultWarning(
      lifespan: $lifespan
      categoryPk: $categoryPk
      documentPk: $documentPk
      initial: $initial
    ) {
      lifespan
      defaultLifespan
      categoryName
    }
  }
`;

export const checkDefaultExpiration = (
  lifespan,
  documentPk,
  categoryPk,
  initialCheck,
  setExpirationWarning
) => {
  const variables = {
    lifespan: parseInt(lifespan, 10),
    documentPk,
    categoryPk,
    initial: initialCheck
  };
  fetchQuery(environment, expirationDefaultWarningQuery, variables).then(
    data => {
      if (!data) {
        return;
      }
      const warningInfo = data.expirationDefaultWarning;
      if (!warningInfo) {
        setExpirationWarning(null);
        return;
      }
      setExpirationWarning(warningInfo);
    }
  );
};

const Banner = ({ expirationWarning, labels }) => {
  const { lifespan, defaultLifespan, categoryName } = expirationWarning;
  const { documentLabel } = labels;
  const { t } = useTranslation();
  let message = t("documentControl.create.defaultExpirationWarning", {
    lifespan,
    documentLabel: documentLabel.toLowerCase()
  });
  if (defaultLifespan) {
    message += t("documentControl.create.defaultExpirationWarning.defaultSet", {
      defaultLifespan,
      categoryName
    });
  } else {
    message += t(
      "documentControl.create.defaultExpirationWarning.defaultNotSet",
      {
        categoryName
      }
    );
  }
  return <WarningBanner width={1} message={message} />;
};

export default withLabelContext(Banner);
