import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentContainer } from "pstat-anywhere/components/pages";
import AdvancedReportPage from "pstat-anywhere/components/pages/reports/AdvancedReportPage";
import unpublishTemplate from "pstat-anywhere/components/system_document_templates/UnpublishTemplateMutation";
import { useLabels } from "pstat-anywhere/context_providers/LabelContext";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { SuccessBanner } from "pstat-design-system/banner";
import Breadcrumb from "pstat-design-system/Breadcrumb";

const TemplateManagementBreadcrumb = () => {
  const { t } = useTranslation();
  const labels = useLabels();
  const { documentLabel } = labels;
  return (
    <Breadcrumb
      text={t("systemDocumentTemplates.breadcrumb", {
        documentLabel: titleCase(documentLabel)
      })}
      to={`${getRootUrl()}/template/manage`}
    />
  );
};

const SuccessfullyUnpublishedBanner = ({ templateName, sitesCount }) => {
  const { t } = useTranslation();
  const messageId =
    sitesCount === 1
      ? "systemDocumentTemplates.manage.sites.unpublishSuccess"
      : "systemDocumentTemplates.manage.sites.unpublishSuccessPlural";
  const message = t(messageId, { templateName, sitesCount });
  return <SuccessBanner message={message} width={null} mx={4} mt={6} />;
};

const PublishedTemplatesPage = ({
  adoptedSystemDocumentTemplates,
  variables,
  location,
  route,
  router
}) => {
  const [showSuccessUnpublishBanner, setShowSuccessUnpublishBanner] = useState(
    false
  );
  const [unpublishTemplateName, setUnpublishTemplateName] = useState("");
  const [numBlocUnpublishTemplates, setNumBlocUnpublishTemplates] = useState(0);

  const onSuccessUnpublish = (templateName, numBlocs) => {
    setShowSuccessUnpublishBanner(true);
    setUnpublishTemplateName(templateName);
    setNumBlocUnpublishTemplates(numBlocs);
  };

  const unpublishTemplateHandler = (
    templateId,
    templateName,
    setLoading,
    setConfirmationModalIsOpen
  ) => {
    unpublishTemplate(templateId, location, (data, errors) => {
      const { unpublishSystemDocumentTemplate } = data || {};
      if (unpublishSystemDocumentTemplate.ok) {
        const { unpublish_success } =
          unpublishSystemDocumentTemplate?.result || {};
        onSuccessUnpublish(templateName, (unpublish_success || []).length);
      }
      setLoading(false);
      setConfirmationModalIsOpen(false);
    });
  };
  const templates =
    adoptedSystemDocumentTemplates?.adoptedSystemDocumentTemplates;
  const edges = templates?.edges;
  const totalCount = templates?.totalCount;

  const dataWithUnpublishHandler = (edges || [])
    .map(item => item.node)
    .map(item => Object.assign({ unpublishTemplateHandler }, item))
    .map(item => ({ node: item }));

  const data = {
    edges: dataWithUnpublishHandler,
    totalCount
  };
  return (
    <ContentContainer>
      {showSuccessUnpublishBanner && (
        <SuccessfullyUnpublishedBanner
          sitesCount={numBlocUnpublishTemplates}
          templateName={unpublishTemplateName}
        />
      )}
      <Templates
        tableType="publishedTemplatesTable"
        data={data}
        variables={variables}
        route={route}
        router={router}
        location={location}
        publishedCount={totalCount}
        unpublishedCount={adoptedSystemDocumentTemplates?.otherTabTotal}
        noContentMessageKey="systemDocumentTemplates.published.emptyMessage.published"
      />
    </ContentContainer>
  );
};

export default PublishedTemplatesPage;

export const UnpublishedTemplatesPage = ({
  unpublishedAdoptedSystemDocumentTemplates,
  variables,
  location,
  route,
  router
}) => {
  const templates =
    unpublishedAdoptedSystemDocumentTemplates?.adoptedSystemDocumentTemplates;
  const edges = templates?.edges;
  const totalCount = templates?.totalCount;

  const data = {
    edges: edges,
    totalCount
  };
  return (
    <ContentContainer>
      <Templates
        tableType="unpublishedTemplatesTable"
        data={data}
        variables={variables}
        route={route}
        router={router}
        location={location}
        publishedCount={
          unpublishedAdoptedSystemDocumentTemplates?.otherTabTotal
        }
        unpublishedCount={totalCount}
        noContentMessageKey="systemDocumentTemplates.published.emptyMessage.unpublished"
      />
    </ContentContainer>
  );
};

const Templates = ({
  tableType,
  data,
  variables,
  route,
  router,
  location,
  publishedCount,
  unpublishedCount,
  noContentMessageKey
}) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = useLabels();
  const tabs = [
    {
      id: "published_templates",
      textKey: "systemDocumentTemplates.published.tabs.published",
      path: "template/published",
      count: publishedCount
    },
    {
      id: "unpublished_templates",
      textKey: "systemDocumentTemplates.published.tabs.unpublished",
      path: "template/unpublished",
      count: unpublishedCount
    }
  ];
  return (
    <ContentContainer>
      <AdvancedReportPage
        variables={variables}
        data={data}
        tabs={tabs}
        route={route}
        router={router}
        location={location}
        headerText={t("systemDocumentTemplates.published.header", {
          documentLabelPlural: titleCase(documentLabelPlural)
        })}
        noContentMessage={t(noContentMessageKey)}
        showTabsPanel={true}
        showPagination={true}
        showHitsPerPageSelector={true}
        tableType={tableType}
        customOnRow={() => {}}
        showActionButtonPanel={true}
        frozenColumnTable={true}
        BreadcrumbComponent={TemplateManagementBreadcrumb}
      />
    </ContentContainer>
  );
};
