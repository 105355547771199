/**
 * @flow
 * @relayHash b68313ba0ae74e6056393e32a63da149
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentStatus = "ACTIVE" | "DELETED" | "OLD" | "OLD_SAVE" | "PENDING" | "RETIRED" | "SAVED" | "SCHEDULED" | "%future added value";
export type TemplateManagementPageQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type TemplateManagementPageQueryResponse = {|
  +systemDocumentTemplates: ?{|
    +systemDocumentTemplates: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +status: ?DocumentStatus,
          +name: ?string,
          +html: ?string,
          +author: ?{|
            +firstName: string,
            +lastName: string,
            +title: ?string,
          |},
          +category: ?{|
            +name: string
          |},
          +approvalDate: ?string,
          +revisionDate: ?string,
          +tags: ?$ReadOnlyArray<?{|
            +name: string
          |}>,
          +document: ?{|
            +pk: ?number
          |},
        |}
      |}>,
    |},
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type TemplateManagementPageQuery = {|
  variables: TemplateManagementPageQueryVariables,
  response: TemplateManagementPageQueryResponse,
|};
*/


/*
query TemplateManagementPageQuery(
  $after: String
  $first: Int
  $sort: String
) {
  systemDocumentTemplates(sort: $sort) {
    systemDocumentTemplates(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          status
          name
          html
          author {
            firstName
            lastName
            title
            id
          }
          category {
            name
            id
          }
          approvalDate
          revisionDate
          tags {
            name
            id
          }
          document {
            pk
            id
          }
          id
        }
      }
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "html",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v11 = [
  v6
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisionDate",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v16 = [
  v6,
  v15
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "TemplateManagementPageQuery",
  "id": "b00cd07762afe84ec363b45ea910c5dc",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TemplateManagementPageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "systemDocumentTemplates",
        "storageKey": null,
        "args": v1,
        "concreteType": "SystemDocumentTemplateListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "systemDocumentTemplates",
            "storageKey": null,
            "args": v2,
            "concreteType": "SystemDocumentTemplateCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SystemDocumentTemplateCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SystemDocumentTemplateType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserType",
                        "plural": false,
                        "selections": [
                          v8,
                          v9,
                          v10
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryType",
                        "plural": false,
                        "selections": v11
                      },
                      v12,
                      v13,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tags",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagType",
                        "plural": true,
                        "selections": v11
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v14
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TemplateManagementPageQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "systemDocumentTemplates",
        "storageKey": null,
        "args": v1,
        "concreteType": "SystemDocumentTemplateListType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "systemDocumentTemplates",
            "storageKey": null,
            "args": v2,
            "concreteType": "SystemDocumentTemplateCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "SystemDocumentTemplateCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SystemDocumentTemplateType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v7,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserType",
                        "plural": false,
                        "selections": [
                          v8,
                          v9,
                          v10,
                          v15
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "category",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CategoryType",
                        "plural": false,
                        "selections": v16
                      },
                      v12,
                      v13,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tags",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TagType",
                        "plural": true,
                        "selections": v16
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "document",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentType",
                        "plural": false,
                        "selections": [
                          v4,
                          v15
                        ]
                      },
                      v15
                    ]
                  }
                ]
              }
            ]
          },
          v14
        ]
      }
    ]
  }
};
})();
node.text = "query TemplateManagementPageQuery(\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  systemDocumentTemplates(sort: $sort) {\n    systemDocumentTemplates(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          status\n          name\n          html\n          author {\n            firstName\n            lastName\n            title\n            id\n          }\n          category {\n            name\n            id\n          }\n          approvalDate\n          revisionDate\n          tags {\n            name\n            id\n          }\n          document {\n            pk\n            id\n          }\n          id\n        }\n      }\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = 'b480a928b34ef9f6ebea43a234c4d235';
module.exports = node;
