/**
 * @flow
 * @relayHash f1cabd26ebe2f559fedaf85201f8b27b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VergeAssociatedDocumentsQueryVariables = {|
  justMine?: ?boolean,
  after?: ?string,
  first?: ?number,
  sort?: ?string,
|};
export type VergeAssociatedDocumentsQueryResponse = {|
  +documentsWithVergeStandards: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +approvalDate: ?string,
          +epAssociationDocuments: ?$ReadOnlyArray<?{|
            +pk: ?number,
            +id: string,
            +epId: string,
            +associationDatetime: any,
          |}>,
          +bloc: {|
            +name: string
          |},
        |}
      |}>,
    |},
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type VergeAssociatedDocumentsQuery = {|
  variables: VergeAssociatedDocumentsQueryVariables,
  response: VergeAssociatedDocumentsQueryResponse,
|};
*/


/*
query VergeAssociatedDocumentsQuery(
  $justMine: Boolean
  $after: String
  $first: Int
  $sort: String
) {
  documentsWithVergeStandards(justMine: $justMine, sort: $sort) {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          approvalDate
          epAssociationDocuments {
            pk
            id
            epId
            associationDatetime
          }
          bloc {
            name
            id
          }
          id
        }
      }
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "justMine",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "justMine",
    "variableName": "justMine",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvalDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "epAssociationDocuments",
  "storageKey": null,
  "args": null,
  "concreteType": "EpAssociationDocumentType",
  "plural": true,
  "selections": [
    v4,
    v7,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "epId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "associationDatetime",
      "args": null,
      "storageKey": null
    }
  ]
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "VergeAssociatedDocumentsQuery",
  "id": "4bac1abfa367db8954134ce3371c8d20",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "VergeAssociatedDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsWithVergeStandards",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentStandardsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          v5
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v9
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VergeAssociatedDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsWithVergeStandards",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentStandardsReportType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v6,
                      v8,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": [
                          v5,
                          v7
                        ]
                      },
                      v7
                    ]
                  }
                ]
              }
            ]
          },
          v9
        ]
      }
    ]
  }
};
})();
node.text = "query VergeAssociatedDocumentsQuery(\n  $justMine: Boolean\n  $after: String\n  $first: Int\n  $sort: String\n) {\n  documentsWithVergeStandards(justMine: $justMine, sort: $sort) {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          approvalDate\n          epAssociationDocuments {\n            pk\n            id\n            epId\n            associationDatetime\n          }\n          bloc {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = '2a58a0abf743c904220880279c3d1bb0';
module.exports = node;
