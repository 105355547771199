/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type Attachments_template$ref = any;
type Content_template$ref = any;
type StatusBar_template$ref = any;
type TemplateProperties_template$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ViewTemplatePage_template$ref: FragmentReference;
export type ViewTemplatePage_template = {|
  +name: ?string,
  +$fragmentRefs: StatusBar_template$ref & TemplateProperties_template$ref & Content_template$ref & Attachments_template$ref,
  +$refType: ViewTemplatePage_template$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ViewTemplatePage_template",
  "type": "SystemDocumentTemplateType",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "FragmentSpread",
      "name": "StatusBar_template",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "TemplateProperties_template",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Content_template",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "Attachments_template",
      "args": null
    }
  ]
};

(node/*: any*/).hash = 'fc62ff90ce83879f532e3f57f2eadd2d';
module.exports = node;
