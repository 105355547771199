import styled from "styled-components";

import { layer } from "pstat-design-system/utils";

const BlurOverflow = styled.div`
  background-image: linear-gradient(
    to top,
    hsl(0, 0%, 100%),
    hsla(0, 0%, 100%, 0)
  );
  height: 23px;
  ${layer};
  display: block;
  width: 100%;
  position: absolute;
  bottom: 52px;
`;

BlurOverflow.defaultProps = {
  layer: "textOverlay"
};

export default BlurOverflow;
