import graphql from "babel-plugin-relay/macro";

export const ImportFromWordQuery = graphql`
  query ImportFromWordQuery {
    documentBuild {
      error {
        statusCode
        message
      }
      redirectUrl
    }
  }
`;

export default ImportFromWordQuery;
